// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/assessment/sitting/v1/sitting.proto" (package "sparx.assessment.sitting.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Package } from '../../../packageactivity/v1/package';
import { Date } from '../../../../../google/type/date';
import { Package_Contents } from '../../../packageactivity/v1/package';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.Sitting
 */
export interface Sitting {
  /**
   * The id of the assessment sitting in the format:
   * "schools/<uuid>/sittings/<uuid>".
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
  /**
   * The name of the assessment.
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * The name of the sitting group.
   *
   * @generated from protobuf field: string sitting_group_name = 3;
   */
  sittingGroupName: string;
  /**
   * The title of the assessment.
   *
   * @generated from protobuf field: string title = 4;
   */
  title: string;
  /**
   * The title of the assessment shown to students.
   *
   * @generated from protobuf field: string student_title = 9;
   */
  studentTitle: string;
  /**
   * UK yeargroup assigned to this sitting.
   *
   * @generated from protobuf field: int32 uk_yeargroup = 8;
   */
  ukYeargroup: number;
  /**
   * The join code for the sitting.
   *
   * @generated from protobuf field: string join_code = 5;
   */
  joinCode: string;
  /**
   * Timestamp the sitting was created.
   *
   * @generated from protobuf field: google.protobuf.Timestamp created_timestamp = 6;
   */
  createdTimestamp?: Timestamp;
  /**
   * The state of the sitting.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.SittingState state = 7;
   */
  state?: SittingState;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.SittingGroup
 */
export interface SittingGroup {
  /**
   * Name of the sitting group. In the format:
   * "schools/<uuid>/sittinggroups/<uuid>".
   *
   * @generated from protobuf field: string sitting_group_name = 1;
   */
  sittingGroupName: string;
  /**
   * Manual name entry.
   *
   * @generated from protobuf field: string manual_name = 2;
   */
  manualName: string;
  /**
   * ID of the student group that this sitting group is assigned
   * too once the group exists in the MIS.
   *
   * @generated from protobuf field: string student_group_id = 3;
   */
  studentGroupId: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.PauseEvent
 */
export interface PauseEvent {
  /**
   * The state of the pause.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.PauseState state = 1;
   */
  state: PauseState;
  /**
   * Timestamp the pause event occurred.
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;
  /**
   * The user subject that caused the pause event.
   *
   * @generated from protobuf field: string actor_subject = 3;
   */
  actorSubject: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.SittingState
 */
export interface SittingState {
  /**
   * The state of the sitting.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.SittingDeliveryState state = 1;
   */
  state: SittingDeliveryState;
  /**
   * Pause state of the sitting.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.PauseState pause_state = 2;
   */
  pauseState: PauseState;
  /**
   * The start timestamp of the sitting. Will be set when the sitting is moved
   * to the IN_PROGRESS state.
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_timestamp = 3;
   */
  startTimestamp?: Timestamp;
  /**
   * The end timestamp of the sitting. Will be set when the sitting is moved to
   * the COMPLETED state.
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_timestamp = 4;
   */
  endTimestamp?: Timestamp;
  /**
   * Pause events that have occurred during the sitting.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.PauseEvent pause_events = 5;
   */
  pauseEvents: PauseEvent[];
  /**
   * Timestamp the sitting was processed after ending.
   *
   * @generated from protobuf field: google.protobuf.Timestamp processed_timestamp = 6;
   */
  processedTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.SittingParticipant
 */
export interface SittingParticipant {
  /**
   * The subject of the participant.
   *
   * @generated from protobuf field: string participant_subject = 1;
   */
  participantSubject: string;
  /**
   * The timestamp when the participant was created.
   *
   * @generated from protobuf field: google.protobuf.Timestamp created_timestamp = 2;
   */
  createdTimestamp?: Timestamp;
  /**
   * The timestamp when the participants result was exported.
   *
   * @generated from protobuf field: google.protobuf.Timestamp exported_timestamp = 3;
   */
  exportedTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.SittingParticipantReportedState
 */
export interface SittingParticipantReportedState {
  /**
   * Name of the current task item the student is on.
   *
   * @generated from protobuf field: string current_task_item_name = 1;
   */
  currentTaskItemName: string;
  /**
   * Name of the current activity the student is on.
   *
   * @generated from protobuf field: string current_activity_name = 2;
   */
  currentActivityName: string;
  /**
   * Current active state of the student.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.StudentActiveState active_state = 3;
   */
  activeState: StudentActiveState;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.SittingParticipantState
 */
export interface SittingParticipantState {
  /**
   * The subject of the participant.
   *
   * @generated from protobuf field: string participant_subject = 1;
   */
  participantSubject: string;
  /**
   * The timestamp when the participant was last seen.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_seen = 2;
   */
  lastSeen?: Timestamp;
  /**
   * Timestamp the student last progressed on this sitting.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_progressed = 3;
   */
  lastProgressed?: Timestamp;
  /**
   * The reported state of the participant.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.SittingParticipantReportedState reported_state = 4;
   */
  reportedState?: SittingParticipantReportedState;
  /**
   * List of packages that were voided for this participant.
   *
   * @generated from protobuf field: repeated string voided_package_names = 5;
   */
  voidedPackageNames: string[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.SittingPackageContents
 */
export interface SittingPackageContents {
  /**
   * @generated from protobuf field: string delivery_method = 1;
   */
  deliveryMethod: string;
  /**
   * @generated from protobuf oneof: spec
   */
  spec:
    | {
        oneofKind: 'staticContents';
        /**
         * @generated from protobuf field: sparx.packageactivity.v1.Package.Contents static_contents = 2;
         */
        staticContents: Package_Contents;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.Student
 */
export interface Student {
  /**
   * The subject of the student.
   *
   * @generated from protobuf field: string subject = 1;
   */
  subject: string;
  /**
   * The student's given name.
   *
   * @generated from protobuf field: string given_name = 2;
   */
  givenName: string;
  /**
   * Family name of the student.
   *
   * @generated from protobuf field: string family_name = 3;
   */
  familyName: string;
  /**
   * Date of birth (without year) in the form "MM-DD".
   *
   * @generated from protobuf field: google.type.Date date_of_birth = 4;
   */
  dateOfBirth?: Date;
  /**
   * The linked subject of this student. This is used to link a guest student
   * to a real student.
   *
   * @generated from protobuf field: string linked_student_name = 5;
   */
  linkedStudentName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.SittingEndedEvent
 */
export interface SittingEndedEvent {
  /**
   * Name of the sitting that ended.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.PackageCompletedEvent
 */
export interface PackageCompletedEvent {
  /**
   * Name of the package that was completed.
   *
   * @generated from protobuf field: string package_name = 1;
   */
  packageName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UploadedAssessmentResult
 */
export interface UploadedAssessmentResult {
  /**
   * Name of the assessment this result was for. In the format `assessments/<uuid>`
   *
   * @generated from protobuf field: string assessment_name = 1;
   */
  assessmentName: string;
  /**
   * Name of the student that the result was for. In the format `students/<uuid>`
   *
   * @generated from protobuf field: string student_name = 2;
   */
  studentName: string;
  /**
   * Name of the result that was uploaded. In the format `packages/<uuid>`.
   *
   * @generated from protobuf field: string package_name = 3;
   */
  packageName: string;
  /**
   * Timestamp the package was uploaded.
   *
   * @generated from protobuf field: google.protobuf.Timestamp uploaded_timestamp = 4;
   */
  uploadedTimestamp?: Timestamp;
  /**
   * Timestamp the participant was created.
   *
   * @generated from protobuf field: google.protobuf.Timestamp created_timestamp = 5;
   */
  createdTimestamp?: Timestamp;
  /**
   * The package relating to this result.
   *
   * @generated from protobuf field: sparx.packageactivity.v1.Package package = 6;
   */
  package?: Package;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListSittingsRequest
 */
export interface ListSittingsRequest {
  /**
   * The name of the school to list sittings for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf oneof: filter
   */
  filter:
    | {
        oneofKind: 'ended';
        /**
         * Include ended sittings instead of active.
         *
         * @generated from protobuf field: bool ended = 2;
         */
        ended: boolean;
      }
    | {
        oneofKind: 'assessmentName';
        /**
         * Return all sittings for an assessment
         *
         * @generated from protobuf field: string assessment_name = 3;
         */
        assessmentName: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListSittingsResponse
 */
export interface ListSittingsResponse {
  /**
   * The list of sittings.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.ListSittingsResponse.SittingData sittings = 1;
   */
  sittings: ListSittingsResponse_SittingData[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListSittingsResponse.SittingData
 */
export interface ListSittingsResponse_SittingData {
  /**
   * The sitting.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.Sitting sitting = 1;
   */
  sitting?: Sitting;
  /**
   * Number of participants in the sitting.
   *
   * @generated from protobuf field: int32 participant_count = 2;
   */
  participantCount: number;
  /**
   * Number of participants whose result have been exported.
   *
   * @generated from protobuf field: int32 exported_participant_count = 3;
   */
  exportedParticipantCount: number;
  /**
   * The sitting group the sitting is assigned to.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.SittingGroup sitting_group = 4;
   */
  sittingGroup?: SittingGroup;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.CreateSittingRequest
 */
export interface CreateSittingRequest {
  /**
   * The name of the school to create the assessment for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The title of the assessment.
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * Name of the assessment to create the sitting for.
   *
   * @generated from protobuf field: string assessment_name = 3;
   */
  assessmentName: string;
  /**
   * The UK year group the assessment is for.
   *
   * @generated from protobuf field: int32 uk_year_group = 4;
   */
  ukYearGroup: number;
  /**
   * List of groups to create the sitting for.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.CreateSittingRequest.StudentGroup groups = 5;
   */
  groups: CreateSittingRequest_StudentGroup[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.CreateSittingRequest.StudentGroup
 */
export interface CreateSittingRequest_StudentGroup {
  /**
   * Manual name entry.
   *
   * @generated from protobuf field: string manual_name = 1;
   */
  manualName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.CreateSittingResponse
 */
export interface CreateSittingResponse {
  /**
   * The created assessment sittings.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.Sitting sittings = 1;
   */
  sittings: Sitting[];
  /**
   * The created assessment sitting groups.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.SittingGroup sitting_groups = 2;
   */
  sittingGroups: SittingGroup[];
  /**
   * Mapping of sitting names to tokens to authenticate guest teachers.
   *
   * @generated from protobuf field: map<string, string> guest_teacher_tokens = 3;
   */
  guestTeacherTokens: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListSittingParticipantsRequest
 */
export interface ListSittingParticipantsRequest {
  /**
   * The name of the sitting to list participants for.
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListSittingParticipantsResponse
 */
export interface ListSittingParticipantsResponse {
  /**
   * The list of participants.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.SittingParticipant participants = 1;
   */
  participants: SittingParticipant[];
  /**
   * The list of students that are assigned to this sitting (including guests).
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.Student students = 2;
   */
  students: Student[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsRequest
 */
export interface ListAssessmentSittingParticipantsRequest {
  /**
   * School name to select the assessment participants for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Name of the assessment to select the participants for.
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse
 */
export interface ListAssessmentSittingParticipantsResponse {
  /**
   * The list of participants.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse.SittingParticipant participants = 1;
   */
  participants: ListAssessmentSittingParticipantsResponse_SittingParticipant[];
  /**
   * The list of guest students that are listed in the participants.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.Student students = 2;
   */
  students: Student[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse.SittingParticipant
 */
export interface ListAssessmentSittingParticipantsResponse_SittingParticipant {
  /**
   * The subject of the participant.
   *
   * @generated from protobuf field: string participant_subject = 1;
   */
  participantSubject: string;
  /**
   * The name of the sitting the participant is in.
   *
   * @generated from protobuf field: string sitting_name = 2;
   */
  sittingName: string;
  /**
   * The timestamp when the participant was created.
   *
   * @generated from protobuf field: google.protobuf.Timestamp created_timestamp = 3;
   */
  createdTimestamp?: Timestamp;
  /**
   * The timestamp when the participants result was exported.
   *
   * @generated from protobuf field: google.protobuf.Timestamp exported_timestamp = 4;
   */
  exportedTimestamp?: Timestamp;
  /**
   * Student ID the result was exported to if any.
   *
   * @generated from protobuf field: string exported_student_id = 5;
   */
  exportedStudentId: string;
  /**
   * Whether or not this participant should be ignored when exporting results.
   *
   * @generated from protobuf field: bool ignore_result = 6;
   */
  ignoreResult: boolean;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingPackagesRequest
 */
export interface ListAssessmentSittingPackagesRequest {
  /**
   * School name to select the assessment participants for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Name of the assessment to select the participants for.
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse
 */
export interface ListAssessmentSittingPackagesResponse {
  /**
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse.StudentPackage packages = 1;
   */
  packages: ListAssessmentSittingPackagesResponse_StudentPackage[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse.StudentPackage
 */
export interface ListAssessmentSittingPackagesResponse_StudentPackage {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: sparx.packageactivity.v1.Package package = 2;
   */
  package?: Package;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.WatchSittingRequest
 */
export interface WatchSittingRequest {
  /**
   * The name of the sitting to watch.
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
  /**
   * As a student, the Session ID from the GetSittingSession request
   * should be provided.
   *
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.JoinRequest
 */
export interface JoinRequest {
  /**
   * @generated from protobuf field: string request_id = 1;
   */
  requestId: string;
  /**
   * @generated from protobuf field: string given_name = 2;
   */
  givenName: string;
  /**
   * @generated from protobuf field: string family_name = 3;
   */
  familyName: string;
  /**
   * @generated from protobuf field: google.type.Date date_of_birth = 4;
   */
  dateOfBirth?: Date;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.WatchSittingResponse
 */
export interface WatchSittingResponse {
  /**
   * The timestamp of the response.
   *
   * @generated from protobuf field: google.protobuf.Timestamp now = 1;
   */
  now?: Timestamp;
  /**
   * The sitting that was updated.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.Sitting sitting = 2;
   */
  sitting?: Sitting;
  /**
   * Updated states of participants in the sitting. This will also include
   * participants that join.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.SittingParticipantState participant_states = 3;
   */
  participantStates: SittingParticipantState[];
  /**
   * The participants that were removed from the sitting (since the watcher began)
   *
   * @generated from protobuf field: repeated string removed_participants = 4;
   */
  removedParticipants: string[];
  /**
   * If a student, this will contain the list of packages that were voided for the student.
   *
   * @generated from protobuf field: repeated string voided_package_names = 5;
   */
  voidedPackageNames: string[];
  /**
   * If teacher, will contain list of join requests that have been made.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.JoinRequest join_requests = 6;
   */
  joinRequests: JoinRequest[];
  /**
   * List of removed join requests (since the watcher began)
   *
   * @generated from protobuf field: repeated string removed_join_requests = 7;
   */
  removedJoinRequests: string[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest
 */
export interface UpdateSittingRequest {
  /**
   * The name of the sitting to update.
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'start';
        /**
         * @generated from protobuf field: sparx.assessment.sitting.v1.UpdateSittingRequest.Start start = 2;
         */
        start: UpdateSittingRequest_Start;
      }
    | {
        oneofKind: 'end';
        /**
         * @generated from protobuf field: sparx.assessment.sitting.v1.UpdateSittingRequest.End end = 3;
         */
        end: UpdateSittingRequest_End;
      }
    | {
        oneofKind: 'updatePause';
        /**
         * @generated from protobuf field: sparx.assessment.sitting.v1.UpdateSittingRequest.UpdatePauseState update_pause = 4;
         */
        updatePause: UpdateSittingRequest_UpdatePauseState;
      }
    | {
        oneofKind: 'respondJoin';
        /**
         * @generated from protobuf field: sparx.assessment.sitting.v1.UpdateSittingRequest.RespondJoin respond_join = 5;
         */
        respondJoin: UpdateSittingRequest_RespondJoin;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest.Start
 */
export interface UpdateSittingRequest_Start {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest.End
 */
export interface UpdateSittingRequest_End {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest.UpdatePauseState
 */
export interface UpdateSittingRequest_UpdatePauseState {
  /**
   * @generated from protobuf field: sparx.assessment.sitting.v1.PauseState pause_state = 1;
   */
  pauseState: PauseState;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest.RespondJoin
 */
export interface UpdateSittingRequest_RespondJoin {
  /**
   * @generated from protobuf field: string request_id = 1;
   */
  requestId: string;
  /**
   * @generated from protobuf field: bool accept = 2;
   */
  accept: boolean;
  /**
   * @generated from protobuf field: string subject = 3;
   */
  subject: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingResponse
 */
export interface UpdateSittingResponse {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.LinkStudentsRequest
 */
export interface LinkStudentsRequest {
  /**
   * The school to update the links in.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * List of links to update
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.LinkStudentsRequest.Link links = 2;
   */
  links: LinkStudentsRequest_Link[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.LinkStudentsRequest.Link
 */
export interface LinkStudentsRequest_Link {
  /**
   * Subject of the student to link.
   *
   * @generated from protobuf field: string subject = 1;
   */
  subject: string;
  /**
   * The student to link the subject to.
   *
   * @generated from protobuf field: string student_subject = 2;
   */
  studentSubject: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.LinkStudentsResponse
 */
export interface LinkStudentsResponse {
  /**
   * New state of the students that were updated.
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.Student students = 1;
   */
  students: Student[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UploadSittingResultsRequest
 */
export interface UploadSittingResultsRequest {
  /**
   * The name of the sitting to upload results for.
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UploadSittingResultsResponse
 */
export interface UploadSittingResultsResponse {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ExportResultsRequest
 */
export interface ExportResultsRequest {
  /**
   * The name of the school to export the results for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Results to export
   *
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.ExportResultsRequest.Result results = 2;
   */
  results: ExportResultsRequest_Result[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ExportResultsRequest.Result
 */
export interface ExportResultsRequest_Result {
  /**
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
  /**
   * @generated from protobuf field: string participant_name = 2;
   */
  participantName: string;
  /**
   * @generated from protobuf field: string package_name = 3;
   */
  packageName: string;
  /**
   * @generated from protobuf field: sparx.assessment.sitting.v1.ExportResultsRequest.Choice choice = 4;
   */
  choice: ExportResultsRequest_Choice;
}
/**
 * @generated from protobuf enum sparx.assessment.sitting.v1.ExportResultsRequest.Choice
 */
export enum ExportResultsRequest_Choice {
  /**
   * @generated from protobuf enum value: CHOICE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: CHOICE_EXPORT = 1;
   */
  EXPORT = 1,
  /**
   * @generated from protobuf enum value: CHOICE_IGNORE = 2;
   */
  IGNORE = 2,
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ExportResultsResponse
 */
export interface ExportResultsResponse {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListUploadedResultsRequest
 */
export interface ListUploadedResultsRequest {
  /**
   * Name of the school to select the uploaded packages for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The name of the assessment.
   *
   * @generated from protobuf field: string assessment_name = 2;
   */
  assessmentName: string;
  /**
   * List of student names to request the results for.
   *
   * @generated from protobuf field: repeated string student_names = 3;
   */
  studentNames: string[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.ListUploadedResultsResponse
 */
export interface ListUploadedResultsResponse {
  /**
   * @generated from protobuf field: repeated sparx.assessment.sitting.v1.UploadedAssessmentResult uploaded_results = 1;
   */
  uploadedResults: UploadedAssessmentResult[];
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantRequest
 */
export interface UpdateSittingParticipantRequest {
  /**
   * The name of the sitting to update.
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
  /**
   * The subject of the participant to update.
   *
   * @generated from protobuf field: string participant_subject = 2;
   */
  participantSubject: string;
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'remove';
        /**
         * @generated from protobuf field: sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.Remove remove = 3;
         */
        remove: UpdateSittingParticipantRequest_Remove;
      }
    | {
        oneofKind: 'voidPackages';
        /**
         * @generated from protobuf field: sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.VoidPackages void_packages = 4;
         */
        voidPackages: UpdateSittingParticipantRequest_VoidPackages;
      }
    | {
        oneofKind: 'editDetails';
        /**
         * @generated from protobuf field: sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.EditDetails edit_details = 5;
         */
        editDetails: UpdateSittingParticipantRequest_EditDetails;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.Remove
 */
export interface UpdateSittingParticipantRequest_Remove {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.VoidPackages
 */
export interface UpdateSittingParticipantRequest_VoidPackages {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.EditDetails
 */
export interface UpdateSittingParticipantRequest_EditDetails {
  /**
   * Editable guest student details.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.Student student = 1;
   */
  student?: Student;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantResponse
 */
export interface UpdateSittingParticipantResponse {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.GetSittingSessionRequest
 */
export interface GetSittingSessionRequest {
  /**
   * Name of the sitting to rejoin.
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.GetSittingSessionResponse
 */
export interface GetSittingSessionResponse {
  /**
   * Session ID to use during sitting session.
   *
   * @generated from protobuf field: string session_id = 1;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantStateRequest
 */
export interface UpdateSittingParticipantStateRequest {
  /**
   * The name of the sitting to update.
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
  /**
   * The new reported state of the participant.
   *
   * @generated from protobuf field: sparx.assessment.sitting.v1.SittingParticipantReportedState state = 2;
   */
  state?: SittingParticipantReportedState;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantStateResponse
 */
export interface UpdateSittingParticipantStateResponse {}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.GetSittingParticipantPackagesRequest
 */
export interface GetSittingParticipantPackagesRequest {
  /**
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
}
/**
 * @generated from protobuf message sparx.assessment.sitting.v1.GetSittingParticipantPackagesResponse
 */
export interface GetSittingParticipantPackagesResponse {
  /**
   * @generated from protobuf field: repeated sparx.packageactivity.v1.Package packages = 1;
   */
  packages: Package[];
}
/**
 * @generated from protobuf enum sparx.assessment.sitting.v1.SittingDeliveryState
 */
export enum SittingDeliveryState {
  /**
   * The state of the sitting is unknown.
   *
   * @generated from protobuf enum value: SITTING_DELIVERY_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * The sitting is scheduled.
   *
   * @generated from protobuf enum value: SITTING_DELIVERY_STATE_SCHEDULED = 1;
   */
  SCHEDULED = 1,
  /**
   * The sitting is in progress.
   *
   * @generated from protobuf enum value: SITTING_DELIVERY_STATE_IN_PROGRESS = 2;
   */
  IN_PROGRESS = 2,
  /**
   * The sitting is completed.
   *
   * @generated from protobuf enum value: SITTING_DELIVERY_STATE_COMPLETED = 3;
   */
  COMPLETED = 3,
  /**
   * The sitting is cancelled.
   *
   * @generated from protobuf enum value: SITTING_DELIVERY_STATE_CANCELLED = 4;
   */
  CANCELLED = 4,
}
/**
 * @generated from protobuf enum sparx.assessment.sitting.v1.PauseState
 */
export enum PauseState {
  /**
   * The state of the pause is unknown.
   *
   * @generated from protobuf enum value: PAUSE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * The sitting is not paused.
   *
   * @generated from protobuf enum value: PAUSE_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,
  /**
   * The sitting is paused.
   *
   * @generated from protobuf enum value: PAUSE_STATE_PAUSED = 2;
   */
  PAUSED = 2,
}
/**
 * @generated from protobuf enum sparx.assessment.sitting.v1.StudentActiveState
 */
export enum StudentActiveState {
  /**
   * @generated from protobuf enum value: STUDENT_ACTIVE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STUDENT_ACTIVE_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,
  /**
   * @generated from protobuf enum value: STUDENT_ACTIVE_STATE_PASSIVE = 2;
   */
  PASSIVE = 2,
  /**
   * @generated from protobuf enum value: STUDENT_ACTIVE_STATE_HIDDEN = 3;
   */
  HIDDEN = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class Sitting$Type extends MessageType<Sitting> {
  constructor() {
    super('sparx.assessment.sitting.v1.Sitting', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'sitting_group_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 9,
        name: 'student_title',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'uk_yeargroup',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'join_code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'created_timestamp', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'state', kind: 'message', T: () => SittingState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.Sitting
 */
export const Sitting = new Sitting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SittingGroup$Type extends MessageType<SittingGroup> {
  constructor() {
    super('sparx.assessment.sitting.v1.SittingGroup', [
      {
        no: 1,
        name: 'sitting_group_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'manual_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.SittingGroup
 */
export const SittingGroup = new SittingGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseEvent$Type extends MessageType<PauseEvent> {
  constructor() {
    super('sparx.assessment.sitting.v1.PauseEvent', [
      {
        no: 1,
        name: 'state',
        kind: 'enum',
        T: () => [
          'sparx.assessment.sitting.v1.PauseState',
          PauseState,
          'PAUSE_STATE_',
        ],
      },
      { no: 2, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'actor_subject',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.PauseEvent
 */
export const PauseEvent = new PauseEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SittingState$Type extends MessageType<SittingState> {
  constructor() {
    super('sparx.assessment.sitting.v1.SittingState', [
      {
        no: 1,
        name: 'state',
        kind: 'enum',
        T: () => [
          'sparx.assessment.sitting.v1.SittingDeliveryState',
          SittingDeliveryState,
          'SITTING_DELIVERY_STATE_',
        ],
      },
      {
        no: 2,
        name: 'pause_state',
        kind: 'enum',
        T: () => [
          'sparx.assessment.sitting.v1.PauseState',
          PauseState,
          'PAUSE_STATE_',
        ],
      },
      { no: 3, name: 'start_timestamp', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'end_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'pause_events',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PauseEvent,
      },
      {
        no: 6,
        name: 'processed_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.SittingState
 */
export const SittingState = new SittingState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SittingParticipant$Type extends MessageType<SittingParticipant> {
  constructor() {
    super('sparx.assessment.sitting.v1.SittingParticipant', [
      {
        no: 1,
        name: 'participant_subject',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'created_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'exported_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.SittingParticipant
 */
export const SittingParticipant = new SittingParticipant$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SittingParticipantReportedState$Type extends MessageType<SittingParticipantReportedState> {
  constructor() {
    super('sparx.assessment.sitting.v1.SittingParticipantReportedState', [
      {
        no: 1,
        name: 'current_task_item_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'current_activity_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'active_state',
        kind: 'enum',
        T: () => [
          'sparx.assessment.sitting.v1.StudentActiveState',
          StudentActiveState,
          'STUDENT_ACTIVE_STATE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.SittingParticipantReportedState
 */
export const SittingParticipantReportedState =
  new SittingParticipantReportedState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SittingParticipantState$Type extends MessageType<SittingParticipantState> {
  constructor() {
    super('sparx.assessment.sitting.v1.SittingParticipantState', [
      {
        no: 1,
        name: 'participant_subject',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'last_seen', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'last_progressed', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'reported_state',
        kind: 'message',
        T: () => SittingParticipantReportedState,
      },
      {
        no: 5,
        name: 'voided_package_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.SittingParticipantState
 */
export const SittingParticipantState = new SittingParticipantState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SittingPackageContents$Type extends MessageType<SittingPackageContents> {
  constructor() {
    super('sparx.assessment.sitting.v1.SittingPackageContents', [
      {
        no: 1,
        name: 'delivery_method',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'static_contents',
        kind: 'message',
        oneof: 'spec',
        T: () => Package_Contents,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.SittingPackageContents
 */
export const SittingPackageContents = new SittingPackageContents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Student$Type extends MessageType<Student> {
  constructor() {
    super('sparx.assessment.sitting.v1.Student', [
      { no: 1, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'date_of_birth', kind: 'message', T: () => Date },
      {
        no: 5,
        name: 'linked_student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.Student
 */
export const Student = new Student$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SittingEndedEvent$Type extends MessageType<SittingEndedEvent> {
  constructor() {
    super('sparx.assessment.sitting.v1.SittingEndedEvent', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.SittingEndedEvent
 */
export const SittingEndedEvent = new SittingEndedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageCompletedEvent$Type extends MessageType<PackageCompletedEvent> {
  constructor() {
    super('sparx.assessment.sitting.v1.PackageCompletedEvent', [
      {
        no: 1,
        name: 'package_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.PackageCompletedEvent
 */
export const PackageCompletedEvent = new PackageCompletedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadedAssessmentResult$Type extends MessageType<UploadedAssessmentResult> {
  constructor() {
    super('sparx.assessment.sitting.v1.UploadedAssessmentResult', [
      {
        no: 1,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'package_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'uploaded_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 5, name: 'created_timestamp', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'package', kind: 'message', T: () => Package },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UploadedAssessmentResult
 */
export const UploadedAssessmentResult = new UploadedAssessmentResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSittingsRequest$Type extends MessageType<ListSittingsRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListSittingsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'ended',
        kind: 'scalar',
        oneof: 'filter',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'assessment_name',
        kind: 'scalar',
        oneof: 'filter',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListSittingsRequest
 */
export const ListSittingsRequest = new ListSittingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSittingsResponse$Type extends MessageType<ListSittingsResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListSittingsResponse', [
      {
        no: 1,
        name: 'sittings',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ListSittingsResponse_SittingData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListSittingsResponse
 */
export const ListSittingsResponse = new ListSittingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSittingsResponse_SittingData$Type extends MessageType<ListSittingsResponse_SittingData> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListSittingsResponse.SittingData', [
      { no: 1, name: 'sitting', kind: 'message', T: () => Sitting },
      {
        no: 2,
        name: 'participant_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'exported_participant_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 4, name: 'sitting_group', kind: 'message', T: () => SittingGroup },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListSittingsResponse.SittingData
 */
export const ListSittingsResponse_SittingData =
  new ListSittingsResponse_SittingData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSittingRequest$Type extends MessageType<CreateSittingRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.CreateSittingRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'uk_year_group',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CreateSittingRequest_StudentGroup,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.CreateSittingRequest
 */
export const CreateSittingRequest = new CreateSittingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSittingRequest_StudentGroup$Type extends MessageType<CreateSittingRequest_StudentGroup> {
  constructor() {
    super('sparx.assessment.sitting.v1.CreateSittingRequest.StudentGroup', [
      {
        no: 1,
        name: 'manual_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.CreateSittingRequest.StudentGroup
 */
export const CreateSittingRequest_StudentGroup =
  new CreateSittingRequest_StudentGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSittingResponse$Type extends MessageType<CreateSittingResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.CreateSittingResponse', [
      {
        no: 1,
        name: 'sittings',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Sitting,
      },
      {
        no: 2,
        name: 'sitting_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SittingGroup,
      },
      {
        no: 3,
        name: 'guest_teacher_tokens',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.CreateSittingResponse
 */
export const CreateSittingResponse = new CreateSittingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSittingParticipantsRequest$Type extends MessageType<ListSittingParticipantsRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListSittingParticipantsRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListSittingParticipantsRequest
 */
export const ListSittingParticipantsRequest =
  new ListSittingParticipantsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSittingParticipantsResponse$Type extends MessageType<ListSittingParticipantsResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListSittingParticipantsResponse', [
      {
        no: 1,
        name: 'participants',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SittingParticipant,
      },
      {
        no: 2,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListSittingParticipantsResponse
 */
export const ListSittingParticipantsResponse =
  new ListSittingParticipantsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentSittingParticipantsRequest$Type extends MessageType<ListAssessmentSittingParticipantsRequest> {
  constructor() {
    super(
      'sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsRequest',
      [
        {
          no: 1,
          name: 'school_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'assessment_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsRequest
 */
export const ListAssessmentSittingParticipantsRequest =
  new ListAssessmentSittingParticipantsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentSittingParticipantsResponse$Type extends MessageType<ListAssessmentSittingParticipantsResponse> {
  constructor() {
    super(
      'sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse',
      [
        {
          no: 1,
          name: 'participants',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => ListAssessmentSittingParticipantsResponse_SittingParticipant,
        },
        {
          no: 2,
          name: 'students',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => Student,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse
 */
export const ListAssessmentSittingParticipantsResponse =
  new ListAssessmentSittingParticipantsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentSittingParticipantsResponse_SittingParticipant$Type extends MessageType<ListAssessmentSittingParticipantsResponse_SittingParticipant> {
  constructor() {
    super(
      'sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse.SittingParticipant',
      [
        {
          no: 1,
          name: 'participant_subject',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'sitting_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 3,
          name: 'created_timestamp',
          kind: 'message',
          T: () => Timestamp,
        },
        {
          no: 4,
          name: 'exported_timestamp',
          kind: 'message',
          T: () => Timestamp,
        },
        {
          no: 5,
          name: 'exported_student_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 6,
          name: 'ignore_result',
          kind: 'scalar',
          T: 8 /*ScalarType.BOOL*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse.SittingParticipant
 */
export const ListAssessmentSittingParticipantsResponse_SittingParticipant =
  new ListAssessmentSittingParticipantsResponse_SittingParticipant$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentSittingPackagesRequest$Type extends MessageType<ListAssessmentSittingPackagesRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListAssessmentSittingPackagesRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingPackagesRequest
 */
export const ListAssessmentSittingPackagesRequest =
  new ListAssessmentSittingPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentSittingPackagesResponse$Type extends MessageType<ListAssessmentSittingPackagesResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ListAssessmentSittingPackagesResponse_StudentPackage,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse
 */
export const ListAssessmentSittingPackagesResponse =
  new ListAssessmentSittingPackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssessmentSittingPackagesResponse_StudentPackage$Type extends MessageType<ListAssessmentSittingPackagesResponse_StudentPackage> {
  constructor() {
    super(
      'sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse.StudentPackage',
      [
        {
          no: 1,
          name: 'student_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 2, name: 'package', kind: 'message', T: () => Package },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse.StudentPackage
 */
export const ListAssessmentSittingPackagesResponse_StudentPackage =
  new ListAssessmentSittingPackagesResponse_StudentPackage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WatchSittingRequest$Type extends MessageType<WatchSittingRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.WatchSittingRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.WatchSittingRequest
 */
export const WatchSittingRequest = new WatchSittingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinRequest$Type extends MessageType<JoinRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.JoinRequest', [
      { no: 1, name: 'request_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'date_of_birth', kind: 'message', T: () => Date },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.JoinRequest
 */
export const JoinRequest = new JoinRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WatchSittingResponse$Type extends MessageType<WatchSittingResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.WatchSittingResponse', [
      { no: 1, name: 'now', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'sitting', kind: 'message', T: () => Sitting },
      {
        no: 3,
        name: 'participant_states',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SittingParticipantState,
      },
      {
        no: 4,
        name: 'removed_participants',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'voided_package_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'join_requests',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => JoinRequest,
      },
      {
        no: 7,
        name: 'removed_join_requests',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.WatchSittingResponse
 */
export const WatchSittingResponse = new WatchSittingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingRequest$Type extends MessageType<UpdateSittingRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'start',
        kind: 'message',
        oneof: 'action',
        T: () => UpdateSittingRequest_Start,
      },
      {
        no: 3,
        name: 'end',
        kind: 'message',
        oneof: 'action',
        T: () => UpdateSittingRequest_End,
      },
      {
        no: 4,
        name: 'update_pause',
        kind: 'message',
        oneof: 'action',
        T: () => UpdateSittingRequest_UpdatePauseState,
      },
      {
        no: 5,
        name: 'respond_join',
        kind: 'message',
        oneof: 'action',
        T: () => UpdateSittingRequest_RespondJoin,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest
 */
export const UpdateSittingRequest = new UpdateSittingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingRequest_Start$Type extends MessageType<UpdateSittingRequest_Start> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingRequest.Start', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest.Start
 */
export const UpdateSittingRequest_Start = new UpdateSittingRequest_Start$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingRequest_End$Type extends MessageType<UpdateSittingRequest_End> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingRequest.End', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest.End
 */
export const UpdateSittingRequest_End = new UpdateSittingRequest_End$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingRequest_UpdatePauseState$Type extends MessageType<UpdateSittingRequest_UpdatePauseState> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingRequest.UpdatePauseState', [
      {
        no: 1,
        name: 'pause_state',
        kind: 'enum',
        T: () => [
          'sparx.assessment.sitting.v1.PauseState',
          PauseState,
          'PAUSE_STATE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest.UpdatePauseState
 */
export const UpdateSittingRequest_UpdatePauseState =
  new UpdateSittingRequest_UpdatePauseState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingRequest_RespondJoin$Type extends MessageType<UpdateSittingRequest_RespondJoin> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingRequest.RespondJoin', [
      { no: 1, name: 'request_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'accept', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingRequest.RespondJoin
 */
export const UpdateSittingRequest_RespondJoin =
  new UpdateSittingRequest_RespondJoin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingResponse$Type extends MessageType<UpdateSittingResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingResponse
 */
export const UpdateSittingResponse = new UpdateSittingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkStudentsRequest$Type extends MessageType<LinkStudentsRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.LinkStudentsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'links',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LinkStudentsRequest_Link,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.LinkStudentsRequest
 */
export const LinkStudentsRequest = new LinkStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkStudentsRequest_Link$Type extends MessageType<LinkStudentsRequest_Link> {
  constructor() {
    super('sparx.assessment.sitting.v1.LinkStudentsRequest.Link', [
      { no: 1, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_subject',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.LinkStudentsRequest.Link
 */
export const LinkStudentsRequest_Link = new LinkStudentsRequest_Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkStudentsResponse$Type extends MessageType<LinkStudentsResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.LinkStudentsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.LinkStudentsResponse
 */
export const LinkStudentsResponse = new LinkStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadSittingResultsRequest$Type extends MessageType<UploadSittingResultsRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.UploadSittingResultsRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UploadSittingResultsRequest
 */
export const UploadSittingResultsRequest =
  new UploadSittingResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadSittingResultsResponse$Type extends MessageType<UploadSittingResultsResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.UploadSittingResultsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UploadSittingResultsResponse
 */
export const UploadSittingResultsResponse =
  new UploadSittingResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExportResultsRequest$Type extends MessageType<ExportResultsRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.ExportResultsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ExportResultsRequest_Result,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ExportResultsRequest
 */
export const ExportResultsRequest = new ExportResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExportResultsRequest_Result$Type extends MessageType<ExportResultsRequest_Result> {
  constructor() {
    super('sparx.assessment.sitting.v1.ExportResultsRequest.Result', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'participant_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'package_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'choice',
        kind: 'enum',
        T: () => [
          'sparx.assessment.sitting.v1.ExportResultsRequest.Choice',
          ExportResultsRequest_Choice,
          'CHOICE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ExportResultsRequest.Result
 */
export const ExportResultsRequest_Result =
  new ExportResultsRequest_Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExportResultsResponse$Type extends MessageType<ExportResultsResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.ExportResultsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ExportResultsResponse
 */
export const ExportResultsResponse = new ExportResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUploadedResultsRequest$Type extends MessageType<ListUploadedResultsRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListUploadedResultsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assessment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListUploadedResultsRequest
 */
export const ListUploadedResultsRequest = new ListUploadedResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUploadedResultsResponse$Type extends MessageType<ListUploadedResultsResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.ListUploadedResultsResponse', [
      {
        no: 1,
        name: 'uploaded_results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UploadedAssessmentResult,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.ListUploadedResultsResponse
 */
export const ListUploadedResultsResponse =
  new ListUploadedResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingParticipantRequest$Type extends MessageType<UpdateSittingParticipantRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingParticipantRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'participant_subject',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'remove',
        kind: 'message',
        oneof: 'action',
        T: () => UpdateSittingParticipantRequest_Remove,
      },
      {
        no: 4,
        name: 'void_packages',
        kind: 'message',
        oneof: 'action',
        T: () => UpdateSittingParticipantRequest_VoidPackages,
      },
      {
        no: 5,
        name: 'edit_details',
        kind: 'message',
        oneof: 'action',
        T: () => UpdateSittingParticipantRequest_EditDetails,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantRequest
 */
export const UpdateSittingParticipantRequest =
  new UpdateSittingParticipantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingParticipantRequest_Remove$Type extends MessageType<UpdateSittingParticipantRequest_Remove> {
  constructor() {
    super(
      'sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.Remove',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.Remove
 */
export const UpdateSittingParticipantRequest_Remove =
  new UpdateSittingParticipantRequest_Remove$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingParticipantRequest_VoidPackages$Type extends MessageType<UpdateSittingParticipantRequest_VoidPackages> {
  constructor() {
    super(
      'sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.VoidPackages',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.VoidPackages
 */
export const UpdateSittingParticipantRequest_VoidPackages =
  new UpdateSittingParticipantRequest_VoidPackages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingParticipantRequest_EditDetails$Type extends MessageType<UpdateSittingParticipantRequest_EditDetails> {
  constructor() {
    super(
      'sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.EditDetails',
      [{ no: 1, name: 'student', kind: 'message', T: () => Student }],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantRequest.EditDetails
 */
export const UpdateSittingParticipantRequest_EditDetails =
  new UpdateSittingParticipantRequest_EditDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingParticipantResponse$Type extends MessageType<UpdateSittingParticipantResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingParticipantResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantResponse
 */
export const UpdateSittingParticipantResponse =
  new UpdateSittingParticipantResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSittingSessionRequest$Type extends MessageType<GetSittingSessionRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.GetSittingSessionRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.GetSittingSessionRequest
 */
export const GetSittingSessionRequest = new GetSittingSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSittingSessionResponse$Type extends MessageType<GetSittingSessionResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.GetSittingSessionResponse', [
      { no: 1, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.GetSittingSessionResponse
 */
export const GetSittingSessionResponse = new GetSittingSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingParticipantStateRequest$Type extends MessageType<UpdateSittingParticipantStateRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.UpdateSittingParticipantStateRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'state',
        kind: 'message',
        T: () => SittingParticipantReportedState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantStateRequest
 */
export const UpdateSittingParticipantStateRequest =
  new UpdateSittingParticipantStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSittingParticipantStateResponse$Type extends MessageType<UpdateSittingParticipantStateResponse> {
  constructor() {
    super(
      'sparx.assessment.sitting.v1.UpdateSittingParticipantStateResponse',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.UpdateSittingParticipantStateResponse
 */
export const UpdateSittingParticipantStateResponse =
  new UpdateSittingParticipantStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSittingParticipantPackagesRequest$Type extends MessageType<GetSittingParticipantPackagesRequest> {
  constructor() {
    super('sparx.assessment.sitting.v1.GetSittingParticipantPackagesRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.GetSittingParticipantPackagesRequest
 */
export const GetSittingParticipantPackagesRequest =
  new GetSittingParticipantPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSittingParticipantPackagesResponse$Type extends MessageType<GetSittingParticipantPackagesResponse> {
  constructor() {
    super('sparx.assessment.sitting.v1.GetSittingParticipantPackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.sitting.v1.GetSittingParticipantPackagesResponse
 */
export const GetSittingParticipantPackagesResponse =
  new GetSittingParticipantPackagesResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.assessment.sitting.v1.Sittings
 */
export const Sittings = new ServiceType(
  'sparx.assessment.sitting.v1.Sittings',
  [
    {
      name: 'ListSittings',
      options: {
        'sparx.api.auth': {
          action: 'list',
          resource: 'assessments:sitting',
          domain: '{school_name}',
        },
      },
      I: ListSittingsRequest,
      O: ListSittingsResponse,
    },
    {
      name: 'CreateSitting',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:sitting',
          domain: '{school_name}',
        },
      },
      I: CreateSittingRequest,
      O: CreateSittingResponse,
    },
    {
      name: 'ListSittingParticipants',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessments:participant',
          domain: '{sitting_name|parent}',
        },
      },
      I: ListSittingParticipantsRequest,
      O: ListSittingParticipantsResponse,
    },
    {
      name: 'ListAssessmentSittingParticipants',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessments:participant',
          domain: '{school_name}',
        },
      },
      I: ListAssessmentSittingParticipantsRequest,
      O: ListAssessmentSittingParticipantsResponse,
    },
    {
      name: 'ListAssessmentSittingPackages',
      options: {
        'sparx.api.auth': {
          action: 'list',
          resource: 'assessments:sitting',
          domain: '{school_name}',
        },
      },
      I: ListAssessmentSittingPackagesRequest,
      O: ListAssessmentSittingPackagesResponse,
    },
    {
      name: 'WatchSitting',
      serverStreaming: true,
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessments:sitting',
          domain: '{sitting_name|parent}',
        },
      },
      I: WatchSittingRequest,
      O: WatchSittingResponse,
    },
    {
      name: 'UpdateSitting',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:sitting',
          domain: '{sitting_name|parent}',
        },
      },
      I: UpdateSittingRequest,
      O: UpdateSittingResponse,
    },
    {
      name: 'LinkStudents',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:sitting',
          domain: '{school_name}',
        },
      },
      I: LinkStudentsRequest,
      O: LinkStudentsResponse,
    },
    {
      name: 'UploadSittingResults',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:sitting',
          domain: '{sitting_name|parent}',
        },
      },
      I: UploadSittingResultsRequest,
      O: UploadSittingResultsResponse,
    },
    {
      name: 'ExportResults',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:sitting',
          domain: '{school_name}',
        },
      },
      I: ExportResultsRequest,
      O: ExportResultsResponse,
    },
    {
      name: 'ListUploadedResults',
      options: {
        'sparx.api.auth': {
          action: 'list',
          resource: 'assessments:sitting',
          domain: '{school_name}',
        },
      },
      I: ListUploadedResultsRequest,
      O: ListUploadedResultsResponse,
    },
    {
      name: 'UpdateSittingParticipant',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:participant',
          domain: '{sitting_name|parent}',
        },
      },
      I: UpdateSittingParticipantRequest,
      O: UpdateSittingParticipantResponse,
    },
    {
      name: 'GetSittingSession',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:user',
          domain: 'assessments',
        },
      },
      I: GetSittingSessionRequest,
      O: GetSittingSessionResponse,
    },
    {
      name: 'UpdateSittingParticipantState',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:user',
          domain: 'assessments',
        },
      },
      I: UpdateSittingParticipantStateRequest,
      O: UpdateSittingParticipantStateResponse,
    },
    {
      name: 'GetSittingParticipantPackages',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'assessments:user',
          domain: 'assessments',
        },
      },
      I: GetSittingParticipantPackagesRequest,
      O: GetSittingParticipantPackagesResponse,
    },
  ],
);

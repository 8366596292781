// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "sparxweb/actsummaries/activity_summaries.proto" (package "sparxweb", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { PackageType } from '../../apis/sparx/packages/v1/spxpkg';
import { TaskItemFlag } from '../../apis/sparx/packages/v1/spxpkg';
import { Timestamp } from '../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparxweb.QuestionActivitySummary
 */
export interface QuestionActivitySummary {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string skillID = 2;
   */
  skillID: string;
  /**
   * @generated from protobuf field: string packageID = 3;
   */
  packageID: string;
  /**
   * @generated from protobuf field: int64 taskIndex = 4;
   */
  taskIndex: number;
  /**
   * @generated from protobuf field: int64 taskItemIndex = 5;
   */
  taskItemIndex: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTime = 6;
   */
  startTime?: Timestamp;
  /**
   * @generated from protobuf field: sparxweb.QuestionResult result = 7;
   */
  result: QuestionResult;
  /**
   * @generated from protobuf field: double totalTime = 8;
   */
  totalTime: number;
  /**
   * @generated from protobuf field: double videoTime = 9;
   */
  videoTime: number;
  /**
   * @generated from protobuf field: bool lesson = 10;
   */
  lesson: boolean;
  /**
   * @generated from protobuf field: string learningUnitID = 11;
   */
  learningUnitID: string;
  /**
   * @generated from protobuf field: string questionID = 12;
   */
  questionID: string;
  /**
   * @generated from protobuf field: string bookworkCode = 13;
   */
  bookworkCode: string;
  /**
   * @generated from protobuf field: string givenAnswer = 14;
   */
  givenAnswer: string;
  /**
   * @generated from protobuf field: string sessionID = 15;
   */
  sessionID: string;
  /**
   * @generated from protobuf field: int64 activityIndex = 16;
   */
  activityIndex: number;
  /**
   * @generated from protobuf field: double timeBeforeFirstVideo = 17;
   */
  timeBeforeFirstVideo: number;
  /**
   * @generated from protobuf field: string partName = 18;
   */
  partName: string;
  /**
   * @generated from protobuf field: repeated sparx.packages.v1.TaskItemFlag taskItemFlags = 19;
   */
  taskItemFlags: TaskItemFlag[];
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType packageType = 20;
   */
  packageType: PackageType;
  /**
   * @generated from protobuf field: string curriculumTopicName = 21;
   */
  curriculumTopicName: string;
  /**
   * @generated from protobuf field: string activityContext = 22;
   */
  activityContext: string;
  /**
   * @generated from protobuf field: map<string, string> hints = 23;
   */
  hints: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparxweb.WACActivitySummary
 */
export interface WACActivitySummary {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string skillID = 2;
   */
  skillID: string;
  /**
   * The PackageID, task index and task item index of the task item the user
   * is being checked on (i.e. the subject of the WAC):
   *
   * @generated from protobuf field: string packageID = 3;
   */
  packageID: string;
  /**
   * @generated from protobuf field: int64 taskIndex = 4;
   */
  taskIndex: number;
  /**
   * @generated from protobuf field: int64 taskItemIndex = 5;
   */
  taskItemIndex: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTime = 6;
   */
  startTime?: Timestamp;
  /**
   * @generated from protobuf field: sparxweb.QuestionResult result = 7;
   */
  result: QuestionResult;
  /**
   * @generated from protobuf field: double totalTime = 8;
   */
  totalTime: number;
  /**
   * @generated from protobuf field: bool lesson = 9;
   */
  lesson: boolean;
  /**
   * @generated from protobuf field: string bookworkCode = 10;
   */
  bookworkCode: string;
  /**
   * @generated from protobuf field: string sessionID = 11;
   */
  sessionID: string;
  /**
   * @generated from protobuf field: int64 activityIndex = 12;
   */
  activityIndex: number;
  /**
   * @generated from protobuf field: bool haventDoneThis = 13;
   */
  haventDoneThis: boolean;
  /**
   * The PackageID, task index and task item index of the task item the user
   * will start after completing the WAC:
   *
   * @generated from protobuf field: string nextPackageID = 14;
   */
  nextPackageID: string;
  /**
   * @generated from protobuf field: int64 nextTaskIndex = 15;
   */
  nextTaskIndex: number;
  /**
   * @generated from protobuf field: int64 nextTaskItemIndex = 16;
   */
  nextTaskItemIndex: number;
  /**
   * The SessionID of the WAC activity itself, rather than the activity being checked
   *
   * @generated from protobuf field: string WACSessionID = 17 [json_name = "WACSessionID"];
   */
  wACSessionID: string;
  /**
   * The Activity index of the WAC activity itself, rather than the activity being checked
   *
   * @generated from protobuf field: int64 WACActivityIndex = 18 [json_name = "WACActivityIndex"];
   */
  wACActivityIndex: number;
  /**
   * The WAC method being used - e.g. "ask_answer_with_pool"
   *
   * @generated from protobuf field: string WACMethod = 19 [json_name = "WACMethod"];
   */
  wACMethod: string;
  /**
   * The WAC tier of the user before the WAC starts, starting with tier 0
   *
   * @generated from protobuf field: int64 WACTier = 20 [json_name = "WACTier"];
   */
  wACTier: number;
  /**
   * The number of answers in the candidate pool before the WAC starts
   *
   * @generated from protobuf field: int64 WACNumCandidates = 21 [json_name = "WACNumCandidates"];
   */
  wACNumCandidates: number;
  /**
   * The provided answer from the student
   *
   * @generated from protobuf field: string GivenAnswer = 22 [json_name = "GivenAnswer"];
   */
  givenAnswer: string;
}
/**
 * @generated from protobuf message sparxweb.GameActivitySummary
 */
export interface GameActivitySummary {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string game = 2;
   */
  game: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTime = 3;
   */
  startTime?: Timestamp;
  /**
   * @generated from protobuf field: sparxweb.QuestionResult result = 4;
   */
  result: QuestionResult;
  /**
   * @generated from protobuf field: double totalTime = 5;
   */
  totalTime: number;
  /**
   * @generated from protobuf field: bool lesson = 6;
   */
  lesson: boolean;
  /**
   * @generated from protobuf field: string sessionID = 7;
   */
  sessionID: string;
  /**
   * @generated from protobuf field: int64 activityIndex = 8;
   */
  activityIndex: number;
}
/**
 * @generated from protobuf message sparxweb.VideoActivitySummary
 */
export interface VideoActivitySummary {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string videoID = 2;
   */
  videoID: string;
  /**
   * @generated from protobuf field: string packageID = 3;
   */
  packageID: string;
  /**
   * @generated from protobuf field: int64 taskIndex = 4;
   */
  taskIndex: number;
  /**
   * @generated from protobuf field: int64 taskItemIndex = 5;
   */
  taskItemIndex: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTime = 6;
   */
  startTime?: Timestamp;
  /**
   * @generated from protobuf field: bool completed = 7;
   */
  completed: boolean;
  /**
   * @generated from protobuf field: double totalTime = 8;
   */
  totalTime: number;
  /**
   * @generated from protobuf field: bool lesson = 9;
   */
  lesson: boolean;
  /**
   * @generated from protobuf field: string sessionID = 10;
   */
  sessionID: string;
  /**
   * @generated from protobuf field: int64 activityIndex = 11;
   */
  activityIndex: number;
  /**
   * @generated from protobuf field: repeated sparx.packages.v1.TaskItemFlag taskItemFlags = 12;
   */
  taskItemFlags: TaskItemFlag[];
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType packageType = 13;
   */
  packageType: PackageType;
}
/**
 * @generated from protobuf enum sparxweb.QuestionResult
 */
export enum QuestionResult {
  /**
   * @generated from protobuf enum value: ABORT = 0;
   */
  ABORT = 0,
  /**
   * @generated from protobuf enum value: CORRECT = 1;
   */
  CORRECT = 1,
  /**
   * @generated from protobuf enum value: INCORRECT = 2;
   */
  INCORRECT = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class QuestionActivitySummary$Type extends MessageType<QuestionActivitySummary> {
  constructor() {
    super('sparxweb.QuestionActivitySummary', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'skillID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'taskIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: 'taskItemIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 6, name: 'startTime', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'result',
        kind: 'enum',
        T: () => ['sparxweb.QuestionResult', QuestionResult],
      },
      { no: 8, name: 'totalTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 9, name: 'videoTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 10, name: 'lesson', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 11,
        name: 'learningUnitID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: 'questionID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: 'bookworkCode',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 14,
        name: 'givenAnswer',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 15, name: 'sessionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 16,
        name: 'activityIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 17,
        name: 'timeBeforeFirstVideo',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      { no: 18, name: 'partName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 19,
        name: 'taskItemFlags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.packages.v1.TaskItemFlag',
          TaskItemFlag,
          'TASK_ITEM_FLAG_',
        ],
      },
      {
        no: 20,
        name: 'packageType',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      {
        no: 21,
        name: 'curriculumTopicName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 22,
        name: 'activityContext',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 23,
        name: 'hints',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.QuestionActivitySummary
 */
export const QuestionActivitySummary = new QuestionActivitySummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WACActivitySummary$Type extends MessageType<WACActivitySummary> {
  constructor() {
    super('sparxweb.WACActivitySummary', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'skillID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'taskIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: 'taskItemIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 6, name: 'startTime', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'result',
        kind: 'enum',
        T: () => ['sparxweb.QuestionResult', QuestionResult],
      },
      { no: 8, name: 'totalTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 9, name: 'lesson', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 10,
        name: 'bookworkCode',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 11, name: 'sessionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 12,
        name: 'activityIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 13,
        name: 'haventDoneThis',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 14,
        name: 'nextPackageID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 15,
        name: 'nextTaskIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 16,
        name: 'nextTaskItemIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 17,
        name: 'WACSessionID',
        kind: 'scalar',
        jsonName: 'WACSessionID',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 18,
        name: 'WACActivityIndex',
        kind: 'scalar',
        jsonName: 'WACActivityIndex',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 19,
        name: 'WACMethod',
        kind: 'scalar',
        jsonName: 'WACMethod',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 20,
        name: 'WACTier',
        kind: 'scalar',
        jsonName: 'WACTier',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 21,
        name: 'WACNumCandidates',
        kind: 'scalar',
        jsonName: 'WACNumCandidates',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 22,
        name: 'GivenAnswer',
        kind: 'scalar',
        jsonName: 'GivenAnswer',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.WACActivitySummary
 */
export const WACActivitySummary = new WACActivitySummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameActivitySummary$Type extends MessageType<GameActivitySummary> {
  constructor() {
    super('sparxweb.GameActivitySummary', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'game', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'startTime', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'result',
        kind: 'enum',
        T: () => ['sparxweb.QuestionResult', QuestionResult],
      },
      { no: 5, name: 'totalTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 6, name: 'lesson', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 7, name: 'sessionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'activityIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.GameActivitySummary
 */
export const GameActivitySummary = new GameActivitySummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoActivitySummary$Type extends MessageType<VideoActivitySummary> {
  constructor() {
    super('sparxweb.VideoActivitySummary', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'videoID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'packageID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'taskIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: 'taskItemIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      { no: 6, name: 'startTime', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'completed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'totalTime', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 9, name: 'lesson', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 10, name: 'sessionID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 11,
        name: 'activityIndex',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 12,
        name: 'taskItemFlags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.packages.v1.TaskItemFlag',
          TaskItemFlag,
          'TASK_ITEM_FLAG_',
        ],
      },
      {
        no: 13,
        name: 'packageType',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparxweb.VideoActivitySummary
 */
export const VideoActivitySummary = new VideoActivitySummary$Type();

import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { ComponentProps } from 'react';

import styles from './Tabs.module.css';

export const Tabs = ({ children, ...props }: ComponentProps<typeof RadixTabs.Root>) => (
  <RadixTabs.Root {...props}>{children}</RadixTabs.Root>
);

interface ListProps extends ComponentProps<typeof RadixTabs.List> {
  /**
   * Will remove bottom padding from the tab list.
   */
  flush?: boolean;
}

const List = ({ children, className, flush, ...props }: ListProps) => (
  <RadixTabs.List
    className={classNames(styles.TabList, flush && styles.TabListFlush, className)}
    {...props}
  >
    {children}
  </RadixTabs.List>
);
Tabs.List = List;

const Trigger = ({ children, className, ...props }: ComponentProps<typeof RadixTabs.Trigger>) => (
  <RadixTabs.Trigger className={classNames(styles.Tab, className)} {...props}>
    {children}
  </RadixTabs.Trigger>
);
Tabs.Trigger = Trigger;

const Controls = ({ children, className, ...rest }: ComponentProps<'div'>) => (
  <div className={classNames(styles.Controls, className)} {...rest}>
    {children}
  </div>
);
Tabs.Controls = Controls;

Tabs.Content = RadixTabs.Content;

import { composeSorts, ISorting, SortDirection, SortFnWithDir } from '../../utils/sorting';
import { IStudentAssessmentFixUpProgress } from './hooks';

type TableSortingFunction = () => SortFnWithDir<IStudentAssessmentFixUpProgress>;

export type TableSorting = ISorting<SortableColumnName, TableSortingFunction>;

export enum SortableColumnName {
  StudentName = 'Student name',
  Class = 'Class',
  Mark = 'Mark',
  FixUpProgress = 'Fix up progress',
  Status = 'Status',
}

const studentNameSort: TableSortingFunction = () => dir => (a, b) => {
  const aName = `${a.lastName}, ${a.firstName}`;
  const bName = `${b.lastName}, ${b.firstName}`;
  return dir * aName.localeCompare(bName);
};

const completionSort: TableSortingFunction = () => dir => (a, b) => {
  // Sort by whether the student has started fix up
  if (a.fixUpStarted && !b.fixUpStarted) {
    return -dir;
  }
  if (!a.fixUpStarted && b.fixUpStarted) {
    return dir;
  }
  if (!a.fixUpStarted && !b.fixUpStarted) {
    return 0;
  }

  // Sort by whether the student had no fix up to do:
  if (a.totalQuestions > 0 && b.totalQuestions === 0) {
    return -dir;
  }
  if (a.totalQuestions === 0 && b.totalQuestions > 0) {
    return dir;
  }
  if (a.totalQuestions === 0 && b.totalQuestions === 0) {
    return 0;
  }

  const aPercent = a.totalQuestions > 0 ? a.questionsComplete / a.totalQuestions : 0;
  const bPercent = b.totalQuestions > 0 ? b.questionsComplete / b.totalQuestions : 0;

  if (aPercent !== bPercent) {
    return dir * (aPercent - bPercent);
  }

  // Sort by whether the student has attempted any questions
  if (a.inProgress && !b.inProgress) {
    return -dir;
  }
  if (!a.inProgress && b.inProgress) {
    return dir;
  }

  // Sort by the number of questions still to do
  return dir * (b.totalQuestions - a.totalQuestions);
};

const classSort: SortFnWithDir<IStudentAssessmentFixUpProgress> = dir => (a, b) =>
  dir *
  a.studentGroupDisplayName.localeCompare(b.studentGroupDisplayName, undefined, { numeric: true });

const assessmentMarkSort: SortFnWithDir<IStudentAssessmentFixUpProgress> = dir => (a, b) =>
  dir * (b.marksAwarded - a.marksAwarded);

const classThenProgressSort: TableSortingFunction = () => dir =>
  composeSorts(
    classSort(dir),
    completionSort()(SortDirection.Ascending),
    studentNameSort()(SortDirection.Ascending),
  );

export const assessmentMarkThenProgressSort: TableSortingFunction = () => dir =>
  composeSorts(
    assessmentMarkSort(dir),
    completionSort()(SortDirection.Ascending),
    studentNameSort()(SortDirection.Ascending),
  );

export const sortFunctions: Record<SortableColumnName, TableSortingFunction> = {
  [SortableColumnName.StudentName]: studentNameSort,
  [SortableColumnName.Class]: classThenProgressSort,
  [SortableColumnName.Mark]: assessmentMarkThenProgressSort,
  [SortableColumnName.FixUpProgress]: completionSort,
  [SortableColumnName.Status]: completionSort,
};

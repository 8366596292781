import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';

import { SortDirection } from '../../utils/sorting';
import styles from './SortingLabel.module.css';

export const getSortingLabel = <T extends string>(
  sortedBy: { sortBy: T; dir: SortDirection },
  onClick: (name: T) => void,
  name: T,
  children: ReactElement | string,
) => (
  <div
    className={classNames(styles.SortingLabel, {
      [styles.SortingLabelActive]: sortedBy.sortBy === name,
      [styles.SortingLabelFlipped]:
        sortedBy.sortBy === name && sortedBy.dir === SortDirection.Descending,
    })}
    onClick={() => onClick?.(name)}
  >
    <FontAwesomeIcon icon={faCaretDown} />
    {children}
  </div>
);

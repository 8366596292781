// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "sparxweb/answer/answer.proto" (package "answer", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message answer.AnswerComponent
 */
export interface AnswerComponent {
  /**
   * @generated from protobuf field: string key = 1;
   */
  key: string;
  /**
   * @generated from protobuf field: string value = 2;
   */
  value: string;
}
/**
 * @generated from protobuf message answer.HumanReadableAnswerComponent
 */
export interface HumanReadableAnswerComponent {
  /**
   * @generated from protobuf field: string key = 1;
   */
  key: string;
  /**
   * @generated from protobuf field: answer.HumanReadableAnswerValue value = 2;
   */
  value?: HumanReadableAnswerValue;
}
/**
 * A collection of potential answers / distractors for a question, grouped by
 * question step.
 *
 * @generated from protobuf message answer.QuestionAnswerOptions
 */
export interface QuestionAnswerOptions {
  /**
   * @generated from protobuf field: repeated answer.QuestionStepAnswerOptions steps = 1;
   */
  steps: QuestionStepAnswerOptions[];
}
/**
 * A collection of potential answers / distractors for a single question step
 * key is answerId, value is HumanReadableAnswerValue.
 *
 * @generated from protobuf message answer.QuestionStepAnswerOptions
 */
export interface QuestionStepAnswerOptions {
  /**
   * @generated from protobuf field: map<string, answer.HumanReadableAnswerValue> options = 1;
   */
  options: {
    [key: string]: HumanReadableAnswerValue;
  };
}
/**
 * How this answer / distractor should be presented to the user.
 *
 * @generated from protobuf message answer.HumanReadableAnswerValue
 */
export interface HumanReadableAnswerValue {
  /**
   * @generated from protobuf oneof: value
   */
  value:
    | {
        oneofKind: 'text';
        /**
         * @generated from protobuf field: string text = 1;
         */
        text: string;
      }
    | {
        oneofKind: 'image';
        /**
         * @generated from protobuf field: string image = 2;
         */
        image: string;
      }
    | {
        oneofKind: undefined;
      };
}
// @generated message type with reflection information, may provide speed optimized methods
class AnswerComponent$Type extends MessageType<AnswerComponent> {
  constructor() {
    super('answer.AnswerComponent', [
      { no: 1, name: 'key', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'value', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message answer.AnswerComponent
 */
export const AnswerComponent = new AnswerComponent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HumanReadableAnswerComponent$Type extends MessageType<HumanReadableAnswerComponent> {
  constructor() {
    super('answer.HumanReadableAnswerComponent', [
      { no: 1, name: 'key', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'value',
        kind: 'message',
        T: () => HumanReadableAnswerValue,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message answer.HumanReadableAnswerComponent
 */
export const HumanReadableAnswerComponent =
  new HumanReadableAnswerComponent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionAnswerOptions$Type extends MessageType<QuestionAnswerOptions> {
  constructor() {
    super('answer.QuestionAnswerOptions', [
      {
        no: 1,
        name: 'steps',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => QuestionStepAnswerOptions,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message answer.QuestionAnswerOptions
 */
export const QuestionAnswerOptions = new QuestionAnswerOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionStepAnswerOptions$Type extends MessageType<QuestionStepAnswerOptions> {
  constructor() {
    super('answer.QuestionStepAnswerOptions', [
      {
        no: 1,
        name: 'options',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => HumanReadableAnswerValue },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message answer.QuestionStepAnswerOptions
 */
export const QuestionStepAnswerOptions = new QuestionStepAnswerOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HumanReadableAnswerValue$Type extends MessageType<HumanReadableAnswerValue> {
  constructor() {
    super('answer.HumanReadableAnswerValue', [
      {
        no: 1,
        name: 'text',
        kind: 'scalar',
        oneof: 'value',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'image',
        kind: 'scalar',
        oneof: 'value',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message answer.HumanReadableAnswerValue
 */
export const HumanReadableAnswerValue = new HumanReadableAnswerValue$Type();

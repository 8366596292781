// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/studentapi/v1/studentapi.proto" (package "sparx.teacherportal.studentapi.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { StudentAPI } from './studentapi';
import type { Empty } from '../../../../../google/protobuf/empty';
import type { BlockStudentRequest } from './studentapi';
import type { GrantPasswordResetResponse } from './studentapi';
import type { GrantPasswordResetRequest } from './studentapi';
import type { SearchStudentsResponse } from './studentapi';
import type { SearchStudentsRequest } from './studentapi';
import type { ListStudentsResponse } from './studentapi';
import type { ListStudentsRequest } from './studentapi';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetSingleStreamSelectionsResponse } from './studentapi';
import type { GetSingleStreamSelectionsRequest } from './studentapi';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.teacherportal.studentapi.v1.StudentAPI
 */
export interface IStudentAPIClient {
  /**
   * GetSingleStreamSelections is a batch endpoint that fetches the stream that a student is working at for a given
   * curriculum. This is used to display what difficulty content will go into the student's homework.
   *
   * @generated from protobuf rpc: GetSingleStreamSelections(sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsRequest) returns (sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsResponse);
   */
  getSingleStreamSelections(
    input: GetSingleStreamSelectionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetSingleStreamSelectionsRequest,
    GetSingleStreamSelectionsResponse
  >;
  /**
   * ListStudents lists all the students in a school.
   *
   * @generated from protobuf rpc: ListStudents(sparx.teacherportal.studentapi.v1.ListStudentsRequest) returns (sparx.teacherportal.studentapi.v1.ListStudentsResponse);
   */
  listStudents(
    input: ListStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentsRequest, ListStudentsResponse>;
  /**
   * @generated from protobuf rpc: SearchStudents(sparx.teacherportal.studentapi.v1.SearchStudentsRequest) returns (sparx.teacherportal.studentapi.v1.SearchStudentsResponse);
   */
  searchStudents(
    input: SearchStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<SearchStudentsRequest, SearchStudentsResponse>;
  /**
   * GrantPasswordReset grants a password reset for some students in a school.
   *
   * @generated from protobuf rpc: GrantPasswordReset(sparx.teacherportal.studentapi.v1.GrantPasswordResetRequest) returns (sparx.teacherportal.studentapi.v1.GrantPasswordResetResponse);
   */
  grantPasswordReset(
    input: GrantPasswordResetRequest,
    options?: RpcOptions,
  ): UnaryCall<GrantPasswordResetRequest, GrantPasswordResetResponse>;
  /**
   * Block a student from logging in. If they are currently logged in then they
   * will be logged out on their next interaction with SparxWeb
   *
   * @generated from protobuf rpc: BlockStudent(sparx.teacherportal.studentapi.v1.BlockStudentRequest) returns (google.protobuf.Empty);
   */
  blockStudent(
    input: BlockStudentRequest,
    options?: RpcOptions,
  ): UnaryCall<BlockStudentRequest, Empty>;
}
/**
 * @generated from protobuf service sparx.teacherportal.studentapi.v1.StudentAPI
 */
export class StudentAPIClient implements IStudentAPIClient, ServiceInfo {
  typeName = StudentAPI.typeName;
  methods = StudentAPI.methods;
  options = StudentAPI.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetSingleStreamSelections is a batch endpoint that fetches the stream that a student is working at for a given
   * curriculum. This is used to display what difficulty content will go into the student's homework.
   *
   * @generated from protobuf rpc: GetSingleStreamSelections(sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsRequest) returns (sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsResponse);
   */
  getSingleStreamSelections(
    input: GetSingleStreamSelectionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetSingleStreamSelectionsRequest,
    GetSingleStreamSelectionsResponse
  > {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetSingleStreamSelectionsRequest,
      GetSingleStreamSelectionsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ListStudents lists all the students in a school.
   *
   * @generated from protobuf rpc: ListStudents(sparx.teacherportal.studentapi.v1.ListStudentsRequest) returns (sparx.teacherportal.studentapi.v1.ListStudentsResponse);
   */
  listStudents(
    input: ListStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentsRequest, ListStudentsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListStudentsRequest, ListStudentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SearchStudents(sparx.teacherportal.studentapi.v1.SearchStudentsRequest) returns (sparx.teacherportal.studentapi.v1.SearchStudentsResponse);
   */
  searchStudents(
    input: SearchStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<SearchStudentsRequest, SearchStudentsResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SearchStudentsRequest, SearchStudentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GrantPasswordReset grants a password reset for some students in a school.
   *
   * @generated from protobuf rpc: GrantPasswordReset(sparx.teacherportal.studentapi.v1.GrantPasswordResetRequest) returns (sparx.teacherportal.studentapi.v1.GrantPasswordResetResponse);
   */
  grantPasswordReset(
    input: GrantPasswordResetRequest,
    options?: RpcOptions,
  ): UnaryCall<GrantPasswordResetRequest, GrantPasswordResetResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GrantPasswordResetRequest,
      GrantPasswordResetResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Block a student from logging in. If they are currently logged in then they
   * will be logged out on their next interaction with SparxWeb
   *
   * @generated from protobuf rpc: BlockStudent(sparx.teacherportal.studentapi.v1.BlockStudentRequest) returns (google.protobuf.Empty);
   */
  blockStudent(
    input: BlockStudentRequest,
    options?: RpcOptions,
  ): UnaryCall<BlockStudentRequest, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<BlockStudentRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}

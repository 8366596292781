import { useEffect, useState } from 'react';

export type StateHook<T> = [T, React.Dispatch<React.SetStateAction<T>>];

/**
 * Browser storage returns a `string` or `null` as the value of a given key.
 * A result of `null` indicates the key isn't set; setting to `null` unsets the key.
 */
export type StorageItem = string | null;

const hasLocalStorage = (() => {
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');
    return true;
  } catch (e) {
    console.log('LocalStorage error:', e);
    return false;
  }
})();

const hasSessionStorage = (() => {
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    window.sessionStorage.setItem('test', 'test');
    window.sessionStorage.removeItem('test');
    return true;
  } catch (e) {
    console.log('SessionStorage error:', e);
    return false;
  }
})();

/**
 * A generic hook for the persistence of strings using local or session storage
 * - see {@link useSessionStorage} and {@link useLocalStorage}
 */
const makeUseStorage =
  (storage: Storage) =>
  (prefKey: string, defaultValue?: string): StateHook<StorageItem> => {
    const [pref, setPref] = useState<StorageItem>(() => {
      try {
        const item = storage.getItem(prefKey);
        if (item) {
          return item;
        }
      } catch (e) {
        console.error('Storage error:', e);
      }
      return defaultValue || null;
    });

    useEffect(() => {
      try {
        if (pref) {
          storage.setItem(prefKey, pref);
        } else {
          storage.removeItem(prefKey);
        }
      } catch (e) {
        console.error('Storage error:', e);
      }
    }, [pref, prefKey]);

    return [pref, setPref];
  };

/**
 * A fallback hook for when localStorage or sessionStorage is not available
 * - see {@link useSessionStorage} and {@link useLocalStorage}
 */
const makeUseStorageUnsupported = (type: string) => {
  console.error(`${type} is not supported in this environment.`);
  return (_: string, defaultValue?: string): StateHook<StorageItem> => {
    return useState<StorageItem>(defaultValue || null);
  };
};

/**
 * Hook for the persistence of strings using local storage
 * @param prefKey The key to use for storing the preference
 * @returns The value of the preference and a setter for updating it.
 */
export const useLocalStorage = hasLocalStorage
  ? makeUseStorage(window.localStorage)
  : makeUseStorageUnsupported('LocalStorage');

/**
 * Hook for the persistence of strings using session storage
 * @param prefKey The key to use for storing the preference
 * @returns The value of the preference and a setter for updating it.
 */
export const useSessionStorage = hasSessionStorage
  ? makeUseStorage(window.sessionStorage)
  : makeUseStorageUnsupported('SessionStorage');

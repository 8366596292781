// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/specialassessment/v1/specialassessment.proto" (package "sparx.games.progress.specialassessment.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { StringValue } from '../../../../../../google/protobuf/wrappers';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.games.progress.specialassessment.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: repeated string completedPackageIds = 2;
   */
  completedPackageIds: string[];
  /**
   * @generated from protobuf field: repeated string completedQuizIds = 3;
   */
  completedQuizIds: string[];
  /**
   * @generated from protobuf field: google.protobuf.StringValue activeQuizId = 4;
   */
  activeQuizId?: StringValue;
  /**
   * @generated from protobuf field: repeated string packageIdsWherePracticeHasBeenDone = 5;
   */
  packageIdsWherePracticeHasBeenDone: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.progress.specialassessment.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'completedPackageIds',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'completedQuizIds',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'activeQuizId', kind: 'message', T: () => StringValue },
      {
        no: 5,
        name: 'packageIdsWherePracticeHasBeenDone',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.specialassessment.v1.Store
 */
export const Store = new Store$Type();

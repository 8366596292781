import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from 'app/queryClient';
import { SuspenseRoute } from 'app/SuspenseRoute';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';

export const App = () => {
  return (
    <SuspenseRoute>
      <QueryClientProvider client={queryClient}>
        <TooltipProvider>
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
        </TooltipProvider>
      </QueryClientProvider>
    </SuspenseRoute>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/packages/tracking/common/v2/common.proto" (package "sparx.packages.tracking.common.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { PackageRecipe } from '../../../v1/spxpkg';
import { Interval } from '../../../../../../google/type/interval';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.packages.tracking.common.v2.Metadata
 */
export interface Metadata {
  /**
   * @generated from protobuf field: map<string, string> kv_map = 1;
   */
  kvMap: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.packages.tracking.common.v2.PackageData
 */
export interface PackageData {
  /**
   * The resource name of the package as 'packages/<packageID>'
   *
   * @generated from protobuf field: string package_name = 1;
   */
  packageName: string;
  /**
   * The ID of the blueprint, required in creation requests unless they they
   * also handle creating a new blueprint.
   *
   * @generated from protobuf field: string blueprint_id = 2;
   */
  blueprintId: string;
  /**
   * Who the package belongs to
   *
   * @generated from protobuf field: string user_id = 3;
   */
  userId: string;
  /**
   * The type of user
   *
   * @generated from protobuf field: sparx.packages.tracking.common.v2.UserType user_type = 4;
   */
  userType: UserType;
  /**
   * When the package was created, read only
   *
   * @generated from protobuf field: google.protobuf.Timestamp creation_time = 5;
   */
  creationTime?: Timestamp;
  /**
   * When the package expires, after this time the data may be deleted
   * if unspecified the package will not expire
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry_time = 6;
   */
  expiryTime?: Timestamp;
  /**
   * When the package should be considered active from (available to the user),
   * if unspecified it will default to the creation time.
   *
   * @generated from protobuf field: google.protobuf.Timestamp activation_time = 7;
   */
  activationTime?: Timestamp;
  /**
   * When the package should no longer be considered active, if unspecified
   * the packagewill not deactivate, though could still expire.
   *
   * @generated from protobuf field: google.protobuf.Timestamp deactivation_time = 8;
   */
  deactivationTime?: Timestamp;
  /**
   * Aditional data about this package
   *
   * @generated from protobuf field: sparx.packages.tracking.common.v2.Metadata metadata = 9;
   */
  metadata?: Metadata;
  /**
   * When the package was marked as deleted, it is up to the API user to decide
   * what deleted means.
   *
   * @generated from protobuf field: google.protobuf.Timestamp deleted_time = 10;
   */
  deletedTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.packages.tracking.common.v2.PackageDataSearch
 */
export interface PackageDataSearch {
  /**
   * When specified only returns packages with matching package names
   *
   * @generated from protobuf field: repeated string package_name = 1;
   */
  packageName: string[];
  /**
   * When specified only returns packages with matching blueprint IDs
   *
   * @generated from protobuf field: repeated string blueprint_id = 2;
   */
  blueprintId: string[];
  /**
   * When specified only returns packages for given users
   *
   * @generated from protobuf field: repeated string user_id = 3;
   */
  userId: string[];
  /**
   * When specified only returns packages for given user types
   *
   * @generated from protobuf field: repeated sparx.packages.tracking.common.v2.UserType user_type = 4;
   */
  userType: UserType[];
  /**
   * When specified only returns packages created within the given interval [start,end)
   *
   * @generated from protobuf field: google.type.Interval creation_time = 5;
   */
  creationTime?: Interval;
  /**
   * When specified only returns packages with an expiry time within the given interval [start,end)
   *
   * @generated from protobuf field: google.type.Interval expiry_time = 6;
   */
  expiryTime?: Interval;
  /**
   * When specified only returns packages with an activation time within the given interval [start,end)
   *
   * @generated from protobuf field: google.type.Interval activation_time = 7;
   */
  activationTime?: Interval;
  /**
   * When specified only returns packages with a deactivation time within the given interval [start,end)
   *
   * @generated from protobuf field: google.type.Interval deactivation_time = 8;
   */
  deactivationTime?: Interval;
  /**
   * When specified only returns packages with a deleted time within the given interval [start,end)
   *
   * @generated from protobuf field: google.type.Interval deleted_time = 10;
   */
  deletedTime?: Interval;
}
/**
 * @generated from protobuf message sparx.packages.tracking.common.v2.BlueprintData
 */
export interface BlueprintData {
  /**
   * The ID of the blueprint. Read only.
   *
   * @generated from protobuf field: string blueprint_id = 1;
   */
  blueprintId: string;
  /**
   * The application this blueprint belongs to. Read only.
   *
   * @generated from protobuf field: sparx.packages.tracking.common.v2.Application application = 2;
   */
  application: Application;
  /**
   * A string to identify the recipe payload's type, if unspecified it will default to
   * the recipes packager_request message full name.
   *
   * @generated from protobuf field: string recipe_type = 3;
   */
  recipeType: string;
  /**
   * A version number for the recipe, this is particularily useful where blueprints
   * are reused to identify the most up to date version for a given recipe_name.
   *
   * @generated from protobuf field: int32 recipe_version = 4;
   */
  recipeVersion: number;
  /**
   * The package recipe
   *
   * @generated from protobuf field: sparx.packages.v1.PackageRecipe recipe = 5;
   */
  recipe?: PackageRecipe;
  /**
   * When the blueprint was created. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp creation_time = 6;
   */
  creationTime?: Timestamp;
  /**
   * Additional data for this blueprint
   *
   * @generated from protobuf field: sparx.packages.tracking.common.v2.Metadata metadata = 7;
   */
  metadata?: Metadata;
  /**
   * When the blueprint expires, after this time the data may be deleted
   * if unspecified the blueprint will not expire
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry_time = 8;
   */
  expiryTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.packages.tracking.common.v2.BlueprintDataSearch
 */
export interface BlueprintDataSearch {
  /**
   * When specified only returns blueprints with the given IDs
   *
   * @generated from protobuf field: repeated string blueprint_id = 1;
   */
  blueprintId: string[];
  /**
   * When specified only returns blueprints for the given applications
   *
   * @generated from protobuf field: repeated sparx.packages.tracking.common.v2.Application application = 2;
   */
  application: Application[];
  /**
   * When specified only returns blueprints with the given recipe types
   *
   * @generated from protobuf field: repeated string recipe_type = 3;
   */
  recipeType: string[];
  /**
   * When specified only returns blueprints with the given version numbers
   *
   * @generated from protobuf field: repeated int32 recipe_version = 4;
   */
  recipeVersion: number[];
  /**
   * When specified only returns blueprints created within the given interval [start,end)
   *
   * @generated from protobuf field: google.type.Interval creation_time = 6;
   */
  creationTime?: Interval;
}
/**
 * @generated from protobuf message sparx.packages.tracking.common.v2.DeleteExpiredBlueprintsRequest
 */
export interface DeleteExpiredBlueprintsRequest {
  /**
   * Number of blueprints to delete in each batch - defaults to 1000 if not set
   *
   * @generated from protobuf field: int32 batch_size = 1;
   */
  batchSize: number;
  /**
   * Whether to just delete a single batch (rather than running until no more blueprints to delete or the max
   * duration has been reached) - defaults to false
   *
   * @generated from protobuf field: bool single_batch = 2;
   */
  singleBatch: boolean;
  /**
   * Duration in seconds after which another batch will not be started if exceeded - defaults to 3600 (1 hour) if not
   * set
   *
   * @generated from protobuf field: int32 max_duration_secs = 3;
   */
  maxDurationSecs: number;
}
/**
 * @generated from protobuf enum sparx.packages.tracking.common.v2.UserType
 */
export enum UserType {
  /**
   * @generated from protobuf enum value: USER_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: USER_TYPE_MONITOR_ROBOT = 1;
   */
  MONITOR_ROBOT = 1,
  /**
   * @generated from protobuf enum value: USER_TYPE_SCHOOL_STUDENT = 2;
   */
  SCHOOL_STUDENT = 2,
  /**
   * @generated from protobuf enum value: USER_TYPE_NUMERISE_USER = 3;
   */
  NUMERISE_USER = 3,
  /**
   * @generated from protobuf enum value: USER_TYPE_SCHOOL_STAFF = 4;
   */
  SCHOOL_STAFF = 4,
}
/**
 * @generated from protobuf enum sparx.packages.tracking.common.v2.Application
 */
export enum Application {
  /**
   * @generated from protobuf enum value: APPLICATION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: APPLICATION_HOMEWORK = 1;
   */
  HOMEWORK = 1,
  /**
   * @generated from protobuf enum value: APPLICATION_INDEPENDENT_LEARNING = 2;
   */
  INDEPENDENT_LEARNING = 2,
  /**
   * @generated from protobuf enum value: APPLICATION_NUMERISE = 3;
   */
  NUMERISE = 3,
  /**
   * @generated from protobuf enum value: APPLICATION_ASSESSMENTS = 4;
   */
  ASSESSMENTS = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class Metadata$Type extends MessageType<Metadata> {
  constructor() {
    super('sparx.packages.tracking.common.v2.Metadata', [
      {
        no: 1,
        name: 'kv_map',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.tracking.common.v2.Metadata
 */
export const Metadata = new Metadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageData$Type extends MessageType<PackageData> {
  constructor() {
    super('sparx.packages.tracking.common.v2.PackageData', [
      {
        no: 1,
        name: 'package_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'blueprint_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'user_type',
        kind: 'enum',
        T: () => [
          'sparx.packages.tracking.common.v2.UserType',
          UserType,
          'USER_TYPE_',
        ],
      },
      { no: 5, name: 'creation_time', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'expiry_time', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'activation_time', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'deactivation_time', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'metadata', kind: 'message', T: () => Metadata },
      { no: 10, name: 'deleted_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.tracking.common.v2.PackageData
 */
export const PackageData = new PackageData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageDataSearch$Type extends MessageType<PackageDataSearch> {
  constructor() {
    super('sparx.packages.tracking.common.v2.PackageDataSearch', [
      {
        no: 1,
        name: 'package_name',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'blueprint_id',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'user_id',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'user_type',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.packages.tracking.common.v2.UserType',
          UserType,
          'USER_TYPE_',
        ],
      },
      { no: 5, name: 'creation_time', kind: 'message', T: () => Interval },
      { no: 6, name: 'expiry_time', kind: 'message', T: () => Interval },
      { no: 7, name: 'activation_time', kind: 'message', T: () => Interval },
      { no: 8, name: 'deactivation_time', kind: 'message', T: () => Interval },
      { no: 10, name: 'deleted_time', kind: 'message', T: () => Interval },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.tracking.common.v2.PackageDataSearch
 */
export const PackageDataSearch = new PackageDataSearch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlueprintData$Type extends MessageType<BlueprintData> {
  constructor() {
    super('sparx.packages.tracking.common.v2.BlueprintData', [
      {
        no: 1,
        name: 'blueprint_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'application',
        kind: 'enum',
        T: () => [
          'sparx.packages.tracking.common.v2.Application',
          Application,
          'APPLICATION_',
        ],
      },
      {
        no: 3,
        name: 'recipe_type',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'recipe_version',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'recipe', kind: 'message', T: () => PackageRecipe },
      { no: 6, name: 'creation_time', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'metadata', kind: 'message', T: () => Metadata },
      { no: 8, name: 'expiry_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.tracking.common.v2.BlueprintData
 */
export const BlueprintData = new BlueprintData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlueprintDataSearch$Type extends MessageType<BlueprintDataSearch> {
  constructor() {
    super('sparx.packages.tracking.common.v2.BlueprintDataSearch', [
      {
        no: 1,
        name: 'blueprint_id',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'application',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.packages.tracking.common.v2.Application',
          Application,
          'APPLICATION_',
        ],
      },
      {
        no: 3,
        name: 'recipe_type',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'recipe_version',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 6, name: 'creation_time', kind: 'message', T: () => Interval },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.tracking.common.v2.BlueprintDataSearch
 */
export const BlueprintDataSearch = new BlueprintDataSearch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteExpiredBlueprintsRequest$Type extends MessageType<DeleteExpiredBlueprintsRequest> {
  constructor() {
    super('sparx.packages.tracking.common.v2.DeleteExpiredBlueprintsRequest', [
      { no: 1, name: 'batch_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'single_batch', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'max_duration_secs',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.packages.tracking.common.v2.DeleteExpiredBlueprintsRequest
 */
export const DeleteExpiredBlueprintsRequest =
  new DeleteExpiredBlueprintsRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.packages.tracking.common.v2.Common
 */
export const Common = new ServiceType(
  'sparx.packages.tracking.common.v2.Common',
  [
    {
      name: 'DeleteExpiredBlueprints',
      options: {},
      I: DeleteExpiredBlueprintsRequest,
      O: Empty,
    },
  ],
);

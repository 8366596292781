// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/types/product.proto" (package "sparx.types", syntax proto3),// tslint:disable
// @ts-nocheck
/**
 * Product is a shared list of products that can be used by services.
 * For example a class may be part of the reader product
 *
 * @generated from protobuf enum sparx.types.Product
 */
export enum Product {
  /**
   * Default enum value
   *
   * @generated from protobuf enum value: PRODUCT_UNKNOWN = 0;
   */
  PRODUCT_UNKNOWN = 0,
  /**
   * The Sparx Maths product
   *
   * @generated from protobuf enum value: SPARX_MATHS = 1;
   */
  SPARX_MATHS = 1,
  /**
   * The Sparx Reader product
   *
   * @generated from protobuf enum value: SPARX_READER = 2;
   */
  SPARX_READER = 2,
  /**
   * The Sparx Science product
   *
   * @generated from protobuf enum value: SPARX_SCIENCE = 3;
   */
  SPARX_SCIENCE = 3,
  /**
   * The Sparx maths curriculum product
   *
   * @generated from protobuf enum value: SPARXMATHS_CURRICULUM = 4;
   */
  SPARXMATHS_CURRICULUM = 4,
  /**
   * The Sparx Teaching product
   *
   * @generated from protobuf enum value: SPARX_TEACHING = 5;
   */
  SPARX_TEACHING = 5,
  /**
   * The Sparx Assessments product
   *
   * @generated from protobuf enum value: SPARX_ASSESSMENTS = 6;
   */
  SPARX_ASSESSMENTS = 6,
  /**
   * The Sparx Primary product
   *
   * @generated from protobuf enum value: SPARX_PRIMARY = 7;
   */
  SPARX_PRIMARY = 7,
}

import { CSSProperties } from 'react';

// Simple R/Y/G colours
export const RED = '#F0414C';
export const YELLOW = '#FFD200';
export const GREEN = '#00BF76';

// From Red through Orange to Green
export const baselineColours = [
  '#F0414C',
  '#F16545',
  '#FB8A3B',
  '#FEA429',
  '#FFBD11',
  '#FFD200',
  '#CDCE17',
  '#9ECB2D',
  '#71C742',
  '#43C457',
  '#00BF76',
];

export const cellBorderStyles = ({
  withTop = false,
  withLeft = false,
  withoutRight = false,
  withoutBottom = false,
}): CSSProperties => ({
  ...(withTop && { borderTopWidth: 1 }),
  ...(withLeft && { borderLeftWidth: 1 }),
  ...(withoutRight && { borderRightWidth: 0 }),
  ...(withoutBottom && { borderBottomWidth: 0 }),
});

/**
 * getColourStyle takes the student's score and the max score and works out what colour to display it as.
 * @param score - the student's score
 * @param maxScore - the max score for the question
 * @param useSplitCells - apply a linear gradient for use in split cells
 */
export const getColourStyle = (
  score: number | undefined,
  maxScore: number | undefined,
  useSplitCells?: { width: string },
): CSSProperties => {
  if (score === undefined || maxScore === undefined) {
    return {};
  }
  const betweenZeroAndOne = score / maxScore;
  const betweenZeroAndTen = betweenZeroAndOne * 10;
  const bgColour = baselineColours[Math.round(betweenZeroAndTen)];
  return {
    background: useSplitCells
      ? `linear-gradient(to right, ${bgColour} ${useSplitCells.width}, white 1%)`
      : bgColour,
  };
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/dataanalysis/aggserver/v1/aggserver.proto" (package "sparx.dataanalysis.aggserver.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { QuestionActivitySummary } from '../../../../../sparxweb/actsummaries/activity_summaries';
import { PackageData } from '../../../packages/tracking/common/v2/common';
import { Topic } from '../../../content/v2/curriculum';
import { Interval } from '../../../../../google/type/interval';
import { TaskItemFlag } from '../../../packages/v1/spxpkg';
import { NumeriseSummaryStats } from '../../../indy/common/common';
import { NumeriseLessonsWorkedOn } from '../../../indy/common/common';
import { NumeriseCorrectAnswers } from '../../../indy/common/common';
import { NumeriseLessonsCompletedForWeek } from '../../../indy/common/common';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { NumeriseStats } from '../../../indy/common/common';
import { PackageType } from '../../../packages/v1/spxpkg';
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.PackageTypeCounts
 */
export interface PackageTypeCounts {
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType type = 1;
   */
  type: PackageType;
  /**
   * @generated from protobuf field: int32 packagesComplete = 2;
   */
  packagesComplete: number;
  /**
   * @generated from protobuf field: int32 taskItemsComplete = 3;
   */
  taskItemsComplete: number;
}
/**
 * [deprecated]: StrandStats represents the skills seen and learnt by a student for a substrand.
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.SubStrandStats
 */
export interface SubStrandStats {
  /**
   * @generated from protobuf field: string subStrandID = 1;
   */
  subStrandID: string; // currently unused
  /**
   * @generated from protobuf field: string subStrandName = 2;
   */
  subStrandName: string;
  /**
   * @generated from protobuf field: int32 seen = 3;
   */
  seen: number; // Number of skills a student has seen for this substrand.
  /**
   * @generated from protobuf field: int32 learnt = 4;
   */
  learnt: number; // Number of skills a student has learnt for this substrand.
  /**
   * @generated from protobuf field: float propLearnt = 5;
   */
  propLearnt: number; // Student's learnt:seen skills ratio for this substrand.
}
/**
 * [deprecated]: StrandStats represents the skills seen and learnt by a student for a strand and its substrands.
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StrandStats
 */
export interface StrandStats {
  /**
   * @generated from protobuf field: string strandID = 1;
   */
  strandID: string; // currently unused
  /**
   * @generated from protobuf field: string strandName = 2;
   */
  strandName: string;
  /**
   * @generated from protobuf field: int32 seen = 3;
   */
  seen: number; // Number of skills a student has seen for this strand.
  /**
   * @generated from protobuf field: int32 learnt = 4;
   */
  learnt: number; // Number of skills a student has learnt for this strand.
  /**
   * @generated from protobuf field: float propLearnt = 5;
   */
  propLearnt: number; // Student's learnt:seen skills ratio for this strand.
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.SubStrandStats subStrandStats = 6;
   */
  subStrandStats: SubStrandStats[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StudentStats
 */
export interface StudentStats {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.PackageTypeCounts counts = 2;
   */
  counts: PackageTypeCounts[];
  /**
   * StrandStats has been deprecated and is not set by aggserver any longer.
   *
   * @deprecated
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.StrandStats strandStats = 3 [deprecated = true];
   */
  strandStats: StrandStats[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetStudentStatsRequest
 */
export interface GetStudentStatsRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetStudentStatsResponse
 */
export interface GetStudentStatsResponse {
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.StudentStats stats = 1;
   */
  stats?: StudentStats;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentStatsRequest
 */
export interface GetNumeriseStudentStatsRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * The minutes offset between UTC and the user's local time, computed by how
   * far ahead, in minutes, UTC is to the local time zone. This is used to
   * correctly ascertain the day/week/month time ranges for the statistics
   * returned.
   *
   * e.g.
   * for local timezone of GMT+2, set this as -120
   *      --> UTC - (GMT+2) = -120
   * for local timezone of GMT-5, set this as 300
   *      --> UTC - (GMT-5) = 300
   *
   * @generated from protobuf field: int32 timezoneOffset = 2;
   */
  timezoneOffset: number;
  /**
   * (Optional) a subset of time periods to return stats for. If omitted,
   * return stats for all time periods.
   * Does not have an effect on the timeTaken stats
   *
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.StatsTimePeriodFilter timePeriods = 3;
   */
  timePeriods?: StatsTimePeriodFilter;
  /**
   * Which stats would you like to return?
   *
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.StatsFilter statsFilter = 4;
   */
  statsFilter?: StatsFilter;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentStatsResponse
 */
export interface GetNumeriseStudentStatsResponse {
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseStats stats = 1;
   */
  stats?: NumeriseStats;
}
/**
 * GetNumeriseStudentLessonsCompletedByWeekRequest loads the counts of lessons
 * completed per week. It includes every week from the first Monday to the
 * last Monday inclusive.
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsCompletedByWeekRequest
 */
export interface GetNumeriseStudentLessonsCompletedByWeekRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp firstMonday = 2;
   */
  firstMonday?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastMonday = 3;
   */
  lastMonday?: Timestamp;
  /**
   * The minutes offset between UTC and the user's local time, computed by how
   * far ahead, in minutes, UTC is to the local time zone. This is used to
   * correctly ascertain the day/week/month time ranges for the statistics
   * returned.
   *
   * @generated from protobuf field: int32 timezoneOffset = 4;
   */
  timezoneOffset: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsCompletedByWeekResponse
 */
export interface GetNumeriseStudentLessonsCompletedByWeekResponse {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: repeated sparx.indy.common.NumeriseLessonsCompletedForWeek lessonsCompletedByWeek = 2;
   */
  lessonsCompletedByWeek: NumeriseLessonsCompletedForWeek[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentCorrectAnswersRequest
 */
export interface GetNumeriseStudentCorrectAnswersRequest {
  /**
   * The student id (aka indy user id) for which to return results
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * The UTC start time of the first time bucket
   *
   * @generated from protobuf field: google.protobuf.Timestamp firstBucketStart = 2;
   */
  firstBucketStart?: Timestamp;
  /**
   * The number of buckets for which to return results
   *
   * @generated from protobuf field: int32 numBuckets = 3;
   */
  numBuckets: number;
  /**
   * The length of each time bucket in hours
   *
   * @generated from protobuf field: int32 bucketLengthHours = 4;
   */
  bucketLengthHours: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentCorrectAnswersResponse
 */
export interface GetNumeriseStudentCorrectAnswersResponse {
  /**
   * The student id (aka indy user id) that the response is for
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * The array of correct answers counts, each with the UTC start and end time of the bucket
   *
   * @generated from protobuf field: repeated sparx.indy.common.NumeriseCorrectAnswers correctAnswers = 2;
   */
  correctAnswers: NumeriseCorrectAnswers[];
}
/**
 * GetNumeriseStudentLessonsWorkedOnRequest gets the lessons worked on for the
 * requested student which meet the specified filter
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsWorkedOnRequest
 */
export interface GetNumeriseStudentLessonsWorkedOnRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.LessonsWorkedOnFilter lessonsWorkedOnFilter = 2;
   */
  lessonsWorkedOnFilter?: LessonsWorkedOnFilter;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsWorkedOnResponse
 */
export interface GetNumeriseStudentLessonsWorkedOnResponse {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseLessonsWorkedOn lessonsWorkedOn = 2;
   */
  lessonsWorkedOn?: NumeriseLessonsWorkedOn;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentsSummaryStatsRequest
 */
export interface GetNumeriseStudentsSummaryStatsRequest {
  /**
   * @generated from protobuf field: repeated string studentIDs = 1;
   */
  studentIDs: string[];
  /**
   * @generated from protobuf field: int32 timezoneOffset = 2;
   */
  timezoneOffset: number;
  /**
   * (Optional) a subset of time periods to return stats for. If omitted,
   * return stats for all time periods.
   *
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.StatsTimePeriodFilter timePeriods = 3;
   */
  timePeriods?: StatsTimePeriodFilter;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StatsTimePeriodFilter
 */
export interface StatsTimePeriodFilter {
  /**
   * @generated from protobuf field: bool allTime = 1;
   */
  allTime: boolean;
  /**
   * @generated from protobuf field: bool currentWeek = 2;
   */
  currentWeek: boolean; // from Monday 00:00 to now
  /**
   * @generated from protobuf field: bool currentMonth = 3;
   */
  currentMonth: boolean; // from 1st of Month 00:00 to now
  /**
   * @generated from protobuf field: bool previousWeek = 4;
   */
  previousWeek: boolean; // from last Monday 00:00 to this Monday 00:00
  /**
   * @generated from protobuf field: bool previousMonth = 5;
   */
  previousMonth: boolean; // from last 1st of Month 00:00 to this 1st of Month 00:00
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.LessonsWorkedOnFilter
 */
export interface LessonsWorkedOnFilter {
  /**
   * @generated from protobuf field: int32 maxRecent = 1;
   */
  maxRecent: number; // maximum number of recent lessons to return, <= 0 returns all
  /**
   * @generated from protobuf field: string courseName = 2;
   */
  courseName: string; // filter to course, returns all courses if empty
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StatsFilter
 */
export interface StatsFilter {
  /**
   * @generated from protobuf field: bool correctAnswers = 1;
   */
  correctAnswers: boolean;
  /**
   * @generated from protobuf field: bool lessonsCompleted = 2;
   */
  lessonsCompleted: boolean;
  /**
   * How many weeks to get the time taken data for
   * 0 = get no data
   * 1 = get this week
   * 2 = get this week & last week
   * Continue incrementing to get this week plus n-1 previous weeks
   *
   * @generated from protobuf field: int32 timeTakenWeeks = 3;
   */
  timeTakenWeeks: number;
  /**
   * @generated from protobuf field: bool coursesCompleted = 4;
   */
  coursesCompleted: boolean;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.LessonsWorkedOnFilter lessonsWorkedOn = 5;
   */
  lessonsWorkedOn?: LessonsWorkedOnFilter;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentsSummaryStatsResponse
 */
export interface GetNumeriseStudentsSummaryStatsResponse {
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseSummaryStats stats = 1;
   */
  stats?: NumeriseSummaryStats;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StudentTaskItemHistory
 */
export interface StudentTaskItemHistory {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string taskItemRef = 2;
   */
  taskItemRef: string;
  /**
   * @generated from protobuf field: string skillID = 3;
   */
  skillID: string;
  /**
   * @generated from protobuf field: string activityString = 4;
   */
  activityString: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startTime = 5;
   */
  startTime?: Timestamp;
  /**
   * @generated from protobuf field: float totalTime = 6;
   */
  totalTime: number;
  /**
   * @generated from protobuf field: float videoTime = 7;
   */
  videoTime: number;
  /**
   * @generated from protobuf field: float timeBeforeFirstVideo = 8;
   */
  timeBeforeFirstVideo: number;
  /**
   * @generated from protobuf field: float timeTillFirstCorrect = 9;
   */
  timeTillFirstCorrect: number;
  /**
   * @generated from protobuf field: bool lesson = 10;
   */
  lesson: boolean;
  /**
   * @generated from protobuf field: bool correctFirstTime = 11;
   */
  correctFirstTime: boolean;
  /**
   * @generated from protobuf field: string learningUnitID = 12;
   */
  learningUnitID: string;
  /**
   * @generated from protobuf field: string partName = 13;
   */
  partName: string;
  /**
   * @generated from protobuf field: repeated sparx.packages.v1.TaskItemFlag taskItemFlags = 14;
   */
  taskItemFlags: TaskItemFlag[];
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType packageType = 15;
   */
  packageType: PackageType;
  /**
   * @generated from protobuf field: string curriculumTopicName = 16;
   */
  curriculumTopicName: string;
  /**
   * @generated from protobuf field: bool correctInQuiz = 17;
   */
  correctInQuiz: boolean;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StudentTaskItemHistoryState
 */
export interface StudentTaskItemHistoryState {
  /**
   * @generated from protobuf field: map<string, sparx.dataanalysis.aggserver.v1.StudentTaskItemHistory> states = 1;
   */
  states: {
    [key: string]: StudentTaskItemHistory;
  };
  /**
   * @generated from protobuf field: google.protobuf.Timestamp watermark = 2;
   */
  watermark?: Timestamp;
  /**
   * @generated from protobuf field: int32 version = 3;
   */
  version: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.ListStudentTaskItemHistoriesRequest
 */
export interface ListStudentTaskItemHistoriesRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * reprocessAll forces the watermark to be ignored and process all activities
   * from the beginning when building the TIHs, it should generally not need
   * to be set
   *
   * @generated from protobuf field: bool reprocessAll = 2;
   */
  reprocessAll: boolean;
  /**
   * activities_interval forces the TIHs to be built from activities only during
   * the given interval. When set and if needed it will ignore the watermark and
   * reprocess all TIHs within the interval, it will not save any changes to
   * the watermark
   *
   * @generated from protobuf field: google.type.Interval activities_interval = 5;
   */
  activitiesInterval?: Interval;
  /**
   * Requested page size. Server may return fewer results.
   * If unspecified, server will pick an arbitrary default.
   *
   * @generated from protobuf field: int32 page_size = 3;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return.
   * Typically the value of [ListStudentTaskItemHistoriesResponse.next_page_token] from
   * a previous `ListStudentTaskItemHistories` request.
   *
   * @generated from protobuf field: string page_token = 4;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.ListStudentTaskItemHistoriesResponse
 */
export interface ListStudentTaskItemHistoriesResponse {
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.StudentTaskItemHistory histories = 1;
   */
  histories: StudentTaskItemHistory[];
  /**
   * A token to retrieve the next page of results.
   * Pass this value in the `ListStudentTaskItemHistoriesRequest.page_token` in a
   * subsequent call to `ListStudentTaskItemHistories` to retrieve the next page.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StreamStudentTaskItemHistoriesRequest
 */
export interface StreamStudentTaskItemHistoriesRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * reprocessAll forces the watermark to be ignored and process all activities
   * from the beginning when building the TIHs, it should generally not need
   * to be set
   *
   * @generated from protobuf field: bool reprocessAll = 2;
   */
  reprocessAll: boolean;
  /**
   * activities_interval forces the TIHs to be built from activities only during
   * the given interval. When set and if needed it will ignore the watermark and
   * reprocess all TIHs within the interval, it will not save any changes to
   * the watermark
   *
   * @generated from protobuf field: google.type.Interval activities_interval = 3;
   */
  activitiesInterval?: Interval;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StreamStudentTaskItemHistoriesResponse
 */
export interface StreamStudentTaskItemHistoriesResponse {
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.StudentTaskItemHistory histories = 1;
   */
  histories: StudentTaskItemHistory[];
  /**
   * total number of histories this student has, this is likely more than the
   * number of histories included in a single stream message
   *
   * @generated from protobuf field: int64 total_histories = 2;
   */
  totalHistories: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetStudentActivityFeedRequest
 */
export interface GetStudentActivityFeedRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * Timestamp to return the feed data from. Defaults to now - 28 days.
   *
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 2;
   */
  startDate?: Timestamp;
  /**
   * Timestamp to return the feed data up to. Defaults to now.
   *
   * @generated from protobuf field: google.protobuf.Timestamp endDate = 3;
   */
  endDate?: Timestamp;
  /**
   * Show only independent learning work
   *
   * @generated from protobuf field: bool onlyIndependentLearning = 4;
   */
  onlyIndependentLearning: boolean;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetStudentActivityFeedResponse
 */
export interface GetStudentActivityFeedResponse {
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.ActivityFeedDay days = 1;
   */
  days: ActivityFeedDay[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.ActivityFeedDay
 */
export interface ActivityFeedDay {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp date = 1;
   */
  date?: Timestamp;
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.ActivityFeedWork work = 2;
   */
  work: ActivityFeedWork[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.ActivityFeedWork
 */
export interface ActivityFeedWork {
  /**
   * @generated from protobuf field: string learningUnitID = 1;
   */
  learningUnitID: string;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType packageType = 2;
   */
  packageType: PackageType;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp latestAttempt = 3;
   */
  latestAttempt?: Timestamp;
  /**
   * @generated from protobuf field: int32 attempts = 4;
   */
  attempts: number;
  /**
   * @generated from protobuf field: int32 correctAnswers = 5;
   */
  correctAnswers: number;
  /**
   * @generated from protobuf field: string curriculumTopicName = 6;
   */
  curriculumTopicName: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StartClearStudentsTIHStatesRequest
 */
export interface StartClearStudentsTIHStatesRequest {
  /**
   * @generated from protobuf field: bool confirm = 1;
   */
  confirm: boolean;
  /**
   * @generated from protobuf field: bool regen = 2;
   */
  regen: boolean;
  /**
   * @generated from protobuf field: repeated string studentIDs = 3;
   */
  studentIDs: string[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StartClearStudentsTIHStatesResponse
 */
export interface StartClearStudentsTIHStatesResponse {
  /**
   * @generated from protobuf field: string message = 1;
   */
  message: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.PackageCompletion
 */
export interface PackageCompletion {
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType type = 1;
   */
  type: PackageType;
  /**
   * @generated from protobuf field: float rate = 2;
   */
  rate: number; // In range 0.0 to 1.0
  /**
   * @generated from protobuf field: bool noPackagesSet = 3;
   */
  noPackagesSet: boolean;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletion
 */
export interface HomeworkCompletion {
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.PackageCompletion legacyPackageCompletion = 1;
   */
  legacyPackageCompletion: PackageCompletion[];
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.PackageCompletion onTimePackageCompletion = 2;
   */
  onTimePackageCompletion: PackageCompletion[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionWeek
 */
export interface HomeworkCompletionWeek {
  /**
   * @generated from protobuf field: int32 week = 1;
   */
  week: number;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletion homeworkCompletion = 2;
   */
  homeworkCompletion?: HomeworkCompletion;
  /**
   * @generated from protobuf field: bool hasFutureDueDate = 3;
   */
  hasFutureDueDate: boolean;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionGroup
 */
export interface HomeworkCompletionGroup {
  /**
   * @generated from protobuf field: string groupID = 1;
   */
  groupID: string;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletion homeworkCompletion = 2;
   */
  homeworkCompletion?: HomeworkCompletion;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetYearGroupHomeworkCompletionRequest
 */
export interface GetYearGroupHomeworkCompletionRequest {
  /**
   * @generated from protobuf field: string yearGroupID = 1;
   */
  yearGroupID: string;
  /**
   * @generated from protobuf field: int32 maxWeekCount = 2;
   */
  maxWeekCount: number;
  /**
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 4;
   */
  startDate?: Timestamp;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetYearGroupHomeworkCompletionResponse
 */
export interface GetYearGroupHomeworkCompletionResponse {
  /**
   * @generated from protobuf field: string yearGroupID = 1;
   */
  yearGroupID: string;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletion globalAverage = 2;
   */
  globalAverage?: HomeworkCompletion;
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.HomeworkCompletionGroup yearGroups = 3;
   */
  yearGroups: HomeworkCompletionGroup[];
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.HomeworkCompletionWeek weeks = 4;
   */
  weeks: HomeworkCompletionWeek[];
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletion schoolAverage = 5;
   */
  schoolAverage?: HomeworkCompletion;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetStudentGroupHomeworkCompletionRequest
 */
export interface GetStudentGroupHomeworkCompletionRequest {
  /**
   * @generated from protobuf field: string studentGroupID = 1;
   */
  studentGroupID: string;
  /**
   * @generated from protobuf field: int32 maxWeekCount = 2;
   */
  maxWeekCount: number;
  /**
   * @generated from protobuf field: string schoolID = 3;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string yearGroupID = 4;
   */
  yearGroupID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 5;
   */
  startDate?: Timestamp;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetStudentGroupHomeworkCompletionResponse
 */
export interface GetStudentGroupHomeworkCompletionResponse {
  /**
   * @generated from protobuf field: string studentGroupID = 1;
   */
  studentGroupID: string;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletion globalAverage = 2;
   */
  globalAverage?: HomeworkCompletion;
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.HomeworkCompletionWeek weeks = 3;
   */
  weeks: HomeworkCompletionWeek[];
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.HomeworkCompletionGroup studentGroups = 4;
   */
  studentGroups: HomeworkCompletionGroup[];
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletion yeargroupAverage = 5;
   */
  yeargroupAverage?: HomeworkCompletion;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.SchoolGroupWeekPackageStats
 */
export interface SchoolGroupWeekPackageStats {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: string yearGroupID = 2;
   */
  yearGroupID: string;
  /**
   * @generated from protobuf field: string studentGroupID = 4;
   */
  studentGroupID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp setDate = 5;
   */
  setDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp dueDate = 6;
   */
  dueDate?: Timestamp;
  /**
   * @generated from protobuf field: int32 weekNumber = 7;
   */
  weekNumber: number;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType packageType = 8;
   */
  packageType: PackageType;
  /**
   * @generated from protobuf field: int32 numHomeworksSet = 9;
   */
  numHomeworksSet: number;
  /**
   * @generated from protobuf field: int32 numHomeworksCompleted = 10;
   */
  numHomeworksCompleted: number;
  /**
   * @generated from protobuf field: int32 numHomeworksCompletedOnTime = 11;
   */
  numHomeworksCompletedOnTime: number;
}
/**
 * To hold the value calculated across all students per week in production schools
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.SparxAverageWeekPackageStats
 */
export interface SparxAverageWeekPackageStats {
  /**
   * @generated from protobuf field: int32 weekNumber = 1;
   */
  weekNumber: number;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType packageType = 2;
   */
  packageType: PackageType;
  /**
   * @generated from protobuf field: int32 numHomeworksSet = 3;
   */
  numHomeworksSet: number;
  /**
   * @generated from protobuf field: int32 numHomeworksCompleted = 4;
   */
  numHomeworksCompleted: number;
  /**
   * @generated from protobuf field: int32 numHomeworksCompletedOnTime = 5;
   */
  numHomeworksCompletedOnTime: number;
}
/**
 * To hold the value from which ever school has the best rate in the period. The school is anonymous.
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.BestCompletionRateYTD
 */
export interface BestCompletionRateYTD {
  /**
   * @generated from protobuf field: int32 academicYear = 1;
   */
  academicYear: number;
  /**
   * @generated from protobuf field: sparx.packages.v1.PackageType packageType = 2;
   */
  packageType: PackageType;
  /**
   * @generated from protobuf field: float completionRate = 3;
   */
  completionRate: number; // value 0.0 to 1.0
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolCollectionPackageStatsResponse
 */
export interface GetSchoolCollectionPackageStatsResponse {
  /**
   * @generated from protobuf field: string schoolCollectionID = 1;
   */
  schoolCollectionID: string;
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.SchoolGroupWeekPackageStats stats = 2;
   */
  stats: SchoolGroupWeekPackageStats[];
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.SparxAverageWeekPackageStats globalAverage = 3;
   */
  globalAverage: SparxAverageWeekPackageStats[];
  /**
   * 'best school value'  Best year to date averages
   *
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.BestCompletionRateYTD bestSchoolLegacyCompletion = 4;
   */
  bestSchoolLegacyCompletion?: BestCompletionRateYTD;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.BestCompletionRateYTD bestSchoolOnTimeCompletion = 5;
   */
  bestSchoolOnTimeCompletion?: BestCompletionRateYTD;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolCollectionPackageStatsRequest
 */
export interface GetSchoolCollectionPackageStatsRequest {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp startDate = 2;
   */
  startDate?: Timestamp;
}
/**
 * GetLUAttemptInfoRequest is a request to get a summary of a student's attempts at skills in a
 * Learning Unit used for creating Numerise revision packages
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetLUAttemptInfoRequest
 */
export interface GetLUAttemptInfoRequest {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: repeated string learning_unit_ids = 2;
   */
  learningUnitIds: string[];
}
/**
 * LearningAttemptInfo gives details of a student's progress with a learning unit, needed for
 * creating Numerise revision packages.
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.LUAttemptInfo
 */
export interface LUAttemptInfo {
  /**
   * The id of the learning unit
   *
   * @generated from protobuf field: string learning_unit_id = 1;
   */
  learningUnitId: string;
  /**
   * The date that the student last attempted a question in the learning unit
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_attempted_at = 2;
   */
  lastAttemptedAt?: Timestamp;
  /**
   * The number of packages in which the students has answered a question correctly that is from the
   * given learning unit
   *
   * @generated from protobuf field: int32 num_packages_correct_in = 3;
   */
  numPackagesCorrectIn: number;
}
/**
 * GetLUAttemptInfoResponse is the response to a query which gives details of a student's progress
 * with a learning unit, needed for creating Numerise revision packages.
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetLUAttemptInfoResponse
 */
export interface GetLUAttemptInfoResponse {
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.LUAttemptInfo attempt_info = 1;
   */
  attemptInfo: LUAttemptInfo[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkCompletionReportRequest
 */
export interface GetSchoolHomeworkCompletionReportRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp;
  /**
   * @generated from protobuf field: string time_zone = 4;
   */
  timeZone: string;
  /**
   * Tell the endpoint to only consider package updates up to the HW due date.
   *
   * @generated from protobuf field: bool at_due_date = 5;
   */
  atDueDate: boolean;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackage
 */
export interface HomeworkCompletionReportPackage {
  /**
   * @generated from protobuf field: float percent_complete = 1;
   */
  percentComplete: number;
  /**
   * @generated from protobuf field: bool on_time = 2;
   */
  onTime: boolean;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackages
 */
export interface HomeworkCompletionReportPackages {
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackage compulsory = 1;
   */
  compulsory?: HomeworkCompletionReportPackage;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackage optional = 2;
   */
  optional?: HomeworkCompletionReportPackage;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackage targets = 3;
   */
  targets?: HomeworkCompletionReportPackage;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportWeek
 */
export interface HomeworkCompletionReportWeek {
  /**
   * @generated from protobuf field: int32 week_number = 1;
   */
  weekNumber: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp set_date = 2;
   */
  setDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp due_date = 3;
   */
  dueDate?: Timestamp;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackages packages = 4;
   */
  packages?: HomeworkCompletionReportPackages;
  /**
   * The group ID for which the week's homework was set
   *
   * @generated from protobuf field: string group_id = 5;
   */
  groupId: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportStudent
 */
export interface HomeworkCompletionReportStudent {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportWeek homework_weeks = 2;
   */
  homeworkWeeks: HomeworkCompletionReportWeek[];
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackages total_completion_over_weeks = 3;
   */
  totalCompletionOverWeeks?: HomeworkCompletionReportPackages;
  /**
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackages on_time_completion_over_weeks = 4;
   */
  onTimeCompletionOverWeeks?: HomeworkCompletionReportPackages;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkCompletionReportResponse
 */
export interface GetSchoolHomeworkCompletionReportResponse {
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportStudent students = 1;
   */
  students: HomeworkCompletionReportStudent[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHasHomeworkRequest
 */
export interface GetSchoolHasHomeworkRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHasHomeworkResponse
 */
export interface GetSchoolHasHomeworkResponse {
  /**
   * @generated from protobuf field: bool has_homework = 1;
   */
  hasHomework: boolean;
}
/**
 * A DateRange represents a period of time between two timestamps
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.DateRange
 */
export interface DateRange {
  /**
   * The start date for the range
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 1;
   */
  startDate?: Timestamp;
  /**
   * The end date for the range
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 2;
   */
  endDate?: Timestamp;
}
/**
 * The request to get XP rewards for a list of students within a given period of time
 * Any students that have no entry in bigtable will be omitted from the response
 * Must contain at least one `source` or `source_prefix`.
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetStudentXPRewardsRequest
 */
export interface GetStudentXPRewardsRequest {
  /**
   * A list of student IDs to gather XP reward data for
   *
   * @generated from protobuf field: repeated string student_ids = 1;
   */
  studentIds: string[];
  /**
   * A list of date ranges to sum up the XP gained. The response will use the same ordering that is supplied on the
   * request
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.DateRange date_ranges = 2;
   */
  dateRanges: DateRange[];
  /**
   * A list of XP sources to return the data for. Must not be empty if
   * `source_prefixes` is empty.
   *
   * @generated from protobuf field: repeated string sources = 3;
   */
  sources: string[];
  /**
   * A list of prefixes to fetch data for, e.g. "sparxweb/" to get all Maths
   * data. Must not be empty if `sources` is empty.
   *
   * @generated from protobuf field: repeated string source_prefixes = 4;
   */
  sourcePrefixes: string[];
}
/**
 * Holds a DateRange and a total xp rewarded to a student over that range
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StudentXPRewardRange
 */
export interface StudentXPRewardRange {
  /**
   * DateRange used to sum the xp
   *
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.DateRange date_range = 1;
   */
  dateRange?: DateRange;
  /**
   * Total XP rewarded over the DateRange
   *
   * @generated from protobuf field: int64 xp_rewarded = 2;
   */
  xpRewarded: number;
}
/**
 * Represents a total of XP rewarded to a student within each date range
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StudentXPRewardStudent
 */
export interface StudentXPRewardStudent {
  /**
   * The ID of the student
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * List of DateRanges with the sum of xp rewarded within that range
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.StudentXPRewardRange xp_reward_ranges = 2;
   */
  xpRewardRanges: StudentXPRewardRange[];
}
/**
 * The response for getting XP rewards per student for each date range
 *
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetStudentXPRewardsResponse
 */
export interface GetStudentXPRewardsResponse {
  /**
   * A list of XP rewards for a student/date range combination
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.StudentXPRewardStudent students = 1;
   */
  students: StudentXPRewardStudent[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseFocusTopicsRequest
 */
export interface GetNumeriseFocusTopicsRequest {
  /**
   * The ID of the student
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * The map from learning unit name to the topics that contain it
   *
   * @generated from protobuf field: map<string, sparx.dataanalysis.aggserver.v1.TopicList> learning_unit_topics_map = 2;
   */
  learningUnitTopicsMap: {
    [key: string]: TopicList;
  };
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.TopicList
 */
export interface TopicList {
  /**
   * @generated from protobuf field: repeated sparx.content.v2.Topic topics = 1;
   */
  topics: Topic[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseFocusTopicsResponse
 */
export interface GetNumeriseFocusTopicsResponse {
  /**
   * The user's strong topics
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.FocusTopic strong_topics = 1;
   */
  strongTopics: FocusTopic[];
  /**
   * The user's weak topics
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.FocusTopic weak_topics = 2;
   */
  weakTopics: FocusTopic[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.FocusTopic
 */
export interface FocusTopic {
  /**
   * The topic
   *
   * @generated from protobuf field: sparx.content.v2.Topic topic = 1;
   */
  topic?: Topic;
  /**
   * The number of correct attempts on the topic
   *
   * @generated from protobuf field: int32 correct_attempts = 2;
   */
  correctAttempts: number;
  /**
   * The total number of attempts on the topic
   *
   * @generated from protobuf field: int32 total_attempts = 3;
   */
  totalAttempts: number;
  /**
   * The total time spent on the topic
   *
   * @generated from protobuf field: float total_time = 4;
   */
  totalTime: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumerisePracticeStatsRequest
 */
export interface GetNumerisePracticeStatsRequest {
  /**
   * The ID of the student
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * The list of learning unit id's to get practice statistics about.
   *
   * @generated from protobuf field: repeated string learning_unit_ids = 2;
   */
  learningUnitIds: string[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetNumerisePracticeStatsResponse
 */
export interface GetNumerisePracticeStatsResponse {
  /**
   * A map of learning units to their respective LUWithPracticeStats map.
   *
   * @generated from protobuf field: map<string, sparx.dataanalysis.aggserver.v1.LUWithPracticeStats> practice_stats = 1;
   */
  practiceStats: {
    [key: string]: LUWithPracticeStats;
  };
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.LUWithPracticeStats
 */
export interface LUWithPracticeStats {
  /**
   * A map from each skill to an object containing various stats.
   *
   * @generated from protobuf field: map<string, sparx.dataanalysis.aggserver.v1.PracticeStatistics> skill_to_statistics_map = 1;
   */
  skillToStatisticsMap: {
    [key: string]: PracticeStatistics;
  };
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.PracticeStatistics
 */
export interface PracticeStatistics {
  /**
   * The time that the student last attempted the skill.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_attempted_at = 1;
   */
  lastAttemptedAt?: Timestamp;
  /**
   * A boolean specifying whether the first attempt was correct in the users latest go.
   *
   * @generated from protobuf field: bool first_attempt_latest_go = 2;
   */
  firstAttemptLatestGo: boolean;
  /**
   * A boolean specifying whether the student watched a video before completing the question.
   *
   * @generated from protobuf field: bool video_help = 3;
   */
  videoHelp: boolean;
  /**
   * An int representing the number of failed attempts a user had on a skill before getting it correct.
   *
   * @generated from protobuf field: int32 failed_attempts_before_correct = 4;
   */
  failedAttemptsBeforeCorrect: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkHandInSummaryRequest
 */
export interface GetSchoolHomeworkHandInSummaryRequest {
  /**
   * The ID of the school.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The earliest due date that should be considered.
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp;
  /**
   * The latest due date that should be considered.
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp;
  /**
   * Timezone for the school.
   *
   * @generated from protobuf field: string time_zone = 4;
   */
  timeZone: string;
  /**
   * Optional list of class IDs to include in report (an empty list implies all classes).
   *
   * @generated from protobuf field: repeated string group_id = 5;
   */
  groupId: string[];
  /**
   * Optional list of student IDs to include in report (an empty list implies all students).
   *
   * @generated from protobuf field: repeated string student_id = 7;
   */
  studentId: string[];
  /**
   * Optional list of completion buckets to calculate completion for.
   *
   * @generated from protobuf field: repeated float buckets = 6;
   */
  buckets: number[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkHandInSummaryResponse
 */
export interface GetSchoolHomeworkHandInSummaryResponse {
  /**
   * The ID of the school.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The percentage completion over the period for each package type.
   *
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.HandInRates completion_over_period = 2;
   */
  completionOverPeriod?: HandInRates;
  /**
   * Student completion numbers for the requested period.
   *
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.StudentHandInSummary student_compulsory_summary = 3;
   */
  studentCompulsorySummary?: StudentHandInSummary;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.HandInRates
 */
export interface HandInRates {
  /**
   * The percentage of compulsory homework packages 100% completed for the period.
   *
   * @generated from protobuf field: float compulsory = 1;
   */
  compulsory: number;
  /**
   * The percentage of compulsory homework packages 100% completed on time for the period.
   *
   * @generated from protobuf field: float compulsory_on_time = 2;
   */
  compulsoryOnTime: number;
  /**
   * The percentage of optional homework packages 100% completed for the period.
   *
   * @generated from protobuf field: float optional = 3;
   */
  optional: number;
  /**
   * The percentage of target homework packages 100% completed for the period.
   *
   * @generated from protobuf field: float targets = 4;
   */
  targets: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.StudentHandInSummary
 */
export interface StudentHandInSummary {
  /**
   * The total number of students assigned homework.
   *
   * @generated from protobuf field: int32 total = 1;
   */
  total: number;
  /**
   * The number of students to complete homework on time.
   *
   * @generated from protobuf field: int32 on_time_completed = 2;
   */
  onTimeCompleted: number;
  /**
   * The number of students to complete homework.
   *
   * @generated from protobuf field: int32 completed = 3;
   */
  completed: number;
  /**
   * The number of students to not start homework.
   *
   * @generated from protobuf field: int32 not_started = 4;
   */
  notStarted: number;
  /**
   * The number of students to not start homework on time.
   *
   * @generated from protobuf field: int32 on_time_not_started = 5;
   */
  onTimeNotStarted: number;
  /**
   * The number of students who fall into each bucket passed in the request.
   *
   * @generated from protobuf field: repeated int32 buckets = 6;
   */
  buckets: number[];
  /**
   * The number of students who fall into each bucket passed in the request (based on on-time data).
   *
   * @generated from protobuf field: repeated int32 on_time_buckets = 7;
   */
  onTimeBuckets: number[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetIndependentLearningSummariesRequest
 */
export interface GetIndependentLearningSummariesRequest {
  /**
   * List of student IDs to get the data for
   *
   * @generated from protobuf field: repeated string studentIDs = 1;
   */
  studentIDs: string[];
  /**
   * Date range to be considered
   *
   * @generated from protobuf field: sparx.dataanalysis.aggserver.v1.DateRange dateRange = 2;
   */
  dateRange?: DateRange;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetIndependentLearningSummariesResponse
 */
export interface GetIndependentLearningSummariesResponse {
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.aggserver.v1.IndependentLearningSummary summaries = 1;
   */
  summaries: IndependentLearningSummary[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.IndependentLearningSummary
 */
export interface IndependentLearningSummary {
  /**
   * Student ID
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * Count of questions attempted in independent learning
   *
   * @generated from protobuf field: int32 questionsAttemptedCount = 2;
   */
  questionsAttemptedCount: number;
  /**
   * Time spent
   *
   * @generated from protobuf field: float timeSpent = 3;
   */
  timeSpent: number;
  /**
   * XP rewarded for questions
   *
   * @generated from protobuf field: int64 xpRewarded = 4;
   */
  xpRewarded: number;
  /**
   * XP rewarded for times tables
   *
   * @generated from protobuf field: int64 xpRewardedTimesTables = 5;
   */
  xpRewardedTimesTables: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetTrackedPackageActivitiesHistoryRequest
 */
export interface GetTrackedPackageActivitiesHistoryRequest {
  /**
   * List of tracked package datas to get task item histories for
   *
   * @generated from protobuf field: repeated sparx.packages.tracking.common.v2.PackageData package_data = 1;
   */
  packageData: PackageData[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.aggserver.v1.GetTrackedPackageActivitiesHistoryResponse
 */
export interface GetTrackedPackageActivitiesHistoryResponse {
  /**
   * @generated from protobuf field: repeated sparxweb.QuestionActivitySummary activities = 1;
   */
  activities: QuestionActivitySummary[];
}
// @generated message type with reflection information, may provide speed optimized methods
class PackageTypeCounts$Type extends MessageType<PackageTypeCounts> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.PackageTypeCounts', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      {
        no: 2,
        name: 'packagesComplete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'taskItemsComplete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.PackageTypeCounts
 */
export const PackageTypeCounts = new PackageTypeCounts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubStrandStats$Type extends MessageType<SubStrandStats> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.SubStrandStats', [
      {
        no: 1,
        name: 'subStrandID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'subStrandName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'seen', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'learnt', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'propLearnt', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.SubStrandStats
 */
export const SubStrandStats = new SubStrandStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StrandStats$Type extends MessageType<StrandStats> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StrandStats', [
      { no: 1, name: 'strandID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'strandName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'seen', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'learnt', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'propLearnt', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 6,
        name: 'subStrandStats',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SubStrandStats,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StrandStats
 */
export const StrandStats = new StrandStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentStats$Type extends MessageType<StudentStats> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StudentStats', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'counts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PackageTypeCounts,
      },
      {
        no: 3,
        name: 'strandStats',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StrandStats,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StudentStats
 */
export const StudentStats = new StudentStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentStatsRequest$Type extends MessageType<GetStudentStatsRequest> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetStudentStatsRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetStudentStatsRequest
 */
export const GetStudentStatsRequest = new GetStudentStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentStatsResponse$Type extends MessageType<GetStudentStatsResponse> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetStudentStatsResponse', [
      { no: 1, name: 'stats', kind: 'message', T: () => StudentStats },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetStudentStatsResponse
 */
export const GetStudentStatsResponse = new GetStudentStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentStatsRequest$Type extends MessageType<GetNumeriseStudentStatsRequest> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetNumeriseStudentStatsRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'timezoneOffset',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'timePeriods',
        kind: 'message',
        T: () => StatsTimePeriodFilter,
      },
      { no: 4, name: 'statsFilter', kind: 'message', T: () => StatsFilter },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentStatsRequest
 */
export const GetNumeriseStudentStatsRequest =
  new GetNumeriseStudentStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentStatsResponse$Type extends MessageType<GetNumeriseStudentStatsResponse> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetNumeriseStudentStatsResponse', [
      { no: 1, name: 'stats', kind: 'message', T: () => NumeriseStats },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentStatsResponse
 */
export const GetNumeriseStudentStatsResponse =
  new GetNumeriseStudentStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentLessonsCompletedByWeekRequest$Type extends MessageType<GetNumeriseStudentLessonsCompletedByWeekRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsCompletedByWeekRequest',
      [
        {
          no: 1,
          name: 'studentID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 2, name: 'firstMonday', kind: 'message', T: () => Timestamp },
        { no: 3, name: 'lastMonday', kind: 'message', T: () => Timestamp },
        {
          no: 4,
          name: 'timezoneOffset',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsCompletedByWeekRequest
 */
export const GetNumeriseStudentLessonsCompletedByWeekRequest =
  new GetNumeriseStudentLessonsCompletedByWeekRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentLessonsCompletedByWeekResponse$Type extends MessageType<GetNumeriseStudentLessonsCompletedByWeekResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsCompletedByWeekResponse',
      [
        {
          no: 1,
          name: 'studentID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'lessonsCompletedByWeek',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => NumeriseLessonsCompletedForWeek,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsCompletedByWeekResponse
 */
export const GetNumeriseStudentLessonsCompletedByWeekResponse =
  new GetNumeriseStudentLessonsCompletedByWeekResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentCorrectAnswersRequest$Type extends MessageType<GetNumeriseStudentCorrectAnswersRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetNumeriseStudentCorrectAnswersRequest',
      [
        {
          no: 1,
          name: 'studentID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'firstBucketStart',
          kind: 'message',
          T: () => Timestamp,
        },
        {
          no: 3,
          name: 'numBuckets',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 4,
          name: 'bucketLengthHours',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentCorrectAnswersRequest
 */
export const GetNumeriseStudentCorrectAnswersRequest =
  new GetNumeriseStudentCorrectAnswersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentCorrectAnswersResponse$Type extends MessageType<GetNumeriseStudentCorrectAnswersResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetNumeriseStudentCorrectAnswersResponse',
      [
        {
          no: 1,
          name: 'studentID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'correctAnswers',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => NumeriseCorrectAnswers,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentCorrectAnswersResponse
 */
export const GetNumeriseStudentCorrectAnswersResponse =
  new GetNumeriseStudentCorrectAnswersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentLessonsWorkedOnRequest$Type extends MessageType<GetNumeriseStudentLessonsWorkedOnRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsWorkedOnRequest',
      [
        {
          no: 1,
          name: 'studentID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'lessonsWorkedOnFilter',
          kind: 'message',
          T: () => LessonsWorkedOnFilter,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsWorkedOnRequest
 */
export const GetNumeriseStudentLessonsWorkedOnRequest =
  new GetNumeriseStudentLessonsWorkedOnRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentLessonsWorkedOnResponse$Type extends MessageType<GetNumeriseStudentLessonsWorkedOnResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsWorkedOnResponse',
      [
        {
          no: 1,
          name: 'studentID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'lessonsWorkedOn',
          kind: 'message',
          T: () => NumeriseLessonsWorkedOn,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentLessonsWorkedOnResponse
 */
export const GetNumeriseStudentLessonsWorkedOnResponse =
  new GetNumeriseStudentLessonsWorkedOnResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentsSummaryStatsRequest$Type extends MessageType<GetNumeriseStudentsSummaryStatsRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetNumeriseStudentsSummaryStatsRequest',
      [
        {
          no: 1,
          name: 'studentIDs',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'timezoneOffset',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 3,
          name: 'timePeriods',
          kind: 'message',
          T: () => StatsTimePeriodFilter,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentsSummaryStatsRequest
 */
export const GetNumeriseStudentsSummaryStatsRequest =
  new GetNumeriseStudentsSummaryStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatsTimePeriodFilter$Type extends MessageType<StatsTimePeriodFilter> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StatsTimePeriodFilter', [
      { no: 1, name: 'allTime', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'currentWeek', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'currentMonth', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'previousWeek', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'previousMonth',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StatsTimePeriodFilter
 */
export const StatsTimePeriodFilter = new StatsTimePeriodFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LessonsWorkedOnFilter$Type extends MessageType<LessonsWorkedOnFilter> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.LessonsWorkedOnFilter', [
      { no: 1, name: 'maxRecent', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'courseName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.LessonsWorkedOnFilter
 */
export const LessonsWorkedOnFilter = new LessonsWorkedOnFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatsFilter$Type extends MessageType<StatsFilter> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StatsFilter', [
      {
        no: 1,
        name: 'correctAnswers',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'lessonsCompleted',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'timeTakenWeeks',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'coursesCompleted',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'lessonsWorkedOn',
        kind: 'message',
        T: () => LessonsWorkedOnFilter,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StatsFilter
 */
export const StatsFilter = new StatsFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseStudentsSummaryStatsResponse$Type extends MessageType<GetNumeriseStudentsSummaryStatsResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetNumeriseStudentsSummaryStatsResponse',
      [
        {
          no: 1,
          name: 'stats',
          kind: 'message',
          T: () => NumeriseSummaryStats,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseStudentsSummaryStatsResponse
 */
export const GetNumeriseStudentsSummaryStatsResponse =
  new GetNumeriseStudentsSummaryStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentTaskItemHistory$Type extends MessageType<StudentTaskItemHistory> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StudentTaskItemHistory', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'taskItemRef',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'skillID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'activityString',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'startTime', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'totalTime', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 7, name: 'videoTime', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 8,
        name: 'timeBeforeFirstVideo',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 9,
        name: 'timeTillFirstCorrect',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 10, name: 'lesson', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 11,
        name: 'correctFirstTime',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 12,
        name: 'learningUnitID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 13, name: 'partName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 14,
        name: 'taskItemFlags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.packages.v1.TaskItemFlag',
          TaskItemFlag,
          'TASK_ITEM_FLAG_',
        ],
      },
      {
        no: 15,
        name: 'packageType',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      {
        no: 16,
        name: 'curriculumTopicName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 17,
        name: 'correctInQuiz',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StudentTaskItemHistory
 */
export const StudentTaskItemHistory = new StudentTaskItemHistory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentTaskItemHistoryState$Type extends MessageType<StudentTaskItemHistoryState> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StudentTaskItemHistoryState', [
      {
        no: 1,
        name: 'states',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => StudentTaskItemHistory },
      },
      { no: 2, name: 'watermark', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'version', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StudentTaskItemHistoryState
 */
export const StudentTaskItemHistoryState =
  new StudentTaskItemHistoryState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentTaskItemHistoriesRequest$Type extends MessageType<ListStudentTaskItemHistoriesRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.ListStudentTaskItemHistoriesRequest',
      [
        {
          no: 1,
          name: 'studentID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'reprocessAll',
          kind: 'scalar',
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 5,
          name: 'activities_interval',
          kind: 'message',
          T: () => Interval,
        },
        { no: 3, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
        {
          no: 4,
          name: 'page_token',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.ListStudentTaskItemHistoriesRequest
 */
export const ListStudentTaskItemHistoriesRequest =
  new ListStudentTaskItemHistoriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentTaskItemHistoriesResponse$Type extends MessageType<ListStudentTaskItemHistoriesResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.ListStudentTaskItemHistoriesResponse',
      [
        {
          no: 1,
          name: 'histories',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => StudentTaskItemHistory,
        },
        {
          no: 2,
          name: 'next_page_token',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.ListStudentTaskItemHistoriesResponse
 */
export const ListStudentTaskItemHistoriesResponse =
  new ListStudentTaskItemHistoriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamStudentTaskItemHistoriesRequest$Type extends MessageType<StreamStudentTaskItemHistoriesRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.StreamStudentTaskItemHistoriesRequest',
      [
        {
          no: 1,
          name: 'studentID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'reprocessAll',
          kind: 'scalar',
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 3,
          name: 'activities_interval',
          kind: 'message',
          T: () => Interval,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StreamStudentTaskItemHistoriesRequest
 */
export const StreamStudentTaskItemHistoriesRequest =
  new StreamStudentTaskItemHistoriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamStudentTaskItemHistoriesResponse$Type extends MessageType<StreamStudentTaskItemHistoriesResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.StreamStudentTaskItemHistoriesResponse',
      [
        {
          no: 1,
          name: 'histories',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => StudentTaskItemHistory,
        },
        {
          no: 2,
          name: 'total_histories',
          kind: 'scalar',
          T: 3 /*ScalarType.INT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StreamStudentTaskItemHistoriesResponse
 */
export const StreamStudentTaskItemHistoriesResponse =
  new StreamStudentTaskItemHistoriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentActivityFeedRequest$Type extends MessageType<GetStudentActivityFeedRequest> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetStudentActivityFeedRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'startDate', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'endDate', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'onlyIndependentLearning',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetStudentActivityFeedRequest
 */
export const GetStudentActivityFeedRequest =
  new GetStudentActivityFeedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentActivityFeedResponse$Type extends MessageType<GetStudentActivityFeedResponse> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetStudentActivityFeedResponse', [
      {
        no: 1,
        name: 'days',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ActivityFeedDay,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetStudentActivityFeedResponse
 */
export const GetStudentActivityFeedResponse =
  new GetStudentActivityFeedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityFeedDay$Type extends MessageType<ActivityFeedDay> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.ActivityFeedDay', [
      { no: 1, name: 'date', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'work',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ActivityFeedWork,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.ActivityFeedDay
 */
export const ActivityFeedDay = new ActivityFeedDay$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityFeedWork$Type extends MessageType<ActivityFeedWork> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.ActivityFeedWork', [
      {
        no: 1,
        name: 'learningUnitID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'packageType',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      { no: 3, name: 'latestAttempt', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'attempts', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 5,
        name: 'correctAnswers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'curriculumTopicName',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.ActivityFeedWork
 */
export const ActivityFeedWork = new ActivityFeedWork$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartClearStudentsTIHStatesRequest$Type extends MessageType<StartClearStudentsTIHStatesRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.StartClearStudentsTIHStatesRequest',
      [
        { no: 1, name: 'confirm', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
        { no: 2, name: 'regen', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
        {
          no: 3,
          name: 'studentIDs',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StartClearStudentsTIHStatesRequest
 */
export const StartClearStudentsTIHStatesRequest =
  new StartClearStudentsTIHStatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartClearStudentsTIHStatesResponse$Type extends MessageType<StartClearStudentsTIHStatesResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.StartClearStudentsTIHStatesResponse',
      [{ no: 1, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StartClearStudentsTIHStatesResponse
 */
export const StartClearStudentsTIHStatesResponse =
  new StartClearStudentsTIHStatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageCompletion$Type extends MessageType<PackageCompletion> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.PackageCompletion', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      { no: 2, name: 'rate', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 3,
        name: 'noPackagesSet',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.PackageCompletion
 */
export const PackageCompletion = new PackageCompletion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletion$Type extends MessageType<HomeworkCompletion> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.HomeworkCompletion', [
      {
        no: 1,
        name: 'legacyPackageCompletion',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PackageCompletion,
      },
      {
        no: 2,
        name: 'onTimePackageCompletion',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PackageCompletion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletion
 */
export const HomeworkCompletion = new HomeworkCompletion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletionWeek$Type extends MessageType<HomeworkCompletionWeek> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.HomeworkCompletionWeek', [
      { no: 1, name: 'week', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'homeworkCompletion',
        kind: 'message',
        T: () => HomeworkCompletion,
      },
      {
        no: 3,
        name: 'hasFutureDueDate',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionWeek
 */
export const HomeworkCompletionWeek = new HomeworkCompletionWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletionGroup$Type extends MessageType<HomeworkCompletionGroup> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.HomeworkCompletionGroup', [
      { no: 1, name: 'groupID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homeworkCompletion',
        kind: 'message',
        T: () => HomeworkCompletion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionGroup
 */
export const HomeworkCompletionGroup = new HomeworkCompletionGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetYearGroupHomeworkCompletionRequest$Type extends MessageType<GetYearGroupHomeworkCompletionRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetYearGroupHomeworkCompletionRequest',
      [
        {
          no: 1,
          name: 'yearGroupID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'maxWeekCount',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 4, name: 'startDate', kind: 'message', T: () => Timestamp },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetYearGroupHomeworkCompletionRequest
 */
export const GetYearGroupHomeworkCompletionRequest =
  new GetYearGroupHomeworkCompletionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetYearGroupHomeworkCompletionResponse$Type extends MessageType<GetYearGroupHomeworkCompletionResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetYearGroupHomeworkCompletionResponse',
      [
        {
          no: 1,
          name: 'yearGroupID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'globalAverage',
          kind: 'message',
          T: () => HomeworkCompletion,
        },
        {
          no: 3,
          name: 'yearGroups',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HomeworkCompletionGroup,
        },
        {
          no: 4,
          name: 'weeks',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HomeworkCompletionWeek,
        },
        {
          no: 5,
          name: 'schoolAverage',
          kind: 'message',
          T: () => HomeworkCompletion,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetYearGroupHomeworkCompletionResponse
 */
export const GetYearGroupHomeworkCompletionResponse =
  new GetYearGroupHomeworkCompletionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentGroupHomeworkCompletionRequest$Type extends MessageType<GetStudentGroupHomeworkCompletionRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetStudentGroupHomeworkCompletionRequest',
      [
        {
          no: 1,
          name: 'studentGroupID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'maxWeekCount',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        { no: 3, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 4,
          name: 'yearGroupID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 5, name: 'startDate', kind: 'message', T: () => Timestamp },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetStudentGroupHomeworkCompletionRequest
 */
export const GetStudentGroupHomeworkCompletionRequest =
  new GetStudentGroupHomeworkCompletionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentGroupHomeworkCompletionResponse$Type extends MessageType<GetStudentGroupHomeworkCompletionResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetStudentGroupHomeworkCompletionResponse',
      [
        {
          no: 1,
          name: 'studentGroupID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'globalAverage',
          kind: 'message',
          T: () => HomeworkCompletion,
        },
        {
          no: 3,
          name: 'weeks',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HomeworkCompletionWeek,
        },
        {
          no: 4,
          name: 'studentGroups',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HomeworkCompletionGroup,
        },
        {
          no: 5,
          name: 'yeargroupAverage',
          kind: 'message',
          T: () => HomeworkCompletion,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetStudentGroupHomeworkCompletionResponse
 */
export const GetStudentGroupHomeworkCompletionResponse =
  new GetStudentGroupHomeworkCompletionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolGroupWeekPackageStats$Type extends MessageType<SchoolGroupWeekPackageStats> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.SchoolGroupWeekPackageStats', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'yearGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'studentGroupID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'setDate', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'dueDate', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'weekNumber', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 8,
        name: 'packageType',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      {
        no: 9,
        name: 'numHomeworksSet',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 10,
        name: 'numHomeworksCompleted',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'numHomeworksCompletedOnTime',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.SchoolGroupWeekPackageStats
 */
export const SchoolGroupWeekPackageStats =
  new SchoolGroupWeekPackageStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SparxAverageWeekPackageStats$Type extends MessageType<SparxAverageWeekPackageStats> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.SparxAverageWeekPackageStats', [
      { no: 1, name: 'weekNumber', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'packageType',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      {
        no: 3,
        name: 'numHomeworksSet',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'numHomeworksCompleted',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'numHomeworksCompletedOnTime',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.SparxAverageWeekPackageStats
 */
export const SparxAverageWeekPackageStats =
  new SparxAverageWeekPackageStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BestCompletionRateYTD$Type extends MessageType<BestCompletionRateYTD> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.BestCompletionRateYTD', [
      {
        no: 1,
        name: 'academicYear',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'packageType',
        kind: 'enum',
        T: () => ['sparx.packages.v1.PackageType', PackageType],
      },
      {
        no: 3,
        name: 'completionRate',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.BestCompletionRateYTD
 */
export const BestCompletionRateYTD = new BestCompletionRateYTD$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolCollectionPackageStatsResponse$Type extends MessageType<GetSchoolCollectionPackageStatsResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetSchoolCollectionPackageStatsResponse',
      [
        {
          no: 1,
          name: 'schoolCollectionID',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'stats',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => SchoolGroupWeekPackageStats,
        },
        {
          no: 3,
          name: 'globalAverage',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => SparxAverageWeekPackageStats,
        },
        {
          no: 4,
          name: 'bestSchoolLegacyCompletion',
          kind: 'message',
          T: () => BestCompletionRateYTD,
        },
        {
          no: 5,
          name: 'bestSchoolOnTimeCompletion',
          kind: 'message',
          T: () => BestCompletionRateYTD,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolCollectionPackageStatsResponse
 */
export const GetSchoolCollectionPackageStatsResponse =
  new GetSchoolCollectionPackageStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolCollectionPackageStatsRequest$Type extends MessageType<GetSchoolCollectionPackageStatsRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetSchoolCollectionPackageStatsRequest',
      [
        { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: 'startDate', kind: 'message', T: () => Timestamp },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolCollectionPackageStatsRequest
 */
export const GetSchoolCollectionPackageStatsRequest =
  new GetSchoolCollectionPackageStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLUAttemptInfoRequest$Type extends MessageType<GetLUAttemptInfoRequest> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetLUAttemptInfoRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'learning_unit_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetLUAttemptInfoRequest
 */
export const GetLUAttemptInfoRequest = new GetLUAttemptInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LUAttemptInfo$Type extends MessageType<LUAttemptInfo> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.LUAttemptInfo', [
      {
        no: 1,
        name: 'learning_unit_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'last_attempted_at', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'num_packages_correct_in',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.LUAttemptInfo
 */
export const LUAttemptInfo = new LUAttemptInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLUAttemptInfoResponse$Type extends MessageType<GetLUAttemptInfoResponse> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetLUAttemptInfoResponse', [
      {
        no: 1,
        name: 'attempt_info',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LUAttemptInfo,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetLUAttemptInfoResponse
 */
export const GetLUAttemptInfoResponse = new GetLUAttemptInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolHomeworkCompletionReportRequest$Type extends MessageType<GetSchoolHomeworkCompletionReportRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkCompletionReportRequest',
      [
        {
          no: 1,
          name: 'school_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 2, name: 'start_date', kind: 'message', T: () => Timestamp },
        { no: 3, name: 'end_date', kind: 'message', T: () => Timestamp },
        {
          no: 4,
          name: 'time_zone',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 5,
          name: 'at_due_date',
          kind: 'scalar',
          T: 8 /*ScalarType.BOOL*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkCompletionReportRequest
 */
export const GetSchoolHomeworkCompletionReportRequest =
  new GetSchoolHomeworkCompletionReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletionReportPackage$Type extends MessageType<HomeworkCompletionReportPackage> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackage', [
      {
        no: 1,
        name: 'percent_complete',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 2, name: 'on_time', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackage
 */
export const HomeworkCompletionReportPackage =
  new HomeworkCompletionReportPackage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletionReportPackages$Type extends MessageType<HomeworkCompletionReportPackages> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackages', [
      {
        no: 1,
        name: 'compulsory',
        kind: 'message',
        T: () => HomeworkCompletionReportPackage,
      },
      {
        no: 2,
        name: 'optional',
        kind: 'message',
        T: () => HomeworkCompletionReportPackage,
      },
      {
        no: 3,
        name: 'targets',
        kind: 'message',
        T: () => HomeworkCompletionReportPackage,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportPackages
 */
export const HomeworkCompletionReportPackages =
  new HomeworkCompletionReportPackages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletionReportWeek$Type extends MessageType<HomeworkCompletionReportWeek> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportWeek', [
      { no: 1, name: 'week_number', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'set_date', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'due_date', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'packages',
        kind: 'message',
        T: () => HomeworkCompletionReportPackages,
      },
      { no: 5, name: 'group_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportWeek
 */
export const HomeworkCompletionReportWeek =
  new HomeworkCompletionReportWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkCompletionReportStudent$Type extends MessageType<HomeworkCompletionReportStudent> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportStudent', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homework_weeks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkCompletionReportWeek,
      },
      {
        no: 3,
        name: 'total_completion_over_weeks',
        kind: 'message',
        T: () => HomeworkCompletionReportPackages,
      },
      {
        no: 4,
        name: 'on_time_completion_over_weeks',
        kind: 'message',
        T: () => HomeworkCompletionReportPackages,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.HomeworkCompletionReportStudent
 */
export const HomeworkCompletionReportStudent =
  new HomeworkCompletionReportStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolHomeworkCompletionReportResponse$Type extends MessageType<GetSchoolHomeworkCompletionReportResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkCompletionReportResponse',
      [
        {
          no: 1,
          name: 'students',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HomeworkCompletionReportStudent,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkCompletionReportResponse
 */
export const GetSchoolHomeworkCompletionReportResponse =
  new GetSchoolHomeworkCompletionReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolHasHomeworkRequest$Type extends MessageType<GetSchoolHasHomeworkRequest> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetSchoolHasHomeworkRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHasHomeworkRequest
 */
export const GetSchoolHasHomeworkRequest =
  new GetSchoolHasHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolHasHomeworkResponse$Type extends MessageType<GetSchoolHasHomeworkResponse> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetSchoolHasHomeworkResponse', [
      { no: 1, name: 'has_homework', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHasHomeworkResponse
 */
export const GetSchoolHasHomeworkResponse =
  new GetSchoolHasHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DateRange$Type extends MessageType<DateRange> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.DateRange', [
      { no: 1, name: 'start_date', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'end_date', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.DateRange
 */
export const DateRange = new DateRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentXPRewardsRequest$Type extends MessageType<GetStudentXPRewardsRequest> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetStudentXPRewardsRequest', [
      {
        no: 1,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'date_ranges',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => DateRange,
      },
      {
        no: 3,
        name: 'sources',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'source_prefixes',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetStudentXPRewardsRequest
 */
export const GetStudentXPRewardsRequest = new GetStudentXPRewardsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentXPRewardRange$Type extends MessageType<StudentXPRewardRange> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StudentXPRewardRange', [
      { no: 1, name: 'date_range', kind: 'message', T: () => DateRange },
      {
        no: 2,
        name: 'xp_rewarded',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StudentXPRewardRange
 */
export const StudentXPRewardRange = new StudentXPRewardRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentXPRewardStudent$Type extends MessageType<StudentXPRewardStudent> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StudentXPRewardStudent', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'xp_reward_ranges',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentXPRewardRange,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StudentXPRewardStudent
 */
export const StudentXPRewardStudent = new StudentXPRewardStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentXPRewardsResponse$Type extends MessageType<GetStudentXPRewardsResponse> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetStudentXPRewardsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentXPRewardStudent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetStudentXPRewardsResponse
 */
export const GetStudentXPRewardsResponse =
  new GetStudentXPRewardsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseFocusTopicsRequest$Type extends MessageType<GetNumeriseFocusTopicsRequest> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetNumeriseFocusTopicsRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'learning_unit_topics_map',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => TopicList },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseFocusTopicsRequest
 */
export const GetNumeriseFocusTopicsRequest =
  new GetNumeriseFocusTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopicList$Type extends MessageType<TopicList> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.TopicList', [
      {
        no: 1,
        name: 'topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Topic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.TopicList
 */
export const TopicList = new TopicList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumeriseFocusTopicsResponse$Type extends MessageType<GetNumeriseFocusTopicsResponse> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetNumeriseFocusTopicsResponse', [
      {
        no: 1,
        name: 'strong_topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => FocusTopic,
      },
      {
        no: 2,
        name: 'weak_topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => FocusTopic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumeriseFocusTopicsResponse
 */
export const GetNumeriseFocusTopicsResponse =
  new GetNumeriseFocusTopicsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FocusTopic$Type extends MessageType<FocusTopic> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.FocusTopic', [
      { no: 1, name: 'topic', kind: 'message', T: () => Topic },
      {
        no: 2,
        name: 'correct_attempts',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'total_attempts',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 4, name: 'total_time', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.FocusTopic
 */
export const FocusTopic = new FocusTopic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumerisePracticeStatsRequest$Type extends MessageType<GetNumerisePracticeStatsRequest> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetNumerisePracticeStatsRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'learning_unit_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumerisePracticeStatsRequest
 */
export const GetNumerisePracticeStatsRequest =
  new GetNumerisePracticeStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumerisePracticeStatsResponse$Type extends MessageType<GetNumerisePracticeStatsResponse> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.GetNumerisePracticeStatsResponse', [
      {
        no: 1,
        name: 'practice_stats',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => LUWithPracticeStats },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetNumerisePracticeStatsResponse
 */
export const GetNumerisePracticeStatsResponse =
  new GetNumerisePracticeStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LUWithPracticeStats$Type extends MessageType<LUWithPracticeStats> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.LUWithPracticeStats', [
      {
        no: 1,
        name: 'skill_to_statistics_map',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => PracticeStatistics },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.LUWithPracticeStats
 */
export const LUWithPracticeStats = new LUWithPracticeStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PracticeStatistics$Type extends MessageType<PracticeStatistics> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.PracticeStatistics', [
      { no: 1, name: 'last_attempted_at', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'first_attempt_latest_go',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 3, name: 'video_help', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 4,
        name: 'failed_attempts_before_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.PracticeStatistics
 */
export const PracticeStatistics = new PracticeStatistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolHomeworkHandInSummaryRequest$Type extends MessageType<GetSchoolHomeworkHandInSummaryRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkHandInSummaryRequest',
      [
        {
          no: 1,
          name: 'school_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 2, name: 'start_date', kind: 'message', T: () => Timestamp },
        { no: 3, name: 'end_date', kind: 'message', T: () => Timestamp },
        {
          no: 4,
          name: 'time_zone',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 5,
          name: 'group_id',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 7,
          name: 'student_id',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 6,
          name: 'buckets',
          kind: 'scalar',
          repeat: 1 /*RepeatType.PACKED*/,
          T: 2 /*ScalarType.FLOAT*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkHandInSummaryRequest
 */
export const GetSchoolHomeworkHandInSummaryRequest =
  new GetSchoolHomeworkHandInSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolHomeworkHandInSummaryResponse$Type extends MessageType<GetSchoolHomeworkHandInSummaryResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkHandInSummaryResponse',
      [
        {
          no: 1,
          name: 'school_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'completion_over_period',
          kind: 'message',
          T: () => HandInRates,
        },
        {
          no: 3,
          name: 'student_compulsory_summary',
          kind: 'message',
          T: () => StudentHandInSummary,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetSchoolHomeworkHandInSummaryResponse
 */
export const GetSchoolHomeworkHandInSummaryResponse =
  new GetSchoolHomeworkHandInSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandInRates$Type extends MessageType<HandInRates> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.HandInRates', [
      { no: 1, name: 'compulsory', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 2,
        name: 'compulsory_on_time',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 3, name: 'optional', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 4, name: 'targets', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.HandInRates
 */
export const HandInRates = new HandInRates$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHandInSummary$Type extends MessageType<StudentHandInSummary> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.StudentHandInSummary', [
      { no: 1, name: 'total', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'on_time_completed',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'completed', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'not_started', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 5,
        name: 'on_time_not_started',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'buckets',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'on_time_buckets',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.StudentHandInSummary
 */
export const StudentHandInSummary = new StudentHandInSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndependentLearningSummariesRequest$Type extends MessageType<GetIndependentLearningSummariesRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetIndependentLearningSummariesRequest',
      [
        {
          no: 1,
          name: 'studentIDs',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 2, name: 'dateRange', kind: 'message', T: () => DateRange },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetIndependentLearningSummariesRequest
 */
export const GetIndependentLearningSummariesRequest =
  new GetIndependentLearningSummariesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndependentLearningSummariesResponse$Type extends MessageType<GetIndependentLearningSummariesResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetIndependentLearningSummariesResponse',
      [
        {
          no: 1,
          name: 'summaries',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => IndependentLearningSummary,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetIndependentLearningSummariesResponse
 */
export const GetIndependentLearningSummariesResponse =
  new GetIndependentLearningSummariesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndependentLearningSummary$Type extends MessageType<IndependentLearningSummary> {
  constructor() {
    super('sparx.dataanalysis.aggserver.v1.IndependentLearningSummary', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'questionsAttemptedCount',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'timeSpent', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 4,
        name: 'xpRewarded',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: 'xpRewardedTimesTables',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.IndependentLearningSummary
 */
export const IndependentLearningSummary = new IndependentLearningSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrackedPackageActivitiesHistoryRequest$Type extends MessageType<GetTrackedPackageActivitiesHistoryRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetTrackedPackageActivitiesHistoryRequest',
      [
        {
          no: 1,
          name: 'package_data',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => PackageData,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetTrackedPackageActivitiesHistoryRequest
 */
export const GetTrackedPackageActivitiesHistoryRequest =
  new GetTrackedPackageActivitiesHistoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrackedPackageActivitiesHistoryResponse$Type extends MessageType<GetTrackedPackageActivitiesHistoryResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.aggserver.v1.GetTrackedPackageActivitiesHistoryResponse',
      [
        {
          no: 1,
          name: 'activities',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => QuestionActivitySummary,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.aggserver.v1.GetTrackedPackageActivitiesHistoryResponse
 */
export const GetTrackedPackageActivitiesHistoryResponse =
  new GetTrackedPackageActivitiesHistoryResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.dataanalysis.aggserver.v1.AggServer
 */
export const AggServer = new ServiceType(
  'sparx.dataanalysis.aggserver.v1.AggServer',
  [
    {
      name: 'GetStudentStats',
      options: { 'google.api.http': { get: '/v1/student/{studentID}/stats' } },
      I: GetStudentStatsRequest,
      O: GetStudentStatsResponse,
    },
    {
      name: 'GetNumeriseStudentStats',
      options: { 'google.api.http': { get: '/v1/numerise/{studentID}/stats' } },
      I: GetNumeriseStudentStatsRequest,
      O: GetNumeriseStudentStatsResponse,
    },
    {
      name: 'GetNumeriseStudentLessonsCompletedByWeek',
      options: {
        'google.api.http': { get: '/v1/numerise/{studentID}/lessonscompleted' },
      },
      I: GetNumeriseStudentLessonsCompletedByWeekRequest,
      O: GetNumeriseStudentLessonsCompletedByWeekResponse,
    },
    {
      name: 'GetNumeriseStudentCorrectAnswers',
      options: {
        'google.api.http': {
          get: '/v1/numerise/{studentID}/correctanswersbybucket',
        },
      },
      I: GetNumeriseStudentCorrectAnswersRequest,
      O: GetNumeriseStudentCorrectAnswersResponse,
    },
    {
      name: 'GetNumeriseStudentLessonsWorkedOn',
      options: {
        'google.api.http': { get: '/v1/numerise/{studentID}/lessonsworkedon' },
      },
      I: GetNumeriseStudentLessonsWorkedOnRequest,
      O: GetNumeriseStudentLessonsWorkedOnResponse,
    },
    {
      name: 'GetNumeriseStudentsSummaryStats',
      options: { 'google.api.http': { get: '/v1/numerise/summarystats' } },
      I: GetNumeriseStudentsSummaryStatsRequest,
      O: GetNumeriseStudentsSummaryStatsResponse,
    },
    {
      name: 'GetNumeriseFocusTopics',
      options: { 'google.api.http': { get: '/v1/numerise/focustopics' } },
      I: GetNumeriseFocusTopicsRequest,
      O: GetNumeriseFocusTopicsResponse,
    },
    {
      name: 'GetNumerisePracticeStats',
      options: { 'google.api.http': { get: '/v1/numerise/practicestats' } },
      I: GetNumerisePracticeStatsRequest,
      O: GetNumerisePracticeStatsResponse,
    },
    {
      name: 'GetYearGroupHomeworkCompletion',
      options: {
        'google.api.http': {
          get: '/v1/yeargroup/{yearGroupID}/homeworkcompletion',
        },
      },
      I: GetYearGroupHomeworkCompletionRequest,
      O: GetYearGroupHomeworkCompletionResponse,
    },
    {
      name: 'GetStudentGroupHomeworkCompletion',
      options: {
        'google.api.http': {
          get: '/v1/studentgroup/{studentGroupID}/homeworkcompletion',
        },
      },
      I: GetStudentGroupHomeworkCompletionRequest,
      O: GetStudentGroupHomeworkCompletionResponse,
    },
    {
      name: 'GetSchoolCollectionPackageStats',
      options: {
        'google.api.http': {
          get: '/v1/school/{schoolID}/schoolcollectionpackagestats',
        },
      },
      I: GetSchoolCollectionPackageStatsRequest,
      O: GetSchoolCollectionPackageStatsResponse,
    },
    {
      name: 'ListStudentTaskItemHistories',
      options: {
        'google.api.http': { get: '/v1/student/{studentID}/task_item_history' },
      },
      I: ListStudentTaskItemHistoriesRequest,
      O: ListStudentTaskItemHistoriesResponse,
    },
    {
      name: 'StreamStudentTaskItemHistories',
      serverStreaming: true,
      options: {
        'google.api.http': {
          get: '/v1/student/{studentID}/task_item_history_stream',
        },
        'sparx.api.auth': {
          action: 'read',
          resource: 'aggserver:student:taskitemhistory',
          domain: 'aggservercommon',
        },
      },
      I: StreamStudentTaskItemHistoriesRequest,
      O: StreamStudentTaskItemHistoriesResponse,
    },
    {
      name: 'GetStudentActivityFeed',
      options: {
        'google.api.http': { get: '/v1/student/{studentID}/activity_feed' },
      },
      I: GetStudentActivityFeedRequest,
      O: GetStudentActivityFeedResponse,
    },
    {
      name: 'GetTrackedPackageActivitiesHistory',
      options: {
        'google.api.http': { get: '/v1/tracked_packages/task_item_history' },
      },
      I: GetTrackedPackageActivitiesHistoryRequest,
      O: GetTrackedPackageActivitiesHistoryResponse,
    },
    {
      name: 'GetLUAttemptInfo',
      options: {
        'google.api.http': { get: '/v1/student/{student_id}/lu_attempt_info' },
      },
      I: GetLUAttemptInfoRequest,
      O: GetLUAttemptInfoResponse,
    },
    {
      name: 'GetSchoolHomeworkCompletionReport',
      options: {
        'google.api.http': {
          get: '/v1/school/{school_id}/homework_completion_report',
        },
      },
      I: GetSchoolHomeworkCompletionReportRequest,
      O: GetSchoolHomeworkCompletionReportResponse,
    },
    {
      name: 'GetSchoolHasHomework',
      options: {
        'google.api.http': { get: '/v1/school/{school_id}/has_homework' },
      },
      I: GetSchoolHasHomeworkRequest,
      O: GetSchoolHasHomeworkResponse,
    },
    {
      name: 'GetStudentXPRewards',
      options: {
        'google.api.http': { get: '/v1/student/{student_ids}/xp_rewards' },
      },
      I: GetStudentXPRewardsRequest,
      O: GetStudentXPRewardsResponse,
    },
    {
      name: 'StartClearStudentsTIHStates',
      options: {},
      I: StartClearStudentsTIHStatesRequest,
      O: StartClearStudentsTIHStatesResponse,
    },
    {
      name: 'GetSchoolHomeworkHandInSummary',
      options: {
        'google.api.http': {
          get: '/v1/school/{school_id}/school_homework_hand_in_summary',
        },
      },
      I: GetSchoolHomeworkHandInSummaryRequest,
      O: GetSchoolHomeworkHandInSummaryResponse,
    },
    {
      name: 'GetIndependentLearningSummaries',
      options: {
        'google.api.http': {
          get: '/v1/student/{studentIDs}/independent_learning_summaries',
        },
      },
      I: GetIndependentLearningSummariesRequest,
      O: GetIndependentLearningSummariesResponse,
    },
  ],
);

import { PauseState, SittingState } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { serverTimeToLocal } from 'api/timeoffset';

export const formatElapsedTime = (seconds: number) => {
  // Ensure not negative
  seconds = Math.max(0, seconds);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${hours ? `${String(hours).padStart(2, '0')}:` : ''}${hours ? String(minutes).padStart(2, '0') : minutes}:${String(remainingSeconds).padStart(2, '0')}`;
};

export const getElapsedTimeSeconds = (sitting: SittingState | undefined) => {
  const now = serverTimeToLocal(new Date());
  let start = sitting?.startTimestamp?.seconds;
  if (!start) return 0;

  let total = 0;
  for (const event of sitting?.pauseEvents || []) {
    if (event.state === PauseState.PAUSED && event.timestamp) {
      total += event.timestamp.seconds - start;
    }
    if (event.timestamp) {
      start = event.timestamp.seconds;
    }
  }
  if (sitting?.endTimestamp) {
    total += sitting?.endTimestamp.seconds - start;
  } else if (sitting?.pauseState === PauseState.ACTIVE) {
    total += now.getTime() / 1000 - start;
  }
  return total;
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/leaguetablegame/v1/leaguetablegame.proto" (package "sparx.games.state.leaguetablegame.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
//
// League Tables Game does not do any processing on the cloud, so the messages
// below are only here because they are expected by game.go

/**
 * @generated from protobuf message sparx.games.state.leaguetablegame.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: uint32 quizPhasesCompleted = 2;
   */
  quizPhasesCompleted: number;
  /**
   * @generated from protobuf field: repeated sparx.games.state.leaguetablegame.v1.Medal alertedMedals = 3;
   */
  alertedMedals: Medal[];
}
/**
 * @generated from protobuf message sparx.games.state.leaguetablegame.v1.State
 */
export interface State {}
/**
 * @generated from protobuf message sparx.games.state.leaguetablegame.v1.Init
 */
export interface Init {}
/**
 *
 * The only action fired by LeagueTables is a "quitGame" action, called when
 * the user presses an in-game "quit" button
 *
 * @generated from protobuf message sparx.games.state.leaguetablegame.v1.Action
 */
export interface Action {
  /**
   * the values that can be sent with an action
   *
   * @generated from protobuf field: sparx.games.state.leaguetablegame.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * the names of actions that can be sent
 *
 * @generated from protobuf enum sparx.games.state.leaguetablegame.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * @generated from protobuf enum value: quitGame = 0;
   */
  quitGame = 0,
}
/**
 * @generated from protobuf message sparx.games.state.leaguetablegame.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.leaguetablegame.v1.Params
 */
export interface Params {}
/**
 * @generated from protobuf message sparx.games.state.leaguetablegame.v1.WeekIndex
 */
export interface WeekIndex {
  /**
   * @generated from protobuf field: int32 week = 1;
   */
  week: number;
  /**
   * @generated from protobuf field: int32 year = 2;
   */
  year: number;
}
/**
 * @generated from protobuf message sparx.games.state.leaguetablegame.v1.Medal
 */
export interface Medal {
  /**
   * @generated from protobuf field: string class = 3;
   */
  class: string;
  /**
   * @generated from protobuf field: string medalName = 4;
   */
  medalName: string;
  /**
   * @generated from protobuf field: uint32 score = 5;
   */
  score: number;
  /**
   * @generated from protobuf field: string gameId = 6;
   */
  gameId: string;
  /**
   * @generated from protobuf field: sparx.games.state.leaguetablegame.v1.WeekIndex weekIndex = 7;
   */
  weekIndex?: WeekIndex;
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.leaguetablegame.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'quizPhasesCompleted',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 3,
        name: 'alertedMedals',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Medal,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.leaguetablegame.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.leaguetablegame.v1.State', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.leaguetablegame.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.leaguetablegame.v1.Init', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.leaguetablegame.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.leaguetablegame.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.leaguetablegame.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.leaguetablegame.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.leaguetablegame.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.leaguetablegame.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.leaguetablegame.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.leaguetablegame.v1.Params
 */
export const Params = new Params$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WeekIndex$Type extends MessageType<WeekIndex> {
  constructor() {
    super('sparx.games.state.leaguetablegame.v1.WeekIndex', [
      { no: 1, name: 'week', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.leaguetablegame.v1.WeekIndex
 */
export const WeekIndex = new WeekIndex$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Medal$Type extends MessageType<Medal> {
  constructor() {
    super('sparx.games.state.leaguetablegame.v1.Medal', [
      { no: 3, name: 'class', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'medalName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'score', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 6, name: 'gameId', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'weekIndex', kind: 'message', T: () => WeekIndex },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.leaguetablegame.v1.Medal
 */
export const Medal = new Medal$Type();

import { checkInput, findChoiceGroupForRef, IInput } from '@sparx/question';
import isEqual from 'lodash.isequal';

// find the references used in the gapEvaluations which has been changed in the input
export const findChangedGapEvalRef = (a: IInput, b: IInput) => {
  const basicFields: (keyof Omit<IInput, 'styles'>)[] = [
    'number_fields',
    'text_fields',
    'expression_fields',
    'upload_fields',
    'media_fields',
    'slots',
  ];

  const changedRefs: string[] = [];

  for (const field of basicFields) {
    const refs = findChangedRefs(a, b, field);
    if (refs.length > 0) {
      changedRefs.push(...refs);
    }
  }

  // For choices we have the choice group reference in the gapEvaluations so need to find the group reference
  const choiceRefs = findChangedRefs(a, b, 'choices');
  if (choiceRefs.length > 0) {
    for (const ref of choiceRefs) {
      const group = findChoiceGroupForRef(a, ref);
      if (group && !changedRefs.includes(group[0])) {
        changedRefs.push(group[0]);
      }
    }
  }

  return changedRefs;
};

const findChangedRefs = (a: IInput, b: IInput, c: keyof Omit<IInput, 'styles'>) => {
  const changed: string[] = [];
  for (const k of Object.keys(a[c] || {})) {
    if (!isEqual(a[c]?.[k], b[c]?.[k])) {
      changed.push(k);
    }
  }
  return changed;
};

export const answerComponentsSame = (a?: Record<string, string>, b?: Record<string, string>) => {
  for (const k of new Set([...Object.keys(a || {}), ...Object.keys(b || {})])) {
    if (!isEqual(a?.[k], b?.[k])) {
      return false;
    }
  }
  return true;
};

// returns whether the submit button should be enabled or not, the default case is just
// that each input has a value but if this is a second chance then we also require that
// the input has changed as their previous answer is persisted across across attempts
// even when incorrect
export const canSubmit = (input: IInput = {}, changedRefs: string[], isSecondChance: boolean) => {
  const check = checkInput(input);
  if (!check) {
    return false;
  }

  if (!isSecondChance) {
    return true;
  }

  // If this is second chance then require that *something* has been changed,
  // this handles questions where we persist all answers (including incorrect)
  if (changedRefs.length === 0) {
    return false; // nothing has been changed.
  }

  return true;
};

import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  Activity,
  ActivityActionRequest,
  ActivityActionResponse,
} from '@sparx/api/apis/sparx/packageactivity/v1/activity';
import {
  answersToAnswerComponents,
  buildStepAnswer,
  countNonMediaEntries,
  IInput,
  inputEntryCounts,
} from '@sparx/question';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';
import { activitiesClient } from 'api/clients';
import { updatePackage } from 'api/packages';
import { queryClient } from 'app/queryClient';

// TODO: THIS FILE IS MOSTLY COPIED FROM SCIENCE!

export const useActivity = (taskItemName?: string) =>
  useQuery({
    queryKey: ['activity', taskItemName],
    queryFn: async () =>
      activitiesClient
        .getActivity({ name: '', taskItemName: taskItemName || '' })
        .response.then(r => r.activity!),
    // TODO: re-enable this?
    // onSuccess: act => {
    // if (isSparxOverriddenCorrect(act.annotations)) {
    //   // If this activity was marked correct by sparx (due to some issue),
    //   // then reload the package to ensure we are showing the correct state
    //   reloadPackage(taskItemName.split('/')[1]);
    // }
    // },
    enabled: Boolean(taskItemName),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: 0, // instantly remove when not needed
  });

export const updateActivityState = (activity: Activity) =>
  queryClient.setQueryData(['activity', activity.taskItemName], activity);

export const reloadActivity = (taskItemName: string) =>
  queryClient.invalidateQueries(['activity', taskItemName]);

export const reloadPackage = (packageID: string) =>
  queryClient.invalidateQueries(['packages', packageID]);

interface ActivityAction {
  action: ActivityActionRequest['action'];
  skipActivityUpdate?: boolean;
}

/**
 * useActivityAction is a mutation function that will update the activity state
 * based on a user action.
 *
 * A token is sent with the activity. If this token does not match the one on
 * the server then will return an 'invalid token' error and the activity should
 * be reloaded.
 *
 * The mutation will try to prevent multiple activity actions from being dispatched
 * at the same time. If mutate is called while another is in progress then
 * it will be a noop. The mutateAsync function will return undefined if an action
 * is already in progress.
 */
export const useActivityAction = (activity: Activity) => {
  const { mutate, mutateAsync, isLoading } = useMutation({
    mutationKey: ['activity', activity.name, 'action'],
    mutationFn: async (action: ActivityAction) =>
      activitiesClient.activityAction({
        name: activity.name,
        action: action.action,
        token: activity.state?.token || '',
      }).response,
    onSuccess: (data, req) => {
      data.activity && !req.skipActivityUpdate && updateActivityState(data.activity);
      data.packageUpdate && updatePackage(data.packageUpdate);
    },
    onError: async err => {
      if (err instanceof RpcError && err.code === 'FAILED_PRECONDITION') {
        await Promise.all([
          reloadActivity(activity.taskItemName),
          reloadPackage(activity.taskItemName.split('/')[1]),
        ]);
      }
    },
  });

  return {
    mutate: isLoading
      ? () => {
          /* noop */
        }
      : mutate,
    mutateAsync: isLoading ? undefined : mutateAsync,
    isLoading,
  };
};

/**
 * useActivityNotify is like useActivityAction but doesn't expect an updated activity
 * in the response. This should mainly be used for 'notify' events that are just telling
 * the server of an action rather than actions that expect an updated activity as a
 * response.
 *
 * The request will take a blank token so the token on the activity is not updated.
 */
export const useActivityNotify = () =>
  useMutation({
    mutationFn: async ({
      activityName,
      action,
    }: {
      activityName: string;
      action: ActivityAction['action'];
    }) =>
      activitiesClient.activityAction({
        name: activityName,
        action,
        token: '', // No token is provided here - we don't want to regenerate it
      }).response,
  });

export const useSubmitAnswer = (
  mutateAsync: ((action: ActivityAction) => Promise<ActivityActionResponse>) | undefined,
  opts?: UseMutationOptions<ActivityActionResponse | void, RpcError, IInput>,
) => {
  return useMutation({
    ...opts,
    mutationFn: async (input: IInput) => {
      if (!mutateAsync) return;

      const answer = buildStepAnswer(input);

      // If the only input for this question are media fields then we can auto
      // progress this step on submit.
      const entryCounts = inputEntryCounts(input);
      const nonInputEntries = countNonMediaEntries(entryCounts);

      const autoProgressStep = nonInputEntries === 0 && entryCounts.media_fields > 0;
      // // Upload the file assets
      // for (const [key, file] of getAnswerFiles(input)) {
      //   if (file.file) {
      //     console.log('uploading file', file.file);
      //     const uploadedAsset = await uploadFile(file.file);
      //     answer[key] = { file: uploadedAsset };
      //     console.log('uploaded file', uploadedAsset);
      //   }
      // }

      const components = answersToAnswerComponents(answer);
      return mutateAsync({
        action: {
          oneofKind: 'answer',
          answer: {
            components,
            autoProgressStep,
          },
        },
      });
    },
  });
};

import { Input } from '@sparx/sparx-design/components/input/Input';
import { ChangeEvent, KeyboardEvent, useRef } from 'react';

import styles from './CodeInput.module.css';

interface CodeInputProps {
  length: number;
  input: string[];
  setInput: (input: string[]) => void;
  isDisabled?: boolean;
  isError?: boolean;
}

const valid = /^[A-Z0-9]+$/;

export const CodeInput = ({ length, input, setInput, isError, isDisabled }: CodeInputProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const getElement = (index: number) =>
    containerRef.current?.querySelector(`input[data-index="${index}"]`);
  const focusElement = (index: number) => {
    const element = getElement(index) as HTMLInputElement | undefined;
    setTimeout(() => element?.select(), 0);
  };

  const onChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    if (value != '' && !value.match(valid)) {
      return;
    }
    const newInput = [...input];
    if (value == '') {
      newInput[index] = '';
    }
    let maxIndex = index;
    for (let i = 0; i < value.length; i++) {
      if (index + i >= length) {
        break;
      }
      maxIndex = index + i;
      newInput[index + i] = value[i];
    }
    setInput(newInput);
    if (value != '') {
      focusElement(Math.min(maxIndex + 1, length - 1));
    }
  };

  const onKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowLeft' && index > 0) {
      focusElement(index - 1);
    } else if (e.key === 'ArrowRight' && index < length - 1) {
      focusElement(index + 1);
    } else if (e.key === 'Backspace' && input[index]) {
      const newInput = [...input];
      newInput[index] = '';
      setInput(newInput);
    } else if (e.key === 'Backspace' && index > 0) {
      const newInput = [...input];
      newInput[index - 1] = '';
      setInput(newInput);
      focusElement(index - 1);
    }
  };

  const onClick = (index: number) => () => {
    focusElement(index);
  };

  return (
    <div ref={containerRef} className={styles.Container}>
      {[...Array(length)].map((_, index) => (
        <Input
          className={styles.Input}
          data-index={index}
          key={index}
          value={input[index] || ''}
          onChange={onChange(index)}
          onKeyDown={onKeyDown(index)}
          onClick={onClick(index)}
          disabled={isDisabled}
          isInvalid={isError}
          type="text"
        />
      ))}
    </div>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/package.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { Completion } from '../../../progress/v1/completion';
import { Subject } from '../../v1/subject';
/**
 * @generated from protobuf message sparx.science.packages.v1.SubjectCompletion
 */
export interface SubjectCompletion {
  /**
   * @generated from protobuf field: sparx.science.v1.Subject subject = 1;
   */
  subject: Subject;
  /**
   * @generated from protobuf field: sparx.progress.v1.Completion completion = 2;
   */
  completion?: Completion;
}
/**
 * Package is a group of work assigned to a student for a given time period.
 * The package contains multiple Tasks.
 *
 * @generated from protobuf message sparx.science.packages.v1.Package
 */
export interface Package {
  /**
   * The resource name of the Package. In the format `packages/-`.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * User friendly name.
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * Type of the package. Arbitrary string.
   *
   * @generated from protobuf field: string type = 3;
   */
  type: string;
  /**
   * Assignment of the Package to a shared unit of work.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Package.Assignment assignment = 4;
   */
  assignment?: Package_Assignment;
  /**
   * Current state of the Package.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Package.State state = 5;
   */
  state?: Package_State;
  /**
   * Contents of the Package.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Package.Contents contents = 6;
   */
  contents?: Package_Contents;
  /**
   * Timestamp that the user should be able to start working on this Package.
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_timestamp = 7;
   */
  startTimestamp?: Timestamp;
  /**
   * Timestamp that this Package is 'due'.
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_timestamp = 8;
   */
  endTimestamp?: Timestamp;
  /**
   * Additional arbitrary annotations on a Package.
   *
   * @generated from protobuf field: map<string, string> annotations = 9;
   */
  annotations: {
    [key: string]: string;
  };
  /**
   * When this package was cancelled.
   *
   * @generated from protobuf field: google.protobuf.Timestamp cancelled_time = 12;
   */
  cancelledTime?: Timestamp;
}
/**
 * Assignment describes the assignment of this package to a shared unit
 * of work, such as a homework or lesson.
 *
 * @generated from protobuf message sparx.science.packages.v1.Package.Assignment
 */
export interface Package_Assignment {
  /**
   * @generated from protobuf field: string assignment_id = 1;
   */
  assignmentId: string;
  /**
   * ID of the lesson this package is assigned to if any.
   *
   * @generated from protobuf field: string lesson_id = 2;
   */
  lessonId: string;
  /**
   * ID of the lesson activity this package is assigned to if any.
   *
   * @generated from protobuf field: string lesson_activity_id = 3;
   */
  lessonActivityId: string;
}
/**
 * State describes the current state of a Package.
 *
 * @generated from protobuf message sparx.science.packages.v1.Package.State
 */
export interface Package_State {
  /**
   * Completion store describing the percentages of progress on this
   * Package.
   *
   * @generated from protobuf field: sparx.progress.v1.Completion completion = 1;
   */
  completion?: Completion;
  /**
   * Completion store describing the percentages of progress on this
   * Package before the end timestamp of the assignment
   *
   * @generated from protobuf field: sparx.progress.v1.Completion ontime_completion = 5;
   */
  ontimeCompletion?: Completion;
  /**
   * Completion store describing the percentages of progress on this
   * Package by subject.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.SubjectCompletion subject_completion = 7;
   */
  subjectCompletion: SubjectCompletion[];
  /**
   * Completion store describing the percentages of progress on this
   * Package by subject before the end timestamp of the assignment
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.SubjectCompletion ontime_subject_completion = 8;
   */
  ontimeSubjectCompletion: SubjectCompletion[];
  /**
   * Time student spent on questions on this package.
   *
   * @generated from protobuf field: double question_time_seconds = 2;
   */
  questionTimeSeconds: number;
  /**
   * Time student spent on support on this package.
   *
   * @generated from protobuf field: double support_time_seconds = 3;
   */
  supportTimeSeconds: number;
  /**
   * Timestamp that the package was completed.
   *
   * @generated from protobuf field: google.protobuf.Timestamp completion_timestamp = 4;
   */
  completionTimestamp?: Timestamp;
  /**
   * Timestamp of the last activity on this package.
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_activity_timestamp = 6;
   */
  lastActivityTimestamp?: Timestamp;
}
/**
 * Contents describes the contents of the Package.
 *
 * @generated from protobuf message sparx.science.packages.v1.Package.Contents
 */
export interface Package_Contents {
  /**
   * List of tasks that make up the contents of this Package.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Task tasks = 1;
   */
  tasks: Task[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.PackageSummary
 */
export interface PackageSummary {
  /**
   * The resource name of the Package. In the format `packages/-`.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Type of the package. Arbitrary string.
   *
   * @generated from protobuf field: string type = 2;
   */
  type: string;
  /**
   * Name of the student this package is assigned to. In the format `students/-`.
   *
   * @generated from protobuf field: string student_name = 3;
   */
  studentName: string;
  /**
   * Name of the assignment this package was assigned to.
   *
   * @generated from protobuf field: string assignment_name = 5;
   */
  assignmentName: string;
  /**
   * Current state of the package.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Package.State state = 4;
   */
  state?: Package_State;
  /**
   * Additional arbitrary annotations on the Package.
   *
   * @generated from protobuf field: map<string, string> annotations = 6;
   */
  annotations: {
    [key: string]: string;
  };
  /**
   * When this package was cancelled.
   *
   * @generated from protobuf field: google.protobuf.Timestamp cancelled_time = 7;
   */
  cancelledTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.Task
 */
export interface Task {
  /**
   * The resource name of the Task. In the format `packages/-/tasks/-`.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Index of the task within a package.
   *
   * @generated from protobuf field: int32 task_index = 2;
   */
  taskIndex: number;
  /**
   * User friendly name.
   *
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * Subject this task covers, if it covers a single task only. SUBJECT_UNDEFINED if it covers multiple subjects
   *
   * @generated from protobuf field: sparx.science.v1.Subject subject = 7;
   */
  subject: Subject;
  /**
   * Current state of the Task.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Task.State state = 4;
   */
  state?: Task_State;
  /**
   * Contents of the Task.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Task.Contents contents = 5;
   */
  contents?: Task_Contents;
  /**
   * Additional arbitrary annotations on a Task.
   *
   * @generated from protobuf field: map<string, string> annotations = 6;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * State describes the current state of a Task.
 *
 * @generated from protobuf message sparx.science.packages.v1.Task.State
 */
export interface Task_State {
  /**
   * Completion store describing the percentages of progress on this
   * Task.
   *
   * @generated from protobuf field: sparx.progress.v1.Completion completion = 1;
   */
  completion?: Completion;
  /**
   * Completion store describing the percentages of progress on this
   * Task by subject.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.SubjectCompletion subject_completion = 2;
   */
  subjectCompletion: SubjectCompletion[];
}
/**
 * Contents describes the contents of the Task.
 *
 * @generated from protobuf message sparx.science.packages.v1.Task.Contents
 */
export interface Task_Contents {
  /**
   * List of task items that make up the contents of this Task.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.TaskItem task_items = 1;
   */
  taskItems: TaskItem[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.TaskItem
 */
export interface TaskItem {
  /**
   * The resource name of the Task. In the format `packages/-/tasks/-/items/-`.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * User friendly name.
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * Current state of the TaskItem.
   *
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.State state = 3;
   */
  state?: TaskItem_State;
  /**
   * Contents of the TaskItem.
   *
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Contents contents = 4;
   */
  contents?: TaskItem_Contents;
  /**
   * Additional arbitrary annotations on a TaskItem.
   *
   * @generated from protobuf field: map<string, string> annotations = 5;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * State describes the current state of the TaskItem.
 *
 * @generated from protobuf message sparx.science.packages.v1.TaskItem.State
 */
export interface TaskItem_State {
  /**
   * Current status of this task item.
   *
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Status status = 1;
   */
  status: TaskItem_Status;
  /**
   * Whether this task item is 'completed' or not. It's possible for
   * a task item to have a status other than CORRECT and also be completed.
   *
   * @generated from protobuf field: bool completed = 2;
   */
  completed: boolean;
  /**
   * Number of correct activities on this task item.
   *
   * @generated from protobuf field: int32 correct_activities = 3;
   */
  correctActivities: number;
  /**
   * Number of incorrect activities on this task item.
   *
   * @generated from protobuf field: int32 incorrect_activities = 4;
   */
  incorrectActivities: number;
  /**
   * Number of incorrect answers including individual steps during multistep questions
   *
   * @generated from protobuf field: int32 incorrect_answers = 9;
   */
  incorrectAnswers: number;
  /**
   * Timestamp when the task item was last answered. TODO: needed?
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_answered_timestamp = 6;
   */
  lastAnsweredTimestamp?: Timestamp;
  /**
   * Whether the first answer answered this task item correctly first time.
   *
   * @generated from protobuf field: bool correct_first_time = 7;
   */
  correctFirstTime: boolean;
  /**
   * The current status of the task item was set by teacher intervention.
   * Future student attempts at this task item will reset this flag.
   *
   * @generated from protobuf field: bool teacher_set_status = 8;
   */
  teacherSetStatus: boolean;
  /**
   * Arbitrary annotations on the task item state.
   *
   * @generated from protobuf field: map<string, string> annotations = 10;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * Contents describes the contents of the TaskItem.
 *
 * @generated from protobuf message sparx.science.packages.v1.TaskItem.Contents
 */
export interface TaskItem_Contents {
  /**
   * Subject this task item covers
   *
   * @generated from protobuf field: sparx.science.v1.Subject subject = 1;
   */
  subject: Subject;
  /**
   * @generated from protobuf oneof: contents
   */
  contents:
    | {
        oneofKind: 'skill';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Contents.Skill skill = 4;
         */
        skill: TaskItem_Contents_Skill;
      }
    | {
        oneofKind: 'rapidFire';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.RapidFireContents rapid_fire = 5;
         */
        rapidFire: RapidFireContents;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.TaskItem.Contents.Skill
 */
export interface TaskItem_Contents_Skill {
  /**
   * @generated from protobuf field: string skill_id = 1;
   */
  skillId: string;
  /**
   * When set, will fix the question on this task item. This value can be
   * empty to use standard question selection. The skill_id must still be
   * set. If the provided question_id does not exist for the skill then
   * an error will be logged and the standard question selection algorithm
   * will be used.
   *
   * If the question_id is a support question the question will be delivered
   * immediately as a support question. If the question is not and there are
   * support questions for this skill then a support question will be
   * delivered when the student has answered the question incorrectly.
   *
   * @generated from protobuf field: string question_id = 4;
   */
  questionId: string;
  /**
   * @generated from protobuf field: string topic_name = 2;
   */
  topicName: string;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Contents.Skill.Section section = 3;
   */
  section: TaskItem_Contents_Skill_Section;
}
/**
 * @generated from protobuf enum sparx.science.packages.v1.TaskItem.Contents.Skill.Section
 */
export enum TaskItem_Contents_Skill_Section {
  /**
   * @generated from protobuf enum value: SECTION_UNSPECIFIED = 0;
   */
  SECTION_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: INFOCUS = 1;
   */
  INFOCUS = 1,
  /**
   * @generated from protobuf enum value: CONSOLIDATION = 2;
   */
  CONSOLIDATION = 2,
}
/**
 * Status is the status of a TaskItem.
 *
 * @generated from protobuf enum sparx.science.packages.v1.TaskItem.Status
 */
export enum TaskItem_Status {
  /**
   * @generated from protobuf enum value: TASK_ITEM_STATUS_UNSPECIFIED = 0;
   */
  TASK_ITEM_STATUS_UNSPECIFIED = 0,
  /**
   * Correctly answered.
   *
   * @generated from protobuf enum value: CORRECT = 1;
   */
  CORRECT = 1,
  /**
   * Incorrectly answered.
   *
   * @generated from protobuf enum value: INCORRECT = 2;
   */
  INCORRECT = 2,
  /**
   * Skipped.
   *
   * @generated from protobuf enum value: SKIPPED = 3;
   */
  SKIPPED = 3,
  /**
   * Question is correctly answered, but pending teacher evaluation
   *
   * @generated from protobuf enum value: PENDING_CORRECT = 4;
   */
  PENDING_CORRECT = 4,
}
/**
 * @generated from protobuf message sparx.science.packages.v1.RapidFireContents
 */
export interface RapidFireContents {
  /**
   * Number of correct answers needed to complete the task item
   *
   * @generated from protobuf field: int32 size = 1;
   */
  size: number;
  /**
   * Probably temporary for trialing
   *
   * @generated from protobuf field: int32 incorrect_offset = 2;
   */
  incorrectOffset: number;
  /**
   * @generated from protobuf field: repeated string skill_ids = 3;
   */
  skillIds: string[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.InternalTaskItemState
 */
export interface InternalTaskItemState {
  /**
   * @generated from protobuf field: int32 attempts = 1;
   */
  attempts: number;
  /**
   * Generic store of state for the task item updater
   *
   * @generated from protobuf field: bytes updater_store = 3;
   */
  updaterStore: Uint8Array;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.RapidFireState
 */
export interface RapidFireState {
  /**
   * completed & shouldn't be redelivered?
   *
   * @generated from protobuf field: repeated string correct_skill_ids = 1;
   */
  correctSkillIds: string[];
  /**
   * @generated from protobuf field: repeated string incorrect_skill_ids = 2;
   */
  incorrectSkillIds: string[];
  /**
   * ordered list of skills to delivery
   * first skill is the current skill being delivered
   * after answer list reordered as needed.
   * getting a skill correct removes it from the list
   * getting a skill wrong moves it back a number of skills
   *
   * @generated from protobuf field: repeated string skills_to_deliver = 3;
   */
  skillsToDeliver: string[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListPackagesRequest
 */
export interface ListPackagesRequest {
  /**
   * When set, will only return packages for this lesson
   *
   * @generated from protobuf field: string lesson_name = 1;
   */
  lessonName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListPackagesResponse
 */
export interface ListPackagesResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.Package packages = 1;
   */
  packages: Package[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetPackageRequest
 */
export interface GetPackageRequest {
  /**
   * @generated from protobuf field: string package_id = 1;
   */
  packageId: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetPackageResponse
 */
export interface GetPackageResponse {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Package package = 1;
   */
  package?: Package;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListPackageSummariesRequest
 */
export interface ListPackageSummariesRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf oneof: query
   */
  query:
    | {
        oneofKind: 'assignmentName';
        /**
         * @generated from protobuf field: string assignment_name = 2;
         */
        assignmentName: string;
      }
    | {
        oneofKind: 'dates';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ListPackageSummariesRequest.DateQuery dates = 3;
         */
        dates: ListPackageSummariesRequest_DateQuery;
      }
    | {
        oneofKind: 'student';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ListPackageSummariesRequest.StudentQuery student = 4;
         */
        student: ListPackageSummariesRequest_StudentQuery;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListPackageSummariesRequest.DateQuery
 */
export interface ListPackageSummariesRequest_DateQuery {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_timestamp = 1;
   */
  startTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_timestamp = 2;
   */
  endTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListPackageSummariesRequest.StudentQuery
 */
export interface ListPackageSummariesRequest_StudentQuery {
  /**
   * @generated from protobuf field: string student_name = 1;
   */
  studentName: string;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.ListPackageSummariesRequest.DateQuery dates = 2;
   */
  dates?: ListPackageSummariesRequest_DateQuery;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListPackageSummariesResponse
 */
export interface ListPackageSummariesResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.PackageSummary package_summaries = 1;
   */
  packageSummaries: PackageSummary[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.CreateDemoPackageRequest
 */
export interface CreateDemoPackageRequest {
  /**
   * Name of the demo package to create.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.CreateDemoPackageResponse
 */
export interface CreateDemoPackageResponse {
  /**
   * List of packages that were created for this demo. Usually there will only be one.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Package package = 1;
   */
  package: Package[];
}
// @generated message type with reflection information, may provide speed optimized methods
class SubjectCompletion$Type extends MessageType<SubjectCompletion> {
  constructor() {
    super('sparx.science.packages.v1.SubjectCompletion', [
      {
        no: 1,
        name: 'subject',
        kind: 'enum',
        T: () => ['sparx.science.v1.Subject', Subject],
      },
      { no: 2, name: 'completion', kind: 'message', T: () => Completion },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.SubjectCompletion
 */
export const SubjectCompletion = new SubjectCompletion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Package$Type extends MessageType<Package> {
  constructor() {
    super('sparx.science.packages.v1.Package', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'assignment',
        kind: 'message',
        T: () => Package_Assignment,
      },
      { no: 5, name: 'state', kind: 'message', T: () => Package_State },
      { no: 6, name: 'contents', kind: 'message', T: () => Package_Contents },
      { no: 7, name: 'start_timestamp', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'end_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      { no: 12, name: 'cancelled_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Package
 */
export const Package = new Package$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Package_Assignment$Type extends MessageType<Package_Assignment> {
  constructor() {
    super('sparx.science.packages.v1.Package.Assignment', [
      {
        no: 1,
        name: 'assignment_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'lesson_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'lesson_activity_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Package.Assignment
 */
export const Package_Assignment = new Package_Assignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Package_State$Type extends MessageType<Package_State> {
  constructor() {
    super('sparx.science.packages.v1.Package.State', [
      { no: 1, name: 'completion', kind: 'message', T: () => Completion },
      {
        no: 5,
        name: 'ontime_completion',
        kind: 'message',
        T: () => Completion,
      },
      {
        no: 7,
        name: 'subject_completion',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SubjectCompletion,
      },
      {
        no: 8,
        name: 'ontime_subject_completion',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SubjectCompletion,
      },
      {
        no: 2,
        name: 'question_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 3,
        name: 'support_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 4,
        name: 'completion_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 6,
        name: 'last_activity_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Package.State
 */
export const Package_State = new Package_State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Package_Contents$Type extends MessageType<Package_Contents> {
  constructor() {
    super('sparx.science.packages.v1.Package.Contents', [
      {
        no: 1,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Task,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Package.Contents
 */
export const Package_Contents = new Package_Contents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageSummary$Type extends MessageType<PackageSummary> {
  constructor() {
    super('sparx.science.packages.v1.PackageSummary', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'assignment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'state', kind: 'message', T: () => Package_State },
      {
        no: 6,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      { no: 7, name: 'cancelled_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.PackageSummary
 */
export const PackageSummary = new PackageSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Task$Type extends MessageType<Task> {
  constructor() {
    super('sparx.science.packages.v1.Task', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'task_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'subject',
        kind: 'enum',
        T: () => ['sparx.science.v1.Subject', Subject],
      },
      { no: 4, name: 'state', kind: 'message', T: () => Task_State },
      { no: 5, name: 'contents', kind: 'message', T: () => Task_Contents },
      {
        no: 6,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Task
 */
export const Task = new Task$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Task_State$Type extends MessageType<Task_State> {
  constructor() {
    super('sparx.science.packages.v1.Task.State', [
      { no: 1, name: 'completion', kind: 'message', T: () => Completion },
      {
        no: 2,
        name: 'subject_completion',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SubjectCompletion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Task.State
 */
export const Task_State = new Task_State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Task_Contents$Type extends MessageType<Task_Contents> {
  constructor() {
    super('sparx.science.packages.v1.Task.Contents', [
      {
        no: 1,
        name: 'task_items',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TaskItem,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Task.Contents
 */
export const Task_Contents = new Task_Contents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskItem$Type extends MessageType<TaskItem> {
  constructor() {
    super('sparx.science.packages.v1.TaskItem', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'state', kind: 'message', T: () => TaskItem_State },
      { no: 4, name: 'contents', kind: 'message', T: () => TaskItem_Contents },
      {
        no: 5,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.TaskItem
 */
export const TaskItem = new TaskItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskItem_State$Type extends MessageType<TaskItem_State> {
  constructor() {
    super('sparx.science.packages.v1.TaskItem.State', [
      {
        no: 1,
        name: 'status',
        kind: 'enum',
        T: () => ['sparx.science.packages.v1.TaskItem.Status', TaskItem_Status],
      },
      { no: 2, name: 'completed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'correct_activities',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'incorrect_activities',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 9,
        name: 'incorrect_answers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'last_answered_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 7,
        name: 'correct_first_time',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 8,
        name: 'teacher_set_status',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 10,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.TaskItem.State
 */
export const TaskItem_State = new TaskItem_State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskItem_Contents$Type extends MessageType<TaskItem_Contents> {
  constructor() {
    super('sparx.science.packages.v1.TaskItem.Contents', [
      {
        no: 1,
        name: 'subject',
        kind: 'enum',
        T: () => ['sparx.science.v1.Subject', Subject],
      },
      {
        no: 4,
        name: 'skill',
        kind: 'message',
        oneof: 'contents',
        T: () => TaskItem_Contents_Skill,
      },
      {
        no: 5,
        name: 'rapid_fire',
        kind: 'message',
        oneof: 'contents',
        T: () => RapidFireContents,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.TaskItem.Contents
 */
export const TaskItem_Contents = new TaskItem_Contents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskItem_Contents_Skill$Type extends MessageType<TaskItem_Contents_Skill> {
  constructor() {
    super('sparx.science.packages.v1.TaskItem.Contents.Skill', [
      { no: 1, name: 'skill_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'section',
        kind: 'enum',
        T: () => [
          'sparx.science.packages.v1.TaskItem.Contents.Skill.Section',
          TaskItem_Contents_Skill_Section,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.TaskItem.Contents.Skill
 */
export const TaskItem_Contents_Skill = new TaskItem_Contents_Skill$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RapidFireContents$Type extends MessageType<RapidFireContents> {
  constructor() {
    super('sparx.science.packages.v1.RapidFireContents', [
      { no: 1, name: 'size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'incorrect_offset',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'skill_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.RapidFireContents
 */
export const RapidFireContents = new RapidFireContents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalTaskItemState$Type extends MessageType<InternalTaskItemState> {
  constructor() {
    super('sparx.science.packages.v1.InternalTaskItemState', [
      { no: 1, name: 'attempts', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'updater_store',
        kind: 'scalar',
        T: 12 /*ScalarType.BYTES*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.InternalTaskItemState
 */
export const InternalTaskItemState = new InternalTaskItemState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RapidFireState$Type extends MessageType<RapidFireState> {
  constructor() {
    super('sparx.science.packages.v1.RapidFireState', [
      {
        no: 1,
        name: 'correct_skill_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'incorrect_skill_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'skills_to_deliver',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.RapidFireState
 */
export const RapidFireState = new RapidFireState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPackagesRequest$Type extends MessageType<ListPackagesRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListPackagesRequest', [
      {
        no: 1,
        name: 'lesson_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListPackagesRequest
 */
export const ListPackagesRequest = new ListPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPackagesResponse$Type extends MessageType<ListPackagesResponse> {
  constructor() {
    super('sparx.science.packages.v1.ListPackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListPackagesResponse
 */
export const ListPackagesResponse = new ListPackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackageRequest$Type extends MessageType<GetPackageRequest> {
  constructor() {
    super('sparx.science.packages.v1.GetPackageRequest', [
      { no: 1, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetPackageRequest
 */
export const GetPackageRequest = new GetPackageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackageResponse$Type extends MessageType<GetPackageResponse> {
  constructor() {
    super('sparx.science.packages.v1.GetPackageResponse', [
      { no: 1, name: 'package', kind: 'message', T: () => Package },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetPackageResponse
 */
export const GetPackageResponse = new GetPackageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPackageSummariesRequest$Type extends MessageType<ListPackageSummariesRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListPackageSummariesRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assignment_name',
        kind: 'scalar',
        oneof: 'query',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'dates',
        kind: 'message',
        oneof: 'query',
        T: () => ListPackageSummariesRequest_DateQuery,
      },
      {
        no: 4,
        name: 'student',
        kind: 'message',
        oneof: 'query',
        T: () => ListPackageSummariesRequest_StudentQuery,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListPackageSummariesRequest
 */
export const ListPackageSummariesRequest =
  new ListPackageSummariesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPackageSummariesRequest_DateQuery$Type extends MessageType<ListPackageSummariesRequest_DateQuery> {
  constructor() {
    super('sparx.science.packages.v1.ListPackageSummariesRequest.DateQuery', [
      { no: 1, name: 'start_timestamp', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'end_timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListPackageSummariesRequest.DateQuery
 */
export const ListPackageSummariesRequest_DateQuery =
  new ListPackageSummariesRequest_DateQuery$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPackageSummariesRequest_StudentQuery$Type extends MessageType<ListPackageSummariesRequest_StudentQuery> {
  constructor() {
    super(
      'sparx.science.packages.v1.ListPackageSummariesRequest.StudentQuery',
      [
        {
          no: 1,
          name: 'student_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'dates',
          kind: 'message',
          T: () => ListPackageSummariesRequest_DateQuery,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListPackageSummariesRequest.StudentQuery
 */
export const ListPackageSummariesRequest_StudentQuery =
  new ListPackageSummariesRequest_StudentQuery$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPackageSummariesResponse$Type extends MessageType<ListPackageSummariesResponse> {
  constructor() {
    super('sparx.science.packages.v1.ListPackageSummariesResponse', [
      {
        no: 1,
        name: 'package_summaries',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PackageSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListPackageSummariesResponse
 */
export const ListPackageSummariesResponse =
  new ListPackageSummariesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateDemoPackageRequest$Type extends MessageType<CreateDemoPackageRequest> {
  constructor() {
    super('sparx.science.packages.v1.CreateDemoPackageRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CreateDemoPackageRequest
 */
export const CreateDemoPackageRequest = new CreateDemoPackageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateDemoPackageResponse$Type extends MessageType<CreateDemoPackageResponse> {
  constructor() {
    super('sparx.science.packages.v1.CreateDemoPackageResponse', [
      {
        no: 1,
        name: 'package',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CreateDemoPackageResponse
 */
export const CreateDemoPackageResponse = new CreateDemoPackageResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.packages.v1.PackageService
 */
export const PackageService = new ServiceType(
  'sparx.science.packages.v1.PackageService',
  [
    {
      name: 'ListPackages',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: ListPackagesRequest,
      O: ListPackagesResponse,
    },
    {
      name: 'GetPackage',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: GetPackageRequest,
      O: GetPackageResponse,
    },
    {
      name: 'ListPackageSummaries',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:homework',
          domain: '{school_name}',
        },
      },
      I: ListPackageSummariesRequest,
      O: ListPackageSummariesResponse,
    },
    {
      name: 'CreateDemoPackage',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'sw:student',
          domain: 'sw',
        },
      },
      I: CreateDemoPackageRequest,
      O: CreateDemoPackageResponse,
    },
  ],
);

import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assessment, AssessmentStatus } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useSubject } from 'api/subjects';
import classNames from 'classnames';
import styles from 'components/assessmentselector/AssessmentCard.module.css';

export const AssessmentCard = ({
  assessment,
  selected,
  onSelect,
}: {
  assessment: Assessment;
  selected?: boolean;
  onSelect?: () => void;
}) => {
  const { data: subject } = useSubject(assessment.subjectKey);

  return (
    <div
      className={classNames(styles.Card, {
        [styles.CardSelectable]: Boolean(onSelect),
        [styles.CardSelected]: selected,
      })}
      onClick={onSelect}
    >
      {subject && (
        <div className={styles.SubjectName} style={{ background: subject.color }}>
          <FontAwesomeIcon icon={subject.icon} />
          {subject.name}
        </div>
      )}
      <h3>{assessment.displayName}</h3>
      <Stack className={styles.Chips}>
        {assessment.status === AssessmentStatus.COMING_SOON && (
          <div className={styles.Chip}>Coming soon</div>
        )}
        {Boolean(assessment.ukYearGroup) && (
          <div className={classNames(styles.Chip, styles.YearChip)}>
            Year {assessment.ukYearGroup}
          </div>
        )}
        {Boolean(assessment.timeAllowedMinutes) && (
          <div className={classNames(styles.Chip, styles.ClockChip)}>
            <FontAwesomeIcon icon={faClock} />
            {assessment.timeAllowedMinutes} mins
          </div>
        )}
      </Stack>
    </div>
  );
};

import { faFileLines, faGlobe, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Assessment,
  AssessmentQuestion,
  Mark,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { useAssessmentContext } from '../../../context';
import { selectedStudentEvent } from '../analytics';
import { StudentSettings } from '../AssessmentDataEntry';
import styles from './StudentTable.module.css';

interface IStudentTableProps {
  currentStudent: Student | undefined;
  setStudent: (s: Student) => void;
  studentsInGroup: Student[];
  studentAssessments: Map<string, StudentAssessment>;
  assessment: Assessment;
  questionIDToQuestion: Map<string, AssessmentQuestion>;
  studentSettings?: Map<string, StudentSettings>;
}

export const StudentTable = ({
  currentStudent,
  studentsInGroup,
  studentAssessments,
  assessment,
  setStudent,
  questionIDToQuestion,
  studentSettings,
}: IStudentTableProps) => {
  const { sendEvent } = useAssessmentContext();
  const tableRef = useRef<(HTMLTableRowElement | null)[]>([]);

  useEffect(() => {
    if (currentStudent) {
      const index = studentsInGroup.indexOf(currentStudent);
      const r = tableRef.current[index];
      if (index >= 0 && r) {
        r.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [currentStudent, studentsInGroup]);

  return (
    <div className={styles.Container}>
      <table className={styles.Table}>
        <tbody>
          {studentsInGroup.map((s, i) => {
            const marks = studentAssessments.get(s.studentId)?.marks;
            if (!marks) {
              return null;
            }
            const filledInMarksCount = marks.filter((m: Mark) => m.score !== undefined).length;
            const validationErrorsCount = marks.filter((m: Mark) => {
              if (m.score === undefined) {
                return false;
              }
              const q = questionIDToQuestion.get(m.assessmentQuestionName);
              return q ? m.score > q.availableMarks : false;
            }).length;

            const completed =
              filledInMarksCount === assessment.questions.length &&
              currentStudent?.studentId !== s.studentId &&
              !validationErrorsCount;

            const settings = studentSettings?.get(s.studentId);
            const hasPaper = !settings?.hasOnline && filledInMarksCount > 0;

            let progressComponent;
            if (settings?.mode === 'online' && settings.hasOnline) {
              progressComponent = (
                <div className={styles.ProgressStatus}>
                  <FontAwesomeIcon icon={faGlobe} />
                  Online
                </div>
              );
            } else if (settings?.mode === 'paper' && hasPaper) {
              progressComponent = (
                <div className={styles.ProgressStatus}>
                  <FontAwesomeIcon icon={faFileLines} />
                  Paper
                </div>
              );
            } else if (settings?.mode === 'paper' && !settings.hasOnline) {
              progressComponent = <i className={styles.NoResult}>No result</i>;
            } else if (settings?.showResult) {
              let [score, available] = [0, 0];
              for (const m of marks) {
                const q = questionIDToQuestion.get(m.assessmentQuestionName);
                score += Math.min(m.score || 0, q?.availableMarks || 0);
                available += q?.availableMarks || 0;
              }
              progressComponent = (
                <div className={styles.Progress}>
                  {validationErrorsCount > 0 && (
                    <span className={styles.Warning}>
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                      <span>
                        {validationErrorsCount} {validationErrorsCount === 1 ? 'error' : 'errors'}
                      </span>
                    </span>
                  )}
                  <span>
                    {score}/{available} marks
                  </span>
                </div>
              );
            } else {
              progressComponent = (
                <div className={styles.Progress}>
                  {validationErrorsCount > 0 && (
                    <span className={styles.Warning}>
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                      <span>
                        {validationErrorsCount} {validationErrorsCount === 1 ? 'error' : 'errors'}
                      </span>
                    </span>
                  )}
                  <span>
                    {filledInMarksCount}/{assessment.questions.length} questions marked
                  </span>
                </div>
              );
            }

            return (
              <tr
                className={classNames(styles.TableRow, {
                  [styles.TableRowSelected]: currentStudent?.studentId === s.studentId,
                  [styles.TableRowCompleted]: completed,
                })}
                key={s.studentId}
                ref={el => (tableRef.current[i] = el)}
                onClick={() => {
                  sendEvent(selectedStudentEvent(s.studentId));
                  setStudent(s);
                }}
              >
                <td>
                  {s.givenName} {s.familyName}
                </td>
                <td>{progressComponent}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

import {
  Assessment,
  AssessmentStatus,
  Settings,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useDisclosure } from '@sparx/react-utils';
import { ErrorMessage } from '@sparx/sparx-design/components';
import { Button } from '@sparx/sparx-design/components/button/Button';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Switch } from '@sparx/sparx-design/components/switch/Switch';
import { Info } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';

import { useUpdateGroupSettings } from '../../api/assessment';
import { useAssessmentContext } from '../../context';
import { isYearGroup } from '../../utils/groups';
import { SortDirection } from '../../utils/sorting';
import { FollowUpComingSoon, FollowUpNotAvailable } from '../Common/SectionNotAvailable';
import { getSortingLabel } from '../Common/SortingLabel';
import iconFollowUpLarge from '../images/icon-large-follow-up.svg';
import styles from './AssessmentFixUp.module.css';
import { IStudentAssessmentFixUpProgress, useAssessmentFixUp } from './hooks';
import { SortableColumnName, sortFunctions, TableSorting } from './tableSortingUtils';

interface IAssessmentFixUpProps {
  assessment: Assessment;

  students: Student[];
  currentGroup: Group | YearGroup;
  groups: Group[];
}

export const AssessmentFixUp = ({
  assessment,
  students,
  currentGroup,
  groups,
}: IAssessmentFixUpProps) => {
  const { loadingComponent } = useAssessmentContext();

  const {
    isLoading,
    isError,
    groupFixUpSettings,
    fixUpStarted,
    fixUpActive,
    studentFixUpProgress,
    totalMarksAvailable,
  } = useAssessmentFixUp(assessment, currentGroup, students, groups);

  const groupSettingsUpdater = useUpdateGroupSettings();
  const { open, onClose, onOpen } = useDisclosure();

  if (assessment.status === AssessmentStatus.COMING_SOON) {
    return <FollowUpComingSoon />;
  }
  if (assessment.status === AssessmentStatus.WILL_NOT_DO) {
    return <FollowUpNotAvailable />;
  }

  if (isError || groupSettingsUpdater.isError) {
    return <ErrorMessage message="An error has occurred. Please refresh and try again." />;
  }

  if (isLoading) {
    return loadingComponent;
  }

  let displayNamePrefix = '';
  if (assessment.subjectKey !== 'science') {
    displayNamePrefix = 'Fluency ';
  }

  let content = (
    <FixUpTable data={studentFixUpProgress} totalMarksAvailable={totalMarksAvailable} />
  );
  if (!isYearGroup(currentGroup) && !fixUpStarted) {
    content = (
      <NotStarted
        group={currentGroup}
        onEnable={onOpen}
        assessment={assessment}
        displayNamePrefix={displayNamePrefix}
      />
    );
  }

  const onConfirm = () => {
    if (isYearGroup(currentGroup)) {
      onClose();
      return;
    }
    const now = Timestamp.now();
    const groupId = currentGroup.name.split('/')[3];
    // Update the classes fix up settings to make the fix up task started
    const settings: Settings = {
      ...(groupFixUpSettings.get(groupId)?.settings || {
        lastUpdatedBy: '',
        revisionAssessmentGroupName: '',
      }),
    };
    if (fixUpActive) {
      settings.fixUpEndedAt = now;
    } else {
      settings.fixUpStartedAt = now;
      settings.fixUpEndedAt = undefined;
    }

    groupSettingsUpdater.mutate(
      {
        ...(groupFixUpSettings.get(groupId) || {
          groupName: currentGroup.name,
          assessmentName: assessment.name,
        }),
        settings,
      },
      {
        onSuccess: () => {
          onClose();
          groupSettingsUpdater.reset();
        },
      },
    );
  };

  return (
    <>
      {fixUpStarted && !isYearGroup(currentGroup) && (
        <div className={styles.HeadingBar}>
          <p>{displayNamePrefix}Fix Up</p>
          <TaskControls
            active={fixUpActive}
            onChange={onOpen}
            displayNamePrefix={displayNamePrefix}
          />
        </div>
      )}
      {content}
      {!isYearGroup(currentGroup) && (
        <Modal isOpen={open} onClose={onClose}>
          <Modal.Content width="3xl">
            <Modal.CloseButton />
            <Modal.Title>
              {fixUpActive ? 'Disable' : 'Enable'} {assessment.displayName} {displayNamePrefix}Fix
              Up task for {currentGroup.displayName}
            </Modal.Title>
            <Modal.Body>
              <p>
                {fixUpActive
                  ? `This will remove the ${displayNamePrefix}Fix Up task from the Student application, any previous progress will remain. Are you sure you want to continue?`
                  : fixUpStarted
                    ? `This will show the ${displayNamePrefix}Fix Up task in the Student application, any previous progress will remain. Are you sure you want to continue?`
                    : `Enabling a ${displayNamePrefix}Fix Up task for ${currentGroup.displayName} will also show students their overall mark for that assessment. Are you sure you want to continue?`}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button colour="custom" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                colour="blue"
                className={styles.ConfirmAbsent}
                onClick={onConfirm}
                isLoading={groupSettingsUpdater.isLoading}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

const TaskControls = ({
  active,
  onChange,
  displayNamePrefix,
}: {
  active: boolean;
  onChange: (checked: boolean) => void;
  displayNamePrefix: string;
}) => {
  const { open, onOpen, onClose } = useDisclosure();

  return (
    <div className={styles.FixUpControls}>
      <button onClick={onOpen} className={styles.Info}>
        <Info variant={'Blue'} />
      </button>
      <label htmlFor="fix-up-switch">{displayNamePrefix}Fix Up task:</label>
      <Switch
        id="fix-up-switch"
        labelLeft="Off"
        labelRight="On"
        checked={active}
        onCheckedChange={onChange}
      />
      <Modal isOpen={open} onClose={onClose}>
        <Modal.Content width="2xl">
          <Modal.CloseButton />
          <Modal.Title>Fix Up task</Modal.Title>
          <Modal.Body>
            <p>
              After you have entered all assessment mark data you can enable a &apos;
              {displayNamePrefix}Fix Up&apos; task for your students to go back and reattempt any
              incorrect answers. Please note that enabling the Fix Up task will also show students
              their overall mark for that assessment.
              <br />
              <br />
              Disabling the Fix Up task will remove it from view for Students but all completion
              data will still be available to review here. You will be able to enable the Fix Up
              task at any point in the future, should you wish to make it active for students again.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button colour="custom" onClick={onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </div>
  );
};

const NotStarted = ({
  assessment,
  group,
  onEnable,
  displayNamePrefix,
}: {
  assessment: Assessment;
  group: Group;
  onEnable: () => void;
  displayNamePrefix: string;
}) => {
  return (
    <>
      <div className={styles.FeatureHighlight}>
        <img src={iconFollowUpLarge} />
        <div className={styles.FeatureHighlightInfo}>
          <h2>{displayNamePrefix}Fix Up</h2>
          <p>
            {assessment.subjectKey === 'science' ? (
              <>
                Enable a {displayNamePrefix}Fix Up task for your students to focus on topics where
                they need extra support and track their progress.
              </>
            ) : (
              <>
                Enable a &apos;{displayNamePrefix}Fix Up&apos; task for your students to go back and
                reattempt any incorrect answers and gain class level insights into Problem Solving
                question misconceptions.
              </>
            )}
          </p>
          <div className={styles.FeatureHighlightButtons}>
            <Button variant="contained" onClick={onEnable}>
              Turn on {displayNamePrefix}Fix Up task for {group.displayName}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const ProgressCell = ({ row }: { row: IStudentAssessmentFixUpProgress }) => {
  if (!row.fixUpStarted) {
    return <>A fix up task has not been generated for this student&apos;s class</>;
  }

  if (row.totalQuestions === 0) {
    return <>This student had no questions to fix up</>;
  }

  const percentageCompletion = Math.round((row.questionsComplete / row.totalQuestions) * 100);

  // Create an array of length row.totalQuestions, where each element is a number from 0 to row.totalQuestions - 1:
  const questionNumbers = Array.from({ length: row.totalQuestions }, (_, i) => i);
  return (
    <div className={styles.ProgressContainer}>
      <div className={styles.PillContainer}>
        {questionNumbers.map(i => (
          <div
            key={i}
            className={classNames(styles.Pill, {
              [styles.Complete]: i < row.questionsComplete,
            })}
          />
        ))}
      </div>
      <p className={classNames(styles.Completion, styles.Raw)}>
        {row.questionsComplete}/{row.totalQuestions}
      </p>
      <p className={styles.Completion}>
        {row.totalQuestions > 0 && <>({percentageCompletion}%)</>}
      </p>
    </div>
  );
};

const StatusCell = ({ row }: { row: IStudentAssessmentFixUpProgress }) => {
  if (!row.fixUpStarted || row.totalQuestions === 0) {
    return <>-</>;
  }
  if (row.completionDate) {
    return <>Completed - {format(Timestamp.toDate(row.completionDate), 'd MMM yyyy')}</>;
  }
  if (!row.inProgress) {
    return <>Not started</>;
  }
  return <>In progress</>;
};

const FixUpTable = ({
  data,
  totalMarksAvailable,
}: {
  data: IStudentAssessmentFixUpProgress[];
  totalMarksAvailable: number;
}) => {
  const { sendEvent: analytics } = useAssessmentContext();
  const [sortedBy, updateSortedBy] = useState<TableSorting>({
    dir: SortDirection.Ascending,
    sortBy: SortableColumnName.FixUpProgress,
    sortFn: sortFunctions[SortableColumnName.FixUpProgress],
  });

  const sortedProgress = useMemo(
    () => data.sort(sortedBy.sortFn()(sortedBy.dir)),
    [data, sortedBy],
  );

  const sortClickHandler = (name: SortableColumnName) => {
    const newDirection = sortedBy.sortBy === name ? -sortedBy.dir : SortDirection.Ascending;
    analytics({
      action: 'Clicked fix up column header to sort',
      category: 'Resources & Assessments',
      labels: {
        order: newDirection === SortDirection.Ascending ? 'ascending' : 'descending',
        sortedBy: name,
      },
    });
    updateSortedBy({
      dir: newDirection,
      sortBy: name,
      sortFn: sortFunctions[name],
    });
  };

  return (
    <div className={styles.TableContainer}>
      <table className={styles.Table}>
        <thead>
          <tr className={styles.TableHeadRow}>
            <th>
              {getSortingLabel(
                sortedBy,
                sortClickHandler,
                SortableColumnName.StudentName,
                'Student Name',
              )}
            </th>
            <th>
              {getSortingLabel(sortedBy, sortClickHandler, SortableColumnName.Class, 'Class')}
            </th>
            <th>{getSortingLabel(sortedBy, sortClickHandler, SortableColumnName.Mark, 'Mark')}</th>
            <th>
              {getSortingLabel(
                sortedBy,
                sortClickHandler,
                SortableColumnName.FixUpProgress,
                'Fix up progress',
              )}
            </th>
            <th>
              {getSortingLabel(sortedBy, sortClickHandler, SortableColumnName.Status, 'Status')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedProgress.map((row, index) => (
            <tr key={index} className={styles.ThinTableRow}>
              <td>
                {row.firstName} {row.lastName}
              </td>
              <td>{row.studentGroupDisplayName}</td>
              <td>
                {row.marksAwarded}/{totalMarksAvailable}
              </td>
              <td>
                <ProgressCell row={row} />
              </td>
              <td>
                <StatusCell row={row} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import {
  Assessment,
  AssessmentGroup,
  AssessmentStatus,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { TopicSummary as ITopicSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { StudentGroupType, YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { Button } from '@sparx/sparx-design/components';
import { Tabs } from '@sparx/sparx-design/components/tabs/Tabs';
import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { useEffect, useState } from 'react';
import Select from 'react-select';

import { useAssessmentContext } from '../../../context';
import { AssessmentAggregatedScores, AssessmentTopicsDetails } from '../../../types/assessments';
import { getAssessmentIDFromName } from '../../../utils/assessments';
import { isYearGroup } from '../../../utils/groups';
import { AssessmentComingSoon, AssessmentNotAvailable } from '../../Common/SectionNotAvailable';
import styles from './AssessmentTab.module.css';
import { Marksheet } from './Marksheet';
import { Reports } from './Reports';
import { clickedDownloadMarksButton } from './Reports/analytics';

/**
 * AssessmentTab handles showing the contents under the Assessment tab. It has two tabs,
 * "Marksheet" and "Report", and clicking either of these shows further contents related to each of
 * these topics for the selected group and assessment.
 */

interface IAssessmentTabProps {
  // Aggregated scores from the studentAssessments related to the assessment and studentGroup
  aggregatedScores: AssessmentAggregatedScores;
  // The assessment to show data for
  assessment: Assessment;
  // Record of studentGroupIDs to StudentGroups
  groupMap: Record<string, Group>;
  // List of studentAssessments related to the assessment and studentGroup
  studentAssessments: StudentAssessment[];
  // List of students in the current studentGroup
  studentsInGroup: Student[];
  // Display details for the topics featured in the assessment
  topicDisplayDetails: AssessmentTopicsDetails;
  // Map of studentIDs to their percentile rankings amongst their yearGroup for the assessment
  studentPercentileRanks: Map<string, string>;
  // Record of topicSummaryNames to TopicSummaries
  topicSummariesMap: Map<string, ITopicSummary>;
  // The currently selected group
  currentGroup: Group | YearGroup;
  // Optional assessment group to show details for
  assessmentGroup?: AssessmentGroup;
  // Optional list of assessments that belong to the assessment group. This should only be undefined
  // if assessmentGroup is also undefined
  assessmentGroupAssessments?: Assessment[];
  // Whether the user is viewing the "All" option for an assessment group. The parent should check
  // that an assessmentGroup exists before setting this to false, as this component assumes that
  // one does.
  viewingAll: boolean;
  // Student assessments that belong to students from the current group for any assessment in the
  // current assessment group. This can be undefined if the user isn't viewing an assessment group,
  // for example.
  assessmentGroupStudentAssessments?: Record<string, Record<string, StudentAssessment>>;
  fixedTab?: AssessmentTabs;
}

export enum AssessmentTabs {
  Marksheet = 'marksheet',
  Reports = 'reports',
}

export const AssessmentTab = ({
  aggregatedScores,
  assessment,
  groupMap,
  studentAssessments,
  studentsInGroup,
  topicDisplayDetails,
  studentPercentileRanks,
  topicSummariesMap,
  currentGroup,
  assessmentGroup,
  fixedTab,
}: IAssessmentTabProps) => {
  const { sendEvent: analytics } = useAssessmentContext();
  const [tab, setTab] = useState<AssessmentTabs>(fixedTab || AssessmentTabs.Marksheet);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>();
  const mediumScreen = useBreakpoint('md');
  const assessmentID = getAssessmentIDFromName(assessment.name);

  // Reset the selected student and search value when the studentGroupID changes
  useEffect(() => {
    setSelectedStudent(undefined);
    setSearchValue('');
  }, [currentGroup.name]);

  if (assessment.status === AssessmentStatus.COMING_SOON) {
    return <AssessmentComingSoon />;
  }
  if (assessment.status === AssessmentStatus.WILL_NOT_DO) {
    return <AssessmentNotAvailable />;
  }

  // Function to create a csv of student names, classes and scores for the currently selected group.
  // If a yeargroup is selected then the class shown in the csv is the student's class, rather than
  // the selected year group.
  const downloadCSV = () => {
    const csvDownloadData = studentAssessments.reduce((acc, sa) => {
      // Calculate the total score for the student assessment
      const score = sa.marks.reduce((totalScore, m) => totalScore + (m.score || 0), 0);
      let firstName = 'unknown';
      let lastName = 'student';
      let groupName = 'not currently in a class';
      let percentileRank = 'N/A';
      // Cycle through the students in the group and find the one that matches the current student
      // assessment
      for (const st of studentsInGroup) {
        if (st.studentId === sa.studentId) {
          // Set the student name
          firstName = st.givenName;
          lastName = st.familyName;
          // Cycle through the student's groups, find the first that is a class and set the class
          // name. Students should only be in one group of class type so we can stop the loop
          // once found.
          for (const groupID of st.studentGroupIds || []) {
            const group = groupMap[groupID];
            if (group.type === StudentGroupType.CLASS) {
              groupName = group.displayName;
              break;
            }
          }
          // Set the percentile rank of the student if they have one
          const studentPercentileRank = studentPercentileRanks.get(sa.studentId);
          if (studentPercentileRank !== undefined) {
            // Put just the number part of the percentile rank string in the csv
            const matches = studentPercentileRank.match(/\d+/gm);
            if (matches && matches.length === 1) {
              percentileRank = matches[0];
            }
          }
          // Stop cycling through students when we've found the one this assessment belongs to
          break;
        }
      }
      return (acc += `\n${firstName},${lastName},${groupName},${score},${percentileRank}`);
    }, 'first_name,last_name,group_name,score,percentile_rank');

    const fileBlob = new Blob([csvDownloadData], { type: 'text/csv' });
    const url = URL.createObjectURL(fileBlob);
    const anchor = (() => {
      const a = document.createElement('a');
      a.href = url;
      a.download = `${assessment.displayName} - ${currentGroup.name}.csv`;
      return a;
    })();
    anchor.click();
    anchor.remove();
    URL.revokeObjectURL(url);

    // Send analytics
    analytics(
      clickedDownloadMarksButton(assessmentID, currentGroup.name, selectedStudent?.studentId),
    );
  };

  const visibleTab = () => {
    switch (tab) {
      case AssessmentTabs.Marksheet:
        return (
          <Marksheet
            aggregatedScores={aggregatedScores}
            assessment={assessment}
            groupMap={groupMap}
            studentAssessments={studentAssessments}
            studentsInGroup={studentsInGroup}
            topicDisplayDetails={topicDisplayDetails}
            studentPercentileRanks={studentPercentileRanks}
          />
        );
      case AssessmentTabs.Reports:
        return (
          <Reports
            assessment={assessment}
            studentAssessments={studentAssessments}
            topicSummariesMap={topicSummariesMap}
            studentPercentileRanks={studentPercentileRanks}
            selectedStudent={selectedStudent}
          />
        );
      default:
        // If the tab isn't one of those listed above then something's gone wrong.
        return <div>Unknown tab</div>;
    }
  };

  // Show the controls on a new line if either the screen is medium width (or smaller), or we're on
  // the reports tab and the assessment group dropdown is present, in which case the controls should
  // be on a new line whatever screen size.
  const controlsOnNewLine = mediumScreen || (tab === AssessmentTabs.Reports && !!assessmentGroup);

  const controls = () => {
    switch (tab) {
      case AssessmentTabs.Reports:
        return (
          <div
            style={{
              width: controlsOnNewLine ? '100%' : '70%',
            }}
            className={styles.ReportsControls}
          >
            {!(mediumScreen && !!assessmentGroup) && <p>Select a Student:</p>}
            <Select
              inputValue={searchValue}
              onInputChange={v => setSearchValue(v)}
              onBlur={() => setSearchValue('')}
              value={selectedStudent}
              placeholder={`All students in ${isYearGroup(currentGroup) ? currentGroup.name : currentGroup.displayName}`}
              classNames={{
                control: () => styles.SearchBoxControl,
                container: () => styles.SearchBoxContainer,
                option: () => styles.SearchBoxOption,
                indicatorSeparator: () => styles.SearchBoxIndicatorSeparator,
              }}
              onChange={(s: Student | string | null) => {
                // Only allow known students to be set
                if (s && typeof s !== 'string') {
                  setSelectedStudent(s);
                  setSearchValue('');
                } else {
                  setSelectedStudent(undefined);
                }
              }}
              getOptionLabel={(o: Student) => `${o.givenName} ${o.familyName}`}
              isOptionSelected={(o: Student) => selectedStudent?.studentId === o.studentId}
              options={studentsInGroup}
              isClearable
            />
            <Button onClick={downloadCSV} variant="outlined">
              Download {isYearGroup(currentGroup) ? 'Year Group' : 'Class'} Marks
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const tabs = (
    <Tabs value={tab}>
      <Tabs.List className={styles.TabList}>
        <Tabs.Trigger
          value={AssessmentTabs.Marksheet}
          onClick={() => setTab(AssessmentTabs.Marksheet)}
        >
          Marksheet
        </Tabs.Trigger>
        <Tabs.Trigger value={AssessmentTabs.Reports} onClick={() => setTab(AssessmentTabs.Reports)}>
          Reports
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs>
  );

  return (
    <div className={styles.Container}>
      <div className={styles.TabsContainer}>
        {fixedTab === undefined ? tabs : <div style={{ flex: 1 }} />}
        {!controlsOnNewLine && controls()}
      </div>
      {controlsOnNewLine && <div className={styles.ControlsContainer}>{controls()}</div>}
      <div className={styles.VisibleTab}>{visibleTab()}</div>
    </div>
  );
};

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListSittingsResponse_SittingData } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { Tabs } from '@sparx/sparx-design/components/tabs/Tabs';
import { useEndedSittings, useSittings } from 'api/sittings';
import { Button } from 'components/button/Button';
import { LargeLoading } from 'components/largeloading/LargeLoading';
import { PageHeader } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { Suspense } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Filters } from 'views/teacher/sittingslistview/Filters';
import { SittingItem } from 'views/teacher/sittingslistview/SittingItem';

import styles from './SittingsListView.module.css';

export const SittingsListView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || 'active';

  return (
    <PageContainer>
      <PageHeader right={<CreateSittingButton />}>Online Sittings</PageHeader>
      <Tabs
        value={tab}
        onValueChange={e =>
          setSearchParams(v => {
            v.set('tab', e);
            return v;
          })
        }
      >
        <Tabs.List>
          <Tabs.Trigger value="active">Active</Tabs.Trigger>
          <Tabs.Trigger value="ended">Ended</Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="active">
          <Suspense fallback={<LargeLoading />}>
            <ActiveSittingList />
          </Suspense>
        </Tabs.Content>
        <Tabs.Content value="ended">
          <Suspense fallback={<LargeLoading />}>
            <EndedSittingList />
          </Suspense>
        </Tabs.Content>
      </Tabs>
    </PageContainer>
  );
};

const CreateSittingButton = () => (
  <Button
    as={Link}
    to="/teacher/sittings/create"
    rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
  >
    Create sitting
  </Button>
);

const ActiveSittingList = () => {
  const { data: sittings } = useSittings({ suspense: true });
  return <FilteredSittingsList sittings={sittings?.sittings || []} />;
};

const EndedSittingList = () => {
  const { data: sittings } = useEndedSittings({ suspense: true });
  return <FilteredSittingsList sittings={sittings?.sittings || []} />;
};

const FilteredSittingsList = ({ sittings }: { sittings: ListSittingsResponse_SittingData[] }) => (
  <Filters sittings={sittings}>
    {({ filters, sorts, filteredSittings }) => (
      <>
        <div className={styles.FiltersContainer}>
          {filters}
          {sorts}
        </div>
        <Stack spacing={2} direction="column" className={styles.SittingList}>
          {filteredSittings.map(sitting => (
            <SittingItem
              key={sitting.sitting?.sittingName}
              sitting={sitting.sitting!}
              participantCount={sitting.participantCount}
            />
          ))}
          {filteredSittings.length === 0 && (
            <div className={styles.NoSittingsFound}>No sittings found</div>
          )}
        </Stack>
      </>
    )}
  </Filters>
);

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faEllipsis,
  faGlobe,
  faGraduationCap,
  faHome,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSession } from 'api/auth';
import classNames from 'classnames';
import { DropdownMenuContent, DropdownMenuItem } from 'components/menu/Menu';
import { PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { sessionIsGuestTeacher } from 'utils/sessions';

import styles from './Sidebar.module.css';

export const Sidebar = ({ children }: PropsWithChildren) => {
  const { data: session } = useSession({ suspense: true });
  const isGuestTeacher = sessionIsGuestTeacher(session);

  return (
    <div className={styles.OuterContainer}>
      <div className={styles.InnerContainer}>
        <div className={styles.Sidebar}>
          <Button icon={faHome} href="/teacher" text="Home" />
          <Button icon={faGlobe} href="/teacher/sittings" text="Online Sittings" />
          {!isGuestTeacher && (
            <Button icon={faWarehouse} href="/teacher/assessments" text="Assessment" />
          )}
          {!isGuestTeacher && <MoreMenu />}
        </div>
        <div className={styles.Content}>{children}</div>
      </div>
    </div>
  );
};

interface ButtonProps {
  icon: IconDefinition;
  text: string;
  href: string;
}

const Button = ({ icon, href, text }: ButtonProps) => {
  const location = useLocation();
  const active = location.pathname === href;

  return (
    <Link to={href} className={classNames(styles.Button, { [styles.ButtonSelected]: active })}>
      <div className={styles.ButtonIcon}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={classNames(styles.ButtonText, { [styles.ButtonTextTight]: text.length > 9 })}>
        {text}
      </div>
    </Link>
  );
};

const MoreMenu = () => {
  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <div className={classNames(styles.Button, { [styles.ButtonSelected]: false })}>
          <div className={styles.ButtonIcon}>
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
          <div className={styles.ButtonText}>More</div>
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenuContent
        rounded="from-top-left"
        side="right"
        align="start"
        className={styles.MoreMenu}
      >
        <Link to="/teacher/staff" style={{ textDecoration: 'none' }}>
          <DropdownMenuItem icon={faGraduationCap}>Staff Manager</DropdownMenuItem>
        </Link>
      </DropdownMenuContent>
    </DropdownMenu.Root>
  );
};

/**
 * AccessTokenProvider is a utility class that can be used to fetch a GRPC Web
 * access token, and then refresh it every 10 minutes before it expires.
 */

const tokenDurationMs = 10 * 60 * 1000; // 10 minutes

const leewayMs = 30 * 1000; // 30 sec

const checkIntervalMs = 1 * 1000; // 1 sec

// The type of a function that can return an access token asynchronously, the
// access token should be the body of the response.
type AccessTokenFetcher = () => Promise<Response>;

// An AccessTokenProvider is initialised with an AccessTokenFetcher, and uses it
// in order to keep a valid access token available and refresh it before it has
// expired.
export class AccessTokenProvider {
  private fetchAccessToken: AccessTokenFetcher;
  private accessToken?: string;
  private refreshTime?: number;
  private interval?: ReturnType<typeof setInterval>;

  // Expected value for Date.now() - performance.now(). If that drifts too much
  // then we should get a new token.
  private clockDiff?: number;
  private pendingRequests: ((token: string) => void)[];
  private refreshInProgress: boolean;
  private cancelRefresh: boolean;
  // Whether we've given up trying to fetch access tokens
  private stopped: boolean;
  private started: boolean;

  constructor(fetchAccessToken: AccessTokenFetcher) {
    this.fetchAccessToken = fetchAccessToken;
    this.pendingRequests = [];
    this.refreshInProgress = false;
    this.cancelRefresh = false;
    this.stopped = false;
    this.started = false;
  }

  // This method should be called first.  A valid access token should be
  // available once the promise it returns is resolved.
  public async start(initialToken?: string) {
    this.started = true;
    if (initialToken) {
      this.accessToken = initialToken;
    } else {
      try {
        await this.refreshToken();
      } catch {
        // Exception can be raised by things like request blocking or network issues.
        return;
      }
    }
    this.keepTokenRefreshed();
  }

  public isStarted() {
    return this.started;
  }

  // Stop refreshing the access token. This can be called if we know the user is not authorised
  // to get a token, so we do not want to retry fetching it any more.
  public async stop() {
    this.stopped = true;
    this.cancelRefresh = true;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  // Returns (a promise for) an access token. Blocks until one is available.
  // Do not keep hold of these, but call this function each time an access token is needed
  // as access tokens are automatically refreshed.
  public async requestAccessToken(): Promise<string> {
    if (this.stopped) {
      // If stopped is true then we've permanently given up requesting access tokens. The caller of this class should
      // know this, but sometimes further requests are made anyway. In this case, we return a promise that will never
      // resolve as it prevents the client from making requests with a bad access token.
      return this.addPendingRequest();
    }

    // NOTE: We explicitly check to see if accessToken is undefined here to appease the type checker, but shouldRefreshToken will also check this.
    if (!this.accessToken || this.shouldRefreshToken()) {
      this.refreshToken();
      return this.addPendingRequest();
    }
    return this.accessToken;
  }

  private addPendingRequest(): Promise<string> {
    return new Promise<string>(resolve => {
      this.pendingRequests.push(resolve);
    });
  }

  private keepTokenRefreshed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      if (this.shouldRefreshToken()) {
        this.refreshToken();
      }
    }, checkIntervalMs);
  }

  private shouldRefreshToken(): boolean {
    // Keeps track of the time according to the device clock to guard against
    // the performance timer being stopped.
    const clockDiff = Date.now() - performance.now();
    const clockDiffDrift = this.clockDiff ? Math.abs(this.clockDiff - clockDiff) : 0;
    if (!this.accessToken) {
      // We don't have a token
      return true;
    }
    if (this.refreshTime === undefined) {
      // We don't know what the refresh time is
      return true;
    }
    if (performance.now() > this.refreshTime) {
      // We have exceeded the refresh time
      return true;
    }
    if (clockDiffDrift > 5 * checkIntervalMs) {
      // The clockDiff has exceeded 5s drift.  This could be due to a change to
      // the clock (we use the absolute value in case the difference is
      // negative) but could also be because the performance timer has stopped
      // incrementing for more than 5s, e.g. because the device went to sleep.
      return true;
    }
    // There is no reason why the token should be invalid.
    return false;
  }

  private async refreshToken() {
    // Prevent concurrently refreshing tokens
    if (this.refreshInProgress) {
      return;
    }
    this.refreshInProgress = true;
    try {
      const clockDiff = Date.now() - performance.now();
      const refreshTime = performance.now() + tokenDurationMs - leewayMs;
      const res = await this.fetchAccessToken();
      if (res.ok) {
        // Update the token to the new valid token
        const token = await res.text();

        // If the token was invalidated during the refresh, then this token is
        // not known to be good so we schedule getting another token ASAP.
        if (this.cancelRefresh) {
          setTimeout(this.refreshToken, 0);
          return;
        }
        this.accessToken = token;

        // Set next refresh to shortly before expiration
        this.refreshTime = refreshTime;
        this.clockDiff = clockDiff;
        // Resolve all pending requests
        const pendingRequests = this.pendingRequests;
        this.pendingRequests = [];
        for (const resolve of pendingRequests) {
          resolve(token);
        }
      } else if (res.status === 401) {
        this.stop();
      }
    } catch {
      // Exception can be raised by things like request blocking or network issues.
      return;
    } finally {
      this.refreshInProgress = false;
      this.cancelRefresh = false;
    }
  }
}

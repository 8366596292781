import classNames from 'classnames';
import { ReactElement } from 'react';

import { TriangleExclamationIcon } from '../../icons';
import styles from './ErrorMessage.module.css';

/**
 * Small component that displays an error message in red coloured box with a triangle icon
 * @constructor
 */
export const ErrorMessage = ({
  message,
  className,
}: {
  message: string | ReactElement;
  className?: string;
}) => (
  <div className={classNames(styles.Root, className)}>
    <TriangleExclamationIcon className={styles.Icon} />
    <span className={styles.Message}>{message}</span>
  </div>
);

import classNames from 'classnames';
import { ComponentProps, forwardRef, useContext } from 'react';

import { FormControlContext } from './FormControlContext';
import styles from './FormLabel.module.css';

interface FormLabelProps extends ComponentProps<'label'> {}

/**
 * FormLabel is a component that is used to label form elements. It should be used
 * within a `<FormControl/>` component to provide context to form elements.
 *
 * @example
 * <FormControl>
 *   <FormLabel>Email address:</FormLabel>
 *   <Input type="email" />
 * </FormControl>
 */
export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  function FormLabel(props, ref) {
    const { className, htmlFor, children, ...rest } = props;
    const formControlContext = useContext(FormControlContext);

    return (
      <label
        ref={ref}
        className={classNames(styles.Label, className)}
        htmlFor={htmlFor || formControlContext?.fieldId}
        {...rest}
      >
        {children}
      </label>
    );
  },
);

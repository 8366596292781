export type ISteps = IStep[];

export interface IStep {
  layout: IElement;
  input: IInput;
  marks?: number;
}

export type IElement =
  | IGroupElement
  | IPartGroupElement
  | ITextElement
  | INumberFieldElement
  | ITextFieldElement
  | IFigureElement
  | ICardElement
  | IImageElement
  | IExpressionFieldElement
  | ISlotElement
  | IChoiceElement
  | IObjectivesElement
  | IAssetUploadElement
  | IMediaInputElement
  | ITemplatedContentElement;

export type GroupElementType =
  | 'multi-part'
  | 'combined-question-answer'
  | 'interleaved-question-answer'
  | 'free-answer'
  | 'multiple-choice'
  | 'small-card'
  | 'small-cards'
  | 'calculator-allowed'
  | 'question'
  | 'question-text'
  | 'question-part'
  | 'left-image'
  | 'right-content'
  | 'answer'
  | 'answer-screen'
  | 'answer-part'
  | 'cards'
  | 'slots'
  | 'slidey'
  | 'horizontal-slots'
  | 'vertical-slots'
  | 'horizontal'
  | 'choices'
  | 'choices-vertical'
  | 'fraction'
  | 'requires-options'
  | 'link-boxes';

export type GroupElementStyle = 'fraction' | 'vector' | 'matrix-static' | undefined;

export interface IGroupElement {
  answerPartIndex?: number;
  element: 'group';
  type: GroupElementType[];
  content: IElement[];
  id?: string;

  label?: string;

  // Required for styled multigap - make new type?
  style?: GroupElementStyle;

  // Required for matrix - why are these here?
  rows?: number;
  columns?: number;
}

export interface IPartGroupElement {
  element: 'part-group';
  prefix: string;
  depth: number;
  content: IElement[];
}

export type TextElementType = 'markdown';

export interface ITextElement {
  element: 'text';
  text: string;
  type?: TextElementType[];
  // labelId is used to identify the text element as a label for an input. It will be undefined if
  // the text element is not a label.
  labelId?: string;
}

export interface INumberFieldElement {
  element: 'number-field';
  ref: string;
}

export interface ITextFieldElement {
  element: 'text-field';
  ref: string;
  text_area?: boolean;
  diff?: string;
}

export type IFigureElementType = 'question-image';

export interface IFigureElement {
  element: 'figure-ref';
  type: IFigureElementType[];
  ref: string;
  figure: {
    image: string;
    size: null;
    name: null;
    title: null;
    description?: null;
  };
}

export interface ICardElement {
  element: 'card';
  ref: string;
  content: IElement[];
}

export interface IChoiceElement {
  element: 'choice';
  variant?: 'checkbox';
  ref: string;
  content: IElement[];
}

export interface IImageElement {
  element: 'image';
  src: string;
}

export interface IExpressionFieldElement {
  element: 'expression-field';
  ref: string;
  features?: {
    variables: string[];
  };
}

export interface IObjectivesElement {
  element: 'objectives';
  title: string;
  objectives: string[];
}

export interface ISlotElement {
  element: 'slot';
  ref: string;
  accept?: string;
  content: IElement[];
}

export interface ITemplatedContentElement {
  element: 'templated-content';
  text: string;
  elements: Record<string, IElement>;
}

export interface ICardInput {
  content: IElement[];
  type?: GroupElementType[];
  slot_ref?: string;
}

export interface IChoiceInput {
  content: IElement[];
  selected?: boolean; // TODO: answer
}

export interface ISlotInput {
  type?: GroupElementType[];
  card_ref?: string;
  locked?: boolean;
}

export type NumberFieldSign = 'positive' | 'negative';

export interface INumberFieldInput {
  sign?: NumberFieldSign;
  value?: string; // TODO: answer
}

export interface ITextFieldInput {
  value?: string; // TODO: answer
}

export interface IExpressionFieldInput {
  value?: string; // TODO: answer
}

export interface ICardGroupInput {
  shuffle: boolean;
  card_refs: string[];
}

export interface ISlotGroupInput {
  slot_refs: string[];
  shuffle?: boolean;
}

export interface IChoiceGroupInput {
  min_choices: number | string; // TODO: can be string '1' etc
  max_choices: number | string; // TODO: can be string '1' etc
  shuffle: boolean;
  choice_refs: string[];
}

export interface IAssetUploadElement {
  element: 'asset-upload';
  ref: string;
  camera_upload?: boolean;
}

export interface IMediaInputElement {
  element: 'media-input';
  ref: string;
  asset_id: string;
  audio_only?: boolean;
}

export interface IUploadField {
  file?: File;
  uploadedAssetName?: string;
}

export interface IMediaField {
  value?: string; // TODO: answer
}

export interface IInput {
  cards?: Record<string, ICardInput>;
  choices?: Record<string, IChoiceInput>;
  slots?: Record<string, ISlotInput>;
  number_fields?: Record<string, INumberFieldInput>;
  text_fields?: Record<string, ITextFieldInput>;
  expression_fields?: Record<string, IExpressionFieldInput>;
  card_groups?: Record<string, ICardGroupInput>;
  slot_groups?: Record<string, ISlotGroupInput>;
  choice_groups?: Record<string, IChoiceGroupInput>;
  upload_fields?: Record<string, IUploadField>;
  media_fields?: Record<string, IMediaField>;
  styles?: object; // TODO
}

// ISSUES:
// - rows / columns are optional fields on group for some reason??
// - sparxweb wraps multi-gap elements with a new type of group for some reason??
// - style on group is only used for multigap

export const newInput = (): IInput => ({
  cards: {},
  card_groups: {},
  choice_groups: {},
  choices: {},
  slot_groups: {},
  expression_fields: {},
  number_fields: {},
  text_fields: {},
  slots: {},
});

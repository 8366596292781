// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/sparxweb/v1/videostatus.proto" (package "sparx.sparxweb.v1", syntax proto3),// tslint:disable
// @ts-nocheck
/**
 * @generated from protobuf enum sparx.sparxweb.v1.VideoStatus
 */
export enum VideoStatus {
  /**
   * @generated from protobuf enum value: VIDEO_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: VIDEO_STATUS_ENABLED = 1;
   */
  ENABLED = 1,
  /**
   * @generated from protobuf enum value: VIDEO_STATUS_DISABLED = 2;
   */
  DISABLED = 2,
}

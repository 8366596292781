import { Timestamp } from '@sparx/api/google/protobuf/timestamp';

let millisOffset: number | undefined = undefined;

export const updateOffset = (nowTimestamp: Timestamp) => {
  const serverNowMillis = nowTimestamp.seconds * 1000;
  const serverOffset = serverNowMillis - new Date().getTime();

  // Store the minimum server offset
  if (millisOffset == undefined || serverOffset < millisOffset) {
    millisOffset = Math.min(millisOffset || Infinity, serverNowMillis);
  }
};

export const serverTimeToLocal = (date: Date | Timestamp) => {
  const d = date instanceof Date ? date : new Date(date.seconds * 1000);
  return new Date(d.getTime() + (millisOffset || 0));
};

import { useBreakpoint } from '@sparx/sparx-design/hooks';
import { removeMathsFromText } from '@sparx/text-with-maths';
import { useMemo } from 'react';

import { CorrectIcon } from '../components/CorrectIcon';
import { Stack } from '../components/Stack';
import { findChoiceGroupForRef } from '../question/input';
import styles from '../question/SparxQuestion.module.css';
import {
  LayoutElementProps,
  useFixedChoiceElementsSort,
  usePredictableShuffleContent,
  useSparxQuestionContext,
} from '../question/SparxQuestionContext';
import { IChoiceElement, IElement, IGroupElement, IImageElement, IInput } from '../question/types';
import { isGapCorrect } from '../utils/isGapCorrect';
import { ChoiceGroupContext } from './ChoiceGroupContext';
import { LayoutElements } from './LayoutElement';

export const ChoiceGroupElement = ({ element }: LayoutElementProps<IGroupElement>) => {
  const context = useSparxQuestionContext();
  const choices = useMemo(() => getChoiceChildren(element), [element]);
  const verticalOverride = useMemo(() => element.type.includes('choices-vertical'), [element]);
  const isSmall = useBreakpoint('sm') && context.insightsMode !== 'print';
  const isMedium = useBreakpoint('md') && context.insightsMode !== 'print';
  const hasLongAnswers = useMemo(
    () => verticalOverride || choicesHasLongAnswers(choices, isSmall, isMedium),
    [verticalOverride, choices, isSmall, isMedium],
  );

  const { groupRef, group } = useMemo(
    () => findChoiceGroup(element, context.input),
    [element, context.input],
  );
  const hasMultipleAnswers =
    group &&
    (typeof group.max_choices === 'string' ? parseInt(group.max_choices) : group.max_choices) > 1;

  // Shuffle the array if this should be shuffled
  const shuffle = group && group.shuffle;
  const shuffledContent = usePredictableShuffleContent(element.content, shuffle);
  // Additional sorting to fix 'idontknow' element to the bottom of the list (if shuffled)
  const content = useFixedChoiceElementsSort(shuffledContent, shuffle);

  // Determine the evaluation on a group level instead of individual choices
  const groupEval = isGapCorrect(
    groupRef || '',
    context.gapEvaluations,
    context.questionMarkingMode,
  );
  const showResult = groupEval.show;
  groupEval.show &&= !hasMultipleAnswers;

  const choiceImages = content
    .map(choice => {
      if (!isChoiceElement(choice)) {
        return {
          ref: '',
          images: [] as IImageElement[],
        };
      }
      const images: IImageElement[] = [];
      choice.content.forEach(el => {
        findImages(el, images);
      });
      return { ref: choice.ref, images: images };
    })
    .filter(choice => choice && choice.images.length > 0);

  const ctxValue: ChoiceGroupContext = {
    ...groupEval,
    choiceImages,
  };

  return (
    <div className={styles.ChoicesGroup}>
      {hasMultipleAnswers && (
        <div className={styles.ChoicesGroupSelectAll}>
          <span>
            Select <strong>all</strong> correct answers
          </span>
          {showResult && (
            <CorrectIcon
              correct={groupEval.correct}
              inline={true}
              analyticsAnswerType="choice-group"
            />
          )}
        </div>
      )}
      <ChoiceGroupContext.Provider value={ctxValue}>
        <Stack wrapChildren size="small" dir={hasLongAnswers ? 'vertical' : 'horizontal'}>
          <LayoutElements element={{ ...element, content }} />
        </Stack>
      </ChoiceGroupContext.Provider>
    </div>
  );
};

const getChoiceChildren = (element: IElement): IChoiceElement[] => {
  switch (element.element) {
    case 'group':
      return element.content.flatMap(getChoiceChildren);
    case 'choice':
      return [element];
  }
  return [];
};

const choicesHasLongAnswers = (choices: IChoiceElement[], isSmall: boolean, isMedium: boolean) => {
  for (const element of choices) {
    for (const child of element.content) {
      if (child.element === 'text') {
        const { result, found } = removeMathsFromText(child.text);
        const threshold = isSmall ? 20 : isMedium ? 30 : found ? 30 : 40;
        if (result.length > threshold) {
          return true;
        }
      }
    }
  }
  return false;
};

const findChoiceGroup = (element: IGroupElement, input: IInput) => {
  // Select the ref for the first choice element in this group
  const firstChoiceRef = (
    element.content.find(el => el.element === 'choice') as IChoiceElement | undefined
  )?.ref;

  // Find the group for this ref
  const group = findChoiceGroupForRef(input, firstChoiceRef);
  return {
    groupRef: group ? group[0] : undefined,
    group: group ? group[1] : undefined,
  };
};

const findImages = (e: IElement, images: IImageElement[]) => {
  if (e.element === 'image') {
    images.push(e);
  } else if (hasContent(e)) {
    e.content.forEach(el => findImages(el, images));
  }
  return;
};

const hasContent = (element: IElement): element is IElement & { content: IElement[] } => {
  return (element as { content: IElement[] }).content !== undefined;
};

const isChoiceElement = (element: IElement): element is IChoiceElement => {
  return element.element === 'choice';
};

import {
  faChevronRight,
  faFileLines,
  faInfoCircle,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UploadedAssessmentResult } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import {
  AssessmentQuestion,
  Mark,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Package, TaskItem } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import {
  AssessmentDataEntry,
  StudentSettings,
} from '@sparx/assessments/src/components/AssessmentDataEntry/AssessmentDataEntry';
import { IDataEntryTableProps } from '@sparx/assessments/src/components/AssessmentDataEntry/DataEntryTable/DataEntryTable';
import { MarkEntry } from '@sparx/assessments/src/components/AssessmentDataEntry/DataEntryTable/QLARow';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { LargeLoading } from '@sparx/sparx-design/icons';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { selectedGroupValue } from 'components/header/BackLink';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { ReactNode, Suspense, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDataEntryData } from 'views/teacher/assessmentsview/dataentryview/hooks';
import { PackageAnswerHistory } from 'views/teacher/sittingview/answerhistory/AnswerHistoryView';

import styles from './OnlineDataEntryView.module.css';

export const OnlineDataEntryView = () => {
  const {
    students,
    groups,
    uploadedResults,
    selectedGroup,
    assessment,
    selectedStudentId,
    setSelectedStudentId,
  } = useDataEntryData();

  const studentSettings = useMemo(
    () =>
      students.reduce((acc, r) => {
        const uploadedResult = uploadedResults?.get(r.studentId);
        acc.set(r.studentId, {
          mode: 'paper',
          hasOnline: Boolean(uploadedResult),
          disabled: true,
          showResult: true,
        });
        return acc;
      }, new Map<string, StudentSettings>()),
    [students, uploadedResults],
  );

  const group = selectedGroupValue(selectedGroup);
  if (!group) {
    return (
      <PageContainer filled={true}>
        <PageHeader
          back={`/teacher/${assessment.name}`}
          title={`Student submissions - ${assessment.displayName}`}
        >
          {assessment.displayName} - Student submissions
        </PageHeader>
        Select a group in header
      </PageContainer>
    );
  }

  return (
    <AssessmentDataEntry
      assessment={assessment}
      students={students}
      currentGroup={group}
      groups={groups}
      selectedStudentId={selectedStudentId}
      setSelectedStudentId={setSelectedStudentId}
      studentSettings={studentSettings}
      customComponent={props => {
        let content = <>Select a student...</>;
        if (props?.currentStudent) {
          const uploadedResult = uploadedResults?.get(props?.currentStudent?.studentId);
          content = <DigitalSittingMarkView {...props} uploadedResult={uploadedResult} />;
        }
        return <div className={styles.EntryContainer}>{content}</div>;
      }}
    >
      {({ progress, content }) => (
        <PageContainer filled={true} fullWidth={true}>
          <PageHeader
            right={progress}
            back={`/teacher/${assessment.name}`}
            title={`Student submissions - ${assessment.displayName}`}
          >
            {assessment.displayName}
            <PageHeaderSubpage>Student submissions</PageHeaderSubpage>
          </PageHeader>
          {content}
        </PageContainer>
      )}
    </AssessmentDataEntry>
  );
};

const DigitalSittingMarkView = ({
  uploadedResult,
  questionIDToQuestion,
  currentAssessment,
  currentStudent,
  onUpdateMark,
  saveMessage,
}: IDataEntryTableProps & { uploadedResult: UploadedAssessmentResult | undefined }) => {
  const location = useLocation();

  // const reviewsOutstanding = currentAssessment?.marks.filter(m => m.requiresReview).length || 0;
  if (!uploadedResult) {
    const hasPaperResult =
      (currentAssessment?.marks?.filter((m: Mark) => m.score !== undefined).length || 0) > 0;

    const dataEntryLink = location.pathname.replace('/submissions', '/dataentry') + location.search;
    const matchStudentsLink = location.pathname.replace('/submissions', '/match') + location.search;

    let alert;
    if (hasPaperResult) {
      alert = (
        <div className={styles.Alert}>
          <FontAwesomeIcon icon={faFileLines} />
          <p>
            This student has marks entered for a paper assessment.
            <br />
            You can view the results on the data entry page.
          </p>
          <Stack spacing={2} className={styles.AlertButtons}>
            <Button
              as={Link}
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              to={dataEntryLink}
            >
              View marks
            </Button>
          </Stack>
        </div>
      );
    } else {
      alert = (
        <div className={styles.Alert}>
          <FontAwesomeIcon icon={faInfoCircle} />
          <p>
            This student has no results for this online assessment. You can either enter data
            manually or match a online sitting to this student.
          </p>
          <Stack spacing={2} className={styles.AlertButtons}>
            <Button
              as={Link}
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              to={dataEntryLink}
            >
              Enter marks
            </Button>
            <Button
              as={Link}
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              to={matchStudentsLink}
            >
              Match online sitting
            </Button>
          </Stack>
        </div>
      );
    }

    return (
      <>
        <div className={styles.Header}>
          <h3>
            {currentStudent.givenName} {currentStudent.familyName}
          </h3>
        </div>
        {alert}
      </>
    );
  }

  return (
    <Suspense fallback={<LargeLoading />}>
      <PackageAnswerHistory
        packageName={uploadedResult.packageName}
        before={pkg => (
          <ResultHeader pkg={pkg} student={currentStudent} saveMessage={saveMessage} />
        )}
        control={(_, taskItem: TaskItem) => (
          <ResultEntryControl
            taskItem={taskItem}
            questionIDToQuestion={questionIDToQuestion}
            assessment={currentAssessment}
            student={currentStudent}
            onUpdateMark={onUpdateMark}
          />
        )}
      />
    </Suspense>
  );
};

const ResultHeader = ({
  pkg,
  student,
  saveMessage,
}: {
  pkg: Package;
  student: Student;
  saveMessage: ReactNode;
}) => {
  const flatItems = pkg?.contents?.tasks?.flatMap(task => task.contents?.taskItems) || [];
  const aiAnswers = flatItems.filter(i => i?.annotations['ai'] === 'has').length;

  return (
    <div className={styles.Header}>
      <h3>
        {student.givenName} {student.familyName}
      </h3>
      <span className={styles.SaveMessage}>{saveMessage}</span>
      {Boolean(aiAnswers) && (
        <Alert status={'warning'} className={styles.TitleAlert}>
          <Alert.Icon customIcon={faWandMagicSparkles} />
          <Alert.Description>
            {aiAnswers} AI marked question{aiAnswers === 1 ? '' : 's'}
          </Alert.Description>
        </Alert>
      )}
    </div>
  );
};

const ResultEntryControl = ({
  taskItem,
  questionIDToQuestion,
  assessment,
  student,
  onUpdateMark,
}: {
  taskItem: TaskItem;
  questionIDToQuestion: Map<string, AssessmentQuestion>;
  assessment: StudentAssessment | undefined;
  student: Student;
  onUpdateMark: (questionName: string, mark: number | undefined, attempted: boolean) => void;
}) => {
  const questionName = taskItem.annotations['assessment/question'];
  const question = questionIDToQuestion.get(questionName || '');
  const mark = assessment?.marks.find(m => m.assessmentQuestionName === questionName);

  if (!question || !mark) {
    return <div>Question not found</div>;
  }

  const requiresReview = taskItem.annotations['ai'] === 'has';
  // const approvalOutstanding = mark.requiresReview;

  return (
    <div
      className={classNames(styles.MarkReview, {
        [styles.MarkReviewRequiresReview]: requiresReview,
      })}
    >
      <div className={styles.MarkInputContainer}>
        {requiresReview && (
          <Alert status="warning">
            <Alert.Icon customIcon={faWandMagicSparkles} />
            <Alert.Description>
              Does this look right? You can override the marks awarded for this question.
            </Alert.Description>
          </Alert>
        )}
        <MarkEntry
          studentID={student?.studentId}
          studentAbsent={!!assessment?.absent}
          row={{
            questionNumber: question.displayName,
            mark: {
              givenMark: mark.score,
              totalMarks: question.availableMarks,
              attempted: mark.attempted,
            },
          }}
          isCurrentRow={true}
          onUpdate={(mark: number | undefined, attempted: boolean) => {
            onUpdateMark(questionName, mark, attempted);
          }}
          isDisabled={!requiresReview}
        />
      </div>
    </div>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/v1/state.proto" (package "sparx.games.state.v1", syntax proto3),// tslint:disable
// @ts-nocheck
//
// GENERATED CODE - DO NOT EDIT
// (generated by sparxweb/games/gen_games.gsl)
//
import { MessageType } from '@protobuf-ts/runtime';
import { Update as Update$15 } from '../rhythmgame/v1/rhythmgame';
import { Update as Update$14 } from '../gridpathgame/v1/gridpathgame';
import { Update as Update$13 } from '../marblesmasher/v1/marblesmasher';
import { Update as Update$12 } from '../gardengame/v1/gardengame';
import { Update as Update$11 } from '../leaguetablegame/v1/leaguetablegame';
import { Update as Update$10 } from '../findittables/v1/findittables';
import { Update as Update$9 } from '../stickercollector/v1/stickercollector';
import { Update as Update$8 } from '../pictureshuffle/v1/pictureshuffle';
import { Update as Update$7 } from '../hundredclub/v1/hundredclub';
import { Update as Update$6 } from '../gemsmash/v1/gemsmash';
import { Update as Update$5 } from '../blocksgame/v1/blocksgame';
import { Update as Update$4 } from '../dicegame/v1/dicegame';
import { Update as Update$3 } from '../autohilo/v1/autohilo';
import { Update as Update$2 } from '../hilo/v1/hilo';
import { Update as Update$ } from '../findit/v1/findit';
import { Init as Init$15 } from '../rhythmgame/v1/rhythmgame';
import { Init as Init$14 } from '../gridpathgame/v1/gridpathgame';
import { Init as Init$13 } from '../marblesmasher/v1/marblesmasher';
import { Init as Init$12 } from '../gardengame/v1/gardengame';
import { Init as Init$11 } from '../leaguetablegame/v1/leaguetablegame';
import { Init as Init$10 } from '../findittables/v1/findittables';
import { Init as Init$9 } from '../stickercollector/v1/stickercollector';
import { Init as Init$8 } from '../pictureshuffle/v1/pictureshuffle';
import { Init as Init$7 } from '../hundredclub/v1/hundredclub';
import { Init as Init$6 } from '../gemsmash/v1/gemsmash';
import { Init as Init$5 } from '../blocksgame/v1/blocksgame';
import { Init as Init$4 } from '../dicegame/v1/dicegame';
import { Init as Init$3 } from '../autohilo/v1/autohilo';
import { Init as Init$2 } from '../hilo/v1/hilo';
import { Init as Init$ } from '../findit/v1/findit';
import { NumberBondsAnswerAction } from '../../progress/numberbonds/v1/numberbonds';
import { PhonicsAnswerAction } from '../../progress/phonics/v1/phonics';
import { TablesAnswerAction } from '../../progress/tablesanswer/v1/tablesanswer';
import { Action as Action$15 } from '../rhythmgame/v1/rhythmgame';
import { Action as Action$14 } from '../gridpathgame/v1/gridpathgame';
import { Action as Action$13 } from '../marblesmasher/v1/marblesmasher';
import { Action as Action$12 } from '../gardengame/v1/gardengame';
import { Action as Action$11 } from '../leaguetablegame/v1/leaguetablegame';
import { Action as Action$10 } from '../findittables/v1/findittables';
import { Action as Action$9 } from '../stickercollector/v1/stickercollector';
import { Action as Action$8 } from '../pictureshuffle/v1/pictureshuffle';
import { Action as Action$7 } from '../hundredclub/v1/hundredclub';
import { Action as Action$6 } from '../gemsmash/v1/gemsmash';
import { Action as Action$5 } from '../blocksgame/v1/blocksgame';
import { Action as Action$4 } from '../dicegame/v1/dicegame';
import { Action as Action$3 } from '../autohilo/v1/autohilo';
import { Action as Action$2 } from '../hilo/v1/hilo';
import { Action as Action$ } from '../findit/v1/findit';
import { Params as Params$15 } from '../rhythmgame/v1/rhythmgame';
import { Params as Params$14 } from '../gridpathgame/v1/gridpathgame';
import { Params as Params$13 } from '../marblesmasher/v1/marblesmasher';
import { Params as Params$12 } from '../gardengame/v1/gardengame';
import { Params as Params$11 } from '../leaguetablegame/v1/leaguetablegame';
import { Params as Params$10 } from '../findittables/v1/findittables';
import { Params as Params$9 } from '../stickercollector/v1/stickercollector';
import { Params as Params$8 } from '../pictureshuffle/v1/pictureshuffle';
import { Params as Params$7 } from '../hundredclub/v1/hundredclub';
import { Params as Params$6 } from '../gemsmash/v1/gemsmash';
import { Params as Params$5 } from '../blocksgame/v1/blocksgame';
import { Params as Params$4 } from '../dicegame/v1/dicegame';
import { Params as Params$3 } from '../autohilo/v1/autohilo';
import { Params as Params$2 } from '../hilo/v1/hilo';
import { Params as Params$ } from '../findit/v1/findit';
/**
 * @generated from protobuf message sparx.games.state.v1.Params
 */
export interface Params {
  /**
   * @generated from protobuf oneof: params
   */
  params:
    | {
        oneofKind: 'findIt';
        /**
         * @generated from protobuf field: sparx.games.state.findit.v1.Params FindIt = 1 [json_name = "FindIt"];
         */
        findIt: Params$;
      }
    | {
        oneofKind: 'hiLo';
        /**
         * @generated from protobuf field: sparx.games.state.hilo.v1.Params HiLo = 2 [json_name = "HiLo"];
         */
        hiLo: Params$2;
      }
    | {
        oneofKind: 'autoHiLo';
        /**
         * @generated from protobuf field: sparx.games.state.autohilo.v1.Params AutoHiLo = 3 [json_name = "AutoHiLo"];
         */
        autoHiLo: Params$3;
      }
    | {
        oneofKind: 'diceGame';
        /**
         * @generated from protobuf field: sparx.games.state.dicegame.v1.Params DiceGame = 4 [json_name = "DiceGame"];
         */
        diceGame: Params$4;
      }
    | {
        oneofKind: 'blocksGame';
        /**
         * @generated from protobuf field: sparx.games.state.blocksgame.v1.Params BlocksGame = 5 [json_name = "BlocksGame"];
         */
        blocksGame: Params$5;
      }
    | {
        oneofKind: 'gemSmash';
        /**
         * @generated from protobuf field: sparx.games.state.gemsmash.v1.Params GemSmash = 6 [json_name = "GemSmash"];
         */
        gemSmash: Params$6;
      }
    | {
        oneofKind: 'hundredClub';
        /**
         * @generated from protobuf field: sparx.games.state.hundredclub.v1.Params HundredClub = 7 [json_name = "HundredClub"];
         */
        hundredClub: Params$7;
      }
    | {
        oneofKind: 'pictureShuffle';
        /**
         * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.Params PictureShuffle = 8 [json_name = "PictureShuffle"];
         */
        pictureShuffle: Params$8;
      }
    | {
        oneofKind: 'stickerCollector';
        /**
         * @generated from protobuf field: sparx.games.state.stickercollector.v1.Params StickerCollector = 9 [json_name = "StickerCollector"];
         */
        stickerCollector: Params$9;
      }
    | {
        oneofKind: 'findItTables';
        /**
         * @generated from protobuf field: sparx.games.state.findittables.v1.Params FindItTables = 10 [json_name = "FindItTables"];
         */
        findItTables: Params$10;
      }
    | {
        oneofKind: 'leagueTableGame';
        /**
         * @generated from protobuf field: sparx.games.state.leaguetablegame.v1.Params LeagueTableGame = 11 [json_name = "LeagueTableGame"];
         */
        leagueTableGame: Params$11;
      }
    | {
        oneofKind: 'gardenGame';
        /**
         * @generated from protobuf field: sparx.games.state.gardengame.v1.Params GardenGame = 12 [json_name = "GardenGame"];
         */
        gardenGame: Params$12;
      }
    | {
        oneofKind: 'marbleSmasher';
        /**
         * @generated from protobuf field: sparx.games.state.marblesmasher.v1.Params MarbleSmasher = 13 [json_name = "MarbleSmasher"];
         */
        marbleSmasher: Params$13;
      }
    | {
        oneofKind: 'gridPathGame';
        /**
         * @generated from protobuf field: sparx.games.state.gridpathgame.v1.Params GridPathGame = 14 [json_name = "GridPathGame"];
         */
        gridPathGame: Params$14;
      }
    | {
        oneofKind: 'rhythmGame';
        /**
         * @generated from protobuf field: sparx.games.state.rhythmgame.v1.Params RhythmGame = 15 [json_name = "RhythmGame"];
         */
        rhythmGame: Params$15;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.games.state.v1.Action
 */
export interface Action {
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'findIt';
        /**
         * @generated from protobuf field: sparx.games.state.findit.v1.Action FindIt = 1 [json_name = "FindIt"];
         */
        findIt: Action$;
      }
    | {
        oneofKind: 'hiLo';
        /**
         * @generated from protobuf field: sparx.games.state.hilo.v1.Action HiLo = 2 [json_name = "HiLo"];
         */
        hiLo: Action$2;
      }
    | {
        oneofKind: 'autoHiLo';
        /**
         * @generated from protobuf field: sparx.games.state.autohilo.v1.Action AutoHiLo = 3 [json_name = "AutoHiLo"];
         */
        autoHiLo: Action$3;
      }
    | {
        oneofKind: 'diceGame';
        /**
         * @generated from protobuf field: sparx.games.state.dicegame.v1.Action DiceGame = 4 [json_name = "DiceGame"];
         */
        diceGame: Action$4;
      }
    | {
        oneofKind: 'blocksGame';
        /**
         * @generated from protobuf field: sparx.games.state.blocksgame.v1.Action BlocksGame = 5 [json_name = "BlocksGame"];
         */
        blocksGame: Action$5;
      }
    | {
        oneofKind: 'gemSmash';
        /**
         * @generated from protobuf field: sparx.games.state.gemsmash.v1.Action GemSmash = 6 [json_name = "GemSmash"];
         */
        gemSmash: Action$6;
      }
    | {
        oneofKind: 'hundredClub';
        /**
         * @generated from protobuf field: sparx.games.state.hundredclub.v1.Action HundredClub = 7 [json_name = "HundredClub"];
         */
        hundredClub: Action$7;
      }
    | {
        oneofKind: 'pictureShuffle';
        /**
         * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.Action PictureShuffle = 8 [json_name = "PictureShuffle"];
         */
        pictureShuffle: Action$8;
      }
    | {
        oneofKind: 'stickerCollector';
        /**
         * @generated from protobuf field: sparx.games.state.stickercollector.v1.Action StickerCollector = 9 [json_name = "StickerCollector"];
         */
        stickerCollector: Action$9;
      }
    | {
        oneofKind: 'findItTables';
        /**
         * @generated from protobuf field: sparx.games.state.findittables.v1.Action FindItTables = 10 [json_name = "FindItTables"];
         */
        findItTables: Action$10;
      }
    | {
        oneofKind: 'leagueTableGame';
        /**
         * @generated from protobuf field: sparx.games.state.leaguetablegame.v1.Action LeagueTableGame = 11 [json_name = "LeagueTableGame"];
         */
        leagueTableGame: Action$11;
      }
    | {
        oneofKind: 'gardenGame';
        /**
         * @generated from protobuf field: sparx.games.state.gardengame.v1.Action GardenGame = 12 [json_name = "GardenGame"];
         */
        gardenGame: Action$12;
      }
    | {
        oneofKind: 'marbleSmasher';
        /**
         * @generated from protobuf field: sparx.games.state.marblesmasher.v1.Action MarbleSmasher = 13 [json_name = "MarbleSmasher"];
         */
        marbleSmasher: Action$13;
      }
    | {
        oneofKind: 'gridPathGame';
        /**
         * @generated from protobuf field: sparx.games.state.gridpathgame.v1.Action GridPathGame = 14 [json_name = "GridPathGame"];
         */
        gridPathGame: Action$14;
      }
    | {
        oneofKind: 'rhythmGame';
        /**
         * @generated from protobuf field: sparx.games.state.rhythmgame.v1.Action RhythmGame = 15 [json_name = "RhythmGame"];
         */
        rhythmGame: Action$15;
      }
    | {
        oneofKind: 'tablesAnswer';
        /**
         * Special action for dispatching times tables answers
         *
         * @generated from protobuf field: sparx.games.progress.tablesanswer.v1.TablesAnswerAction tablesAnswer = 500;
         */
        tablesAnswer: TablesAnswerAction;
      }
    | {
        oneofKind: 'phonicsAnswer';
        /**
         * Special action for dispatching phonics answers
         *
         * @generated from protobuf field: sparx.games.progress.phonics.v1.PhonicsAnswerAction phonicsAnswer = 501;
         */
        phonicsAnswer: PhonicsAnswerAction;
      }
    | {
        oneofKind: 'numberBondsAnswer';
        /**
         * Special action for dispatching number bonds answers
         *
         * @generated from protobuf field: sparx.games.progress.numberbonds.v1.NumberBondsAnswerAction numberBondsAnswer = 502;
         */
        numberBondsAnswer: NumberBondsAnswerAction;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.games.state.v1.Init
 */
export interface Init {
  /**
   * @generated from protobuf oneof: init
   */
  init:
    | {
        oneofKind: 'findIt';
        /**
         * @generated from protobuf field: sparx.games.state.findit.v1.Init FindIt = 1 [json_name = "FindIt"];
         */
        findIt: Init$;
      }
    | {
        oneofKind: 'hiLo';
        /**
         * @generated from protobuf field: sparx.games.state.hilo.v1.Init HiLo = 2 [json_name = "HiLo"];
         */
        hiLo: Init$2;
      }
    | {
        oneofKind: 'autoHiLo';
        /**
         * @generated from protobuf field: sparx.games.state.autohilo.v1.Init AutoHiLo = 3 [json_name = "AutoHiLo"];
         */
        autoHiLo: Init$3;
      }
    | {
        oneofKind: 'diceGame';
        /**
         * @generated from protobuf field: sparx.games.state.dicegame.v1.Init DiceGame = 4 [json_name = "DiceGame"];
         */
        diceGame: Init$4;
      }
    | {
        oneofKind: 'blocksGame';
        /**
         * @generated from protobuf field: sparx.games.state.blocksgame.v1.Init BlocksGame = 5 [json_name = "BlocksGame"];
         */
        blocksGame: Init$5;
      }
    | {
        oneofKind: 'gemSmash';
        /**
         * @generated from protobuf field: sparx.games.state.gemsmash.v1.Init GemSmash = 6 [json_name = "GemSmash"];
         */
        gemSmash: Init$6;
      }
    | {
        oneofKind: 'hundredClub';
        /**
         * @generated from protobuf field: sparx.games.state.hundredclub.v1.Init HundredClub = 7 [json_name = "HundredClub"];
         */
        hundredClub: Init$7;
      }
    | {
        oneofKind: 'pictureShuffle';
        /**
         * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.Init PictureShuffle = 8 [json_name = "PictureShuffle"];
         */
        pictureShuffle: Init$8;
      }
    | {
        oneofKind: 'stickerCollector';
        /**
         * @generated from protobuf field: sparx.games.state.stickercollector.v1.Init StickerCollector = 9 [json_name = "StickerCollector"];
         */
        stickerCollector: Init$9;
      }
    | {
        oneofKind: 'findItTables';
        /**
         * @generated from protobuf field: sparx.games.state.findittables.v1.Init FindItTables = 10 [json_name = "FindItTables"];
         */
        findItTables: Init$10;
      }
    | {
        oneofKind: 'leagueTableGame';
        /**
         * @generated from protobuf field: sparx.games.state.leaguetablegame.v1.Init LeagueTableGame = 11 [json_name = "LeagueTableGame"];
         */
        leagueTableGame: Init$11;
      }
    | {
        oneofKind: 'gardenGame';
        /**
         * @generated from protobuf field: sparx.games.state.gardengame.v1.Init GardenGame = 12 [json_name = "GardenGame"];
         */
        gardenGame: Init$12;
      }
    | {
        oneofKind: 'marbleSmasher';
        /**
         * @generated from protobuf field: sparx.games.state.marblesmasher.v1.Init MarbleSmasher = 13 [json_name = "MarbleSmasher"];
         */
        marbleSmasher: Init$13;
      }
    | {
        oneofKind: 'gridPathGame';
        /**
         * @generated from protobuf field: sparx.games.state.gridpathgame.v1.Init GridPathGame = 14 [json_name = "GridPathGame"];
         */
        gridPathGame: Init$14;
      }
    | {
        oneofKind: 'rhythmGame';
        /**
         * @generated from protobuf field: sparx.games.state.rhythmgame.v1.Init RhythmGame = 15 [json_name = "RhythmGame"];
         */
        rhythmGame: Init$15;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.games.state.v1.Update
 */
export interface Update {
  /**
   * @generated from protobuf oneof: update
   */
  update:
    | {
        oneofKind: 'findIt';
        /**
         * @generated from protobuf field: sparx.games.state.findit.v1.Update FindIt = 1 [json_name = "FindIt"];
         */
        findIt: Update$;
      }
    | {
        oneofKind: 'hiLo';
        /**
         * @generated from protobuf field: sparx.games.state.hilo.v1.Update HiLo = 2 [json_name = "HiLo"];
         */
        hiLo: Update$2;
      }
    | {
        oneofKind: 'autoHiLo';
        /**
         * @generated from protobuf field: sparx.games.state.autohilo.v1.Update AutoHiLo = 3 [json_name = "AutoHiLo"];
         */
        autoHiLo: Update$3;
      }
    | {
        oneofKind: 'diceGame';
        /**
         * @generated from protobuf field: sparx.games.state.dicegame.v1.Update DiceGame = 4 [json_name = "DiceGame"];
         */
        diceGame: Update$4;
      }
    | {
        oneofKind: 'blocksGame';
        /**
         * @generated from protobuf field: sparx.games.state.blocksgame.v1.Update BlocksGame = 5 [json_name = "BlocksGame"];
         */
        blocksGame: Update$5;
      }
    | {
        oneofKind: 'gemSmash';
        /**
         * @generated from protobuf field: sparx.games.state.gemsmash.v1.Update GemSmash = 6 [json_name = "GemSmash"];
         */
        gemSmash: Update$6;
      }
    | {
        oneofKind: 'hundredClub';
        /**
         * @generated from protobuf field: sparx.games.state.hundredclub.v1.Update HundredClub = 7 [json_name = "HundredClub"];
         */
        hundredClub: Update$7;
      }
    | {
        oneofKind: 'pictureShuffle';
        /**
         * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.Update PictureShuffle = 8 [json_name = "PictureShuffle"];
         */
        pictureShuffle: Update$8;
      }
    | {
        oneofKind: 'stickerCollector';
        /**
         * @generated from protobuf field: sparx.games.state.stickercollector.v1.Update StickerCollector = 9 [json_name = "StickerCollector"];
         */
        stickerCollector: Update$9;
      }
    | {
        oneofKind: 'findItTables';
        /**
         * @generated from protobuf field: sparx.games.state.findittables.v1.Update FindItTables = 10 [json_name = "FindItTables"];
         */
        findItTables: Update$10;
      }
    | {
        oneofKind: 'leagueTableGame';
        /**
         * @generated from protobuf field: sparx.games.state.leaguetablegame.v1.Update LeagueTableGame = 11 [json_name = "LeagueTableGame"];
         */
        leagueTableGame: Update$11;
      }
    | {
        oneofKind: 'gardenGame';
        /**
         * @generated from protobuf field: sparx.games.state.gardengame.v1.Update GardenGame = 12 [json_name = "GardenGame"];
         */
        gardenGame: Update$12;
      }
    | {
        oneofKind: 'marbleSmasher';
        /**
         * @generated from protobuf field: sparx.games.state.marblesmasher.v1.Update MarbleSmasher = 13 [json_name = "MarbleSmasher"];
         */
        marbleSmasher: Update$13;
      }
    | {
        oneofKind: 'gridPathGame';
        /**
         * @generated from protobuf field: sparx.games.state.gridpathgame.v1.Update GridPathGame = 14 [json_name = "GridPathGame"];
         */
        gridPathGame: Update$14;
      }
    | {
        oneofKind: 'rhythmGame';
        /**
         * @generated from protobuf field: sparx.games.state.rhythmgame.v1.Update RhythmGame = 15 [json_name = "RhythmGame"];
         */
        rhythmGame: Update$15;
      }
    | {
        oneofKind: undefined;
      };
}
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.v1.Params', [
      {
        no: 1,
        name: 'FindIt',
        kind: 'message',
        jsonName: 'FindIt',
        oneof: 'params',
        T: () => Params$,
      },
      {
        no: 2,
        name: 'HiLo',
        kind: 'message',
        jsonName: 'HiLo',
        oneof: 'params',
        T: () => Params$2,
      },
      {
        no: 3,
        name: 'AutoHiLo',
        kind: 'message',
        jsonName: 'AutoHiLo',
        oneof: 'params',
        T: () => Params$3,
      },
      {
        no: 4,
        name: 'DiceGame',
        kind: 'message',
        jsonName: 'DiceGame',
        oneof: 'params',
        T: () => Params$4,
      },
      {
        no: 5,
        name: 'BlocksGame',
        kind: 'message',
        jsonName: 'BlocksGame',
        oneof: 'params',
        T: () => Params$5,
      },
      {
        no: 6,
        name: 'GemSmash',
        kind: 'message',
        jsonName: 'GemSmash',
        oneof: 'params',
        T: () => Params$6,
      },
      {
        no: 7,
        name: 'HundredClub',
        kind: 'message',
        jsonName: 'HundredClub',
        oneof: 'params',
        T: () => Params$7,
      },
      {
        no: 8,
        name: 'PictureShuffle',
        kind: 'message',
        jsonName: 'PictureShuffle',
        oneof: 'params',
        T: () => Params$8,
      },
      {
        no: 9,
        name: 'StickerCollector',
        kind: 'message',
        jsonName: 'StickerCollector',
        oneof: 'params',
        T: () => Params$9,
      },
      {
        no: 10,
        name: 'FindItTables',
        kind: 'message',
        jsonName: 'FindItTables',
        oneof: 'params',
        T: () => Params$10,
      },
      {
        no: 11,
        name: 'LeagueTableGame',
        kind: 'message',
        jsonName: 'LeagueTableGame',
        oneof: 'params',
        T: () => Params$11,
      },
      {
        no: 12,
        name: 'GardenGame',
        kind: 'message',
        jsonName: 'GardenGame',
        oneof: 'params',
        T: () => Params$12,
      },
      {
        no: 13,
        name: 'MarbleSmasher',
        kind: 'message',
        jsonName: 'MarbleSmasher',
        oneof: 'params',
        T: () => Params$13,
      },
      {
        no: 14,
        name: 'GridPathGame',
        kind: 'message',
        jsonName: 'GridPathGame',
        oneof: 'params',
        T: () => Params$14,
      },
      {
        no: 15,
        name: 'RhythmGame',
        kind: 'message',
        jsonName: 'RhythmGame',
        oneof: 'params',
        T: () => Params$15,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.v1.Params
 */
export const Params = new Params$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.v1.Action', [
      {
        no: 1,
        name: 'FindIt',
        kind: 'message',
        jsonName: 'FindIt',
        oneof: 'action',
        T: () => Action$,
      },
      {
        no: 2,
        name: 'HiLo',
        kind: 'message',
        jsonName: 'HiLo',
        oneof: 'action',
        T: () => Action$2,
      },
      {
        no: 3,
        name: 'AutoHiLo',
        kind: 'message',
        jsonName: 'AutoHiLo',
        oneof: 'action',
        T: () => Action$3,
      },
      {
        no: 4,
        name: 'DiceGame',
        kind: 'message',
        jsonName: 'DiceGame',
        oneof: 'action',
        T: () => Action$4,
      },
      {
        no: 5,
        name: 'BlocksGame',
        kind: 'message',
        jsonName: 'BlocksGame',
        oneof: 'action',
        T: () => Action$5,
      },
      {
        no: 6,
        name: 'GemSmash',
        kind: 'message',
        jsonName: 'GemSmash',
        oneof: 'action',
        T: () => Action$6,
      },
      {
        no: 7,
        name: 'HundredClub',
        kind: 'message',
        jsonName: 'HundredClub',
        oneof: 'action',
        T: () => Action$7,
      },
      {
        no: 8,
        name: 'PictureShuffle',
        kind: 'message',
        jsonName: 'PictureShuffle',
        oneof: 'action',
        T: () => Action$8,
      },
      {
        no: 9,
        name: 'StickerCollector',
        kind: 'message',
        jsonName: 'StickerCollector',
        oneof: 'action',
        T: () => Action$9,
      },
      {
        no: 10,
        name: 'FindItTables',
        kind: 'message',
        jsonName: 'FindItTables',
        oneof: 'action',
        T: () => Action$10,
      },
      {
        no: 11,
        name: 'LeagueTableGame',
        kind: 'message',
        jsonName: 'LeagueTableGame',
        oneof: 'action',
        T: () => Action$11,
      },
      {
        no: 12,
        name: 'GardenGame',
        kind: 'message',
        jsonName: 'GardenGame',
        oneof: 'action',
        T: () => Action$12,
      },
      {
        no: 13,
        name: 'MarbleSmasher',
        kind: 'message',
        jsonName: 'MarbleSmasher',
        oneof: 'action',
        T: () => Action$13,
      },
      {
        no: 14,
        name: 'GridPathGame',
        kind: 'message',
        jsonName: 'GridPathGame',
        oneof: 'action',
        T: () => Action$14,
      },
      {
        no: 15,
        name: 'RhythmGame',
        kind: 'message',
        jsonName: 'RhythmGame',
        oneof: 'action',
        T: () => Action$15,
      },
      {
        no: 500,
        name: 'tablesAnswer',
        kind: 'message',
        oneof: 'action',
        T: () => TablesAnswerAction,
      },
      {
        no: 501,
        name: 'phonicsAnswer',
        kind: 'message',
        oneof: 'action',
        T: () => PhonicsAnswerAction,
      },
      {
        no: 502,
        name: 'numberBondsAnswer',
        kind: 'message',
        oneof: 'action',
        T: () => NumberBondsAnswerAction,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.v1.Init', [
      {
        no: 1,
        name: 'FindIt',
        kind: 'message',
        jsonName: 'FindIt',
        oneof: 'init',
        T: () => Init$,
      },
      {
        no: 2,
        name: 'HiLo',
        kind: 'message',
        jsonName: 'HiLo',
        oneof: 'init',
        T: () => Init$2,
      },
      {
        no: 3,
        name: 'AutoHiLo',
        kind: 'message',
        jsonName: 'AutoHiLo',
        oneof: 'init',
        T: () => Init$3,
      },
      {
        no: 4,
        name: 'DiceGame',
        kind: 'message',
        jsonName: 'DiceGame',
        oneof: 'init',
        T: () => Init$4,
      },
      {
        no: 5,
        name: 'BlocksGame',
        kind: 'message',
        jsonName: 'BlocksGame',
        oneof: 'init',
        T: () => Init$5,
      },
      {
        no: 6,
        name: 'GemSmash',
        kind: 'message',
        jsonName: 'GemSmash',
        oneof: 'init',
        T: () => Init$6,
      },
      {
        no: 7,
        name: 'HundredClub',
        kind: 'message',
        jsonName: 'HundredClub',
        oneof: 'init',
        T: () => Init$7,
      },
      {
        no: 8,
        name: 'PictureShuffle',
        kind: 'message',
        jsonName: 'PictureShuffle',
        oneof: 'init',
        T: () => Init$8,
      },
      {
        no: 9,
        name: 'StickerCollector',
        kind: 'message',
        jsonName: 'StickerCollector',
        oneof: 'init',
        T: () => Init$9,
      },
      {
        no: 10,
        name: 'FindItTables',
        kind: 'message',
        jsonName: 'FindItTables',
        oneof: 'init',
        T: () => Init$10,
      },
      {
        no: 11,
        name: 'LeagueTableGame',
        kind: 'message',
        jsonName: 'LeagueTableGame',
        oneof: 'init',
        T: () => Init$11,
      },
      {
        no: 12,
        name: 'GardenGame',
        kind: 'message',
        jsonName: 'GardenGame',
        oneof: 'init',
        T: () => Init$12,
      },
      {
        no: 13,
        name: 'MarbleSmasher',
        kind: 'message',
        jsonName: 'MarbleSmasher',
        oneof: 'init',
        T: () => Init$13,
      },
      {
        no: 14,
        name: 'GridPathGame',
        kind: 'message',
        jsonName: 'GridPathGame',
        oneof: 'init',
        T: () => Init$14,
      },
      {
        no: 15,
        name: 'RhythmGame',
        kind: 'message',
        jsonName: 'RhythmGame',
        oneof: 'init',
        T: () => Init$15,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.v1.Update', [
      {
        no: 1,
        name: 'FindIt',
        kind: 'message',
        jsonName: 'FindIt',
        oneof: 'update',
        T: () => Update$,
      },
      {
        no: 2,
        name: 'HiLo',
        kind: 'message',
        jsonName: 'HiLo',
        oneof: 'update',
        T: () => Update$2,
      },
      {
        no: 3,
        name: 'AutoHiLo',
        kind: 'message',
        jsonName: 'AutoHiLo',
        oneof: 'update',
        T: () => Update$3,
      },
      {
        no: 4,
        name: 'DiceGame',
        kind: 'message',
        jsonName: 'DiceGame',
        oneof: 'update',
        T: () => Update$4,
      },
      {
        no: 5,
        name: 'BlocksGame',
        kind: 'message',
        jsonName: 'BlocksGame',
        oneof: 'update',
        T: () => Update$5,
      },
      {
        no: 6,
        name: 'GemSmash',
        kind: 'message',
        jsonName: 'GemSmash',
        oneof: 'update',
        T: () => Update$6,
      },
      {
        no: 7,
        name: 'HundredClub',
        kind: 'message',
        jsonName: 'HundredClub',
        oneof: 'update',
        T: () => Update$7,
      },
      {
        no: 8,
        name: 'PictureShuffle',
        kind: 'message',
        jsonName: 'PictureShuffle',
        oneof: 'update',
        T: () => Update$8,
      },
      {
        no: 9,
        name: 'StickerCollector',
        kind: 'message',
        jsonName: 'StickerCollector',
        oneof: 'update',
        T: () => Update$9,
      },
      {
        no: 10,
        name: 'FindItTables',
        kind: 'message',
        jsonName: 'FindItTables',
        oneof: 'update',
        T: () => Update$10,
      },
      {
        no: 11,
        name: 'LeagueTableGame',
        kind: 'message',
        jsonName: 'LeagueTableGame',
        oneof: 'update',
        T: () => Update$11,
      },
      {
        no: 12,
        name: 'GardenGame',
        kind: 'message',
        jsonName: 'GardenGame',
        oneof: 'update',
        T: () => Update$12,
      },
      {
        no: 13,
        name: 'MarbleSmasher',
        kind: 'message',
        jsonName: 'MarbleSmasher',
        oneof: 'update',
        T: () => Update$13,
      },
      {
        no: 14,
        name: 'GridPathGame',
        kind: 'message',
        jsonName: 'GridPathGame',
        oneof: 'update',
        T: () => Update$14,
      },
      {
        no: 15,
        name: 'RhythmGame',
        kind: 'message',
        jsonName: 'RhythmGame',
        oneof: 'update',
        T: () => Update$15,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.v1.Update
 */
export const Update = new Update$Type();

type Annotations = {
  [key: string]: string;
};

export const hasSupport = (anns: Annotations) => anns['support'] === 'has';

export const hasTalkAndLearn = (anns: Annotations) => anns['tal'] === 'has';

export const isTalkAndLearn = (anns: Annotations) => anns['tal'] === 'is';

export const isTalkAndLearnTransition = (anns: Annotations) => !!anns['tal_transition'];

export const isSupport = (anns: Annotations) => isTalkAndLearn(anns) || anns['support'] === 'is';

export const isNonTALSupport = (anns: Annotations) => isSupport(anns) && !isTalkAndLearn(anns);

export const isSecondChance = (anns: Annotations) => anns['second_chance'] === 'is';

export const isMultiStep = (anns: Annotations) =>
  anns['multistep_type'] === 'continuous' || anns['multistep_type'] === 'stepped';

export const isSparxOverriddenCorrect = (anns: Annotations) =>
  anns['sparx_overridden_correct'] === 'true';

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/tablesanswer/v1/tablesanswer.proto" (package "sparx.games.progress.tablesanswer.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.games.progress.tablesanswer.v1.TablesAnswerAction
 */
export interface TablesAnswerAction {
  /**
   * @generated from protobuf field: repeated sparx.games.progress.tablesanswer.v1.TablesAnswer answers = 1;
   */
  answers: TablesAnswer[];
}
/**
 * @generated from protobuf message sparx.games.progress.tablesanswer.v1.TablesAnswer
 */
export interface TablesAnswer {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp answerTime = 1;
   */
  answerTime?: Timestamp;
  /**
   * @generated from protobuf field: string questionText = 2;
   */
  questionText: string;
  /**
   * @generated from protobuf field: string answerText = 3;
   */
  answerText: string;
  /**
   * @generated from protobuf field: bool correct = 4;
   */
  correct: boolean;
  /**
   * @generated from protobuf field: bool timedOut = 5;
   */
  timedOut: boolean;
  /**
   * @generated from protobuf field: float timeTaken = 6;
   */
  timeTaken: number;
  /**
   * @generated from protobuf field: string game = 7;
   */
  game: string;
  /**
   * @generated from protobuf field: bool enterCorrectionPhase = 8;
   */
  enterCorrectionPhase: boolean;
  /**
   * @generated from protobuf field: bool leaveCorrectionPhase = 9;
   */
  leaveCorrectionPhase: boolean;
  /**
   * @generated from protobuf field: string inputString = 10;
   */
  inputString: string;
  /**
   * @generated from protobuf field: uint32 questionGap = 11;
   */
  questionGap: number;
  /**
   * @generated from protobuf field: bool badData = 12;
   */
  badData: boolean;
  /**
   * @generated from protobuf field: string questionSetID = 13;
   */
  questionSetID: string;
  /**
   * The delivery mechanism of the question - e.g. basicKeypad, talkAndLearn
   *
   * @generated from protobuf field: string deliveryMechanism = 14;
   */
  deliveryMechanism: string;
  /**
   * Whether the fact being tests was one of the user's times tables targets
   *
   * @generated from protobuf field: bool target = 15;
   */
  target: boolean;
  /**
   * The number of talk and learns the user must complete to unlock 100-club
   *
   * @generated from protobuf field: uint32 numPendingTalkAndLearns = 16;
   */
  numPendingTalkAndLearns: number;
  /**
   * The context the fact was delivered in
   *
   * @generated from protobuf field: sparx.games.progress.tablesanswer.v1.TablesAnswer.Context context = 18;
   */
  context: TablesAnswer_Context;
  /**
   * Whether the user pressed the "I don't know" button
   *
   * @generated from protobuf field: bool didNotKnow = 19;
   */
  didNotKnow: boolean;
  /**
   * At what position was this answered during this quiz session
   * (a quiz session could be an instance of a green bar, or
   * a 100 Club quiz between Venice appearing and results screen showing)
   *
   * @generated from protobuf field: uint32 indexWithinQuiz = 20;
   */
  indexWithinQuiz: number;
  /**
   * How the player was delivered the phrase to talk-and-learn, if they
   * are in a TaL.
   * Either "audio" or "text". Empty string if they were not in a TaL
   *
   * @generated from protobuf field: string talPromptType = 21;
   */
  talPromptType: string;
  /**
   * True if the player answered this in the second chance phase of 100 Club
   *
   * @generated from protobuf field: bool secondChance = 22;
   */
  secondChance: boolean;
  /**
   * If in Talk and Learn, the number of times the player had to be
   * shown the table before they could answer correctly
   *
   * @generated from protobuf field: uint32 talCycleCount = 23;
   */
  talCycleCount: number;
  /**
   * At what position was this answered during this game session
   * (a game session begins when a game loads and ends when the
   * player backs out or refreshes)
   *
   * @generated from protobuf field: uint32 indexWithinGameSession = 24;
   */
  indexWithinGameSession: number;
  /**
   * True if the question was the last question of a quiz
   * (used to tell if we should award an "end of Special Assessment"
   * point towards the quiz)
   *
   * @generated from protobuf field: bool isEndOfQuiz = 26;
   */
  isEndOfQuiz: boolean;
}
/**
 * The context a fact is given in
 * Only answers with the context TEST will impact the student's recall data
 * (i.e. their state in PottedDelivery)
 * The other options currently serve only as useful logging data
 *
 * @generated from protobuf enum sparx.games.progress.tablesanswer.v1.TablesAnswer.Context
 */
export enum TablesAnswer_Context {
  /**
   * @generated from protobuf enum value: UNKNOWN_CONTEXT = 0;
   */
  UNKNOWN_CONTEXT = 0,
  /**
   * The student is being tested on the fact
   *
   * @generated from protobuf enum value: TEST = 1;
   */
  TEST = 1,
  /**
   * The student is being corrected on a fact they just recalled incorrectly
   *
   * @generated from protobuf enum value: CORRECTION = 2;
   */
  CORRECTION = 2,
  /**
   * The student is being taught the fact - e.g. with a talk-and-learn or special question type
   *
   * @generated from protobuf enum value: TEACHING = 3;
   */
  TEACHING = 3,
  /**
   * The student chose to answer this question as part of a game - e.g. River Crossing
   * so it is not directly-linkable to their ability to recall a times table
   *
   * @generated from protobuf enum value: GAME_INPUT = 4;
   */
  GAME_INPUT = 4,
  /**
   * The question was asked during a special assessment and should be considered
   * disconnected from the student's progress
   *
   * @generated from protobuf enum value: SPECIAL_ASSESSMENT = 5;
   */
  SPECIAL_ASSESSMENT = 5,
  /**
   * The question was asked during a special assessment practice
   * and should not contribute any progress
   *
   * @generated from protobuf enum value: SPECIAL_ASSESSMENT_PRACTICE = 6;
   */
  SPECIAL_ASSESSMENT_PRACTICE = 6,
}
// @generated message type with reflection information, may provide speed optimized methods
class TablesAnswerAction$Type extends MessageType<TablesAnswerAction> {
  constructor() {
    super('sparx.games.progress.tablesanswer.v1.TablesAnswerAction', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TablesAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.tablesanswer.v1.TablesAnswerAction
 */
export const TablesAnswerAction = new TablesAnswerAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TablesAnswer$Type extends MessageType<TablesAnswer> {
  constructor() {
    super('sparx.games.progress.tablesanswer.v1.TablesAnswer', [
      { no: 1, name: 'answerTime', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'questionText',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'answerText', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 5, name: 'timedOut', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 6, name: 'timeTaken', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 7, name: 'game', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'enterCorrectionPhase',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 9,
        name: 'leaveCorrectionPhase',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 10,
        name: 'inputString',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 11,
        name: 'questionGap',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 12, name: 'badData', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 13,
        name: 'questionSetID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 14,
        name: 'deliveryMechanism',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 15, name: 'target', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 16,
        name: 'numPendingTalkAndLearns',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 18,
        name: 'context',
        kind: 'enum',
        T: () => [
          'sparx.games.progress.tablesanswer.v1.TablesAnswer.Context',
          TablesAnswer_Context,
        ],
      },
      { no: 19, name: 'didNotKnow', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 20,
        name: 'indexWithinQuiz',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 21,
        name: 'talPromptType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 22,
        name: 'secondChance',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 23,
        name: 'talCycleCount',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 24,
        name: 'indexWithinGameSession',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 26, name: 'isEndOfQuiz', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.tablesanswer.v1.TablesAnswer
 */
export const TablesAnswer = new TablesAnswer$Type();

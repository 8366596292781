import {
  Assessment,
  AssessmentQuestion,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useDimensions } from '@sparx/react-utils';
import { Tooltip } from '@sparx/sparx-design/components';
import { InfoOutlined, TriangleExclamationIcon } from '@sparx/sparx-design/icons';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { RefObject, useMemo, useState } from 'react';

import { useAssessmentContext } from '../../../../context';
import { AssessmentAggregatedScores, AssessmentTopicsDetails } from '../../../../types/assessments';
import { roundTo1DP } from '../../../../utils/assessments';
import { SortDirection } from '../../../../utils/sorting';
import { getSortingLabel } from '../../../Common/SortingLabel';
import { cellBorderStyles, getColourStyle } from '../../styleUtils';
import styles from './Marksheet.module.css';
import { SortableColumnName, sortFunctions, TableSorting } from './tableSortingUtils';

/**
 * Marksheet displays a Marksheet for the current assessment and student group, displaying
 * each student's results for the assessment.
 */

interface IMarksheetProps {
  // Aggregated scores from the studentAssessments related to the assessment and studentGroup
  aggregatedScores: AssessmentAggregatedScores;
  // The assessment to show data for
  assessment: Assessment;
  // Record of studentGroupIDs to StudentGroups
  groupMap: Record<string, Group>;
  // List of studentAssessments related to the assessment and studentGroup
  studentAssessments: StudentAssessment[];
  // List of students in the current studentGroup
  studentsInGroup: Student[];
  // Display details for the topics featured in the assessment
  topicDisplayDetails: AssessmentTopicsDetails;
  // Map of studentIDs to their percentile rankings amongst their yearGroup for the assessment
  studentPercentileRanks: Map<string, string>;
}

export const Marksheet = ({
  aggregatedScores,
  assessment,
  groupMap,
  studentAssessments,
  studentsInGroup,
  topicDisplayDetails,
  studentPercentileRanks,
}: IMarksheetProps) => {
  const { sendEvent: analytics } = useAssessmentContext();

  const [sortedBy, updateSortedBy] = useState<TableSorting>({
    dir: SortDirection.Ascending,
    sortBy: SortableColumnName.StudentName,
    sortFn: sortFunctions[SortableColumnName.StudentName],
  });

  const studentsMap = useMemo(
    () =>
      studentsInGroup.reduce<Record<string, Student>>((m, student) => {
        m[student.studentId] = student;
        return m;
      }, {}),
    [studentsInGroup],
  );

  const sortedAssessments = useMemo(
    () => studentAssessments.sort(sortedBy.sortFn(studentsMap, groupMap)(sortedBy.dir)),
    [studentAssessments, studentsMap, groupMap, sortedBy],
  );

  // To make the second column sticky with the first we need to know where to
  // position it, so we need to no the width of the first column
  const [firstColRef, firstColDimensions] = useDimensions();

  const sortClickHandler = (name: SortableColumnName) => {
    const newDirection = sortedBy.sortBy === name ? -sortedBy.dir : SortDirection.Ascending;
    updateSortedBy({
      dir: newDirection,
      sortBy: name,
      sortFn: sortFunctions[name],
    });

    analytics({
      action: 'Clicked marksheet column header to sort',
      category: 'Resources & Assessments',
      labels: {
        order: newDirection === SortDirection.Ascending ? 'ascending' : 'descending',
        sortedBy: name,
      },
    });
  };

  // Remove certain KaTeX from the topic names to make them fit better in the table header
  const formatDisplayName = (str: string) => str.replace(/\$\\frac.*\$/g, '...');

  const showTopicNames = assessment.subjectKey !== 'science';

  const createTopicNamesRow = () => (
    <tr>
      <td className={styles.InvisibleCell} style={cellBorderStyles({ withoutBottom: true })} />
      <td
        className={styles.HorizontalHeadingCell}
        style={{
          verticalAlign: 'bottom',
          ...cellBorderStyles({ withTop: true, withoutRight: true }),
        }}
      >
        Topic name
      </td>
      {topicDisplayDetails.map((details, i) => (
        <Tooltip
          position="bottom"
          key={`topic-name-${i}`}
          content={<TextWithMaths text={details.displayName} />}
        >
          <td className={styles.TopicNameCell} style={cellBorderStyles({ withoutRight: true })}>
            <div>
              <p
                className={classNames({
                  height: `${200 / Math.cos((Math.PI / 180) * 45) - 30}px`,
                })}
              >
                <TextWithMaths text={formatDisplayName(details.displayName)} />
              </p>
            </div>
          </td>
        </Tooltip>
      ))}
      <td
        className={styles.TableCell}
        style={{
          ...cellBorderStyles({ withoutBottom: true, withoutRight: true }),
          background: 'unset',
        }}
      />
    </tr>
  );

  const createAverageMarksRow = (
    questions: AssessmentQuestion[],
    averageScoresByQuestion: Map<string, number>,
  ) => (
    <tr>
      <td className={styles.InvisibleCell} style={cellBorderStyles({ withoutBottom: true })} />
      <td className={styles.HorizontalHeadingCell}>Average mark</td>
      {questions.map(question => {
        const averageScore = roundTo1DP(averageScoresByQuestion.get(question.name) || 0);
        return (
          <td
            key={`question-average-${question.name}`}
            className={styles.TableCellMark}
            style={{
              textAlign: 'center',
              ...getColourStyle(averageScore, question.availableMarks),
            }}
          >
            {averageScore}
          </td>
        );
      })}
      <td
        className={styles.TableCell}
        style={{
          ...cellBorderStyles({ withoutRight: true }),
          background: 'unset',
        }}
      />
    </tr>
  );

  const createAvailableMarksRow = (
    questions: AssessmentQuestion[],
    totalAvailableScore: number,
  ) => (
    <tr>
      <td className={styles.InvisibleCell} />
      <td className={styles.HorizontalHeadingCell}>Available marks</td>
      {questions.map(question => (
        <td className={styles.TableCell} key={`question-average-${question.name}`} align="center">
          {question.availableMarks}
        </td>
      ))}
      <td className={styles.TableCell} align="center">
        {totalAvailableScore}
      </td>
    </tr>
  );

  const createQuestionNumbersRow = (questions: AssessmentQuestion[]) => (
    <tr>
      <th
        className={styles.HeadingCell}
        ref={firstColRef as RefObject<HTMLTableCellElement>}
        style={cellBorderStyles({ withLeft: true })}
      >
        {getSortingLabel(
          sortedBy,
          sortClickHandler,
          SortableColumnName.StudentName,
          <strong>Student Name</strong>,
        )}
      </th>
      <th className={styles.HeadingCell}>
        {getSortingLabel(
          sortedBy,
          sortClickHandler,
          SortableColumnName.Class,
          <strong>Class</strong>,
        )}
      </th>
      {questions.map(question => (
        <th
          className={styles.HeadingCell}
          align="center"
          key={`question-number-${question.name}`}
          style={{ fontSize: 14 }}
        >
          Q{question.displayName}
        </th>
      ))}
      <th
        className={styles.HeadingCell}
        style={{ textAlign: 'center', fontWeight: 600, minWidth: 130 }}
      >
        Total
      </th>
      <th className={styles.HeadingCell} style={{ fontWeight: 600 }}>
        <div style={{ display: 'flex' }}>
          Percentile Rank{' '}
          <Tooltip
            position="left"
            content={`This shows the percentile rank for this student compared with other students in
            their year group at this school who sat this assessment. For example, 65th would mean that the student
            scored better than 65% of students in the year group.`}
          >
            <span className={styles.InfoIcon}>
              <InfoOutlined variant="Blue" />
            </span>
          </Tooltip>
        </div>
      </th>
    </tr>
  );

  const createStudentRows = (
    questions: AssessmentQuestion[],
    studentAssessments: StudentAssessment[],
    studentsMap: Record<string, Student>,
    studentTotals: Map<string, number>,
    totalAvailableScore: number,
    studentPercentileRanks: Map<string, string>,
  ) =>
    studentAssessments.map(studentAssessment => {
      const student = studentsMap[studentAssessment.studentId];
      const studentName = student
        ? `${student.givenName} ${student.familyName}`
        : 'Unknown Student';
      const studentClassID = student?.studentGroupIds?.find(
        id => groupMap[id]?.type === StudentGroupType.CLASS,
      );
      const studentClass = studentClassID ? groupMap[studentClassID].displayName : 'Unknown Group';

      const totalScore = studentTotals.get(studentAssessment.studentId);
      const percentile = studentPercentileRanks.get(studentAssessment.studentId);

      return (
        <tr key={`student-${studentAssessment.studentId}`}>
          <td
            className={styles.TableCell}
            style={{
              backgroundColor: 'inherit',
              ...cellBorderStyles({ withLeft: true }),
            }}
          >
            {studentName}
          </td>
          <td
            className={styles.TableCell}
            style={{
              backgroundColor: 'inherit',
            }}
          >
            {studentClass}
          </td>
          {questions.map(question => {
            const studentMark = studentAssessment.marks.find(
              mark => mark.assessmentQuestionName === question.name,
            );
            const studentScore = studentMark?.score;
            const unattempted = studentMark && studentScore === 0 && !studentMark.attempted;
            const invalidScore = !!(studentScore && studentScore > question.availableMarks);

            return (
              <Tooltip
                position="top"
                key={`student-${studentAssessment.studentId}-question-${question.name}`}
                content={invalidScore && 'Invalid mark'}
                disabled={!invalidScore}
              >
                <td
                  className={styles.TableCellMark}
                  style={{
                    textAlign: 'center',
                    ...getColourStyle(studentScore, question.availableMarks),
                  }}
                >
                  {invalidScore ? (
                    <TriangleExclamationIcon className={styles.MarkErrorIcon} />
                  ) : unattempted ? (
                    'U'
                  ) : (
                    studentScore
                  )}
                </td>
              </Tooltip>
            );
          })}
          <td
            className={styles.TableCell}
            style={{ textAlign: 'center', ...getColourStyle(totalScore, totalAvailableScore) }}
          >
            {totalScore}
          </td>
          {percentile ? (
            <td className={styles.TableCell} style={{ textAlign: 'center' }}>
              {percentile}
            </td>
          ) : (
            <Tooltip
              position="top"
              content="No Percentile Rank due to absence or incomplete data entry"
            >
              <td className={styles.TableCell} style={{ textAlign: 'center' }}>
                {' '}
                -{' '}
              </td>
            </Tooltip>
          )}
        </tr>
      );
    });

  const { averageScoresByQuestion, totalAvailableScore, studentTotals } = aggregatedScores;

  return (
    <table
      className={classNames(styles.Table, {
        '& tr td:nth-of-type(2)': {
          position: 'sticky',
          left: firstColDimensions.width || 0,
          zIndex: 4,
        },
      })}
    >
      <tbody>
        {showTopicNames && createTopicNamesRow()}
        {createAverageMarksRow(assessment.questions, averageScoresByQuestion)}
        {createAvailableMarksRow(assessment.questions, totalAvailableScore)}
        {createQuestionNumbersRow(assessment.questions)}
        {createStudentRows(
          assessment.questions,
          sortedAssessments,
          studentsMap,
          studentTotals,
          totalAvailableScore,
          studentPercentileRanks,
        )}
      </tbody>
    </table>
  );
};

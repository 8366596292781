import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import {
  faCaretDown,
  faPause,
  faPlay,
  faRotateLeft,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useLocalStorage } from '@sparx/react-utils';
import { Button } from '@sparx/sparx-design/components';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IMediaInputElement } from '../question/types';
import { useAssetUrl } from '../utils/asset';
import styles from './MediaInputElement.module.css';

type MediaMode = 'audio' | 'text' | 'both';

const useMediaMode = (hasSubtitles: boolean): [MediaMode, (m: MediaMode) => void] => {
  const { sendAnalyticEvent } = useSparxQuestionContext();
  const [mode, setMode] = useLocalStorage('question-media-input-mode', 'audio');

  let mediaMode: MediaMode = 'audio';
  switch (mode) {
    case 'text':
      mediaMode = 'text';
      break;
    case 'both':
      mediaMode = 'both';
      break;
  }

  const setMediaMode = (m: MediaMode) => {
    sendAnalyticEvent('media_input_mode_change', { newMode: m, previousMode: mode || 'unknown' });
    setMode(m);
  };

  return [hasSubtitles ? mediaMode : 'audio', setMediaMode];
};

export const MediaInputElement = ({ element }: LayoutElementProps<IMediaInputElement>) => {
  const {
    sendAction,
    input,
    sendAnalyticEvent,
    mediaInputSettings: { autoComplete, autoPlay, onPlay } = {},
  } = useSparxQuestionContext();
  const ref = useRef<HTMLVideoElement>(null);
  const trackref = useRef<HTMLTrackElement>(null);

  const { url } = useAssetUrl(element.asset_id);

  const urls = { videoUrl: url, subtitleUrl: url && url + '/subtitles' };

  const [subtitlesStatus, setSubtitlesStatus] = useState<'loading' | 'loaded' | 'error'>('loading');
  const [mediaMode, setMediaMode] = useMediaMode(subtitlesStatus === 'loaded');

  // Note: only supports VTTCue at the moment
  const [currentCue, setCurrentCue] = useState<VTTCue | undefined>(undefined);

  const [vidCanPlay, setVidCanPlay] = useState(false);
  const [started, setStarted] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [reachedTime, setReachedTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [videoError, setVideoError] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const refCopy = ref.current;
      const timeCallback = () => {
        setMaxTime(mt => Math.max(mt, refCopy?.duration || mt));
        setCurrentTime(refCopy?.currentTime || 0);
        setReachedTime(mt => Math.max(mt, refCopy?.currentTime || mt));

        if (refCopy?.ended) {
          sendAction({ action: 'media_complete', ref: element.ref, value: element.asset_id });
        }
      };
      const playCallback = () => {
        refCopy.playbackRate = mediaMode === 'text' ? 0.5 : 1;
        setPlaying(!refCopy?.paused || false);
        if (!refCopy?.paused) {
          onPlay?.();
        }
      };

      refCopy.addEventListener('play', playCallback);
      refCopy.addEventListener('pause', playCallback);
      refCopy.addEventListener('timeupdate', timeCallback);

      return () => {
        refCopy?.removeEventListener('play', playCallback);
        refCopy?.removeEventListener('pause', playCallback);
        refCopy?.removeEventListener('timeupdate', timeCallback);
      };
    }
  }, [
    ref,
    setPlaying,
    setMaxTime,
    setReachedTime,
    setCurrentTime,
    element,
    sendAction,
    onPlay,
    mediaMode,
  ]);

  useEffect(() => {
    if (trackref.current) {
      const trackCopy = trackref.current;
      trackCopy.track.mode = 'showing';
      const loadCallback = () => {
        setSubtitlesStatus('loaded');
      };
      const errorCallback = () => {
        setSubtitlesStatus(val => {
          if (val !== 'error') {
            // Only send event if we've not sent it before
            sendAnalyticEvent('media_input_track_error', { asset_id: element.asset_id });
          }
          return 'error';
        });
      };
      const cueChangeCallback = () => {
        const cues = [...(trackCopy.track.activeCues || [])].filter(
          c => c instanceof VTTCue,
        ) as VTTCue[];
        if (cues.length > 0) {
          setCurrentCue(cues[0]); // TODO: what if is more than one cue?
        }
      };

      // Handle cases where the track is already loaded/errored before we attach the listeners
      if (trackCopy.readyState === HTMLTrackElement.LOADED) {
        loadCallback();
      } else if (trackCopy.readyState === HTMLTrackElement.ERROR) {
        errorCallback();
      }

      trackCopy.addEventListener('load', loadCallback, false);
      trackCopy.addEventListener('error', errorCallback, false);
      trackCopy.addEventListener('cuechange', cueChangeCallback, false);
      return () => {
        trackCopy.removeEventListener('load', loadCallback);
        trackCopy.removeEventListener('error', errorCallback);
        trackCopy.removeEventListener('cuechange', cueChangeCallback);
      };
    }
  }, [trackref, sendAnalyticEvent, element]);

  const completed = Boolean(input.media_fields?.[element.ref]?.value);

  useEffect(() => {
    if (autoComplete && !completed) {
      sendAction({ action: 'media_complete', ref: element.ref, value: element.asset_id });
    }
  }, [autoComplete, completed, element, sendAction]);

  // When in text only mode slow it down to give more reading time.
  // Also handle muting the video here when the mode changes.
  useEffect(() => {
    if (ref.current) {
      ref.current.playbackRate = mediaMode === 'text' ? 0.5 : 1;
      if (vidCanPlay) {
        // Only set this if the video has loaded otherwise some thing odd
        // happens in Safari: if muted before it's loaded it instantly ends when played.
        ref.current.muted = mediaMode === 'text';
      }
    }
  }, [ref, mediaMode, vidCanPlay]);

  const play = () => {
    if (ref.current) {
      if (playing) ref.current.pause();
      else ref.current.play();
    }
  };

  const rewind = () => {
    if (ref.current) {
      ref.current.currentTime = ref.current.currentTime - 10;
    }
  };

  return (
    <>
      <div
        className={classNames(styles.Container, {
          [styles.ContainerComplete]: completed && !autoComplete,
        })}
      >
        <div className={styles.Controls}>
          <Button
            className={styles.PlayButton}
            onClick={play}
            variant="contained"
            isLoading={!vidCanPlay}
          >
            <div className={styles.ButtonIcon}>
              <FontAwesomeIcon icon={playing ? faPause : faPlay} fixedWidth />
            </div>
          </Button>
          <Button
            className={styles.RewindButton}
            onClick={rewind}
            isDisabled={maxTime === 0 || !vidCanPlay}
            variant="contained"
          >
            <div className={styles.ButtonIcon}>
              <FontAwesomeIcon icon={faRotateLeft} fixedWidth />
            </div>
          </Button>
          {subtitlesStatus === 'loaded' && (
            <MediaModeDropDown mediaMode={mediaMode} setMediaMode={setMediaMode} />
          )}
        </div>
        <div className={styles.TrackContainer}>
          {videoError ? (
            <div className={styles.ErrorLoading}>
              <FontAwesomeIcon icon={faWarning} /> Error loading media
            </div>
          ) : (
            <>
              <div className={styles.Track}>
                <div
                  className={styles.TrackReached}
                  style={{ width: `${(reachedTime / (maxTime || 1)) * 100}%` }}
                />
                <div
                  className={styles.TrackCurrent}
                  style={{ width: `${(currentTime / (maxTime || 1)) * 100}%` }}
                />
              </div>
              <div className={styles.Subtitles}>
                {subtitlesStatus === 'error' ? (
                  <div className={styles.ErrorLoading}>
                    <FontAwesomeIcon icon={faWarning} /> Error loading subtitles
                  </div>
                ) : (
                  <AnimatePresence mode="popLayout">
                    {started ? (
                      !currentCue || mediaMode === 'audio' ? (
                        <SubtitleLine key="empty">&nbsp;</SubtitleLine>
                      ) : (
                        <SubtitleLine key={currentCue.startTime}>{currentCue.text}</SubtitleLine>
                      )
                    ) : (
                      <SubtitleLine key="empty">
                        <i style={{ color: '#777777' }}>Press the play button to play the clip</i>
                      </SubtitleLine>
                    )}
                  </AnimatePresence>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {urls.videoUrl && (
        <video
          crossOrigin="anonymous"
          ref={ref}
          src={urls.videoUrl}
          controls={true}
          playsInline={true}
          preload="auto"
          autoPlay={autoPlay}
          onSeeking={() => {
            // This is sneaky but prevents seeking if they show the video and controls
            if (ref.current) {
              const diff = ref.current.currentTime - currentTime;
              if (diff > 0.01) {
                ref.current.currentTime = currentTime;
              }
            }
          }}
          onLoadedMetadata={() => {
            // Set canPlay after loading metadata, it would be better to only
            // do this in onCanPlayThrough but ios12 doesn't seem to fire those events.
            setVidCanPlay(true);
          }}
          onCanPlayThrough={e => {
            // We need to set muted here rather than on the element as Chrome
            // won't let us autoplay if only muted on the element, plus Safari
            // hits some weird bug when the element prop is used.
            e.currentTarget.muted = mediaMode === 'text';
            setVidCanPlay(true);
            autoPlay && e.currentTarget.play();
          }}
          onPlaying={() => setStarted(true)}
          onError={() => {
            setVideoError(true);
            sendAnalyticEvent('media_input_video_error', { asset_id: element.asset_id });
          }}
          style={{ display: 'none' }}
        >
          {urls.subtitleUrl && (
            <track
              ref={trackref}
              label="English"
              kind="captions"
              srcLang="en"
              src={urls.subtitleUrl}
              default={true}
            />
          )}
        </video>
      )}
    </>
  );
};

const SubtitleLine = React.forwardRef(
  ({ children }: PropsWithChildren, ref: React.ForwardedRef<HTMLDivElement>) => (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, translateY: 10 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: -10 }}
    >
      {children}
    </motion.div>
  ),
);
SubtitleLine.displayName = 'SubtitleLine';

const MediaModeDropDown = ({
  mediaMode,
  setMediaMode,
}: {
  mediaMode: MediaMode;
  setMediaMode: (m: MediaMode) => void;
}) => {
  // We manually handle the open state of the dropdown so we can on on the
  // onClick event for iOS 12 support.
  // Natively the menu only supports onPointerDown which doesn't work on iOS 12.
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu.Root modal={false} open={isOpen} onOpenChange={val => setIsOpen(val)}>
      <DropdownMenu.Trigger asChild>
        <Button
          className={styles.ModeSelectButton}
          leftIcon={<FontAwesomeIcon icon={faCommentDots} />}
          rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
          variant="outlined"
          onClick={() => setIsOpen(true)}
        >
          {mediaMode.charAt(0).toUpperCase() + mediaMode.slice(1)}
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.DropdownMenuContent}>
          <DropdownMenu.Item
            className={styles.DropdownMenuItem}
            onClick={() => setMediaMode('audio')}
          >
            Audio
          </DropdownMenu.Item>
          <DropdownMenu.Item
            className={styles.DropdownMenuItem}
            onClick={() => setMediaMode('text')}
          >
            Text
          </DropdownMenu.Item>
          <DropdownMenu.Item
            className={styles.DropdownMenuItem}
            onClick={() => setMediaMode('both')}
          >
            Both
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AssessmentDataEntry,
  StudentSettings,
} from '@sparx/assessments/src/components/AssessmentDataEntry/AssessmentDataEntry';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { Button } from 'components/button/Button';
import { selectedGroupValue } from 'components/header/BackLink';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDataEntryData } from 'views/teacher/assessmentsview/dataentryview/hooks';

export const PaperDataEntryView = () => {
  const {
    students,
    groups,
    uploadedResults,
    selectedGroup,
    assessment,
    selectedStudentId,
    setSelectedStudentId,
  } = useDataEntryData();

  const studentSettings = useMemo(
    () =>
      students.reduce((acc, r) => {
        const uploadedResult = uploadedResults?.get(r.studentId);
        acc.set(r.studentId, {
          mode: 'online',
          hasOnline: Boolean(uploadedResult),
          component: uploadedResult ? <OnlineNotice /> : undefined,
          disabled: Boolean(uploadedResult),
        });
        return acc;
      }, new Map<string, StudentSettings>()),
    [students, uploadedResults],
  );

  const group = selectedGroupValue(selectedGroup);
  if (!group) {
    return (
      <PageContainer filled={true}>
        <PageHeader back={`/teacher/${assessment.name}`}>
          {assessment.displayName} - Data entry
        </PageHeader>
        Select a group in header
      </PageContainer>
    );
  }

  return (
    <AssessmentDataEntry
      assessment={assessment}
      students={students}
      currentGroup={group}
      groups={groups}
      selectedStudentId={selectedStudentId}
      setSelectedStudentId={setSelectedStudentId}
      studentSettings={studentSettings}
    >
      {({ progress, content }) => (
        <PageContainer filled={true}>
          <PageHeader right={progress} back={`/teacher/${assessment.name}`}>
            {assessment.displayName}
            <PageHeaderSubpage>Data entry</PageHeaderSubpage>
          </PageHeader>
          {content}
        </PageContainer>
      )}
    </AssessmentDataEntry>
  );
};

const OnlineNotice = () => {
  const location = useLocation();
  const link = location.pathname.replace('/dataentry', '/submissions') + location.search;

  return (
    <Alert>
      <Alert.Icon />
      <Alert.Description>
        The student completed this assessment online. You can view theirs answers on the student
        submissions page.
      </Alert.Description>
      <Button
        as={Link}
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        to={link}
        style={{ marginLeft: 'var(--spx-unit-4)' }}
      >
        View
      </Button>
    </Alert>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/schoolstatus/v1/schoolstatus.proto" (package "sparx.teacherportal.schoolstatus.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Product } from '../../../types/product';
import { AttainmentLevel } from '../../../personalisation/attainmentlevel/v1/attainmentlevel';
import { StaffRoleAssignment } from '../../../school/staff/v2/staff';
import { TrainingProgress } from '../../../training/progress/v1/trainingprogress';
/**
 * Request message for GetSchoolOnboardingStatus.
 *
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusRequest
 */
export interface GetSchoolOnboardingStatusRequest {
  /**
   * The resource name of the school.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * Response message for GetSchoolOnboardingStatus.
 *
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusResponse
 */
export interface GetSchoolOnboardingStatusResponse {
  /**
   * @generated from protobuf field: sparx.teacherportal.schoolstatus.v1.SchoolOnboardingSummary summary = 1;
   */
  summary?: SchoolOnboardingSummary;
  /**
   * @generated from protobuf field: repeated sparx.teacherportal.schoolstatus.v1.TeacherOnboardingStatus teacher_statuses = 2;
   */
  teacherStatuses: TeacherOnboardingStatus[];
  /**
   * @generated from protobuf field: repeated sparx.teacherportal.schoolstatus.v1.ClassOnboardingStatus class_statuses = 3;
   */
  classStatuses: ClassOnboardingStatus[];
}
/**
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusRequest
 */
export interface GetSchoolTeacherOnboardingStatusRequest {
  /**
   * The resource name of the school.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusResponse
 */
export interface GetSchoolTeacherOnboardingStatusResponse {
  /**
   * @generated from protobuf field: repeated sparx.teacherportal.schoolstatus.v1.TeacherOnboardingStatus teacher_statuses = 1;
   */
  teacherStatuses: TeacherOnboardingStatus[];
}
/**
 * A simple summary of a school's setup status.
 *
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.SchoolOnboardingSummary
 */
export interface SchoolOnboardingSummary {
  /**
   * The number of active classes in the school.
   *
   * @generated from protobuf field: int32 classes_count = 1;
   */
  classesCount: number;
  /**
   * The number of active classes in the school that contain at least one student.
   *
   * @generated from protobuf field: int32 classes_with_students_count = 2;
   */
  classesWithStudentsCount: number;
  /**
   * The number of holidays set up in the current school year.
   *
   * @generated from protobuf field: int32 holidays_count = 3;
   */
  holidaysCount: number;
  /**
   * The number of schemes of learning currently set up.
   *
   * @generated from protobuf field: int32 schemes_of_learning_count = 4;
   */
  schemesOfLearningCount: number;
  /**
   * The number of active teachers in the school.
   *
   * @generated from protobuf field: int32 teachers_count = 5;
   */
  teachersCount: number;
  /**
   * The number of sparx students in the school.
   *
   * @generated from protobuf field: int32 students_count = 6;
   */
  studentsCount: number;
  /**
   * Whether or not the school is an MIS school.
   *
   * @generated from protobuf field: bool is_mis = 7;
   */
  isMis: boolean;
  /**
   * The wonde authorisation status of the school as defined by the constant values
   * specified in schools/utils/annotations
   *
   * @generated from protobuf field: string wonde_auth_status = 8;
   */
  wondeAuthStatus: string;
  /**
   * Whether or not the Wonde connection is online for this school.
   *
   * @generated from protobuf field: bool is_online = 14;
   */
  isOnline: boolean;
  /**
   * The set of optional features dependant on Wonde permissions
   *
   * @generated from protobuf field: sparx.teacherportal.schoolstatus.v1.OptionalFeatures features = 9;
   */
  features?: OptionalFeatures;
  /**
   * The SSO status for the school
   *
   * @generated from protobuf field: sparx.teacherportal.schoolstatus.v1.SSOStatus sso_status = 11;
   */
  ssoStatus?: SSOStatus;
  /**
   * Whether parent emails are enabled in Admin Portal
   *
   * @generated from protobuf field: bool parent_emails_enabled = 12;
   */
  parentEmailsEnabled: boolean;
  /**
   * THe number of sparx students with valid parents contacts
   *
   * @generated from protobuf field: int32 students_with_parental_contacts_count = 13;
   */
  studentsWithParentalContactsCount: number;
}
/**
 * Describes the set of optional features that depend on optional Wonde permissions
 *
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.OptionalFeatures
 */
export interface OptionalFeatures {
  /**
   * The list of permissions required to enable parent emails
   *
   * @generated from protobuf field: repeated sparx.teacherportal.schoolstatus.v1.RequiredWondePermission parent_email_permissions = 1;
   */
  parentEmailPermissions: RequiredWondePermission[];
  /**
   * The list of permissions required to enable SSO
   *
   * @generated from protobuf field: repeated sparx.teacherportal.schoolstatus.v1.RequiredWondePermission sso_permissions = 2;
   */
  ssoPermissions: RequiredWondePermission[];
  /**
   * The list of permissions required to enable student demographics (e.g. in reporting)
   *
   * @generated from protobuf field: repeated sparx.teacherportal.schoolstatus.v1.RequiredWondePermission reports_demographics_permissions = 3;
   */
  reportsDemographicsPermissions: RequiredWondePermission[];
}
/**
 * Represents a Wonde permission and whether or not it is approved by the school
 *
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.RequiredWondePermission
 */
export interface RequiredWondePermission {
  /**
   * The ID of the permission as displayed in the Wonde API
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * The display name of the permission as displayed in the Wonde UI
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * Whether the permission has been approved by the school
   *
   * @generated from protobuf field: bool approved = 3;
   */
  approved: boolean;
}
/**
 * Describes the current status of SSO for the school
 *
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.SSOStatus
 */
export interface SSOStatus {
  /**
   * Whether SSO is enabled for students
   *
   * @generated from protobuf field: bool student_sso_enabled = 1;
   */
  studentSsoEnabled: boolean;
  /**
   * Whether SSO is enabled for teachers
   *
   * @generated from protobuf field: bool teacher_sso_enabled = 2;
   */
  teacherSsoEnabled: boolean;
  /**
   * The SSO domain configured for students, if set
   *
   * @generated from protobuf field: string student_domain = 3;
   */
  studentDomain: string;
  /**
   * The SSO domain configured for teachers, if set
   *
   * @generated from protobuf field: string teacher_domain = 4;
   */
  teacherDomain: string;
  /**
   * The number of students that don't have email addresses in Sparx
   *
   * @generated from protobuf field: int32 students_with_missing_email = 5;
   */
  studentsWithMissingEmail: number;
  /**
   * The number of students that have email addresses with the wrong domain
   *
   * @generated from protobuf field: int32 students_with_wrong_email_domain = 6;
   */
  studentsWithWrongEmailDomain: number;
}
/**
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.TeacherOnboardingStatus
 */
export interface TeacherOnboardingStatus {
  /**
   * @generated from protobuf field: string staff_id = 1;
   */
  staffId: string;
  /**
   * @generated from protobuf field: string first_name = 2;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string last_name = 3;
   */
  lastName: string;
  /**
   * @generated from protobuf field: string email = 4;
   */
  email: string;
  /**
   * This field is deprecated. String role names are no longer in use; use the role_assignments
   * field instead which provides role and product pairs.
   *
   * @deprecated
   * @generated from protobuf field: repeated string roles = 5 [deprecated = true];
   */
  roles: string[];
  /**
   * @generated from protobuf field: sparx.training.progress.v1.TrainingProgress training_progress = 7;
   */
  trainingProgress?: TrainingProgress;
  /**
   * @generated from protobuf field: repeated sparx.school.staff.v2.StaffRoleAssignment role_assignments = 8;
   */
  roleAssignments: StaffRoleAssignment[];
}
/**
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.ClassOnboardingStatus
 */
export interface ClassOnboardingStatus {
  /**
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
  /**
   * @generated from protobuf field: string class_name = 2;
   */
  className: string;
  /**
   * @generated from protobuf field: string sol_id = 3;
   */
  solId: string;
  /**
   * @generated from protobuf field: repeated string teacher_ids = 4;
   */
  teacherIds: string[];
  /**
   * @generated from protobuf field: sparx.personalisation.attainmentlevel.v1.AttainmentLevel attainment_level = 5;
   */
  attainmentLevel: AttainmentLevel;
  /**
   * @generated from protobuf field: bool homework_enabled = 6;
   */
  homeworkEnabled: boolean;
  /**
   * The number of students in the class
   *
   * @generated from protobuf field: int32 student_count = 7;
   */
  studentCount: number;
  /**
   * The number of students in the class that have logged in
   *
   * @generated from protobuf field: int32 students_logged_in = 8;
   */
  studentsLoggedIn: number;
  /**
   * The number of students in the class that have started their practice homework
   *
   * @generated from protobuf field: int32 students_started_practice = 9;
   */
  studentsStartedPractice: number;
  /**
   * The number of students in the class that have started or could start their practice homework
   * (e.g. it is not yet created or hasn't yet expired)
   *
   * @generated from protobuf field: int32 students_could_start_practice = 10;
   */
  studentsCouldStartPractice: number;
}
/**
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.StudentDomainCheckRequest
 */
export interface StudentDomainCheckRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: string domain = 2;
   */
  domain: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.StudentDomainCheckResponse
 */
export interface StudentDomainCheckResponse {
  /**
   * The number of students that have email addresses with the wrong domain.
   *
   * @generated from protobuf field: int32 students_with_wrong_email_domain = 1;
   */
  studentsWithWrongEmailDomain: number;
  /**
   * The number of sparx students in the school.
   *
   * @generated from protobuf field: int32 students_count = 2;
   */
  studentsCount: number;
}
/**
 * Request message for GetMISStatus.
 *
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.GetMISStatusRequest
 */
export interface GetMISStatusRequest {
  /**
   * The resource name of the school.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The product to check the MIS status for.
   *
   * @generated from protobuf field: sparx.types.Product product = 2;
   */
  product: Product;
}
/**
 * Response message for GetMISStatus.
 *
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.GetMISStatusResponse
 */
export interface GetMISStatusResponse {
  /**
   * Whether or not the school's MIS appears to have 'rolled over' with respect to the current data in Sparx.
   *
   * @generated from protobuf field: bool has_rolled_over = 1;
   */
  hasRolledOver: boolean;
  /**
   * The number of existing students in Sparx.
   *
   * @generated from protobuf field: int32 sparx_students = 2;
   */
  sparxStudents: number;
  /**
   * The number of students no longer appearing in the school's MIS.
   *
   * @generated from protobuf field: int32 missing_students = 3;
   */
  missingStudents: number;
  /**
   * The number of students who are no longer in the any of the classes they
   * were in last academic year in Sparx, but that are still in at least one MIS
   * class.
   *
   * @generated from protobuf field: int32 moved_students = 4;
   */
  movedStudents: number;
}
/**
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkRequest
 */
export interface GetSchoolHasHomeworkRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkResponse
 */
export interface GetSchoolHasHomeworkResponse {
  /**
   * @generated from protobuf field: bool has_homework = 1;
   */
  hasHomework: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolOnboardingStatusRequest$Type extends MessageType<GetSchoolOnboardingStatusRequest> {
  constructor() {
    super(
      'sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusRequest',
      [
        {
          no: 1,
          name: 'school_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusRequest
 */
export const GetSchoolOnboardingStatusRequest =
  new GetSchoolOnboardingStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolOnboardingStatusResponse$Type extends MessageType<GetSchoolOnboardingStatusResponse> {
  constructor() {
    super(
      'sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusResponse',
      [
        {
          no: 1,
          name: 'summary',
          kind: 'message',
          T: () => SchoolOnboardingSummary,
        },
        {
          no: 2,
          name: 'teacher_statuses',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => TeacherOnboardingStatus,
        },
        {
          no: 3,
          name: 'class_statuses',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => ClassOnboardingStatus,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolOnboardingStatusResponse
 */
export const GetSchoolOnboardingStatusResponse =
  new GetSchoolOnboardingStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolTeacherOnboardingStatusRequest$Type extends MessageType<GetSchoolTeacherOnboardingStatusRequest> {
  constructor() {
    super(
      'sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusRequest',
      [
        {
          no: 1,
          name: 'school_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusRequest
 */
export const GetSchoolTeacherOnboardingStatusRequest =
  new GetSchoolTeacherOnboardingStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolTeacherOnboardingStatusResponse$Type extends MessageType<GetSchoolTeacherOnboardingStatusResponse> {
  constructor() {
    super(
      'sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusResponse',
      [
        {
          no: 1,
          name: 'teacher_statuses',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => TeacherOnboardingStatus,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolTeacherOnboardingStatusResponse
 */
export const GetSchoolTeacherOnboardingStatusResponse =
  new GetSchoolTeacherOnboardingStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolOnboardingSummary$Type extends MessageType<SchoolOnboardingSummary> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.SchoolOnboardingSummary', [
      {
        no: 1,
        name: 'classes_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'classes_with_students_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'holidays_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'schemes_of_learning_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'teachers_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'students_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 7, name: 'is_mis', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 8,
        name: 'wonde_auth_status',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 14, name: 'is_online', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 9, name: 'features', kind: 'message', T: () => OptionalFeatures },
      { no: 11, name: 'sso_status', kind: 'message', T: () => SSOStatus },
      {
        no: 12,
        name: 'parent_emails_enabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 13,
        name: 'students_with_parental_contacts_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.SchoolOnboardingSummary
 */
export const SchoolOnboardingSummary = new SchoolOnboardingSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OptionalFeatures$Type extends MessageType<OptionalFeatures> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.OptionalFeatures', [
      {
        no: 1,
        name: 'parent_email_permissions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RequiredWondePermission,
      },
      {
        no: 2,
        name: 'sso_permissions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RequiredWondePermission,
      },
      {
        no: 3,
        name: 'reports_demographics_permissions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RequiredWondePermission,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.OptionalFeatures
 */
export const OptionalFeatures = new OptionalFeatures$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequiredWondePermission$Type extends MessageType<RequiredWondePermission> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.RequiredWondePermission', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'approved', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.RequiredWondePermission
 */
export const RequiredWondePermission = new RequiredWondePermission$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SSOStatus$Type extends MessageType<SSOStatus> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.SSOStatus', [
      {
        no: 1,
        name: 'student_sso_enabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'teacher_sso_enabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'student_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'teacher_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'students_with_missing_email',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'students_with_wrong_email_domain',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.SSOStatus
 */
export const SSOStatus = new SSOStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeacherOnboardingStatus$Type extends MessageType<TeacherOnboardingStatus> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.TeacherOnboardingStatus', [
      { no: 1, name: 'staff_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'roles',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'training_progress',
        kind: 'message',
        T: () => TrainingProgress,
      },
      {
        no: 8,
        name: 'role_assignments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaffRoleAssignment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.TeacherOnboardingStatus
 */
export const TeacherOnboardingStatus = new TeacherOnboardingStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClassOnboardingStatus$Type extends MessageType<ClassOnboardingStatus> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.ClassOnboardingStatus', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'class_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'sol_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'teacher_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'attainment_level',
        kind: 'enum',
        T: () => [
          'sparx.personalisation.attainmentlevel.v1.AttainmentLevel',
          AttainmentLevel,
        ],
      },
      {
        no: 6,
        name: 'homework_enabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 7,
        name: 'student_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'students_logged_in',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 9,
        name: 'students_started_practice',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 10,
        name: 'students_could_start_practice',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.ClassOnboardingStatus
 */
export const ClassOnboardingStatus = new ClassOnboardingStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentDomainCheckRequest$Type extends MessageType<StudentDomainCheckRequest> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.StudentDomainCheckRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'domain', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.StudentDomainCheckRequest
 */
export const StudentDomainCheckRequest = new StudentDomainCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentDomainCheckResponse$Type extends MessageType<StudentDomainCheckResponse> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.StudentDomainCheckResponse', [
      {
        no: 1,
        name: 'students_with_wrong_email_domain',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'students_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.StudentDomainCheckResponse
 */
export const StudentDomainCheckResponse = new StudentDomainCheckResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMISStatusRequest$Type extends MessageType<GetMISStatusRequest> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.GetMISStatusRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.GetMISStatusRequest
 */
export const GetMISStatusRequest = new GetMISStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMISStatusResponse$Type extends MessageType<GetMISStatusResponse> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.GetMISStatusResponse', [
      {
        no: 1,
        name: 'has_rolled_over',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'sparx_students',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'missing_students',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'moved_students',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.GetMISStatusResponse
 */
export const GetMISStatusResponse = new GetMISStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolHasHomeworkRequest$Type extends MessageType<GetSchoolHasHomeworkRequest> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkRequest
 */
export const GetSchoolHasHomeworkRequest =
  new GetSchoolHasHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolHasHomeworkResponse$Type extends MessageType<GetSchoolHasHomeworkResponse> {
  constructor() {
    super('sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkResponse', [
      { no: 1, name: 'has_homework', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.schoolstatus.v1.GetSchoolHasHomeworkResponse
 */
export const GetSchoolHasHomeworkResponse =
  new GetSchoolHasHomeworkResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.teacherportal.schoolstatus.v1.SchoolStatusService
 */
export const SchoolStatusService = new ServiceType(
  'sparx.teacherportal.schoolstatus.v1.SchoolStatusService',
  [
    {
      name: 'GetSchoolOnboardingStatus',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'schoolstatus',
          domain: '{school_name}',
        },
      },
      I: GetSchoolOnboardingStatusRequest,
      O: GetSchoolOnboardingStatusResponse,
    },
    {
      name: 'GetSchoolTeacherOnboardingStatus',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'schoolstatus',
          domain: '{school_name}',
        },
      },
      I: GetSchoolTeacherOnboardingStatusRequest,
      O: GetSchoolTeacherOnboardingStatusResponse,
    },
    {
      name: 'StudentDomainCheck',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'schoolstatus',
          domain: '{school_name}',
        },
      },
      I: StudentDomainCheckRequest,
      O: StudentDomainCheckResponse,
    },
    {
      name: 'GetMISStatus',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'schoolstatus',
          domain: '{school_name}',
        },
      },
      I: GetMISStatusRequest,
      O: GetMISStatusResponse,
    },
    {
      name: 'GetSchoolHasHomework',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'schoolstatus',
          domain: '{school_name}',
        },
      },
      I: GetSchoolHasHomeworkRequest,
      O: GetSchoolHasHomeworkResponse,
    },
  ],
);

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/content/summaries/v1/curriculum.proto" (package "sparx.content.summaries.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { SkillSummaries } from './curriculum';
import type { GetSkillSummaryResponse } from './curriculum';
import type { GetSkillSummaryRequest } from './curriculum';
import { CurriculumSummaries } from './curriculum';
import type { GetCurriculumSummaryResponse } from './curriculum';
import type { GetCurriculumSummaryRequest } from './curriculum';
import type { ListCurriculumSummariesResponse } from './curriculum';
import type { ListCurriculumSummariesRequest } from './curriculum';
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { TopicSummaries } from './curriculum';
import type { GetCurriculumLUTopicsMapResponse } from './curriculum';
import type { GetCurriculumLUTopicsMapRequest } from './curriculum';
import type { GetTopicSummaryResponse } from './curriculum';
import type { GetTopicSummaryRequest } from './curriculum';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListTopicSummariesResponse } from './curriculum';
import type { ListTopicSummariesRequest } from './curriculum';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * This service gives summary information about topics.
 *
 * @generated from protobuf service sparx.content.summaries.v1.TopicSummaries
 */
export interface ITopicSummariesClient {
  /**
   * List all topic summaries for a curriculum.  Returns light topic
   * summaries, without key questions.
   *
   * @generated from protobuf rpc: ListTopicSummaries(sparx.content.summaries.v1.ListTopicSummariesRequest) returns (sparx.content.summaries.v1.ListTopicSummariesResponse);
   */
  listTopicSummaries(
    input: ListTopicSummariesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListTopicSummariesRequest, ListTopicSummariesResponse>;
  /**
   * Get a topic summary for a particular topic.  Returns a detailed summary
   * (with key questions).
   *
   * @generated from protobuf rpc: GetTopicSummary(sparx.content.summaries.v1.GetTopicSummaryRequest) returns (sparx.content.summaries.v1.GetTopicSummaryResponse);
   */
  getTopicSummary(
    input: GetTopicSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTopicSummaryRequest, GetTopicSummaryResponse>;
  /**
   * GetCurriculumLUTopicsMap returns a map of learning unit name to topic for the requested curriculum
   *
   * @generated from protobuf rpc: GetCurriculumLUTopicsMap(sparx.content.summaries.v1.GetCurriculumLUTopicsMapRequest) returns (sparx.content.summaries.v1.GetCurriculumLUTopicsMapResponse);
   */
  getCurriculumLUTopicsMap(
    input: GetCurriculumLUTopicsMapRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetCurriculumLUTopicsMapRequest,
    GetCurriculumLUTopicsMapResponse
  >;
}
/**
 * This service gives summary information about topics.
 *
 * @generated from protobuf service sparx.content.summaries.v1.TopicSummaries
 */
export class TopicSummariesClient
  implements ITopicSummariesClient, ServiceInfo
{
  typeName = TopicSummaries.typeName;
  methods = TopicSummaries.methods;
  options = TopicSummaries.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * List all topic summaries for a curriculum.  Returns light topic
   * summaries, without key questions.
   *
   * @generated from protobuf rpc: ListTopicSummaries(sparx.content.summaries.v1.ListTopicSummariesRequest) returns (sparx.content.summaries.v1.ListTopicSummariesResponse);
   */
  listTopicSummaries(
    input: ListTopicSummariesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListTopicSummariesRequest, ListTopicSummariesResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListTopicSummariesRequest,
      ListTopicSummariesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Get a topic summary for a particular topic.  Returns a detailed summary
   * (with key questions).
   *
   * @generated from protobuf rpc: GetTopicSummary(sparx.content.summaries.v1.GetTopicSummaryRequest) returns (sparx.content.summaries.v1.GetTopicSummaryResponse);
   */
  getTopicSummary(
    input: GetTopicSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTopicSummaryRequest, GetTopicSummaryResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetTopicSummaryRequest, GetTopicSummaryResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetCurriculumLUTopicsMap returns a map of learning unit name to topic for the requested curriculum
   *
   * @generated from protobuf rpc: GetCurriculumLUTopicsMap(sparx.content.summaries.v1.GetCurriculumLUTopicsMapRequest) returns (sparx.content.summaries.v1.GetCurriculumLUTopicsMapResponse);
   */
  getCurriculumLUTopicsMap(
    input: GetCurriculumLUTopicsMapRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetCurriculumLUTopicsMapRequest,
    GetCurriculumLUTopicsMapResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetCurriculumLUTopicsMapRequest,
      GetCurriculumLUTopicsMapResponse
    >('unary', this._transport, method, opt, input);
  }
}
/**
 * This service gives summary information about curriculums.
 *
 * @generated from protobuf service sparx.content.summaries.v1.CurriculumSummaries
 */
export interface ICurriculumSummariesClient {
  /**
   * List all curriculum summaries. The response contains the whole
   * strand/substrand hierarchy as well as general info about the curriculum.
   *
   * @generated from protobuf rpc: ListCurriculumSummaries(sparx.content.summaries.v1.ListCurriculumSummariesRequest) returns (sparx.content.summaries.v1.ListCurriculumSummariesResponse);
   */
  listCurriculumSummaries(
    input: ListCurriculumSummariesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListCurriculumSummariesRequest, ListCurriculumSummariesResponse>;
  /**
   * Get a particular curriculum summary.  The response contains the whole
   * strand/substrand hierarchy as well as general info about the curriculum.
   *
   * @generated from protobuf rpc: GetCurriculumSummary(sparx.content.summaries.v1.GetCurriculumSummaryRequest) returns (sparx.content.summaries.v1.GetCurriculumSummaryResponse);
   */
  getCurriculumSummary(
    input: GetCurriculumSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCurriculumSummaryRequest, GetCurriculumSummaryResponse>;
}
/**
 * This service gives summary information about curriculums.
 *
 * @generated from protobuf service sparx.content.summaries.v1.CurriculumSummaries
 */
export class CurriculumSummariesClient
  implements ICurriculumSummariesClient, ServiceInfo
{
  typeName = CurriculumSummaries.typeName;
  methods = CurriculumSummaries.methods;
  options = CurriculumSummaries.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * List all curriculum summaries. The response contains the whole
   * strand/substrand hierarchy as well as general info about the curriculum.
   *
   * @generated from protobuf rpc: ListCurriculumSummaries(sparx.content.summaries.v1.ListCurriculumSummariesRequest) returns (sparx.content.summaries.v1.ListCurriculumSummariesResponse);
   */
  listCurriculumSummaries(
    input: ListCurriculumSummariesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListCurriculumSummariesRequest,
    ListCurriculumSummariesResponse
  > {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListCurriculumSummariesRequest,
      ListCurriculumSummariesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Get a particular curriculum summary.  The response contains the whole
   * strand/substrand hierarchy as well as general info about the curriculum.
   *
   * @generated from protobuf rpc: GetCurriculumSummary(sparx.content.summaries.v1.GetCurriculumSummaryRequest) returns (sparx.content.summaries.v1.GetCurriculumSummaryResponse);
   */
  getCurriculumSummary(
    input: GetCurriculumSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCurriculumSummaryRequest, GetCurriculumSummaryResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetCurriculumSummaryRequest,
      GetCurriculumSummaryResponse
    >('unary', this._transport, method, opt, input);
  }
}
/**
 * @generated from protobuf service sparx.content.summaries.v1.SkillSummaries
 */
export interface ISkillSummariesClient {
  /**
   * Get a particular skill. The response contains the full details of the skill
   * as well as a sample question
   *
   * @generated from protobuf rpc: GetSkillSummary(sparx.content.summaries.v1.GetSkillSummaryRequest) returns (sparx.content.summaries.v1.GetSkillSummaryResponse);
   */
  getSkillSummary(
    input: GetSkillSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSkillSummaryRequest, GetSkillSummaryResponse>;
}
/**
 * @generated from protobuf service sparx.content.summaries.v1.SkillSummaries
 */
export class SkillSummariesClient
  implements ISkillSummariesClient, ServiceInfo
{
  typeName = SkillSummaries.typeName;
  methods = SkillSummaries.methods;
  options = SkillSummaries.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Get a particular skill. The response contains the full details of the skill
   * as well as a sample question
   *
   * @generated from protobuf rpc: GetSkillSummary(sparx.content.summaries.v1.GetSkillSummaryRequest) returns (sparx.content.summaries.v1.GetSkillSummaryResponse);
   */
  getSkillSummary(
    input: GetSkillSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSkillSummaryRequest, GetSkillSummaryResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSkillSummaryRequest, GetSkillSummaryResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}

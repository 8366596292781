// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/assessment/v1/assessment.proto" (package "sparx.assessment.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { AssessmentService } from './assessment';
import type { ListAssessmentGroupsResponse } from './assessment';
import type { ListAssessmentGroupsRequest } from './assessment';
import type { GetAssessmentGroupResponse } from './assessment';
import type { GetAssessmentGroupRequest } from './assessment';
import type { GetAssessmentSummaryResponse } from './assessment';
import type { GetAssessmentSummaryRequest } from './assessment';
import type { GetOwnAssessmentPackagesResponse } from './assessment';
import type { GetOwnAssessmentPackagesRequest } from './assessment';
import type { GetOwnAssessmentDataResponse } from './assessment';
import type { GetOwnAssessmentDataRequest } from './assessment';
import type { BatchGetAssessmentRevisionProgressResponse } from './assessment';
import type { BatchGetAssessmentRevisionProgressRequest } from './assessment';
import type { GetAssessmentRevisionInsightsResponse } from './assessment';
import type { GetAssessmentRevisionInsightsRequest } from './assessment';
import type { ListAssessmentRevisionsResponse } from './assessment';
import type { ListAssessmentRevisionsRequest } from './assessment';
import type { GetAssessmentRevisionResponse } from './assessment';
import type { GetAssessmentRevisionRequest } from './assessment';
import type { GetTrustNationalComparisonReportRequest } from './assessment';
import type { GetNationalComparisonReportDryRunRequest } from './assessment';
import type { GetNationalComparisonReportResponse } from './assessment';
import type { GetNationalComparisonReportRequest } from './assessment';
import type { GetAssessmentInsightsResponse } from './assessment';
import type { GetAssessmentInsightsRequest } from './assessment';
import type { ListAssessmentPackageProgressResponse } from './assessment';
import type { ListAssessmentPackageProgressRequest } from './assessment';
import type { ListGroupSettingsResponse } from './assessment';
import type { ListGroupSettingsRequest } from './assessment';
import type { GroupSettings } from './assessment';
import type { GetGroupSettingsRequest } from './assessment';
import type { StudentAssessment } from './assessment';
import type { ListAllStudentAssessmentsResponse } from './assessment';
import type { ListAllStudentAssessmentsRequest } from './assessment';
import type { BatchGetStudentAssessmentsResponse } from './assessment';
import type { BatchGetStudentAssessmentsRequest } from './assessment';
import type { ListStudentAssessmentsResponse } from './assessment';
import type { ListStudentAssessmentsRequest } from './assessment';
import type { ListAssessmentSnapshotsResponse } from './assessment';
import type { ListAssessmentSnapshotsRequest } from './assessment';
import type { ListLatestAssessmentSnapshotsResponse } from './assessment';
import type { ListLatestAssessmentSnapshotsRequest } from './assessment';
import type { Assessment } from './assessment';
import type { GetAssessmentRequest } from './assessment';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListAssessmentsResponse } from './assessment';
import type { ListAssessmentsRequest } from './assessment';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.assessment.v1.AssessmentService
 */
export interface IAssessmentServiceClient {
  /**
   * Retrieve a list of all assessments
   *
   * @generated from protobuf rpc: ListAssessments(sparx.assessment.v1.ListAssessmentsRequest) returns (sparx.assessment.v1.ListAssessmentsResponse);
   */
  listAssessments(
    input: ListAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentsRequest, ListAssessmentsResponse>;
  /**
   * Retrieve a single assessment
   *
   * @generated from protobuf rpc: GetAssessment(sparx.assessment.v1.GetAssessmentRequest) returns (sparx.assessment.v1.Assessment);
   */
  getAssessment(
    input: GetAssessmentRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentRequest, Assessment>;
  /**
   * Retrieve details of when the last snapshot was created for each assessment. This allows us to determine whether
   * a report is available to download for the current academic year yet
   * TODO: BOO-129 deprecate this and use ListAssessmentSnapshots instead
   *
   * @generated from protobuf rpc: ListLatestAssessmentSnapshots(sparx.assessment.v1.ListLatestAssessmentSnapshotsRequest) returns (sparx.assessment.v1.ListLatestAssessmentSnapshotsResponse);
   */
  listLatestAssessmentSnapshots(
    input: ListLatestAssessmentSnapshotsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListLatestAssessmentSnapshotsRequest,
    ListLatestAssessmentSnapshotsResponse
  >;
  /**
   * Retrieve details of when the last snapshots was created for each assessment between the given dates.
   * This allows us to determine whether a report is available to download for the academic year provided yet.
   *
   * @generated from protobuf rpc: ListAssessmentSnapshots(sparx.assessment.v1.ListAssessmentSnapshotsRequest) returns (sparx.assessment.v1.ListAssessmentSnapshotsResponse);
   */
  listAssessmentSnapshots(
    input: ListAssessmentSnapshotsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentSnapshotsRequest, ListAssessmentSnapshotsResponse>;
  /**
   * Retrieve a list of all student assessments for the given assessment and list of student ids. If no data has
   * been stored for a student then an empty/default assessment will be returned with all the marks set to 0
   * TODO BOOL-2705: deprecate this in favour of BatchGetStudentAssessments
   *
   * @generated from protobuf rpc: ListStudentAssessments(sparx.assessment.v1.ListStudentAssessmentsRequest) returns (sparx.assessment.v1.ListStudentAssessmentsResponse);
   */
  listStudentAssessments(
    input: ListStudentAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentAssessmentsRequest, ListStudentAssessmentsResponse>;
  /**
   * Retrieve a list of all student assessments for the given assessments and list of student ids. If no data has
   * been stored for a student then an empty/default assessment will be returned with all the marks set to 0
   *
   * @generated from protobuf rpc: BatchGetStudentAssessments(sparx.assessment.v1.BatchGetStudentAssessmentsRequest) returns (sparx.assessment.v1.BatchGetStudentAssessmentsResponse);
   */
  batchGetStudentAssessments(
    input: BatchGetStudentAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchGetStudentAssessmentsRequest,
    BatchGetStudentAssessmentsResponse
  >;
  /**
   * Retrieve a list of all student assessments.
   *
   * @generated from protobuf rpc: ListAllStudentAssessments(sparx.assessment.v1.ListAllStudentAssessmentsRequest) returns (sparx.assessment.v1.ListAllStudentAssessmentsResponse);
   */
  listAllStudentAssessments(
    input: ListAllStudentAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAllStudentAssessmentsRequest,
    ListAllStudentAssessmentsResponse
  >;
  /**
   * Update a student assessment
   *
   * @generated from protobuf rpc: UpdateStudentAssessment(sparx.assessment.v1.StudentAssessment) returns (sparx.assessment.v1.StudentAssessment);
   */
  updateStudentAssessment(
    input: StudentAssessment,
    options?: RpcOptions,
  ): UnaryCall<StudentAssessment, StudentAssessment>;
  /**
   * Get the settings for a group
   *
   * @generated from protobuf rpc: GetGroupSettings(sparx.assessment.v1.GetGroupSettingsRequest) returns (sparx.assessment.v1.GroupSettings);
   */
  getGroupSettings(
    input: GetGroupSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetGroupSettingsRequest, GroupSettings>;
  /**
   * Get the group settings for all student groups within a school for the given assessment
   *
   * @generated from protobuf rpc: ListGroupSettings(sparx.assessment.v1.ListGroupSettingsRequest) returns (sparx.assessment.v1.ListGroupSettingsResponse);
   */
  listGroupSettings(
    input: ListGroupSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListGroupSettingsRequest, ListGroupSettingsResponse>;
  /**
   * Update the settings for a group
   *
   * @generated from protobuf rpc: UpdateGroupSettings(sparx.assessment.v1.GroupSettings) returns (sparx.assessment.v1.GroupSettings);
   */
  updateGroupSettings(
    input: GroupSettings,
    options?: RpcOptions,
  ): UnaryCall<GroupSettings, GroupSettings>;
  /**
   * List the assessment packages for the provided studentIDs, assessment & package type
   *
   * @generated from protobuf rpc: ListAssessmentPackageProgress(sparx.assessment.v1.ListAssessmentPackageProgressRequest) returns (sparx.assessment.v1.ListAssessmentPackageProgressResponse);
   */
  listAssessmentPackageProgress(
    input: ListAssessmentPackageProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAssessmentPackageProgressRequest,
    ListAssessmentPackageProgressResponse
  >;
  /**
   * @generated from protobuf rpc: GetAssessmentInsights(sparx.assessment.v1.GetAssessmentInsightsRequest) returns (sparx.assessment.v1.GetAssessmentInsightsResponse);
   */
  getAssessmentInsights(
    input: GetAssessmentInsightsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentInsightsRequest, GetAssessmentInsightsResponse>;
  /**
   * Get all the data needed to create a National Comparison Report for an assessment. This only works if the
   * snapshot of data has been created - i.e. the report is past the release date
   *
   * @generated from protobuf rpc: GetNationalComparisonReport(sparx.assessment.v1.GetNationalComparisonReportRequest) returns (sparx.assessment.v1.GetNationalComparisonReportResponse);
   */
  getNationalComparisonReport(
    input: GetNationalComparisonReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetNationalComparisonReportRequest,
    GetNationalComparisonReportResponse
  >;
  /**
   * Get all the data needed to create a National Comparison Report for an assessment, using dry run snapshot. This
   * can be called at any time for by a Sparx staff member to preview what a report would look like if it were
   * released now with the currently entered student assessments data.
   *
   * @generated from protobuf rpc: GetNationalComparisonReportDryRun(sparx.assessment.v1.GetNationalComparisonReportDryRunRequest) returns (sparx.assessment.v1.GetNationalComparisonReportResponse);
   */
  getNationalComparisonReportDryRun(
    input: GetNationalComparisonReportDryRunRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetNationalComparisonReportDryRunRequest,
    GetNationalComparisonReportResponse
  >;
  /**
   * Get all the data needed to create a Trust Comparison Report for an assessment, using dry run snapshot. This
   * can be called at any time for by a Sparx staff member to preview what a report would look like if it were
   * released now with the currently entered student assessments data. This endpoint accepts a list of group IDs to use
   * as the comparison data.
   *
   * @generated from protobuf rpc: GetTrustNationalReport(sparx.assessment.v1.GetTrustNationalComparisonReportRequest) returns (sparx.assessment.v1.GetNationalComparisonReportResponse);
   */
  getTrustNationalReport(
    input: GetTrustNationalComparisonReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetTrustNationalComparisonReportRequest,
    GetNationalComparisonReportResponse
  >;
  /**
   * Retrieve a single assessment revision
   *
   * @generated from protobuf rpc: GetAssessmentRevision(sparx.assessment.v1.GetAssessmentRevisionRequest) returns (sparx.assessment.v1.GetAssessmentRevisionResponse);
   */
  getAssessmentRevision(
    input: GetAssessmentRevisionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentRevisionRequest, GetAssessmentRevisionResponse>;
  /**
   * Retrieve a list of all assessment revisions
   *
   * @generated from protobuf rpc: ListAssessmentRevisions(sparx.assessment.v1.ListAssessmentRevisionsRequest) returns (sparx.assessment.v1.ListAssessmentRevisionsResponse);
   */
  listAssessmentRevisions(
    input: ListAssessmentRevisionsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentRevisionsRequest, ListAssessmentRevisionsResponse>;
  /**
   * Retrieve Insights data for a given Assessment Revision
   *
   * @generated from protobuf rpc: GetAssessmentRevisionInsights(sparx.assessment.v1.GetAssessmentRevisionInsightsRequest) returns (sparx.assessment.v1.GetAssessmentRevisionInsightsResponse);
   */
  getAssessmentRevisionInsights(
    input: GetAssessmentRevisionInsightsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetAssessmentRevisionInsightsRequest,
    GetAssessmentRevisionInsightsResponse
  >;
  /**
   * Retrieve all assessment revision progress for the provided studentIDs & assessment revision
   *
   * @generated from protobuf rpc: BatchGetAssessmentRevisionProgress(sparx.assessment.v1.BatchGetAssessmentRevisionProgressRequest) returns (sparx.assessment.v1.BatchGetAssessmentRevisionProgressResponse);
   */
  batchGetAssessmentRevisionProgress(
    input: BatchGetAssessmentRevisionProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchGetAssessmentRevisionProgressRequest,
    BatchGetAssessmentRevisionProgressResponse
  >;
  // Endpoints called by Sparx Web

  /**
   * The user for this request is taken from the context
   *
   * @generated from protobuf rpc: GetOwnAssessmentData(sparx.assessment.v1.GetOwnAssessmentDataRequest) returns (sparx.assessment.v1.GetOwnAssessmentDataResponse);
   */
  getOwnAssessmentData(
    input: GetOwnAssessmentDataRequest,
    options?: RpcOptions,
  ): UnaryCall<GetOwnAssessmentDataRequest, GetOwnAssessmentDataResponse>;
  /**
   * The user for this request is taken from the context
   *
   * @generated from protobuf rpc: GetOwnAssessmentPackages(sparx.assessment.v1.GetOwnAssessmentPackagesRequest) returns (sparx.assessment.v1.GetOwnAssessmentPackagesResponse);
   */
  getOwnAssessmentPackages(
    input: GetOwnAssessmentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetOwnAssessmentPackagesRequest,
    GetOwnAssessmentPackagesResponse
  >;
  /**
   * Returns all active tasks and the associated assessments for the currently logged in student.
   *
   * @generated from protobuf rpc: GetAssessmentSummary(sparx.assessment.v1.GetAssessmentSummaryRequest) returns (sparx.assessment.v1.GetAssessmentSummaryResponse);
   */
  getAssessmentSummary(
    input: GetAssessmentSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentSummaryRequest, GetAssessmentSummaryResponse>;
  /**
   * Retrieve a single assessment group
   *
   * @generated from protobuf rpc: GetAssessmentGroup(sparx.assessment.v1.GetAssessmentGroupRequest) returns (sparx.assessment.v1.GetAssessmentGroupResponse);
   */
  getAssessmentGroup(
    input: GetAssessmentGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentGroupRequest, GetAssessmentGroupResponse>;
  /**
   * Retrieve a list of all assessment groups
   *
   * @generated from protobuf rpc: ListAssessmentGroups(sparx.assessment.v1.ListAssessmentGroupsRequest) returns (sparx.assessment.v1.ListAssessmentGroupsResponse);
   */
  listAssessmentGroups(
    input: ListAssessmentGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentGroupsRequest, ListAssessmentGroupsResponse>;
}
/**
 * @generated from protobuf service sparx.assessment.v1.AssessmentService
 */
export class AssessmentServiceClient
  implements IAssessmentServiceClient, ServiceInfo
{
  typeName = AssessmentService.typeName;
  methods = AssessmentService.methods;
  options = AssessmentService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Retrieve a list of all assessments
   *
   * @generated from protobuf rpc: ListAssessments(sparx.assessment.v1.ListAssessmentsRequest) returns (sparx.assessment.v1.ListAssessmentsResponse);
   */
  listAssessments(
    input: ListAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentsRequest, ListAssessmentsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListAssessmentsRequest, ListAssessmentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Retrieve a single assessment
   *
   * @generated from protobuf rpc: GetAssessment(sparx.assessment.v1.GetAssessmentRequest) returns (sparx.assessment.v1.Assessment);
   */
  getAssessment(
    input: GetAssessmentRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentRequest, Assessment> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetAssessmentRequest, Assessment>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Retrieve details of when the last snapshot was created for each assessment. This allows us to determine whether
   * a report is available to download for the current academic year yet
   * TODO: BOO-129 deprecate this and use ListAssessmentSnapshots instead
   *
   * @generated from protobuf rpc: ListLatestAssessmentSnapshots(sparx.assessment.v1.ListLatestAssessmentSnapshotsRequest) returns (sparx.assessment.v1.ListLatestAssessmentSnapshotsResponse);
   */
  listLatestAssessmentSnapshots(
    input: ListLatestAssessmentSnapshotsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListLatestAssessmentSnapshotsRequest,
    ListLatestAssessmentSnapshotsResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListLatestAssessmentSnapshotsRequest,
      ListLatestAssessmentSnapshotsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve details of when the last snapshots was created for each assessment between the given dates.
   * This allows us to determine whether a report is available to download for the academic year provided yet.
   *
   * @generated from protobuf rpc: ListAssessmentSnapshots(sparx.assessment.v1.ListAssessmentSnapshotsRequest) returns (sparx.assessment.v1.ListAssessmentSnapshotsResponse);
   */
  listAssessmentSnapshots(
    input: ListAssessmentSnapshotsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAssessmentSnapshotsRequest,
    ListAssessmentSnapshotsResponse
  > {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentSnapshotsRequest,
      ListAssessmentSnapshotsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve a list of all student assessments for the given assessment and list of student ids. If no data has
   * been stored for a student then an empty/default assessment will be returned with all the marks set to 0
   * TODO BOOL-2705: deprecate this in favour of BatchGetStudentAssessments
   *
   * @generated from protobuf rpc: ListStudentAssessments(sparx.assessment.v1.ListStudentAssessmentsRequest) returns (sparx.assessment.v1.ListStudentAssessmentsResponse);
   */
  listStudentAssessments(
    input: ListStudentAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentAssessmentsRequest, ListStudentAssessmentsResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListStudentAssessmentsRequest,
      ListStudentAssessmentsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve a list of all student assessments for the given assessments and list of student ids. If no data has
   * been stored for a student then an empty/default assessment will be returned with all the marks set to 0
   *
   * @generated from protobuf rpc: BatchGetStudentAssessments(sparx.assessment.v1.BatchGetStudentAssessmentsRequest) returns (sparx.assessment.v1.BatchGetStudentAssessmentsResponse);
   */
  batchGetStudentAssessments(
    input: BatchGetStudentAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchGetStudentAssessmentsRequest,
    BatchGetStudentAssessmentsResponse
  > {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchGetStudentAssessmentsRequest,
      BatchGetStudentAssessmentsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve a list of all student assessments.
   *
   * @generated from protobuf rpc: ListAllStudentAssessments(sparx.assessment.v1.ListAllStudentAssessmentsRequest) returns (sparx.assessment.v1.ListAllStudentAssessmentsResponse);
   */
  listAllStudentAssessments(
    input: ListAllStudentAssessmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAllStudentAssessmentsRequest,
    ListAllStudentAssessmentsResponse
  > {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAllStudentAssessmentsRequest,
      ListAllStudentAssessmentsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Update a student assessment
   *
   * @generated from protobuf rpc: UpdateStudentAssessment(sparx.assessment.v1.StudentAssessment) returns (sparx.assessment.v1.StudentAssessment);
   */
  updateStudentAssessment(
    input: StudentAssessment,
    options?: RpcOptions,
  ): UnaryCall<StudentAssessment, StudentAssessment> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<StudentAssessment, StudentAssessment>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Get the settings for a group
   *
   * @generated from protobuf rpc: GetGroupSettings(sparx.assessment.v1.GetGroupSettingsRequest) returns (sparx.assessment.v1.GroupSettings);
   */
  getGroupSettings(
    input: GetGroupSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetGroupSettingsRequest, GroupSettings> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetGroupSettingsRequest, GroupSettings>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Get the group settings for all student groups within a school for the given assessment
   *
   * @generated from protobuf rpc: ListGroupSettings(sparx.assessment.v1.ListGroupSettingsRequest) returns (sparx.assessment.v1.ListGroupSettingsResponse);
   */
  listGroupSettings(
    input: ListGroupSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListGroupSettingsRequest, ListGroupSettingsResponse> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListGroupSettingsRequest, ListGroupSettingsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Update the settings for a group
   *
   * @generated from protobuf rpc: UpdateGroupSettings(sparx.assessment.v1.GroupSettings) returns (sparx.assessment.v1.GroupSettings);
   */
  updateGroupSettings(
    input: GroupSettings,
    options?: RpcOptions,
  ): UnaryCall<GroupSettings, GroupSettings> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GroupSettings, GroupSettings>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * List the assessment packages for the provided studentIDs, assessment & package type
   *
   * @generated from protobuf rpc: ListAssessmentPackageProgress(sparx.assessment.v1.ListAssessmentPackageProgressRequest) returns (sparx.assessment.v1.ListAssessmentPackageProgressResponse);
   */
  listAssessmentPackageProgress(
    input: ListAssessmentPackageProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAssessmentPackageProgressRequest,
    ListAssessmentPackageProgressResponse
  > {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentPackageProgressRequest,
      ListAssessmentPackageProgressResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetAssessmentInsights(sparx.assessment.v1.GetAssessmentInsightsRequest) returns (sparx.assessment.v1.GetAssessmentInsightsResponse);
   */
  getAssessmentInsights(
    input: GetAssessmentInsightsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentInsightsRequest, GetAssessmentInsightsResponse> {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetAssessmentInsightsRequest,
      GetAssessmentInsightsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Get all the data needed to create a National Comparison Report for an assessment. This only works if the
   * snapshot of data has been created - i.e. the report is past the release date
   *
   * @generated from protobuf rpc: GetNationalComparisonReport(sparx.assessment.v1.GetNationalComparisonReportRequest) returns (sparx.assessment.v1.GetNationalComparisonReportResponse);
   */
  getNationalComparisonReport(
    input: GetNationalComparisonReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetNationalComparisonReportRequest,
    GetNationalComparisonReportResponse
  > {
    const method = this.methods[13],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetNationalComparisonReportRequest,
      GetNationalComparisonReportResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Get all the data needed to create a National Comparison Report for an assessment, using dry run snapshot. This
   * can be called at any time for by a Sparx staff member to preview what a report would look like if it were
   * released now with the currently entered student assessments data.
   *
   * @generated from protobuf rpc: GetNationalComparisonReportDryRun(sparx.assessment.v1.GetNationalComparisonReportDryRunRequest) returns (sparx.assessment.v1.GetNationalComparisonReportResponse);
   */
  getNationalComparisonReportDryRun(
    input: GetNationalComparisonReportDryRunRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetNationalComparisonReportDryRunRequest,
    GetNationalComparisonReportResponse
  > {
    const method = this.methods[14],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetNationalComparisonReportDryRunRequest,
      GetNationalComparisonReportResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Get all the data needed to create a Trust Comparison Report for an assessment, using dry run snapshot. This
   * can be called at any time for by a Sparx staff member to preview what a report would look like if it were
   * released now with the currently entered student assessments data. This endpoint accepts a list of group IDs to use
   * as the comparison data.
   *
   * @generated from protobuf rpc: GetTrustNationalReport(sparx.assessment.v1.GetTrustNationalComparisonReportRequest) returns (sparx.assessment.v1.GetNationalComparisonReportResponse);
   */
  getTrustNationalReport(
    input: GetTrustNationalComparisonReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetTrustNationalComparisonReportRequest,
    GetNationalComparisonReportResponse
  > {
    const method = this.methods[15],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetTrustNationalComparisonReportRequest,
      GetNationalComparisonReportResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve a single assessment revision
   *
   * @generated from protobuf rpc: GetAssessmentRevision(sparx.assessment.v1.GetAssessmentRevisionRequest) returns (sparx.assessment.v1.GetAssessmentRevisionResponse);
   */
  getAssessmentRevision(
    input: GetAssessmentRevisionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentRevisionRequest, GetAssessmentRevisionResponse> {
    const method = this.methods[16],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetAssessmentRevisionRequest,
      GetAssessmentRevisionResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve a list of all assessment revisions
   *
   * @generated from protobuf rpc: ListAssessmentRevisions(sparx.assessment.v1.ListAssessmentRevisionsRequest) returns (sparx.assessment.v1.ListAssessmentRevisionsResponse);
   */
  listAssessmentRevisions(
    input: ListAssessmentRevisionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAssessmentRevisionsRequest,
    ListAssessmentRevisionsResponse
  > {
    const method = this.methods[17],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentRevisionsRequest,
      ListAssessmentRevisionsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve Insights data for a given Assessment Revision
   *
   * @generated from protobuf rpc: GetAssessmentRevisionInsights(sparx.assessment.v1.GetAssessmentRevisionInsightsRequest) returns (sparx.assessment.v1.GetAssessmentRevisionInsightsResponse);
   */
  getAssessmentRevisionInsights(
    input: GetAssessmentRevisionInsightsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetAssessmentRevisionInsightsRequest,
    GetAssessmentRevisionInsightsResponse
  > {
    const method = this.methods[18],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetAssessmentRevisionInsightsRequest,
      GetAssessmentRevisionInsightsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve all assessment revision progress for the provided studentIDs & assessment revision
   *
   * @generated from protobuf rpc: BatchGetAssessmentRevisionProgress(sparx.assessment.v1.BatchGetAssessmentRevisionProgressRequest) returns (sparx.assessment.v1.BatchGetAssessmentRevisionProgressResponse);
   */
  batchGetAssessmentRevisionProgress(
    input: BatchGetAssessmentRevisionProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchGetAssessmentRevisionProgressRequest,
    BatchGetAssessmentRevisionProgressResponse
  > {
    const method = this.methods[19],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchGetAssessmentRevisionProgressRequest,
      BatchGetAssessmentRevisionProgressResponse
    >('unary', this._transport, method, opt, input);
  }
  // Endpoints called by Sparx Web

  /**
   * The user for this request is taken from the context
   *
   * @generated from protobuf rpc: GetOwnAssessmentData(sparx.assessment.v1.GetOwnAssessmentDataRequest) returns (sparx.assessment.v1.GetOwnAssessmentDataResponse);
   */
  getOwnAssessmentData(
    input: GetOwnAssessmentDataRequest,
    options?: RpcOptions,
  ): UnaryCall<GetOwnAssessmentDataRequest, GetOwnAssessmentDataResponse> {
    const method = this.methods[20],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetOwnAssessmentDataRequest,
      GetOwnAssessmentDataResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * The user for this request is taken from the context
   *
   * @generated from protobuf rpc: GetOwnAssessmentPackages(sparx.assessment.v1.GetOwnAssessmentPackagesRequest) returns (sparx.assessment.v1.GetOwnAssessmentPackagesResponse);
   */
  getOwnAssessmentPackages(
    input: GetOwnAssessmentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetOwnAssessmentPackagesRequest,
    GetOwnAssessmentPackagesResponse
  > {
    const method = this.methods[21],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetOwnAssessmentPackagesRequest,
      GetOwnAssessmentPackagesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Returns all active tasks and the associated assessments for the currently logged in student.
   *
   * @generated from protobuf rpc: GetAssessmentSummary(sparx.assessment.v1.GetAssessmentSummaryRequest) returns (sparx.assessment.v1.GetAssessmentSummaryResponse);
   */
  getAssessmentSummary(
    input: GetAssessmentSummaryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentSummaryRequest, GetAssessmentSummaryResponse> {
    const method = this.methods[22],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetAssessmentSummaryRequest,
      GetAssessmentSummaryResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve a single assessment group
   *
   * @generated from protobuf rpc: GetAssessmentGroup(sparx.assessment.v1.GetAssessmentGroupRequest) returns (sparx.assessment.v1.GetAssessmentGroupResponse);
   */
  getAssessmentGroup(
    input: GetAssessmentGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentGroupRequest, GetAssessmentGroupResponse> {
    const method = this.methods[23],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetAssessmentGroupRequest,
      GetAssessmentGroupResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Retrieve a list of all assessment groups
   *
   * @generated from protobuf rpc: ListAssessmentGroups(sparx.assessment.v1.ListAssessmentGroupsRequest) returns (sparx.assessment.v1.ListAssessmentGroupsResponse);
   */
  listAssessmentGroups(
    input: ListAssessmentGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssessmentGroupsRequest, ListAssessmentGroupsResponse> {
    const method = this.methods[24],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentGroupsRequest,
      ListAssessmentGroupsResponse
    >('unary', this._transport, method, opt, input);
  }
}

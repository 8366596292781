interface InitialiseProps {
  appId: string;
  crossOrigin?: string;
  onLoad?: () => void;
  onLoadFailed?: () => void;
}

export const initialise = ({ appId, crossOrigin, onLoad, onLoadFailed }: InitialiseProps) => {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function (...args: unknown[]) {
      i.c(args);
    };
    i.q = [] as unknown[];
    i.c = function (args: unknown) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + appId;
      if (crossOrigin) {
        s.crossOrigin = crossOrigin;
      }
      if (onLoad) {
        s.addEventListener('load', () => {
          onLoad && onLoad();
        });
      }
      if (onLoadFailed) {
        s.addEventListener('error', () => {
          // No need to pass any information from the ErrorEvent because it will contain no information about the error.
          onLoadFailed && onLoadFailed();
        });
      }
      const x = d.getElementsByTagName('script')[0];
      x.parentNode?.insertBefore(s, x);
    };

    if (document.readyState === 'complete') {
      l();
    } else {
      w.addEventListener('load', l, false);
    }
  }
};

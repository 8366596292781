// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/assessment/v1/adaptive.proto" (package "sparx.assessment.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.assessment.v1.AdaptiveAssessmentSettings
 */
export interface AdaptiveAssessmentSettings {
  /**
   * Minimum number of questions that a student should be issued on this quiz, regardless
   * of the minutes_max value.
   *
   * @generated from protobuf field: int32 min_questions = 1;
   */
  minQuestions: number;
  /**
   * Maximum number of questions that a student should be issued on this quiz.
   *
   * @generated from protobuf field: int32 max_questions = 2;
   */
  maxQuestions: number;
  /**
   * Maximum number of minutes that a student should spend on this quiz. If the student
   * has answered min_questions but has not spent this amount of time on the quiz then
   * questions will continue to be asked until they have spent minutes_max or have been
   * issued max_questions.
   *
   * @generated from protobuf field: int32 minutes_max = 3;
   */
  minutesMax: number;
  /**
   * Question index of the first question the student is issued on the quiz.
   *
   * @generated from protobuf field: int32 start = 4;
   */
  start: number;
  /**
   * Random jitter +/- around the start index that should be applied when starting
   * the quiz. For example a start index of 5 and a jitter of 2 would allow students
   * to start on indexes 3,4,5,6,7.
   *
   * @generated from protobuf field: int32 start_jitter = 5;
   */
  startJitter: number;
  /**
   * Number of questions to move when a student answers a question incorrectly.
   *
   * @generated from protobuf field: int32 move_on_wrong = 6;
   */
  moveOnWrong: number;
  /**
   * Number of questions to move when a student answers a question correctly.
   *
   * @generated from protobuf field: int32 move_on_correct = 7;
   */
  moveOnCorrect: number;
}
/**
 * @generated from protobuf message sparx.assessment.v1.AdaptiveAssessmentContents
 */
export interface AdaptiveAssessmentContents {
  /**
   * Equivalent size of this task in a package.
   *
   * @generated from protobuf field: int32 size = 1;
   */
  size: number;
  /**
   * Ordered list of skills available in this assessment.
   *
   * @generated from protobuf field: repeated string skill_ids = 2;
   */
  skillIds: string[];
  /**
   * Additional description on this assessment.
   *
   * @generated from protobuf field: string description = 4;
   */
  description: string;
  /**
   * Settings for the delivery of skills within the assessment.
   *
   * @generated from protobuf field: sparx.assessment.v1.AdaptiveAssessmentSettings settings = 5;
   */
  settings?: AdaptiveAssessmentSettings;
}
/**
 * @generated from protobuf message sparx.assessment.v1.BucketedAssessmentContents
 */
export interface BucketedAssessmentContents {
  /**
   * Equivalent size of this task in a package.
   *
   * @generated from protobuf field: int32 size = 1;
   */
  size: number;
  /**
   * Ordered list of buckets. The last bucket that is <= max_level will be used.
   * If the level is greater than the max_level of the last bucket then the last
   * bucket will be used.
   *
   * @generated from protobuf field: repeated sparx.assessment.v1.BucketedAssessmentContents.Bucket buckets = 2;
   */
  buckets: BucketedAssessmentContents_Bucket[];
  /**
   * @generated from protobuf field: string tutorial_intro_skill_id = 3;
   */
  tutorialIntroSkillId: string;
  /**
   * @generated from protobuf field: repeated string tutorial_skill_ids = 4;
   */
  tutorialSkillIds: string[];
}
/**
 * @generated from protobuf message sparx.assessment.v1.BucketedAssessmentContents.Bucket
 */
export interface BucketedAssessmentContents_Bucket {
  /**
   * @generated from protobuf field: int32 max_level = 1;
   */
  maxLevel: number;
  /**
   * @generated from protobuf field: string intro_skill_id = 3;
   */
  introSkillId: string;
  /**
   * @generated from protobuf field: repeated string skill_ids = 2;
   */
  skillIds: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class AdaptiveAssessmentSettings$Type extends MessageType<AdaptiveAssessmentSettings> {
  constructor() {
    super('sparx.assessment.v1.AdaptiveAssessmentSettings', [
      {
        no: 1,
        name: 'min_questions',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'max_questions',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'minutes_max', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'start', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 5,
        name: 'start_jitter',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'move_on_wrong',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'move_on_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AdaptiveAssessmentSettings
 */
export const AdaptiveAssessmentSettings = new AdaptiveAssessmentSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdaptiveAssessmentContents$Type extends MessageType<AdaptiveAssessmentContents> {
  constructor() {
    super('sparx.assessment.v1.AdaptiveAssessmentContents', [
      { no: 1, name: 'size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'skill_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'settings',
        kind: 'message',
        T: () => AdaptiveAssessmentSettings,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.AdaptiveAssessmentContents
 */
export const AdaptiveAssessmentContents = new AdaptiveAssessmentContents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BucketedAssessmentContents$Type extends MessageType<BucketedAssessmentContents> {
  constructor() {
    super('sparx.assessment.v1.BucketedAssessmentContents', [
      { no: 1, name: 'size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'buckets',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BucketedAssessmentContents_Bucket,
      },
      {
        no: 3,
        name: 'tutorial_intro_skill_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'tutorial_skill_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.BucketedAssessmentContents
 */
export const BucketedAssessmentContents = new BucketedAssessmentContents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BucketedAssessmentContents_Bucket$Type extends MessageType<BucketedAssessmentContents_Bucket> {
  constructor() {
    super('sparx.assessment.v1.BucketedAssessmentContents.Bucket', [
      { no: 1, name: 'max_level', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'intro_skill_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'skill_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.v1.BucketedAssessmentContents.Bucket
 */
export const BucketedAssessmentContents_Bucket =
  new BucketedAssessmentContents_Bucket$Type();

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/phonics/v1/phonics.proto" (package "sparx.games.progress.phonics.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.games.progress.phonics.v1.PhonicsAnswerAction
 */
export interface PhonicsAnswerAction {
  /**
   * @generated from protobuf field: repeated sparx.games.progress.phonics.v1.PhonicsAnswer answers = 1;
   */
  answers: PhonicsAnswer[];
}
/**
 * @generated from protobuf message sparx.games.progress.phonics.v1.PhonicsAnswer
 */
export interface PhonicsAnswer {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp answerTime = 1;
   */
  answerTime?: Timestamp;
  /**
   * @generated from protobuf field: string questionId = 2;
   */
  questionId: string;
  /**
   * @generated from protobuf field: string answerGrapheme = 3;
   */
  answerGrapheme: string;
  /**
   * @generated from protobuf field: string chosenGrapheme = 4;
   */
  chosenGrapheme: string;
  /**
   * @generated from protobuf field: bool correct = 5;
   */
  correct: boolean;
  /**
   * @generated from protobuf field: string orderedEvents = 6;
   */
  orderedEvents: string;
  /**
   * @generated from protobuf field: string targetWord = 7;
   */
  targetWord: string;
  /**
   * @generated from protobuf field: string answerSpelling = 8;
   */
  answerSpelling: string;
  /**
   * @generated from protobuf field: string distractors = 9;
   */
  distractors: string;
  /**
   * @generated from protobuf field: float timeTaken = 10;
   */
  timeTaken: number;
  /**
   * @generated from protobuf field: int32 questionSourcedFromSet = 11;
   */
  questionSourcedFromSet: number;
  /**
   * @generated from protobuf field: int32 studentCurrentSet = 12;
   */
  studentCurrentSet: number;
  /**
   * @generated from protobuf field: int32 studentMaxSet = 13;
   */
  studentMaxSet: number;
  /**
   * @generated from protobuf field: string questionSelectedPot = 14;
   */
  questionSelectedPot: string;
  /**
   * The kind of question being asked,
   * e.g. "missing phoneme" or "word for picture"
   *
   * @generated from protobuf field: string questionType = 15;
   */
  questionType: string;
  /**
   * in Word for Picture game, the file name for the picture selected
   *
   * @generated from protobuf field: string chosenPictureFile = 16;
   */
  chosenPictureFile: string;
  /**
   * in Word for Picture game, the word for the picture selected
   *
   * @generated from protobuf field: string chosenPictureWord = 17;
   */
  chosenPictureWord: string;
  /**
   * in Word for Picture game, the file name for the correct answer
   *
   * @generated from protobuf field: string targetPictureFile = 18;
   */
  targetPictureFile: string;
}
/**
 * @generated from protobuf message sparx.games.progress.phonics.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * Saved progress in the Missing Phoneme exercise
   *
   * @generated from protobuf field: sparx.games.progress.phonics.v1.MissingPhonemeStore missingPhoneme = 2;
   */
  missingPhoneme?: MissingPhonemeStore;
  /**
   * Saved progress in the "choose the word that matches this picture" exercise
   *
   * @generated from protobuf field: sparx.games.progress.phonics.v1.WordForPictureStore wordForPicture = 3;
   */
  wordForPicture?: WordForPictureStore;
}
/**
 * @generated from protobuf message sparx.games.progress.phonics.v1.WordForPictureStore
 */
export interface WordForPictureStore {
  /**
   * For each grapheme/phoneme pair, store how the student is doing
   * keys are of the style 'grapheme/phoneme', e.g. the 'oo' in food
   * has key 'oo/oo_long'
   *
   * @generated from protobuf field: map<string, sparx.games.progress.phonics.v1.PhonemeProgress> progressPerPair = 1;
   */
  progressPerPair: {
    [key: string]: PhonemeProgress;
  };
  /**
   * What step of the algorithm we are in. Used to tell when questions
   * should be shown by the spaced repetition algorithm
   *
   * @generated from protobuf field: uint32 questionIndex = 2;
   */
  questionIndex: number;
  /**
   * @generated from protobuf field: sparx.games.progress.phonics.v1.AcclimatisationPhase acclimatisationPhase = 3;
   */
  acclimatisationPhase: AcclimatisationPhase;
  /**
   * @generated from protobuf field: uint32 startingSet = 4;
   */
  startingSet: number;
  /**
   * An identifier for the last-used version of the algorithm by the user.
   * Use this to check if progress is from an older version and, if it is, adjust the
   * data accordingly (e.g. by resetting progress)
   *
   * @generated from protobuf field: string algorithmVersion = 5;
   */
  algorithmVersion: string;
  /**
   * What stage of Find Your Level phase you are in
   * Questions will be taken from this set.
   *
   * @generated from protobuf field: uint32 findYourLevelSet = 6;
   */
  findYourLevelSet: number;
}
/**
 * @generated from protobuf message sparx.games.progress.phonics.v1.MissingPhonemeStore
 */
export interface MissingPhonemeStore {
  /**
   * For each grapheme/phoneme pair, store how the student is doing
   * keys are of the style 'grapheme/phoneme', e.g. the 'oo' in food
   * has key 'oo/oo_long'
   *
   * @generated from protobuf field: map<string, sparx.games.progress.phonics.v1.PhonemeProgress> progressPerPair = 1;
   */
  progressPerPair: {
    [key: string]: PhonemeProgress;
  };
  /**
   * What step of the algorithm we are in. Used to tell when questions
   * should be shown by the spaced repetition algorithm
   *
   * @generated from protobuf field: uint32 questionIndex = 2;
   */
  questionIndex: number;
  /**
   * @generated from protobuf field: sparx.games.progress.phonics.v1.AcclimatisationPhase acclimatisationPhase = 3;
   */
  acclimatisationPhase: AcclimatisationPhase;
  /**
   * @generated from protobuf field: uint32 startingSet = 4;
   */
  startingSet: number;
  /**
   * An identifier for the last-used version of the algorithm by the user.
   * Use this to check if progress is from an older version and, if it is, adjust the
   * data accordingly (e.g. by resetting progress)
   *
   * @generated from protobuf field: string algorithmVersion = 5;
   */
  algorithmVersion: string;
  /**
   * What stage of Find Your Level phase you are in
   * Questions will be taken from this set.
   *
   * @generated from protobuf field: uint32 findYourLevelSet = 6;
   */
  findYourLevelSet: number;
}
/**
 * @generated from protobuf message sparx.games.progress.phonics.v1.PhonemeProgress
 */
export interface PhonemeProgress {
  /**
   * Equivalent to pots in potted delivery - why this question should be asked
   *
   * @generated from protobuf field: sparx.games.progress.phonics.v1.AlgorithmPhase currentPhase = 2;
   */
  currentPhase: AlgorithmPhase;
  /**
   * Details of the most recent 10 answers
   *
   * @generated from protobuf field: repeated sparx.games.progress.phonics.v1.AnswerHistoryRecord history = 3;
   */
  history: AnswerHistoryRecord[];
  /**
   * When this question should next be asked if in one of the
   * spaced-repetition phases
   *
   * @generated from protobuf field: uint32 timeToAskNext = 4;
   */
  timeToAskNext: number;
}
/**
 * @generated from protobuf message sparx.games.progress.phonics.v1.AnswerHistoryRecord
 */
export interface AnswerHistoryRecord {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: bool wasCorrect = 2;
   */
  wasCorrect: boolean;
  /**
   * Why the question was asked (i.e. which pot it was lifted from)
   *
   * @generated from protobuf field: sparx.games.progress.phonics.v1.AlgorithmPhase phase = 3;
   */
  phase: AlgorithmPhase;
  /**
   * The word that was used in the question
   *
   * @generated from protobuf field: string word = 4;
   */
  word: string;
}
/**
 * @generated from protobuf enum sparx.games.progress.phonics.v1.AcclimatisationPhase
 */
export enum AcclimatisationPhase {
  /**
   * @generated from protobuf enum value: UNUSED = 0;
   */
  UNUSED = 0,
  /**
   * @generated from protobuf enum value: INTRODUCTION = 1;
   */
  INTRODUCTION = 1,
  /**
   * @generated from protobuf enum value: EXPLORATION = 2;
   */
  EXPLORATION = 2,
  /**
   * @generated from protobuf enum value: ACTIVE = 3;
   */
  ACTIVE = 3,
}
/**
 * What stage of learning a phoneme is in
 *
 * @generated from protobuf enum sparx.games.progress.phonics.v1.AlgorithmPhase
 */
export enum AlgorithmPhase {
  /**
   * It has not been asked yet
   *
   * @generated from protobuf enum value: UNASKED = 0;
   */
  UNASKED = 0,
  /**
   * It should be asked in a spaced-repetition process without similar-looking
   * phonemes showing at all
   * (happens if the student has got it wrong)
   *
   * @generated from protobuf enum value: ASK_IN_ISOLATION = 1;
   */
  ASK_IN_ISOLATION = 1,
  /**
   * It should be asked in a spaced-repetition process while interspersed with
   * similar-looking phonemes if they exist
   * (happens if the student has graduated it out of ASK_IN_ISOLATION)
   *
   * @generated from protobuf enum value: MIX_WITH_SIMILAR = 2;
   */
  MIX_WITH_SIMILAR = 2,
  /**
   * We are confident the student knows it
   *
   * @generated from protobuf enum value: LEARNT = 3;
   */
  LEARNT = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class PhonicsAnswerAction$Type extends MessageType<PhonicsAnswerAction> {
  constructor() {
    super('sparx.games.progress.phonics.v1.PhonicsAnswerAction', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PhonicsAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.phonics.v1.PhonicsAnswerAction
 */
export const PhonicsAnswerAction = new PhonicsAnswerAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhonicsAnswer$Type extends MessageType<PhonicsAnswer> {
  constructor() {
    super('sparx.games.progress.phonics.v1.PhonicsAnswer', [
      { no: 1, name: 'answerTime', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'questionId', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'answerGrapheme',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'chosenGrapheme',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 6,
        name: 'orderedEvents',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'targetWord', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'answerSpelling',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: 'distractors',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 10, name: 'timeTaken', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 11,
        name: 'questionSourcedFromSet',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 12,
        name: 'studentCurrentSet',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 13,
        name: 'studentMaxSet',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 14,
        name: 'questionSelectedPot',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 15,
        name: 'questionType',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 16,
        name: 'chosenPictureFile',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 17,
        name: 'chosenPictureWord',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 18,
        name: 'targetPictureFile',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.phonics.v1.PhonicsAnswer
 */
export const PhonicsAnswer = new PhonicsAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.progress.phonics.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'missingPhoneme',
        kind: 'message',
        T: () => MissingPhonemeStore,
      },
      {
        no: 3,
        name: 'wordForPicture',
        kind: 'message',
        T: () => WordForPictureStore,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.phonics.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WordForPictureStore$Type extends MessageType<WordForPictureStore> {
  constructor() {
    super('sparx.games.progress.phonics.v1.WordForPictureStore', [
      {
        no: 1,
        name: 'progressPerPair',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => PhonemeProgress },
      },
      {
        no: 2,
        name: 'questionIndex',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 3,
        name: 'acclimatisationPhase',
        kind: 'enum',
        T: () => [
          'sparx.games.progress.phonics.v1.AcclimatisationPhase',
          AcclimatisationPhase,
        ],
      },
      {
        no: 4,
        name: 'startingSet',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 5,
        name: 'algorithmVersion',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'findYourLevelSet',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.phonics.v1.WordForPictureStore
 */
export const WordForPictureStore = new WordForPictureStore$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MissingPhonemeStore$Type extends MessageType<MissingPhonemeStore> {
  constructor() {
    super('sparx.games.progress.phonics.v1.MissingPhonemeStore', [
      {
        no: 1,
        name: 'progressPerPair',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => PhonemeProgress },
      },
      {
        no: 2,
        name: 'questionIndex',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 3,
        name: 'acclimatisationPhase',
        kind: 'enum',
        T: () => [
          'sparx.games.progress.phonics.v1.AcclimatisationPhase',
          AcclimatisationPhase,
        ],
      },
      {
        no: 4,
        name: 'startingSet',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 5,
        name: 'algorithmVersion',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'findYourLevelSet',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.phonics.v1.MissingPhonemeStore
 */
export const MissingPhonemeStore = new MissingPhonemeStore$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhonemeProgress$Type extends MessageType<PhonemeProgress> {
  constructor() {
    super('sparx.games.progress.phonics.v1.PhonemeProgress', [
      {
        no: 2,
        name: 'currentPhase',
        kind: 'enum',
        T: () => [
          'sparx.games.progress.phonics.v1.AlgorithmPhase',
          AlgorithmPhase,
        ],
      },
      {
        no: 3,
        name: 'history',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AnswerHistoryRecord,
      },
      {
        no: 4,
        name: 'timeToAskNext',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.phonics.v1.PhonemeProgress
 */
export const PhonemeProgress = new PhonemeProgress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnswerHistoryRecord$Type extends MessageType<AnswerHistoryRecord> {
  constructor() {
    super('sparx.games.progress.phonics.v1.AnswerHistoryRecord', [
      { no: 1, name: 'timestamp', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'wasCorrect', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'phase',
        kind: 'enum',
        T: () => [
          'sparx.games.progress.phonics.v1.AlgorithmPhase',
          AlgorithmPhase,
        ],
      },
      { no: 4, name: 'word', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.phonics.v1.AnswerHistoryRecord
 */
export const AnswerHistoryRecord = new AnswerHistoryRecord$Type();

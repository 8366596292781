import { UserInteractionEvent } from '@sparx/api/genproto/apis/uievents/uievents';

const clientEventsMaxLocal = 50;

// makeLocalStorageKey returns a string to be used to store events in local storage for the given app and pump
// these are app specific so that a web browser logging into multiple apps will not send confused analytics
export const makeLocalStorageKey = (application: string, pump: string) =>
  `${application}/client_events/${pump}`;

// makeQueryKey returns a key to be used as a queryKey for the given pump
export const makeQueryKey = (pump: string) => `eventpump/${pump}`;

export const loadClientEvents = (key: string): UserInteractionEvent[] => {
  const store = localStorage.getItem(key);
  let events = [];
  try {
    const parsed = JSON.parse(store || '[]');
    events = parsed || [];
    if (events.length > clientEventsMaxLocal) {
      throw new Error('local storage events exceeded maximum, discarding');
    }
  } catch (e) {
    localStorage.removeItem(key);
  }
  return events;
};

export const setClientEvents = (key: string, events: UserInteractionEvent[]) => {
  try {
    localStorage.setItem(key, JSON.stringify(events));
  } catch (e) {
    console.error('Failed to store client events', e);
  }
};

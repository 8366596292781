import { useQueries, useQuery } from '@tanstack/react-query';

import { useAssessmentContext } from '../context';
import {
  ListTopicSummariesRequest,
  ListTopicSummariesResponse,
  TopicSummary,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { TopicSummariesClient } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum.client';

export const useListCurriculumSummaries = () => {
  const { curriculumClient } = useAssessmentContext();

  return useQuery({
    queryKey: ['curriculum-summaries'],
    queryFn: async () =>
      curriculumClient.listCurriculumSummaries({
        includeHidden: false,
        subjectName: '',
      }).response,
  });
};

/**
 * useTopicSummariesMap returns a map of topic summaries for every curriculum.
 * The loading/error states are handled by suspense.
 */
export const useTopicSummariesMap = () => {
  const curriculumSummaries = useListCurriculumSummaries();
  const curriculumNames =
    curriculumSummaries.data?.curriculumSummaries.map(cs => cs.curriculum!.name) ?? [];
  return useTopicSummariesMapForCurriculums(curriculumNames);
};

const useTopicSummariesMapForCurriculums = (curriculumNames: string[]) => {
  const { topicClient } = useAssessmentContext();
  const topicSummariesQueries = curriculumNames.map(curriculumName =>
    createTopicSummariesQuery(topicClient, curriculumName),
  );
  const queries = useQueries({
    queries: topicSummariesQueries,
  });

  const merged = new Map<string, TopicSummary>();
  queries.forEach(query => {
    if (query.data) {
      query.data.forEach((v, k) => {
        merged.set(k, v);
      });
    }
  });
  return merged;
};

const createTopicSummariesQuery = (
  topicSummariesClient: TopicSummariesClient,
  topicParent: string,
) => {
  return {
    queryKey: ['topicSummaries', topicParent],
    queryFn: () =>
      topicSummariesClient.listTopicSummaries(
        ListTopicSummariesRequest.create({
          topicParent: topicParent,
          options: {
            includeLearningPaths: true,
            omitKeyQuestions: true,
            omitTopicLinks: false,
            includeAllQuestions: false,
            includeQuestionLayoutJson: false,
          },
        }),
      ).response,
    select: (data: ListTopicSummariesResponse) =>
      data.topicSummaries.reduce((m, v) => {
        if (v.topic) {
          m.set(v.topic.name, v);
        }
        return m;
      }, new Map<string, TopicSummary>()),

    // Topics rarely change, so we cache them forever.
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: true,
  };
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/rhythmgame/v1/rhythmgame.proto" (package "sparx.games.state.rhythmgame.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { BoolValue } from '../../../../../../google/protobuf/wrappers';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
//
// RhythmGame does not do any processing on the cloud, so the messages
// below are only here because they are expected by game.go

/**
 * @generated from protobuf message sparx.games.state.rhythmgame.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * I don't use these ones anymore. Can I delete them?
   *
   * @generated from protobuf field: repeated string unlockedLevelIds = 2;
   */
  unlockedLevelIds: string[];
  /**
   * @generated from protobuf field: repeated string completedLevelIds = 3;
   */
  completedLevelIds: string[];
  /**
   * Formerly for each song ID, gave the hardest level that had been unlocked
   *
   * @deprecated
   * @generated from protobuf field: map<string, uint32> unlockedDifficultyPerSong = 4 [deprecated = true];
   */
  unlockedDifficultyPerSong: {
    [key: string]: number;
  };
  /**
   * Formerly for each song ID, gave the hardest level that had been completed
   *
   * @deprecated
   * @generated from protobuf field: map<string, uint32> completedDifficultyPerSong = 5 [deprecated = true];
   */
  completedDifficultyPerSong: {
    [key: string]: number;
  };
  /**
   * Formerly a list of all songs which had been unlocked
   *
   * @deprecated
   * @generated from protobuf field: repeated string unlockedSongs = 6 [deprecated = true];
   */
  unlockedSongs: string[];
  /**
   * Set to true when the player completes the tutorial
   *
   * @generated from protobuf field: google.protobuf.BoolValue hasCompletedTutorial = 7;
   */
  hasCompletedTutorial?: BoolValue;
  /**
   * For each song ID, gives a number from 0 to 1 to represent how hard it
   * should be (0 = easiest, 1 = hardest)
   *
   * @generated from protobuf field: map<string, float> dynamicDifficultyPerSong = 8;
   */
  dynamicDifficultyPerSong: {
    [key: string]: number;
  };
  /**
   * For each song ID, list the results of the previous phrases attempted
   * in this song. This is used to calculate how much the difficulty should
   * increase after a success.
   *
   * @generated from protobuf field: map<string, sparx.games.state.rhythmgame.v1.DifficultyHistory> difficultyHistoryPerSong = 9;
   */
  difficultyHistoryPerSong: {
    [key: string]: DifficultyHistory;
  };
  /**
   * A list of all songs which have been completed all the way through
   * (i.e. a run of all 5 phases was done in one sitting)
   *
   * @generated from protobuf field: repeated string completedSongs = 10;
   */
  completedSongs: string[];
  /**
   * how many times they have seen an "incorrect!" hint
   * the more times they see hints the longer the hints
   * take to play out and the less overt they become
   *
   * @generated from protobuf field: uint32 incorrectHintIndex = 11;
   */
  incorrectHintIndex: number;
  /**
   * how many times they have done a block and learn
   * the more times they have done BaL:
   *  - the more distractors they will see
   *  - the fewer blocks will be pre-placed for them
   *
   * @generated from protobuf field: uint32 completedBlockAndLearnCount = 12;
   */
  completedBlockAndLearnCount: number;
}
/**
 * @generated from protobuf message sparx.games.state.rhythmgame.v1.DifficultyHistory
 */
export interface DifficultyHistory {
  /**
   * most recent phrase will be at the end of the list
   * the list will have no more than 10 elements
   *
   * @generated from protobuf field: repeated sparx.games.state.rhythmgame.v1.DifficultyHistoryState orderedStates = 1;
   */
  orderedStates: DifficultyHistoryState[];
}
/**
 * @generated from protobuf message sparx.games.state.rhythmgame.v1.DifficultyHistoryState
 */
export interface DifficultyHistoryState {
  /**
   * How hard the song was during this phrase
   *
   * @generated from protobuf field: float difficulty = 1;
   */
  difficulty: number;
  /**
   * Whether the student succeeded the phrase
   * (success is hitting at least n-1 of the n cells spawned)
   *
   * @generated from protobuf field: bool success = 2;
   */
  success: boolean;
}
/**
 * @generated from protobuf message sparx.games.state.rhythmgame.v1.State
 */
export interface State {}
/**
 * @generated from protobuf message sparx.games.state.rhythmgame.v1.Init
 */
export interface Init {}
/**
 *
 * The only action fired by GridPathGame is a "quitGame" action, called when
 * the user presses an in-game "quit" button
 *
 * @generated from protobuf message sparx.games.state.rhythmgame.v1.Action
 */
export interface Action {
  /**
   * the values that can be sent with an action
   *
   * @generated from protobuf field: sparx.games.state.rhythmgame.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * the names of actions that can be sent
 *
 * @generated from protobuf enum sparx.games.state.rhythmgame.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * @generated from protobuf enum value: quitGame = 0;
   */
  quitGame = 0,
}
/**
 * @generated from protobuf message sparx.games.state.rhythmgame.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.rhythmgame.v1.Params
 */
export interface Params {}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.rhythmgame.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'unlockedLevelIds',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'completedLevelIds',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'unlockedDifficultyPerSong',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      },
      {
        no: 5,
        name: 'completedDifficultyPerSong',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      },
      {
        no: 6,
        name: 'unlockedSongs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'hasCompletedTutorial',
        kind: 'message',
        T: () => BoolValue,
      },
      {
        no: 8,
        name: 'dynamicDifficultyPerSong',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      },
      {
        no: 9,
        name: 'difficultyHistoryPerSong',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => DifficultyHistory },
      },
      {
        no: 10,
        name: 'completedSongs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 11,
        name: 'incorrectHintIndex',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 12,
        name: 'completedBlockAndLearnCount',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.rhythmgame.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DifficultyHistory$Type extends MessageType<DifficultyHistory> {
  constructor() {
    super('sparx.games.state.rhythmgame.v1.DifficultyHistory', [
      {
        no: 1,
        name: 'orderedStates',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => DifficultyHistoryState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.rhythmgame.v1.DifficultyHistory
 */
export const DifficultyHistory = new DifficultyHistory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DifficultyHistoryState$Type extends MessageType<DifficultyHistoryState> {
  constructor() {
    super('sparx.games.state.rhythmgame.v1.DifficultyHistoryState', [
      { no: 1, name: 'difficulty', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 2, name: 'success', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.rhythmgame.v1.DifficultyHistoryState
 */
export const DifficultyHistoryState = new DifficultyHistoryState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.rhythmgame.v1.State', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.rhythmgame.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.rhythmgame.v1.Init', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.rhythmgame.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.rhythmgame.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.rhythmgame.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.rhythmgame.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.rhythmgame.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.rhythmgame.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.rhythmgame.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.rhythmgame.v1.Params
 */
export const Params = new Params$Type();

import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  ListStudentsRequest,
  ListStudentsResponse,
  Student,
} from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { studentClient } from 'api/clients';
import { useSchool } from 'components/ensuresession/EnsureSession';

export const useStudents = (opts?: UseQueryOptions<ListStudentsResponse, RpcError, Student[]>) => {
  const { schoolId } = useSchool();
  return useQuery({
    queryKey: ['students'],
    queryFn: () =>
      studentClient.listStudents(
        ListStudentsRequest.create({
          schoolId: schoolId,
        }),
      ).response,
    select: data => data.students,
    ...opts,
  });
};

import classNames from 'classnames';
import { ComponentProps, forwardRef, useContext } from 'react';

import { FormControlContext } from '../form/FormControlContext';
import styles from './Input.module.css';

interface InputProps extends ComponentProps<'input'> {
  isInvalid?: boolean;
}

/**
 * Input is a styles input element that is used to get user input from a text field.
 *
 * When used within a `<FormControl/>` component, the input will be styled based on the
 * `isInvalid` context from the parent.
 *
 * @example
 * <Input type="text" value={value} onChange={e => setValue(e.target.value) }/>
 *
 * @example
 * <FormControl isInvalid={!!errors.emailAddress}>
 *   <FormLabel>Email address:</FormLabel>
 *   <Input type="email" />
 * </FormControl>
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  const { className, disabled, readOnly, id, isInvalid, ...rest } = props;
  const formControlContext = useContext(FormControlContext);

  return (
    <input
      id={id || formControlContext?.fieldId}
      ref={ref}
      disabled={disabled}
      readOnly={readOnly}
      className={classNames(
        styles.Input,
        {
          [styles.Disabled]: disabled,
          [styles.Readonly]: readOnly,
          [styles.Invalid]: isInvalid || formControlContext?.isInvalid,
        },
        className,
      )}
      {...rest}
    />
  );
});

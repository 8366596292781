// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/store/v1/store.proto" (package "sparx.games.store.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Store as Store$18 } from '../../progress/specialassessment/v1/specialassessment';
import { Store as Store$17 } from '../../progress/phonics/v1/phonics';
import { Store as Store$16 } from '../../state/rhythmgame/v1/rhythmgame';
import { Store as Store$15 } from '../../state/gridpathgame/v1/gridpathgame';
import { Store as Store$14 } from '../../state/marblesmasher/v1/marblesmasher';
import { Store as Store$13 } from '../../progress/abilityvalidation/v1/abilityvalidation';
import { Store as Store$12 } from '../../progress/specialquestions/v1/specialquestions';
import { Store as Store$11 } from '../../progress/talkandlearncontrol/v1/talkandlearncontrol';
import { Store as Store$10 } from '../../state/gardengame/v1/gardengame';
import { Store as Store$9 } from '../../state/leaguetablegame/v1/leaguetablegame';
import { Store as Store$8 } from '../../state/findittables/v1/findittables';
import { Store as Store$7 } from '../../progress/dailytokens/v1/dailytokens';
import { Store as Store$6 } from '../../state/stickercollector/v1/stickercollector';
import { Store as Store$5 } from '../../state/gemsmash/v1/gemsmash';
import { Store as Store$4 } from '../../state/hundredclub/v1/hundredclub';
import { Store as Store$3 } from '../../state/pictureshuffle/v1/pictureshuffle';
import { Store as Store$2 } from '../../progress/potteddelivery/v1/potteddelivery';
import { Store as Store$ } from '../../progress/targets/v1/targets';
/**
 * Store is the definition of a user's game progress as it is kept in the store.
 *
 * This is a single object which contains a data point for:
 * - each game
 * - targets
 * - progress in the potted delivery algorithm
 *
 * @generated from protobuf message sparx.games.store.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: sparx.games.progress.targets.v1.Store Targets = 1 [json_name = "Targets"];
   */
  targets?: Store$;
  /**
   * @generated from protobuf field: sparx.games.progress.potteddelivery.v1.Store PottedDelivery = 2 [json_name = "PottedDelivery"];
   */
  pottedDelivery?: Store$2;
  /**
   * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.Store PictureShuffle = 3 [json_name = "PictureShuffle"];
   */
  pictureShuffle?: Store$3;
  /**
   * @generated from protobuf field: sparx.games.state.hundredclub.v1.Store HundredClub = 4 [json_name = "HundredClub"];
   */
  hundredClub?: Store$4;
  /**
   * @generated from protobuf field: sparx.games.state.gemsmash.v1.Store GemSmash = 5 [json_name = "GemSmash"];
   */
  gemSmash?: Store$5;
  /**
   * @generated from protobuf field: sparx.games.state.stickercollector.v1.Store StickerCollector = 6 [json_name = "StickerCollector"];
   */
  stickerCollector?: Store$6;
  /**
   * @generated from protobuf field: sparx.games.progress.dailytokens.v1.Store DailyTokens = 7 [json_name = "DailyTokens"];
   */
  dailyTokens?: Store$7;
  /**
   * @generated from protobuf field: sparx.games.state.findittables.v1.Store FindItTables = 8 [json_name = "FindItTables"];
   */
  findItTables?: Store$8;
  /**
   * @generated from protobuf field: sparx.games.state.leaguetablegame.v1.Store LeagueTableGame = 9 [json_name = "LeagueTableGame"];
   */
  leagueTableGame?: Store$9;
  /**
   * @generated from protobuf field: sparx.games.state.gardengame.v1.Store GardenGame = 10 [json_name = "GardenGame"];
   */
  gardenGame?: Store$10;
  /**
   * @generated from protobuf field: sparx.games.progress.talkandlearncontrol.v1.Store TalkAndLearnControl = 11 [json_name = "TalkAndLearnControl"];
   */
  talkAndLearnControl?: Store$11;
  /**
   * @generated from protobuf field: sparx.games.progress.specialquestions.v1.Store SpecialQuestions = 12 [json_name = "SpecialQuestions"];
   */
  specialQuestions?: Store$12;
  /**
   * @generated from protobuf field: sparx.games.progress.abilityvalidation.v1.Store AbilityValidation = 13 [json_name = "AbilityValidation"];
   */
  abilityValidation?: Store$13;
  /**
   * @generated from protobuf field: sparx.games.state.marblesmasher.v1.Store MarbleSmasher = 14 [json_name = "MarbleSmasher"];
   */
  marbleSmasher?: Store$14;
  /**
   * @generated from protobuf field: sparx.games.state.gridpathgame.v1.Store GridPathGame = 15 [json_name = "GridPathGame"];
   */
  gridPathGame?: Store$15;
  /**
   * @generated from protobuf field: sparx.games.state.rhythmgame.v1.Store RhythmGame = 16 [json_name = "RhythmGame"];
   */
  rhythmGame?: Store$16;
  /**
   * @generated from protobuf field: sparx.games.progress.phonics.v1.Store Phonics = 17 [json_name = "Phonics"];
   */
  phonics?: Store$17;
  /**
   * @generated from protobuf field: sparx.games.progress.specialassessment.v1.Store SpecialAssessment = 18 [json_name = "SpecialAssessment"];
   */
  specialAssessment?: Store$18;
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.store.v1.Store', [
      {
        no: 1,
        name: 'Targets',
        kind: 'message',
        jsonName: 'Targets',
        T: () => Store$,
      },
      {
        no: 2,
        name: 'PottedDelivery',
        kind: 'message',
        jsonName: 'PottedDelivery',
        T: () => Store$2,
      },
      {
        no: 3,
        name: 'PictureShuffle',
        kind: 'message',
        jsonName: 'PictureShuffle',
        T: () => Store$3,
      },
      {
        no: 4,
        name: 'HundredClub',
        kind: 'message',
        jsonName: 'HundredClub',
        T: () => Store$4,
      },
      {
        no: 5,
        name: 'GemSmash',
        kind: 'message',
        jsonName: 'GemSmash',
        T: () => Store$5,
      },
      {
        no: 6,
        name: 'StickerCollector',
        kind: 'message',
        jsonName: 'StickerCollector',
        T: () => Store$6,
      },
      {
        no: 7,
        name: 'DailyTokens',
        kind: 'message',
        jsonName: 'DailyTokens',
        T: () => Store$7,
      },
      {
        no: 8,
        name: 'FindItTables',
        kind: 'message',
        jsonName: 'FindItTables',
        T: () => Store$8,
      },
      {
        no: 9,
        name: 'LeagueTableGame',
        kind: 'message',
        jsonName: 'LeagueTableGame',
        T: () => Store$9,
      },
      {
        no: 10,
        name: 'GardenGame',
        kind: 'message',
        jsonName: 'GardenGame',
        T: () => Store$10,
      },
      {
        no: 11,
        name: 'TalkAndLearnControl',
        kind: 'message',
        jsonName: 'TalkAndLearnControl',
        T: () => Store$11,
      },
      {
        no: 12,
        name: 'SpecialQuestions',
        kind: 'message',
        jsonName: 'SpecialQuestions',
        T: () => Store$12,
      },
      {
        no: 13,
        name: 'AbilityValidation',
        kind: 'message',
        jsonName: 'AbilityValidation',
        T: () => Store$13,
      },
      {
        no: 14,
        name: 'MarbleSmasher',
        kind: 'message',
        jsonName: 'MarbleSmasher',
        T: () => Store$14,
      },
      {
        no: 15,
        name: 'GridPathGame',
        kind: 'message',
        jsonName: 'GridPathGame',
        T: () => Store$15,
      },
      {
        no: 16,
        name: 'RhythmGame',
        kind: 'message',
        jsonName: 'RhythmGame',
        T: () => Store$16,
      },
      {
        no: 17,
        name: 'Phonics',
        kind: 'message',
        jsonName: 'Phonics',
        T: () => Store$17,
      },
      {
        no: 18,
        name: 'SpecialAssessment',
        kind: 'message',
        jsonName: 'SpecialAssessment',
        T: () => Store$18,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.store.v1.Store
 */
export const Store = new Store$Type();

import { GetCurrentSessionResponse } from '@sparx/api/apis/sparx/assessment/app/v1/authentication';
import { redirectToLogin, useSessionWithAnonymous } from 'api/auth';
import { useSchoolName } from 'api/school';
import { queryClient } from 'app/queryClient';
import { createContext, PropsWithChildren, useContext } from 'react';
import usePromise from 'react-promise-suspense';

export const startSession = async () => {
  await queryClient.invalidateQueries(['session', 'token']);
  return new Promise<void>(() => {});
};

const rootForUserType = (userType: string) => {
  if (userType === 'staff') {
    return '/teacher';
  }
  return '/student';
};

const userTypeFromSubject = (subject: string | undefined) => {
  if (
    subject?.startsWith('staff/') ||
    subject?.startsWith('sparxstaff/') ||
    subject?.startsWith('assessmentsguestteacher/')
  ) {
    return 'staff';
  }
  return 'student';
};

interface EnsureSessionProps {
  requireUnauthenticated?: boolean;
  userType?: 'staff' | 'student';
}

export const EnsureSession = ({
  children,
  requireUnauthenticated = false,
  userType,
}: PropsWithChildren<EnsureSessionProps>) => {
  const { data: sessionSubject } = useSessionWithAnonymous({ suspense: true });

  usePromise(
    async (
      requireUnauthenticated: boolean,
      sessionSubject: GetCurrentSessionResponse | false | undefined,
      userType?: string,
    ) => {
      // Ignore while loading
      if (!sessionSubject === undefined) return;

      if (!sessionSubject && !requireUnauthenticated) {
        await redirectToLogin();
      } else if (sessionSubject && requireUnauthenticated) {
        const subjectUserType = userTypeFromSubject(sessionSubject?.subject);
        window.location.replace(rootForUserType(subjectUserType));
        return new Promise<void>(() => {}); // stop
      } else if (sessionSubject && userType) {
        const subjectUserType = userTypeFromSubject(sessionSubject?.subject);
        if (userType !== subjectUserType) {
          window.location.replace(rootForUserType(subjectUserType));
          return new Promise<void>(() => {}); // stop
        }
      }
    },
    [requireUnauthenticated, sessionSubject, userType],
  );

  return <EnsureSchool>{children}</EnsureSchool>;
};

interface SchoolContext {
  schoolId: string | undefined;
  schoolName: string | undefined;
}

const SchoolContext = createContext<SchoolContext>({
  schoolId: undefined,
  schoolName: undefined,
});

export const useSchool = () => useContext(SchoolContext);

const EnsureSchool = ({ children }: PropsWithChildren) => {
  const { data } = useSchoolName();
  return (
    <SchoolContext.Provider value={data || { schoolId: undefined, schoolName: undefined }}>
      {children}
    </SchoolContext.Provider>
  );
};

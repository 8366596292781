// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/marblesmasher/v1/marblesmasher.proto" (package "sparx.games.state.marblesmasher.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { UInt32Value } from '../../../../../../google/protobuf/wrappers';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
//
// MarbleSmasher does not do any processing on the cloud, so the messages
// below are only here because they are expected by game.go

/**
 * @generated from protobuf message sparx.games.state.marblesmasher.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value levelIndex = 2;
   */
  levelIndex?: UInt32Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value gameTimeRemaining = 3;
   */
  gameTimeRemaining?: UInt32Value;
  /**
   * @generated from protobuf field: uint32 quizPhasesCompleted = 4;
   */
  quizPhasesCompleted: number;
  /**
   * Each tutorial completed by a user is stored as an ID string.
   * For example, a user who has completed the "how to match marbles" and
   * "how to smash the star" tutorials would have the array
   * ["howToMatchMarbles", "howToSmashTheStar"]
   *
   * @generated from protobuf field: repeated string tutorialsCompleted = 5;
   */
  tutorialsCompleted: string[];
}
/**
 * @generated from protobuf message sparx.games.state.marblesmasher.v1.State
 */
export interface State {}
/**
 * @generated from protobuf message sparx.games.state.marblesmasher.v1.Init
 */
export interface Init {}
/**
 *
 * The only action fired by MarbleSmasher is a "quitGame" action, called when
 * the user presses an in-game "quit" button
 *
 * @generated from protobuf message sparx.games.state.marblesmasher.v1.Action
 */
export interface Action {
  /**
   * the values that can be sent with an action
   *
   * @generated from protobuf field: sparx.games.state.marblesmasher.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * the names of actions that can be sent
 *
 * @generated from protobuf enum sparx.games.state.marblesmasher.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * @generated from protobuf enum value: quitGame = 0;
   */
  quitGame = 0,
}
/**
 * @generated from protobuf message sparx.games.state.marblesmasher.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.marblesmasher.v1.Params
 */
export interface Params {}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.marblesmasher.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 2, name: 'levelIndex', kind: 'message', T: () => UInt32Value },
      {
        no: 3,
        name: 'gameTimeRemaining',
        kind: 'message',
        T: () => UInt32Value,
      },
      {
        no: 4,
        name: 'quizPhasesCompleted',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 5,
        name: 'tutorialsCompleted',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.marblesmasher.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.marblesmasher.v1.State', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.marblesmasher.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.marblesmasher.v1.Init', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.marblesmasher.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.marblesmasher.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.marblesmasher.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.marblesmasher.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.marblesmasher.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.marblesmasher.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.marblesmasher.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.marblesmasher.v1.Params
 */
export const Params = new Params$Type();

import { Hint } from '@sparx/api/sparxweb/swmsg/sparxweb';
import { Button } from '@sparx/sparx-design/components';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

import { useSparxQuestionContext } from '../../../question/SparxQuestionContext';
import { FractionHintInfo, HintInfo } from '../types';
import styles from './FractionsHints.module.css';

export const HintArrow = ({ hint }: { hint: FractionHintInfo | undefined }) => {
  const [shouldShow, setShouldShow] = useState(!hint?.partHasInput);
  // once we have input in the part, we should hide the arrow
  useEffect(() => {
    if (hint?.partHasInput) {
      setShouldShow(false);
    }
  }, [hint?.partHasInput]);
  return (
    <>
      {hint && (
        <div
          className={classNames({ [styles.HintArrow]: true, [styles.Hidden]: !shouldShow })}
          role="presentation"
        >
          👉
        </div>
      )}
    </>
  );
};

export const ResetButton = ({ hint }: { hint: FractionHintInfo | undefined }) => {
  const { setOpenElementRef } = useSparxQuestionContext();
  return (
    <>
      {hint && (
        <div
          className={classNames({
            [styles.ResetButtonContainer]: true,
            [styles.Hidden]: !hint.partHasInput,
          })}
          data-reset-button
          role="presentation"
        >
          <Button
            onClick={() => {
              hint.onReset();
              setOpenElementRef('');
            }}
            variant="text"
            className={styles.ResetButton}
          >
            reset
          </Button>
        </div>
      )}
    </>
  );
};

export const HintWrapper = ({
  hintInfo,
  children,
}: {
  hintInfo: HintInfo | undefined;
  children: ReactNode;
}) => {
  const { openHintVideo } = useSparxQuestionContext();
  const videoId = getVideoForHint(hintInfo?.hintType);
  let hintElement: ReactNode = undefined;
  switch (hintInfo?.hintType) {
    case Hint.SIMPLIFY_FRACTION:
      hintElement = (
        <motion.div
          className={styles.HintContainer}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className={styles.Hint}>
            <b>Nearly! Fully simplify your answer</b> to make this question correct.{' '}
            {openHintVideo && videoId && (
              <button
                className={classNames(styles.VideoLink, accessibilityStyles.FocusTarget)}
                onClick={() =>
                  openHintVideo({ videoId: videoId, hintType: Hint.SIMPLIFY_FRACTION })
                }
              >
                Show me how to simplify fractions
              </button>
            )}
          </div>
        </motion.div>
      );
      break;
    default:
    // do nothing
  }
  return (
    <>
      <AnimatePresence>{hintElement}</AnimatePresence>
      {children}
    </>
  );
};

const getVideoForHint = (hintType: Hint | undefined) => {
  switch (hintType) {
    case Hint.SIMPLIFY_FRACTION:
      return 'ec27a003-1489-4000-9214-e5878eaa1049';
    default:
      return '';
  }
};

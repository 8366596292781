import classNames from 'classnames';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

import styles from './CenteredPage.module.css';

export const CenteredPage = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={styles.Container}>
    <div className={classNames(styles.Content, className)}>{children}</div>
  </div>
);

export const AnimatedCenteredPage = ({ children }: PropsWithChildren) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
    transition={{ type: 'spring', duration: 0.5, bounce: 0 }}
    className={styles.Container}
  >
    <div className={styles.Content}>{children}</div>
  </motion.div>
);

import {
  GetSittingResponse,
  JoinSittingRequest,
} from '@sparx/api/apis/sparx/assessment/app/v1/authentication';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { invalidateSession, logout, useSession } from 'api/auth';
import { Button } from 'components/button/Button';
import { Card } from 'components/card/Card';
import { CenteredPage } from 'components/centeredpage/CenteredPage';
import { startSession } from 'components/ensuresession/EnsureSession';
import { PageHeader } from 'components/pageheader/PageHeader';
import { useJoinSitting } from 'queries/app';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FindSitting } from 'views/landing/joinview/FindSitting';
import styles from 'views/landing/joinview/JoinView.module.css';
import { StudentSittingView } from 'views/student/studentsittingview/StudentSittingView';

export const StudentHomeView = () => {
  const { data: session } = useSession({ suspense: true });

  const { mutateAsync: joinSitting, isLoading } = useJoinSitting({
    onSuccess: () => {
      startSession();
      invalidateSession();
    },
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const querySittingName = searchParams.get('s');
  useEffect(() => {
    if (querySittingName) {
      // Clear search params
      setSearchParams(
        p => {
          p.delete('s');
          return p;
        },
        { replace: true },
      );
      joinSitting({
        sittingName: querySittingName,
        method: {
          oneofKind: 'withToken',
          withToken: true,
        },
      });
    }
  }, [querySittingName, setSearchParams, joinSitting]);

  if (querySittingName) {
    return <LargeLoading />;
  }

  // TODO: assumes first sitting is the current one
  const sittingName = session?.currentSittingNames?.[0];
  if (!sittingName) {
    return <AuthenticatedJoinSitting joinSitting={joinSitting} isLoading={isLoading} />;
  }

  return <StudentSittingView sittingName={sittingName} />;
};

const AuthenticatedJoinSitting = ({
  joinSitting,
  isLoading,
}: {
  joinSitting: (req: JoinSittingRequest) => void;
  isLoading?: boolean;
}) => {
  const doJoinSitting = (sitting: GetSittingResponse) => {
    console.log('Joining sitting', sitting);
    return joinSitting({
      sittingName: sitting.sittingName,
      method: {
        oneofKind: 'withToken',
        withToken: true,
      },
    });
  };

  return (
    <CenteredPage className={styles.Container}>
      <Card className={styles.Card}>
        <PageHeader
          right={
            <Button variant="outline" onClick={() => logout()}>
              Logout
            </Button>
          }
        >
          Join an assessment
        </PageHeader>
        {isLoading ? <LargeLoading /> : <FindSitting onSuccess={doJoinSitting} />}
      </Card>
    </CenteredPage>
  );
};

import { WarningMessage } from '@sparx/sparx-design/components';

const SectionNotAvailable = ({ children }: { children: string }) => (
  <WarningMessage message={children} />
);

export const AssessmentNotAvailable = () => (
  <SectionNotAvailable>
    Data entry and Marksheet views are not available for this assessment.
  </SectionNotAvailable>
);

export const AssessmentComingSoon = () => (
  <SectionNotAvailable>
    Data entry and Marksheet views for this assessment are coming soon.
  </SectionNotAvailable>
);

export const FollowUpNotAvailable = () => (
  <SectionNotAvailable>Follow up is not available for this assessment.</SectionNotAvailable>
);

export const FollowUpComingSoon = () => (
  <SectionNotAvailable>Follow up for this assessment is coming soon.</SectionNotAvailable>
);

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import * as RadixSwitch from '@radix-ui/react-switch';
import classNames from 'classnames';

import styles from './Switch.module.css';

interface ISwitchProps {
  // Optional label for the left side of the switch
  labelLeft?: string;
  // Optional label for the right side of the switch
  labelRight?: string;
  // Whether the switch is checked
  checked: boolean;
  // Function to call when the switch is toggled
  onCheckedChange: (checked: boolean) => void;
  // Unique ID for the component
  id: string;

  icon?: FontAwesomeIconProps['icon'];
}

/**
 * Switch component used to toggle a state on or off
 */
export const Switch = ({
  labelLeft,
  labelRight,
  checked,
  onCheckedChange,
  id,
  icon,
}: ISwitchProps) => {
  // Set aria label based on the labels provided
  let ariaLabel = 'Toggle on or off';
  if (labelLeft && labelRight) {
    ariaLabel = `Toggle between ${labelLeft} and ${labelRight}`;
  } else if (labelRight || labelLeft) {
    ariaLabel = `Toggle ${labelRight || labelLeft} on or off`;
  }

  return (
    <form>
      <div className={styles.Switch}>
        {labelLeft && (
          <label
            className={classNames(styles.Label, {
              [styles.LabelActive]: !checked,
            })}
            htmlFor={id}
          >
            {labelLeft}
          </label>
        )}
        <RadixSwitch.Root
          className={styles.SwitchRoot}
          id={id}
          checked={checked}
          onCheckedChange={onCheckedChange}
          onClick={e => e.stopPropagation()}
          aria-label={ariaLabel}
        >
          <RadixSwitch.Thumb className={styles.SwitchThumb}>
            {icon && <FontAwesomeIcon icon={icon} size="2xs" className={styles.Icon} />}
          </RadixSwitch.Thumb>
        </RadixSwitch.Root>
        {labelRight && (
          <label
            className={classNames(styles.Label, {
              [styles.LabelActive]: checked,
            })}
          >
            {labelRight}
          </label>
        )}
      </div>
    </form>
  );
};

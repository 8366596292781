import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { MatchMethodCell, SittingNameCell } from 'components/matchtable/Cells';
import styles from 'components/matchtable/MatchTable.module.css';
import { ResolveParticipantMatch, SittingLookup } from 'components/matchtable/utils';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { useMemo } from 'react';

export const ExportedResultsTable = ({
  sittings,
  participants,
}: {
  sittings?: SittingLookup;
  participants: ResolveParticipantMatch[];
}) => {
  const rows = useMemo(
    () =>
      participants.sort(
        (a, b) =>
          a.matchedDetails.givenName.localeCompare(b.matchedDetails.givenName) ||
          a.matchedDetails.familyName.localeCompare(b.matchedDetails.familyName),
      ),
    [participants],
  );

  const table = (
    <table className={styles.Table}>
      <thead>
        <tr>
          <th>Group name</th>
          <th>Student</th>
          <th>Match method</th>
          {/*<th>Progress</th>*/}
          <th>Exported</th>
        </tr>
      </thead>
      <tbody>
        {rows.length === 0 && (
          <tr>
            <td colSpan={4} className={styles.NoRows}>
              There are no exported results
            </td>
          </tr>
        )}
        {rows.map(p => {
          const sitting = sittings?.[p.participant.sittingName];

          return (
            <tr key={p.participant.participantSubject}>
              <SittingNameCell sitting={sitting} />
              <td>
                {p.matchedDetails.givenName} {p.matchedDetails.familyName}
              </td>
              <MatchMethodCell method={p.matchMethod} />
              {/*<td></td>*/}
              <td className={classNames(styles.StatusColumn, styles.StatusColumnSuccess)}>
                <FontAwesomeIcon icon={faCheck} />
                <PrettyTimestamp>{p.participant.exportedTimestamp}</PrettyTimestamp>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <>
      <p style={{ marginBottom: 'var(--spx-unit-5)' }}>
        This page displays information on previously exported results for students.
      </p>
      {table}
    </>
  );
};

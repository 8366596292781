// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/assessment/app/v1/authentication.proto" (package "sparx.assessment.app.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Authentication } from './authentication';
import type { AuthenticateGuestTeacherResponse } from './authentication';
import type { AuthenticateGuestTeacherRequest } from './authentication';
import type { JoinSittingResponse } from './authentication';
import type { JoinSittingRequest } from './authentication';
import type { GetSittingResponse } from './authentication';
import type { GetSittingRequest } from './authentication';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetCurrentSessionResponse } from './authentication';
import type { GetCurrentSessionRequest } from './authentication';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.assessment.app.v1.Authentication
 */
export interface IAuthenticationClient {
  /**
   * @generated from protobuf rpc: GetCurrentSession(sparx.assessment.app.v1.GetCurrentSessionRequest) returns (sparx.assessment.app.v1.GetCurrentSessionResponse);
   */
  getCurrentSession(
    input: GetCurrentSessionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCurrentSessionRequest, GetCurrentSessionResponse>;
  /**
   * GetSitting returns the assessment sitting for a join code if one exits.
   *
   * @generated from protobuf rpc: GetSitting(sparx.assessment.app.v1.GetSittingRequest) returns (sparx.assessment.app.v1.GetSittingResponse);
   */
  getSitting(
    input: GetSittingRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSittingRequest, GetSittingResponse>;
  /**
   * JoinSitting joins a student to an assessment sitting.
   *
   * @generated from protobuf rpc: JoinSitting(sparx.assessment.app.v1.JoinSittingRequest) returns (sparx.assessment.app.v1.JoinSittingResponse);
   */
  joinSitting(
    input: JoinSittingRequest,
    options?: RpcOptions,
  ): UnaryCall<JoinSittingRequest, JoinSittingResponse>;
  /**
   * AuthenticateGuestTeacher authenticates a guest teacher.
   *
   * @generated from protobuf rpc: AuthenticateGuestTeacher(sparx.assessment.app.v1.AuthenticateGuestTeacherRequest) returns (sparx.assessment.app.v1.AuthenticateGuestTeacherResponse);
   */
  authenticateGuestTeacher(
    input: AuthenticateGuestTeacherRequest,
    options?: RpcOptions,
  ): UnaryCall<
    AuthenticateGuestTeacherRequest,
    AuthenticateGuestTeacherResponse
  >;
}
/**
 * @generated from protobuf service sparx.assessment.app.v1.Authentication
 */
export class AuthenticationClient
  implements IAuthenticationClient, ServiceInfo
{
  typeName = Authentication.typeName;
  methods = Authentication.methods;
  options = Authentication.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetCurrentSession(sparx.assessment.app.v1.GetCurrentSessionRequest) returns (sparx.assessment.app.v1.GetCurrentSessionResponse);
   */
  getCurrentSession(
    input: GetCurrentSessionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCurrentSessionRequest, GetCurrentSessionResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetCurrentSessionRequest, GetCurrentSessionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetSitting returns the assessment sitting for a join code if one exits.
   *
   * @generated from protobuf rpc: GetSitting(sparx.assessment.app.v1.GetSittingRequest) returns (sparx.assessment.app.v1.GetSittingResponse);
   */
  getSitting(
    input: GetSittingRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSittingRequest, GetSittingResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSittingRequest, GetSittingResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * JoinSitting joins a student to an assessment sitting.
   *
   * @generated from protobuf rpc: JoinSitting(sparx.assessment.app.v1.JoinSittingRequest) returns (sparx.assessment.app.v1.JoinSittingResponse);
   */
  joinSitting(
    input: JoinSittingRequest,
    options?: RpcOptions,
  ): UnaryCall<JoinSittingRequest, JoinSittingResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<JoinSittingRequest, JoinSittingResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * AuthenticateGuestTeacher authenticates a guest teacher.
   *
   * @generated from protobuf rpc: AuthenticateGuestTeacher(sparx.assessment.app.v1.AuthenticateGuestTeacherRequest) returns (sparx.assessment.app.v1.AuthenticateGuestTeacherResponse);
   */
  authenticateGuestTeacher(
    input: AuthenticateGuestTeacherRequest,
    options?: RpcOptions,
  ): UnaryCall<
    AuthenticateGuestTeacherRequest,
    AuthenticateGuestTeacherResponse
  > {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      AuthenticateGuestTeacherRequest,
      AuthenticateGuestTeacherResponse
    >('unary', this._transport, method, opt, input);
  }
}

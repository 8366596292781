import {
  composeSorts,
  ISorting,
  SortDirection,
  SortFnWithDir,
} from '@sparx/assessments/src/utils/sorting';
import { ReadingResultRow } from 'views/teacher/assessmentsview/reportsview/ReadingTestResultsTable';

type TableSortingFunction = () => SortFnWithDir<ReadingResultRow>;

export type TableSorting = ISorting<SortableColumnName, TableSortingFunction>;

export enum SortableColumnName {
  StudentName = 'Student name',
  Year = 'Year',
  Class = 'Class',
  RegGroup = 'RegGroup',
  Date = 'Date',
  AgeAtTest = 'AgeAtTest',
  ReadingAge = 'ReadingAge',
  SAS = 'SAS',
  Stanine = 'Stanine',
}

const fieldSort =
  (field: keyof ReadingResultRow, numeric?: boolean) =>
  (): SortFnWithDir<ReadingResultRow> =>
  dir =>
  (a, b) =>
    (a[field] || '').toString().localeCompare((b[field] || '').toString(), undefined, { numeric }) *
    dir;

const numericSort =
  (field: 'sas' | 'stanine') => (): SortFnWithDir<ReadingResultRow> => dir => (a, b) =>
    dir * ((b[field] || 0) - (a[field] || 0));

const monthSort =
  (field: 'monthsAge' | 'readingAge') => (): SortFnWithDir<ReadingResultRow> => dir => (a, b) =>
    dir * ((b[field] || 0) - (a[field] || 0));

const dateSort = (): SortFnWithDir<ReadingResultRow> => dir => (a, b) =>
  dir * ((b.testDate?.getUTCSeconds() || 0) - (a.testDate?.getUTCSeconds() || 0));

const studentNameSort = fieldSort('sortableStudentName');
const studentNameFallback = (sort: TableSortingFunction) => () => (dir: SortDirection) =>
  composeSorts(sort()(dir), studentNameSort()(SortDirection.Ascending));

const hasTestFirst =
  (sort: TableSortingFunction) =>
  (): SortFnWithDir<ReadingResultRow> =>
  (dir: SortDirection) =>
  (a, b) => {
    if (a.testDate && !b.testDate) {
      return -1;
    }
    if (!a.testDate && b.testDate) {
      return 1;
    }
    return sort()(dir)(a, b);
  };

export const sortFunctions: Record<SortableColumnName, TableSortingFunction> = {
  [SortableColumnName.StudentName]: fieldSort('sortableStudentName'),
  [SortableColumnName.Year]: studentNameFallback(fieldSort('yearGroup', true)),
  [SortableColumnName.Class]: studentNameFallback(fieldSort('readerGroup', true)),
  [SortableColumnName.RegGroup]: studentNameFallback(fieldSort('tutorGroup', true)),
  [SortableColumnName.Date]: hasTestFirst(studentNameFallback(dateSort)),
  [SortableColumnName.AgeAtTest]: hasTestFirst(studentNameFallback(monthSort('monthsAge'))),
  [SortableColumnName.ReadingAge]: hasTestFirst(studentNameFallback(monthSort('readingAge'))),
  [SortableColumnName.SAS]: hasTestFirst(studentNameFallback(numericSort('sas'))),
  [SortableColumnName.Stanine]: hasTestFirst(studentNameFallback(numericSort('stanine'))),
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/numberbonds/v1/numberbonds.proto" (package "sparx.games.progress.numberbonds.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.games.progress.numberbonds.v1.NumberBondsAnswerAction
 */
export interface NumberBondsAnswerAction {
  /**
   * @generated from protobuf field: repeated sparx.games.progress.numberbonds.v1.NumberBondsAnswer answers = 1;
   */
  answers: NumberBondsAnswer[];
}
/**
 * @generated from protobuf message sparx.games.progress.numberbonds.v1.NumberBondsAnswer
 */
export interface NumberBondsAnswer {
  /**
   * @generated from protobuf field: sparx.games.progress.numberbonds.v1.RhythmAnswer rhythmAnswer = 1;
   */
  rhythmAnswer?: RhythmAnswer;
}
/**
 * @generated from protobuf message sparx.games.progress.numberbonds.v1.RhythmAnswer
 */
export interface RhythmAnswer {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp answerTime = 1;
   */
  answerTime?: Timestamp;
  /**
   * @generated from protobuf field: float timeSinceLastHit = 2;
   */
  timeSinceLastHit: number;
  /**
   * @generated from protobuf field: int32 requiredTotal = 3;
   */
  requiredTotal: number;
  /**
   * @generated from protobuf field: int32 displayedNumber = 4;
   */
  displayedNumber: number;
  /**
   * @generated from protobuf field: int32 wantedAnswer = 5;
   */
  wantedAnswer: number;
  /**
   * @generated from protobuf field: int32 pressedAnswer = 6;
   */
  pressedAnswer: number;
  /**
   * @generated from protobuf field: bool awaitingHit = 7;
   */
  awaitingHit: boolean;
  /**
   * @generated from protobuf field: float secondsLate = 8;
   */
  secondsLate: number;
  /**
   * @generated from protobuf field: bool missed = 9;
   */
  missed: boolean;
  /**
   * @generated from protobuf field: bool wasPerfect = 10;
   */
  wasPerfect: boolean;
  /**
   * @generated from protobuf field: string songName = 11;
   */
  songName: string;
  /**
   * @generated from protobuf field: int32 phraseIndex = 12;
   */
  phraseIndex: number;
  /**
   * @generated from protobuf field: float difficulty = 13;
   */
  difficulty: number;
  /**
   * @generated from protobuf field: float timeBetweenCells = 14;
   */
  timeBetweenCells: number;
  /**
   * @generated from protobuf field: int32 indexWithinPhrase = 15;
   */
  indexWithinPhrase: number;
  /**
   * @generated from protobuf field: string targetNumbers = 16;
   */
  targetNumbers: string;
  /**
   * @generated from protobuf field: string eventDescription = 17;
   */
  eventDescription: string;
  /**
   * @generated from protobuf field: bool wasTalkAndLearn = 18;
   */
  wasTalkAndLearn: boolean;
  /**
   * @generated from protobuf field: string talkAndLearnTextInput = 19;
   */
  talkAndLearnTextInput: string;
  /**
   * @generated from protobuf field: string talkAndLearnRequiredAnswer = 20;
   */
  talkAndLearnRequiredAnswer: string;
  /**
   * @generated from protobuf field: int32 talkAndLearnAttemptCount = 21;
   */
  talkAndLearnAttemptCount: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class NumberBondsAnswerAction$Type extends MessageType<NumberBondsAnswerAction> {
  constructor() {
    super('sparx.games.progress.numberbonds.v1.NumberBondsAnswerAction', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => NumberBondsAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.numberbonds.v1.NumberBondsAnswerAction
 */
export const NumberBondsAnswerAction = new NumberBondsAnswerAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumberBondsAnswer$Type extends MessageType<NumberBondsAnswer> {
  constructor() {
    super('sparx.games.progress.numberbonds.v1.NumberBondsAnswer', [
      { no: 1, name: 'rhythmAnswer', kind: 'message', T: () => RhythmAnswer },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.numberbonds.v1.NumberBondsAnswer
 */
export const NumberBondsAnswer = new NumberBondsAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RhythmAnswer$Type extends MessageType<RhythmAnswer> {
  constructor() {
    super('sparx.games.progress.numberbonds.v1.RhythmAnswer', [
      { no: 1, name: 'answerTime', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'timeSinceLastHit',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 3,
        name: 'requiredTotal',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'displayedNumber',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'wantedAnswer',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'pressedAnswer',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 7, name: 'awaitingHit', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'secondsLate', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 9, name: 'missed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 10, name: 'wasPerfect', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 11, name: 'songName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 12,
        name: 'phraseIndex',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 13, name: 'difficulty', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 14,
        name: 'timeBetweenCells',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 15,
        name: 'indexWithinPhrase',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 16,
        name: 'targetNumbers',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 17,
        name: 'eventDescription',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 18,
        name: 'wasTalkAndLearn',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 19,
        name: 'talkAndLearnTextInput',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 20,
        name: 'talkAndLearnRequiredAnswer',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 21,
        name: 'talkAndLearnAttemptCount',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.numberbonds.v1.RhythmAnswer
 */
export const RhythmAnswer = new RhythmAnswer$Type();

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/pictureshuffle/v1/pictureshuffle.proto" (package "sparx.games.state.pictureshuffle.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { StringValue } from '../../../../../../google/protobuf/wrappers';
import { BoolValue } from '../../../../../../google/protobuf/wrappers';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
import { UInt32Value } from '../../../../../../google/protobuf/wrappers';
//
// The messages below correspond to the data values that are saved for each
// user so that progress is retained between sessions
//
// In Picture Shuffle this is:
// - how much money the player has
// - which VIPs the player has unlocked
// - data for the state of each animal:
// - what is the state of the board
// - how many times has it been solved
// - has it been purchased
// - data for the state of each enclosure:
// - which animal is equipped here

/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value money = 1;
   */
  money?: UInt32Value;
  /**
   * @generated from protobuf field: map<string, sparx.games.state.pictureshuffle.v1.AnimalState> animalStates = 2;
   */
  animalStates: {
    [key: string]: AnimalState;
  };
  /**
   * @generated from protobuf field: map<string, sparx.games.state.pictureshuffle.v1.EnclosureState> enclosureStates = 3;
   */
  enclosureStates: {
    [key: string]: EnclosureState;
  };
  /**
   * @generated from protobuf field: repeated string vipsUnlocked = 4;
   */
  vipsUnlocked: string[];
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 5;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: repeated string enclosuresUnlocked = 6;
   */
  enclosuresUnlocked: string[];
  /**
   * @generated from protobuf field: uint32 quizPhasesCompleted = 7;
   */
  quizPhasesCompleted: number;
}
/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.AnimalState
 */
export interface AnimalState {
  /**
   * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.TilePermutation permutation = 1;
   */
  permutation?: TilePermutation;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value completionCount = 2;
   */
  completionCount?: UInt32Value;
  /**
   * @generated from protobuf field: google.protobuf.BoolValue purchased = 3;
   */
  purchased?: BoolValue;
}
/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.EnclosureState
 */
export interface EnclosureState {
  /**
   * @generated from protobuf field: google.protobuf.StringValue equipped = 1;
   */
  equipped?: StringValue;
}
/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.TilePermutation
 */
export interface TilePermutation {
  /**
   * @generated from protobuf field: google.protobuf.BoolValue inProgress = 1;
   */
  inProgress?: BoolValue;
  /**
   * @generated from protobuf field: repeated sparx.games.state.pictureshuffle.v1.TilePairing pairings = 2;
   */
  pairings: TilePairing[];
}
/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.TilePairing
 */
export interface TilePairing {
  /**
   * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.GridCoordinate source = 1;
   */
  source?: GridCoordinate;
  /**
   * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.GridCoordinate dest = 2;
   */
  dest?: GridCoordinate;
}
/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.GridCoordinate
 */
export interface GridCoordinate {
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value x = 1;
   */
  x?: UInt32Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value y = 2;
   */
  y?: UInt32Value;
}
//
// Picture Shuffle does not do any processing on the cloud, so the messages
// below are only here because they are expected by game.go

/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.State
 */
export interface State {}
/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.Init
 */
export interface Init {}
/**
 *
 * The only action fired by PictureShuffle is a "quitGame" action, called when
 * the user presses an in-game "quit" button
 *
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.Action
 */
export interface Action {
  /**
   * the values that can be sent with an action
   *
   * @generated from protobuf field: sparx.games.state.pictureshuffle.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * the names of actions that can be sent
 *
 * @generated from protobuf enum sparx.games.state.pictureshuffle.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * @generated from protobuf enum value: quitGame = 0;
   */
  quitGame = 0,
}
/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.pictureshuffle.v1.Params
 */
export interface Params {}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.Store', [
      { no: 1, name: 'money', kind: 'message', T: () => UInt32Value },
      {
        no: 2,
        name: 'animalStates',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => AnimalState },
      },
      {
        no: 3,
        name: 'enclosureStates',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => EnclosureState },
      },
      {
        no: 4,
        name: 'vipsUnlocked',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 6,
        name: 'enclosuresUnlocked',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'quizPhasesCompleted',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnimalState$Type extends MessageType<AnimalState> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.AnimalState', [
      { no: 1, name: 'permutation', kind: 'message', T: () => TilePermutation },
      { no: 2, name: 'completionCount', kind: 'message', T: () => UInt32Value },
      { no: 3, name: 'purchased', kind: 'message', T: () => BoolValue },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.AnimalState
 */
export const AnimalState = new AnimalState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnclosureState$Type extends MessageType<EnclosureState> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.EnclosureState', [
      { no: 1, name: 'equipped', kind: 'message', T: () => StringValue },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.EnclosureState
 */
export const EnclosureState = new EnclosureState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TilePermutation$Type extends MessageType<TilePermutation> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.TilePermutation', [
      { no: 1, name: 'inProgress', kind: 'message', T: () => BoolValue },
      {
        no: 2,
        name: 'pairings',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TilePairing,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.TilePermutation
 */
export const TilePermutation = new TilePermutation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TilePairing$Type extends MessageType<TilePairing> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.TilePairing', [
      { no: 1, name: 'source', kind: 'message', T: () => GridCoordinate },
      { no: 2, name: 'dest', kind: 'message', T: () => GridCoordinate },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.TilePairing
 */
export const TilePairing = new TilePairing$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GridCoordinate$Type extends MessageType<GridCoordinate> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.GridCoordinate', [
      { no: 1, name: 'x', kind: 'message', T: () => UInt32Value },
      { no: 2, name: 'y', kind: 'message', T: () => UInt32Value },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.GridCoordinate
 */
export const GridCoordinate = new GridCoordinate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.State', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.Init', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.pictureshuffle.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.pictureshuffle.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.pictureshuffle.v1.Params
 */
export const Params = new Params$Type();

import { ListSittingsResponse_SittingData } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import styles from 'components/matchtable/MatchTable.module.css';
import { Link as RouteLink } from 'react-router-dom';

export const SittingNameCell = ({ sitting }: { sitting?: ListSittingsResponse_SittingData }) => (
  <td className={styles.SittingName}>
    {sitting && (
      <RouteLink
        to={`/teacher/sittings/${sitting.sitting?.sittingName.split('/')[3]}`}
        state={{
          from: window.location.pathname,
        }}
      >
        {sitting.sitting?.ukYeargroup ? `Year ${sitting.sitting?.ukYeargroup} - ` : ''}
        {sitting.sittingGroup?.manualName}
      </RouteLink>
    )}
  </td>
);

export const MatchMethodCell = ({ method }: { method: 'login' | 'manual' | undefined }) => (
  <td className={styles.SecondaryText}>
    {method === 'manual' ? (
      <span>Joined as guest</span>
    ) : method === 'login' ? (
      <span>Sparx login</span>
    ) : (
      <span>Other</span>
    )}
  </td>
);

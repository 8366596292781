import { RpcError } from '@protobuf-ts/runtime-rpc';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getSchoolID, loadSchoolId, redirectToLogin } from 'api/auth';
import { schoolsClient } from 'api/clients';

interface SchoolInfo {
  a: string;
  i: string;
  n: string;
  p: string[];
}

export const getSchoolName = async (schoolId: string): Promise<string> => {
  const url = window.settings?.slDataUrl || 'schools-json';
  const data = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'text/plain',
    },
  });

  const schoolsEncoded = await data.text();
  const schoolsDecoded = decodeBase64(schoolsEncoded);
  const response = JSON.parse(schoolsDecoded) as SchoolInfo[];
  const school = response.find(s => s.i === schoolId);
  if (!school) {
    throw new Error('School not found');
  }
  if (!school?.p.includes('SPARX_ASSESSMENTS')) {
    throw new Error('School does not have assessments enabled');
  }
  return school.n;
};

// this is needed to support non ascii characters in the base64 encoded string, see
// https://stackoverflow.com/a/64752311/1806381
const decodeBase64 = (base64: string) => {
  const text = atob(base64);
  const length = text.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = text.charCodeAt(i);
  }
  const decoder = new TextDecoder(); // default is utf-8
  return decoder.decode(bytes);
};

export const useSchoolName = () =>
  useQuery({
    queryKey: ['school'],
    queryFn: async () => {
      const schoolId = loadSchoolId();
      if (!schoolId) {
        await redirectToLogin();
      }
      try {
        return {
          schoolId,
          schoolName: await getSchoolName(schoolId || ''),
        };
      } catch (e) {
        console.error('Failed to get school name', e);
        await redirectToLogin(undefined, { forget: true });
      }
    },
    retry: false,
    staleTime: Infinity, // Remember forever
    cacheTime: Infinity, // Remember forever
    suspense: true,
  });

export const useSchool = (opts?: UseQueryOptions<School, RpcError>) =>
  useQuery({
    queryKey: ['school', 'data'],
    queryFn: async () =>
      schoolsClient.getSchool({
        name: 'schools/' + (await getSchoolID()),
      }).response,
    cacheTime: Infinity,
    staleTime: Infinity,
    ...opts,
  });

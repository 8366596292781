import { RpcError } from '@protobuf-ts/runtime-rpc';
import { GetSittingParticipantPackagesResponse } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import {
  GetPackageResponse,
  ListAssignmentPackagesResponse,
  Package,
} from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getSchoolID } from 'api/auth';
import { packagesClient, sittingsClient } from 'api/clients';
import { queryClient } from 'app/queryClient';

export const sittingPackagesQuery = (sittingName: string) => ({
  queryKey: ['sitting', sittingName, 'packages'],
  queryFn: () =>
    sittingsClient.getSittingParticipantPackages({
      sittingName,
    }).response,
});

export const useStudentSittingPackages = (
  sittingName: string,
  opts?: UseQueryOptions<GetSittingParticipantPackagesResponse, RpcError>,
) =>
  useQuery({
    ...sittingPackagesQuery(sittingName),
    ...opts,
  });

export const usePackage = (
  packageName: string,
  opts?: UseQueryOptions<GetPackageResponse, RpcError>,
) =>
  useQuery({
    queryKey: ['packages', packageName],
    queryFn: () => packagesClient.getPackage({ packageName }).response,
    ...opts,
  });

export const updatePackage = (pkg: Package) => {
  // TODO: replace package in useSittingPackages query?
  if (pkg.contents) {
    queryClient.setQueryData(['packages', pkg.name], { package: pkg });
  }
};

export const updateVoidedPackages = (sittingName: string, voidedPackageNames: string[]) => {
  // If sitting packages contains a voided package, invalidate the query
  const sittingQuery = sittingPackagesQuery(sittingName);
  queryClient.fetchQuery(sittingQuery).then(data => {
    const found = data.packages.find(pkg => voidedPackageNames.includes(pkg.name));
    if (found) {
      queryClient.invalidateQueries(sittingQuery.queryKey);
    }
  });

  // Ensure all the packages are invalidated
  for (const voidedPackageName in voidedPackageNames) {
    const packageId = voidedPackageName.replace('packages/', '');
    queryClient.invalidateQueries(['packages', packageId]);
  }
};

export const useAssignmentPackages = (
  assignmentName: string,
  opts?: UseQueryOptions<ListAssignmentPackagesResponse, RpcError>,
) =>
  useQuery({
    queryKey: ['packages', 'assignment', assignmentName],
    queryFn: async () =>
      packagesClient.listAssignmentPackages({
        assignmentName,
        schoolName: `schools/${await getSchoolID()}`,
      }).response,
    refetchInterval: 5000, // every 5 seconds
    refetchIntervalInBackground: true,
    enabled: Boolean(assignmentName),
    ...opts,
  });

import { UserInteractionEventFields } from '@sparx/analytics';
import { AssessmentServiceClient } from '@sparx/api/apis/sparx/assessment/v1/assessment.client';
import {
  CurriculumSummariesClient,
  TopicSummariesClient,
} from '@sparx/api/apis/sparx/content/summaries/v1/curriculum.client';
import { createContext, ReactNode, useContext } from 'react';

interface AssessmentContext {
  schoolId: string;
  assessmentClient: AssessmentServiceClient;
  curriculumClient: CurriculumSummariesClient;
  topicClient: TopicSummariesClient;
  sendEvent: (event: UserInteractionEventFields) => void;
  loadingComponent: ReactNode;
}

const AssessmentContext = createContext<AssessmentContext>({} as AssessmentContext);

export const useAssessmentContext = (): AssessmentContext => {
  const context = useContext(AssessmentContext);
  if (!context) {
    throw new Error('useAssessmentContext must be used within an AssessmentContextProvider');
  }
  return context;
};

export const AssessmentContextProvider = AssessmentContext.Provider;

import { useLibAnalytics } from '@sparx/analytics';
import { AssessmentContextProvider } from '@sparx/assessments';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { useSession } from 'api/auth';
import { assessmentsClient, curriculumsClient, topicsClient } from 'api/clients';
import { PropsWithChildren } from 'react';

export const AssessmentContext = ({ children }: PropsWithChildren) => {
  const { data: session } = useSession({ suspense: true });
  const sendEvent = useLibAnalytics();

  return (
    <AssessmentContextProvider
      value={{
        assessmentClient: assessmentsClient,
        curriculumClient: curriculumsClient,
        topicClient: topicsClient,
        schoolId: session?.schoolId || '',
        sendEvent,
        loadingComponent: <LargeLoading />,
      }}
    >
      {children}
    </AssessmentContextProvider>
  );
};

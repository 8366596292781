import { TaskItem } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { useMeasuredSize } from '@sparx/react-utils';
import { useActivity } from 'api/activities';
import { setCurrentActivity } from 'api/statepusher';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { ActivityDelivery } from 'views/student/studentsittingview/adaptive/ActivityDelivery';
import { SupportMaterial } from 'views/student/studentsittingview/adaptive/SupportMaterial';
import { scrollContainerTop } from 'views/student/studentsittingview/adaptive/utils';
import { ScrollingWrapper } from 'views/student/studentsittingview/ScrollingWrapper';

import styles from './BucketedAssessmentTaskItem.module.css';

interface BucketedAssessmentTaskItemProps {
  taskItem: TaskItem;
}

export const BucketedAssessmentTaskItem = ({ taskItem }: BucketedAssessmentTaskItemProps) => {
  const { data: activity, refetch } = useActivity(taskItem.name);

  useEffect(() => {
    setCurrentActivity({
      activityName: activity?.name || '',
      taskItemName: taskItem.name,
    });
  }, [activity?.name, taskItem.name]);

  const [container, size] = useMeasuredSize();

  if (!activity) {
    return <></>;
  }

  const supportText = activity.state?.skillActivity?.question?.supportMaterial?.text;
  const index = activity.annotations['assessment/question-index'] || '?';
  const isIntro = activity.annotations['assessment/intro-skill'] === 'true';

  const fullHeight = activity && !supportText;

  const activityDelivery = (
    <ActivityDelivery
      activity={activity}
      onComplete={refetch}
      fontSize="15"
      submitText={isIntro ? 'I have read this' : undefined}
    />
  );

  return (
    <div className={styles.Container}>
      <ScrollingWrapper disabled={!isIntro} className={styles.SupportContainer}>
        <div className={styles.SupportMaterial}>
          <AnimatePresence mode="wait">
            {isIntro && (
              <motion.div
                className={styles.IntroText}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
              >
                <span>Read this, then answer the questions:</span>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence mode="wait">
            {supportText && (
              <motion.div
                key={supportText}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <SupportMaterial>{supportText}</SupportMaterial>
                {isIntro && <div className={styles.IHaveReadThis}>{activityDelivery}</div>}
                {!isIntro && !fullHeight && <div style={{ height: size.height }} />}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </ScrollingWrapper>
      <div
        className={classNames(
          styles.QuestionContainer,
          fullHeight && styles.QuestionContainerFullHeight,
        )}
        ref={container}
      >
        {!isIntro && (
          <ScrollingWrapper className={styles.Question} id="bucketed-question-container">
            <AnimatePresence
              mode="wait"
              onExitComplete={scrollContainerTop('bucketed-question-container')}
            >
              <motion.div
                className={styles.QuestionWithNumber}
                key={activity.name}
                transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
                initial={{ opacity: 0, x: '200px' }}
                exit={{ opacity: 0, x: '-200px' }}
                animate={{ opacity: 1, x: 0 }}
              >
                <div className={styles.QuestionNumber}>Q{index}.</div>
                {activityDelivery}
              </motion.div>
            </AnimatePresence>
          </ScrollingWrapper>
        )}
      </div>
    </div>
  );
};

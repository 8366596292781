import { RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

// Dimensions of a component measured by the hook
interface IDimensions {
  // The width of the rendered component (including scaling)
  width?: number;
  // The height of the rendered component (including scaling)
  height?: number;
  // The base width of the component (before scaling)
  offsetWidth?: number;
  // The base height of the component (before scaling)
  offsetHeight?: number;
}

// Options for useDimensions
interface IUseDimensionsOptions {
  // If true, then the dimensions will be remeasured when any images in the components have loaded
  detectImagesLoading?: boolean;
}

/**
 * Hook to measure the dimensions of a React component
 * Returns [ref, dimensions], where ref is a React ref you should add to the component to be measured,
 * and dimensions will be the measured dimensions of the component as an IDimensions (with width
 * and height attributes and offsetWidth and offsetHeight). The offset dimensions are the original dimensions of the
 * component, whereas the non-offset dimensions are the dimensions after scaling.
 * Inspired by https://github.com/Swizec/useDimensions, converted to TypeScript and added window
 * resizing listener
 * Originally used in indyclient
 * For an example of use, see js/apps/swclient2/src/views/wac/WACDisplay.tsx
 */
export const useDimensions = <T extends HTMLElement>({
  detectImagesLoading = false,
}: IUseDimensionsOptions = {}): [RefObject<T>, IDimensions] => {
  const ref = useRef<T>(null);
  const [dimensions, setDimensions] = useState<IDimensions>({});

  const calculateDimensions = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (ref.current && rect) {
      // Update exported dimensions if the content size has changed
      if (
        dimensions.width !== rect.width ||
        dimensions.height !== rect.height ||
        dimensions.offsetWidth != ref.current.offsetWidth ||
        dimensions.offsetHeight != ref.current.offsetHeight
      ) {
        setDimensions({
          width: rect.width,
          height: rect.height,
          offsetWidth: ref.current.offsetWidth,
          offsetHeight: ref.current.offsetHeight,
        });
      }
    }
  }, [dimensions.width, dimensions.height, dimensions.offsetWidth, dimensions.offsetHeight]);

  // Recalculate dimensions when the DOM changes (every render)
  useLayoutEffect(() => {
    calculateDimensions();
  });

  // Recalculate dimensions when the window is resized
  useEffect(() => {
    window.addEventListener('resize', calculateDimensions);
    return () => window.removeEventListener('resize', calculateDimensions);
  }, [calculateDimensions]);

  // Listen for images loading and then recalculate the dimensions:
  useEffect(() => {
    if (detectImagesLoading && ref.current) {
      const imgs = ref.current.getElementsByTagName('img');
      for (const img of imgs) {
        if (!img.complete) {
          img.onload = () => {
            if (ref.current) {
              calculateDimensions();
            }
          };
        }
      }
    }
  }, [calculateDimensions, detectImagesLoading]);

  return [ref, dimensions];
};

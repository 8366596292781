import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  GetSittingResponse,
  JoinSittingRequest,
  JoinSittingRequest_GuestStudent,
  JoinSittingResponse,
  LoginProvider_Method,
} from '@sparx/api/apis/sparx/assessment/app/v1/authentication';
import { useDisclosure } from '@sparx/react-utils';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { redirectToLogin } from 'api/auth';
import { signInWithGoogle, signInWithMicrosoft } from 'api/firebase';
import { Button, ButtonProps } from 'components/button/Button';
import { Card } from 'components/card/Card';
import { CenteredPage } from 'components/centeredpage/CenteredPage';
import { EditStudentForm, GuestStudentFields } from 'components/editstudentform/EditStudentForm';
import { startSession, useSchool } from 'components/ensuresession/EnsureSession';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { FirebaseError } from 'firebase/app';
import { User, UserCredential } from 'firebase/auth';
import { useGetSitting, useJoinSitting } from 'queries/app';
import queryString from 'query-string';
import { BaseSyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FindSitting } from 'views/landing/joinview/FindSitting';

import styles from './JoinView.module.css';
import sparxStar from './sparxstar.png';

export const JoinView = () => {
  const { schoolName } = useSchool();

  const [sitting, setSitting] = useState<GetSittingResponse | null>(null);

  return (
    <CenteredPage className={styles.Container}>
      <Card className={styles.Card}>
        {!sitting ? (
          <>
            <PageHeader back="/">Join an assessment</PageHeader>
            <div className={styles.Form}>
              <FindSitting onSuccess={setSitting} />
              <div className={styles.SelectedSchool}>
                Selected school: <strong>{schoolName}</strong>
              </div>
            </div>
          </>
        ) : (
          <JoinChoice sitting={sitting} onBack={() => setSitting(null)} />
        )}
      </Card>
    </CenteredPage>
  );
};

const JoinChoice = ({
  sitting: _sitting,
  onBack,
}: {
  sitting: GetSittingResponse;
  onBack: () => void;
}) => {
  const {
    sitting,
    joinSitting,
    isLoading: isLoadingJoining,
    isWaitingForApproval,
    error,
    reset,
  } = useJoinSittingWithLoading(_sitting);

  const hasNonSparxLoginMethod = sitting.authenticationProviders.find(
    p => p.method !== LoginProvider_Method.SPARX,
  );
  const getAuthMethod = (method: LoginProvider_Method) =>
    sitting.authenticationProviders.find(p => p.method === method);

  const [promptFirebaseDetails, setPromptFirebaseDetails] = useState<User | undefined>(undefined);
  const {
    isLoading,
    mutate: ssoSignIn,
    error: ssoError,
  } = useMutation({
    mutationFn: async (method: LoginProvider_Method) => {
      const settings = getAuthMethod(method);
      if (!settings) {
        throw new Error('Requested sign-in is not enabled for this assessment');
      }

      let fn: (domain: string) => Promise<UserCredential>;
      switch (method) {
        case LoginProvider_Method.GOOGLE_SSO:
          fn = signInWithGoogle;
          break;
        case LoginProvider_Method.AZURE_SSO:
          fn = signInWithMicrosoft;
          break;
        default:
          throw new Error('Unsupported sign-in method');
      }

      const user = await fn(settings.domain);
      const idToken = await user.user.getIdToken();

      try {
        return await joinSitting({
          sittingName: sitting.sittingName,
          method: {
            oneofKind: 'firebaseAuth',
            firebaseAuth: {
              idToken,
            },
          },
        });
      } catch (err) {
        if (err instanceof RpcError) {
          if (err.message === 'date of birth required') {
            setPromptFirebaseDetails(user.user);
            reset();
            return;
          }
        }
        throw err; // rethrow
      }
    },
  });

  const [guestLogin, setUseGuestLogin] = useState(false);

  if (isWaitingForApproval) {
    return (
      <Stack align="center" spacing={2} direction="column">
        <LargeLoading />
        <h2>Waiting for your teacher...</h2>
        <p>Ask your teacher to approve you to join the assessment.</p>
      </Stack>
    );
  }

  if (promptFirebaseDetails) {
    return (
      <>
        <PageHeader back={() => setUseGuestLogin(false)} title="Joining assessment">
          Joining assessment
          <PageHeaderSubpage>Details</PageHeaderSubpage>
        </PageHeader>
        <Stack direction="column" spacing={4}>
          <GuestError error={error} />
          <FirebaseGuestJoin
            sitting={sitting}
            joinSitting={joinSitting}
            isLoading={isLoadingJoining}
            user={promptFirebaseDetails}
          />
        </Stack>
      </>
    );
  }

  const buttonProps: ButtonProps & { className: string } = {
    colour: 'white',
    variant: 'outline',
    className: styles.JoinOption,
  };

  if (guestLogin) {
    const hasGoogleSSO = getAuthMethod(LoginProvider_Method.GOOGLE_SSO);
    const hasMicrosoftSSO = getAuthMethod(LoginProvider_Method.AZURE_SSO);
    const hasGuestLogin = getAuthMethod(LoginProvider_Method.GUEST);

    return (
      <>
        <PageHeader back={() => setUseGuestLogin(false)} title="Joining assessment">
          Joining assessment
          <PageHeaderSubpage>Other</PageHeaderSubpage>
        </PageHeader>
        <Stack direction="column" spacing={4}>
          <GuestError error={error} />
          {hasGoogleSSO && (
            <Button
              {...buttonProps}
              leftIcon={<FontAwesomeIcon icon={faGoogle} />}
              onClick={() => ssoSignIn(LoginProvider_Method.GOOGLE_SSO)}
            >
              Login with Google
            </Button>
          )}
          {hasMicrosoftSSO && (
            <Button
              {...buttonProps}
              leftIcon={<FontAwesomeIcon icon={faMicrosoft} />}
              onClick={() => ssoSignIn(LoginProvider_Method.AZURE_SSO)}
            >
              Login with Microsoft
            </Button>
          )}
          {(hasGoogleSSO || hasMicrosoftSSO) && hasGuestLogin && (
            <div className={styles.Or}>
              <span>or</span>
            </div>
          )}
          {hasGuestLogin && (
            <GuestJoin sitting={sitting} joinSitting={joinSitting} isLoading={isLoadingJoining} />
          )}
        </Stack>
      </>
    );
  }

  if (isLoading) {
    return <LargeLoading />;
  }
  return (
    <>
      <PageHeader back={onBack}>Joining assessment</PageHeader>
      <Stack direction="column" spacing={4}>
        {Boolean(ssoError) && <GuestError error={ssoError} />}
        {getAuthMethod(LoginProvider_Method.SPARX) && <SparxSignInButton sitting={sitting} />}

        {hasNonSparxLoginMethod && (
          <Button
            {...buttonProps}
            leftIcon={<FontAwesomeIcon icon={faUser} />}
            onClick={() => setUseGuestLogin(true)}
          >
            Sign in another way...
          </Button>
        )}
      </Stack>
    </>
  );
};

const buttonProps: ButtonProps & { className: string } = {
  colour: 'white',
  variant: 'outline',
  className: styles.JoinOption,
};

const SparxSignInButton = ({ sitting }: { sitting: GetSittingResponse }) => {
  const { isLoading: isLoadingSparxLogin, mutate: sparxSignIn } = useMutation({
    mutationFn: () =>
      redirectToLogin(undefined, {
        path: `/student?${queryString.stringify({ s: sitting.sittingName })}`,
      }),
  });

  return (
    <Button
      {...buttonProps}
      leftIcon={<img src={sparxStar} alt="" />}
      isLoading={isLoadingSparxLogin}
      onClick={() => sparxSignIn()}
    >
      Sign in using Sparx
    </Button>
  );
};

const GuestError = ({ error }: { error?: unknown | null }) => {
  if (!error) {
    return null;
  }

  let text = 'An unknown error occurred, please try again.';
  if (error instanceof RpcError) {
    if (error.code === 'PERMISSION_DENIED') {
      text = 'Join request rejected by your teacher.';
    } else if (error.code === 'DEADLINE_EXCEEDED') {
      text = 'Assessment has already ended. Check assessment code with your teacher.';
    } else if (error.code === 'INVALID_ARGUMENT') {
      text = `Validation error: ${error.message}`;
    }
  } else if (error instanceof FirebaseError) {
    if (error.code === 'auth/popup-closed-by-user') {
      text = 'Login popup was closed, please try again.';
    }
  }

  return (
    <Alert status="error">
      <Alert.Icon />
      <Alert.Description>{text}</Alert.Description>
    </Alert>
  );
};

const useJoinSittingWithLoading = (_sitting: GetSittingResponse) => {
  // We store the sitting in a state and refetch it when the user enters their details
  // This is to ensure that if the sitting is started while the user is entering their details,
  // we know if the sitting has started or not (and whether to show the approval message).
  const [sitting, setSitting] = useState(_sitting);
  const { mutate: refetchSitting } = useGetSitting({
    onSuccess: setSitting,
  });

  const {
    mutateAsync: joinSitting,
    isLoading,
    error,
    reset,
    variables,
  } = useJoinSitting({
    onSuccess: startSession,
    onMutate: () => {
      // When mutation starts, refetch the sitting to check if it has started
      refetchSitting(sitting.joinCode);
    },
  });

  const isRejoining =
    variables?.method.oneofKind === 'guestStudent' && variables?.method.guestStudent.rejoining;

  return {
    sitting,
    joinSitting,
    isLoading,
    error,
    isWaitingForApproval: sitting.hasStarted && isLoading && isRejoining,
    reset,
  };
};

interface GuestJoinProps {
  sitting: GetSittingResponse;
  isLoading?: boolean;
  joinSitting: UseMutateAsyncFunction<JoinSittingResponse, RpcError, JoinSittingRequest>;
}

const GuestJoin = ({ sitting, isLoading, joinSitting }: GuestJoinProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<GuestStudentFields>({
    disabled: isLoading,
    defaultValues: {
      familyName: '',
      givenName: '',
      dateOfBirth: {},
    },
  });

  const {
    open: rejoinPromptOpen,
    onOpen: showRejoinPrompt,
    onClose: closeRejoinPrompt,
  } = useDisclosure();

  const onRejoinChoice = (choice: boolean) => {
    closeRejoinPrompt();
    onSubmit(undefined, choice);
  };

  const sittingStarted = sitting.hasStarted;

  const onSubmit = (e?: BaseSyntheticEvent, rejoining?: boolean) => {
    e?.preventDefault();
    if (sittingStarted && rejoining === undefined) {
      showRejoinPrompt();
      return;
    }

    handleSubmit(
      guestStudent =>
        !isLoading &&
        joinSitting({
          sittingName: sitting.sittingName,
          method: {
            oneofKind: 'guestStudent',
            guestStudent: {
              ...guestStudent,
              rejoining: rejoining || false,
            },
          },
        }),
    )(e);
  };

  return (
    <form onSubmit={onSubmit}>
      <Modal isOpen={rejoinPromptOpen} onClose={closeRejoinPrompt}>
        <Modal.Content width="md" className={styles.RejoinPrompt}>
          <Modal.CloseButton />
          <Modal.Title>This assessment has already started</Modal.Title>
          <div className={styles.RejoinChoice}>
            <Button
              colour="blue"
              onClick={() => onRejoinChoice(true)}
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            >
              Continue a test I have started
            </Button>
            <Button
              colour="blue"
              onClick={() => onRejoinChoice(false)}
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            >
              Start a test from the beginning
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      <Stack direction="column" spacing={4}>
        <EditStudentForm register={register} errors={errors} />
        <Stack justify="flex-end">
          <Button
            type="submit"
            isDisabled={!isValid}
            isLoading={isLoading}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            Join assessment
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

const getNamesFromUser = (user: User) => {
  const split = (user.displayName || '').indexOf(' ');
  return {
    givenName: (user.displayName || '').slice(0, split),
    familyName: (user.displayName || '').slice(split + 1),
  };
};

const FirebaseGuestJoin = ({
  sitting,
  isLoading,
  joinSitting,
  user,
}: GuestJoinProps & { user: User }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<GuestStudentFields>({
    disabled: isLoading,
    defaultValues: JoinSittingRequest_GuestStudent.create({
      dateOfBirth: { day: 1, month: 0, year: 2008 },
      ...getNamesFromUser(user),
    }),
  });

  return (
    <form
      onSubmit={handleSubmit(
        async guestStudent =>
          !isLoading &&
          joinSitting({
            sittingName: sitting.sittingName,
            method: {
              oneofKind: 'firebaseAuth',
              firebaseAuth: {
                idToken: await user.getIdToken(),
                guestStudent: {
                  ...guestStudent,
                  rejoining: false, // unused here
                },
              },
            },
          }),
      )}
    >
      <Stack direction="column" spacing={4}>
        <EditStudentForm register={register} errors={errors} />
        <Stack justify="flex-end">
          <Button
            type="submit"
            isDisabled={!isValid}
            isLoading={isLoading}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            Join assessment
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

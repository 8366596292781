import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth';

// Live firebase config
const firebaseConfigLive = {
  apiKey: 'AIzaSyA3oSV2nfyevdNH7f97E-xOzMAxVZlmao0',
  authDomain: 'sparx-assessments.firebaseapp.com',
  projectId: 'sparx-assessments',
  storageBucket: 'sparx-assessments.appspot.com',
  messagingSenderId: '941703887345',
  appId: '1:941703887345:web:c1a282a41d2e4b04c9ec50',
};

// Test firebase config
const firebaseConfigTest = {
  apiKey: 'AIzaSyA3oSV2nfyevdNH7f97E-xOzMAxVZlmao0',
  authDomain: 'sparx-assessments.firebaseapp.com',
  projectId: 'sparx-assessments',
  storageBucket: 'sparx-assessments.appspot.com',
  messagingSenderId: '941703887345',
  appId: '1:941703887345:web:342f571e917cbc80c9ec50',
};

const firebaseConfig =
  window.settings?.envName === 'live' ? firebaseConfigLive : firebaseConfigTest;

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const signInWithGoogle = async (domain: string) => {
  const provider = new GoogleAuthProvider();
  auth.useDeviceLanguage();

  provider.setCustomParameters({ hd: domain });

  return await signInWithPopup(auth, provider);
};

export const signInWithMicrosoft = async (domain: string) => {
  const provider = new OAuthProvider('microsoft.com');
  auth.useDeviceLanguage();

  provider.setCustomParameters({ hd: domain });

  return await signInWithPopup(auth, provider);
};

import { faChevronRight, faGraduationCap, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSelectSchoolUrl } from 'api/auth';
import { Button } from 'components/button/Button';
import { AnimatedCenteredPage } from 'components/centeredpage/CenteredPage';
import { useSchool } from 'components/ensuresession/EnsureSession';
import { Logo } from 'components/logo/Logo';
import { Link } from 'react-router-dom';
import styles from 'views/landing/selectview/SelectView.module.css';

export const SelectView = () => {
  const { schoolName } = useSchool();
  return (
    <>
      <AnimatedCenteredPage>
        <div className={styles.Content}>
          <Logo className={styles.Logo} />
          <div className={styles.YourSchool}>
            <strong>{schoolName}</strong>
            <a href={getSelectSchoolUrl(undefined, true)}>Change school</a>
          </div>
          <div className={styles.Buttons}>
            <Link to="/join" className={styles.Button}>
              <FontAwesomeIcon icon={faUser} />
              <span className={styles.ButtonText}>Join assessment</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </div>
        </div>
      </AnimatedCenteredPage>
      <Button
        as={Link}
        to="/teacher"
        variant="ghost"
        leftIcon={<FontAwesomeIcon icon={faGraduationCap} />}
        className={styles.TeacherButton}
      >
        Sign in as teacher
      </Button>
    </>
  );
};

import { faFileLines, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import classNames from 'classnames';
import { isOnlineAssessment, isPaperAssessment } from 'utils/assessments';

import styles from './AssessmentTags.module.css';

export const AssessmentTags = ({ assessment }: { assessment: Assessment }) => {
  const onlineAssessment = isOnlineAssessment(assessment);
  const paperAssessment = isPaperAssessment(assessment);

  return (
    <Stack spacing={2}>
      {paperAssessment && (
        <div className={classNames(styles.Tag, styles.TagPaper)}>
          <FontAwesomeIcon icon={faFileLines} />
          Paper
        </div>
      )}
      {onlineAssessment && (
        <div className={classNames(styles.Tag, styles.TagOnline)}>
          <FontAwesomeIcon icon={faGlobe} />
          Online
        </div>
      )}
    </Stack>
  );
};

import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentWithAs } from '@sparx/sparx-design/ComponentWithAs';
import sparxMathsLogo from '@sparx/sparx-design/logos/sparx_maths_logo.svg';

import { Button, ButtonProps } from './Button';
import styles from './InProductButton.module.css';

export const InProductButton: ComponentWithAs<'button', { feature: string } & ButtonProps> = ({
  feature,
  ...rest
}) => (
  <Button rightIcon={<FontAwesomeIcon icon={faExternalLink} />} {...rest} className={styles.Button}>
    {feature} in
    <img src={sparxMathsLogo} alt="Sparx Maths" className={styles.FeatureButtonLogo} />
  </Button>
);

import { useLibAnalytics } from '@sparx/analytics';
import { IntercomProvider } from '@sparx/intercom';
import { useSession } from 'api/auth';
import { useSchool } from 'api/school';
import { PropsWithChildren } from 'react';

const intercomAppId = 'os8d5arr';

export const urlKnowledgeBase = 'https://support.sparxassessments.com/en/';
const urlKnowledgeBaseArticlePrefix = `${urlKnowledgeBase}articles/`;
const supportEmailAddress = 'schoolsupport@sparx.co.uk';

export const IntercomContext = ({ children }: PropsWithChildren) => {
  const bootProps = useIntercomBootProps();
  const sendEvent = useLibAnalytics();

  return (
    <IntercomProvider
      appId={intercomAppId}
      bootProps={bootProps}
      sendEvent={sendEvent}
      handleSupportLinks={urlKnowledgeBaseArticlePrefix}
      handleSupportEmail={supportEmailAddress}
    >
      {children}
    </IntercomProvider>
  );
};

const useIntercomBootProps = () => {
  const { data: user } = useSession();
  const isTeacher = user?.userType === 'teacher';
  const { data: school } = useSchool({ suspense: false, enabled: isTeacher });

  // Don't return the properties until everything is loaded
  if (!user || !isTeacher || !school) {
    return undefined;
  }

  let company = {
    company_id: school.name,
    name: school.displayName,
    created_at: school.createTime?.seconds,
    website: `https://app.sparxassessments.com?school=${school.name.split('/')[1]}`,
  };
  if (user.subject.startsWith('sparxstaff/')) {
    company = {
      company_id: 'sparx',
      name: 'Sparx',
      created_at: 0,
      website: 'https://sparx-learning.com/',
    };
  }

  return {
    user_id: user.userId,
    school_id: user.schoolId,
    name: user.displayName || `${user.givenName} ${user.familyName}`,
    email: user.emailAddress,
    user_hash: user.intercomHash,

    // company attributes
    company,

    action_color: '#30215d',
    background_color: '#5e5281',
  };
};

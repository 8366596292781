import {
  Assessment,
  AssessmentQuestion,
  Mark,
  QuestionType,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { TopicSummary as ITopicSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';

import { computeAggregatedScores } from '../../../../utils/assessments';

export const groupUnitsWithQuestions = (questions: AssessmentQuestion[]) =>
  questions.reduce((map, q) => {
    const currentValue = map.get(q.unit);
    if (currentValue) {
      currentValue.set(q.type, [...(currentValue.get(q.type) || []), q]);
      map.set(q.unit, currentValue);
    } else {
      map.set(q.unit, new Map().set(q.type, [q]));
    }
    return map;
  }, new Map<string, Map<QuestionType, AssessmentQuestion[]>>());

type TopicCode = { displayName: string; topicCode: string };

export const getTopicDetailsForQuestions = (
  questions: AssessmentQuestion[],
  topicSummariesMap: Map<string, ITopicSummary>,
) =>
  questions.reduce<Record<string, TopicCode | undefined>>((obj, question) => {
    obj[question.name] = question.curriculumTopicNames.reduce<TopicCode>(
      (temp, topicName) => {
        const topicSummary = topicSummariesMap.get(topicName);
        if (temp.displayName === undefined) {
          temp.displayName = '';
          temp.topicCode = '';
        }
        if (temp.displayName !== '') {
          temp.displayName += ', ';
          temp.topicCode += ', ';
        }
        if (topicSummary?.topic) {
          temp.displayName += topicSummary.topic.displayName;
          temp.topicCode += topicSummary.topic.code;
        } else {
          temp.displayName += 'Unknown Topic';
          temp.topicCode += 'Unknown Topic code';
        }
        return temp;
      },
      { displayName: '', topicCode: '' },
    );
    return obj;
  }, {});

export const parseQuestionType = (questionType: QuestionType, subjectKey: string) => {
  switch (subjectKey) {
    case 'science':
      switch (questionType) {
        case QuestionType.FLUENCY:
          return 'Substantive';
        case QuestionType.PROBLEM_SOLVING:
          return 'Disciplinary';
        default:
          return 'Unspecified';
      }
    default:
      switch (questionType) {
        case QuestionType.FLUENCY:
          return 'Fluency';
        case QuestionType.PROBLEM_SOLVING:
          return 'Problem solving';
        default:
          return 'Unspecified';
      }
  }
};

export const getScoresForReport = (
  assessment: Assessment,
  selectedStudentAssessments: StudentAssessment[],
  selectedStudent: Student | undefined,
) => {
  const { averageScoresByQuestion, totalAvailableScore, availableScoresByQuestion } =
    computeAggregatedScores(assessment, selectedStudentAssessments);
  if (selectedStudent) {
    return {
      averageScoresByQuestion:
        selectedStudentAssessments[0]?.marks.reduce((tmp: Map<string, number>, m: Mark) => {
          const availableScore = availableScoresByQuestion.get(m.assessmentQuestionName);
          if (availableScore !== undefined && m.score !== undefined && m.score <= availableScore) {
            tmp.set(m.assessmentQuestionName, m.score);
          }
          return tmp;
        }, new Map<string, number>()) ?? new Map(),
      totalAvailableScore,
    };
  }

  return { totalAvailableScore, averageScoresByQuestion };
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/packageactivity/v1/package.proto" (package "sparx.packageactivity.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Packages } from './package';
import type { ListAssignmentPackagesResponse } from './package';
import type { ListAssignmentPackagesRequest } from './package';
import type { GetPackageResponse } from './package';
import type { GetPackageRequest } from './package';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListStudentPackagesResponse } from './package';
import type { ListStudentPackagesRequest } from './package';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.packageactivity.v1.Packages
 */
export interface IPackagesClient {
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  };
   *
   * @generated from protobuf rpc: ListStudentPackages(sparx.packageactivity.v1.ListStudentPackagesRequest) returns (sparx.packageactivity.v1.ListStudentPackagesResponse);
   */
  listStudentPackages(
    input: ListStudentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentPackagesRequest, ListStudentPackagesResponse>;
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  };
   *
   * @generated from protobuf rpc: GetPackage(sparx.packageactivity.v1.GetPackageRequest) returns (sparx.packageactivity.v1.GetPackageResponse);
   */
  getPackage(
    input: GetPackageRequest,
    options?: RpcOptions,
  ): UnaryCall<GetPackageRequest, GetPackageResponse>;
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "tp:homework"
   *      domain: "{school_name}"
   *    };
   *  }
   *
   * @generated from protobuf rpc: ListAssignmentPackages(sparx.packageactivity.v1.ListAssignmentPackagesRequest) returns (sparx.packageactivity.v1.ListAssignmentPackagesResponse);
   */
  listAssignmentPackages(
    input: ListAssignmentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssignmentPackagesRequest, ListAssignmentPackagesResponse>;
}
/**
 * @generated from protobuf service sparx.packageactivity.v1.Packages
 */
export class PackagesClient implements IPackagesClient, ServiceInfo {
  typeName = Packages.typeName;
  methods = Packages.methods;
  options = Packages.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  };
   *
   * @generated from protobuf rpc: ListStudentPackages(sparx.packageactivity.v1.ListStudentPackagesRequest) returns (sparx.packageactivity.v1.ListStudentPackagesResponse);
   */
  listStudentPackages(
    input: ListStudentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListStudentPackagesRequest, ListStudentPackagesResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListStudentPackagesRequest,
      ListStudentPackagesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  };
   *
   * @generated from protobuf rpc: GetPackage(sparx.packageactivity.v1.GetPackageRequest) returns (sparx.packageactivity.v1.GetPackageResponse);
   */
  getPackage(
    input: GetPackageRequest,
    options?: RpcOptions,
  ): UnaryCall<GetPackageRequest, GetPackageResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetPackageRequest, GetPackageResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "tp:homework"
   *      domain: "{school_name}"
   *    };
   *  }
   *
   * @generated from protobuf rpc: ListAssignmentPackages(sparx.packageactivity.v1.ListAssignmentPackagesRequest) returns (sparx.packageactivity.v1.ListAssignmentPackagesResponse);
   */
  listAssignmentPackages(
    input: ListAssignmentPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssignmentPackagesRequest, ListAssignmentPackagesResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssignmentPackagesRequest,
      ListAssignmentPackagesResponse
    >('unary', this._transport, method, opt, input);
  }
}

import { ComponentProps } from 'react';

import logoDark from './logos/Sparx assessments_dark_no space.svg';
import logoLight from './logos/Sparx assessments_white_no space.svg';

interface LogoProps extends ComponentProps<'img'> {
  colour?: 'light' | 'dark';
}

export const Logo = ({ colour = 'dark', ...props }: LogoProps) => (
  <img src={colour === 'dark' ? logoDark : logoLight} alt="Sparx Assessments" {...props} />
);

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListSittingsResponse_SittingData,
  SittingDeliveryState,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useAssessment } from '@sparx/assessments/src/api/hooks';
import { AssessmentTabs } from '@sparx/assessments/src/components/AssessmentLandingView/AssessmentTab/AssessmentTab';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { Tabs } from '@sparx/sparx-design/components/tabs/Tabs';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { useAssessmentSittings, useSittingStats } from 'api/sittings';
import { useIsMisReady } from 'api/status';
import { useSubjects } from 'api/subjects';
import { AssessmentTags } from 'components/assessmenttags/AssessmentTags';
import { Button } from 'components/button/Button';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { ReactNode, Suspense, useMemo } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import {
  assessmentHasFollowUp,
  assessmentHasReport,
  assessmentHasRevision,
  assessmentHasStudentSubmissions,
  followUpName,
  getAssessmentResourceURL,
  getStudentGroupTypeForAssessment,
  isOnlineAssessment,
  isPaperAssessment,
} from 'utils/assessments';
import { sortSittings } from 'utils/sittings';
import {
  FollowUpCard,
  MarksheetCard,
  MatchStudentsCard,
  ReportsCard,
  ResourcesCard,
  RevisionCard,
  SetAssessmentCard,
  StudentSubmissionsCard,
} from 'views/teacher/assessmentsview/AssessmentHomeViewCards';
import { MarkSheetView } from 'views/teacher/assessmentsview/marksheetview/MarksheetView';
import { SittingItem } from 'views/teacher/sittingslistview/SittingItem';

import styles from './AssessmentHomeView.module.css';
import { FollowUpView } from './followupview/FollowUpView';
import { ReportsView } from './reportsview/ReportsView';

export const AssessmentHomeView = () => {
  const { assessmentId } = useParams();
  const { data: subjects = [] } = useSubjects({ suspense: true });

  const { data: assessment } = useAssessment(
    assessmentId || '',
    {
      includeAssessmentGroupAssessments: false,
      subjectName: 'subjects/-',
    },
    {
      suspense: true,
    },
  );
  const subject = subjects.find(s => s.key === assessment?.subjectKey);

  if (!assessment) {
    throw new Error('Assessment not found');
  }

  const { data: sittings } = useAssessmentSittings(assessment.name, {
    suspense: true,
    select: data => data.sittings,
  });

  const stats = useSittingStats(sittings);
  const sortedSitting = useMemo(
    () =>
      sortSittings(sittings, 'ended').filter(
        s =>
          s.sitting?.state?.state !== SittingDeliveryState.CANCELLED &&
          // Hide ended sittings with 0 participants from the list also
          !(s.sitting?.state?.endTimestamp && s.participantCount === 0),
      ),
    [sittings],
  );
  const backLink = `/teacher/assessments?subject=${assessment.subjectKey}`;

  const [search, setSearch] = useSearchParams();
  const tab = search.get('tab') || '';
  const setTab = (tab: string) =>
    setSearch(p => {
      p.set('tab', tab);
      return p;
    });

  const onlineAssessment = isOnlineAssessment(assessment);
  const paperAssessment = isPaperAssessment(assessment);
  const assessmentGroupType = getStudentGroupTypeForAssessment(assessment);

  const tabs: ReactNode[] = [];
  const content: ReactNode[] = [];

  const pushTab = (data: { title: string; key: string; component: ReactNode }) => {
    tabs.push(
      <Tabs.Trigger key={data.key} value={data.key}>
        {data.title}
      </Tabs.Trigger>,
    );
    content.push(
      <Tabs.Content value={data.key} key={data.key}>
        {data.component}
      </Tabs.Content>,
    );
  };

  const prepareCards = [];
  const assessCards = [];
  const enterCards = [
    <div key="status" className={styles.AlertCard}>
      <MISStatusMessage groupType={assessmentGroupType} />
    </div>,
  ];
  const reviewCards = [];

  if (assessmentHasRevision(assessment)) {
    prepareCards.push(<RevisionCard assessment={assessment} />);
  }

  if (onlineAssessment) {
    pushTab({
      title: 'Sittings',
      key: 'sittings',
      component: (
        <SittingsView
          assessment={assessment}
          unexportedStudents={stats.unexportedStudents}
          sortedSitting={sortedSitting}
        />
      ),
    });
    assessCards.push(<SetAssessmentCard assessment={assessment} />);
    enterCards.push(
      <MatchStudentsCard
        setTab={setTab}
        totalStudents={stats.totalStudents}
        exportedStudents={stats.exportedStudents}
      />,
    );
  }

  if (assessmentHasStudentSubmissions(assessment)) {
    reviewCards.push(<StudentSubmissionsCard assessment={assessment} />);
  }

  if (paperAssessment) {
    const resourceLink = getAssessmentResourceURL(assessment);
    if (resourceLink) {
      assessCards.push(<ResourcesCard link={resourceLink} />);
    }

    pushTab({
      title: 'Marksheet',
      key: 'marksheet',
      component: <MarkSheetView assessment={assessment} fixedTab={AssessmentTabs.Marksheet} />,
    });
    enterCards.push(<MarksheetCard assessment={assessment} setTab={setTab} />);
  }

  if (assessmentHasReport(assessment)) {
    pushTab({
      title: 'Reports',
      key: 'reports',
      component: <ReportsView assessment={assessment} />,
    });
    reviewCards.push(<ReportsCard setTab={setTab} />);
  }

  if (assessmentHasFollowUp(assessment)) {
    pushTab({
      title: followUpName(assessment),
      key: 'followup',
      component: <FollowUpView assessment={assessment} />,
    });
    reviewCards.push(<FollowUpCard assessment={assessment} setTab={setTab} />);
  }

  return (
    <PageContainer>
      <PageHeader
        back={backLink}
        right={<AssessmentTags assessment={assessment} />}
        title={assessment.displayName}
      >
        {subject && (
          <PageHeaderSubpage before={true} to={backLink}>
            {subject.name}
          </PageHeaderSubpage>
        )}
        {assessment.displayName}
      </PageHeader>

      <Tabs value={tab} onValueChange={setTab}>
        <Tabs.List>
          <Tabs.Trigger value="">Overview</Tabs.Trigger>
          {tabs}
        </Tabs.List>
        <Suspense fallback={<LargeLoading />}>
          <Tabs.Content value="">
            <div className={styles.CardsContainer}>
              <div className={styles.CardsTimeline} />
              <div className={styles.Cards}>
                <CardGroup cards={prepareCards} title="Prepare" />
                <CardGroup cards={assessCards} title="Assess" />
                <CardGroup cards={enterCards} title="Match data" />
                <CardGroup cards={reviewCards} title="Review" />
              </div>
            </div>
          </Tabs.Content>
          {content}
        </Suspense>
      </Tabs>
    </PageContainer>
  );
};

const CardGroup = ({ cards, title }: { cards: ReactNode[]; title: string }) => (
  <>
    {cards.length > 0 && (
      <>
        <h3>{title}</h3>
        {cards}
      </>
    )}
  </>
);

export const SittingsView = ({
  assessment,
  unexportedStudents,
  sortedSitting,
}: {
  assessment: Assessment;
  unexportedStudents: number;
  sortedSitting: ListSittingsResponse_SittingData[];
}) => {
  const assessmentGroupType = getStudentGroupTypeForAssessment(assessment);

  return (
    <Stack spacing={5} direction="column">
      <MISStatusMessage groupType={assessmentGroupType} />

      <Alert status={unexportedStudents > 0 ? 'info' : 'success'}>
        <Alert.Icon />
        {unexportedStudents ? (
          <Alert.Description>
            {unexportedStudents} student{unexportedStudents === 1 ? '' : 's'} have not had their
            data exported
          </Alert.Description>
        ) : (
          <Alert.Description>All student data has been exported</Alert.Description>
        )}
        <div style={{ flex: 1 }} />
        <Button
          as={Link}
          to={`/teacher/${assessment.name}/match`}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          Match &amp; export students
        </Button>
      </Alert>

      <h3>Assessment Sittings</h3>

      <Stack spacing={2} direction="column">
        {sortedSitting.map(sitting => (
          <SittingItem
            key={sitting.sitting?.sittingName}
            sitting={sitting.sitting!}
            participantCount={sitting.participantCount}
            exportedParticipantCount={sitting.exportedParticipantCount}
            ended={true}
            from={`/teacher/${assessment.name}`}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export const MISStatusMessage = ({ groupType }: { groupType: StudentGroupType | undefined }) => {
  const message = useIsMisReady(groupType);
  if (!message) return null;

  return (
    <Alert status="warning">
      <Alert.Icon />
      <Alert.Description>{message}</Alert.Description>
    </Alert>
  );
};

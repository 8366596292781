import { Assessment, StudentAssessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { ReactNode, useMemo } from 'react';

import { useBatchGetStudentAssessments } from '../../api/assessment';
import { useTopicSummariesMap } from '../../api/content';
import { useStudentAssessments } from '../../api/hooks';
import { useAssessmentContext } from '../../context';
import {
  computeAggregatedScores,
  computeStudentPercentileRanks,
  getTopicDisplayDetails,
} from '../../utils/assessments';
import { SortDirection } from '../../utils/sorting';
import { AssessmentTabs } from '../AssessmentLandingView/AssessmentTab/AssessmentTab';
import { AssessmentTab } from './AssessmentTab';

/**
 * AssessmentLandingView is the highest level component shown when an assessment is selected. It
 * displays 4 tabs, "Overview", "Revision", "Assessment" and "Follow Up", each showing information
 * (and possibly further tabs) for the assessment and current group.
 */

export interface AssessmentMarksheetViewProps {
  viewingAll?: boolean;
  assessment: Assessment;
  students: Student[];
  currentGroup: Group | YearGroup;
  groups: Group[];
  fixedTab?: AssessmentTabs;

  children: ({ content }: { content: ReactNode }) => ReactNode;
}

export const AssessmentLandingView = ({
  viewingAll,
  assessment,
  students,
  currentGroup,
  groups,
  fixedTab,
  children,
}: AssessmentMarksheetViewProps) => {
  const { loadingComponent, schoolId } = useAssessmentContext();
  const printMode = window.location.href.indexOf('printview') !== -1; // Whether print mode is enabled.

  // Fetch Data
  const topicSummariesMap = useTopicSummariesMap();
  const sortedStudentsInYearGroup = students ? students.map(s => s.studentId) : undefined;

  const sortedStudents = useMemo(
    () =>
      students.sort((a, b) => {
        const studentA = `${a.familyName}, ${a.givenName}`;
        const studentB = `${b.familyName}, ${b.givenName}`;
        return SortDirection.Ascending * studentA.localeCompare(studentB);
      }),
    [students],
  );

  // Load assessments for all students in the yeargroup. We'll then filter these so we only display
  // rows for assessments for students in the current group.
  const studentAssessmentsQuery = useStudentAssessments(assessment.name, sortedStudentsInYearGroup);
  const batchStudentAssessmentQuery = useBatchGetStudentAssessments(
    {
      schoolName: `schools/${schoolId}`,
      assessmentNames: [assessment.name],
      studentIds: sortedStudentsInYearGroup ?? [],
    },
    {
      enabled: !!sortedStudentsInYearGroup,
      select: data =>
        data.studentAssessments.reduce(
          (obj, s) => {
            if (obj[s.studentId] === undefined) {
              obj[s.studentId] = {
                [s.assessmentName]: s,
              };
            } else {
              obj[s.studentId][s.assessmentName] = s;
            }
            return obj;
          },
          {} as Record<string, Record<string, StudentAssessment>>,
        ),
    },
  );
  const groupMap = groups.reduce<Record<string, Group>>((acc, group) => {
    const groupID = group.name.split('/')[3];
    acc[groupID] = group;
    return acc;
  }, {});

  if (!currentGroup) {
    return <div>Select a group</div>;
  }

  if (studentAssessmentsQuery.isError || batchStudentAssessmentQuery.isError) {
    return <div>error</div>;
  }

  if (studentAssessmentsQuery.isInitialLoading || batchStudentAssessmentQuery.isInitialLoading) {
    return loadingComponent;
  }

  if (students.length === 0) {
    return <h1>Selected group contains no students</h1>;
  }

  const unfilteredStudentAssessments: StudentAssessment[] = Array.from(
    studentAssessmentsQuery.data?.values() ?? [],
  );

  // Filter student assessments to those for students in the current group.
  const studentAssessments = unfilteredStudentAssessments.filter(
    sa => !!students.find(st => st.studentId === sa.studentId),
  );

  const aggregatedScores = computeAggregatedScores(assessment, studentAssessments);
  const topicDisplayDetails = getTopicDisplayDetails(assessment.questions, topicSummariesMap);
  const studentPercentileRanks = computeStudentPercentileRanks(
    assessment,
    unfilteredStudentAssessments,
  );

  let content = (
    <AssessmentTab
      aggregatedScores={aggregatedScores}
      assessment={assessment}
      groupMap={groupMap}
      studentAssessments={studentAssessments}
      studentsInGroup={sortedStudents}
      topicDisplayDetails={topicDisplayDetails}
      studentPercentileRanks={studentPercentileRanks}
      topicSummariesMap={topicSummariesMap}
      currentGroup={currentGroup}
      viewingAll={!!viewingAll}
      assessmentGroupStudentAssessments={batchStudentAssessmentQuery.data}
      fixedTab={fixedTab}
    />
  );

  if (printMode) {
    content = (
      // <AssessmentPrintView
      //   aggregatedScores={aggregatedScores}
      //   assessment={assessment}
      //   groupMap={groupMap}
      //   studentAssessments={studentAssessments}
      //   studentsInGroup={students}
      //   topicDisplayDetails={topicDisplayDetails}
      // />
      <>Print mode</>
    );
  }

  return children({ content });
};

import classNames from 'classnames';
import { forwardRef, PropsWithChildren, ReactNode, useMemo } from 'react';

import { Stack } from '../components/Stack';
import { TextElement } from '../elements/TextElement';
import styles from '../question/SparxQuestion.module.css';
import { IGroupElement } from '../question/types';

interface AnswerContentProps {
  groupElement: IGroupElement;
  keypad: ReactNode;
}

export const AnswerContent = forwardRef<HTMLDivElement, PropsWithChildren<AnswerContentProps>>(
  ({ groupElement, children, keypad }, forwardedRef) => {
    const style = groupElement.style;

    // Determine if to display this content with inline text input style.
    // This styling will make text inline so it can overflow with the text inputs.
    const inlineTextInput = useMemo(() => isInlineTextInput(groupElement), [groupElement]);

    const content =
      style === 'matrix-static' ? (
        <div
          className={styles.MatrixGroup}
          style={{
            gridTemplateColumns: `repeat(${groupElement.columns || 1}, 1fr)`,
          }}
          ref={forwardedRef}
        >
          {children}
        </div>
      ) : (
        <div
          className={classNames({
            [styles.FractionAnswerContent]: style === 'fraction',
          })}
          ref={forwardedRef}
        >
          <Stack
            dir={style === 'fraction' || style === 'vector' ? 'vertical' : 'horizontal'}
            className={classNames({
              [styles.VectorGroup]: style === 'vector',
              [styles.InlineTextGroup]: inlineTextInput,
            })}
            dataTag="answer-content"
          >
            {children}
          </Stack>
        </div>
      );

    return (
      <Stack>
        {groupElement.label && (
          <TextElement
            element={{
              element: 'text',
              text: groupElement.label,
            }}
          />
        )}
        {content}
        {keypad}
      </Stack>
    );
  },
);
AnswerContent.displayName = 'AnswerContent';

/**
 * We can use the InlineTextInput style if the group element contains only text fields and
 * text elements.
 */
const isInlineTextInput = (element: IGroupElement) => {
  let hasTextField = false;
  for (const el of element.content) {
    switch (el.element) {
      case 'text-field':
        hasTextField = true;
        break;
      case 'text':
        break; // Ignore
      default:
        return false; // Unsupported
    }
  }
  return hasTextField && element.content.length > 1;
};

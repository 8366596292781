import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import styles from 'components/matchtable/MatchTable.module.css';
import { formatDateOfBirth } from 'components/matchtable/utils';
import { useState } from 'react';
import Select from 'react-select';

export const StudentSearch = ({
  students,
  setStudent,
  defaultStudentId,
  autoFocus,
}: {
  students: Student[];
  setStudent: (s: Student) => void;
  defaultStudentId?: string;
  autoFocus?: boolean;
}) => {
  const [studentSearchValue, setStudentSearchValue] = useState<string>('');
  const [defaultStudent] = useState(students.find(g => g.studentId === defaultStudentId));

  return (
    <Select
      inputValue={studentSearchValue}
      onInputChange={v => setStudentSearchValue(v)}
      onBlur={() => setStudentSearchValue('')}
      value={defaultStudent}
      placeholder="Search for a student name"
      classNames={{
        option: () => styles.SearchBoxOption,
        container: () => styles.SearchBoxContainer,
      }}
      autoFocus={autoFocus}
      onChange={(s: Student | string | null) => {
        // Only allow known students to be set
        if (s && typeof s !== 'string') {
          setStudent(s);
          setStudentSearchValue('');
        }
      }}
      getOptionLabel={(o: Student) =>
        `${o.givenName} ${o.familyName} (${formatDateOfBirth(o.dateOfBirth)})`
      }
      options={students}
    />
  );
};

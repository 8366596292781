import {
  faArrowRight,
  faCheck,
  faChevronDown,
  faChevronUp,
  faHourglass,
  faPencil,
  faTimes,
  faWandMagicSparkles,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useMutation } from '@tanstack/react-query';
import { getSchoolID } from 'api/auth';
import { sittingsClient } from 'api/clients';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { IconButton } from 'components/button/IconButton';
import { SittingNameCell } from 'components/matchtable/Cells';
import styles from 'components/matchtable/MatchTable.module.css';
import { SelectStudentLink } from 'components/matchtable/SelectStudentLink';
import {
  calculateSuggestedLinks,
  formatDateOfBirth,
  GuestParticipantMatch,
  Link,
  SittingLookup,
} from 'components/matchtable/utils';
import { useMemo, useState } from 'react';
import { getStudentGroupTypeForAssessment } from 'utils/assessments';
import { MISStatusMessage } from 'views/teacher/assessmentsview/AssessmentHomeView';

export const MatchGuestTable = ({
  sittings,
  assessment,
  participants,
  students,
  studentLookup,
  onSave,
}: {
  sittings?: SittingLookup;
  assessment: Assessment;
  participants: GuestParticipantMatch[];
  students: Student[];
  studentLookup: Record<string, Student>;
  onSave?: () => Promise<unknown>;
}) => {
  const assessmentGroupType = getStudentGroupTypeForAssessment(assessment);

  const [showMatched, setShowMatched] = useState(false);
  const [editingRow, setEditingRow] = useState<string | null>(null);
  const [newLinks, setNewLinks] = useState<Record<string, Link | undefined>>(() =>
    calculateSuggestedLinks(participants, students),
  );
  const updateLink = (participantId: string, link: Link | undefined) =>
    setNewLinks(links => ({ ...links, [participantId]: link }));

  const onEdit = (close?: boolean) => setEditingRow(close ? null : 'edit');

  const { mutateAsync: save, isLoading } = useMutation({
    mutationFn: async () =>
      sittingsClient.linkStudents({
        schoolName: `schools/${await getSchoolID()}`,
        links: Object.entries(newLinks)
          .filter(Boolean)
          .map(([from, to]) => ({
            subject: from,
            studentSubject: to?.studentId ? `student/${to?.studentId}` : '',
          })),
      }).response,
    onMutate: () => {
      setEditingRow(null);
    },
    onSuccess: async () => {
      await onSave?.();
      // for (const sittingName of sittingNames) {
      //   updateSittingParticipantStudents(sittingName, data.students);
      // }
      setNewLinks({});
    },
  });

  const sortedParticipants = useMemo(
    () =>
      participants.sort(
        (a, b) =>
          (a.resolvedStudentId ? 1 : 0) - (b.resolvedStudentId ? 1 : 0) ||
          a.enteredDetails.givenName.localeCompare(b.enteredDetails.givenName) ||
          a.enteredDetails.familyName.localeCompare(b.enteredDetails.familyName),
      ),
    [participants],
  );

  const unmatchedRowCount = sortedParticipants.filter(p => !p.matchedDetails).length;
  const matchedRowCount = sortedParticipants.filter(p => p.matchedDetails).length;

  const table = (
    <table className={styles.Table}>
      <thead>
        <tr>
          <th>Group name</th>
          <th style={{ width: '30%' }} colSpan={2}>
            Details entered by student
          </th>
          <th style={{ width: 50 }} />
          <th style={{ width: '50%' }}>Matched student</th>
          <th colSpan={2}>Status</th>
        </tr>
      </thead>
      <tbody>
        {unmatchedRowCount === 0 && (
          <tr>
            <td colSpan={7} className={styles.NoRows}>
              There are no outstanding guest students to match
            </td>
          </tr>
        )}
        {sortedParticipants
          .filter(p => (showMatched ? true : !p.matchedDetails))
          .map(p => {
            const subject = p.participant.participantSubject;
            const proposed = newLinks[subject];
            const existing = p.resolvedStudentId ? { studentId: p.resolvedStudentId } : undefined;
            const link: Link | undefined = proposed || existing;

            const student = link && studentLookup[link.studentId];
            const proposedStudent = proposed && studentLookup[proposed.studentId];

            let className = '';
            let icon = faWarning;
            let message = 'No match found';
            if (proposed?.fuzzy) {
              message = 'Suggested match';
              icon = faWandMagicSparkles;
              className = styles.RowPending;
            } else if (proposed) {
              message = existing
                ? !proposed.studentId
                  ? 'Unsaved (unmatched)'
                  : 'Unsaved (new match)'
                : 'Unsaved (new match)';
              icon = faHourglass;
              className = styles.RowPending;
            } else if (existing) {
              message = 'Successful match';
              icon = faCheck;
              className = styles.RowSuccess;
            }

            const sitting = sittings?.[p.participant.sittingName];

            return (
              <tr key={p.participant.participantSubject} className={className}>
                <SittingNameCell sitting={sitting} />
                <td>
                  {p.enteredDetails.givenName} {p.enteredDetails.familyName}
                </td>
                <td className={styles.DateOfBirth}>
                  {formatDateOfBirth(p.enteredDetails.dateOfBirth)}
                </td>
                <td>
                  <FontAwesomeIcon icon={faArrowRight} />
                </td>
                <td>
                  {editingRow === subject ? (
                    <SelectStudentLink
                      link={link}
                      students={students}
                      existing={existing}
                      onSetLink={studentId => {
                        updateLink(subject, studentId !== undefined ? { studentId } : undefined);
                        onEdit(true);
                      }}
                      onClose={() => onEdit(true)}
                    />
                  ) : link?.fuzzy && proposedStudent ? (
                    <Stack spacing={2}>
                      <span className={classNames(styles.ProposedStudent, styles.MatchBox)}>
                        {proposedStudent.givenName} {proposedStudent.familyName}
                        <span className={styles.DateOfBirth}>
                          {formatDateOfBirth(proposedStudent.dateOfBirth)}
                        </span>
                      </span>
                      <IconButton
                        icon={<FontAwesomeIcon icon={faCheck} />}
                        size="xs"
                        colour="green"
                        onClick={() =>
                          updateLink(
                            subject,
                            proposedStudent ? { studentId: proposedStudent.studentId } : undefined,
                          )
                        }
                        aria-label="Accept"
                      />
                      <IconButton
                        icon={<FontAwesomeIcon icon={faTimes} />}
                        size="xs"
                        colour="red"
                        onClick={() => updateLink(subject, undefined)}
                        aria-label="Reject"
                      />
                    </Stack>
                  ) : (
                    <Stack
                      className={styles.EditRow}
                      spacing={3}
                      onClick={() => setEditingRow(subject)}
                    >
                      <span className={styles.MatchBox}>
                        {student ? (
                          <>
                            {student.givenName} {student.familyName}
                            <span className={styles.DateOfBirth}>
                              {formatDateOfBirth(student.dateOfBirth)}
                            </span>
                          </>
                        ) : existing ? (
                          <i>Unmatched</i>
                        ) : (
                          <span>Enter details</span>
                        )}
                      </span>
                      <FontAwesomeIcon icon={faPencil} />
                    </Stack>
                  )}
                </td>
                <td className={styles.StatusColumn}>
                  <FontAwesomeIcon icon={icon} fixedWidth={true} />
                  <span>{message}</span>
                </td>
              </tr>
            );
          })}

        {matchedRowCount > 0 && (
          <tr>
            <td
              colSpan={7}
              className={styles.ShowMoreButton}
              onClick={() => setShowMatched(!showMatched)}
            >
              <FontAwesomeIcon icon={showMatched ? faChevronUp : faChevronDown} />
              {showMatched ? 'Hide' : 'Show'} {matchedRowCount} matched student
              {matchedRowCount === 1 ? '' : 's'}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );

  const linkCount = Object.values(newLinks).filter(Boolean).length;
  const unresolvedLinks = useMemo(
    () => Object.values(newLinks).filter(l => l?.fuzzy).length,
    [newLinks],
  );

  let message = `Save ${linkCount} match${linkCount === 1 ? '' : 'es'}`;
  if (unresolvedLinks) {
    message = `Resolve ${unresolvedLinks} outstanding match${unresolvedLinks === 1 ? '' : 'es'} to save`;
  }

  const saveButton = (
    <Button
      className={styles.SaveButton}
      isDisabled={Object.values(newLinks).length === 0}
      onClick={() => save()}
      isLoading={isLoading}
      leftIcon={unresolvedLinks ? <FontAwesomeIcon icon={faWarning} /> : undefined}
    >
      {message}
    </Button>
  );

  return (
    <>
      <Stack spacing={4} direction="column">
        <MISStatusMessage groupType={assessmentGroupType} />
        <Stack spacing={4} style={{ marginBottom: 'var(--spx-unit-5)' }}>
          <p>
            Use this page to match entered student details with those in your Sparx system. You can
            approve or reject suggested matches or manually search for students. Once you&apos;re
            satisfied with the matches, save your results and click on the{' '}
            <i>&apos;Resolve and export results&apos;</i> tab to continue processing.
          </p>
          {saveButton}
        </Stack>
      </Stack>
      {table}
    </>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/specialquestions/v1/specialquestions.proto" (package "sparx.games.progress.specialquestions.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.games.progress.specialquestions.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * The progress, listed for each type of question (e.g. with keys
   * 'eggBaskets' or 'pegGrid')
   *
   * @generated from protobuf field: map<string, sparx.games.progress.specialquestions.v1.LevelData> levelDataPerQuestionType = 2;
   */
  levelDataPerQuestionType: {
    [key: string]: LevelData;
  };
}
/**
 * @generated from protobuf message sparx.games.progress.specialquestions.v1.LevelData
 */
export interface LevelData {
  /**
   * Number of times student has solved this question type
   *
   * @generated from protobuf field: uint32 playCount = 1;
   */
  playCount: number;
  /**
   * Level the student is on for this question type
   *
   * @generated from protobuf field: uint32 currentLevel = 2;
   */
  currentLevel: number;
  /**
   * How many times the student has completed this question type at this level
   * since changing level
   *
   * @generated from protobuf field: uint32 playCountThisLevel = 3;
   */
  playCountThisLevel: number;
  /**
   * How many times the student has asked for help with this question type
   * at this level since changing level
   *
   * @generated from protobuf field: uint32 helpRequestsThisLevel = 4;
   */
  helpRequestsThisLevel: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.progress.specialquestions.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'levelDataPerQuestionType',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => LevelData },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.specialquestions.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LevelData$Type extends MessageType<LevelData> {
  constructor() {
    super('sparx.games.progress.specialquestions.v1.LevelData', [
      { no: 1, name: 'playCount', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      {
        no: 2,
        name: 'currentLevel',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 3,
        name: 'playCountThisLevel',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 4,
        name: 'helpRequestsThisLevel',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.specialquestions.v1.LevelData
 */
export const LevelData = new LevelData$Type();

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reports/reportgen/v1/reader.proto" (package "sparx.reports.reportgen.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { ReaderReportGen } from './reader';
import type { GetSchoolsLeaderboardReportResponse } from './reader';
import type { GetSchoolsLeaderboardReportRequest } from './reader';
import type { GetInsightsReportResponse } from './reader';
import type { GetInsightsReportRequest } from './reader';
import type { GetAssessmentReportResponse } from './reader';
import type { GetAssessmentReportRequest } from './reader';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetActivityReportResponse } from './reader';
import type { GetActivityReportRequest } from './reader';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * APIs for Reader reports generated by ReportGen.
 *
 * Reports are file based and are served from an external source such as GCS.
 *
 * @generated from protobuf service sparx.reports.reportgen.v1.ReaderReportGen
 */
export interface IReaderReportGenClient {
  /**
   * GetActivityReport generates a report containing students' reading activity
   * on homeworks and in vocab, aggregated by week.
   *
   * @generated from protobuf rpc: GetActivityReport(sparx.reports.reportgen.v1.GetActivityReportRequest) returns (sparx.reports.reportgen.v1.GetActivityReportResponse);
   */
  getActivityReport(
    input: GetActivityReportRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivityReportRequest, GetActivityReportResponse>;
  /**
   * GetAssessmentReport generates a report containing students' reading
   * assessment results.
   *
   * @generated from protobuf rpc: GetAssessmentReport(sparx.reports.reportgen.v1.GetAssessmentReportRequest) returns (sparx.reports.reportgen.v1.GetAssessmentReportResponse);
   */
  getAssessmentReport(
    input: GetAssessmentReportRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentReportRequest, GetAssessmentReportResponse>;
  /**
   * GetInsightsReport generates a whole school insights report.
   *
   * @generated from protobuf rpc: GetInsightsReport(sparx.reports.reportgen.v1.GetInsightsReportRequest) returns (sparx.reports.reportgen.v1.GetInsightsReportResponse);
   */
  getInsightsReport(
    input: GetInsightsReportRequest,
    options?: RpcOptions,
  ): UnaryCall<GetInsightsReportRequest, GetInsightsReportResponse>;
  /**
   * GetSchoolsLeaderboardReport generates a report ranking schools on homework completion
   *
   * @generated from protobuf rpc: GetSchoolsLeaderboardReport(sparx.reports.reportgen.v1.GetSchoolsLeaderboardReportRequest) returns (sparx.reports.reportgen.v1.GetSchoolsLeaderboardReportResponse);
   */
  getSchoolsLeaderboardReport(
    input: GetSchoolsLeaderboardReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetSchoolsLeaderboardReportRequest,
    GetSchoolsLeaderboardReportResponse
  >;
}
/**
 * APIs for Reader reports generated by ReportGen.
 *
 * Reports are file based and are served from an external source such as GCS.
 *
 * @generated from protobuf service sparx.reports.reportgen.v1.ReaderReportGen
 */
export class ReaderReportGenClient
  implements IReaderReportGenClient, ServiceInfo
{
  typeName = ReaderReportGen.typeName;
  methods = ReaderReportGen.methods;
  options = ReaderReportGen.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetActivityReport generates a report containing students' reading activity
   * on homeworks and in vocab, aggregated by week.
   *
   * @generated from protobuf rpc: GetActivityReport(sparx.reports.reportgen.v1.GetActivityReportRequest) returns (sparx.reports.reportgen.v1.GetActivityReportResponse);
   */
  getActivityReport(
    input: GetActivityReportRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivityReportRequest, GetActivityReportResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetActivityReportRequest, GetActivityReportResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetAssessmentReport generates a report containing students' reading
   * assessment results.
   *
   * @generated from protobuf rpc: GetAssessmentReport(sparx.reports.reportgen.v1.GetAssessmentReportRequest) returns (sparx.reports.reportgen.v1.GetAssessmentReportResponse);
   */
  getAssessmentReport(
    input: GetAssessmentReportRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentReportRequest, GetAssessmentReportResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetAssessmentReportRequest,
      GetAssessmentReportResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GetInsightsReport generates a whole school insights report.
   *
   * @generated from protobuf rpc: GetInsightsReport(sparx.reports.reportgen.v1.GetInsightsReportRequest) returns (sparx.reports.reportgen.v1.GetInsightsReportResponse);
   */
  getInsightsReport(
    input: GetInsightsReportRequest,
    options?: RpcOptions,
  ): UnaryCall<GetInsightsReportRequest, GetInsightsReportResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetInsightsReportRequest, GetInsightsReportResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetSchoolsLeaderboardReport generates a report ranking schools on homework completion
   *
   * @generated from protobuf rpc: GetSchoolsLeaderboardReport(sparx.reports.reportgen.v1.GetSchoolsLeaderboardReportRequest) returns (sparx.reports.reportgen.v1.GetSchoolsLeaderboardReportResponse);
   */
  getSchoolsLeaderboardReport(
    input: GetSchoolsLeaderboardReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetSchoolsLeaderboardReportRequest,
    GetSchoolsLeaderboardReportResponse
  > {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetSchoolsLeaderboardReportRequest,
      GetSchoolsLeaderboardReportResponse
    >('unary', this._transport, method, opt, input);
  }
}

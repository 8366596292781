// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/v2/schools.proto" (package "sparx.school.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../google/protobuf/field_mask';
import { Product } from '../../types/product';
import { Date } from '../../../../google/type/date';
import { Timestamp } from '../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.school.v2.School
 */
export interface School {
  /**
   * The school's resource name.
   * `schools/{school_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The actual name of the school e.g. Springfield Elementary.
   * Maximum length 127 characters.
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * The time the school record was first created. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 11;
   */
  createTime?: Timestamp;
  /**
   * The time the school record was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;
  /**
   * The time the school was deleted. If a school is undeleted then this is set
   * back to nil, so a school is considered deleted (expired) if and only if
   * this is not nil, regardless of the value of undelete_time. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp delete_time = 4;
   */
  deleteTime?: Timestamp;
  /**
   * The time the school was undeleted. If a school is undeleted then the
   * delete_time is set to nil. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp undelete_time = 14;
   */
  undeleteTime?: Timestamp;
  /**
   * Location information for the school.
   *
   * @generated from protobuf field: sparx.school.v2.School.LocationData location = 5;
   */
  location?: School_LocationData;
  /**
   * The IANA time zone of the school.
   *
   * @generated from protobuf field: string time_zone = 6;
   */
  timeZone: string;
  /**
   * External identifiers for this school. e.g. DFE number
   *
   * @generated from protobuf field: map<string, string> external_ids = 7;
   */
  externalIds: {
    [key: string]: string;
  };
  /**
   * Annotations for services to store arbitrary data against the school.
   * Write behaviour is merge.
   *
   * @generated from protobuf field: map<string, string> annotations = 8;
   */
  annotations: {
    [key: string]: string;
  };
  /**
   * The date (year, month and day) of the start of the academic year
   * for this school.
   * Must be after all previous rollover dates.
   *
   * @generated from protobuf field: google.type.Date academic_year_start_date = 9;
   */
  academicYearStartDate?: Date;
  /**
   * The previous academic year start dates for the school, normally set
   * during the rollover process.
   * Must be strictly ascending.
   *
   * @generated from protobuf field: repeated google.type.Date previous_academic_year_start_dates = 13;
   */
  previousAcademicYearStartDates: Date[];
  /**
   * User inputted tags for the school. Always lower case and duplicates are
   * removed on save.
   *
   * @generated from protobuf field: repeated string tags = 10;
   */
  tags: string[];
  /**
   * Optional. The url prefix for the school. Must be a single domain segment.
   *
   * @generated from protobuf field: string url_prefix = 12;
   */
  urlPrefix: string;
  /**
   * The products enabled for this school
   *
   * @generated from protobuf field: repeated sparx.types.Product products = 15;
   */
  products: Product[];
  /**
   * When products were added or removed from the school. Read only.
   * If a product is currently enabled, then there will be an entry with when it
   * was enabled. If a product was previously enabled but then disabled, then
   * there will be an entry with when it was disabled.
   *
   * @generated from protobuf field: repeated sparx.school.v2.School.ProductChangeTime product_change_times = 16;
   */
  productChangeTimes: School_ProductChangeTime[];
}
/**
 * @generated from protobuf message sparx.school.v2.School.LocationData
 */
export interface School_LocationData {
  /**
   * Required. CLDR region code for the school.
   *
   * @generated from protobuf field: string region_code = 1;
   */
  regionCode: string;
  /**
   * Optional. Postal code (such as Post Code or Zip Code) of the address,
   * where appropriate.
   *
   * @generated from protobuf field: string postal_code = 2;
   */
  postalCode: string;
  /**
   * Optional. Generally refers to city/town.
   *
   * @generated from protobuf field: string locality = 3;
   */
  locality: string;
  /**
   * Optional. For example boroughs or districts.
   *
   * @generated from protobuf field: string sublocality = 4;
   */
  sublocality: string;
  /**
   * Unstructured address lines describing remaining address fields.
   * If unsure about any of the other address fields, they should be
   * added to address_lines.
   *
   * @generated from protobuf field: repeated string address_lines = 5;
   */
  addressLines: string[];
}
/**
 * @generated from protobuf message sparx.school.v2.School.ProductChangeTime
 */
export interface School_ProductChangeTime {
  /**
   * The product that was added or removed.
   *
   * @generated from protobuf field: sparx.types.Product product = 1;
   */
  product: Product;
  /**
   * When the product was most recently enabled for the school.
   * Not set if disabled_time is set.
   *
   * @generated from protobuf field: google.protobuf.Timestamp enabled_time = 2;
   */
  enabledTime?: Timestamp;
  /**
   * When the product was most recently disabled for the school.
   * Not set if enabled_time is set.
   *
   * @generated from protobuf field: google.protobuf.Timestamp disabled_time = 3;
   */
  disabledTime?: Timestamp;
}
/**
 * Request message for GetSchool
 *
 * @generated from protobuf message sparx.school.v2.GetSchoolRequest
 */
export interface GetSchoolRequest {
  /**
   * The name of the school
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.school.v2.ListSchoolsRequest
 */
export interface ListSchoolsRequest {
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Should be the
   * value of [ListSchoolsResponse.next_page_token] from a previous
   * request.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
  /**
   * Include soft deleted schools in the response
   *
   * @generated from protobuf field: bool show_deleted = 3;
   */
  showDeleted: boolean;
}
/**
 * @generated from protobuf message sparx.school.v2.ListSchoolsResponse
 */
export interface ListSchoolsResponse {
  /**
   * The schools
   *
   * @generated from protobuf field: repeated sparx.school.v2.School schools = 1;
   */
  schools: School[];
  /**
   * A token to retrieve the next page of results.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.school.v2.CreateSchoolRequest
 */
export interface CreateSchoolRequest {
  /**
   * The school to create.
   *
   * @generated from protobuf field: sparx.school.v2.School school = 1;
   */
  school?: School;
}
/**
 * @generated from protobuf message sparx.school.v2.UpdateSchoolRequest
 */
export interface UpdateSchoolRequest {
  /**
   * The school to update.
   * The school's name field is used to identify the school to update.
   *
   * @generated from protobuf field: sparx.school.v2.School school = 1;
   */
  school?: School;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
  /**
   * If true, the server will not return an error if the school to be updated is
   * deleted.
   *
   * @generated from protobuf field: bool allow_deleted = 3;
   */
  allowDeleted: boolean;
}
/**
 * @generated from protobuf message sparx.school.v2.DeleteSchoolRequest
 */
export interface DeleteSchoolRequest {
  /**
   * The name of the school to delete.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.school.v2.UndeleteSchoolRequest
 */
export interface UndeleteSchoolRequest {
  /**
   * The name of the deleted school.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class School$Type extends MessageType<School> {
  constructor() {
    super(
      'sparx.school.v2.School',
      [
        {
          no: 1,
          name: 'name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { key: true } },
        },
        {
          no: 2,
          name: 'display_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 11, name: 'create_time', kind: 'message', T: () => Timestamp },
        { no: 3, name: 'update_time', kind: 'message', T: () => Timestamp },
        { no: 4, name: 'delete_time', kind: 'message', T: () => Timestamp },
        { no: 14, name: 'undelete_time', kind: 'message', T: () => Timestamp },
        {
          no: 5,
          name: 'location',
          kind: 'message',
          T: () => School_LocationData,
        },
        {
          no: 6,
          name: 'time_zone',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 7,
          name: 'external_ids',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        },
        {
          no: 8,
          name: 'annotations',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        },
        {
          no: 9,
          name: 'academic_year_start_date',
          kind: 'message',
          T: () => Date,
        },
        {
          no: 13,
          name: 'previous_academic_year_start_dates',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => Date,
        },
        {
          no: 10,
          name: 'tags',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 12,
          name: 'url_prefix',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 15,
          name: 'products',
          kind: 'enum',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => ['sparx.types.Product', Product],
        },
        {
          no: 16,
          name: 'product_change_times',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => School_ProductChangeTime,
        },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'schools_v2',
          partitionColumn: 'update_time',
          additionalColumns: [
            {
              name: 'school_id',
              mode: 'KEY',
              doc: 'The school id as parsed from the name',
            },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.School
 */
export const School = new School$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School_LocationData$Type extends MessageType<School_LocationData> {
  constructor() {
    super(
      'sparx.school.v2.School.LocationData',
      [
        {
          no: 1,
          name: 'region_code',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'postal_code',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: 'locality', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 4,
          name: 'sublocality',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 5,
          name: 'address_lines',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          additionalColumns: [
            {
              name: 'region_name',
              doc: 'The CLDR name for the region for the school.',
            },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.School.LocationData
 */
export const School_LocationData = new School_LocationData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class School_ProductChangeTime$Type extends MessageType<School_ProductChangeTime> {
  constructor() {
    super('sparx.school.v2.School.ProductChangeTime', [
      {
        no: 1,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
      { no: 2, name: 'enabled_time', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'disabled_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.School.ProductChangeTime
 */
export const School_ProductChangeTime = new School_ProductChangeTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolRequest$Type extends MessageType<GetSchoolRequest> {
  constructor() {
    super('sparx.school.v2.GetSchoolRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.GetSchoolRequest
 */
export const GetSchoolRequest = new GetSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolsRequest$Type extends MessageType<ListSchoolsRequest> {
  constructor() {
    super('sparx.school.v2.ListSchoolsRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'show_deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.ListSchoolsRequest
 */
export const ListSchoolsRequest = new ListSchoolsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolsResponse$Type extends MessageType<ListSchoolsResponse> {
  constructor() {
    super('sparx.school.v2.ListSchoolsResponse', [
      {
        no: 1,
        name: 'schools',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => School,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.ListSchoolsResponse
 */
export const ListSchoolsResponse = new ListSchoolsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSchoolRequest$Type extends MessageType<CreateSchoolRequest> {
  constructor() {
    super('sparx.school.v2.CreateSchoolRequest', [
      { no: 1, name: 'school', kind: 'message', T: () => School },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.CreateSchoolRequest
 */
export const CreateSchoolRequest = new CreateSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolRequest$Type extends MessageType<UpdateSchoolRequest> {
  constructor() {
    super('sparx.school.v2.UpdateSchoolRequest', [
      { no: 1, name: 'school', kind: 'message', T: () => School },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      {
        no: 3,
        name: 'allow_deleted',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.UpdateSchoolRequest
 */
export const UpdateSchoolRequest = new UpdateSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSchoolRequest$Type extends MessageType<DeleteSchoolRequest> {
  constructor() {
    super('sparx.school.v2.DeleteSchoolRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.DeleteSchoolRequest
 */
export const DeleteSchoolRequest = new DeleteSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UndeleteSchoolRequest$Type extends MessageType<UndeleteSchoolRequest> {
  constructor() {
    super('sparx.school.v2.UndeleteSchoolRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.UndeleteSchoolRequest
 */
export const UndeleteSchoolRequest = new UndeleteSchoolRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.v2.SchoolsService
 */
export const SchoolsService = new ServiceType(
  'sparx.school.v2.SchoolsService',
  [
    {
      name: 'GetSchool',
      options: {
        'google.api.http': { get: '/v2/{name=schools/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:school',
          domain: '{name}',
        },
      },
      I: GetSchoolRequest,
      O: School,
    },
    {
      name: 'ListSchools',
      options: {
        'google.api.http': { get: '/v2/schools' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:school',
          domain: 'schools',
        },
      },
      I: ListSchoolsRequest,
      O: ListSchoolsResponse,
    },
    {
      name: 'CreateSchool',
      options: {
        'google.api.http': { post: '/v2/schools', body: 'school' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school',
          domain: 'schools',
        },
      },
      I: CreateSchoolRequest,
      O: School,
    },
    {
      name: 'UpdateSchool',
      options: {
        'google.api.http': {
          patch: '/v2/{school.name=schools/*}',
          body: 'school',
        },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school',
          domain: '{school.name}',
        },
      },
      I: UpdateSchoolRequest,
      O: School,
    },
    {
      name: 'DeleteSchool',
      options: {
        'google.api.http': { delete: '/v2/{name=schools/*}' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school',
          domain: '{name}',
        },
      },
      I: DeleteSchoolRequest,
      O: School,
    },
    {
      name: 'UndeleteSchool',
      options: {
        'google.api.http': { post: '/v2/{name=schools/*}:undelete', body: '*' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school',
          domain: '{name}',
        },
      },
      I: UndeleteSchoolRequest,
      O: School,
    },
  ],
);

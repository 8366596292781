import { UserInteractionEvent } from '@sparx/api/genproto/apis/uievents/uievents';
import { createContext, useContext } from 'react';

export const AnalyticsContext = createContext<
  ((event: UserInteractionEventFields) => void) | undefined
>(undefined);

// useLibAnalytics is a hook that returns the analytics function from the context.
// If you're importing this, you may want to check if theres an app specific version that should be
// used instead.
export const useLibAnalytics = () => {
  const analytics = useContext(AnalyticsContext);
  if (!analytics) {
    throw Error('Used analytics outside an analytics provider');
  }
  return analytics;
};

type DefaultFields =
  | 'application'
  | 'schoolId'
  | 'userId'
  | 'sessionId'
  | 'connectionId'
  | 'version'
  | 'serverTimestamp';

type AutomaticFields = 'timestamp' | 'eventIndex' | 'page';

export type UserInteractionEventDefaults = Pick<UserInteractionEvent, DefaultFields>;
export type UserInteractionEventFieldsWithLabelsRequired = Omit<
  UserInteractionEvent,
  DefaultFields | AutomaticFields
>;
export type UserInteractionEventFields = Omit<
  UserInteractionEventFieldsWithLabelsRequired,
  'labels'
> & {
  labels?: Record<string, string | number | boolean>;
};

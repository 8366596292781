// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/targets/v1/targets.proto" (package "sparx.games.progress.targets.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
//
// Contains the data for the user's progress in assessments (i.e. Hundred Club)
// This is the data used to calculate 5 times tables targets for the user
//
// This data is generated when the user answers questions and completes
// sessions in Hundred Club.
//
// The data is requested by all games that merge targets into their quizzes
// (currently all games)
//
// The data includes:
// - The name of the highest club that has been completed
// - If the user has completed the "first pass" assessment
// - If the user has completed the first batch of questions in the
// first pass
// - When the student last did a Hundred Club assessment
//
// - For each question, the following data for each time it was answered
// (indexed by timestamp of that assessment):
// - If the answer was correct
// - How long the last answer took
// - The total time spent on this question (if it was asked twice)

/**
 * @generated from protobuf message sparx.games.progress.targets.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: string lastClearedClub = 1;
   */
  lastClearedClub: string;
  /**
   * @generated from protobuf field: bool hasClearedFirstPass = 2;
   */
  hasClearedFirstPass: boolean;
  /**
   * @generated from protobuf field: bool advancedPastFirstClub = 3;
   */
  advancedPastFirstClub: boolean;
  /**
   * @generated from protobuf field: map<string, sparx.games.progress.targets.v1.History> questionHistory = 4;
   */
  questionHistory: {
    [key: string]: History;
  };
  /**
   * @generated from protobuf field: uint64 lastAssessmentTime = 5;
   */
  lastAssessmentTime: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 6;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * Used for discovery - stores timestamped events for 100 Club, e.g.
   * started quiz, ended quiz, including current club, promotion, demotion,
   * and current set of targets
   *
   * @generated from protobuf field: map<string, sparx.games.progress.targets.v1.EventLog> eventLogs = 501;
   */
  eventLogs: {
    [key: string]: EventLog;
  };
  /**
   * Used for discovery - records the list of targets given to a student
   * last time the application requested targets (i.e. the last ones calculated
   * or, if fixed targets are on, the fixed targets)
   *
   * @generated from protobuf field: repeated string activeTargets = 600;
   */
  activeTargets: string[];
}
/**
 * @generated from protobuf message sparx.games.progress.targets.v1.History
 */
export interface History {
  /**
   * @generated from protobuf field: map<string, sparx.games.progress.targets.v1.HistoryEntry> entries = 1;
   */
  entries: {
    [key: string]: HistoryEntry;
  };
}
/**
 * @generated from protobuf message sparx.games.progress.targets.v1.HistoryEntry
 */
export interface HistoryEntry {
  /**
   * @generated from protobuf field: bool correct = 1;
   */
  correct: boolean;
  /**
   * @generated from protobuf field: float answerTime = 2;
   */
  answerTime: number;
  /**
   * @generated from protobuf field: float compoundAnswerTime = 3;
   */
  compoundAnswerTime: number;
  /**
   * isBadData is set to true if the student's response has been detected as
   * "in bad faith" (e.g. mashing keypad or letting device time out)
   *
   * @generated from protobuf field: bool isBadData = 4;
   */
  isBadData: boolean;
}
/**
 * @generated from protobuf message sparx.games.progress.targets.v1.EventLog
 */
export interface EventLog {
  /**
   * @generated from protobuf field: string event = 1;
   */
  event: string;
  /**
   * @generated from protobuf field: string currentClub = 2;
   */
  currentClub: string;
  /**
   * @generated from protobuf field: string promotionState = 3;
   */
  promotionState: string;
  /**
   * @generated from protobuf field: repeated string targets = 4;
   */
  targets: string[];
  /**
   * @generated from protobuf field: float quizDuration = 5;
   */
  quizDuration: number;
  /**
   * @generated from protobuf field: float bestQuizDuration = 6;
   */
  bestQuizDuration: number;
  /**
   * @generated from protobuf field: uint64 totalCorrect = 7;
   */
  totalCorrect: number;
  /**
   * @generated from protobuf field: uint64 totalAnswered = 8;
   */
  totalAnswered: number;
  /**
   * @generated from protobuf field: uint64 bestTotalCorrect = 9;
   */
  bestTotalCorrect: number;
  /**
   * @generated from protobuf field: bool hasClearedFirstPass = 10;
   */
  hasClearedFirstPass: boolean;
  /**
   * @generated from protobuf field: bool wasFirstPass = 11;
   */
  wasFirstPass: boolean;
  /**
   * @generated from protobuf field: uint64 badDataCount = 12;
   */
  badDataCount: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.progress.targets.v1.Store', [
      {
        no: 1,
        name: 'lastClearedClub',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'hasClearedFirstPass',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'advancedPastFirstClub',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'questionHistory',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => History },
      },
      {
        no: 5,
        name: 'lastAssessmentTime',
        kind: 'scalar',
        T: 4 /*ScalarType.UINT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 6,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 501,
        name: 'eventLogs',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => EventLog },
      },
      {
        no: 600,
        name: 'activeTargets',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.targets.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class History$Type extends MessageType<History> {
  constructor() {
    super('sparx.games.progress.targets.v1.History', [
      {
        no: 1,
        name: 'entries',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => HistoryEntry },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.targets.v1.History
 */
export const History = new History$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryEntry$Type extends MessageType<HistoryEntry> {
  constructor() {
    super('sparx.games.progress.targets.v1.HistoryEntry', [
      { no: 1, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'answerTime', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 3,
        name: 'compoundAnswerTime',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 4, name: 'isBadData', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.targets.v1.HistoryEntry
 */
export const HistoryEntry = new HistoryEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventLog$Type extends MessageType<EventLog> {
  constructor() {
    super('sparx.games.progress.targets.v1.EventLog', [
      { no: 1, name: 'event', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'currentClub',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'promotionState',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'targets',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'quizDuration',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 6,
        name: 'bestQuizDuration',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 7,
        name: 'totalCorrect',
        kind: 'scalar',
        T: 4 /*ScalarType.UINT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 8,
        name: 'totalAnswered',
        kind: 'scalar',
        T: 4 /*ScalarType.UINT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 9,
        name: 'bestTotalCorrect',
        kind: 'scalar',
        T: 4 /*ScalarType.UINT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 10,
        name: 'hasClearedFirstPass',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 11,
        name: 'wasFirstPass',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 12,
        name: 'badDataCount',
        kind: 'scalar',
        T: 4 /*ScalarType.UINT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.targets.v1.EventLog
 */
export const EventLog = new EventLog$Type();

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/indy/common/common.proto" (package "sparx.indy.common", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.indy.common.NumeriseCorrectAnswerCountsOverTime
 */
export interface NumeriseCorrectAnswerCountsOverTime {
  /**
   * @generated from protobuf field: int32 week = 1;
   */
  week: number;
  /**
   * @generated from protobuf field: int32 month = 2;
   */
  month: number;
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseCorrectAnswerCounts
 */
export interface NumeriseCorrectAnswerCounts {
  /**
   * @generated from protobuf field: int32 allTime = 1;
   */
  allTime: number;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseCorrectAnswerCountsOverTime currentCounts = 2;
   */
  currentCounts?: NumeriseCorrectAnswerCountsOverTime;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseCorrectAnswerCountsOverTime prevCounts = 3;
   */
  prevCounts?: NumeriseCorrectAnswerCountsOverTime;
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseLessonCompleteCountsOverTime
 */
export interface NumeriseLessonCompleteCountsOverTime {
  /**
   * @generated from protobuf field: int32 week = 1;
   */
  week: number;
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseLessonCompleteCounts
 */
export interface NumeriseLessonCompleteCounts {
  /**
   * @generated from protobuf field: map<string, int32> courseAllTime = 1;
   */
  courseAllTime: {
    [key: string]: number;
  };
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseLessonCompleteCountsOverTime currentCounts = 2;
   */
  currentCounts?: NumeriseLessonCompleteCountsOverTime;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseLessonCompleteCountsOverTime prevCounts = 3;
   */
  prevCounts?: NumeriseLessonCompleteCountsOverTime;
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseTimeTakenForWeek
 */
export interface NumeriseTimeTakenForWeek {
  /**
   * @generated from protobuf field: float timeInSeconds = 1;
   */
  timeInSeconds: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp monday = 2;
   */
  monday?: Timestamp;
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseTimeTaken
 */
export interface NumeriseTimeTaken {
  /**
   * @generated from protobuf field: repeated sparx.indy.common.NumeriseTimeTakenForWeek timeTakenForWeek = 1;
   */
  timeTakenForWeek: NumeriseTimeTakenForWeek[];
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseCourseCompleted
 */
export interface NumeriseCourseCompleted {
  /**
   * @generated from protobuf field: string courseName = 1;
   */
  courseName: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp completionDate = 2;
   */
  completionDate?: Timestamp;
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseCoursesCompleted
 */
export interface NumeriseCoursesCompleted {
  /**
   * @generated from protobuf field: repeated sparx.indy.common.NumeriseCourseCompleted coursesCompleted = 1;
   */
  coursesCompleted: NumeriseCourseCompleted[];
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseLessonWorkedOn
 */
export interface NumeriseLessonWorkedOn {
  /**
   * @generated from protobuf field: string partName = 1;
   */
  partName: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastWorkedOn = 2;
   */
  lastWorkedOn?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp completionDate = 3;
   */
  completionDate?: Timestamp;
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseLessonsWorkedOn
 */
export interface NumeriseLessonsWorkedOn {
  /**
   * @generated from protobuf field: repeated sparx.indy.common.NumeriseLessonWorkedOn lessonsWorkedOn = 1;
   */
  lessonsWorkedOn: NumeriseLessonWorkedOn[];
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseStats
 */
export interface NumeriseStats {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseCorrectAnswerCounts correctAnswerCounts = 2;
   */
  correctAnswerCounts?: NumeriseCorrectAnswerCounts;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseLessonCompleteCounts lessonCompleteCounts = 3;
   */
  lessonCompleteCounts?: NumeriseLessonCompleteCounts;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseTimeTaken timeTaken = 4;
   */
  timeTaken?: NumeriseTimeTaken;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseCoursesCompleted coursesCompleted = 5;
   */
  coursesCompleted?: NumeriseCoursesCompleted;
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseLessonsWorkedOn lessonsWorkedOn = 6;
   */
  lessonsWorkedOn?: NumeriseLessonsWorkedOn;
}
/**
 * @generated from protobuf message sparx.indy.common.NumeriseSummaryStats
 */
export interface NumeriseSummaryStats {
  /**
   * @generated from protobuf field: sparx.indy.common.NumeriseCorrectAnswerCounts correctAnswerCounts = 1;
   */
  correctAnswerCounts?: NumeriseCorrectAnswerCounts;
}
/**
 * TODO: deprecated remove this
 *
 * @generated from protobuf message sparx.indy.common.NumeriseLessonsCompletedForWeek
 */
export interface NumeriseLessonsCompletedForWeek {
  /**
   * @generated from protobuf field: int32 count = 1;
   */
  count: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp monday = 2;
   */
  monday?: Timestamp;
}
/**
 * TODO: deprecated remove this
 *
 * @generated from protobuf message sparx.indy.common.NumeriseCorrectAnswersForWeek
 */
export interface NumeriseCorrectAnswersForWeek {
  /**
   * @generated from protobuf field: int32 count = 1;
   */
  count: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp monday = 2;
   */
  monday?: Timestamp;
}
/**
 * NumeriseCorrectAnswers includes the count of correct answers given in a time bucket
 *
 * @generated from protobuf message sparx.indy.common.NumeriseCorrectAnswers
 */
export interface NumeriseCorrectAnswers {
  /**
   * The number of correct answers
   *
   * @generated from protobuf field: int32 count = 1;
   */
  count: number;
  /**
   * The UT start of the time bucket in which the correct answers were given
   *
   * @generated from protobuf field: google.protobuf.Timestamp bucket_start = 2;
   */
  bucketStart?: Timestamp;
  /**
   * The UTC end of the time bucket in which the correct answers were given
   *
   * @generated from protobuf field: google.protobuf.Timestamp bucket_end = 3;
   */
  bucketEnd?: Timestamp;
}
/**
 * Indy specific level
 * Needs to map to a stream string to match up with content.v2 for now,
 * though content.v2 may change.
 *
 * @generated from protobuf enum sparx.indy.common.IndyLevel
 */
export enum IndyLevel {
  /**
   * @generated from protobuf enum value: INDY_LEVEL_UNSPECIFIED = 0;
   */
  INDY_LEVEL_UNSPECIFIED = 0,
  /**
   * Original KS3 Levels
   *
   * @generated from protobuf enum value: INDY_LEVEL_A = 1;
   */
  INDY_LEVEL_A = 1,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_B = 2;
   */
  INDY_LEVEL_B = 2,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_C = 3;
   */
  INDY_LEVEL_C = 3,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_D = 4;
   */
  INDY_LEVEL_D = 4,
  /**
   * Original GCSE Levels
   *
   * @generated from protobuf enum value: INDY_LEVEL_F = 5;
   */
  INDY_LEVEL_F = 5,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_F_PLUS = 6;
   */
  INDY_LEVEL_F_PLUS = 6,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_FH = 7;
   */
  INDY_LEVEL_FH = 7,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_H = 8;
   */
  INDY_LEVEL_H = 8,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_H_PLUS = 9;
   */
  INDY_LEVEL_H_PLUS = 9,
  /**
   * Year 6 Levels
   *
   * @generated from protobuf enum value: INDY_LEVEL_YEAR6_A = 10;
   */
  INDY_LEVEL_YEAR6_A = 10,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_YEAR6_B = 11;
   */
  INDY_LEVEL_YEAR6_B = 11,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_YEAR6_C = 12;
   */
  INDY_LEVEL_YEAR6_C = 12,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_YEAR6_D = 13;
   */
  INDY_LEVEL_YEAR6_D = 13,
  /**
   * Cambridge IGCSE levels
   *
   * @generated from protobuf enum value: INDY_LEVEL_CAMBRIDGE_IGCSE_F = 14;
   */
  INDY_LEVEL_CAMBRIDGE_IGCSE_F = 14,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_CAMBRIDGE_IGCSE_F_PLUS = 15;
   */
  INDY_LEVEL_CAMBRIDGE_IGCSE_F_PLUS = 15,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_CAMBRIDGE_IGCSE_FH = 16;
   */
  INDY_LEVEL_CAMBRIDGE_IGCSE_FH = 16,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_CAMBRIDGE_IGCSE_H = 17;
   */
  INDY_LEVEL_CAMBRIDGE_IGCSE_H = 17,
  /**
   * @generated from protobuf enum value: INDY_LEVEL_CAMBRIDGE_IGCSE_H_PLUS = 18;
   */
  INDY_LEVEL_CAMBRIDGE_IGCSE_H_PLUS = 18,
}
/**
 * @generated from protobuf enum sparx.indy.common.IndyPackageType
 */
export enum IndyPackageType {
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_UNSPECIFIED = 0;
   */
  INDY_PACKAGE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_LU_CORE = 1;
   */
  INDY_PACKAGE_LU_CORE = 1,
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_LU_EXT = 2;
   */
  INDY_PACKAGE_LU_EXT = 2,
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_LESSON_CHECK = 3;
   */
  INDY_PACKAGE_LESSON_CHECK = 3,
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_DAILY_CHALLENGE = 4;
   */
  INDY_PACKAGE_DAILY_CHALLENGE = 4,
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_ANONYMOUS = 5;
   */
  INDY_PACKAGE_ANONYMOUS = 5,
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_REVISION = 6;
   */
  INDY_PACKAGE_REVISION = 6,
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_WEEKLY_WORKOUT = 7;
   */
  INDY_PACKAGE_WEEKLY_WORKOUT = 7,
  /**
   * @generated from protobuf enum value: INDY_PACKAGE_LESSON_REVISION_V2 = 8;
   */
  INDY_PACKAGE_LESSON_REVISION_V2 = 8,
}
/**
 * Courses are specific to a region (through display names and descriptions)
 *
 * @generated from protobuf enum sparx.indy.common.CourseRegion
 */
export enum CourseRegion {
  /**
   * Unspecified
   *
   * @generated from protobuf enum value: COURSE_REGION_UNSPECIFIED = 0;
   */
  COURSE_REGION_UNSPECIFIED = 0,
  /**
   * United Kingdom
   *
   * @generated from protobuf enum value: UK = 1;
   */
  UK = 1,
  /**
   * United States
   *
   * @generated from protobuf enum value: US = 2;
   */
  US = 2,
  /**
   * India
   *
   * @generated from protobuf enum value: IN = 3;
   */
  IN = 3,
}
/**
 * LearnerState describes whether an indy user is a learner (i.e. has access to the learning part
 * of the app). By default indy users have learner state enabled.
 *
 * @generated from protobuf enum sparx.indy.common.LearnerState
 */
export enum LearnerState {
  /**
   * @generated from protobuf enum value: LEARNER_STATE_NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: LEARNER_STATE_ENABLED = 1;
   */
  ENABLED = 1,
  /**
   * @generated from protobuf enum value: LEARNER_STATE_DISABLED = 2;
   */
  DISABLED = 2,
}
/**
 * SupporterState describes whether an indy user is a supporter (i.e. has access to the supporting
 * adult part of the app). By default indy users have supporter state disabled.
 *
 * @generated from protobuf enum sparx.indy.common.SupporterState
 */
export enum SupporterState {
  /**
   * @generated from protobuf enum value: SUPPORTER_STATE_NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: SUPPORTER_STATE_ENABLED = 1;
   */
  ENABLED = 1,
  /**
   * @generated from protobuf enum value: SUPPORTER_STATE_DISABLED = 2;
   */
  DISABLED = 2,
}
/**
 * EmailPlusState describes the state of the user regarding the "Email Plus" system under the
 * US COPPA regulations.
 *
 * @generated from protobuf enum sparx.indy.common.EmailPlusState
 */
export enum EmailPlusState {
  /**
   * Unspecified
   *
   * @generated from protobuf enum value: EMAIL_PLUS_STATE_NONE = 0;
   */
  NONE = 0,
  /**
   * The user is either too old or not in the US so Email Plus is not applicable.
   *
   * @generated from protobuf enum value: EMAIL_PLUS_STATE_NOT_APPLICABLE = 1;
   */
  NOT_APPLICABLE = 1,
  /**
   * The user is waiting for their parent/guardian to consent to them using
   * the product via email.
   *
   * @generated from protobuf enum value: EMAIL_PLUS_STATE_AWAITING_CONSENT = 2;
   */
  AWAITING_CONSENT = 2,
  /**
   * The user's parent/guardian has consented that the user can use the product.
   *
   * @generated from protobuf enum value: EMAIL_PLUS_STATE_CONSENTED = 4;
   */
  CONSENTED = 4,
}
/**
 * Marketing state describes a user's marketing preferences.
 *
 * @generated from protobuf enum sparx.indy.common.MarketingState
 */
export enum MarketingState {
  /**
   * Unspecified
   *
   * @generated from protobuf enum value: MARKETING_STATE_NONE = 0;
   */
  NONE = 0,
  /**
   * Opt-in
   *
   * @generated from protobuf enum value: MARKETING_STATE_OPT_IN = 1;
   */
  OPT_IN = 1,
  /**
   * Opt-out
   *
   * @generated from protobuf enum value: MARKETING_STATE_OPT_OUT = 2;
   */
  OPT_OUT = 2,
}
/**
 * WeeklyEmailSubscriptionState describes whether a user is subscribed to
 * weekly update emails. If unset, weekly update emails will be sent.
 *
 * @generated from protobuf enum sparx.indy.common.WeeklyEmailSubscriptionState
 */
export enum WeeklyEmailSubscriptionState {
  /**
   * @generated from protobuf enum value: WEEKLY_EMAIL_SUBSCRIPTION_NONE = 0;
   */
  WEEKLY_EMAIL_SUBSCRIPTION_NONE = 0,
  /**
   * @generated from protobuf enum value: WEEKLY_EMAIL_SUBSCRIPTION_SUBSCRIBED = 1;
   */
  WEEKLY_EMAIL_SUBSCRIPTION_SUBSCRIBED = 1,
  /**
   * @generated from protobuf enum value: WEEKLY_EMAIL_SUBSCRIPTION_UNSUBSCRIBED = 2;
   */
  WEEKLY_EMAIL_SUBSCRIPTION_UNSUBSCRIBED = 2,
}
/**
 * EmailVerificationState describes whether or not the user has verified
 * their email address through firebase.
 *
 * @generated from protobuf enum sparx.indy.common.EmailVerificationState
 */
export enum EmailVerificationState {
  /**
   * Unspecified.
   *
   * @generated from protobuf enum value: EMAIL_VERIFICATION_STATE_NONE = 0;
   */
  NONE = 0,
  /**
   * Email verified through firebase.
   *
   * @generated from protobuf enum value: EMAIL_VERIFICATION_STATE_VERIFIED = 1;
   */
  VERIFIED = 1,
  /**
   * Email has not yet been verified through firebase.
   *
   * @generated from protobuf enum value: EMAIL_VERIFICATION_STATE_NOT_VERIFIED = 2;
   */
  NOT_VERIFIED = 2,
}
/**
 * OnboardingFlag indicates whether a user has been onboarded through some
 * aspect of the product.
 *
 * @generated from protobuf enum sparx.indy.common.OnboardingFlag
 */
export enum OnboardingFlag {
  /**
   * @generated from protobuf enum value: ONBOARDING_FLAG_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ONBOARDING_FLAG_FALSE = 1;
   */
  FALSE = 1,
  /**
   * @generated from protobuf enum value: ONBOARDING_FLAG_TRUE = 2;
   */
  TRUE = 2,
}
/**
 * FreeAccessReason indicates why a user has been given free access to Numerise
 *
 * @generated from protobuf enum sparx.indy.common.FreeAccessReason
 */
export enum FreeAccessReason {
  /**
   * @generated from protobuf enum value: FREE_ACCESS_REASON_NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: FREE_ACCESS_REASON_MONITORING_USER = 1;
   */
  MONITORING_USER = 1,
  /**
   * @generated from protobuf enum value: FREE_ACCESS_REASON_STAFF_PERK = 2;
   */
  STAFF_PERK = 2,
  /**
   * @generated from protobuf enum value: FREE_ACCESS_REASON_VIP = 3;
   */
  VIP = 3,
  /**
   * @generated from protobuf enum value: FREE_ACCESS_REASON_DISCRETIONARY_BONUS = 4;
   */
  DISCRETIONARY_BONUS = 4,
  /**
   * @generated from protobuf enum value: FREE_ACCESS_REASON_PAYMENT_NOT_SUPPORTED = 5;
   */
  PAYMENT_NOT_SUPPORTED = 5,
  /**
   * Set automatically - should not be given to a user by admin:
   *
   * @generated from protobuf enum value: FREE_ACCESS_REASON_INITIAL_FREE_TRIAL = 6;
   */
  INITIAL_FREE_TRIAL = 6,
  /**
   * Free access for users until the date we require payment for numerise (i.e. the date
   * the payments system will activate)
   *
   * No longer used as payment is switched on.
   *
   * @generated from protobuf enum value: FREE_ACCESS_REASON_BEFORE_PAYMENTS_REQUIRED_DATE = 7;
   */
  BEFORE_PAYMENTS_REQUIRED_DATE = 7,
  /**
   * @generated from protobuf enum value: FREE_ACCESS_REASON_NEW_TERRITORY_TRIAL = 8;
   */
  NEW_TERRITORY_TRIAL = 8,
  /**
   * Free trial which is displayed in the same way as FREE_ACCESS_INITIAL_FREE_TRIAL in the client, but
   * can be added at any time though indyadminclient
   *
   * @generated from protobuf enum value: FREE_ACCESS_REASON_EXTRA_FREE_TRIAL = 9;
   */
  EXTRA_FREE_TRIAL = 9,
}
/**
 * @generated from protobuf enum sparx.indy.common.AchievementType
 */
export enum AchievementType {
  /**
   * @generated from protobuf enum value: ACHIEVEMENT_TYPE_NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: ACHIEVEMENT_TYPE_GOAL_SETTER = 1;
   */
  GOAL_SETTER = 1,
  /**
   * @generated from protobuf enum value: ACHIEVEMENT_TYPE_QUESTION_PERFECTION = 2;
   */
  QUESTION_PERFECTION = 2,
  /**
   * @generated from protobuf enum value: ACHIEVEMENT_TYPE_LESSON_LEGEND = 3;
   */
  LESSON_LEGEND = 3,
  /**
   * @generated from protobuf enum value: ACHIEVEMENT_TYPE_ON_A_ROLL = 4;
   */
  ON_A_ROLL = 4,
  /**
   * @generated from protobuf enum value: ACHIEVEMENT_TYPE_DAILY_CHALLENGE = 5;
   */
  DAILY_CHALLENGE = 5,
  /**
   * @generated from protobuf enum value: ACHIEVEMENT_TYPE_COURSE_COMPLETER = 6;
   */
  COURSE_COMPLETER = 6,
  /**
   * @generated from protobuf enum value: ACHIEVEMENT_TYPE_DAILY_CHALLENGE_UNLOCKED = 7;
   */
  DAILY_CHALLENGE_UNLOCKED = 7,
}
/**
 * AnonymousState specifies whether or not a user is anonymous
 *
 * @generated from protobuf enum sparx.indy.common.AnonymousState
 */
export enum AnonymousState {
  /**
   * @generated from protobuf enum value: ANONYMOUS_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ANONYMOUS_STATE_FALSE = 1;
   */
  FALSE = 1,
  /**
   * @generated from protobuf enum value: ANONYMOUS_STATE_TRUE = 2;
   */
  TRUE = 2,
}
/**
 * SupporterType specifies the type of supporter.
 *
 * @generated from protobuf enum sparx.indy.common.SupporterType
 */
export enum SupporterType {
  /**
   * @generated from protobuf enum value: SUPPORTER_TYPE_NONE = 0;
   */
  NONE = 0,
  /**
   * @generated from protobuf enum value: SUPPORTER_TYPE_PARENT = 1;
   */
  PARENT = 1,
  /**
   * @generated from protobuf enum value: SUPPORTER_TYPE_HOME_ED = 2;
   */
  HOME_ED = 2,
  /**
   * @generated from protobuf enum value: SUPPORTER_TYPE_TUTOR = 3;
   */
  TUTOR = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseCorrectAnswerCountsOverTime$Type extends MessageType<NumeriseCorrectAnswerCountsOverTime> {
  constructor() {
    super('sparx.indy.common.NumeriseCorrectAnswerCountsOverTime', [
      { no: 1, name: 'week', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'month', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseCorrectAnswerCountsOverTime
 */
export const NumeriseCorrectAnswerCountsOverTime =
  new NumeriseCorrectAnswerCountsOverTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseCorrectAnswerCounts$Type extends MessageType<NumeriseCorrectAnswerCounts> {
  constructor() {
    super('sparx.indy.common.NumeriseCorrectAnswerCounts', [
      { no: 1, name: 'allTime', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'currentCounts',
        kind: 'message',
        T: () => NumeriseCorrectAnswerCountsOverTime,
      },
      {
        no: 3,
        name: 'prevCounts',
        kind: 'message',
        T: () => NumeriseCorrectAnswerCountsOverTime,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseCorrectAnswerCounts
 */
export const NumeriseCorrectAnswerCounts =
  new NumeriseCorrectAnswerCounts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseLessonCompleteCountsOverTime$Type extends MessageType<NumeriseLessonCompleteCountsOverTime> {
  constructor() {
    super('sparx.indy.common.NumeriseLessonCompleteCountsOverTime', [
      { no: 1, name: 'week', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseLessonCompleteCountsOverTime
 */
export const NumeriseLessonCompleteCountsOverTime =
  new NumeriseLessonCompleteCountsOverTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseLessonCompleteCounts$Type extends MessageType<NumeriseLessonCompleteCounts> {
  constructor() {
    super('sparx.indy.common.NumeriseLessonCompleteCounts', [
      {
        no: 1,
        name: 'courseAllTime',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
      {
        no: 2,
        name: 'currentCounts',
        kind: 'message',
        T: () => NumeriseLessonCompleteCountsOverTime,
      },
      {
        no: 3,
        name: 'prevCounts',
        kind: 'message',
        T: () => NumeriseLessonCompleteCountsOverTime,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseLessonCompleteCounts
 */
export const NumeriseLessonCompleteCounts =
  new NumeriseLessonCompleteCounts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseTimeTakenForWeek$Type extends MessageType<NumeriseTimeTakenForWeek> {
  constructor() {
    super('sparx.indy.common.NumeriseTimeTakenForWeek', [
      {
        no: 1,
        name: 'timeInSeconds',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 2, name: 'monday', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseTimeTakenForWeek
 */
export const NumeriseTimeTakenForWeek = new NumeriseTimeTakenForWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseTimeTaken$Type extends MessageType<NumeriseTimeTaken> {
  constructor() {
    super('sparx.indy.common.NumeriseTimeTaken', [
      {
        no: 1,
        name: 'timeTakenForWeek',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => NumeriseTimeTakenForWeek,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseTimeTaken
 */
export const NumeriseTimeTaken = new NumeriseTimeTaken$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseCourseCompleted$Type extends MessageType<NumeriseCourseCompleted> {
  constructor() {
    super('sparx.indy.common.NumeriseCourseCompleted', [
      { no: 1, name: 'courseName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'completionDate', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseCourseCompleted
 */
export const NumeriseCourseCompleted = new NumeriseCourseCompleted$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseCoursesCompleted$Type extends MessageType<NumeriseCoursesCompleted> {
  constructor() {
    super('sparx.indy.common.NumeriseCoursesCompleted', [
      {
        no: 1,
        name: 'coursesCompleted',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => NumeriseCourseCompleted,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseCoursesCompleted
 */
export const NumeriseCoursesCompleted = new NumeriseCoursesCompleted$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseLessonWorkedOn$Type extends MessageType<NumeriseLessonWorkedOn> {
  constructor() {
    super('sparx.indy.common.NumeriseLessonWorkedOn', [
      { no: 1, name: 'partName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'lastWorkedOn', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'completionDate', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseLessonWorkedOn
 */
export const NumeriseLessonWorkedOn = new NumeriseLessonWorkedOn$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseLessonsWorkedOn$Type extends MessageType<NumeriseLessonsWorkedOn> {
  constructor() {
    super('sparx.indy.common.NumeriseLessonsWorkedOn', [
      {
        no: 1,
        name: 'lessonsWorkedOn',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => NumeriseLessonWorkedOn,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseLessonsWorkedOn
 */
export const NumeriseLessonsWorkedOn = new NumeriseLessonsWorkedOn$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseStats$Type extends MessageType<NumeriseStats> {
  constructor() {
    super('sparx.indy.common.NumeriseStats', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'correctAnswerCounts',
        kind: 'message',
        T: () => NumeriseCorrectAnswerCounts,
      },
      {
        no: 3,
        name: 'lessonCompleteCounts',
        kind: 'message',
        T: () => NumeriseLessonCompleteCounts,
      },
      { no: 4, name: 'timeTaken', kind: 'message', T: () => NumeriseTimeTaken },
      {
        no: 5,
        name: 'coursesCompleted',
        kind: 'message',
        T: () => NumeriseCoursesCompleted,
      },
      {
        no: 6,
        name: 'lessonsWorkedOn',
        kind: 'message',
        T: () => NumeriseLessonsWorkedOn,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseStats
 */
export const NumeriseStats = new NumeriseStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseSummaryStats$Type extends MessageType<NumeriseSummaryStats> {
  constructor() {
    super('sparx.indy.common.NumeriseSummaryStats', [
      {
        no: 1,
        name: 'correctAnswerCounts',
        kind: 'message',
        T: () => NumeriseCorrectAnswerCounts,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseSummaryStats
 */
export const NumeriseSummaryStats = new NumeriseSummaryStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseLessonsCompletedForWeek$Type extends MessageType<NumeriseLessonsCompletedForWeek> {
  constructor() {
    super('sparx.indy.common.NumeriseLessonsCompletedForWeek', [
      { no: 1, name: 'count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'monday', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseLessonsCompletedForWeek
 */
export const NumeriseLessonsCompletedForWeek =
  new NumeriseLessonsCompletedForWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseCorrectAnswersForWeek$Type extends MessageType<NumeriseCorrectAnswersForWeek> {
  constructor() {
    super('sparx.indy.common.NumeriseCorrectAnswersForWeek', [
      { no: 1, name: 'count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'monday', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseCorrectAnswersForWeek
 */
export const NumeriseCorrectAnswersForWeek =
  new NumeriseCorrectAnswersForWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NumeriseCorrectAnswers$Type extends MessageType<NumeriseCorrectAnswers> {
  constructor() {
    super('sparx.indy.common.NumeriseCorrectAnswers', [
      { no: 1, name: 'count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'bucket_start', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'bucket_end', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.indy.common.NumeriseCorrectAnswers
 */
export const NumeriseCorrectAnswers = new NumeriseCorrectAnswers$Type();

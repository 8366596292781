import { ComponentWithAs } from '@sparx/sparx-design/ComponentWithAs';
import classNames from 'classnames';
import { Button, ButtonProps } from 'components/button/Button';
import { forwardRef } from 'react';

import styles from './Button.module.css';

interface IconButtonProps extends ButtonProps {
  icon: React.ReactNode;
  [`aria-label`]: string;
}

export const IconButton: ComponentWithAs<'button', IconButtonProps> = forwardRef(
  ({ size = 'md', icon, className, ...rest }, forwardedRef) => (
    <Button
      ref={forwardedRef}
      className={classNames(
        styles.IconButton,
        {
          [styles.IconButtonSm]: size === 'sm',
          [styles.IconButtonMd]: size === 'md',
          [styles.IconButtonXs]: size === 'xs',
        },
        className,
      )}
      size={size}
      {...rest}
      leftIcon={icon}
    />
  ),
);

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/packageactivity/v1/activity.proto" (package "sparx.packageactivity.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Activities } from './activity';
import type { ListUserTaskItemActivitiesResponse } from './activity';
import type { ListUserTaskItemActivitiesRequest } from './activity';
import type { GetPackageAnswerHistoryResponse } from './activity';
import type { GetPackageAnswerHistoryRequest } from './activity';
import type { ActivityActionResponse } from './activity';
import type { ActivityActionRequest } from './activity';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetActivityResponse } from './activity';
import type { GetActivityRequest } from './activity';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.packageactivity.v1.Activities
 */
export interface IActivitiesClient {
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  };
   *
   * @generated from protobuf rpc: GetActivity(sparx.packageactivity.v1.GetActivityRequest) returns (sparx.packageactivity.v1.GetActivityResponse);
   */
  getActivity(
    input: GetActivityRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivityRequest, GetActivityResponse>;
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  };
   *
   * @generated from protobuf rpc: ActivityAction(sparx.packageactivity.v1.ActivityActionRequest) returns (sparx.packageactivity.v1.ActivityActionResponse);
   */
  activityAction(
    input: ActivityActionRequest,
    options?: RpcOptions,
  ): UnaryCall<ActivityActionRequest, ActivityActionResponse>;
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "tp:homework"
   *      domain: "{school_name}"
   *    };
   *  };
   *
   * @generated from protobuf rpc: GetPackageAnswerHistory(sparx.packageactivity.v1.GetPackageAnswerHistoryRequest) returns (sparx.packageactivity.v1.GetPackageAnswerHistoryResponse);
   */
  getPackageAnswerHistory(
    input: GetPackageAnswerHistoryRequest,
    options?: RpcOptions,
  ): UnaryCall<GetPackageAnswerHistoryRequest, GetPackageAnswerHistoryResponse>;
  //  rpc GetTetheringActivity (GetTetheringActivityRequest) returns (GetTetheringActivityResponse) {
  //    option (sparx.api.auth) = {
  //      action: "read"
  //      resource: "sw:student"
  //      domain: "sw"
  //    };
  //  };

  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  }
   *
   * @generated from protobuf rpc: ListUserTaskItemActivities(sparx.packageactivity.v1.ListUserTaskItemActivitiesRequest) returns (sparx.packageactivity.v1.ListUserTaskItemActivitiesResponse);
   */
  listUserTaskItemActivities(
    input: ListUserTaskItemActivitiesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListUserTaskItemActivitiesRequest,
    ListUserTaskItemActivitiesResponse
  >;
}
/**
 * @generated from protobuf service sparx.packageactivity.v1.Activities
 */
export class ActivitiesClient implements IActivitiesClient, ServiceInfo {
  typeName = Activities.typeName;
  methods = Activities.methods;
  options = Activities.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  };
   *
   * @generated from protobuf rpc: GetActivity(sparx.packageactivity.v1.GetActivityRequest) returns (sparx.packageactivity.v1.GetActivityResponse);
   */
  getActivity(
    input: GetActivityRequest,
    options?: RpcOptions,
  ): UnaryCall<GetActivityRequest, GetActivityResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetActivityRequest, GetActivityResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  };
   *
   * @generated from protobuf rpc: ActivityAction(sparx.packageactivity.v1.ActivityActionRequest) returns (sparx.packageactivity.v1.ActivityActionResponse);
   */
  activityAction(
    input: ActivityActionRequest,
    options?: RpcOptions,
  ): UnaryCall<ActivityActionRequest, ActivityActionResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ActivityActionRequest, ActivityActionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "tp:homework"
   *      domain: "{school_name}"
   *    };
   *  };
   *
   * @generated from protobuf rpc: GetPackageAnswerHistory(sparx.packageactivity.v1.GetPackageAnswerHistoryRequest) returns (sparx.packageactivity.v1.GetPackageAnswerHistoryResponse);
   */
  getPackageAnswerHistory(
    input: GetPackageAnswerHistoryRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetPackageAnswerHistoryRequest,
    GetPackageAnswerHistoryResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetPackageAnswerHistoryRequest,
      GetPackageAnswerHistoryResponse
    >('unary', this._transport, method, opt, input);
  }
  //  rpc GetTetheringActivity (GetTetheringActivityRequest) returns (GetTetheringActivityResponse) {
  //    option (sparx.api.auth) = {
  //      action: "read"
  //      resource: "sw:student"
  //      domain: "sw"
  //    };
  //  };

  /**
   *  {
   *    option (sparx.api.auth) = {
   *      action: "read"
   *      resource: "sw:student"
   *      domain: "sw"
   *    };
   *  }
   *
   * @generated from protobuf rpc: ListUserTaskItemActivities(sparx.packageactivity.v1.ListUserTaskItemActivitiesRequest) returns (sparx.packageactivity.v1.ListUserTaskItemActivitiesResponse);
   */
  listUserTaskItemActivities(
    input: ListUserTaskItemActivitiesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListUserTaskItemActivitiesRequest,
    ListUserTaskItemActivitiesResponse
  > {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListUserTaskItemActivitiesRequest,
      ListUserTaskItemActivitiesResponse
    >('unary', this._transport, method, opt, input);
  }
}

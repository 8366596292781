// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/dataanalysis/homeworkinsights/v1/homeworkinsights.proto" (package "sparx.dataanalysis.homeworkinsights.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { QuestionPart } from '../../../content/summaries/v1/curriculum';
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.GetHomeworkClassHelpRequest
 */
export interface GetHomeworkClassHelpRequest {
  /**
   * ID of the homework we want insights for
   *
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string;
  /**
   * ID of the school the homework was set in
   *
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string;
  /**
   * How many results we want
   *
   * @generated from protobuf field: int32 n_results = 3;
   */
  nResults: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.GetHomeworkClassHelpResponse
 */
export interface GetHomeworkClassHelpResponse {
  /**
   * Completion statistics for the skills and questions returned
   *
   * @generated from protobuf field: sparx.dataanalysis.homeworkinsights.v1.HelpTheClassHomeworkStats stats = 1;
   */
  stats?: HelpTheClassHomeworkStats;
  /**
   * Information for each skill returned, including one example question
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.homeworkinsights.v1.HelpTheClassQuestion questions = 2;
   */
  questions: HelpTheClassQuestion[];
  /**
   * Information for each skill returned, including all questions for that skill
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillWithQuestions skills_with_questions = 3;
   */
  skillsWithQuestions: HelpTheClassSkillWithQuestions[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.HelpTheClassHomeworkStats
 */
export interface HelpTheClassHomeworkStats {
  /**
   * Number of correct answers given by the class for the homework
   *
   * @generated from protobuf field: int32 number_correct = 1;
   */
  numberCorrect: number;
  /**
   * Number of incorrect answers given by the class for the homework
   *
   * @generated from protobuf field: int32 number_incorrect = 2;
   */
  numberIncorrect: number;
  /**
   * Number of videos watched by the class for the homework
   *
   * @generated from protobuf field: int32 number_videos = 3;
   */
  numberVideos: number;
}
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.HelpTheClassQuestion
 */
export interface HelpTheClassQuestion {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: string question_name = 2;
   */
  questionName: string;
  /**
   * To support questions with multiple pieces of text and images, question_parts should be
   * used by consumers of this API instead of question_text and question_image_id.
   *
   * @deprecated
   * @generated from protobuf field: string question_text = 3 [deprecated = true];
   */
  questionText: string;
  /**
   * @deprecated
   * @generated from protobuf field: string question_image_id = 4 [deprecated = true];
   */
  questionImageId: string;
  /**
   * @generated from protobuf field: sparx.dataanalysis.homeworkinsights.v1.HelpType type = 5;
   */
  type: HelpType;
  /**
   * @generated from protobuf field: int32 number_seen = 6;
   */
  numberSeen: number;
  /**
   * @generated from protobuf field: int32 number_struggled = 7;
   */
  numberStruggled: number;
  /**
   * @generated from protobuf field: repeated sparx.content.summaries.v1.QuestionPart question_parts = 8;
   */
  questionParts: QuestionPart[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillWithQuestions
 */
export interface HelpTheClassSkillWithQuestions {
  /**
   * Title of the topic the skill was seen in
   *
   * @generated from protobuf field: string topic_title = 1;
   */
  topicTitle: string;
  /**
   * Reason for the skill being returned
   *
   * @generated from protobuf field: sparx.dataanalysis.homeworkinsights.v1.HelpType type = 2;
   */
  type: HelpType;
  /**
   * Number of students that saw the skill
   *
   * @generated from protobuf field: int32 number_seen = 3;
   */
  numberSeen: number;
  /**
   * Number of students that struggled with the skill
   *
   * @generated from protobuf field: int32 number_struggled = 4;
   */
  numberStruggled: number;
  /**
   * All questions that belong to the skill
   *
   * @generated from protobuf field: repeated sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillQuestion questions = 5;
   */
  questions: HelpTheClassSkillQuestion[];
  /**
   * Code of the topic the skill was seen in
   *
   * @generated from protobuf field: string topic_code = 6;
   */
  topicCode: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillQuestion
 */
export interface HelpTheClassSkillQuestion {
  /**
   * Question name in the form 'skills/<id>/questions/<id>'
   *
   * @generated from protobuf field: string question_name = 1;
   */
  questionName: string;
  /**
   * Parts of the question
   *
   * @generated from protobuf field: repeated sparx.content.summaries.v1.QuestionPart question_parts = 2;
   */
  questionParts: QuestionPart[];
  /**
   * Correct answer for the question in xml form
   *
   * @generated from protobuf field: string correct_answer = 3;
   */
  correctAnswer: string;
}
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.GetHomeworkWACReportRequest
 */
export interface GetHomeworkWACReportRequest {
  /**
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string; // ID of the homework we want the report for
  /**
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string; // ID of the school the homework was set in
}
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.GetHomeworkWACReportResponse
 */
export interface GetHomeworkWACReportResponse {
  /**
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string;
  /**
   * @generated from protobuf field: repeated sparx.dataanalysis.homeworkinsights.v1.StudentWACRate wac_rates = 2;
   */
  wacRates: StudentWACRate[];
}
/**
 * @generated from protobuf message sparx.dataanalysis.homeworkinsights.v1.StudentWACRate
 */
export interface StudentWACRate {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: int32 number_attempted = 2;
   */
  numberAttempted: number;
  /**
   * @generated from protobuf field: int32 number_incorrect = 3;
   */
  numberIncorrect: number;
}
/**
 * @generated from protobuf enum sparx.dataanalysis.homeworkinsights.v1.HelpType
 */
export enum HelpType {
  /**
   * @generated from protobuf enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * Skill with a large propn. of struggles seen by a large propn. of the class
   *
   * @generated from protobuf enum value: MANY_STRUGGLED_SKILL = 1;
   */
  MANY_STRUGGLED_SKILL = 1,
  /**
   * Keyish skill from an LU with a large propn. of struggles seen by a large propn. of the class
   *
   * @generated from protobuf enum value: MANY_STRUGGLED_LU_TASK = 2;
   */
  MANY_STRUGGLED_LU_TASK = 2,
  /**
   * Random skill from the LU about an LU which was aced by a large propn. of the class
   *
   * @generated from protobuf enum value: CHALLENGE = 3;
   */
  CHALLENGE = 3,
  /**
   * Skill with a large propn. of struggles seen by a small propn. of the class
   *
   * @generated from protobuf enum value: FEW_STRUGGLED_SKILL = 4;
   */
  FEW_STRUGGLED_SKILL = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkClassHelpRequest$Type extends MessageType<GetHomeworkClassHelpRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.homeworkinsights.v1.GetHomeworkClassHelpRequest',
      [
        {
          no: 1,
          name: 'homework_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'school_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: 'n_results', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.GetHomeworkClassHelpRequest
 */
export const GetHomeworkClassHelpRequest =
  new GetHomeworkClassHelpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkClassHelpResponse$Type extends MessageType<GetHomeworkClassHelpResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.homeworkinsights.v1.GetHomeworkClassHelpResponse',
      [
        {
          no: 1,
          name: 'stats',
          kind: 'message',
          T: () => HelpTheClassHomeworkStats,
        },
        {
          no: 2,
          name: 'questions',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HelpTheClassQuestion,
        },
        {
          no: 3,
          name: 'skills_with_questions',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HelpTheClassSkillWithQuestions,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.GetHomeworkClassHelpResponse
 */
export const GetHomeworkClassHelpResponse =
  new GetHomeworkClassHelpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HelpTheClassHomeworkStats$Type extends MessageType<HelpTheClassHomeworkStats> {
  constructor() {
    super('sparx.dataanalysis.homeworkinsights.v1.HelpTheClassHomeworkStats', [
      {
        no: 1,
        name: 'number_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'number_incorrect',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'number_videos',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.HelpTheClassHomeworkStats
 */
export const HelpTheClassHomeworkStats = new HelpTheClassHomeworkStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HelpTheClassQuestion$Type extends MessageType<HelpTheClassQuestion> {
  constructor() {
    super('sparx.dataanalysis.homeworkinsights.v1.HelpTheClassQuestion', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'question_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'question_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'question_image_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.dataanalysis.homeworkinsights.v1.HelpType', HelpType],
      },
      { no: 6, name: 'number_seen', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 7,
        name: 'number_struggled',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'question_parts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => QuestionPart,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.HelpTheClassQuestion
 */
export const HelpTheClassQuestion = new HelpTheClassQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HelpTheClassSkillWithQuestions$Type extends MessageType<HelpTheClassSkillWithQuestions> {
  constructor() {
    super(
      'sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillWithQuestions',
      [
        {
          no: 1,
          name: 'topic_title',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'type',
          kind: 'enum',
          T: () => [
            'sparx.dataanalysis.homeworkinsights.v1.HelpType',
            HelpType,
          ],
        },
        {
          no: 3,
          name: 'number_seen',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 4,
          name: 'number_struggled',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 5,
          name: 'questions',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => HelpTheClassSkillQuestion,
        },
        {
          no: 6,
          name: 'topic_code',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillWithQuestions
 */
export const HelpTheClassSkillWithQuestions =
  new HelpTheClassSkillWithQuestions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HelpTheClassSkillQuestion$Type extends MessageType<HelpTheClassSkillQuestion> {
  constructor() {
    super('sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillQuestion', [
      {
        no: 1,
        name: 'question_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'question_parts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => QuestionPart,
      },
      {
        no: 3,
        name: 'correct_answer',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.HelpTheClassSkillQuestion
 */
export const HelpTheClassSkillQuestion = new HelpTheClassSkillQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkWACReportRequest$Type extends MessageType<GetHomeworkWACReportRequest> {
  constructor() {
    super(
      'sparx.dataanalysis.homeworkinsights.v1.GetHomeworkWACReportRequest',
      [
        {
          no: 1,
          name: 'homework_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'school_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.GetHomeworkWACReportRequest
 */
export const GetHomeworkWACReportRequest =
  new GetHomeworkWACReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkWACReportResponse$Type extends MessageType<GetHomeworkWACReportResponse> {
  constructor() {
    super(
      'sparx.dataanalysis.homeworkinsights.v1.GetHomeworkWACReportResponse',
      [
        {
          no: 1,
          name: 'homework_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'wac_rates',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => StudentWACRate,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.GetHomeworkWACReportResponse
 */
export const GetHomeworkWACReportResponse =
  new GetHomeworkWACReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentWACRate$Type extends MessageType<StudentWACRate> {
  constructor() {
    super('sparx.dataanalysis.homeworkinsights.v1.StudentWACRate', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'number_attempted',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'number_incorrect',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.dataanalysis.homeworkinsights.v1.StudentWACRate
 */
export const StudentWACRate = new StudentWACRate$Type();
/**
 * @generated ServiceType for protobuf service sparx.dataanalysis.homeworkinsights.v1.HomeworkInsights
 */
export const HomeworkInsights = new ServiceType(
  'sparx.dataanalysis.homeworkinsights.v1.HomeworkInsights',
  [
    {
      name: 'GetHomeworkClassHelp',
      options: {
        'google.api.http': {
          get: '/v1/schools/{school_id}/homeworks/{homework_id}/helptheclass',
        },
      },
      I: GetHomeworkClassHelpRequest,
      O: GetHomeworkClassHelpResponse,
    },
    {
      name: 'GetHomeworkWACReport',
      options: {
        'google.api.http': {
          get: '/v1/schools/{school_id}/homeworks/{homework_id}/wacreport',
        },
      },
      I: GetHomeworkWACReportRequest,
      O: GetHomeworkWACReportResponse,
    },
  ],
);

import { MarkdownNode } from '@sparx/question';
import { useMemo } from 'react';

import supportTexts from './support_texts.txt?raw';

const parseSupportTexts = () => {
  let [key, run] = ['', ''];
  const output: Record<string, string> = {};
  const push = () => {
    if (key) {
      output[key] = run.trim();
      [run, key] = ['', ''];
    }
  };

  for (const line of supportTexts.split(/\n/g)) {
    if (line.startsWith('[[')) {
      push();
      key = line.trim();
    } else {
      run += line + '\n';
    }
  }
  push();

  return output;
};

const supportReplacements = parseSupportTexts();

export const SupportMaterial = ({ children }: { children: string }) => {
  const replacedText = useMemo(() => {
    let replaced = children;
    for (const [key, value] of Object.entries(supportReplacements)) {
      replaced = replaced.replace(key, value);
    }
    return replaced;
  }, [children]);

  return <MarkdownNode>{replacedText}</MarkdownNode>;
};

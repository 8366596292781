import classNames from 'classnames';
import { findAndReplace } from 'mdast-util-find-and-replace';
import { memo, useMemo } from 'react';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';

import { CorrectIcon } from '../components/CorrectIcon';
import { findChoiceGroupForRef } from '../question/input';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IElement, ITemplatedContentElement } from '../question/types';
import { isGapCorrect } from '../utils/isGapCorrect';
import { LayoutElement } from './LayoutElement';
import { MarkdownNode } from './TextElement';

// Modify the default sanitize schema to allow our custom elementreference tag
const sanitizeSchema: typeof defaultSchema = {
  ...defaultSchema,
  tagNames: [...(defaultSchema.tagNames || []), 'elementreference'],
};

export const TemplatedContentElement = ({
  element,
}: LayoutElementProps<ITemplatedContentElement>) => {
  const { questionMarkingMode, gapEvaluations } = useSparxQuestionContext();

  // Only detecting checkbox grid fill for now
  const isGridFill = Object.values(element.elements).some(
    el => el.element === 'choice' && el.variant === 'checkbox',
  );
  const marked = gapEvaluations && (!questionMarkingMode || questionMarkingMode === 'gap');

  return (
    <>
      <MarkdownNodeWithTemplating
        elements={element.elements}
        className={classNames(styles.TemplatedContent, styles.GridFill, marked && styles.Marked)}
      >
        {element.text}
      </MarkdownNodeWithTemplating>
      {isGridFill && <GridFillMarkSummary elements={Object.values(element.elements)} />}
    </>
  );
};

const MarkdownNodeWithTemplating = memo(
  ({
    elements,
    children,
    className,
  }: {
    elements: Record<string, IElement>;
    children: string;
    className?: string;
  }) => (
    <MarkdownNode
      className={className}
      options={{
        additionalRemarkPlugins: [elementReplacer],
        // There's something not right with the types but it's actually fine and works so
        // we cast to any so it doesn't error when compiling
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        additionalRehypePlugins: [rehypeRaw as any, rehypeSanitize(sanitizeSchema)],
        additionalComponents: {
          th: ({ node, children }) => {
            const contents = children && children.toString();
            return (
              <th {...node.properties} style={styleStringToObj(node.properties?.style)}>
                {contents && (
                  <MarkdownNodeWithTemplating elements={elements}>
                    {contents}
                  </MarkdownNodeWithTemplating>
                )}
              </th>
            );
          },
          td: ({ node, children }) => {
            const contents = children && children.toString();
            return (
              <td {...node.properties} style={styleStringToObj(node.properties?.style)}>
                {contents && (
                  <MarkdownNodeWithTemplating elements={elements}>
                    {contents}
                  </MarkdownNodeWithTemplating>
                )}
              </td>
            );
          },
          elementreference: ({ node }) => {
            const key = node.properties?.keyRef?.toString() || '';
            const el = elements[key];
            if (!el) {
              return <span>ERROR</span>;
            }
            return <LayoutElement element={el} />;
          },
        },
      }}
    >
      {children}
    </MarkdownNode>
  ),
);

// Can't pass a string to the style prop so if we have one we need to convert it to an object first.
// This seems to work for at least the very simple uses we need.
// https://stackoverflow.com/a/65039159
const styleStringToObj = (
  styles?: string | number | boolean | (string | number)[] | null | undefined,
) => {
  if (typeof styles === 'string') {
    const cssInObject = styles
      .split(';')
      .map(cur => cur.split(':'))
      .reduce<Record<string, unknown>>((acc, val) => {
        if (val.length !== 2) return acc;
        let key = val[0];
        const value = val[1];
        key = key.replace(/-./g, css => css.toUpperCase()[1]);
        acc[key] = value;
        return acc;
      }, {});
    return cssInObject;
  }
  return {};
};

const RE_ELEMENT = /\[\[([a-zA-Z0-9-]+)\]\]/g;

const elementReplacer = () => {
  const replace = (match: string) => {
    const key = match.substring(2, match.length - 2);
    return {
      type: 'text',
      data: {
        hName: 'elementreference',
        hProperties: {
          keyRef: key,
        },
      },
    };
  };

  //
  //   eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (tree: any) => {
    findAndReplace(tree, [[RE_ELEMENT, replace as never]]);
  };
};

const GridFillMarkSummary = ({ elements }: { elements: IElement[] }) => {
  const { input, questionMarkingMode, gapEvaluations } = useSparxQuestionContext();

  const result = useMemo(() => {
    const correctGroups = new Set<string>();
    const totalGroups = new Set<string>();

    for (const el of elements) {
      if (el.element !== 'choice') continue;
      const [groupRef, _] = findChoiceGroupForRef(input, el.ref) || [];

      if (!groupRef || totalGroups.has(groupRef)) continue;

      const groupEval = isGapCorrect(groupRef || '', gapEvaluations, questionMarkingMode);

      if (!groupEval.show) return undefined;

      totalGroups.add(groupRef);
      if (groupEval.correct) correctGroups.add(groupRef);
    }
    return {
      correct: correctGroups.size,
      total: totalGroups.size,
    };
  }, [elements, input, questionMarkingMode, gapEvaluations]);

  if (!result) return null;

  return (
    <div>
      <div
        className={classNames(styles.SummaryFeedback, {
          [styles.Correct]: result.correct === result.total,
        })}
      >
        <CorrectIcon
          correct={result.correct === result.total}
          inline={true}
          analyticsAnswerType="grid-fill-summary"
        />
        {result.correct} out of {result.total} rows are correct
      </div>
    </div>
  );
};

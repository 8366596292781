import { AssessmentQuestion } from '@sparx/api/apis/sparx/assessment/v1/assessment';

import { roundTo1DP } from '../../../../utils/assessments';
import { composeSorts, ISorting, SortFnWithDir } from '../../../../utils/sorting';

type TableSortingFunction = (
  scoreByQuestion: Map<string, number>,
) => SortFnWithDir<AssessmentQuestion>;

export type TableSorting = ISorting<SortableColumnName, TableSortingFunction>;

export enum SortableColumnName {
  QuestionNumber = 'Question number',
  QuestionType = 'Question type',
  Unit = 'Unit',
  Marks = 'Marks',
}

const questionNumberSort: TableSortingFunction = () => dir => (a, b) =>
  a.displayName.localeCompare(b.displayName, 'en', { numeric: true }) * dir;

const questionType: () => SortFnWithDir<AssessmentQuestion> = () => dir => (a, b) =>
  dir * (a.type - b.type);

const unit: () => SortFnWithDir<AssessmentQuestion> = () => dir => (a, b) =>
  a.unit.localeCompare(b.unit, 'en') * dir;

const marks: (s: Map<string, number>) => SortFnWithDir<AssessmentQuestion> = s => dir => (a, b) => {
  const aScore = s.get(a.name);
  const bScore = s.get(b.name);
  if (aScore === undefined && bScore === undefined) {
    return 0;
  } else if (aScore === undefined) {
    return 1;
  } else if (bScore === undefined) {
    return -1;
  }
  const aPercentage = roundTo1DP(aScore) / a.availableMarks;
  const bPercentage = roundTo1DP(bScore) / b.availableMarks;
  if (aPercentage === bPercentage) {
    return 0;
  }
  return dir * (aPercentage - bPercentage);
};

const questionTypeSort: TableSortingFunction = s => dir =>
  composeSorts(questionType()(dir), marks(s)(dir), questionNumberSort(s)(dir));

const unitSort: TableSortingFunction = s => dir =>
  composeSorts(unit()(dir), marks(s)(dir), questionNumberSort(s)(dir));

const marksSort: TableSortingFunction = s => dir =>
  composeSorts(marks(s)(dir), questionNumberSort(s)(dir));

export const sortFunctions: Record<SortableColumnName, TableSortingFunction> = {
  [SortableColumnName.QuestionNumber]: questionNumberSort,
  [SortableColumnName.QuestionType]: questionTypeSort,
  [SortableColumnName.Unit]: unitSort,
  [SortableColumnName.Marks]: marksSort,
};

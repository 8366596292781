import { Navigate, useSearchParams } from 'react-router-dom';

export const LoginRedirectView = () => {
  const [params] = useSearchParams();
  const schoolID = params.get('school') || '';

  // TODO: do something with the route?

  return <Navigate to={schoolID ? `/?school=${schoolID}` : '/'} replace={true} />;
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "genproto/apis/lessondeliverysettings/lessondeliverysettings.proto" (package "lessondeliverysettings", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message lessondeliverysettings.LessonSettings
 */
export interface LessonSettings {
  /**
   * Videos will be disabled by default and must be manually enabled.
   *
   * @generated from protobuf field: bool disableVideosByDefault = 1;
   */
  disableVideosByDefault: boolean;
  /**
   * Default students to the task selection page instead of putting them in task 1.
   *
   * @generated from protobuf field: bool defaultToTaskSelection = 2;
   */
  defaultToTaskSelection: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class LessonSettings$Type extends MessageType<LessonSettings> {
  constructor() {
    super('lessondeliverysettings.LessonSettings', [
      {
        no: 1,
        name: 'disableVideosByDefault',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'defaultToTaskSelection',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message lessondeliverysettings.LessonSettings
 */
export const LessonSettings = new LessonSettings$Type();

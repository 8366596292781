import { StudentAssessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';

import { composeSorts, ISorting, SortDirection, SortFnWithDir } from '../../../../utils/sorting';

type TableSortingFunction = (
  studentsMap: Record<string, Student>,
  groupMap: Record<string, Group>,
) => SortFnWithDir<StudentAssessment>;

export type TableSorting = ISorting<SortableColumnName, TableSortingFunction>;

export enum SortableColumnName {
  StudentName = 'Student Name',
  Class = 'Class',
}

const studentNameSort: TableSortingFunction = studentsMap => dir => (a, b) => {
  const s1 = studentsMap[a.studentId];
  const s2 = studentsMap[b.studentId];
  const aName = `${s1.familyName}, ${s1.givenName}`;
  const bName = `${s2.familyName}, ${s2.givenName}`;
  return dir * aName.localeCompare(bName);
};

const getStudentGroup = (student: Student | undefined, groupMap: Record<string, Group>) =>
  student?.studentGroupIds
    .map(id => groupMap[id])
    .find(group => group?.type === StudentGroupType.CLASS);

const groupSort: (
  studentsMap: Record<string, Student>,
  groupMap: Record<string, Group>,
) => SortFnWithDir<StudentAssessment> = (studentsMap, groupMap) => dir => (a, b) => {
  const g1 = getStudentGroup(studentsMap[a.studentId], groupMap);
  const g2 = getStudentGroup(studentsMap[b.studentId], groupMap);
  if (g1 === g2) {
    return 0;
  } else if (g1 === undefined) {
    return 1;
  } else if (g2 === undefined) {
    return -1;
  }
  return dir * g1.displayName.localeCompare(g2.displayName, undefined, { numeric: true });
};

const groupNameSort: TableSortingFunction = (studentsMap, groupMap) => dir =>
  composeSorts(
    groupSort(studentsMap, groupMap)(dir),
    studentNameSort(studentsMap, groupMap)(SortDirection.Ascending),
  );

export const sortFunctions: Record<SortableColumnName, TableSortingFunction> = {
  [SortableColumnName.StudentName]: studentNameSort,
  [SortableColumnName.Class]: groupNameSort,
};

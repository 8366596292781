import { Product } from '@sparx/api/apis/sparx/types/product';
import { useEffect, useRef } from 'react';

import { useLibAnalytics } from './analyticsContext';

const system = {
  [Product.SPARX_MATHS]: 'Maths',
  [Product.SPARX_READER]: 'Reader',
  [Product.SPARX_SCIENCE]: 'Science',
  [Product.SPARXMATHS_CURRICULUM]: 'Curriculum',
  [Product.SPARX_TEACHING]: 'Sparx Teaching',
  [Product.SPARX_ASSESSMENTS]: 'Assessments',
  [Product.SPARX_PRIMARY]: 'Primary',
  [Product.PRODUCT_UNKNOWN]: 'Unknown',
};

const domainCheckUrl = 'https://sparx-learning.com/bg-maths.png';

export const DomainCheck = ({
  schoolId,
  userType,
  product,
}: {
  schoolId: string;
  userType: string;
  product: Product;
}) => {
  const sendEvent = useLibAnalytics();
  const resultReported = useRef(false);

  useEffect(() => {
    new Promise<string>(resolve => {
      const img = new Image();
      img.onload = () => resolve('passed');
      img.onerror = () => resolve('failed');
      img.src = domainCheckUrl;
      window.setTimeout(() => resolve('timeout'), 15000);
    }).then(status => {
      if (!resultReported.current) {
        sendEvent({
          category: 'domain-check',
          action: `domain check ${status}`,
          labels: {
            schoolId,
            userType,
            product: system[product] || 'Unknown',
            status,
            host: window.location.hostname,
          },
        });
      }
      resultReported.current = true;
    });
  }, [sendEvent, schoolId, userType, product]);

  return null;
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/content/summaries/v1/curriculum.proto" (package "sparx.content.summaries.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Skill } from '../../v2/skill';
import { Substrand } from '../../v2/curriculum';
import { Strand } from '../../v2/curriculum';
import { Curriculum } from '../../v2/curriculum';
import { SkillFlag } from '../../v2/skill';
import { LearningTaskType } from '../../v2/learningunit';
import { LearningPath } from '../../v2/curriculum';
import { Topic } from '../../v2/curriculum';
/**
 * @generated from protobuf message sparx.content.summaries.v1.ListTopicSummariesRequest
 */
export interface ListTopicSummariesRequest {
  /**
   * Name of the parent to get topics for, i.e. a curriculum name.
   *
   * @generated from protobuf field: string topic_parent = 1;
   */
  topicParent: string;
  /**
   * @generated from protobuf field: sparx.content.summaries.v1.TopicSummaryRequestOptions options = 2;
   */
  options?: TopicSummaryRequestOptions;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.ListTopicSummariesResponse
 */
export interface ListTopicSummariesResponse {
  /**
   * @generated from protobuf field: repeated sparx.content.summaries.v1.TopicSummary topic_summaries = 2;
   */
  topicSummaries: TopicSummary[];
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.GetTopicSummaryRequest
 */
export interface GetTopicSummaryRequest {
  /**
   * @generated from protobuf field: string topic_name = 1;
   */
  topicName: string;
  /**
   * @generated from protobuf field: sparx.content.summaries.v1.TopicSummaryRequestOptions options = 2;
   */
  options?: TopicSummaryRequestOptions;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.GetTopicSummaryResponse
 */
export interface GetTopicSummaryResponse {
  /**
   * @generated from protobuf field: sparx.content.summaries.v1.TopicSummary topic_summary = 1;
   */
  topicSummary?: TopicSummary;
}
/**
 * NOTE: In Go package content/summaries, ContentSummariser.fillTopicSummary
 * makes a shallow copy of this message.  If new fields are added to this
 * message, the function needs to be updated.
 *
 * @generated from protobuf message sparx.content.summaries.v1.TopicSummaryRequestOptions
 */
export interface TopicSummaryRequestOptions {
  /**
   * If true the response will contain the list of learning paths a topic
   * contains.
   *
   * @generated from protobuf field: bool include_learning_paths = 1;
   */
  includeLearningPaths: boolean;
  /**
   * If true the key questions will not be in the response (these are never
   * included in a list request).
   *
   * @generated from protobuf field: bool omit_key_questions = 2;
   */
  omitKeyQuestions: boolean;
  /**
   * If true the topic links are omitted from the response.
   *
   * @generated from protobuf field: bool omit_topic_links = 3;
   */
  omitTopicLinks: boolean;
  /**
   * If true all questions will be in the response (can be many!) - this is
   * only used when omit_key_questions is false, in particular it has no
   * effect on a list requeest.
   *
   * @generated from protobuf field: bool include_all_questions = 4;
   */
  includeAllQuestions: boolean;
  /**
   * If true all questions will include a populated layout_json field. This
   * is optional as it will increase the size of the response.
   *
   * @generated from protobuf field: bool include_question_layout_json = 5;
   */
  includeQuestionLayoutJson: boolean;
  /**
   * If true all skills will include populated flags and tags fields.
   *
   * @generated from protobuf field: bool include_skill_flags_and_tags = 6;
   */
  includeSkillFlagsAndTags: boolean;
}
/**
 * A topic augmented with extra info.
 *
 * @generated from protobuf message sparx.content.summaries.v1.TopicSummary
 */
export interface TopicSummary {
  /**
   * The topic
   *
   * @generated from protobuf field: sparx.content.v2.Topic topic = 1;
   */
  topic?: Topic;
  /**
   * Number of offline objectives in the topic
   *
   * @generated from protobuf field: int32 offline_objective_count = 2;
   */
  offlineObjectiveCount: number;
  /**
   * Total number of objectives in the topic
   *
   * @generated from protobuf field: int32 objective_count = 3;
   */
  objectiveCount: number;
  /**
   * List of learning paths this topic contains
   *
   * @generated from protobuf field: repeated sparx.content.v2.LearningPath learning_paths = 7;
   */
  learningPaths: LearningPath[];
  /**
   * A list of key questions with some context for the topic (only in detailed view).
   *
   * @generated from protobuf field: repeated sparx.content.summaries.v1.KeyTopicSkill key_questions = 4;
   */
  keyQuestions: KeyTopicSkill[];
  /**
   * Teaching notes for the topic (optional)
   *
   * @generated from protobuf field: sparx.content.summaries.v1.TeachingNotes teaching_notes = 5;
   */
  teachingNotes?: TeachingNotes;
  /**
   * Whether skills within this topic require special equipment to be answered by students.
   *
   * @generated from protobuf field: bool requires_special_equipment = 6;
   */
  requiresSpecialEquipment: boolean;
  /**
   * Total number of skills in the topic
   *
   * @generated from protobuf field: int32 skill_count = 8;
   */
  skillCount: number;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.TeachingNotes
 */
export interface TeachingNotes {
  /**
   * @generated from protobuf field: string text = 1;
   */
  text: string;
  /**
   * @generated from protobuf field: string image_id = 2;
   */
  imageId: string;
}
/**
 * A key skill in the topic
 *
 * @generated from protobuf message sparx.content.summaries.v1.KeyTopicSkill
 */
export interface KeyTopicSkill {
  /**
   * Summary info about the question.
   *
   * @generated from protobuf field: sparx.content.summaries.v1.QuestionSummary question_summary = 1;
   */
  questionSummary?: QuestionSummary;
  /**
   * Name of the skill
   *
   * @generated from protobuf field: string skill_name = 2;
   */
  skillName: string;
  /**
   * The level ("title") of the learning path the skill appears in
   *
   * @generated from protobuf field: string learning_path_level = 3;
   */
  learningPathLevel: string;
  /**
   * The name of the learning path the skill appears in
   *
   * @generated from protobuf field: string learning_path_name = 4;
   */
  learningPathName: string;
  /**
   * Type of the task the skill appears in
   *
   * @generated from protobuf field: sparx.content.v2.LearningTaskType task_type = 5;
   */
  taskType: LearningTaskType;
  /**
   * True if this key skill is actually not key (!)
   *
   * @generated from protobuf field: bool not_key = 6;
   */
  notKey: boolean;
  /**
   * Position in the task (starting from 1, 0 means unspecified)
   *
   * @generated from protobuf field: int32 position_in_task = 7;
   */
  positionInTask: number;
  /**
   * ID of the video for this skill (if it exists)
   *
   * @generated from protobuf field: string video_id = 8;
   */
  videoId: string;
  /**
   * Flags affecting usage of the Skill.
   *
   * @generated from protobuf field: repeated sparx.content.v2.SkillFlag flags = 9;
   */
  flags: SkillFlag[];
  /**
   * Tags associated with the Skill.
   *
   * @generated from protobuf field: repeated string tags = 10;
   */
  tags: string[];
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.QuestionPart
 */
export interface QuestionPart {
  /**
   * @generated from protobuf field: sparx.content.summaries.v1.QuestionPartType type = 1;
   */
  type: QuestionPartType;
  /**
   * @generated from protobuf field: string value = 2;
   */
  value: string;
}
/**
 * Summmary info for being able to display a question
 *
 * @generated from protobuf message sparx.content.summaries.v1.QuestionSummary
 */
export interface QuestionSummary {
  /**
   * @generated from protobuf field: string question_name = 1;
   */
  questionName: string;
  /**
   * To support questions with multiple pieces of text and images, question_parts should be
   * used by consumers of this API instead of question_text and question_image.
   *
   * @deprecated
   * @generated from protobuf field: string question_text = 2 [deprecated = true];
   */
  questionText: string;
  /**
   * @deprecated
   * @generated from protobuf field: string question_image_id = 3 [deprecated = true];
   */
  questionImageId: string;
  /**
   * @generated from protobuf field: repeated sparx.content.summaries.v1.QuestionPart question_parts = 4;
   */
  questionParts: QuestionPart[];
  /**
   * JSON layout spec for the question
   *
   * @generated from protobuf field: string layout_json = 5;
   */
  layoutJson: string;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.ListCurriculumSummariesRequest
 */
export interface ListCurriculumSummariesRequest {
  /**
   * Whether to include hidden curriculums in the response.
   *
   * @generated from protobuf field: bool include_hidden = 1;
   */
  includeHidden: boolean;
  /**
   * @generated from protobuf field: string subject_name = 2;
   */
  subjectName: string;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.ListCurriculumSummariesResponse
 */
export interface ListCurriculumSummariesResponse {
  /**
   * @generated from protobuf field: repeated sparx.content.summaries.v1.CurriculumSummary curriculum_summaries = 1;
   */
  curriculumSummaries: CurriculumSummary[];
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.GetCurriculumSummaryRequest
 */
export interface GetCurriculumSummaryRequest {
  /**
   * @generated from protobuf field: string curriculum_name = 1;
   */
  curriculumName: string;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.GetCurriculumSummaryResponse
 */
export interface GetCurriculumSummaryResponse {
  /**
   * @generated from protobuf field: sparx.content.summaries.v1.CurriculumSummary curriculum_summary = 1;
   */
  curriculumSummary?: CurriculumSummary;
}
/**
 * A curriculum with extra hierarchy info.
 *
 * @generated from protobuf message sparx.content.summaries.v1.CurriculumSummary
 */
export interface CurriculumSummary {
  /**
   * @generated from protobuf field: sparx.content.v2.Curriculum curriculum = 1;
   */
  curriculum?: Curriculum;
  /**
   * @generated from protobuf field: repeated sparx.content.summaries.v1.StrandSummary strand_summaries = 2;
   */
  strandSummaries: StrandSummary[];
}
/**
 * A strand with info about substrands.
 *
 * @generated from protobuf message sparx.content.summaries.v1.StrandSummary
 */
export interface StrandSummary {
  /**
   * @generated from protobuf field: sparx.content.v2.Strand strand = 1;
   */
  strand?: Strand;
  /**
   * @generated from protobuf field: repeated sparx.content.summaries.v1.SubstrandSummary substrand_summaries = 2;
   */
  substrandSummaries: SubstrandSummary[];
}
/**
 * A substrand with extra summary info (currently none).
 *
 * @generated from protobuf message sparx.content.summaries.v1.SubstrandSummary
 */
export interface SubstrandSummary {
  /**
   * @generated from protobuf field: sparx.content.v2.Substrand substrand = 1;
   */
  substrand?: Substrand;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.GetCurriculumLUTopicsMapRequest
 */
export interface GetCurriculumLUTopicsMapRequest {
  /**
   * Name of the parent of the topics, i.e. a curriculum name.
   *
   * @generated from protobuf field: string topic_parent = 1;
   */
  topicParent: string;
  /**
   * Which view of the map to return.
   *
   * @generated from protobuf field: sparx.content.summaries.v1.LUTopicsMapView view = 2;
   */
  view: LUTopicsMapView;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.GetCurriculumLUTopicsMapResponse
 */
export interface GetCurriculumLUTopicsMapResponse {
  /**
   * @generated from protobuf field: map<string, sparx.content.summaries.v1.TopicList> learning_unit_topics_map = 1;
   */
  learningUnitTopicsMap: {
    [key: string]: TopicList;
  };
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.TopicList
 */
export interface TopicList {
  /**
   * @generated from protobuf field: repeated sparx.content.v2.Topic topics = 1;
   */
  topics: Topic[];
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.GetSkillSummaryRequest
 */
export interface GetSkillSummaryRequest {
  /**
   * Resource name of the skill requested
   *
   * @generated from protobuf field: string skill_name = 1;
   */
  skillName: string;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.GetSkillSummaryResponse
 */
export interface GetSkillSummaryResponse {
  /**
   * @generated from protobuf field: sparx.content.summaries.v1.SkillSummary skill_summary = 1;
   */
  skillSummary?: SkillSummary;
}
/**
 * @generated from protobuf message sparx.content.summaries.v1.SkillSummary
 */
export interface SkillSummary {
  /**
   * The skill requested
   *
   * @generated from protobuf field: sparx.content.v2.Skill skill = 1;
   */
  skill?: Skill;
  /**
   * A sample question for that skill
   *
   * @generated from protobuf field: sparx.content.summaries.v1.QuestionSummary sample_question = 2;
   */
  sampleQuestion?: QuestionSummary;
}
/**
 * @generated from protobuf enum sparx.content.summaries.v1.QuestionPartType
 */
export enum QuestionPartType {
  /**
   * @generated from protobuf enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,
  /**
   * @generated from protobuf enum value: TEXT = 1;
   */
  TEXT = 1,
  /**
   * @generated from protobuf enum value: IMAGE = 2;
   */
  IMAGE = 2,
  /**
   * @generated from protobuf enum value: MARKDOWN_TEXT = 3;
   */
  MARKDOWN_TEXT = 3,
}
/**
 * LUTopicsMapView allows a client to request a predetermined subset of fields on the topics.
 *
 * @generated from protobuf enum sparx.content.summaries.v1.LUTopicsMapView
 */
export enum LUTopicsMapView {
  /**
   * Not specified, equivalent to FULL.
   *
   * @generated from protobuf enum value: VIEW_UNSPECIFIED = 0;
   */
  VIEW_UNSPECIFIED = 0,
  /**
   * Topics only include name and display_name.
   *
   * @generated from protobuf enum value: BASIC = 1;
   */
  BASIC = 1,
  /**
   * Full representation of each topic is returned.
   * This is the default value.
   *
   * @generated from protobuf enum value: FULL = 2;
   */
  FULL = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class ListTopicSummariesRequest$Type extends MessageType<ListTopicSummariesRequest> {
  constructor() {
    super('sparx.content.summaries.v1.ListTopicSummariesRequest', [
      {
        no: 1,
        name: 'topic_parent',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'options',
        kind: 'message',
        T: () => TopicSummaryRequestOptions,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.ListTopicSummariesRequest
 */
export const ListTopicSummariesRequest = new ListTopicSummariesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTopicSummariesResponse$Type extends MessageType<ListTopicSummariesResponse> {
  constructor() {
    super('sparx.content.summaries.v1.ListTopicSummariesResponse', [
      {
        no: 2,
        name: 'topic_summaries',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TopicSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.ListTopicSummariesResponse
 */
export const ListTopicSummariesResponse = new ListTopicSummariesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTopicSummaryRequest$Type extends MessageType<GetTopicSummaryRequest> {
  constructor() {
    super('sparx.content.summaries.v1.GetTopicSummaryRequest', [
      { no: 1, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'options',
        kind: 'message',
        T: () => TopicSummaryRequestOptions,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.GetTopicSummaryRequest
 */
export const GetTopicSummaryRequest = new GetTopicSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTopicSummaryResponse$Type extends MessageType<GetTopicSummaryResponse> {
  constructor() {
    super('sparx.content.summaries.v1.GetTopicSummaryResponse', [
      { no: 1, name: 'topic_summary', kind: 'message', T: () => TopicSummary },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.GetTopicSummaryResponse
 */
export const GetTopicSummaryResponse = new GetTopicSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopicSummaryRequestOptions$Type extends MessageType<TopicSummaryRequestOptions> {
  constructor() {
    super('sparx.content.summaries.v1.TopicSummaryRequestOptions', [
      {
        no: 1,
        name: 'include_learning_paths',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'omit_key_questions',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'omit_topic_links',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'include_all_questions',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'include_question_layout_json',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 6,
        name: 'include_skill_flags_and_tags',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.TopicSummaryRequestOptions
 */
export const TopicSummaryRequestOptions = new TopicSummaryRequestOptions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopicSummary$Type extends MessageType<TopicSummary> {
  constructor() {
    super('sparx.content.summaries.v1.TopicSummary', [
      {
        no: 1,
        name: 'topic',
        kind: 'message',
        T: () => Topic,
        options: { 'sparx.api.typescript.required': true },
      },
      {
        no: 2,
        name: 'offline_objective_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'objective_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'learning_paths',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LearningPath,
      },
      {
        no: 4,
        name: 'key_questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => KeyTopicSkill,
      },
      {
        no: 5,
        name: 'teaching_notes',
        kind: 'message',
        T: () => TeachingNotes,
      },
      {
        no: 6,
        name: 'requires_special_equipment',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 8, name: 'skill_count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.TopicSummary
 */
export const TopicSummary = new TopicSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeachingNotes$Type extends MessageType<TeachingNotes> {
  constructor() {
    super('sparx.content.summaries.v1.TeachingNotes', [
      { no: 1, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'image_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.TeachingNotes
 */
export const TeachingNotes = new TeachingNotes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KeyTopicSkill$Type extends MessageType<KeyTopicSkill> {
  constructor() {
    super('sparx.content.summaries.v1.KeyTopicSkill', [
      {
        no: 1,
        name: 'question_summary',
        kind: 'message',
        T: () => QuestionSummary,
      },
      { no: 2, name: 'skill_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'learning_path_level',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'learning_path_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'task_type',
        kind: 'enum',
        T: () => [
          'sparx.content.v2.LearningTaskType',
          LearningTaskType,
          'LEARNING_TASK_TYPE_',
        ],
      },
      { no: 6, name: 'not_key', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: 'position_in_task',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 8, name: 'video_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 9,
        name: 'flags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.content.v2.SkillFlag', SkillFlag],
      },
      {
        no: 10,
        name: 'tags',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.KeyTopicSkill
 */
export const KeyTopicSkill = new KeyTopicSkill$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionPart$Type extends MessageType<QuestionPart> {
  constructor() {
    super('sparx.content.summaries.v1.QuestionPart', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.content.summaries.v1.QuestionPartType',
          QuestionPartType,
        ],
      },
      { no: 2, name: 'value', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.QuestionPart
 */
export const QuestionPart = new QuestionPart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionSummary$Type extends MessageType<QuestionSummary> {
  constructor() {
    super('sparx.content.summaries.v1.QuestionSummary', [
      {
        no: 1,
        name: 'question_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'question_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'question_image_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'question_parts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => QuestionPart,
      },
      {
        no: 5,
        name: 'layout_json',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.QuestionSummary
 */
export const QuestionSummary = new QuestionSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCurriculumSummariesRequest$Type extends MessageType<ListCurriculumSummariesRequest> {
  constructor() {
    super('sparx.content.summaries.v1.ListCurriculumSummariesRequest', [
      {
        no: 1,
        name: 'include_hidden',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.ListCurriculumSummariesRequest
 */
export const ListCurriculumSummariesRequest =
  new ListCurriculumSummariesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCurriculumSummariesResponse$Type extends MessageType<ListCurriculumSummariesResponse> {
  constructor() {
    super('sparx.content.summaries.v1.ListCurriculumSummariesResponse', [
      {
        no: 1,
        name: 'curriculum_summaries',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CurriculumSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.ListCurriculumSummariesResponse
 */
export const ListCurriculumSummariesResponse =
  new ListCurriculumSummariesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurriculumSummaryRequest$Type extends MessageType<GetCurriculumSummaryRequest> {
  constructor() {
    super('sparx.content.summaries.v1.GetCurriculumSummaryRequest', [
      {
        no: 1,
        name: 'curriculum_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.GetCurriculumSummaryRequest
 */
export const GetCurriculumSummaryRequest =
  new GetCurriculumSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurriculumSummaryResponse$Type extends MessageType<GetCurriculumSummaryResponse> {
  constructor() {
    super('sparx.content.summaries.v1.GetCurriculumSummaryResponse', [
      {
        no: 1,
        name: 'curriculum_summary',
        kind: 'message',
        T: () => CurriculumSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.GetCurriculumSummaryResponse
 */
export const GetCurriculumSummaryResponse =
  new GetCurriculumSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurriculumSummary$Type extends MessageType<CurriculumSummary> {
  constructor() {
    super('sparx.content.summaries.v1.CurriculumSummary', [
      {
        no: 1,
        name: 'curriculum',
        kind: 'message',
        T: () => Curriculum,
        options: { 'sparx.api.typescript.required': true },
      },
      {
        no: 2,
        name: 'strand_summaries',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StrandSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.CurriculumSummary
 */
export const CurriculumSummary = new CurriculumSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StrandSummary$Type extends MessageType<StrandSummary> {
  constructor() {
    super('sparx.content.summaries.v1.StrandSummary', [
      {
        no: 1,
        name: 'strand',
        kind: 'message',
        T: () => Strand,
        options: { 'sparx.api.typescript.required': true },
      },
      {
        no: 2,
        name: 'substrand_summaries',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SubstrandSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.StrandSummary
 */
export const StrandSummary = new StrandSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubstrandSummary$Type extends MessageType<SubstrandSummary> {
  constructor() {
    super('sparx.content.summaries.v1.SubstrandSummary', [
      {
        no: 1,
        name: 'substrand',
        kind: 'message',
        T: () => Substrand,
        options: { 'sparx.api.typescript.required': true },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.SubstrandSummary
 */
export const SubstrandSummary = new SubstrandSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurriculumLUTopicsMapRequest$Type extends MessageType<GetCurriculumLUTopicsMapRequest> {
  constructor() {
    super('sparx.content.summaries.v1.GetCurriculumLUTopicsMapRequest', [
      {
        no: 1,
        name: 'topic_parent',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'view',
        kind: 'enum',
        T: () => [
          'sparx.content.summaries.v1.LUTopicsMapView',
          LUTopicsMapView,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.GetCurriculumLUTopicsMapRequest
 */
export const GetCurriculumLUTopicsMapRequest =
  new GetCurriculumLUTopicsMapRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurriculumLUTopicsMapResponse$Type extends MessageType<GetCurriculumLUTopicsMapResponse> {
  constructor() {
    super('sparx.content.summaries.v1.GetCurriculumLUTopicsMapResponse', [
      {
        no: 1,
        name: 'learning_unit_topics_map',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => TopicList },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.GetCurriculumLUTopicsMapResponse
 */
export const GetCurriculumLUTopicsMapResponse =
  new GetCurriculumLUTopicsMapResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopicList$Type extends MessageType<TopicList> {
  constructor() {
    super('sparx.content.summaries.v1.TopicList', [
      {
        no: 1,
        name: 'topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Topic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.TopicList
 */
export const TopicList = new TopicList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSkillSummaryRequest$Type extends MessageType<GetSkillSummaryRequest> {
  constructor() {
    super('sparx.content.summaries.v1.GetSkillSummaryRequest', [
      { no: 1, name: 'skill_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.GetSkillSummaryRequest
 */
export const GetSkillSummaryRequest = new GetSkillSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSkillSummaryResponse$Type extends MessageType<GetSkillSummaryResponse> {
  constructor() {
    super('sparx.content.summaries.v1.GetSkillSummaryResponse', [
      { no: 1, name: 'skill_summary', kind: 'message', T: () => SkillSummary },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.GetSkillSummaryResponse
 */
export const GetSkillSummaryResponse = new GetSkillSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SkillSummary$Type extends MessageType<SkillSummary> {
  constructor() {
    super('sparx.content.summaries.v1.SkillSummary', [
      { no: 1, name: 'skill', kind: 'message', T: () => Skill },
      {
        no: 2,
        name: 'sample_question',
        kind: 'message',
        T: () => QuestionSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.content.summaries.v1.SkillSummary
 */
export const SkillSummary = new SkillSummary$Type();
/**
 * @generated ServiceType for protobuf service sparx.content.summaries.v1.TopicSummaries
 */
export const TopicSummaries = new ServiceType(
  'sparx.content.summaries.v1.TopicSummaries',
  [
    {
      name: 'ListTopicSummaries',
      options: {
        'google.api.http': { get: '/v1/{topic_parent=curriculums/*}/topics' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'contentsummaries:topic',
          domain: 'content',
        },
      },
      I: ListTopicSummariesRequest,
      O: ListTopicSummariesResponse,
    },
    {
      name: 'GetTopicSummary',
      options: {
        'google.api.http': { get: '/v1/{topic_name=curriculums/*/topics/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'contentsummaries:topic',
          domain: 'content',
        },
      },
      I: GetTopicSummaryRequest,
      O: GetTopicSummaryResponse,
    },
    {
      name: 'GetCurriculumLUTopicsMap',
      options: {
        'google.api.http': {
          get: '/v1/{topic_parent=curriculums/*}/lutopicsmap',
        },
        'sparx.api.auth': {
          action: 'read',
          resource: 'contentsummaries:topic',
          domain: 'content',
        },
      },
      I: GetCurriculumLUTopicsMapRequest,
      O: GetCurriculumLUTopicsMapResponse,
    },
  ],
);
/**
 * @generated ServiceType for protobuf service sparx.content.summaries.v1.CurriculumSummaries
 */
export const CurriculumSummaries = new ServiceType(
  'sparx.content.summaries.v1.CurriculumSummaries',
  [
    {
      name: 'ListCurriculumSummaries',
      options: {
        'google.api.http': { get: '/v1/curriculums' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'contentsummaries:curriculum',
          domain: 'content',
        },
      },
      I: ListCurriculumSummariesRequest,
      O: ListCurriculumSummariesResponse,
    },
    {
      name: 'GetCurriculumSummary',
      options: {
        'google.api.http': { get: '/v1/{curriculum_name=curriculums/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'contentsummaries:curriculum',
          domain: 'content',
        },
      },
      I: GetCurriculumSummaryRequest,
      O: GetCurriculumSummaryResponse,
    },
  ],
);
/**
 * @generated ServiceType for protobuf service sparx.content.summaries.v1.SkillSummaries
 */
export const SkillSummaries = new ServiceType(
  'sparx.content.summaries.v1.SkillSummaries',
  [
    {
      name: 'GetSkillSummary',
      options: {
        'google.api.http': { get: '/v1/{skill_name=skills/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'contentsummaries:skill',
          domain: 'content',
        },
      },
      I: GetSkillSummaryRequest,
      O: GetSkillSummaryResponse,
    },
  ],
);

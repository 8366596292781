import { Stack } from '../components/Stack';
import { useSlideyGroupContext } from '../question/SlideyContext';
import {
  LayoutElementProps,
  usePredictableShuffleContent,
  useSparxQuestionContext,
} from '../question/SparxQuestionContext';
import { IGroupElement } from '../question/types';
import { CardElementWrapper } from './CardElement';

export const CardsElement = ({ element }: LayoutElementProps<IGroupElement>) => {
  const isWithinSlidey = useSlideyGroupContext().slidey;

  // Ensure shuffled cards
  const ctx = useSparxQuestionContext();
  const cardGroup = ctx.input?.card_groups?.[element.id || ''];
  const inlineCards = usePredictableShuffleContent(element.content, Boolean(cardGroup?.shuffle));

  if (element.id && !isWithinSlidey) {
    // If the cards element has an id, and we're not in a slidey element
    // then we should instead render the cards in the multi gap.
    return null;
  }

  const nodes: JSX.Element[] = [];
  for (const content of inlineCards) {
    switch (content.element) {
      case 'card':
        nodes.push(<CardElementWrapper element={content} key={content.ref} />);
        break;
      default:
        nodes.push(<div>UNKNOWN element: {content.element}</div>);
        break;
    }
  }

  return (
    <Stack dataTag={isWithinSlidey ? 'cards' : undefined} wrapChildren>
      {nodes}
    </Stack>
  );
};

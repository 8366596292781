import {
  faCheck,
  faChevronRight,
  faTimes,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Package,
  StoredAnswerState,
  TaskItem_Status,
} from '@sparx/api/apis/sparx/packageactivity/v1/package';
import classNames from 'classnames';

import styles from './QuestionNavigator.module.css';

interface QuestionNavigatorProps {
  pkg: Package;
  currentTaskItem?: string | null;
  setCurrentTaskItem: (taskItem: string) => void;
  showResult?: boolean;
  showAITasks?: boolean;
}

export const QuestionNavigator = ({
  pkg,
  currentTaskItem,
  setCurrentTaskItem,
  showResult,
  showAITasks,
}: QuestionNavigatorProps) => {
  const items = pkg.contents?.tasks?.map(task => (
    <div key={task.name} className={styles.QuestionListTask}>
      {task?.contents?.taskItems.map((item, index) => (
        <div
          key={item.name}
          onClick={() => setCurrentTaskItem(item.name)}
          className={classNames(styles.QuestionListItem, {
            [styles.QuestionListItemActive]: item.name === currentTaskItem,
            [styles.QuestionListItemCorrect]:
              showResult && item.state?.status === TaskItem_Status.CORRECT,
            [styles.QuestionListItemIncorrect]:
              showResult && item.state?.status === TaskItem_Status.INCORRECT,
            [styles.QuestionListItemAI]: showAITasks && item.annotations['ai'] === 'has',
          })}
          data-taskitem-name={item.name}
        >
          <div
            className={classNames(styles.QuestionListItemStatus, {
              [styles.QuestionListItemStatusStored]:
                item.state?.storedAnswerState === StoredAnswerState.STORED,
              [styles.QuestionListItemStatusStoredComplete]:
                item.state?.storedAnswerState === StoredAnswerState.STORED_COMPLETE,
              [styles.QuestionListItemStatusResult]: showResult,
            })}
          >
            {showResult &&
              (item.state?.status === TaskItem_Status.CORRECT ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : item.state?.status === TaskItem_Status.INCORRECT ? (
                <FontAwesomeIcon icon={faTimes} />
              ) : (
                <span className={styles.QuestionListItemStatusUnattempted}>U</span>
              ))}
          </div>
          <span>{item.title ? `Q${item.title}` : `Q${index + 1}`}</span>

          {showAITasks && item.annotations['ai'] === 'has' && (
            <FontAwesomeIcon icon={faWandMagicSparkles} className={styles.AIIcon} />
          )}

          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      ))}

      {!showResult && (
        <div
          className={classNames(styles.QuestionListItem, {
            [styles.QuestionListItemActive]: currentTaskItem === 'finish',
          })}
          onClick={() => setCurrentTaskItem('finish')}
        >
          <span>Finish</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
    </div>
  ));
  return <>{items}</>;
};

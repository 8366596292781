// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/sparxweb/v1/taskstate.proto" (package "sparx.sparxweb.v1", syntax proto3),// tslint:disable
// @ts-nocheck
/**
 * *
 * TaskState describes the state or mode of a task. Quiz tasks are an example task where
 * this is used, as the student could be viewing the question view or the answer view.
 *
 * @generated from protobuf enum sparx.sparxweb.v1.TaskState
 */
export enum TaskState {
  /**
   * Unspecified default value
   *
   * @generated from protobuf enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,
  /**
   * Used for a quiz task, displays the quiz questions.
   *
   * @generated from protobuf enum value: STATE_QUIZ_VIEW = 1;
   */
  STATE_QUIZ_VIEW = 1,
  /**
   * Used a quiz task, displays the quiz answer entry screen.
   *
   * @generated from protobuf enum value: STATE_QUIZ_ANSWER = 2;
   */
  STATE_QUIZ_ANSWER = 2,
  /**
   * TODO: featureFlag: lsi (temporary)
   *
   * @generated from protobuf enum value: STATE_KEY_SKILL_ITEM_1 = 51;
   */
  STATE_KEY_SKILL_ITEM_1 = 51,
  /**
   * @generated from protobuf enum value: STATE_KEY_SKILL_ITEM_2 = 52;
   */
  STATE_KEY_SKILL_ITEM_2 = 52,
  /**
   * @generated from protobuf enum value: STATE_KEY_SKILL_ITEM_3 = 53;
   */
  STATE_KEY_SKILL_ITEM_3 = 53,
  /**
   * @generated from protobuf enum value: STATE_KEY_SKILL_ITEM_4 = 54;
   */
  STATE_KEY_SKILL_ITEM_4 = 54,
  /**
   * @generated from protobuf enum value: STATE_KEY_SKILL_ITEM_5 = 55;
   */
  STATE_KEY_SKILL_ITEM_5 = 55,
}

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBook, faCalculator, faFlask } from '@fortawesome/free-solid-svg-icons';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

interface Subject {
  name: string;
  key: string;
  icon: IconDefinition;
  color: string;
}

export const useSubjects = <TData = Subject[]>(opts?: UseQueryOptions<Subject[], Error, TData>) =>
  useQuery({
    queryKey: ['subjects'],
    queryFn: async () => [
      { name: 'Maths', key: 'maths', icon: faCalculator, color: 'var(--product-maths)' },
      { name: 'English', key: 'english', icon: faBook, color: 'var(--product-reader)' },
      { name: 'Science', key: 'science', icon: faFlask, color: 'var(--product-science)' },
    ],
    ...opts,
  });

export const useSubject = (key: string) =>
  useSubjects({
    select: subjects => subjects.find(subject => subject.key === key),
  });

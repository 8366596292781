// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/hundredclub/v1/hundredclub.proto" (package "sparx.games.state.hundredclub.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
//
// The messages below correspond to the data values that are saved for each
// user so that progress is retained between sessions
//
// Hundred Club saves the following:
// - For each club attempted:
// - the fastest time
// - the most correct answers
// - the second-fastest time

/**
 * @generated from protobuf message sparx.games.state.hundredclub.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: map<string, sparx.games.state.hundredclub.v1.ClubRecord> clubRecords = 1;
   */
  clubRecords: {
    [key: string]: ClubRecord;
  };
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 2;
   */
  lastUpdatedTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.games.state.hundredclub.v1.ClubRecord
 */
export interface ClubRecord {
  /**
   * @generated from protobuf field: uint32 record = 1;
   */
  record: number;
  /**
   * @generated from protobuf field: float bestTime = 2;
   */
  bestTime: number;
  /**
   * @generated from protobuf field: float previousBestTime = 3;
   */
  previousBestTime: number;
}
//
// The only action fired by Hundred Club is a "cancel" action, called when
// the game ends of its own accord (i.e. when the ending screen times out)

/**
 * @generated from protobuf message sparx.games.state.hundredclub.v1.Action
 */
export interface Action {
  /**
   * the values that can be sent with an action
   *
   * @generated from protobuf field: sparx.games.state.hundredclub.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * the names of actions that can be sent
 *
 * @generated from protobuf enum sparx.games.state.hundredclub.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * @generated from protobuf enum value: cancel = 0;
   */
  cancel = 0,
  /**
   * @generated from protobuf enum value: quitGame = 1;
   */
  quitGame = 1,
}
//
// Hundred Club does not do any processing on the cloud, so the messages below
// are only here because they are expected by game.go

/**
 * @generated from protobuf message sparx.games.state.hundredclub.v1.State
 */
export interface State {}
/**
 * @generated from protobuf message sparx.games.state.hundredclub.v1.Init
 */
export interface Init {}
/**
 * @generated from protobuf message sparx.games.state.hundredclub.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.hundredclub.v1.Params
 */
export interface Params {}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.hundredclub.v1.Store', [
      {
        no: 1,
        name: 'clubRecords',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => ClubRecord },
      },
      {
        no: 2,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.hundredclub.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClubRecord$Type extends MessageType<ClubRecord> {
  constructor() {
    super('sparx.games.state.hundredclub.v1.ClubRecord', [
      { no: 1, name: 'record', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 2, name: 'bestTime', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 3,
        name: 'previousBestTime',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.hundredclub.v1.ClubRecord
 */
export const ClubRecord = new ClubRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.hundredclub.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.hundredclub.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.hundredclub.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.hundredclub.v1.State', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.hundredclub.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.hundredclub.v1.Init', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.hundredclub.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.hundredclub.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.hundredclub.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.hundredclub.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.hundredclub.v1.Params
 */
export const Params = new Params$Type();

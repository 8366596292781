import { UserInteractionEventFields } from '@sparx/analytics';
import {
  Assessment,
  AssessmentQuestion,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Checkbox } from '@sparx/sparx-design/components';
import { Button } from '@sparx/sparx-design/components/button/Button';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { useEffect, useRef, useState } from 'react';

import {
  nextStudentButtonEvent,
  previousStudentButtonEvent,
  setStudentAbsentAbortEvent,
  setStudentAbsentConfirmEvent,
  setStudentAbsentEvent,
} from '../analytics';
import { StudentSettings } from '../AssessmentDataEntry';
import styles from './DataEntryTable.module.css';
import { QLARow } from './QLARow';

const UP_ARROW = 'ArrowUp';
const DOWN_ARROW = 'ArrowDown';
const LEFT_ARROW = 'ArrowLeft';
const RIGHT_ARROW = 'ArrowRight';

export interface IDataEntryTableProps {
  currentStudent: Student;
  assessment: Assessment;
  currentAssessment: StudentAssessment | undefined;
  questionIDToQuestion: Map<string, AssessmentQuestion>;
  onStudentAbsent: (bool: boolean) => void;
  onUpdateMark: (questionName: string, mark: number | undefined, attempted: boolean) => void;
  onNextStudent: () => void;
  onPreviousStudent: () => void;
  hasPreviousStudent: boolean;
  hasNextStudent: boolean;
  saveMessage: JSX.Element | null;
  sendEvent: (event: UserInteractionEventFields) => void;
  studentSettings?: StudentSettings;
}

export const DataEntryTable = ({
  currentStudent,
  assessment,
  currentAssessment,
  questionIDToQuestion,
  onStudentAbsent,
  onUpdateMark,
  onPreviousStudent,
  onNextStudent,
  hasNextStudent,
  hasPreviousStudent,
  saveMessage,
  sendEvent,
  studentSettings,
}: IDataEntryTableProps) => {
  const [currentRow, setCurrentRow] = useState(0);
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);
  const nextButton = useRef<HTMLButtonElement | null>(null);
  const previousButton = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (currentStudent) {
      setCurrentRow(0);
    }
  }, [currentStudent]);

  useEffect(() => {
    const r = rowRefs.current[currentRow];
    if (r) {
      r.scrollIntoView({ block: 'nearest' });
    }
  }, [currentRow]);

  const [showingAbsentConfirmation, setShowingAbsentConfirmation] = useState(false);

  const getNumMarksSubmitted = () => {
    let numMarks = 0;
    if (currentAssessment) {
      for (const mark of currentAssessment.marks) {
        if (mark.score !== undefined) {
          numMarks++;
        }
      }
    }
    return numMarks;
  };
  const _onStudentAbsentCancel = () => {
    sendEvent(setStudentAbsentAbortEvent(currentStudent.studentId, getNumMarksSubmitted()));
    setShowingAbsentConfirmation(false);
  };
  const _onStudentAbsentConfirm = () => {
    sendEvent(setStudentAbsentConfirmEvent(currentStudent.studentId, getNumMarksSubmitted()));
    setShowingAbsentConfirmation(false);
    onStudentAbsent(true);
  };
  const _onStudentAbsent = (absent: boolean) => {
    if (currentAssessment) {
      // If the teacher is marking the student as absent but there are marks
      // submitted, make sure the teacher wants to overwrite them.
      if (absent) {
        if (getNumMarksSubmitted() > 0) {
          setShowingAbsentConfirmation(true);
          return;
        }
      }

      onStudentAbsent(absent);
      sendEvent(setStudentAbsentEvent(currentStudent.studentId, absent));
    }
  };

  return (
    <>
      {studentSettings?.component && (
        <div className={styles.CustomComponent}>{studentSettings.component}</div>
      )}
      <div className={styles.Header}>
        <h2 className={styles.StudentName}>
          {currentStudent.givenName} {currentStudent.familyName}
        </h2>

        <Modal isOpen={showingAbsentConfirmation} onClose={_onStudentAbsentCancel}>
          <Modal.Content width="md">
            <Modal.CloseButton />
            <Modal.Title>Are you sure you want to mark this student as absent?</Modal.Title>
            <Modal.Body>
              <p>This will remove any marks you have added and set every question to 0.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button colour="custom" onClick={_onStudentAbsentCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                colour="blue"
                className={styles.ConfirmAbsent}
                onClick={_onStudentAbsentConfirm}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>

        <Checkbox
          id="absent-checkbox"
          checked={!!currentAssessment?.absent}
          onCheckedChange={state => _onStudentAbsent(Boolean(state))}
          label="Student absent for assessment"
          disabled={studentSettings?.disabled}
        />
      </div>
      <div className={styles.SaveMessage}>{saveMessage}</div>
      <div className={styles.Content}>
        <table
          className={styles.Table}
          onKeyDown={e => {
            if (e.key === DOWN_ARROW && currentRow + 1 <= assessment.questions.length) {
              setCurrentRow(currentRow + 1);
            }

            if (e.key === DOWN_ARROW && currentRow + 1 === assessment.questions.length) {
              nextButton.current?.focus();
            }

            if (e.key === UP_ARROW && currentRow - 1 >= 0) {
              setCurrentRow(currentRow - 1);
            }
          }}
        >
          <thead>
            <tr>
              <td>Question no</td>
              <td>Mark</td>
            </tr>
          </thead>
          <tbody>
            {currentAssessment?.marks.map((r, i) => {
              const question = questionIDToQuestion.get(r.assessmentQuestionName);
              if (!question) {
                return null;
              }

              return (
                <QLARow
                  ref={(el: HTMLTableRowElement) => (rowRefs.current[i] = el)}
                  key={r.assessmentQuestionName}
                  studentID={currentStudent?.studentId}
                  studentAbsent={!!currentAssessment?.absent}
                  row={{
                    questionNumber: question.displayName,
                    mark: {
                      givenMark: r.score,
                      totalMarks: question.availableMarks,
                      attempted: r.attempted,
                    },
                  }}
                  isCurrentRow={i === currentRow}
                  onRowCompleted={() => {
                    if (i + 1 <= assessment.questions.length) {
                      setCurrentRow(i + 1);
                    }
                    if (i + 1 === assessment.questions.length) {
                      nextButton.current?.focus();
                    }
                  }}
                  onClick={() => setCurrentRow(i)}
                  onUpdate={(mark: number | undefined, attempted: boolean) =>
                    onUpdateMark(r.assessmentQuestionName, mark, attempted)
                  }
                  isDisabled={studentSettings?.disabled}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        className={styles.Controls}
        onKeyDown={e => {
          if (e.key === UP_ARROW) {
            setCurrentRow(assessment.questions.length - 1);
          }
          if (e.key === RIGHT_ARROW) {
            nextButton.current?.focus();
          }
          if (e.key === LEFT_ARROW) {
            previousButton.current?.focus();
          }
        }}
      >
        <Button
          ref={previousButton}
          variant="outlined"
          onClick={() => {
            sendEvent(previousStudentButtonEvent());
            onPreviousStudent();
          }}
          disabled={!hasPreviousStudent}
        >
          Previous student
        </Button>

        <Button
          ref={nextButton}
          onClick={() => {
            sendEvent(nextStudentButtonEvent());
            onNextStudent();
          }}
          variant="outlined"
          disabled={!hasNextStudent}
        >
          Next student
        </Button>
      </div>
    </>
  );
};

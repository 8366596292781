import { Hint } from '@sparx/api/sparxweb/swmsg/sparxweb';

import { IElement, IGroupElement } from '../../../question/types';
import { FractionHintInfo, HintInfo } from '../types';

/**
 * getFractionHintForElement returns a hint for the given element if it is a fraction element which
 * contains an input with a reference which exists in the given hintsMap.
 * It returns the hint for the first matching ref found, if any.
 */
export const getFractionHintForElement = (
  element: IElement,
  hintsMap: Partial<Record<string, HintInfo>> | undefined,
) => {
  for (const ref in hintsMap) {
    if (isGroupElementFractionContainingRef(element, ref)) {
      const h = hintsMap[ref];
      if (hintIsFractionsHint(h)) {
        return h;
      }
    }
  }
  return undefined;
};

const isGroupElementFractionContainingRef = (group: IElement, ref: string) => {
  if (!isElementFraction(group)) {
    return false;
  }
  for (const element of group.content) {
    if (element.element === 'number-field' && element.ref === ref) {
      return true;
    }
  }
  return false;
};

const isElementFraction = (group: IElement): group is IGroupElement => {
  return group.element === 'group' && group.style === 'fraction';
};

const hintIsFractionsHint = (hint: HintInfo | undefined): hint is FractionHintInfo =>
  hint?.hintType === Hint.SIMPLIFY_FRACTION;

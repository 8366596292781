import classNames from 'classnames';
import { ComponentProps, PropsWithChildren } from 'react';

import styles from './PageContainer.module.css';

interface PageContainerProps extends ComponentProps<'div'> {
  fullWidth?: boolean;
  filled?: boolean;
}

export const PageContainer = ({
  children,
  fullWidth,
  filled,
  className,
  ...rest
}: PropsWithChildren<PageContainerProps>) => (
  <div
    className={classNames(
      styles.Page,
      {
        [styles.FullWidth]: fullWidth,
        [styles.Filled]: filled,
      },
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

export const PageContainerScroll = ({ children }: PropsWithChildren) => (
  <div className={styles.ScrollContent}>{children}</div>
);

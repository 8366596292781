// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/gemsmash/v1/gemsmash.proto" (package "sparx.games.state.gemsmash.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
import { UInt64Value } from '../../../../../../google/protobuf/wrappers';
import { UInt32Value } from '../../../../../../google/protobuf/wrappers';
//
// The messages below correspond to the data values that are saved for each
// user so that progress is retained between sessions
//
// In Gem Smash this is:
// - data for the current state of the board
// - data for the current state of the play session (i.e. how long the
// player has spent on this board)
// - records for each time the board has been completed (i.e. how long
// it took, when it ended...)

/**
 * @generated from protobuf message sparx.games.state.gemsmash.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: string startDate = 1;
   */
  startDate: string;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value stage = 2;
   */
  stage?: UInt32Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value challenge = 3;
   */
  challenge?: UInt32Value;
  /**
   * @generated from protobuf field: uint32 combo = 4;
   */
  combo: number;
  /**
   * @generated from protobuf field: google.protobuf.UInt64Value playTime = 5;
   */
  playTime?: UInt64Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value moveCount = 6;
   */
  moveCount?: UInt32Value;
  /**
   * @generated from protobuf field: map<string, uint32> layout = 7;
   */
  layout: {
    [key: string]: number;
  };
  /**
   * @generated from protobuf field: map<string, sparx.games.state.gemsmash.v1.RecordValue> records = 8;
   */
  records: {
    [key: string]: RecordValue;
  };
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 9;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: uint32 quizPhasesCompleted = 10;
   */
  quizPhasesCompleted: number;
  /**
   * @generated from protobuf field: bool completedTutorial = 11;
   */
  completedTutorial: boolean;
}
/**
 * @generated from protobuf message sparx.games.state.gemsmash.v1.RecordValue
 */
export interface RecordValue {
  /**
   * @generated from protobuf field: uint32 moveCount = 1;
   */
  moveCount: number;
  /**
   * @generated from protobuf field: uint32 playTime = 2;
   */
  playTime: number;
  /**
   * @generated from protobuf field: string startDate = 3;
   */
  startDate: string;
  /**
   * @generated from protobuf field: string endDate = 4;
   */
  endDate: string;
  /**
   * @generated from protobuf field: string timestamp = 5;
   */
  timestamp: string;
  /**
   * @generated from protobuf field: uint32 maxLevel = 6;
   */
  maxLevel: number;
}
//
// Gem Smash does not do any processing on the cloud, so the messages below are
// only here because they are expected by game.go

/**
 * @generated from protobuf message sparx.games.state.gemsmash.v1.State
 */
export interface State {}
/**
 * @generated from protobuf message sparx.games.state.gemsmash.v1.Init
 */
export interface Init {}
/**
 *
 * The only action fired by GemSmash is a "quitGame" action, called when
 * the user presses an in-game "quit" button
 *
 * @generated from protobuf message sparx.games.state.gemsmash.v1.Action
 */
export interface Action {
  /**
   * the values that can be sent with an action
   *
   * @generated from protobuf field: sparx.games.state.gemsmash.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * the names of actions that can be sent
 *
 * @generated from protobuf enum sparx.games.state.gemsmash.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * @generated from protobuf enum value: quitGame = 0;
   */
  quitGame = 0,
}
/**
 * @generated from protobuf message sparx.games.state.gemsmash.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.gemsmash.v1.Params
 */
export interface Params {}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.gemsmash.v1.Store', [
      { no: 1, name: 'startDate', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'stage', kind: 'message', T: () => UInt32Value },
      { no: 3, name: 'challenge', kind: 'message', T: () => UInt32Value },
      { no: 4, name: 'combo', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 5, name: 'playTime', kind: 'message', T: () => UInt64Value },
      { no: 6, name: 'moveCount', kind: 'message', T: () => UInt32Value },
      {
        no: 7,
        name: 'layout',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      },
      {
        no: 8,
        name: 'records',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => RecordValue },
      },
      {
        no: 9,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 10,
        name: 'quizPhasesCompleted',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 11,
        name: 'completedTutorial',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gemsmash.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordValue$Type extends MessageType<RecordValue> {
  constructor() {
    super('sparx.games.state.gemsmash.v1.RecordValue', [
      { no: 1, name: 'moveCount', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 2, name: 'playTime', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 3, name: 'startDate', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'endDate', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'timestamp', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'maxLevel', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gemsmash.v1.RecordValue
 */
export const RecordValue = new RecordValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.gemsmash.v1.State', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gemsmash.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.gemsmash.v1.Init', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gemsmash.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.gemsmash.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.gemsmash.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gemsmash.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.gemsmash.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gemsmash.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.gemsmash.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gemsmash.v1.Params
 */
export const Params = new Params$Type();

import { faArrowDown, faArrowUp, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Assessment,
  AssessmentQuestion,
  Mark,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useDebounce } from '@sparx/react-utils/hooks/use-debounce';
import { ProgressBar } from '@sparx/sparx-design/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';
import Select from 'react-select';

import { useUpdateStudentAssessment } from '../../api/assessment';
import { useStudentAssessments } from '../../api/hooks';
import { useAssessmentContext } from '../../context';
import { searchedForStudentEvent } from './analytics';
import styles from './AssessmentDataEntry.module.css';
import { DataEntryTable, IDataEntryTableProps } from './DataEntryTable/DataEntryTable';
import { StudentTable } from './StudentTable/StudentTable';

const DEBOUNCE_DELAY = 1500;

const isYearGroup = (group: Group | YearGroup) => !('misTeachingGroupId' in group);

/**
 * AssessmentDataEntry allows teachers to enter student marks for the selected assessment and
 * student group.
 */

export interface StudentSettings {
  disabled?: boolean;
  component?: ReactNode;
  mode?: 'online' | 'paper';
  hasOnline?: boolean;
  showResult?: boolean;
}

interface IAssessmentDataEntryProps {
  assessment: Assessment;

  students: Student[];
  currentGroup: Group | YearGroup;
  groups: Group[];

  selectedStudentId: string | undefined;
  setSelectedStudentId: (studentId: string) => void;

  children: ({ progress, content }: { progress: ReactNode; content: ReactNode }) => ReactNode;

  customComponent?: (props: IDataEntryTableProps | undefined) => ReactNode;
  studentSettings?: Map<string, StudentSettings>;
}

export const AssessmentDataEntry = ({
  assessment,
  students,
  currentGroup,
  groups,
  selectedStudentId,
  setSelectedStudentId,
  children,
  customComponent,
  studentSettings,
}: IAssessmentDataEntryProps) => {
  const { sendEvent, loadingComponent } = useAssessmentContext();
  const [currentStudent, setCurrentStudent] = useState<Student | undefined>(undefined);
  const [currentAssessment, setCurrentAssessment] = useState<StudentAssessment | undefined>(
    undefined,
  );
  const [unsavedEdits, setUnsavedEdits] = useState<boolean>(false);
  const [lastSavedAt, setLastSavedAt] = useState<string | undefined>();
  const [saveError, setSaveError] = useState<boolean>(false);
  const debouncedAssessment = useDebounce(currentAssessment, DEBOUNCE_DELAY);

  const currentStudentClass = useMemo(
    () => groups.find(g => currentStudent?.studentGroupIds.includes(g.name.split('/')[3])),
    [groups, currentStudent],
  );
  const yearGroupID = currentStudentClass?.yearGroupId;

  const studentAssessments = useStudentAssessments(
    assessment.name,
    students.map(s => s.studentId),
  );
  const updateStudentAssessment = useUpdateStudentAssessment();

  const sortedStudents = useMemo(
    () =>
      students.sort(
        (a, b) =>
          a.familyName.localeCompare(b.familyName) ||
          a.givenName.localeCompare(b.givenName) ||
          a.studentId.localeCompare(b.studentId), // for stability
      ),
    [students],
  );

  useEffect(() => {
    if (
      debouncedAssessment &&
      currentAssessment &&
      debouncedAssessment === currentAssessment &&
      unsavedEdits
    ) {
      updateStudentAssessment.mutate(debouncedAssessment);
      setUnsavedEdits(false);
    }
  }, [debouncedAssessment, currentAssessment, updateStudentAssessment, unsavedEdits]);

  useEffect(() => {
    if (updateStudentAssessment.isSuccess) {
      updateStudentAssessment.reset();
      setSaveError(false);
      setLastSavedAt(format(new Date(), 'HH:mm:ss'));
    }
    if (updateStudentAssessment.isError) {
      setSaveError(true);
    }
  }, [updateStudentAssessment]);

  // Prompt if the user navigates elsewhere in the app with unsaved changes
  unstable_usePrompt({
    message: 'There are unsaved changes. Are you sure you want to leave?',
    when: unsavedEdits,
  });

  const currentStudentIndex = currentStudent ? sortedStudents.indexOf(currentStudent) : -1;
  const questionIDToQuestion = assessment?.questions.reduce((map, q) => {
    map.set(q.name, q);
    return map;
  }, new Map<string, AssessmentQuestion>());

  const setStudent = useCallback(
    (s: Student, skipQueryUpdate?: boolean) => {
      !skipQueryUpdate && setSelectedStudentId(s.studentId);
      setCurrentStudent(s);
      if (unsavedEdits && currentAssessment) {
        updateStudentAssessment.mutate(currentAssessment);
        setUnsavedEdits(false);
      }
      setCurrentAssessment(studentAssessments.data?.get(s.studentId));
    },
    [
      currentAssessment,
      unsavedEdits,
      setSelectedStudentId,
      setCurrentStudent,
      setUnsavedEdits,
      updateStudentAssessment,
      studentAssessments,
    ],
  );

  useMemo(() => {
    if (selectedStudentId && currentStudent?.studentId !== selectedStudentId) {
      const student = students.find(s => s.studentId === selectedStudentId);
      if (student) {
        setStudent(student, true);
      }
    }
  }, [setStudent, currentStudent, selectedStudentId, students]);

  const onNextStudent = () => {
    if (currentStudentIndex === undefined) {
      return;
    }
    if (currentStudentIndex + 1 <= sortedStudents.length) {
      setStudent(sortedStudents[currentStudentIndex + 1]);
    }
  };

  const onPreviousStudent = () => {
    if (currentStudentIndex === undefined) {
      return;
    }
    if (currentStudentIndex - 1 >= 0) {
      setStudent(sortedStudents[currentStudentIndex - 1]);
    }
  };

  // Update the yearGroupID on the assessment if it has changed
  const updateYearGroupID = (absent?: boolean) => {
    if (currentAssessment && yearGroupID && currentAssessment.yearGroupId !== yearGroupID) {
      const updatedAssessment: StudentAssessment = {
        ...currentAssessment,
        yearGroupId: yearGroupID,
      };
      if (absent !== undefined) {
        updatedAssessment.absent = absent;
      }
      setCurrentAssessment(updatedAssessment);
      setUnsavedEdits(true);
    }
  };

  const onUpdateMark = (questionName: string, mark: number | undefined, attempted: boolean) => {
    if (currentAssessment) {
      const updatedMarks = currentAssessment.marks.map(a => {
        if (a.assessmentQuestionName === questionName) {
          a.score = mark;
          a.attempted = attempted;
        }
        return a;
      });
      const updatedAssessment = { ...currentAssessment, marks: updatedMarks };
      setCurrentAssessment(updatedAssessment);
      setUnsavedEdits(true);
    }
    updateYearGroupID();
  };

  const onStudentAbsent = (absent: boolean) => {
    if (currentAssessment) {
      const updatedMarks = currentAssessment.marks.map(a => {
        a.attempted = absent;
        a.score = absent ? 0 : undefined;
        return a;
      });
      const updatedAssessment = { ...currentAssessment, absent, marks: updatedMarks };
      setCurrentAssessment(updatedAssessment);
      updateStudentAssessment.mutate(updatedAssessment);
    }
    updateYearGroupID(absent);
  };

  const retrySave = () => {
    if (debouncedAssessment) {
      updateStudentAssessment.mutate(debouncedAssessment);
    }
  };

  if (studentAssessments.isInitialLoading || !studentAssessments.data) {
    return loadingComponent;
  }
  if (!assessment || !questionIDToQuestion) {
    throw new Error('Not found');
  }

  const progress = (
    <EntryProgressBar
      assessment={assessment}
      studentAssessments={studentAssessments.data}
      studentsInGroup={sortedStudents}
      isYearGroup={isYearGroup(currentGroup)}
      questionIDToQuestion={questionIDToQuestion}
    />
  );

  const studentsPanel = (
    <div className={styles.Panel}>
      <div className={styles.PanelHeader}>
        <h3 className={styles.PanelTitle}>
          Students in {'displayName' in currentGroup ? currentGroup.displayName : currentGroup.name}
        </h3>
        <StudentSearch studentsInGroup={sortedStudents} setStudent={setStudent} />
      </div>

      <StudentTable
        currentStudent={currentStudent}
        setStudent={setStudent}
        studentsInGroup={sortedStudents}
        studentAssessments={studentAssessments.data}
        assessment={assessment}
        questionIDToQuestion={questionIDToQuestion}
        studentSettings={studentSettings}
      />
    </div>
  );

  let dataEntryProps: IDataEntryTableProps | undefined;
  if (currentStudent && currentStudentIndex !== -1) {
    dataEntryProps = {
      currentStudent: currentStudent,
      assessment: assessment,
      currentAssessment: currentAssessment,
      onStudentAbsent: onStudentAbsent,
      onUpdateMark: onUpdateMark,
      onNextStudent: onNextStudent,
      onPreviousStudent: onPreviousStudent,
      hasPreviousStudent: !!currentStudentIndex,
      hasNextStudent: currentStudentIndex + 1 < sortedStudents.length,
      questionIDToQuestion: questionIDToQuestion,
      saveMessage: (
        <SaveMessage lastSavedAt={lastSavedAt} saveError={saveError} retrySave={retrySave} />
      ),
      sendEvent,
      studentSettings: studentSettings?.get(currentStudent.studentId),
    };
  }

  const questionPanel = customComponent ? (
    customComponent(dataEntryProps)
  ) : (
    <div className={styles.Panel}>
      <div className={classNames(styles.PanelHeader, !currentStudent && styles.PanelHeaderFaded)}>
        <h3 className={styles.PanelTitle}>Assessment mark entry</h3>
        <NavigationInstructions />
      </div>
      {dataEntryProps ? (
        <DataEntryTable {...dataEntryProps} />
      ) : (
        <div className={styles.SelectStudentMessage}>
          <FontAwesomeIcon icon={faArrowLeft} />
          Select a student on the left to enter their assessment data
        </div>
      )}
    </div>
  );

  const content = (
    <div className={styles.Content}>
      {studentsPanel}
      {questionPanel}
    </div>
  );

  return children({ progress, content });
};

const NavigationInstructions = () => (
  <>
    <p className={styles.NavigationInstructions}>
      Navigate through the questions using the up and down arrow keys
    </p>
    <div className={styles.NavigationKey}>
      <p className={styles.NavigationKeyGroup}>
        <span className={styles.NavigationKeyKey}>
          <FontAwesomeIcon icon={faArrowUp} />
        </span>
        Previous Question
      </p>
      <p className={styles.NavigationKeyGroup}>
        <span className={styles.NavigationKeyKey}>
          <FontAwesomeIcon icon={faArrowDown} />
        </span>
        Next Question
      </p>
      <p className={styles.NavigationKeyGroup}>
        <span className={styles.NavigationKeyKey}>0-9</span>Enter Mark
      </p>
      <p className={styles.NavigationKeyGroup}>
        <span className={styles.NavigationKeyKey}>U</span> or{' '}
        <span className={styles.NavigationKeyKey}>-</span> Unattempted
      </p>
    </div>
  </>
);

const SaveMessage = ({
  lastSavedAt,
  saveError,
  retrySave,
}: {
  lastSavedAt: undefined | string;
  saveError: boolean;
  retrySave: () => void;
}) => {
  if (saveError) {
    return (
      <p className={styles.SaveMessageError}>
        <FontAwesomeIcon icon={faTriangleExclamation} />
        Error saving
        <span className={styles.SaveMessageLink} onClick={retrySave}>
          Click here to retry
        </span>
      </p>
    );
  }

  if (!lastSavedAt) {
    return null;
  }

  return <p className={styles.SaveMessageComplete}>Last saved at {lastSavedAt}</p>;
};

const StudentSearch = ({
  studentsInGroup,
  setStudent,
}: {
  studentsInGroup: Student[];
  setStudent: (s: Student) => void;
}) => {
  const { sendEvent } = useAssessmentContext();
  const [studentSearchValue, setStudentSearchValue] = useState<string>('');

  return (
    <Select
      inputValue={studentSearchValue}
      onInputChange={v => setStudentSearchValue(v)}
      onBlur={() => setStudentSearchValue('')}
      value={null}
      placeholder="Search for a student name"
      classNames={{
        control: () => styles.SearchBoxControl,
        container: () => styles.SearchBoxContainer,
        option: () => styles.SearchBoxOption,
        placeholder: () => styles.SearchBoxPlaceholder,
        indicatorSeparator: () => styles.SearchBoxIndicatorSeparator,
        input: () => styles.SearchBoxInput,
      }}
      onChange={(s: Student | string | null) => {
        // Only allow known students to be set
        if (s && typeof s !== 'string') {
          setStudent(s);
          setStudentSearchValue('');
          sendEvent(searchedForStudentEvent(s.studentId));
        }
      }}
      getOptionLabel={(o: Student) => `${o.givenName} ${o.familyName}`}
      options={studentsInGroup}
    />
  );
};

const EntryProgressBar = ({
  assessment,
  studentAssessments,
  questionIDToQuestion,
  studentsInGroup,
  isYearGroup,
}: {
  assessment: Assessment;
  studentAssessments: Map<string, StudentAssessment>;
  questionIDToQuestion: Map<string, AssessmentQuestion>;
  studentsInGroup: Student[];
  isYearGroup: boolean;
}) => {
  const currentProgress: { total: number; completed: number } = (() => {
    const allAssessments = Array.from(studentAssessments.values());
    const total = assessment.questions.length * studentsInGroup.length;
    const completed = allAssessments.flatMap(a =>
      a.marks.filter((m: Mark) => {
        if (m.score === undefined) {
          return false;
        }
        const q = questionIDToQuestion.get(m.assessmentQuestionName);
        return q ? m.score <= q.availableMarks : false;
      }),
    );
    return { total, completed: completed.length };
  })();

  const percentage = (currentProgress.completed / currentProgress.total) * 100;

  return (
    <div className={styles.ProgressBarContainer}>
      <div className={styles.ProgressBarText}>
        {isYearGroup ? 'Year group' : 'Class'} data entry progress
      </div>
      <div className={styles.ProgressBar}>
        <ProgressBar
          percentComplete={percentage}
          animateFill
          animateWobble
          containerClassName={styles.ProgressBarProgress}
          completionBarClassName={styles.ProgressBarComplete}
        />
      </div>
      <div className={styles.ProgressBarPercentage}>
        {Math.floor((currentProgress.completed / currentProgress.total) * 100)}%
      </div>
    </div>
  );
};

import { Date as pbDate } from '@sparx/api/google/type/date';
import { FormControl } from '@sparx/sparx-design/components/form/FormControl';
import { FormLabel } from '@sparx/sparx-design/components/form/FormLabel';
import { Input } from '@sparx/sparx-design/components/input/Input';
import inputStyles from '@sparx/sparx-design/components/input/Input.module.css';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import classNames from 'classnames';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import styles from './EditStudentForm.module.css';

export interface GuestStudentFields {
  givenName: string;
  familyName: string;
  dateOfBirth?: pbDate;
}

export const EditStudentForm = ({
  register,
  errors,
}: {
  register: UseFormRegister<GuestStudentFields>;
  errors: FieldErrors<GuestStudentFields>;
}) => {
  return (
    <Stack direction="column" spacing={4}>
      <FormControl isInvalid={Boolean(errors.givenName)}>
        <FormLabel>First name</FormLabel>
        <Input
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          type="text"
          {...register('givenName', {
            required: true,
          })}
        />
      </FormControl>
      <FormControl isInvalid={Boolean(errors.familyName)}>
        <FormLabel>Last name</FormLabel>
        <Input
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          type="text"
          {...register('familyName', {
            required: true,
          })}
        />
      </FormControl>
      <FormControl isInvalid={Boolean(errors.dateOfBirth)}>
        <FormLabel>Date of birth</FormLabel>
        <Stack>
          <Input
            autoComplete="off"
            type="number"
            style={{ width: 140 }}
            placeholder="Day"
            {...register('dateOfBirth.day', {
              valueAsNumber: true,
              required: true,
              min: 1,
              max: 31,
            })}
            min={1}
            max={31}
          />
          <select
            autoComplete="off"
            required={true}
            className={classNames(inputStyles.Input, styles.DateOfBirthSelect, {
              [inputStyles.Invalid]: errors.dateOfBirth,
            })}
            defaultValue=""
            {...register('dateOfBirth.month', {
              valueAsNumber: true,
              required: true,
              min: 1,
              max: 12,
            })}
          >
            <option value="" disabled>
              Month
            </option>
            {months.map((month, i) => (
              <option key={i} value={i + 1}>
                {month}
              </option>
            ))}
          </select>
          <Input
            autoComplete="off"
            style={{ width: 140 }}
            min={1900}
            max={new Date().getFullYear() - 5}
            type="number"
            placeholder="Year"
            {...register('dateOfBirth.year', {
              valueAsNumber: true,
              required: true,
              min: 1900,
              max: new Date().getFullYear() - 5,
            })}
          />
        </Stack>
      </FormControl>
    </Stack>
  );
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

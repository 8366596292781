// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/abilityvalidation/v1/abilityvalidation.proto" (package "sparx.games.progress.abilityvalidation.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { BoolValue } from '../../../../../../google/protobuf/wrappers';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
//
// Stored data to keep track of what questions the player needs to do
// Talk and Learn on before they can do 100 Club

/**
 * @generated from protobuf message sparx.games.progress.abilityvalidation.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * Set to true if the player should be allowed to access 100 Club
   * Set to false if they should not
   *
   * @generated from protobuf field: google.protobuf.BoolValue hasAllowed100ClubAttempt = 2;
   */
  hasAllowed100ClubAttempt?: BoolValue;
  /**
   * Set to true if the player has reached 30 Club, to signify validation complete
   * Set to false if the player gets demoted below 30 Club, so they have to do
   * validation again
   *
   * @generated from protobuf field: google.protobuf.BoolValue hasSucceeded100ClubAttempt = 3;
   */
  hasSucceeded100ClubAttempt?: BoolValue;
  /**
   * The date of the last time they completed a 100 Club first pass
   *
   * @generated from protobuf field: google.protobuf.Timestamp lastAttempted100ClubTimestamp = 4;
   */
  lastAttempted100ClubTimestamp?: Timestamp;
  /**
   * For each question, data to track whether or not it has been answered
   * fast enough. When a question is answered faster or more correctly than the
   * existing state, overwrite the existing state.
   *
   * @generated from protobuf field: map<string, sparx.games.progress.abilityvalidation.v1.QuestionState> questionStates = 5;
   */
  questionStates: {
    [key: string]: QuestionState;
  };
  /**
   * For students with data from before Ability Validation was added
   * If false, we need to check that the player is not already on 30 Club
   *  - if they are, validate them immediately
   *  - if they are not, reset them to first pass
   * Set to true when we have done this check
   *
   * @generated from protobuf field: google.protobuf.BoolValue hasPerformedInitialCheck = 6;
   */
  hasPerformedInitialCheck?: BoolValue;
}
/**
 * @generated from protobuf message sparx.games.progress.abilityvalidation.v1.QuestionState
 */
export interface QuestionState {
  /**
   * How long it took them to answer this question
   *
   * @generated from protobuf field: float timeTaken = 1;
   */
  timeTaken: number;
  /**
   * True if they answered correctly
   *
   * @generated from protobuf field: bool wasCorrect = 2;
   */
  wasCorrect: boolean;
  /**
   * Set to true to represent "this state should be overwritten by the next
   * response to this question, even if slow or wrong.
   *
   * @generated from protobuf field: google.protobuf.BoolValue isInvalidated = 3;
   */
  isInvalidated?: BoolValue;
  /**
   * When the question was answered
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 4;
   */
  timestamp?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.progress.abilityvalidation.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'hasAllowed100ClubAttempt',
        kind: 'message',
        T: () => BoolValue,
      },
      {
        no: 3,
        name: 'hasSucceeded100ClubAttempt',
        kind: 'message',
        T: () => BoolValue,
      },
      {
        no: 4,
        name: 'lastAttempted100ClubTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 5,
        name: 'questionStates',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => QuestionState },
      },
      {
        no: 6,
        name: 'hasPerformedInitialCheck',
        kind: 'message',
        T: () => BoolValue,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.abilityvalidation.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionState$Type extends MessageType<QuestionState> {
  constructor() {
    super('sparx.games.progress.abilityvalidation.v1.QuestionState', [
      { no: 1, name: 'timeTaken', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 2, name: 'wasCorrect', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'isInvalidated', kind: 'message', T: () => BoolValue },
      { no: 4, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.abilityvalidation.v1.QuestionState
 */
export const QuestionState = new QuestionState$Type();

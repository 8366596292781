import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { AnimatedCenteredPage } from 'components/centeredpage/CenteredPage';

import styles from './LargeLoading.module.css';

export const LargeLoadingPage = () => (
  <AnimatedCenteredPage>
    <LargeLoading />
  </AnimatedCenteredPage>
);

export const LargeLoading = () => (
  <div className={styles.LargeLoading}>
    <LoadingSpinner size="lg" />
  </div>
);

export enum SortDirection {
  Ascending = 1,
  Descending = -1,
}

export interface ISorting<SortBy, Fn> {
  dir: SortDirection;
  sortBy: SortBy;
  sortFn: Fn;
}

export type SortFunction<T> = (a: T, b: T) => number;
export type SortFnWithDir<T> = (dir: SortDirection) => SortFunction<T>;

export function composeSorts<T>(...sortFns: Array<SortFunction<T>>): SortFunction<T> {
  return (a, b) => {
    for (const fn of sortFns) {
      const val = fn(a, b);
      if (val !== 0) {
        return val;
      }
    }
    return 0;
  };
}

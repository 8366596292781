import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { AssessmentFixUp } from '@sparx/assessments/src/components/AssessmentFixUp';
import { useSchool } from 'api/school';
import { InProductButton } from 'components/button/InProductButton';
import { selectedGroupValue, useClassSelectionStudents } from 'components/header/BackLink';
import { Link } from 'react-router-dom';
import { getStudentGroupTypeForAssessment } from 'utils/assessments';

import iconFollowUpLarge from '../images/icon-large-follow-up.svg';
import styles from './FollowUpView.module.css';

export const FollowUpView = ({ assessment }: { assessment: Assessment }) => {
  if (assessment.subjectKey === 'science') {
    return <FollowUpViewInner assessment={assessment} />;
  }
  return <PlaceholderView assessment={assessment} />;
};

const PlaceholderView = ({ assessment }: { assessment: Assessment }) => {
  const { data: school } = useSchool();
  const enableUrl =
    window.settings?.sparxMathsUrl + `/teacher/${assessment.name}/fixup?s=${school?.urlPrefix}`;

  return (
    <div className={styles.FeatureHighlight}>
      <img src={iconFollowUpLarge} alt="" />
      <div className={styles.FeatureHighlightInfo}>
        <h2>Fluency Fix Up</h2>
        <p>
          Enable a &apos;Fluency Fix Up&apos; task for your students to go back and reattempt any
          incorrect answers and gain class level insights into Problem Solving question
          misconceptions.
        </p>
        <div className={styles.FeatureHighlightButtons}>
          <InProductButton feature="Enable" as={Link} to={enableUrl} target="_blank" />
        </div>
      </div>
    </div>
  );
};

const FollowUpViewInner = ({ assessment }: { assessment: Assessment }) => {
  const groupType = getStudentGroupTypeForAssessment(assessment);
  const { students, groups, selectedGroup } = useClassSelectionStudents(groupType);
  const group = selectedGroupValue(selectedGroup);

  if (!group) {
    return <>Select a group in header</>;
  }

  return (
    <AssessmentFixUp
      assessment={assessment}
      currentGroup={group}
      groups={groups.filter(g => g.type === groupType)}
      students={students}
    />
  );
};

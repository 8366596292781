import { Activity, ActivityActionRequest } from '@sparx/api/apis/sparx/packageactivity/v1/activity';
import { TaskItem_Status } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { useEffect } from 'react';

interface ActivityAction {
  action: ActivityActionRequest['action'];
  skipActivityUpdate?: boolean;
}

type ActivityNotifyMutateFunc = (args: {
  activityName: string;
  action: ActivityAction['action'];
}) => void;

export const useActivityViewNotify = (mutate: ActivityNotifyMutateFunc, activity: Activity) => {
  const activityName = activity.name;
  const activityEvaluation = activity.state?.skillActivity?.evaluation;
  const activityAnswered =
    activityEvaluation?.status === TaskItem_Status.TASK_ITEM_STATUS_UNSPECIFIED;
  useEffect(() => {
    if (activityName && !activityAnswered) {
      mutate({ activityName, action: { oneofKind: 'view', view: { unload: false } } });
      return () => {
        mutate({ activityName, action: { oneofKind: 'view', view: { unload: true } } });
      };
    }
  }, [mutate, activityName, activityAnswered]);
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "google/type/dayofweek.proto" (package "google.type", syntax proto3),// tslint:disable
// @ts-nocheck
//
// Copyright 2024 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
/**
 * Represents a day of the week.
 *
 * @generated from protobuf enum google.type.DayOfWeek
 */
export enum DayOfWeek {
  /**
   * The day of the week is unspecified.
   *
   * @generated from protobuf enum value: DAY_OF_WEEK_UNSPECIFIED = 0;
   */
  DAY_OF_WEEK_UNSPECIFIED = 0,
  /**
   * Monday
   *
   * @generated from protobuf enum value: MONDAY = 1;
   */
  MONDAY = 1,
  /**
   * Tuesday
   *
   * @generated from protobuf enum value: TUESDAY = 2;
   */
  TUESDAY = 2,
  /**
   * Wednesday
   *
   * @generated from protobuf enum value: WEDNESDAY = 3;
   */
  WEDNESDAY = 3,
  /**
   * Thursday
   *
   * @generated from protobuf enum value: THURSDAY = 4;
   */
  THURSDAY = 4,
  /**
   * Friday
   *
   * @generated from protobuf enum value: FRIDAY = 5;
   */
  FRIDAY = 5,
  /**
   * Saturday
   *
   * @generated from protobuf enum value: SATURDAY = 6;
   */
  SATURDAY = 6,
  /**
   * Sunday
   *
   * @generated from protobuf enum value: SUNDAY = 7;
   */
  SUNDAY = 7,
}

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/leagueman/v1/leagueman.proto" (package "sparx.games.leagueman.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.games.leagueman.v1.HealthCheckRequest
 */
export interface HealthCheckRequest {}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.HealthCheckResponse
 */
export interface HealthCheckResponse {
  /**
   * @generated from protobuf field: string message = 1;
   */
  message: string;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.Result
 */
export interface Result {
  /**
   * Student the result is for.
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * Game that the result was generated in.
   *
   * @generated from protobuf field: string gameID = 2;
   */
  gameID: string;
  /**
   * Score that was awarded during the play of the game.
   *
   * @generated from protobuf field: double score = 3;
   */
  score: number;
  /**
   * Timestamp that the score was awarded.
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 4;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: sparx.games.leagueman.v1.Avatar avatar = 5;
   */
  avatar?: Avatar;
  /**
   * studentgroup_id of the class the student belongs to
   *
   * @generated from protobuf field: string classID = 6;
   */
  classID: string;
}
/**
 * The definition of how a student is represented on a league table
 *
 * @generated from protobuf message sparx.games.leagueman.v1.Avatar
 */
export interface Avatar {
  /**
   * The name used to identify them (e.g. 'Adjective Animal')
   *
   * @generated from protobuf field: string nickname = 1;
   */
  nickname: string;
  /**
   * The colour that appears behind their icon (e.g. '#FFFFFF')
   *
   * @generated from protobuf field: string colour = 2;
   */
  colour: string;
  /**
   * The image that should be used as their icon (e.g. 'cowboy_hat')
   *
   * @generated from protobuf field: string symbol = 3;
   */
  symbol: string;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.CreateResultRequest
 */
export interface CreateResultRequest {
  /**
   * The result to create.
   *
   * @generated from protobuf field: sparx.games.leagueman.v1.Result result = 1;
   */
  result?: Result;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.CreateResultResponse
 */
export interface CreateResultResponse {}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.GetResultsRequest
 */
export interface GetResultsRequest {
  /**
   * Optional list of student ids to filter the results on.
   *
   * @generated from protobuf field: repeated string studentID = 1;
   */
  studentID: string[];
  /**
   * Game to load the results for.
   *
   * @generated from protobuf field: string gameID = 2;
   */
  gameID: string;
  /**
   * Minimum time results was awarded (inclusive). Can be excluded to be the beginning of time.
   *
   * @generated from protobuf field: google.protobuf.Timestamp startTimestamp = 3;
   */
  startTimestamp?: Timestamp;
  /**
   * Maximum time result was awarded (exclusive). Can be excluded to be now.
   *
   * @generated from protobuf field: google.protobuf.Timestamp endTimestamp = 4;
   */
  endTimestamp?: Timestamp;
  /**
   * How to return the selected results.
   *
   * @generated from protobuf field: sparx.games.leagueman.v1.GetResultsRequest.Selection selection = 5;
   */
  selection: GetResultsRequest_Selection;
}
/**
 * @generated from protobuf enum sparx.games.leagueman.v1.GetResultsRequest.Selection
 */
export enum GetResultsRequest_Selection {
  /**
   * Return each result separately.
   *
   * @generated from protobuf enum value: ALL = 0;
   */
  ALL = 0,
  /**
   * Return results grouped by studentID.
   *
   * @generated from protobuf enum value: SUM = 1;
   */
  SUM = 1,
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.GetResultsResponse
 */
export interface GetResultsResponse {
  /**
   * List of filtered results.
   *
   * @generated from protobuf field: repeated sparx.games.leagueman.v1.Result results = 1;
   */
  results: Result[];
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.GetAvatarRequest
 */
export interface GetAvatarRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.GetAvatarResponse
 */
export interface GetAvatarResponse {
  /**
   * @generated from protobuf field: sparx.games.leagueman.v1.Avatar avatar = 1;
   */
  avatar?: Avatar;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.SetAvatarRequest
 */
export interface SetAvatarRequest {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: sparx.games.leagueman.v1.Avatar avatar = 2;
   */
  avatar?: Avatar;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.SetAvatarResponse
 */
export interface SetAvatarResponse {}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.WeekIndex
 */
export interface WeekIndex {
  /**
   * @generated from protobuf field: int32 week = 1;
   */
  week: number;
  /**
   * @generated from protobuf field: int32 year = 2;
   */
  year: number;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.GetMedalsRequest
 */
export interface GetMedalsRequest {
  /**
   * @generated from protobuf field: string gameID = 1;
   */
  gameID: string;
  /**
   * The earliest time we can look back to when determining medals, in terms
   * of ISO indexed week and year
   *
   * @generated from protobuf field: sparx.games.leagueman.v1.WeekIndex medalStartWeekIndex = 2;
   */
  medalStartWeekIndex?: WeekIndex;
  /**
   * List of student ids to filter the results on.
   *
   * @generated from protobuf field: repeated string studentIDs = 3;
   */
  studentIDs: string[];
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.GetMedalsForStudentRequest
 */
export interface GetMedalsForStudentRequest {
  /**
   * @generated from protobuf field: string gameID = 1;
   */
  gameID: string;
  /**
   * @generated from protobuf field: string studentID = 2;
   */
  studentID: string;
  /**
   * The earliest time we can look back to when determining medals, in terms
   * of ISO indexed week and year
   *
   * @generated from protobuf field: sparx.games.leagueman.v1.WeekIndex medalStartWeekIndex = 3;
   */
  medalStartWeekIndex?: WeekIndex;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.GetMedalsResponse
 */
export interface GetMedalsResponse {
  /**
   * @generated from protobuf field: repeated sparx.games.leagueman.v1.Medal medals = 1;
   */
  medals: Medal[];
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.GetMedalsForStudentResponse
 */
export interface GetMedalsForStudentResponse {
  /**
   * @generated from protobuf field: repeated sparx.games.leagueman.v1.Medal medals = 1;
   */
  medals: Medal[];
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.Medal
 */
export interface Medal {
  /**
   * Student the result is for.
   *
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * Game that the result was generated in.
   *
   * @generated from protobuf field: string gameID = 2;
   */
  gameID: string;
  /**
   * Score that was awarded during the play of the game.
   *
   * @generated from protobuf field: double score = 3;
   */
  score: number;
  /**
   * The name of the medal, e.g. 'gold', 'silver', 'bronze'
   *
   * @generated from protobuf field: string medalName = 4;
   */
  medalName: string;
  /**
   * The week-year pairing that this medal was awarded for
   *
   * @generated from protobuf field: sparx.games.leagueman.v1.WeekIndex weekIndex = 5;
   */
  weekIndex?: WeekIndex;
  /**
   * The ID of the class that this medal was awarded for
   * or 'world' if a worldwide medal
   *
   * @generated from protobuf field: string class = 6;
   */
  class: string;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.BatchCreateMedalsRequest
 */
export interface BatchCreateMedalsRequest {
  /**
   * Optional - if specified, the game ID, otherwise will be "LeagueTableGame"
   *
   * @generated from protobuf field: string gameID = 1;
   */
  gameID: string;
  /**
   * Optional - if specified, the week to generate the medals for, otherwise it will be last week
   *
   * @generated from protobuf field: sparx.games.leagueman.v1.WeekIndex week = 2;
   */
  week?: WeekIndex;
}
/**
 * @generated from protobuf message sparx.games.leagueman.v1.BatchCreateMedalsResponse
 */
export interface BatchCreateMedalsResponse {
  /**
   * @generated from protobuf field: int32 createdCount = 1;
   */
  createdCount: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckRequest$Type extends MessageType<HealthCheckRequest> {
  constructor() {
    super('sparx.games.leagueman.v1.HealthCheckRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.HealthCheckRequest
 */
export const HealthCheckRequest = new HealthCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckResponse$Type extends MessageType<HealthCheckResponse> {
  constructor() {
    super('sparx.games.leagueman.v1.HealthCheckResponse', [
      { no: 1, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.HealthCheckResponse
 */
export const HealthCheckResponse = new HealthCheckResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Result$Type extends MessageType<Result> {
  constructor() {
    super('sparx.games.leagueman.v1.Result', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'gameID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'score', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 4, name: 'timestamp', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'avatar', kind: 'message', T: () => Avatar },
      { no: 6, name: 'classID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.Result
 */
export const Result = new Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Avatar$Type extends MessageType<Avatar> {
  constructor() {
    super('sparx.games.leagueman.v1.Avatar', [
      { no: 1, name: 'nickname', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'colour', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'symbol', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.Avatar
 */
export const Avatar = new Avatar$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateResultRequest$Type extends MessageType<CreateResultRequest> {
  constructor() {
    super('sparx.games.leagueman.v1.CreateResultRequest', [
      { no: 1, name: 'result', kind: 'message', T: () => Result },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.CreateResultRequest
 */
export const CreateResultRequest = new CreateResultRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateResultResponse$Type extends MessageType<CreateResultResponse> {
  constructor() {
    super('sparx.games.leagueman.v1.CreateResultResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.CreateResultResponse
 */
export const CreateResultResponse = new CreateResultResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResultsRequest$Type extends MessageType<GetResultsRequest> {
  constructor() {
    super('sparx.games.leagueman.v1.GetResultsRequest', [
      {
        no: 1,
        name: 'studentID',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'gameID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'startTimestamp', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'endTimestamp', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'selection',
        kind: 'enum',
        T: () => [
          'sparx.games.leagueman.v1.GetResultsRequest.Selection',
          GetResultsRequest_Selection,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.GetResultsRequest
 */
export const GetResultsRequest = new GetResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResultsResponse$Type extends MessageType<GetResultsResponse> {
  constructor() {
    super('sparx.games.leagueman.v1.GetResultsResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Result,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.GetResultsResponse
 */
export const GetResultsResponse = new GetResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvatarRequest$Type extends MessageType<GetAvatarRequest> {
  constructor() {
    super('sparx.games.leagueman.v1.GetAvatarRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.GetAvatarRequest
 */
export const GetAvatarRequest = new GetAvatarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvatarResponse$Type extends MessageType<GetAvatarResponse> {
  constructor() {
    super('sparx.games.leagueman.v1.GetAvatarResponse', [
      { no: 1, name: 'avatar', kind: 'message', T: () => Avatar },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.GetAvatarResponse
 */
export const GetAvatarResponse = new GetAvatarResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAvatarRequest$Type extends MessageType<SetAvatarRequest> {
  constructor() {
    super('sparx.games.leagueman.v1.SetAvatarRequest', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'avatar', kind: 'message', T: () => Avatar },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.SetAvatarRequest
 */
export const SetAvatarRequest = new SetAvatarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAvatarResponse$Type extends MessageType<SetAvatarResponse> {
  constructor() {
    super('sparx.games.leagueman.v1.SetAvatarResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.SetAvatarResponse
 */
export const SetAvatarResponse = new SetAvatarResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WeekIndex$Type extends MessageType<WeekIndex> {
  constructor() {
    super('sparx.games.leagueman.v1.WeekIndex', [
      { no: 1, name: 'week', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'year', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.WeekIndex
 */
export const WeekIndex = new WeekIndex$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMedalsRequest$Type extends MessageType<GetMedalsRequest> {
  constructor() {
    super('sparx.games.leagueman.v1.GetMedalsRequest', [
      { no: 1, name: 'gameID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'medalStartWeekIndex',
        kind: 'message',
        T: () => WeekIndex,
      },
      {
        no: 3,
        name: 'studentIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.GetMedalsRequest
 */
export const GetMedalsRequest = new GetMedalsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMedalsForStudentRequest$Type extends MessageType<GetMedalsForStudentRequest> {
  constructor() {
    super('sparx.games.leagueman.v1.GetMedalsForStudentRequest', [
      { no: 1, name: 'gameID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'medalStartWeekIndex',
        kind: 'message',
        T: () => WeekIndex,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.GetMedalsForStudentRequest
 */
export const GetMedalsForStudentRequest = new GetMedalsForStudentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMedalsResponse$Type extends MessageType<GetMedalsResponse> {
  constructor() {
    super('sparx.games.leagueman.v1.GetMedalsResponse', [
      {
        no: 1,
        name: 'medals',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Medal,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.GetMedalsResponse
 */
export const GetMedalsResponse = new GetMedalsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMedalsForStudentResponse$Type extends MessageType<GetMedalsForStudentResponse> {
  constructor() {
    super('sparx.games.leagueman.v1.GetMedalsForStudentResponse', [
      {
        no: 1,
        name: 'medals',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Medal,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.GetMedalsForStudentResponse
 */
export const GetMedalsForStudentResponse =
  new GetMedalsForStudentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Medal$Type extends MessageType<Medal> {
  constructor() {
    super('sparx.games.leagueman.v1.Medal', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'gameID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'score', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 4, name: 'medalName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'weekIndex', kind: 'message', T: () => WeekIndex },
      { no: 6, name: 'class', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.Medal
 */
export const Medal = new Medal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCreateMedalsRequest$Type extends MessageType<BatchCreateMedalsRequest> {
  constructor() {
    super('sparx.games.leagueman.v1.BatchCreateMedalsRequest', [
      { no: 1, name: 'gameID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'week', kind: 'message', T: () => WeekIndex },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.BatchCreateMedalsRequest
 */
export const BatchCreateMedalsRequest = new BatchCreateMedalsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCreateMedalsResponse$Type extends MessageType<BatchCreateMedalsResponse> {
  constructor() {
    super('sparx.games.leagueman.v1.BatchCreateMedalsResponse', [
      {
        no: 1,
        name: 'createdCount',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.leagueman.v1.BatchCreateMedalsResponse
 */
export const BatchCreateMedalsResponse = new BatchCreateMedalsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.games.leagueman.v1.LeagueMan
 */
export const LeagueMan = new ServiceType('sparx.games.leagueman.v1.LeagueMan', [
  {
    name: 'LiveCheck',
    options: { 'google.api.http': { get: '/healthz/live' } },
    I: HealthCheckRequest,
    O: HealthCheckResponse,
  },
  {
    name: 'ReadyCheck',
    options: { 'google.api.http': { get: '/healthz/ready' } },
    I: HealthCheckRequest,
    O: HealthCheckResponse,
  },
  {
    name: 'CreateResult',
    options: {},
    I: CreateResultRequest,
    O: CreateResultResponse,
  },
  {
    name: 'GetResults',
    options: {},
    I: GetResultsRequest,
    O: GetResultsResponse,
  },
  { name: 'GetAvatar', options: {}, I: GetAvatarRequest, O: GetAvatarResponse },
  { name: 'SetAvatar', options: {}, I: SetAvatarRequest, O: SetAvatarResponse },
  { name: 'GetMedals', options: {}, I: GetMedalsRequest, O: GetMedalsResponse },
  {
    name: 'GetMedalsForStudent',
    options: {},
    I: GetMedalsForStudentRequest,
    O: GetMedalsForStudentResponse,
  },
  {
    name: 'BatchCreateMedals',
    options: {
      'google.api.http': { post: '/v1/batchcreatemedals', body: '*' },
    },
    I: BatchCreateMedalsRequest,
    O: Empty,
  },
]);

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/dicegame/v1/dicegame.proto" (package "sparx.games.state.dicegame.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.games.state.dicegame.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: uint32 completionCount = 1;
   */
  completionCount: number;
}
/**
 * @generated from protobuf message sparx.games.state.dicegame.v1.State
 */
export interface State {
  /**
   * @generated from protobuf field: repeated sparx.games.state.dicegame.v1.ChallengeDef challenge = 1;
   */
  challenge: ChallengeDef[];
  /**
   * @generated from protobuf field: uint32 diceCount = 2;
   */
  diceCount: number;
  /**
   * @generated from protobuf field: float duration = 3;
   */
  duration: number;
  /**
   * @generated from protobuf field: sparx.games.state.dicegame.v1.State.WinState winState = 4;
   */
  winState: State_WinState;
}
/**
 * @generated from protobuf enum sparx.games.state.dicegame.v1.State.WinState
 */
export enum State_WinState {
  /**
   * @generated from protobuf enum value: none = 0;
   */
  none = 0,
  /**
   * @generated from protobuf enum value: won = 1;
   */
  won = 1,
  /**
   * @generated from protobuf enum value: lost = 2;
   */
  lost = 2,
}
/**
 * @generated from protobuf message sparx.games.state.dicegame.v1.Init
 */
export interface Init {
  /**
   * @generated from protobuf field: repeated sparx.games.state.dicegame.v1.ChallengeDef challenge = 1;
   */
  challenge: ChallengeDef[];
  /**
   * @generated from protobuf field: uint32 diceCount = 2;
   */
  diceCount: number;
  /**
   * @generated from protobuf field: float duration = 3;
   */
  duration: number;
}
/**
 * @generated from protobuf message sparx.games.state.dicegame.v1.Action
 */
export interface Action {
  /**
   * @generated from protobuf field: sparx.games.state.dicegame.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * @generated from protobuf enum sparx.games.state.dicegame.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * deprecated - supports client 2.59.8 and earlier
   *
   * @generated from protobuf enum value: win = 0;
   */
  win = 0,
  /**
   * deprecated - supports client 2.59.8 and earlier
   *
   * @generated from protobuf enum value: lose = 1;
   */
  lose = 1,
  /**
   * @generated from protobuf enum value: quitGame = 2;
   */
  quitGame = 2,
  /**
   * @generated from protobuf enum value: flagWinState = 3;
   */
  flagWinState = 3,
  /**
   * @generated from protobuf enum value: flagLoseState = 4;
   */
  flagLoseState = 4,
}
/**
 * @generated from protobuf message sparx.games.state.dicegame.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.dicegame.v1.Params
 */
export interface Params {}
/**
 * @generated from protobuf message sparx.games.state.dicegame.v1.ChallengeDef
 */
export interface ChallengeDef {
  /**
   * @generated from protobuf field: int32 minimumSum = 2;
   */
  minimumSum: number;
  /**
   * @generated from protobuf field: int32 maximumSum = 3;
   */
  maximumSum: number;
  /**
   * @generated from protobuf field: uint32 guaranteedCombinations = 4;
   */
  guaranteedCombinations: number;
  /**
   * @generated from protobuf field: uint32 slots = 5;
   */
  slots: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.dicegame.v1.Store', [
      {
        no: 1,
        name: 'completionCount',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.dicegame.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.dicegame.v1.State', [
      {
        no: 1,
        name: 'challenge',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ChallengeDef,
      },
      { no: 2, name: 'diceCount', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 3, name: 'duration', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 4,
        name: 'winState',
        kind: 'enum',
        T: () => [
          'sparx.games.state.dicegame.v1.State.WinState',
          State_WinState,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.dicegame.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.dicegame.v1.Init', [
      {
        no: 1,
        name: 'challenge',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ChallengeDef,
      },
      { no: 2, name: 'diceCount', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 3, name: 'duration', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.dicegame.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.dicegame.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.dicegame.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.dicegame.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.dicegame.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.dicegame.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.dicegame.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.dicegame.v1.Params
 */
export const Params = new Params$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChallengeDef$Type extends MessageType<ChallengeDef> {
  constructor() {
    super('sparx.games.state.dicegame.v1.ChallengeDef', [
      { no: 2, name: 'minimumSum', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'maximumSum', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'guaranteedCombinations',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 5, name: 'slots', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.dicegame.v1.ChallengeDef
 */
export const ChallengeDef = new ChallengeDef$Type();

import { faTimes, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { IconButton } from 'components/button/IconButton';
import { StudentSearch } from 'components/matchtable/StudentSearch';
import { Link } from 'components/matchtable/utils';
import { Tooltip } from 'components/tooltip/Tooltip';

export const SelectStudentLink = ({
  link,
  existing,
  students,
  onSetLink,
  onClose,
}: {
  link?: Link;
  existing?: Link;
  students: Student[];
  onSetLink: (studentId?: string) => void;
  onClose: () => void;
}) => (
  <Stack spacing={3}>
    {link?.studentId ? (
      <Tooltip content="Unmatch">
        <IconButton
          icon={<FontAwesomeIcon icon={faTrash} />}
          size="xs"
          colour="red"
          onClick={() => onSetLink(existing ? '' : undefined)}
          aria-label="Unmatch"
        />
      </Tooltip>
    ) : existing?.studentId ? (
      <Tooltip content="Reset">
        <IconButton
          icon={<FontAwesomeIcon icon={faUndo} />}
          size="xs"
          colour="orange"
          onClick={() => onSetLink(undefined)}
          aria-label="Reset"
        />
      </Tooltip>
    ) : null}

    <StudentSearch
      students={students}
      setStudent={id => onSetLink(id.studentId)}
      defaultStudentId={link?.studentId}
      autoFocus={true}
    />

    <Tooltip content="Cancel">
      <IconButton
        icon={<FontAwesomeIcon icon={faTimes} />}
        variant="ghost"
        size="xs"
        onClick={onClose}
        aria-label="Cancel"
      />
    </Tooltip>
  </Stack>
);

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/assessment/sitting/v1/sitting.proto" (package "sparx.assessment.sitting.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Sittings } from './sitting';
import type { GetSittingParticipantPackagesResponse } from './sitting';
import type { GetSittingParticipantPackagesRequest } from './sitting';
import type { UpdateSittingParticipantStateResponse } from './sitting';
import type { UpdateSittingParticipantStateRequest } from './sitting';
import type { GetSittingSessionResponse } from './sitting';
import type { GetSittingSessionRequest } from './sitting';
import type { UpdateSittingParticipantResponse } from './sitting';
import type { UpdateSittingParticipantRequest } from './sitting';
import type { ListUploadedResultsResponse } from './sitting';
import type { ListUploadedResultsRequest } from './sitting';
import type { ExportResultsResponse } from './sitting';
import type { ExportResultsRequest } from './sitting';
import type { UploadSittingResultsResponse } from './sitting';
import type { UploadSittingResultsRequest } from './sitting';
import type { LinkStudentsResponse } from './sitting';
import type { LinkStudentsRequest } from './sitting';
import type { UpdateSittingResponse } from './sitting';
import type { UpdateSittingRequest } from './sitting';
import type { WatchSittingResponse } from './sitting';
import type { WatchSittingRequest } from './sitting';
import type { ServerStreamingCall } from '@protobuf-ts/runtime-rpc';
import type { ListAssessmentSittingPackagesResponse } from './sitting';
import type { ListAssessmentSittingPackagesRequest } from './sitting';
import type { ListAssessmentSittingParticipantsResponse } from './sitting';
import type { ListAssessmentSittingParticipantsRequest } from './sitting';
import type { ListSittingParticipantsResponse } from './sitting';
import type { ListSittingParticipantsRequest } from './sitting';
import type { CreateSittingResponse } from './sitting';
import type { CreateSittingRequest } from './sitting';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListSittingsResponse } from './sitting';
import type { ListSittingsRequest } from './sitting';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.assessment.sitting.v1.Sittings
 */
export interface ISittingsClient {
  /**
   * ListSittings returns a list of sittings for a school.
   *
   * @generated from protobuf rpc: ListSittings(sparx.assessment.sitting.v1.ListSittingsRequest) returns (sparx.assessment.sitting.v1.ListSittingsResponse);
   */
  listSittings(
    input: ListSittingsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSittingsRequest, ListSittingsResponse>;
  /**
   * CreateSitting creates a new assessment sitting.
   *
   * @generated from protobuf rpc: CreateSitting(sparx.assessment.sitting.v1.CreateSittingRequest) returns (sparx.assessment.sitting.v1.CreateSittingResponse);
   */
  createSitting(
    input: CreateSittingRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateSittingRequest, CreateSittingResponse>;
  /**
   * @generated from protobuf rpc: ListSittingParticipants(sparx.assessment.sitting.v1.ListSittingParticipantsRequest) returns (sparx.assessment.sitting.v1.ListSittingParticipantsResponse);
   */
  listSittingParticipants(
    input: ListSittingParticipantsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSittingParticipantsRequest, ListSittingParticipantsResponse>;
  /**
   * @generated from protobuf rpc: ListAssessmentSittingParticipants(sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsRequest) returns (sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse);
   */
  listAssessmentSittingParticipants(
    input: ListAssessmentSittingParticipantsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAssessmentSittingParticipantsRequest,
    ListAssessmentSittingParticipantsResponse
  >;
  /**
   * @generated from protobuf rpc: ListAssessmentSittingPackages(sparx.assessment.sitting.v1.ListAssessmentSittingPackagesRequest) returns (sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse);
   */
  listAssessmentSittingPackages(
    input: ListAssessmentSittingPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAssessmentSittingPackagesRequest,
    ListAssessmentSittingPackagesResponse
  >;
  /**
   * @generated from protobuf rpc: WatchSitting(sparx.assessment.sitting.v1.WatchSittingRequest) returns (stream sparx.assessment.sitting.v1.WatchSittingResponse);
   */
  watchSitting(
    input: WatchSittingRequest,
    options?: RpcOptions,
  ): ServerStreamingCall<WatchSittingRequest, WatchSittingResponse>;
  /**
   * @generated from protobuf rpc: UpdateSitting(sparx.assessment.sitting.v1.UpdateSittingRequest) returns (sparx.assessment.sitting.v1.UpdateSittingResponse);
   */
  updateSitting(
    input: UpdateSittingRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSittingRequest, UpdateSittingResponse>;
  /**
   * @generated from protobuf rpc: LinkStudents(sparx.assessment.sitting.v1.LinkStudentsRequest) returns (sparx.assessment.sitting.v1.LinkStudentsResponse);
   */
  linkStudents(
    input: LinkStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<LinkStudentsRequest, LinkStudentsResponse>;
  /**
   * @generated from protobuf rpc: UploadSittingResults(sparx.assessment.sitting.v1.UploadSittingResultsRequest) returns (sparx.assessment.sitting.v1.UploadSittingResultsResponse);
   */
  uploadSittingResults(
    input: UploadSittingResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<UploadSittingResultsRequest, UploadSittingResultsResponse>;
  /**
   * @generated from protobuf rpc: ExportResults(sparx.assessment.sitting.v1.ExportResultsRequest) returns (sparx.assessment.sitting.v1.ExportResultsResponse);
   */
  exportResults(
    input: ExportResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<ExportResultsRequest, ExportResultsResponse>;
  /**
   * @generated from protobuf rpc: ListUploadedResults(sparx.assessment.sitting.v1.ListUploadedResultsRequest) returns (sparx.assessment.sitting.v1.ListUploadedResultsResponse);
   */
  listUploadedResults(
    input: ListUploadedResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListUploadedResultsRequest, ListUploadedResultsResponse>;
  /**
   * @generated from protobuf rpc: UpdateSittingParticipant(sparx.assessment.sitting.v1.UpdateSittingParticipantRequest) returns (sparx.assessment.sitting.v1.UpdateSittingParticipantResponse);
   */
  updateSittingParticipant(
    input: UpdateSittingParticipantRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateSittingParticipantRequest,
    UpdateSittingParticipantResponse
  >;
  /**
   * @generated from protobuf rpc: GetSittingSession(sparx.assessment.sitting.v1.GetSittingSessionRequest) returns (sparx.assessment.sitting.v1.GetSittingSessionResponse);
   */
  getSittingSession(
    input: GetSittingSessionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSittingSessionRequest, GetSittingSessionResponse>;
  /**
   * @generated from protobuf rpc: UpdateSittingParticipantState(sparx.assessment.sitting.v1.UpdateSittingParticipantStateRequest) returns (sparx.assessment.sitting.v1.UpdateSittingParticipantStateResponse);
   */
  updateSittingParticipantState(
    input: UpdateSittingParticipantStateRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateSittingParticipantStateRequest,
    UpdateSittingParticipantStateResponse
  >;
  /**
   * @generated from protobuf rpc: GetSittingParticipantPackages(sparx.assessment.sitting.v1.GetSittingParticipantPackagesRequest) returns (sparx.assessment.sitting.v1.GetSittingParticipantPackagesResponse);
   */
  getSittingParticipantPackages(
    input: GetSittingParticipantPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetSittingParticipantPackagesRequest,
    GetSittingParticipantPackagesResponse
  >;
}
/**
 * @generated from protobuf service sparx.assessment.sitting.v1.Sittings
 */
export class SittingsClient implements ISittingsClient, ServiceInfo {
  typeName = Sittings.typeName;
  methods = Sittings.methods;
  options = Sittings.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * ListSittings returns a list of sittings for a school.
   *
   * @generated from protobuf rpc: ListSittings(sparx.assessment.sitting.v1.ListSittingsRequest) returns (sparx.assessment.sitting.v1.ListSittingsResponse);
   */
  listSittings(
    input: ListSittingsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListSittingsRequest, ListSittingsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListSittingsRequest, ListSittingsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * CreateSitting creates a new assessment sitting.
   *
   * @generated from protobuf rpc: CreateSitting(sparx.assessment.sitting.v1.CreateSittingRequest) returns (sparx.assessment.sitting.v1.CreateSittingResponse);
   */
  createSitting(
    input: CreateSittingRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateSittingRequest, CreateSittingResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateSittingRequest, CreateSittingResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListSittingParticipants(sparx.assessment.sitting.v1.ListSittingParticipantsRequest) returns (sparx.assessment.sitting.v1.ListSittingParticipantsResponse);
   */
  listSittingParticipants(
    input: ListSittingParticipantsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListSittingParticipantsRequest,
    ListSittingParticipantsResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListSittingParticipantsRequest,
      ListSittingParticipantsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListAssessmentSittingParticipants(sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsRequest) returns (sparx.assessment.sitting.v1.ListAssessmentSittingParticipantsResponse);
   */
  listAssessmentSittingParticipants(
    input: ListAssessmentSittingParticipantsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAssessmentSittingParticipantsRequest,
    ListAssessmentSittingParticipantsResponse
  > {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentSittingParticipantsRequest,
      ListAssessmentSittingParticipantsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListAssessmentSittingPackages(sparx.assessment.sitting.v1.ListAssessmentSittingPackagesRequest) returns (sparx.assessment.sitting.v1.ListAssessmentSittingPackagesResponse);
   */
  listAssessmentSittingPackages(
    input: ListAssessmentSittingPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListAssessmentSittingPackagesRequest,
    ListAssessmentSittingPackagesResponse
  > {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAssessmentSittingPackagesRequest,
      ListAssessmentSittingPackagesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: WatchSitting(sparx.assessment.sitting.v1.WatchSittingRequest) returns (stream sparx.assessment.sitting.v1.WatchSittingResponse);
   */
  watchSitting(
    input: WatchSittingRequest,
    options?: RpcOptions,
  ): ServerStreamingCall<WatchSittingRequest, WatchSittingResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<WatchSittingRequest, WatchSittingResponse>(
      'serverStreaming',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateSitting(sparx.assessment.sitting.v1.UpdateSittingRequest) returns (sparx.assessment.sitting.v1.UpdateSittingResponse);
   */
  updateSitting(
    input: UpdateSittingRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSittingRequest, UpdateSittingResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSittingRequest, UpdateSittingResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: LinkStudents(sparx.assessment.sitting.v1.LinkStudentsRequest) returns (sparx.assessment.sitting.v1.LinkStudentsResponse);
   */
  linkStudents(
    input: LinkStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<LinkStudentsRequest, LinkStudentsResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<LinkStudentsRequest, LinkStudentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UploadSittingResults(sparx.assessment.sitting.v1.UploadSittingResultsRequest) returns (sparx.assessment.sitting.v1.UploadSittingResultsResponse);
   */
  uploadSittingResults(
    input: UploadSittingResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<UploadSittingResultsRequest, UploadSittingResultsResponse> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UploadSittingResultsRequest,
      UploadSittingResultsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ExportResults(sparx.assessment.sitting.v1.ExportResultsRequest) returns (sparx.assessment.sitting.v1.ExportResultsResponse);
   */
  exportResults(
    input: ExportResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<ExportResultsRequest, ExportResultsResponse> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ExportResultsRequest, ExportResultsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListUploadedResults(sparx.assessment.sitting.v1.ListUploadedResultsRequest) returns (sparx.assessment.sitting.v1.ListUploadedResultsResponse);
   */
  listUploadedResults(
    input: ListUploadedResultsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListUploadedResultsRequest, ListUploadedResultsResponse> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListUploadedResultsRequest,
      ListUploadedResultsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateSittingParticipant(sparx.assessment.sitting.v1.UpdateSittingParticipantRequest) returns (sparx.assessment.sitting.v1.UpdateSittingParticipantResponse);
   */
  updateSittingParticipant(
    input: UpdateSittingParticipantRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateSittingParticipantRequest,
    UpdateSittingParticipantResponse
  > {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateSittingParticipantRequest,
      UpdateSittingParticipantResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetSittingSession(sparx.assessment.sitting.v1.GetSittingSessionRequest) returns (sparx.assessment.sitting.v1.GetSittingSessionResponse);
   */
  getSittingSession(
    input: GetSittingSessionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSittingSessionRequest, GetSittingSessionResponse> {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSittingSessionRequest, GetSittingSessionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateSittingParticipantState(sparx.assessment.sitting.v1.UpdateSittingParticipantStateRequest) returns (sparx.assessment.sitting.v1.UpdateSittingParticipantStateResponse);
   */
  updateSittingParticipantState(
    input: UpdateSittingParticipantStateRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateSittingParticipantStateRequest,
    UpdateSittingParticipantStateResponse
  > {
    const method = this.methods[13],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateSittingParticipantStateRequest,
      UpdateSittingParticipantStateResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetSittingParticipantPackages(sparx.assessment.sitting.v1.GetSittingParticipantPackagesRequest) returns (sparx.assessment.sitting.v1.GetSittingParticipantPackagesResponse);
   */
  getSittingParticipantPackages(
    input: GetSittingParticipantPackagesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetSittingParticipantPackagesRequest,
    GetSittingParticipantPackagesResponse
  > {
    const method = this.methods[14],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetSittingParticipantPackagesRequest,
      GetSittingParticipantPackagesResponse
    >('unary', this._transport, method, opt, input);
  }
}

/**
 * SparxUnit is a type that represents valid units in the Sparx Design System
 * that are defined as css variables.
 * @see units.css
 */
export type SparxUnit =
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 72
  | 80
  | 96;

export const sparxUnitToValue = (unit: SparxUnit) =>
  `var(--spx-unit-${unit.toString().replace('.', '-')})`;

export type SparxSize =
  | 'max'
  | 'min'
  | 'full'
  | '3xs'
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl'
  | '8xl';

export const sparxSizeToValue = (size: SparxSize) => `var(--spx-size-${size})`;

import {
  Assessment,
  AssessmentQuestion,
  GroupSettings,
  QuestionType,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { PackageType } from '@sparx/api/apis/sparx/packages/v1/spxpkg';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';

import {
  useListAssessmentPackageProgress,
  useListGroupSettings,
  useListStudentAssessments,
} from '../../api/assessment';
import { useAssessmentContext } from '../../context';
import { isYearGroup } from '../../utils/groups';
import { scienceAssessmentFixUpSkills } from './scienceSkills';

/**
 * Details about a student's progress on the fix up assessment to be displayed in a row in the fix up view's table
 */
export interface IStudentAssessmentFixUpProgress {
  // The student's first name
  firstName: string;

  // The student's last name
  lastName: string;

  // Class display name:
  studentGroupDisplayName: string;

  // Whether fix up was ever started for this student's class (might have been ended)
  fixUpStarted: boolean;

  // The total number of questions the student has to answer in their fix up package
  totalQuestions: number;

  // The number of questions the student has answered in their fix up package
  questionsComplete: number;

  // The number of marks the student got in the assessment
  marksAwarded: number;

  // Whether the student has started the fix up task
  inProgress: boolean;

  // The date the student completed their fix up package (or undefined if they haven't yet)
  completionDate?: Timestamp;
}

/**
 * A summary about progress on fix up assessments for a class or year group
 */
export interface IAssessmentFixUpData {
  // Whether any of the information needed for the fix up view is still loading from the server
  isLoading: boolean;

  // Whether there has been an error loading any of the information needed for the fix up view
  isError: boolean;

  // A map of student group IDs to the assessment group settings for that group. If the current group is a class
  // it will contain the settings for that one class, but if the current group is a year group it will contain the
  // the settings for all classes in that year group. If an entry is undefined, it means that the fix-up assessment
  // is not enabled for that group.
  groupFixUpSettings: Map<string, GroupSettings | null>;

  // Whether the fix up assessment has ever been started  for the current class or, if a year group, any class in that
  // year group
  fixUpStarted: boolean;

  // Whether the fix up assessment is active for the current class or, if a year group, any class in that year group
  // i.e. started and not ended
  fixUpActive: boolean;

  // Details of the progress each student in the group has made on their fix up package
  studentFixUpProgress: IStudentAssessmentFixUpProgress[];

  // The total number of marks possible in the assessment
  totalMarksAvailable: number;
}

/**
 * Hook to get the data needed for the assessment fix up view
 */
export const useAssessmentFixUp = (
  // The assessment currently selected
  assessment: Assessment,
  // The group currently selected - this could be a class or a year group
  currentGroup: YearGroup | Group,
  // Students in the currently selected group
  students: Student[],
  // All groups
  groups: Group[],
): IAssessmentFixUpData => {
  const { schoolId } = useAssessmentContext();
  const isScience = assessment.subjectKey === 'science';

  // Fetch the assessment settings for every group in the school:
  const groupSettings = useListGroupSettings(
    {
      schoolName: `schools/${schoolId}`,
      assessmentName: assessment.name,
    },
    { enabled: !!assessment.name && !!schoolId },
  );
  const { data: groupSettingsData } = groupSettings;

  // Create a map of each class to be included in the table (if a class is selected, only that class, but if a year
  // group is selected, all classes in that year group). If there are no settings for the group, because fix up has
  // not been activated, return null.
  const groupFixUpSettings: Map<string, GroupSettings | null> = new Map();
  const groupDisplayNames: Map<string, string> = new Map();
  let fixUpStarted = false;
  let fixUpActive = false;
  if (!isYearGroup(currentGroup)) {
    const settings = groupSettingsData?.settings?.find(s => s.groupName === currentGroup.name);
    groupFixUpSettings.set(currentGroup.name.split('/')[3], settings || null);
    if (settings?.settings?.fixUpStartedAt) {
      fixUpStarted = true;
      if (!settings?.settings?.fixUpEndedAt) {
        fixUpActive = true;
      }
    }
    groupDisplayNames.set(currentGroup.name.split('/')[3], currentGroup.displayName);
  } else {
    const classesInYear = groups.filter(c => c.yearGroupId === currentGroup.yearGroupID);
    for (const c of classesInYear) {
      const settings = groupSettingsData?.settings?.find(s => s.groupName === c.name);
      groupFixUpSettings.set(c.name.split('/')[3], settings || null);
      if (settings?.settings?.fixUpStartedAt) {
        fixUpStarted = true;
        if (!settings?.settings?.fixUpEndedAt) {
          fixUpActive = true;
        }
      }
      groupDisplayNames.set(c.name.split('/')[3], c.displayName);
    }
  }

  const studentIDs = students.map(student => student.studentId);

  // Load the student assessments for the students (all students):
  const {
    isInitialLoading: studentAssessmentsLoading,
    isError: studentAssessmentsError,
    data: studentAssessments,
  } = useListStudentAssessments(
    {
      schoolName: `schools/${schoolId}`,
      assessmentName: assessment.name,
      studentIds: studentIDs,
    },
    {
      enabled: !!assessment.name && !!schoolId && studentIDs.length > 0,
    },
  );

  // Put into a lookup map by student id:
  const studentAssessmentsByStudentID: Map<string, StudentAssessment> = new Map();
  if (studentAssessments?.studentAssessments) {
    for (const sa of studentAssessments.studentAssessments) {
      studentAssessmentsByStudentID.set(sa.studentId, sa);
    }
  }

  // Create a list of student ids of the students who in a groups that have fix up started:
  const studentsWithFixUpEnabled = students.filter(student =>
    student.studentGroupIds?.some(sgid => !!groupFixUpSettings.get(sgid)?.settings?.fixUpStartedAt),
  );
  const studentIDsForStudentsWithFixUpEnabled = studentsWithFixUpEnabled.map(
    student => student.studentId,
  );

  // Load the package progress for the students who have fix up started:
  const {
    isInitialLoading: packageProgressLoading,
    isError: packageProgressError,
    data: packageProgress,
  } = useListAssessmentPackageProgress(
    {
      schoolName: `schools/${schoolId}`,
      assessmentName: assessment.name,
      packageType: PackageType.ASSESSMENT_FIXUP,
      studentIds: studentIDsForStudentsWithFixUpEnabled,
    },
    {
      enabled:
        !!currentGroup &&
        !!assessment.name &&
        !!schoolId &&
        studentIDsForStudentsWithFixUpEnabled.length > 0,
      // Reload every 30 seconds to get live updates:
      refetchInterval: 30000,
    },
  );

  // Make a lookup of questions in the assessment and work out the total marks available
  let totalMarksAvailable = 0;
  const questionsByName = new Map<string, AssessmentQuestion>();
  for (const q of assessment.questions) {
    questionsByName.set(q.name, q);
    totalMarksAvailable += q.availableMarks;
  }

  // Create the information about each student for the results table:
  const studentFixUpProgress = students.map(student => {
    const fixUpStartedForStudent =
      student?.studentGroupIds?.some(
        sgid => !!groupFixUpSettings.get(sgid)?.settings?.fixUpStartedAt,
      ) || false;

    let studentGroupDisplayName = '';
    if (student?.studentGroupIds) {
      for (const sgid of student.studentGroupIds) {
        if (groupDisplayNames.has(sgid)) {
          studentGroupDisplayName = groupDisplayNames.get(sgid) || '';
          break;
        }
      }
    }

    let totalQuestions = 0;
    let questionsComplete = 0;
    let inProgress = false;
    let completionDate: Timestamp | undefined = undefined;
    let marksAwarded = 0;

    // If the student has package progress because they've started the fix up work, use that to get the stats:
    const pp = packageProgress?.assessmentPackages?.[student.studentId];
    if (pp) {
      totalQuestions = pp.numTaskItems;
      questionsComplete = pp.numTaskItemsComplete;
      inProgress = pp.numTasksInProgress > 0;
      completionDate = pp.completionDate;
    }

    const studentAssessment = studentAssessmentsByStudentID.get(student.studentId);
    if (studentAssessment) {
      // Compute how many marks the student got in the assessment. We do this by adding up the marks for each question
      // If they haven't got any package progress, work out how many questions will be in the package when they start
      // To find the total questions, we count the number of questions in the assessment that are fluency questions
      // (or all for Science) and either did not have full marks or were not attempted. Also, we don't count questions
      // if they have the same skill ID. This should match the package generation algos.
      const uniqueSkills = new Set<string>();
      for (const m of studentAssessment.marks) {
        const question = questionsByName.get(m.assessmentQuestionName);
        if (!question) {
          continue;
        }
        marksAwarded += m.score || 0;
        let skillID = question.onlineSkill.split('/')[1];
        // For science use the hardcoded map, until they are actually on the assessment.
        if (isScience) {
          skillID = scienceAssessmentFixUpSkills[question.name];
        }
        if (
          // Only count fluency questions except in Science where we count all questions
          (question.type === QuestionType.FLUENCY || isScience) &&
          (!m.attempted || (m.score || 0) < question.availableMarks)
        ) {
          uniqueSkills.add(skillID);
        }
      }
      if (!pp) {
        totalQuestions = uniqueSkills.size;
      }
    }

    if (!pp && isScience && totalQuestions > 10) {
      // Science limits the total questions to 10
      totalQuestions = 10;
    }

    return {
      firstName: student.givenName,
      lastName: student.familyName,
      studentGroupDisplayName,
      fixUpStarted: fixUpStartedForStudent,
      totalQuestions,
      questionsComplete,
      marksAwarded,
      inProgress,
      completionDate,
    };
  });

  return {
    isLoading:
      groupSettings.isInitialLoading || studentAssessmentsLoading || packageProgressLoading,
    isError: groupSettings.isError || studentAssessmentsError || packageProgressError,
    groupFixUpSettings,
    fixUpStarted,
    fixUpActive,
    studentFixUpProgress,
    totalMarksAvailable,
  };
};

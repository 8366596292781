import { useDisclosure } from '@sparx/react-utils';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'components/button/Button';
import { HTMLAttributes, ReactNode } from 'react';

interface SimpleAlertProps {
  children: (props: HTMLAttributes<HTMLElement>) => ReactNode;
  header: string;
  body?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => Promise<any>;
  onClose?: () => void;
}

export const SimpleAlert = ({
  children,
  header,
  body,
  confirmText,
  cancelText,
  onConfirm,
  onClose: onCloseProp,
}: SimpleAlertProps) => {
  const { open, onClose, getButtonProps } = useDisclosure({
    onClose: onCloseProp,
  });

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: onConfirm,
  });
  const confirm = () => mutateAsync().then(onClose);

  return (
    <>
      <Modal isOpen={open} onClose={onClose}>
        <Modal.Content width="md">
          <Modal.CloseButton />
          <Modal.Title>{header}</Modal.Title>
          <Modal.Body>{body}</Modal.Body>
          <Modal.Footer>
            <Button colour="grey" onClick={onClose} isDisabled={isLoading}>
              {cancelText || 'Cancel'}
            </Button>
            <Button colour="blue" onClick={confirm} isLoading={isLoading}>
              {confirmText || 'Confirm'}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      {children(getButtonProps())}
    </>
  );
};

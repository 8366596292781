import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

import styles from './FilledPage.module.css';

export const FilledPage = ({ children }: PropsWithChildren) => (
  <div className={styles.Page}>{children}</div>
);

export const AnimatedFilledPage = ({ children }: PropsWithChildren) => (
  <motion.div
    // initial={{ opacity: 0, scale: 0.9 }}
    // animate={{ opacity: 1, scale: 1 }}
    // exit={{ opacity: 0, scale: 0.9 }}
    // transition={{ type: 'spring', duration: 0.2 }}
    className={styles.Page}
  >
    {children}
  </motion.div>
);

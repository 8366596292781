import { ListSittingsResponse_SittingData } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';

export const sortSittings = (sittings?: ListSittingsResponse_SittingData[], sort?: string) =>
  sittings?.sort(
    (a, b) =>
      (sort === 'name' && b.sitting?.sittingName.localeCompare(a.sitting?.sittingName || '')) ||
      (sort === 'started' &&
        (b.sitting?.state?.startTimestamp?.seconds || 0) -
          (a.sitting?.state?.startTimestamp?.seconds || 0)) ||
      (sort === 'created' &&
        (b.sitting?.createdTimestamp?.seconds || 0) -
          (a.sitting?.createdTimestamp?.seconds || 0)) ||
      (sort === 'ended' &&
        (a.sitting?.state?.endTimestamp?.seconds || 0) -
          (b.sitting?.state?.endTimestamp?.seconds || 0)) ||
      // Sort by if has start timestamp
      (b.sitting?.state?.startTimestamp ? 1 : 0) - (a.sitting?.state?.startTimestamp ? 1 : 0) ||
      // Sort by start timestamp / created timestamp
      (b.sitting?.state?.startTimestamp?.seconds || b.sitting?.createdTimestamp?.seconds || 0) -
        (a.sitting?.state?.startTimestamp?.seconds || a.sitting?.createdTimestamp?.seconds || 0),
  ) || [];

import { faCheck, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';

interface CorrectIconProps {
  correct?: boolean;
  inline?: boolean;
  className?: string;
  markingError?: boolean;
  analyticsAnswerType?: string;
}

export const CorrectIcon = ({
  correct,
  inline,
  className,
  markingError,
  analyticsAnswerType,
}: CorrectIconProps) => {
  const { sendAnalyticEvent } = useSparxQuestionContext();
  const onClick = () => {
    sendAnalyticEvent('click_correct_icon', {
      state: markingError ? 'markingError' : correct ? 'correct' : 'incorrect',
      answerType: analyticsAnswerType || 'unknown',
    });
  };

  return (
    <div
      className={classNames(
        className,
        !inline && styles.CorrectIconFloat,
        markingError
          ? styles.MarkingErrorIcon
          : correct
            ? styles.CorrectIcon
            : styles.IncorrectIcon,
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={markingError ? faMinus : correct ? faCheck : faTimes}
        fixedWidth={true}
      />
    </div>
  );
};

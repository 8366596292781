// Hardcoded fix up skills for the science assessment, these match those in `science/server/pkg/pkgsrv/assessment/fix_up_skills.csv`
export const scienceAssessmentFixUpSkills: Record<string, string> = {
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/05ba0b07-53b3-421f-a42b-3fe2b8946ff8':
    'eee1c858-ee58-4a51-84de-964a4b013abf',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/83ae5624-e954-45f7-8d8a-e34a4928f14b':
    '1d3fe2f2-3cb7-4038-b9b3-271755b4d485',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/ca318988-5a70-43e1-babd-f3a6cbfa8257':
    '5198f42b-a5d2-4189-bd45-efe91c9b25bf',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/f3090351-7e13-4810-8815-b6c152468521':
    'af338c22-7832-447a-ac7e-756386ec3a25',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/eabfa0fe-f01e-482b-b7d4-d07513651a9c':
    'b31adfbf-fb10-4aa3-8c9d-de930bd63dc3',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/7663389a-3ee7-4ad0-829c-b201cb5beffb':
    '5b1ef712-31bc-4e34-9bfc-118e15115fbe',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/fee26ec9-3824-4e4e-ab84-549e070e003f':
    'a241a0fe-36a4-4ec6-b473-c0c83c80bbe1',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/b4a5174b-f44c-41b8-97dc-5ae84e11af87':
    'd2b4a6ab-7399-4a25-b678-8a233dde46fc',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/84119142-4b19-4fa7-b219-f3054567e1b1':
    'c4c1a3a7-7e3e-490b-8c21-5fe816714af1',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/9a61551e-a6f3-472e-b9ad-515d57ec6215':
    '5b1ef712-31bc-4e34-9bfc-118e15115fbe',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/00faba03-0293-43f1-a0d8-bee8f1826ae2':
    '28f08057-5a28-4105-a509-493cae522433',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/b044acd9-0a6d-4108-befb-0abd58ac8e1f':
    '27ead8c2-ef63-4c4d-bbd1-4809e2356656',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/c3dd6a1d-5b88-4143-81c8-70383604b3f9':
    '27ead8c2-ef63-4c4d-bbd1-4809e2356656',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/c73d2865-3b4c-4a29-b36f-4779e41a6d0c':
    '05ec29aa-0b7c-4cae-a124-0d3ea6fc9373',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/672db676-b26a-4096-84be-d5d6433e7624':
    'af338c22-7832-447a-ac7e-756386ec3a25',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/a4db2906-3206-4bb3-951e-1375cd4889ae':
    'd2b4a6ab-7399-4a25-b678-8a233dde46fc',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/645736be-98ea-4433-abb1-902d9a1e1c2f':
    'ade59aaa-47ee-4d11-a662-6a2d4dfdfb0f',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/16522df9-bec3-46d1-a8fc-b7c100431180':
    '4862030e-8abe-4299-8e5c-328950649017',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/949b066f-e763-4f98-9486-79f3a1528c49':
    'ade59aaa-47ee-4d11-a662-6a2d4dfdfb0f',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/2ef62fff-b154-41d3-867c-a89b9c2e6a33':
    '5198f42b-a5d2-4189-bd45-efe91c9b25bf',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/daae173b-f5f9-4d65-ad31-74e31eb57132':
    '05ec29aa-0b7c-4cae-a124-0d3ea6fc9373',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/1d1787ad-4697-4709-91c7-d70da3352f77':
    'a068a293-c0a3-4179-942c-1e99ec66ebf9',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/5785b120-0ebe-49dd-a5f6-f67ea72ef6d7':
    '56d636e5-8733-463c-ae8c-1043b63e6a45',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/e8434498-cfa0-4df3-bafe-12d0caf80f71':
    '200e12b9-d536-4a3f-8184-ae19e6d128fb',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/b28c999b-8ab2-497f-b456-85e5cbf43f17':
    'be7ca491-c37a-425f-bf60-8d1c14b8612c',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/8057b239-8bb6-4192-90c3-54d7bd59c8d3':
    '2db62b7b-9462-4499-a038-bf47b624661b',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/5dc5b846-8f77-44f8-859f-03711c64f721':
    '2215223f-a632-476c-8b27-155685359ee9',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/5745618a-6b19-4b6e-8a53-75694f7f2455':
    '05ec29aa-0b7c-4cae-a124-0d3ea6fc9373',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/e60422f5-548c-4d7e-b0a3-ac625c0f92a6':
    '5198f42b-a5d2-4189-bd45-efe91c9b25bf',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/3c7f644b-dc1d-4f9b-8230-2a0345990165':
    'c4c1a3a7-7e3e-490b-8c21-5fe816714af1',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/844a2f79-7e30-4e30-a7bb-f76f06a9c2f8':
    '1d3fe2f2-3cb7-4038-b9b3-271755b4d485',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/2d86595c-1c9c-4249-90a2-880c34af4216':
    'c4c1a3a7-7e3e-490b-8c21-5fe816714af1',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/9dc45476-e21a-4513-b6df-fc95f97505a5':
    '200e12b9-d536-4a3f-8184-ae19e6d128fb',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/eb80bbd1-31ac-4ec7-9602-6d119d0131f9':
    '2db62b7b-9462-4499-a038-bf47b624661b',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/ec706cd8-d19c-4518-b21a-8abe7a34d827':
    'd2b4a6ab-7399-4a25-b678-8a233dde46fc',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/166c8698-ff26-4ffc-b322-25108c137792':
    '2215223f-a632-476c-8b27-155685359ee9',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/57acb98b-dbf4-4cc8-b5e4-208587baddd9':
    'f73d7672-2566-4aba-b473-b59f31b8f0bb',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/ddb98185-efee-4a61-93ac-58e5cf2f2539':
    'e44774f8-26d1-4920-97c5-30bd3da2c6ce',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/31819fd4-64a1-4528-93e7-02cc6539fa5e':
    'e44774f8-26d1-4920-97c5-30bd3da2c6ce',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/1813a0b5-39d4-4e9a-b7bd-843193029e9d':
    'e44774f8-26d1-4920-97c5-30bd3da2c6ce',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/92dc69f3-fd30-4c9a-9f0f-795d151100c7':
    'be7ca491-c37a-425f-bf60-8d1c14b8612c',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/61ecf91a-f015-4602-b5b1-6f184150e7e3':
    'a241a0fe-36a4-4ec6-b473-c0c83c80bbe1',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/c645243b-91b6-46b4-aa07-2143dc5777fe':
    '4862030e-8abe-4299-8e5c-328950649017',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/35ab3a58-a60f-4cf4-b7d4-183a7edf9c35':
    '4862030e-8abe-4299-8e5c-328950649017',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/047d624d-f026-40ea-89be-fa77899cad1a':
    '05ec29aa-0b7c-4cae-a124-0d3ea6fc9373',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/6e1d7535-a670-4b5e-930e-b8a6319a8f42':
    'f73d7672-2566-4aba-b473-b59f31b8f0bb',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/b2fcc856-1360-48e7-b48f-6962a284ff1f':
    'af338c22-7832-447a-ac7e-756386ec3a25',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/da982cd7-84a3-4a25-aa9d-e5f2becf158a':
    'be7ca491-c37a-425f-bf60-8d1c14b8612c',
  'assessments/dd37a844-3e06-11ef-b5eb-c3bc0e6e5843/questions/648003f8-213d-49e1-a00d-24a4212aa9da':
    'a068a293-c0a3-4179-942c-1e99ec66ebf9',
};

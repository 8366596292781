import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ComponentProps, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import styles from './ScrollingWrapper.module.css';

type ScrollPos = 'top' | 'middle' | 'bottom';
const ScrollOpaqueButtonHeight = 50;
const ScrollButtonHeightMultiplier = 1.1; //.5;

export const ScrollingWrapper = ({
  children,
  className,
  disabled,
  id,
}: PropsWithChildren<{ className?: string; id?: string; disabled?: boolean }>) => {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);

  const [scrollPos, setScrollPos] = useState<ScrollPos>('top');
  const [showButtons, setShowButtons] = useState(false);
  const checkShowButtons = useCallback(
    (container: HTMLDivElement) => {
      const show = container.scrollHeight > container.clientHeight * ScrollButtonHeightMultiplier;
      setShowButtons(show);
    },
    [setShowButtons],
  );

  // When the scrollRef is set check whether we need to scroll after a short time,
  // this seems to be the simplest way for initially displaying the buttons.
  // Attempts were made by using resize events but it was inconsistent, this
  // seems like a suitable solution without adding extra complexity monitoring
  // element sizes, especially given the buttons will also appear when any
  // scrolling happens
  useEffect(() => {
    if (scrollRef) {
      const container = scrollRef;
      const callback = () => {
        checkShowButtons(container);
      };
      const timeout = setTimeout(callback, 100);
      return () => clearTimeout(timeout);
    }
  }, [checkShowButtons, scrollRef]);

  // Call checkShowButtons on window resize
  useEffect(() => {
    const onResize = () => {
      if (scrollRef) {
        checkShowButtons(scrollRef);
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [checkShowButtons, scrollRef]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    let pos: ScrollPos = 'middle';
    if (e.currentTarget.scrollTop < ScrollOpaqueButtonHeight) {
      pos = 'top';
    } else if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <
      e.currentTarget.clientHeight + ScrollOpaqueButtonHeight
    ) {
      pos = 'bottom';
    }
    setScrollPos(pos);
    checkShowButtons(e.currentTarget);
  };

  return (
    <div className={classNames(styles.Container, className)}>
      <div className={styles.ScrollContainer} ref={setScrollRef} onScroll={onScroll} id={id}>
        <div>{children}</div>
      </div>
      {!disabled && showButtons && (
        <>
          <ScrollButton
            atExtreme={scrollPos === 'bottom'}
            atOppositeExtreme={scrollPos === 'top'}
            side="top"
          >
            <FontAwesomeIcon icon={faArrowUp} />
            Scroll up
          </ScrollButton>
          <ScrollButton
            atExtreme={scrollPos === 'top'}
            atOppositeExtreme={scrollPos === 'bottom'}
            side="bottom"
          >
            <FontAwesomeIcon icon={faArrowDown} />
            Scroll down
          </ScrollButton>
        </>
      )}
    </div>
  );
};

const ScrollButton = ({
  children,
  atExtreme,
  atOppositeExtreme,
  side,
  ...props
}: ComponentProps<'div'> & {
  atExtreme: boolean;
  side: 'top' | 'bottom';
  atOppositeExtreme: boolean;
}) => (
  <div
    className={classNames(
      styles.ScrollButton,
      side === 'top' ? styles.ScrollButtonTop : styles.ScrollButtonBottom,
    )}
    style={{ opacity: atExtreme ? 1 : atOppositeExtreme ? 0 : 0.2 }}
    {...props}
  >
    <span>{children}</span>
  </div>
);

import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { AssessmentLandingView } from '@sparx/assessments/src/components/AssessmentLandingView';
import { AssessmentTabs } from '@sparx/assessments/src/components/AssessmentLandingView/AssessmentTab/AssessmentTab';
import { Button } from 'components/button/Button';
import { selectedGroupValue, useClassSelectionStudents } from 'components/header/BackLink';
import { Link } from 'react-router-dom';
import { getStudentGroupTypeForAssessment } from 'utils/assessments';

export const MarkSheetView = ({
  assessment,
  fixedTab,
}: {
  assessment: Assessment;
  fixedTab?: AssessmentTabs;
}) => {
  const groupType = getStudentGroupTypeForAssessment(assessment);
  const { students, groups, selectedGroup } = useClassSelectionStudents(groupType);
  const group = selectedGroupValue(selectedGroup);
  if (!group) {
    return <>Select a group in header</>;
  }
  if (students.length === 0) {
    return <>No students in group</>;
  }

  return (
    <>
      <Button as={Link} to={`/teacher/${assessment.name}/dataentry`}>
        Enter data
      </Button>
      <AssessmentLandingView
        assessment={assessment}
        students={students}
        currentGroup={group}
        groups={groups}
        fixedTab={fixedTab}
      >
        {({ content }) => <>{content}</>}
      </AssessmentLandingView>
    </>
  );
};

import { UserInteractionEventFields } from '@sparx/analytics';

const category = 'Resources & Assessments';

export const selectedStudentEvent = (studentID: string): UserInteractionEventFields => ({
  category,
  action: 'Selected student',
  labels: {
    studentID,
  },
});

export const searchedForStudentEvent = (studentID: string): UserInteractionEventFields => ({
  category,
  action: 'Searched for student',
  labels: {
    studentID,
  },
});

export const nextStudentButtonEvent = (): UserInteractionEventFields => ({
  category,
  action: 'Used next student button',
});

export const previousStudentButtonEvent = (): UserInteractionEventFields => ({
  category,
  action: 'Used previous student button',
});

export const setStudentAbsentEvent = (
  studentID: string,
  absent: boolean,
): UserInteractionEventFields => ({
  category,
  action: `Set student as ${absent ? 'absent' : 'present'} for assessment`,
  labels: { studentID },
});

export const setStudentAbsentConfirmEvent = (
  studentID: string,
  numMarksEntered: number,
): UserInteractionEventFields => ({
  category,
  action: `Set student as absent for assessment (confirmed)`,
  labels: { studentID, numMarksEntered: numMarksEntered.toString() },
});

export const setStudentAbsentAbortEvent = (
  studentID: string,
  numMarksEntered: number,
): UserInteractionEventFields => ({
  category,
  action: `Set student as absent for assessment (aborted)`,
  labels: { studentID, numMarksEntered: numMarksEntered.toString() },
});

import { createContext, useContext } from 'react';

// This context is used for other component to know whether they are being displayed as a child of a link boxes element.
export interface LinkBoxesContext {
  isLinkBoxes: boolean;
}

const LinkBoxesContext = createContext<LinkBoxesContext>({ isLinkBoxes: false });

export const useLinkBoxesContext = () => useContext(LinkBoxesContext);

export const LinkBoxesContextProvider = LinkBoxesContext.Provider;

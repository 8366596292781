// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/dailytokens/v1/dailytokens.proto" (package "sparx.games.progress.dailytokens.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.games.progress.dailytokens.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: repeated string tokenEarnedDates = 2;
   */
  tokenEarnedDates: string[];
  /**
   * @generated from protobuf field: map<string, sparx.games.progress.dailytokens.v1.ProgressRecord> progressRecords = 3;
   */
  progressRecords: {
    [key: string]: ProgressRecord;
  };
}
/**
 * @generated from protobuf message sparx.games.progress.dailytokens.v1.ProgressRecord
 */
export interface ProgressRecord {
  /**
   * @generated from protobuf field: map<string, uint64> correctAnswersPerGame = 2;
   */
  correctAnswersPerGame: {
    [key: string]: number;
  };
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.progress.dailytokens.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'tokenEarnedDates',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'progressRecords',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => ProgressRecord },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.dailytokens.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProgressRecord$Type extends MessageType<ProgressRecord> {
  constructor() {
    super('sparx.games.progress.dailytokens.v1.ProgressRecord', [
      {
        no: 2,
        name: 'correctAnswersPerGame',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: {
          kind: 'scalar',
          T: 4 /*ScalarType.UINT64*/,
          L: 2 /*LongType.NUMBER*/,
        },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.dailytokens.v1.ProgressRecord
 */
export const ProgressRecord = new ProgressRecord$Type();

import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  CreateSittingRequest,
  CreateSittingResponse,
  GetSittingSessionResponse,
  ListSittingParticipantsResponse,
  ListSittingsResponse,
  ListSittingsResponse_SittingData,
  ListUploadedResultsResponse,
  Student,
  UpdateSittingParticipantRequest,
  UpdateSittingRequest,
  UpdateSittingResponse,
  WatchSittingResponse,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getSchoolID, useSession } from 'api/auth';
import { sittingsClient } from 'api/clients';
import { queryClient } from 'app/queryClient';
import { useMemo } from 'react';
import { mergeArraysById } from 'utils/arrays';
import { useStream } from 'utils/streamer';

const listSittingsQuery = {
  queryKey: ['sittings', 'list'],
  queryFn: async () =>
    sittingsClient.listSittings({
      schoolName: `schools/${await getSchoolID()}`,
      filter: {
        oneofKind: 'ended',
        ended: false,
      },
    }).response,
};

const listEndedSittingsQuery = {
  queryKey: ['sittings', 'list', 'ended'],
  queryFn: async () =>
    sittingsClient.listSittings({
      schoolName: `schools/${await getSchoolID()}`,
      filter: {
        oneofKind: 'ended',
        ended: true,
      },
    }).response,
};

const listSittingsForAssessmentQuery = (assessmentName: string) => ({
  queryKey: ['sittings', 'list', 'assessment', assessmentName],
  queryFn: async () =>
    sittingsClient.listSittings({
      schoolName: `schools/${await getSchoolID()}`,
      filter: {
        oneofKind: 'assessmentName',
        assessmentName,
      },
    }).response,
});

export const useSittings = <TData = ListSittingsResponse>(
  opts?: UseQueryOptions<ListSittingsResponse, RpcError, TData>,
) =>
  useQuery({
    ...listSittingsQuery,
    ...opts,
  });

export const useEndedSittings = <TData = ListSittingsResponse>(
  opts?: UseQueryOptions<ListSittingsResponse, RpcError, TData>,
) =>
  useQuery({
    ...listEndedSittingsQuery,
    ...opts,
  });

export const useAssessmentSittings = <TData = ListSittingsResponse>(
  assessmentName: string,
  opts?: UseQueryOptions<ListSittingsResponse, RpcError, TData>,
) =>
  useQuery({
    ...listSittingsForAssessmentQuery(assessmentName),
    ...opts,
  });

export const useEndedSitting = (
  sittingId: string,
  opts?: UseQueryOptions<ListSittingsResponse_SittingData, RpcError>,
) =>
  useQuery({
    queryKey: ['sittings', sittingId],
    queryFn: async () => {
      const data = await queryClient.fetchQuery(listEndedSittingsQuery);
      const sittingName = `schools/${await getSchoolID()}/sittings/${sittingId}`;
      const sitting = data.sittings.find(s => s.sitting?.sittingName === sittingName);
      if (!sitting?.sitting) throw new Error(`Sitting ${sittingName} not found`);
      return sitting;
    },
    ...opts,
  });

export const useEndedAssessmentSittings = (
  assessmentName: string,
  opts?: UseQueryOptions<ListSittingsResponse, RpcError, ListSittingsResponse_SittingData[]>,
) =>
  useEndedSittings({
    select: data => data.sittings.filter(s => s.sitting?.assessmentName === assessmentName),
    ...opts,
  });

export const useSittingStats = (sittings?: ListSittingsResponse_SittingData[]) =>
  useMemo(() => {
    const totalStudents =
      sittings?.reduce((acc, sitting) => acc + sitting.participantCount, 0) || 0;
    const exportedStudents =
      sittings?.reduce((acc, sitting) => acc + sitting.exportedParticipantCount, 0) || 0;
    const unexportedStudents = totalStudents - exportedStudents;

    return {
      totalStudents,
      exportedStudents,
      unexportedStudents,
    };
  }, [sittings]);

export const participantsQuery = <TData = ListSittingParticipantsResponse>(
  sittingName: string,
  opts?: UseQueryOptions<ListSittingParticipantsResponse, RpcError, TData>,
) => ({
  queryKey: ['sittings', 'participants', sittingName],
  queryFn: async () =>
    sittingsClient.listSittingParticipants({
      sittingName,
    }).response,
  ...opts,
});

export const useSittingParticipants = <TData = ListSittingParticipantsResponse>(
  sittingName: string,
  opts?: UseQueryOptions<ListSittingParticipantsResponse, RpcError, TData>,
) => useQuery(participantsQuery(sittingName, opts));

export const updateSittingParticipantStudents = (sittingName: string, updates: Student[]) =>
  queryClient.setQueryData(
    ['sittings', 'participants', sittingName],
    (data: ListSittingParticipantsResponse | undefined) => {
      if (!data) return data;
      return {
        ...data,
        students: data.students.map(s => updates.find(u => u.subject === s.subject) || s),
      };
    },
  );

export const useSittingParticipantsMap = (sittingName: string, opts?: { enabled: boolean }) =>
  useSittingParticipants(sittingName, {
    suspense: true,
    ...opts,
    select: data => {
      const studentLookup = new Map(data.students?.map(p => [p.subject, p]));
      return new Map(
        (data.participants || []).map(p => [
          p.participantSubject,
          {
            participant: p,
            student: studentLookup.get(p.participantSubject),
          },
        ]),
      );
    },
  });

export const invalidateSittingParticipants = (sittingName: string) =>
  queryClient.invalidateQueries(['sittings', 'participants', sittingName]);

export const useUpdateSitting = (
  sittingName: string,
  opts?: UseMutationOptions<UpdateSittingResponse, RpcError, UpdateSittingRequest['action']>,
) =>
  useMutation({
    mutationFn: async (action: UpdateSittingRequest['action']) =>
      sittingsClient.updateSitting({
        sittingName,
        action,
      }).response,
    ...opts,
    // TODO: do we want to return the updated sitting?
  });

export const useUpdateSittingParticipant = (sittingName: string) =>
  useMutation({
    mutationFn: async (req: {
      participantSubject: string;
      action: UpdateSittingParticipantRequest['action'];
    }) =>
      sittingsClient.updateSittingParticipant({
        sittingName,
        ...req,
      }).response,
  });

export type CreateSittingParams = Omit<CreateSittingRequest, 'schoolName'>;

export const useCreateSitting = (
  opts?: UseMutationOptions<CreateSittingResponse, RpcError, CreateSittingParams>,
) =>
  useMutation({
    mutationFn: async (req: CreateSittingParams) =>
      sittingsClient.createSitting({
        ...req,
        schoolName: `schools/${await getSchoolID()}`,
      }).response,
    onSettled: () => {
      // Invalidate the list of sittings
      // TODO: append the sittings to the list?
      queryClient.invalidateQueries(['sittings', 'list']);
    },
    ...opts,
  });

export const useSittingSession = (
  sittingName: string,
  opts?: UseQueryOptions<GetSittingSessionResponse, RpcError>,
) =>
  useQuery({
    queryKey: ['sittings', sittingName, 'session'],
    queryFn: async () =>
      sittingsClient.getSittingSession({
        sittingName,
      }).response,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...opts,
  });

export const useWatchSitting = (
  sittingIdOrName: string,
  sessionID: string,
  opts?: {
    onError?: (err: Error) => void;
    onMessage?: (data: WatchSittingResponse) => void;
  },
) => {
  const { data: session } = useSession();
  const sittingId = useMemo(() => sittingIdOrName.split('/').pop(), [sittingIdOrName]);

  return useStream({
    key: ['sittings', sittingId, 'watch'],
    queryFn: signal =>
      sittingsClient.watchSitting(
        { sittingName: `schools/${session?.schoolId}/sittings/${sittingId}`, sessionId: sessionID },
        { abort: signal },
      ),
    enabled: Boolean(session?.schoolId),
    stateMerger: (oldState, newState) => ({
      ...oldState,
      ...newState,
      participantStates: mergeArraysById(
        'participantSubject',
        (oldState?.participantStates || []).filter(
          // Ensure we remove any participants that have been removed
          p => !newState.removedParticipants?.includes(p.participantSubject),
        ),
        newState.participantStates,
      ),
      joinRequests: mergeArraysById(
        'requestId',
        (oldState?.joinRequests || []).filter(
          // Ensure we remove any join requests that have been removed
          p => !newState.removedJoinRequests?.includes(p.requestId),
        ),
        newState.joinRequests,
      ),
    }),
    ...opts,
  });
};

export const useSittingUploadedResults = <TData = ListUploadedResultsResponse>(
  assessmentName: string,
  studentNames: string[],
  opts?: UseQueryOptions<ListUploadedResultsResponse, RpcError, TData>,
) =>
  useQuery({
    queryKey: ['assessments', assessmentName, 'uploaded', studentNames],
    queryFn: async () =>
      sittingsClient.listUploadedResults({
        schoolName: `schools/${await getSchoolID()}`,
        assessmentName,
        studentNames,
      }).response,
    ...opts,
  });

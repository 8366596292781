import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@sparx/sparx-design/components/input/Input';
import classNames from 'classnames';
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './QLARow.module.css';

interface IQLARow {
  questionNumber: string;
  mark: {
    givenMark: undefined | number;
    totalMarks: number;
    attempted: boolean;
  };
}

interface IQLAPRowProps {
  row: IQLARow;
  isCurrentRow: boolean;
  onRowCompleted?: () => void;
  onClick?: () => void;
  onUpdate: (mark: number | undefined, attempted: boolean) => void;
  studentID: string;
  studentAbsent: boolean;
  isDisabled?: boolean;
}

const shouldShowValidationError = (row: IQLARow) =>
  !!row.mark.givenMark && row.mark.givenMark > row.mark.totalMarks;

export const QLARow = forwardRef<HTMLTableRowElement, IQLAPRowProps>((props, ref) => {
  const { row, onClick, isCurrentRow } = props;
  const showValidationError = shouldShowValidationError(row);

  return (
    <tr
      className={classNames(styles.Row, {
        [styles.RowCurrent]: isCurrentRow,
        [styles.RowValidationError]: showValidationError,
      })}
      ref={ref}
      onClick={onClick}
    >
      <td>{row.questionNumber}</td>
      <td>
        <MarkEntry {...props} />
      </td>
    </tr>
  );
});

const getRowValue = (givenMark: number | undefined, attempted: boolean) => {
  // If the mark is unattempted AND has a given mark of 0, then mark it to the teacher as
  // unattempted. This distinguishes between unattempted marks with undefined givenMark, which
  // hasn't yet been marked by the teacher. In this case we'll show a blank space.
  if (!attempted && givenMark === 0) {
    return 'U';
  } else if (givenMark !== undefined) {
    return String(givenMark);
  }
  return '';
};

export const MarkEntry = ({
  row,
  onRowCompleted,
  isCurrentRow,
  studentID,
  studentAbsent,
  onUpdate,
  onClick,
  isDisabled,
}: IQLAPRowProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(getRowValue(row.mark.givenMark, row.mark.attempted));

  const showValidationError = shouldShowValidationError(row);

  useEffect(() => {
    setValue(getRowValue(row.mark.givenMark, row.mark.attempted));
  }, [studentID, row.mark.givenMark, row.mark.attempted]);

  useLayoutEffect(() => {
    if (isCurrentRow && inputRef.current && !isDisabled) {
      inputRef.current.focus();
    }
  }, [isCurrentRow, studentID, isDisabled]);

  return (
    <>
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        className={classNames(styles.Warning, {
          [styles.WarningHidden]: !showValidationError,
        })}
      />
      <div className={styles.InputContainer}>
        <Input
          className={styles.Input}
          ref={inputRef}
          value={value}
          autoComplete="off"
          disabled={studentAbsent || isDisabled}
          maxLength={1}
          onKeyDown={e => {
            if (value === e.key) {
              e.preventDefault();
              onRowCompleted?.();
            }
          }}
          onChange={e => {
            e.preventDefault();
            if (e.target.value.trim()) {
              const upper = e.target.value.toUpperCase();
              if (upper === 'U' || upper === '-') {
                setValue('U');
                // A question marked as unattempted has a mark of 0, rather than undefined which
                // is a question not yet marked by the teacher.
                onUpdate(0, false);
                onRowCompleted?.();
                return;
              }
              const val = Number(e.target.value);
              if (isNaN(val)) {
                onRowCompleted?.();
                return;
              }
              setValue(e.target.value);
              onUpdate(val, true);
              onRowCompleted?.();
            } else {
              setValue('');
              onUpdate(undefined, false);
            }
          }}
          onClick={onClick}
          onFocus={e => {
            const target = e.currentTarget;
            setTimeout(() => {
              target.setSelectionRange(0, target.value.length);
            }, 0);
          }}
        />
        <div className={styles.TotalMarks}>/ {row.mark.totalMarks}</div>
      </div>
    </>
  );
};

import { Package } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { useMemo } from 'react';

export const useGetTaskItems = (
  pkg: Package | undefined,
  currentTaskItem: string | number | null,
) => {
  const flatItems = useMemo(
    () => pkg?.contents?.tasks?.flatMap(task => task.contents?.taskItems),
    [pkg],
  );

  const selectedIndex =
    typeof currentTaskItem === 'number'
      ? currentTaskItem
      : flatItems?.findIndex(item => item?.name === currentTaskItem) ?? -1;
  const itemAtIndex = (idx: number) => flatItems?.[idx];

  const selectedTaskItem = selectedIndex !== undefined ? itemAtIndex(selectedIndex) : undefined;
  const nextTaskItem = itemAtIndex(selectedIndex + 1);
  const prevTaskItem = itemAtIndex(selectedIndex - 1);

  return { selectedTaskItem, nextTaskItem, prevTaskItem, flatItems };
};

import { handleKeyPress, KeyMappings } from '@sparx/react-utils/keyboard';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import {
  ForwardedRef,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CorrectIcon } from '../components/CorrectIcon';
import { Stack } from '../components/Stack';
import { QuestionAction } from '../question/input';
import { LinkBoxesContextProvider } from '../question/LinkBoxesContext';
import sharedStyles from '../question/SparxQuestion.module.css';
import {
  LayoutElementProps,
  usePredictableShuffleContent,
  useSparxQuestionContext,
} from '../question/SparxQuestionContext';
import { ICardElement, IElement, IGroupElement, ISlotElement } from '../question/types';
import { LayoutElements } from './LayoutElement';
import styles from './LinkBoxesElement.module.css';

type pointerEventType = 'down' | 'enter' | 'leave' | 'dot-down' | 'click';

// Take the elements from the link box group and split them into headers, slots and cards
// Also returns an array of the links between them.
const useLinkBoxesElements = (element: IGroupElement) => {
  const { input } = useSparxQuestionContext();

  const { cardsGroupId, cards, slots, ...rest } = useMemo(() => {
    const cardsHeader: IElement[] = [];
    const cards: ICardElement[] = [];
    const slotsHeader: IElement[] = [];
    const slots: ISlotElement[] = [];
    let cardsGroupId: string | undefined;

    const lineRefs: [string, string][] = [];

    element.content.forEach(e => {
      if (e.element !== 'group') {
        console.error('LinkBoxesElement: expected group element got', e.element);
        return;
      }

      if (e.type.includes('cards')) {
        cardsGroupId = e.id;
        e.content.forEach(c => {
          if (c.element === 'card') {
            cards.push(c);
          } else {
            cardsHeader.push(c);
          }
        });
      } else if (e.type.includes('slots')) {
        e.content.forEach(s => {
          if (s.element === 'slot') {
            slots.push(s);

            const cRef = input.slots?.[s.ref]?.card_ref;
            if (cRef) {
              lineRefs.push([s.ref, cRef]);
            }
          } else {
            slotsHeader.push(s);
          }
        });
      } else {
        console.error('LinkBoxesElement: invalid group type', e.type);
        return;
      }
    });

    return {
      cardsGroupId,
      cards,
      cardsHeader,
      slots,
      slotsHeader,
      lineRefs,
    };
  }, [element, input.slots]);

  return {
    cards: usePredictableShuffleContent(
      cards,
      Boolean(input.card_groups?.[cardsGroupId || ''].shuffle),
      'cards',
    ),
    slots: usePredictableShuffleContent(
      slots,
      Boolean(input.slot_groups?.[cardsGroupId || ''].shuffle),
      'slots',
    ),
    ...rest,
  };
};

// given two elements, send an action to link them. Expects the elements to be a card and a slot.
const linkElements = (
  sendAction: (action: QuestionAction) => void,
  aElement: ISlotElement | ICardElement,
  bElement: ISlotElement | ICardElement,
  lineRefs: [string, string][],
) => {
  const cardRef = aElement.element === 'card' ? aElement.ref : bElement.ref;
  const slotRef = aElement.element === 'slot' ? aElement.ref : bElement.ref;
  // Require no line between the elements already
  if (!lineRefs.some(([lhs, rhs]) => lhs === slotRef && rhs === cardRef)) {
    sendAction({
      action: 'drop_card',
      cardRef,
      slotRef,
    });
  }
};

// makes the result feedback data
const useResult = (slots: ISlotElement[]) => {
  const { gapEvaluations, questionMarkingMode, input } = useSparxQuestionContext();

  return useMemo(() => {
    if (
      !gapEvaluations ||
      questionMarkingMode === 'part' ||
      // Only show if we have an evaluation for each slot
      slots.some(s => gapEvaluations[s.ref] === undefined)
    ) {
      return { show: false };
    }

    // The evaluations are summarised on the client, meaning the granular data is
    // technically accessible to the user, we don't think this is a big problem as
    // the detailed evaluations just give more information to be able to infer the
    // corerct answer rather than giving the correct answer.
    const numCorrect = slots.reduce<number>(
      (acc, slot) => acc + (gapEvaluations[slot.ref]?.correct ? 1 : 0),
      0,
    );

    const resultByRef: Record<string, boolean | undefined> = {};
    // If we are not just showing the summary (i.e. this is the first attempt)
    // then populate the results for each slot/card which we will display as granular feedback
    if (questionMarkingMode !== 'gap-summary') {
      for (const slot of slots) {
        const gapEval = gapEvaluations[slot.ref];
        if (!gapEval) continue;

        resultByRef[slot.ref] = gapEval.correct;
        const cardRef = input.slots?.[slot.ref]?.card_ref;
        if (!cardRef) continue;
        resultByRef[cardRef] = gapEval.correct;
      }
    }

    return {
      show: true,
      numCorrect,
      total: slots.length,
      resultByRef,
    };
  }, [gapEvaluations, questionMarkingMode, slots, input]);
};

export const LinkBoxesElement = ({ element }: LayoutElementProps<IGroupElement>) => {
  const { sendAction, readOnly, keyboardMode } = useSparxQuestionContext();

  const { cards, cardsHeader, slots, slotsHeader, lineRefs } = useLinkBoxesElements(element);

  const [startElement, setStartElement] = useState<ISlotElement | ICardElement | null>(null);
  const [endElement, setEndElement] = useState<ISlotElement | ICardElement | null>(null);
  const [selectedElement, setSelectedElement] = useState<ISlotElement | ICardElement | null>(null);

  const boxRefs = useRef<Record<string, HTMLDivElement>>({});

  const result = useResult(slots);

  const [dotClicked, setDotClicked] = useState(false);

  // callback to handle selecting/unselecting elements
  const selectElement = useCallback(
    (el: ISlotElement | ICardElement) => {
      if (selectedElement?.ref !== el.ref) {
        // the elements differ, if there is no selected or they are of the same type just change the selection
        if (!selectedElement?.ref || selectedElement.element === el.element) {
          setSelectedElement(el);
        } else {
          // the selected and clicked are different types, so join the two and removed the selection
          linkElements(sendAction, selectedElement, el, lineRefs);
          setSelectedElement(null);
        }
      } else {
        // the clicked element is the same as the selected so unselect
        setSelectedElement(null);
      }
    },
    [selectedElement, sendAction, lineRefs],
  );

  // Effect to handle when click or drag ends, handles selecting/linking/unlinking items
  useEffect(() => {
    if (!readOnly) {
      const pUp = () => {
        if (startElement?.ref && endElement?.ref) {
          if (startElement.ref !== endElement.ref) {
            // drag selections made
            linkElements(sendAction, startElement, endElement, lineRefs);
          } else {
            if (selectedElement?.ref !== startElement.ref) {
              selectElement(startElement);
            } else {
              // the clicked element is the same as the selected so unselect
              setSelectedElement(null);
            }
          }
        } else if (startElement?.ref) {
          // Started to drag but stopped when not over an item, if there is an existing link then remove it
          sendAction({
            action: 'drop_card',
            cardRef: startElement.element === 'card' ? startElement.ref : '',
            slotRef: startElement.element === 'slot' ? startElement.ref : '',
          });
        }
        setStartElement(null);
        setEndElement(null);
        setDotClicked(false);
      };
      window.addEventListener('pointerup', pUp);
      return () => {
        window.removeEventListener('pointerup', pUp);
      };
    }
  }, [startElement, endElement, selectedElement, sendAction, readOnly, selectElement, lineRefs]);

  // pointer event callback used to track where a new line starts and ends
  const onPointerEvent = useCallback(
    (
      e: React.PointerEvent<HTMLDivElement> | null,
      event: pointerEventType,
      linkBoxEl: ISlotElement | ICardElement,
    ) => {
      if (readOnly) return;
      switch (event) {
        case 'down':
          if (e !== null && e.button !== 0) break;
          setStartElement(linkBoxEl);
          setEndElement(linkBoxEl);
          break;
        case 'dot-down':
          if (e !== null && e.button !== 0) break;
          setDotClicked(true);
          setStartElement(linkBoxEl);
          setEndElement(linkBoxEl);
          break;
        case 'enter':
          // A start element must be set, must not be the same element, and must be a different type
          if (
            startElement &&
            startElement.ref !== linkBoxEl.ref &&
            startElement.element !== linkBoxEl.element
          ) {
            setEndElement(linkBoxEl);
          }
          break;
        case 'leave':
          setEndElement(null);
          if (startElement) {
            if (dotClicked) {
              // If we started by clicking a linked dot then we should be dragging
              // the end of the line rather than the start, so we need to find what
              // we were linked to as set that as the start element
              const lineRef = lineRefs.find(
                ([lhs, rhs]) => lhs === startElement.ref || rhs === startElement.ref,
              );
              if (lineRef) {
                const otherRef = lineRef[0] === startElement.ref ? lineRef[1] : lineRef[0];
                const el = [...slots, ...cards].find(e => e.ref === otherRef);
                if (el) setStartElement(el);
              }
              setDotClicked(false);
            }
            setSelectedElement(null);
          }
          break;
        case 'click':
          // This is only used when the browser does not support pointer events.
          selectElement(linkBoxEl);
          break;
      }

      if (e !== null && e.target instanceof HTMLElement && e.target.releasePointerCapture) {
        e.target.releasePointerCapture(e.pointerId);
      }
    },
    [startElement, readOnly, lineRefs, slots, cards, dotClicked, selectElement],
  );

  // keyboard support to handle selecting and linking items
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent, linkBoxEl: ISlotElement | ICardElement) => {
      const click = () => {
        if (readOnly) return;
        if (selectedElement) {
          if (selectedElement.ref === linkBoxEl.ref) {
            setSelectedElement(null);
          } else if (selectedElement.element === linkBoxEl.element) {
            setSelectedElement(linkBoxEl);
          } else {
            linkElements(sendAction, selectedElement, linkBoxEl, lineRefs);
            setSelectedElement(null);
          }
        } else {
          setSelectedElement(linkBoxEl);
        }
      };
      const keymaps: KeyMappings = {
        ' ': () => click(),
      };
      if (keyboardMode) {
        keymaps['Enter'] = () => click();
      }
      handleKeyPress(keymaps)(e);
    },
    [keyboardMode, readOnly, selectedElement, sendAction, lineRefs],
  );

  const clearAll = useCallback(() => {
    const slotRefs = slots.map(s => s.ref);
    sendAction({
      action: 'reset_cards',
      slotRefs,
      cardRefs: [],
    });
  }, [slots, sendAction]);

  const makeLinkBoxList = (items: ISlotElement[] | ICardElement[]) => (
    <Stack dir="vertical">
      {items.map((s, i) => (
        <LinkBoxElement
          key={i}
          ref={r => {
            if (r) boxRefs.current[s.ref] = r;
          }}
          element={s}
          onPointerEvent={onPointerEvent}
          selected={selectedElement?.ref === s.ref}
          onKeyDown={onKeyDown}
          linked={
            lineRefs.some(([lhs, rhs]) => lhs === s.ref || rhs === s.ref) ||
            startElement?.ref === s.ref ||
            endElement?.ref === s.ref
          }
          result={result.resultByRef?.[s.ref]}
          linkStartElement={startElement}
        />
      ))}
    </Stack>
  );

  return (
    <LinkBoxesContextProvider value={{ isLinkBoxes: true }}>
      <div
        className={classNames(styles.LinkBoxesWrapper, {
          [styles.ReadOnly]: readOnly,
        })}
      >
        <div className={styles.LinesContainer}>
          <LinkBoxLines
            elementRefs={boxRefs.current}
            lines={lineRefs}
            lhsRef={startElement?.element === 'slot' ? startElement?.ref : endElement?.ref}
            rhsRef={startElement?.element === 'slot' ? endElement?.ref : startElement?.ref}
            selectedRef={selectedElement?.ref}
            resultsByRef={result.resultByRef}
            removeLine={slotRef => {
              sendAction({
                action: 'reset_cards',
                slotRefs: [slotRef],
                cardRefs: [],
              });
            }}
          />
          <table>
            {(slotsHeader.length > 0 || cardsHeader.length > 0) && (
              <thead>
                <tr>
                  <th>
                    <LayoutElements element={{ content: slotsHeader }} />
                  </th>
                  <th></th>
                  <th>
                    <LayoutElements element={{ content: cardsHeader }} />
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              <tr>
                <td>{makeLinkBoxList(slots)}</td>
                <td className={styles.MiddleSpacing}></td>
                <td>{makeLinkBoxList(cards)}</td>
              </tr>
              <tr>
                <td colSpan={3}></td>
              </tr>
            </tbody>
          </table>
        </div>
        {!readOnly && (
          <div className={styles.ButtonContainer}>
            <button disabled={lineRefs.length === 0} className={styles.Button} onClick={clearAll}>
              Clear selection
            </button>
          </div>
        )}
        {result.show && (
          <div>
            <div
              className={classNames(sharedStyles.SummaryFeedback, {
                [sharedStyles.Correct]: result.numCorrect === result.total,
              })}
            >
              <CorrectIcon
                correct={result.numCorrect === result.total}
                inline={true}
                analyticsAnswerType="link-boxes-summary"
              />
              {result.numCorrect} out of {result.total} are correct
            </div>
          </div>
        )}
      </div>
    </LinkBoxesContextProvider>
  );
};

interface ILinkBoxElementProps {
  element: ICardElement | ISlotElement;
  linked: boolean;
  onPointerEvent: (
    e: React.PointerEvent<HTMLDivElement> | null,
    event: pointerEventType,
    linkBoxEl: ISlotElement | ICardElement,
  ) => void;
  onKeyDown: (event: React.KeyboardEvent, linkBoxEl: ISlotElement | ICardElement) => void;
  selected: boolean;
  result?: boolean;
  linkStartElement: ICardElement | ISlotElement | null;
}

const LinkBoxElement = forwardRef(
  (
    {
      element,
      onPointerEvent,
      onKeyDown,
      selected,
      linked,
      result,
      linkStartElement,
    }: ILinkBoxElementProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(styles.LinkBoxesBox, accessibilityStyles.FocusTarget, {
          [styles.Selected]: selected,
          [styles.Linked]: linked,
          [styles.Correct]: result === true,
          [styles.Incorrect]: result === false,
          [styles.DisableHover]:
            linkStartElement &&
            linkStartElement.ref !== element.ref &&
            linkStartElement.element === element.element,
        })}
        tabIndex={0}
        onPointerDown={e => onPointerEvent(e, 'down', element)}
        onPointerEnter={e => onPointerEvent(e, 'enter', element)}
        onPointerLeave={e => onPointerEvent(e, 'leave', element)}
        onKeyDown={e => onKeyDown(e, element)}
        // ios 12 doesn't support pointer events, so we need to use click events instead, but only if pointer events are not supported
        // eslint-disable-next-line compat/compat
        onClick={() => !window?.PointerEvent && onPointerEvent(null, 'click', element)}
      >
        <div
          className={classNames(
            styles.DotContainer,
            element.element === 'slot' ? styles.Left : styles.Right,
          )}
          onPointerDown={e => {
            e.stopPropagation();
            onPointerEvent(e, 'dot-down', element);
          }}
        >
          <div className={styles.Dot} />
        </div>
        {element.element === 'card' && result !== undefined && (
          <CorrectIcon correct={result} inline={false} analyticsAnswerType="link-box" />
        )}
        <LayoutElements element={element} />
      </div>
    );
  },
);
LinkBoxElement.displayName = 'LinkBoxElement';

interface ILinkBoxLinesProps {
  elementRefs: Record<string, HTMLDivElement>;
  lines: [string, string][];
  lhsRef?: string;
  rhsRef?: string;
  selectedRef?: string;
  resultsByRef?: Record<string, boolean | undefined>;
  removeLine: (slotRef: string) => void;
}

const lhsToXY = (lhs: DOMRect, box: DOMRect) => ({
  x: (lhs.left + lhs.width - box.left) / box.width,
  y: (lhs.top + lhs.height / 2 - box.top) / box.height,
});

const rhsToXY = (rhs: DOMRect, box: DOMRect) => ({
  x: (rhs.left - box.left) / box.width,
  y: (rhs.top + rhs.height / 2 - box.top) / box.height,
});

interface IPoint {
  x: number;
  y: number;
}

const posToPercent = (start: IPoint, end: IPoint) => ({
  startX: `${start.x * 100}%`,
  startY: `${start.y * 100}%`,
  endX: `${end.x * 100}%`,
  endY: `${end.y * 100}%`,
});

interface ILineProps {
  startX: string;
  startY: string;
  endX: string;
  endY: string;
  willReplace: boolean;
  correct?: boolean;
  ref: string;
}

const LinkBoxLines = ({
  elementRefs,
  lines,
  lhsRef,
  rhsRef,
  selectedRef,
  resultsByRef,
  removeLine,
}: ILinkBoxLinesProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>({
    x: 0,
    y: 0,
  });

  // calculate the positions for the line currently being drawn by the user
  const currentLineProps = useMemo(() => {
    const boxSize = svgRef.current?.getBoundingClientRect();
    const lhsBox = elementRefs[lhsRef || '']?.getBoundingClientRect();
    const rhsBox = elementRefs[rhsRef || '']?.getBoundingClientRect();

    if (!boxSize || (!lhsBox && !rhsBox)) {
      return null;
    }

    let start: { x: number; y: number } = { x: 0, y: 0 };
    let end: { x: number; y: number } = { x: 0, y: 0 };
    let usingMouse = false;

    if (lhsBox && rhsBox) {
      // draw line between the two boxes
      start = lhsToXY(lhsBox, boxSize);
      end = rhsToXY(rhsBox, boxSize);
    } else if (!mousePosition) {
      // This should really happen but just in case...
      return null;
    } else {
      // draw line between the first box and the mouse
      const pos = lhsBox ? lhsToXY(lhsBox, boxSize) : rhsToXY(rhsBox, boxSize);
      start = pos;
      end = mousePosition;
      usingMouse = true;
    }

    return {
      ...posToPercent(start, end),
      usingMouse,
    };
  }, [svgRef, elementRefs, lhsRef, rhsRef, mousePosition]);

  // calculate the positions of the placed lines
  const [lineProps, setLineProps] = useState<(ILineProps | null)[]>([]);
  const calculateLineProps = useCallback(
    () =>
      setLineProps(
        lines.map(([startRef, endRef]) => {
          const boxSize = svgRef.current?.getBoundingClientRect();
          const startBox = elementRefs[startRef]?.getBoundingClientRect();
          const endBox = elementRefs[endRef]?.getBoundingClientRect();

          if (!boxSize || !startBox || !endBox) {
            return null;
          }

          const start = lhsToXY(startBox, boxSize);
          const end = rhsToXY(endBox, boxSize);

          const willReplace =
            startRef === lhsRef ||
            endRef == rhsRef ||
            startRef === selectedRef ||
            endRef === selectedRef;

          return {
            ...posToPercent(start, end),
            willReplace,
            correct: resultsByRef?.[startRef],
            ref: startRef,
          };
        }),
      ),
    [svgRef, elementRefs, lines, lhsRef, rhsRef, selectedRef, resultsByRef],
  );

  // When the svg element is resized recalculate the line positions
  // This could be caused by the window resizing or the container resizing like when images are loaded
  useEffect(() => {
    if (svgRef.current) {
      calculateLineProps();
      const resizeObserver = new ResizeObserver(calculateLineProps);
      resizeObserver.observe(svgRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [svgRef, calculateLineProps]);

  // update the stored mouse position when the mouse moves
  useEffect(() => {
    const setMouseLocation = (e: PointerEvent) => {
      if (svgRef.current) {
        // Calculate the mouse XY position in % based on the svg bounding rect
        const boxSize = svgRef.current.getBoundingClientRect();
        const left = (e.clientX - boxSize.left) / boxSize.width;
        const top = (e.clientY - boxSize.top) / boxSize.height;
        setMousePosition({ x: left, y: top });
      }
    };
    window.addEventListener('pointermove', setMouseLocation);
    return () => {
      window.removeEventListener('pointermove', setMouseLocation);
    };
  });

  return (
    <svg ref={svgRef} className={styles.LinesSVG}>
      {lineProps.map((p, i) => {
        if (!p) {
          return null;
        }
        return (
          <Fragment key={i}>
            <line
              x1={p.startX}
              y1={p.startY}
              x2={p.endX}
              y2={p.endY}
              stroke={
                p.correct === undefined
                  ? 'var(--lb-line-colour)'
                  : p.correct
                    ? 'var(--lb-correct-colour)'
                    : 'var(--lb-incorrect-colour)'
              }
              opacity={p.willReplace ? 0.3 : 1}
              strokeWidth={4}
            />
            <line
              // This line is transparent and wider than the visible line to make it easier to click to clear the line
              onClick={() => removeLine(p.ref)}
              x1={p.startX}
              y1={p.startY}
              x2={p.endX}
              y2={p.endY}
              stroke={'transparent'}
              strokeWidth={12}
            />
          </Fragment>
        );
      })}
      {currentLineProps && (
        <>
          <line
            x1={currentLineProps.startX}
            y1={currentLineProps.startY}
            x2={currentLineProps.endX}
            y2={currentLineProps.endY}
            stroke="var(--lb-line-colour)"
            strokeWidth={4}
          />
          {currentLineProps.usingMouse && (
            <circle
              fill="var(--lb-line-colour)"
              r={4}
              cx={currentLineProps.endX}
              cy={currentLineProps.endY}
            />
          )}
        </>
      )}
    </svg>
  );
};

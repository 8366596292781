import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLibAnalytics } from '@sparx/analytics';
import { GetSittingResponse } from '@sparx/api/apis/sparx/assessment/app/v1/authentication';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { Button } from 'components/button/Button';
import { CodeInput } from 'components/codeinput/CodeInput';
import { useGetSitting } from 'queries/app';
import { FormEvent, useRef, useState } from 'react';

export const FindSitting = ({
  onSuccess,
}: {
  onSuccess: (sitting: GetSittingResponse) => void;
}) => {
  const sendEvent = useLibAnalytics();

  const formRef = useRef<HTMLFormElement>(null);
  const [input, setInput] = useState<string[]>([]);
  const inputValid = input.length >= 4 && input.every(i => i.length === 1);

  const { mutate, isLoading, isError, error, reset } = useGetSitting({
    onError: (error, code) => {
      setTimeout(() => {
        formRef.current?.querySelector('input')?.select();
      }, 100);

      // Track that we've failed to send it
      sendEvent({
        category: 'join-assessment',
        action: 'error',
        labels: {
          code,
          error: error.code,
        },
      });
    },
    onSuccess: data => {
      onSuccess(data);
    },
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Stop redirect
    if (!inputValid) return;

    const code = input.join('');
    mutate(code);
  };

  return (
    <form onSubmit={onSubmit} ref={formRef}>
      <Stack direction="column" spacing={4} align="center">
        {error && (
          <Alert status="error">
            <Alert.Icon />
            <Alert.Description>
              {error?.code === 'NOT_FOUND'
                ? 'Assessment not found. Ask your teacher for the assessment code.'
                : error?.code === 'DEADLINE_EXCEEDED'
                  ? 'Assessment has already ended. Check assessment code with your teacher.'
                  : 'An unknown error occurred, please try again.'}
            </Alert.Description>
          </Alert>
        )}
        <span>Enter the assessment join code:</span>
        <CodeInput
          input={input}
          setInput={i => {
            setInput(i);
            if (isError) reset();
          }}
          length={4}
          isDisabled={isLoading}
          isError={isError}
        />
        <Button
          type="submit"
          isDisabled={!inputValid}
          isLoading={isLoading}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          Join assessment
        </Button>
      </Stack>
    </form>
  );
};

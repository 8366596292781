import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { context } from './context';
import { initialise } from './initialise';

const apiBase = 'https://api-iam.intercom.io';

type SendEventFn = (
  event: { action: string; category: string },
  labels?: {
    [key: string]: string;
  },
) => void;

interface ProviderProps {
  appId: string;
  // Properties to pass to the boot method.
  // Only api_base and app_id are included by default.
  // If suppplied the boot method will be called with these properties automatically after initialisation.
  // If not supplied boot must be called manually.
  bootProps?: object;
  // If false the widget will not be loaded automatically.
  shouldInitialise?: boolean;
  onLoad?: () => void;
  onLoadFailed?: () => void;

  // If set, will intercept support links and open the widget instead to the relevant article.
  // Should be set to the prefix of the article urls eg. 'https://intercom.help/sparx-science/en/articles/'
  handleSupportLinks?: string;
  // If set, will intercept mailto links for the email set and open the widget instead
  handleSupportEmail?: string;

  // Function for sending page events, no events will be sent if unset
  sendEvent?: SendEventFn;
  eventCategory?: string; // defaults to intercom
}

export const IntercomProvider = ({
  appId,
  shouldInitialise = true,
  bootProps,
  onLoad,
  onLoadFailed,
  handleSupportEmail,
  handleSupportLinks,
  sendEvent: doSendEvent = () => undefined,
  eventCategory = 'intercom',
  children,
}: PropsWithChildren<ProviderProps>) => {
  const isInitialised = useRef(false);
  const isBooted = useRef(false);

  const [hasLoaded, setHasLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isOpenRef = useRef(isOpen);

  const sendEvent = useCallback(
    (
      action: string,
      labels?: {
        [key: string]: string;
      },
    ) => doSendEvent({ action, category: eventCategory }, labels),
    [doSendEvent, eventCategory],
  );

  const onLoadWrapper = useCallback(() => {
    console.debug('Intercom: load complete');
    setHasLoaded(true);
    onLoad && onLoad();
  }, [onLoad, setHasLoaded]);

  const onLoadFailedWrapper = useCallback(() => {
    console.warn('Intercom: failed to load');
    sendEvent('widget_load_failed');
    onLoadFailed && onLoadFailed();
  }, [onLoadFailed, sendEvent]);

  // Intercom always fires an onUnreadCountChange event when it first loads
  // We use this to check it's ready.
  const onUnreadCountChange = useCallback(
    (_: number) => {
      setIsReady(true);
    },
    [setIsReady],
  );

  const onShowWrapper = useCallback(() => {
    setIsOpen(true);
    isOpenRef.current = true;
    sendEvent('widget_opened');
  }, [setIsOpen, sendEvent]);

  const onHideWrapper = useCallback(() => {
    setIsOpen(false);
    isOpenRef.current = false;
    sendEvent('widget_closed');
  }, [setIsOpen, sendEvent]);

  const boot = useCallback(
    (bootProps?: object) => {
      if (!window.Intercom || !isInitialised.current) {
        console.warn('Intercom: tried to boot when not initialised');
        return;
      }

      const metadata = {
        api_base: apiBase,
        app_id: appId,
        ...bootProps,
      };

      window.intercomSettings = metadata;
      window.Intercom('boot', metadata);
      isBooted.current = true;
    },
    [appId],
  );

  useEffect(() => {
    if (shouldInitialise && !isInitialised.current) {
      initialise({
        appId,
        crossOrigin: 'anonymous',
        onLoad: onLoadWrapper,
        onLoadFailed: onLoadFailedWrapper,
      });

      window.Intercom?.('onShow', onShowWrapper);
      window.Intercom?.('onHide', onHideWrapper);
      window.Intercom?.('onUnreadCountChange', onUnreadCountChange);

      isInitialised.current = true;
    }

    if (isInitialised.current && bootProps && !isBooted.current) {
      boot(bootProps);
    }
  }, [
    shouldInitialise,
    bootProps,
    appId,
    onLoadWrapper,
    onLoadFailedWrapper,
    onShowWrapper,
    onHideWrapper,
    onUnreadCountChange,
    boot,
  ]);

  const ensureIntercom = useCallback(
    (functionName: string, callback: (() => void) | (() => string)) => {
      if (!window.Intercom || !isInitialised.current) {
        console.warn('Intercom: instance is not initialized');
        return;
      }
      if (!isBooted.current) {
        console.warn(`"${functionName}" was called but Intercom has not booted yet.`);
        return;
      }
      return callback();
    },
    [],
  );

  const update = useCallback(() => {
    ensureIntercom('update', () => window.Intercom?.('update'));
  }, [ensureIntercom]);

  const show = useCallback(() => {
    ensureIntercom('show', () => window.Intercom?.('show'));
  }, [ensureIntercom]);

  const hide = useCallback(() => {
    ensureIntercom('hide', () => window.Intercom?.('hide'));
  }, [ensureIntercom]);

  const showArticle = useCallback(
    (id: number) => {
      ensureIntercom('showArticle', () => window.Intercom?.('showArticle', id));
    },
    [ensureIntercom],
  );

  const showSpace = useCallback(
    (space: string) => {
      ensureIntercom('showSpace', () => window.Intercom?.('showSpace', space));
    },
    [ensureIntercom],
  );

  const showNewMessage = useCallback(() => {
    ensureIntercom('showNewMessage', () => window.Intercom?.('showNewMessage'));
  }, [ensureIntercom]);

  // Handle support & mailto links
  useEffect(() => {
    if (!handleSupportLinks && !handleSupportEmail) return;
    if (!window.Intercom || !isReady) return;

    // A click handler which will intercept intercom support links and open the widget instead
    const intercomLinkHandler = (event: MouseEvent) => {
      // have to have intercom, and the target must be a anchor tag.
      if (!window.Intercom || !(event.target instanceof Element) || event.target.tagName !== 'A')
        return;

      const link = event.target.getAttribute('href');
      if (!link) return; // no href, so not a link

      let handled = false;
      if (handleSupportEmail && link === `mailto:${handleSupportEmail}`) {
        handled = true;
        showNewMessage();
        sendEvent('click_mailto_link', { href: link });
      } else if (handleSupportLinks && link.startsWith(handleSupportLinks)) {
        const page = link.slice(handleSupportLinks.length);

        const match = page.match(/^(\d+)/);
        if (!match) return; // didn't find an article id

        handled = true;
        const id = Number(match[1]);
        showArticle(id);
        sendEvent('click_article_link', { href: link, article: id.toString() });
      }

      if (handled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    // Register the link handler to open any intercom support links in the intercom widget
    document.addEventListener('click', intercomLinkHandler, true);
    return () => {
      document.removeEventListener('click', intercomLinkHandler, true);
    };
  }, [handleSupportEmail, handleSupportLinks, isReady, sendEvent, showArticle, showNewMessage]);

  return (
    <context.Provider
      value={{
        intercomEnabled: hasLoaded && isReady,
        isOpen,
        isOpenRef,
        boot,
        update,
        show,
        hide,
        showArticle,
        showSpace,
        showNewMessage,
      }}
    >
      {children}
    </context.Provider>
  );
};

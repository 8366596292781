import * as RadixTooltip from '@radix-ui/react-tooltip';
import { PropsWithChildren, ReactNode, useState } from 'react';

import styles from './Tooltip.module.css';

export interface TooltipProps {
  content?: ReactNode;
  side?: RadixTooltip.TooltipContentProps['side'];
}

export const Tooltip = ({ children, content, side }: PropsWithChildren<TooltipProps>) => {
  const [open, setOpen] = useState(false);

  // If there is no tooltip content, then just return the children.
  if (!content) {
    return children;
  }

  return (
    <RadixTooltip.Root open={open} delayDuration={0} onOpenChange={setOpen}>
      <RadixTooltip.Trigger onClick={() => setOpen(true)} asChild>
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content className={styles.TooltipContent} side={side} sideOffset={5}>
          <div className={styles.Content}>{content}</div>
          <RadixTooltip.Arrow className={styles.TooltipArrow} width={16} height={8} />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/potteddelivery/v1/potteddelivery.proto" (package "sparx.games.progress.potteddelivery.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
import { UInt32Value } from '../../../../../../google/protobuf/wrappers';
import { Int32Value } from '../../../../../../google/protobuf/wrappers';
//
// Contains the data for the user's progress in the Potted Delivery algorithm
// This is the algorithm used to choose questions in Picture Shuffle and
// Gem Smash.
//
// The store contains:
// - The number of the questions we have asked
// - How many were asked correctly/incorrectly
// - The name of the pot last used
// - Which index of the discovery pot to search in
//
// - For each question that has been answered (there are currently 111
// questions):
// - What pot it is in
// - When it should next be asked (time and/or index)
// - When it was last asked (time and/or index)
// - If it is a "recap" question
// - Where it should go when it leaves quarantine

/**
 * @generated from protobuf message sparx.games.progress.potteddelivery.v1.Store
 */
export interface Store {
  /**
   * Position of the next question
   *
   * @generated from protobuf field: uint32 nextPosition = 1;
   */
  nextPosition: number;
  /**
   * Times got right in a row (when > 0) or wrong in a row (when < 0)
   *
   * @generated from protobuf field: google.protobuf.Int32Value streak = 2;
   */
  streak?: Int32Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value discoveryPosition = 3;
   */
  discoveryPosition?: UInt32Value;
  /**
   * @generated from protobuf field: string lastQuestionPot = 4;
   */
  lastQuestionPot: string;
  /**
   * @generated from protobuf field: map<string, sparx.games.progress.potteddelivery.v1.QuestionProgress> questions = 5;
   */
  questions: {
    [key: string]: QuestionProgress;
  };
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 6;
   */
  lastUpdatedTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.games.progress.potteddelivery.v1.QuestionProgress
 */
export interface QuestionProgress {
  /**
   * @generated from protobuf field: string pot = 1;
   */
  pot: string;
  /**
   * When this should be asked again
   *
   * @generated from protobuf field: uint64 nextTimestamp = 2;
   */
  nextTimestamp: number;
  /**
   * The position at which we want to ask this question again
   *
   * @generated from protobuf field: uint32 shortTermGapPosition = 3;
   */
  shortTermGapPosition: number;
  /**
   * @generated from protobuf field: bool flaggedForRecap = 4;
   */
  flaggedForRecap: boolean;
  /**
   * @generated from protobuf field: uint64 lastAnsweredTime = 5;
   */
  lastAnsweredTime: number;
  /**
   * Position of the last time the question was answered
   *
   * @generated from protobuf field: uint32 lastAnswerPosition = 6;
   */
  lastAnswerPosition: number;
  /**
   * @generated from protobuf field: string potAfterQuarantine = 7;
   */
  potAfterQuarantine: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.progress.potteddelivery.v1.Store', [
      {
        no: 1,
        name: 'nextPosition',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 2, name: 'streak', kind: 'message', T: () => Int32Value },
      {
        no: 3,
        name: 'discoveryPosition',
        kind: 'message',
        T: () => UInt32Value,
      },
      {
        no: 4,
        name: 'lastQuestionPot',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'questions',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => QuestionProgress },
      },
      {
        no: 6,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.potteddelivery.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuestionProgress$Type extends MessageType<QuestionProgress> {
  constructor() {
    super('sparx.games.progress.potteddelivery.v1.QuestionProgress', [
      { no: 1, name: 'pot', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'nextTimestamp',
        kind: 'scalar',
        T: 4 /*ScalarType.UINT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 3,
        name: 'shortTermGapPosition',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 4,
        name: 'flaggedForRecap',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'lastAnsweredTime',
        kind: 'scalar',
        T: 4 /*ScalarType.UINT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 6,
        name: 'lastAnswerPosition',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 7,
        name: 'potAfterQuarantine',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.potteddelivery.v1.QuestionProgress
 */
export const QuestionProgress = new QuestionProgress$Type();

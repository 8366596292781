import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  GetSittingResponse,
  JoinSittingRequest,
  JoinSittingResponse,
} from '@sparx/api/apis/sparx/assessment/app/v1/authentication';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { authenticationClient, authenticationClientWithAuth } from 'api/clients';
import { useSchool } from 'components/ensuresession/EnsureSession';
import { useEffect, useRef } from 'react';

export const useGetSitting = (opts?: UseMutationOptions<GetSittingResponse, RpcError, string>) => {
  const { schoolId } = useSchool();
  return useMutation({
    mutationFn: async (joinCode: string) =>
      authenticationClient.getSitting({
        joinCode: joinCode,
        schoolName: `schools/${schoolId}`,
      }).response,
    ...opts,
  });
};

export const useJoinSitting = (
  opts?: UseMutationOptions<JoinSittingResponse, RpcError, JoinSittingRequest>,
) => {
  // Abort the request if the component unmounts
  const abortRef = useRef<AbortController>(new AbortController());
  useEffect(() => {
    const abort = abortRef.current;
    return () => {
      abort.abort('unmount');
      abortRef.current = new AbortController(); // reset controller
    };
  }, [abortRef]);

  return useMutation({
    mutationFn: async (req: JoinSittingRequest) =>
      (req.method.oneofKind === 'withToken'
        ? authenticationClientWithAuth
        : authenticationClient
      ).joinSitting(req, {
        abort: abortRef.current.signal,
      }).response,
    ...opts,
  });
};

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { Property } from 'csstype';
import { ComponentProps, PropsWithChildren } from 'react';

import { SparxSize, sparxSizeToValue } from '../../utils/units';
import { Stack } from '../stack/Stack';
import styles from './Modal.module.css';

interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  overlayDismiss?: boolean;
  overlayClassName?: string;
}

/**
 * Modal is a component that is used to display content on top of the current page.
 *
 * ### CSS Overrides
 *
 * The colours of the alerts can be overridden for each status:
 *
 * - `--colours-modal-background`: Background colour for the modal
 * - `--colours-modal-button`: Colour for the modal close button
 * - `--colours-modal-button-hover`: Colour for the modal close button on hover
 * - `--colours-modal-button-background-hover`: Background colour for the modal close button on hover
 *
 * @example
 * <Modal isOpen={isOpen} onClose={onClose}>
 *   <Modal.Content>
 *     <Modal.Title>Agree to subscribe</Modal.Title>
 *     <Modal.CloseButton />
 *     <Modal.Body>
 *       Description
 *     </Modal.Body>
 *     <Modal.Footer>
 *       <Button variant="outlined" onClick={onClose}>
 *         Cancel
 *       </Button>
 *       <Button variant="contained" isDisabled={!isValid}>
 *         Agree
 *       </Button>
 *     </Modal.Footer>
 *   </Modal.Content>
 * </Modal>
 *
 * @param children Content of the modal
 * @param isOpen If the modal is open
 * @param onClose Callback for when the modal is closed
 * @param overlayDismiss Whether clicking the backdrop should close the modal
 * @param overlayClassName Class name for the overlay
 * @constructor
 */
export const Modal = ({
  children,
  isOpen,
  onClose,
  overlayDismiss = true,
  overlayClassName,
}: PropsWithChildren<ModalProps>) => (
  <Dialog.Root open={isOpen} onOpenChange={open => !open && onClose?.()}>
    <Dialog.Portal>
      <Dialog.Overlay className={classNames(styles.Overlay, overlayClassName)}>
        {overlayDismiss && <div className={styles.OverlayClickTrap} onClick={onClose} />}
        {children}
      </Dialog.Overlay>
    </Dialog.Portal>
  </Dialog.Root>
);

interface ContentProps extends ComponentProps<'div'> {
  width?: SparxSize;
}

const Content = ({ children, width = 'xl', className, style, ...rest }: ContentProps) => (
  <Dialog.Content
    // Outside interaction controlled by overlayDismiss on modal parent
    onInteractOutside={e => e.preventDefault()}
    className={classNames(styles.Content, className)}
    style={{
      maxWidth: sparxSizeToValue(width),
      ...style,
    }}
    {...rest}
  >
    {children}
  </Dialog.Content>
);
Modal.Content = Content;

const CloseButton = () => (
  <Dialog.Close className={styles.CloseButton}>
    <FontAwesomeIcon icon={faTimes} />
  </Dialog.Close>
);
Modal.CloseButton = CloseButton;

interface TitleProps {
  align?: Property.TextAlign;
  className?: string;
}

const Title = ({ children, className, align }: PropsWithChildren<TitleProps>) => (
  <Dialog.Title className={classNames(styles.Title, className)} style={{ textAlign: align }}>
    {children}
  </Dialog.Title>
);
Modal.Title = Title;

const Body = ({ className, children, ...rest }: ComponentProps<'div'>) => (
  <div className={classNames(styles.Description, className)} {...rest}>
    {children}
  </div>
);
Modal.Body = Body;

interface FooterProps {
  align?: Property.AlignItems;
  className?: string;
}

const Footer = ({ children, align = 'flex-end', className }: PropsWithChildren<FooterProps>) => (
  <div className={classNames(styles.ButtonsContainer, className)} style={{ justifyContent: align }}>
    <Stack spacing={3}>{children}</Stack>
  </div>
);
Modal.Footer = Footer;

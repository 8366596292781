import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { AssessmentTabs } from '@sparx/assessments/src/components/AssessmentLandingView/AssessmentTab/AssessmentTab';
import { MarkSheetView } from 'views/teacher/assessmentsview/marksheetview/MarksheetView';
import { ReadingTestResultsTable } from 'views/teacher/assessmentsview/reportsview/ReadingTestResultsTable';

export const ReportsView = ({ assessment }: { assessment: Assessment }) => {
  if (assessment.subjectKey === 'english') {
    return <ReadingTestResultsTable assessment={assessment} />;
  }

  return <MarkSheetView assessment={assessment} fixedTab={AssessmentTabs.Reports} />;
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/autohilo/v1/autohilo.proto" (package "sparx.games.state.autohilo.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.games.state.autohilo.v1.Store
 */
export interface Store {}
/**
 * @generated from protobuf message sparx.games.state.autohilo.v1.State
 */
export interface State {
  /**
   * @generated from protobuf field: uint32 lives = 1;
   */
  lives: number;
  /**
   * @generated from protobuf field: uint32 ticksNeeded = 2;
   */
  ticksNeeded: number;
  /**
   * @generated from protobuf field: int32 lowestValue = 3;
   */
  lowestValue: number;
  /**
   * @generated from protobuf field: int32 highestValue = 4;
   */
  highestValue: number;
  /**
   * @generated from protobuf field: bool shouldWin = 5;
   */
  shouldWin: boolean;
}
/**
 * @generated from protobuf message sparx.games.state.autohilo.v1.Init
 */
export interface Init {
  /**
   * @generated from protobuf field: uint32 lives = 1;
   */
  lives: number;
  /**
   * @generated from protobuf field: uint32 ticksNeeded = 2;
   */
  ticksNeeded: number;
  /**
   * @generated from protobuf field: int32 lowestValue = 3;
   */
  lowestValue: number;
  /**
   * @generated from protobuf field: int32 highestValue = 4;
   */
  highestValue: number;
  /**
   * @generated from protobuf field: bool shouldWin = 5;
   */
  shouldWin: boolean;
}
/**
 * @generated from protobuf message sparx.games.state.autohilo.v1.Action
 */
export interface Action {
  /**
   * @generated from protobuf field: sparx.games.state.autohilo.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * @generated from protobuf enum sparx.games.state.autohilo.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * @generated from protobuf enum value: ended = 0;
   */
  ended = 0,
}
/**
 * @generated from protobuf message sparx.games.state.autohilo.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.autohilo.v1.Params
 */
export interface Params {}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.autohilo.v1.Store', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.autohilo.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.autohilo.v1.State', [
      { no: 1, name: 'lives', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      {
        no: 2,
        name: 'ticksNeeded',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 3, name: 'lowestValue', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'highestValue',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'shouldWin', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.autohilo.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.autohilo.v1.Init', [
      { no: 1, name: 'lives', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      {
        no: 2,
        name: 'ticksNeeded',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 3, name: 'lowestValue', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'highestValue',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'shouldWin', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.autohilo.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.autohilo.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.autohilo.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.autohilo.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.autohilo.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.autohilo.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.autohilo.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.autohilo.v1.Params
 */
export const Params = new Params$Type();

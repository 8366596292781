import { RpcError } from '@protobuf-ts/runtime-rpc';
import { useQuery } from '@tanstack/react-query';
import { getToken, storeSchoolId } from 'api/auth';
import { authenticationClient } from 'api/clients';
import { Button } from 'components/button/Button';
import { CenteredPage } from 'components/centeredpage/CenteredPage';
import { LargeLoadingPage } from 'components/largeloading/LargeLoading';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const alreadyAuthenticatedError = 'You are already logged in';

export const TokenAuthView = () => {
  const [params] = useSearchParams();

  const schoolID = params.get('school') || '';
  const token = params.get('t') || '';

  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(true);
  const { error } = useQuery({
    queryKey: ['teacher', 'auth'],
    queryFn: async () => {
      const authToken = await getToken();
      if (authToken.ok && authToken.status === 200) {
        throw new Error(alreadyAuthenticatedError);
      }

      const resp = await authenticationClient.authenticateGuestTeacher({
        token: token,
        schoolName: `schools/${schoolID}`,
      }).response;

      storeSchoolId(schoolID);
      navigate(`/teacher/sittings/${resp.sittingName.split('/')[3]}`);
      return true;
    },
    retry: false,
    refetchOnMount: false,
    enabled: enabled,
  });

  // If error, disable the query
  useEffect(() => {
    if (error) {
      setEnabled(false);
    }
  }, [setEnabled, error]);

  if (error) {
    return (
      <CenteredPage>
        <h2>Error logging in</h2>
        <p style={{ marginBottom: '2em' }}>
          {error instanceof Error && error.message === alreadyAuthenticatedError
            ? 'You are already signed in to Sparx Assessments'
            : error instanceof RpcError && error.message === 'invalid token'
              ? 'The link you followed is invalid or has expired'
              : 'An error occurred'}
        </p>
        <Button as={Link} to="/">
          Back to homepage
        </Button>
      </CenteredPage>
    );
  }

  return <LargeLoadingPage />;
};

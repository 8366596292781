import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  BatchGetAssessmentRevisionProgressRequest,
  BatchGetAssessmentRevisionProgressResponse,
  BatchGetStudentAssessmentsRequest,
  BatchGetStudentAssessmentsResponse,
  GetAssessmentGroupRequest,
  GetAssessmentGroupResponse,
  GetAssessmentInsightsRequest,
  GetAssessmentInsightsResponse,
  GetAssessmentRevisionInsightsRequest,
  GetAssessmentRevisionInsightsResponse,
  GetAssessmentRevisionRequest,
  GetAssessmentRevisionResponse,
  GetNationalComparisonReportDryRunRequest,
  GetNationalComparisonReportRequest,
  GetNationalComparisonReportResponse,
  GetTrustNationalComparisonReportRequest,
  GroupSettings,
  ListAssessmentGroupsResponse,
  ListAssessmentPackageProgressRequest,
  ListAssessmentPackageProgressResponse,
  ListAssessmentsRequest,
  ListAssessmentsResponse,
  ListGroupSettingsRequest,
  ListGroupSettingsResponse,
  ListLatestAssessmentSnapshotsResponse,
  ListStudentAssessmentsRequest,
  ListStudentAssessmentsResponse,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { useAssessmentContext } from '../context';

const minutesToMilliseconds = (minutes: number) => minutes * 60 * 1000;
const getSchoolName = (schoolID: string) => `schools/${schoolID}`;

const useListAssessmentsQueryKey = 'useListAssessmentsKey';
export function useListAssessments<TData = ListAssessmentsResponse>(
  req: ListAssessmentsRequest,
  options?: UseQueryOptions<ListAssessmentsResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();
  return useQuery({
    queryKey: [useListAssessmentsQueryKey, req],
    queryFn: () => assessmentClient.listAssessments(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useListStudentAssessmentsQueryKey = 'useListStudentAssessments';
type studentAssignmentsKey = [string, string, string[]];
export function useListStudentAssessments<TData = ListStudentAssessmentsResponse>(
  request: ListStudentAssessmentsRequest,
  options?: UseQueryOptions<ListStudentAssessmentsResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();

  const key: studentAssignmentsKey = [
    useListStudentAssessmentsQueryKey,
    request.assessmentName,
    request.studentIds.sort((a, b) => a.localeCompare(b)),
  ];

  return useQuery({
    queryKey: key,
    queryFn: () => assessmentClient.listStudentAssessments(request).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useBatchGetStudentAssessmentsQueryKey = 'useBatchGetStudentAssessments';
export function useBatchGetStudentAssessments<TData = BatchGetStudentAssessmentsResponse>(
  request: BatchGetStudentAssessmentsRequest,
  options?: UseQueryOptions<ListStudentAssessmentsResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();

  return useQuery({
    queryKey: [
      useBatchGetStudentAssessmentsQueryKey,
      request.assessmentNames.sort((a, b) => a.localeCompare(b)),
      request.studentIds.sort((a, b) => a.localeCompare(b)),
    ],
    queryFn: () => assessmentClient.batchGetStudentAssessments(request).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

export function useUpdateStudentAssessment() {
  const queryClient = useQueryClient();
  const { assessmentClient } = useAssessmentContext();

  return useMutation(
    (req: StudentAssessment) => assessmentClient.updateStudentAssessment(req).response,
    {
      onSuccess: studentAssessment => {
        const parentKey = [useListStudentAssessmentsQueryKey, studentAssessment.assessmentName];
        // Get all the queries for this assessment, this can include queries for different sets of students
        const queriesData = queryClient.getQueriesData<ListStudentAssessmentsResponse>(parentKey);

        queriesData.forEach(([key, oldData]) => {
          // Check if this query key contains the student that has been updated
          const studentIDs = (key as studentAssignmentsKey)[2];
          if (studentIDs.includes(studentAssessment.studentId)) {
            // Find this students assessment in the query data and update it
            const updatedAssessments = oldData?.studentAssessments.map(oldAssessment =>
              oldAssessment.studentId === studentAssessment.studentId
                ? studentAssessment
                : oldAssessment,
            );
            queryClient.setQueryData(key, { studentAssessments: updatedAssessments });
          }
        });

        // Invalidate the Insights query so that it rerenders, since the information may have changed
        // from updating the student assessments.
        queryClient.invalidateQueries([useGetAssessmentInsightsQueryKey]);

        // Invalidate batch student assessments query so that it rerenders
        queryClient.invalidateQueries([useBatchGetStudentAssessmentsQueryKey]);
      },
    },
  );
}

const useListGroupSettingsQueryKey = 'useListGroupSettings';

/**
 * Query to fetch all the assessment settings for a particular assessment for all groups in a school
 * @param req
 * @param options
 */
export function useListGroupSettings<TData = ListGroupSettingsResponse>(
  req: ListGroupSettingsRequest,
  options?: UseQueryOptions<ListGroupSettingsResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();
  return useQuery({
    queryKey: [useListGroupSettingsQueryKey, req.assessmentName],
    queryFn: () => assessmentClient.listGroupSettings(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

/**
 * Mutation to update the assessment settings for a particular group
 */
export function useUpdateGroupSettings() {
  const queryClient = useQueryClient();
  const { assessmentClient } = useAssessmentContext();

  return useMutation((req: GroupSettings) => assessmentClient.updateGroupSettings(req).response, {
    onSuccess: (groupSetting: GroupSettings) => {
      // Update the query data for the group settings query after a successful update
      queryClient.setQueryData(
        [useListGroupSettingsQueryKey, groupSetting.assessmentName],
        (oldData: ListGroupSettingsResponse | undefined) => {
          const settings =
            oldData?.settings?.map(oldGroupSetting =>
              oldGroupSetting.groupName === groupSetting.groupName ? groupSetting : oldGroupSetting,
            ) || [];
          if (!settings.find(s => s.groupName === groupSetting.groupName)) {
            settings.push(groupSetting);
          }
          return { settings };
        },
      );
      // Invalidate the queries for package progress (all groups)
      queryClient.invalidateQueries({ queryKey: [useListAssessmentPackageProgressQueryKey] });
    },
  });
}

const useListAssessmentPackageProgressQueryKey = 'useListAssessmentPackageProgress';

/**
 * Query to fetch the assessment package progress for a particular assessment for a given set of students
 *
 * @param req
 * @param options
 */
export function useListAssessmentPackageProgress<TData = ListAssessmentPackageProgressResponse>(
  req: ListAssessmentPackageProgressRequest,
  options?: UseQueryOptions<ListAssessmentPackageProgressResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();

  const key: studentAssignmentsKey = [
    useListAssessmentPackageProgressQueryKey,
    req.assessmentName,
    req.studentIds.sort((a, b) => a.localeCompare(b)),
  ];

  return useQuery({
    queryKey: key,
    queryFn: () => assessmentClient.listAssessmentPackageProgress(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useGetAssessmentInsightsQueryKey = 'useGetAssessmentInsights';

export function useGetAssessmentInsights<TData = GetAssessmentInsightsResponse>(
  req: GetAssessmentInsightsRequest,
  options?: UseQueryOptions<GetAssessmentInsightsResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();

  const key: studentAssignmentsKey = [
    useGetAssessmentInsightsQueryKey,
    req.assessmentName,
    req.studentIds.sort((a, b) => a.localeCompare(b)),
  ];

  return useQuery({
    queryKey: key,
    queryFn: () => assessmentClient.getAssessmentInsights(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useGetNationalComparisonReportQueryKey = 'useGetNationalComparisonReport';
export function useGetNationalComparisonReport<TData = GetNationalComparisonReportResponse>(
  req: GetNationalComparisonReportRequest,
  options?: UseQueryOptions<GetNationalComparisonReportResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();

  return useQuery({
    queryKey: [useGetNationalComparisonReportQueryKey, req.assessmentName, ''],
    queryFn: () => assessmentClient.getNationalComparisonReport(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useGetNationalComparisonReportDryRunQueryKey = 'useGetNationalComparisonReportDryRun';
export function useGetNationalComparisonDryRunReport<TData = GetNationalComparisonReportResponse>(
  req: GetNationalComparisonReportDryRunRequest,
  options?: UseQueryOptions<GetNationalComparisonReportResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();
  return useQuery({
    queryKey: [useGetNationalComparisonReportDryRunQueryKey, req.assessmentName, req.dryRunTag],
    queryFn: () => assessmentClient.getNationalComparisonReportDryRun(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useListLatestAssessmentSnapshotsQueryKey = 'useListLatestAssessmentSnapshots';
export function useListLatestAssessmentSnapshots<TData = ListLatestAssessmentSnapshotsResponse>(
  options?: UseQueryOptions<ListLatestAssessmentSnapshotsResponse, RpcError, TData>,
) {
  const { assessmentClient, schoolId } = useAssessmentContext();
  return useQuery({
    queryKey: [useListLatestAssessmentSnapshotsQueryKey],
    queryFn: () =>
      assessmentClient.listLatestAssessmentSnapshots({
        schoolName: getSchoolName(schoolId),
      }).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useGetTrustNationalComparisonReportQueryKey = 'useGetTrustNationalComparisonReport';
export function useGetTrustNationalComparisonReport<TData = GetNationalComparisonReportResponse>(
  req: GetTrustNationalComparisonReportRequest,
  options?: UseQueryOptions<GetNationalComparisonReportResponse, RpcError, TData>,
) {
  const { assessmentClient } = useAssessmentContext();
  return useQuery({
    queryKey: [
      useGetTrustNationalComparisonReportQueryKey,
      req.assessmentName,
      req.dryRunTag,
      ...req.yearGroupIds.sort((a, b) => a.localeCompare(b)),
    ],
    queryFn: () => assessmentClient.getTrustNationalReport(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useGetAssessmentRevisionQueryKey = 'useGetAssessmentRevision';
export function useGetAssessmentRevision<TData = GetAssessmentRevisionResponse>(
  req: GetAssessmentRevisionRequest,
  options?: UseQueryOptions<GetAssessmentRevisionResponse, Error, TData>,
) {
  const { assessmentClient } = useAssessmentContext();
  return useQuery({
    queryKey: [useGetAssessmentRevisionQueryKey, req.name],
    queryFn: () => assessmentClient.getAssessmentRevision(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    retry: (_, error) => {
      // For a not found error there is no point retrying
      if (error instanceof RpcError) {
        return error.code !== 'NOT_FOUND';
      }
      return true;
    },
    retryOnMount: false,
    ...options,
  });
}

const useGetAssessmentRevisionInsightsQueryKey = 'useGetAssessmentRevisionInsights';

export function useGetAssessmentRevisionInsights<TData = GetAssessmentRevisionInsightsResponse>(
  req: GetAssessmentRevisionInsightsRequest,
  options?: UseQueryOptions<GetAssessmentRevisionInsightsResponse, Error, TData>,
) {
  const { assessmentClient } = useAssessmentContext();

  const key: studentAssignmentsKey = [
    useGetAssessmentRevisionInsightsQueryKey,
    req.assessmentRevisionName,
    req.studentIds.sort((a, b) => a.localeCompare(b)),
  ];

  return useQuery({
    queryKey: key,
    queryFn: () => assessmentClient.getAssessmentRevisionInsights(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useBatchGetAssessmentRevisionProgressQueryKey = 'useBatchGetAssessmentRevisionProgress';
export function useBatchGetAssessmentRevisionProgress<
  TData = BatchGetAssessmentRevisionProgressResponse,
>(
  req: BatchGetAssessmentRevisionProgressRequest,
  options?: UseQueryOptions<BatchGetAssessmentRevisionProgressResponse, Error, TData>,
) {
  const { assessmentClient } = useAssessmentContext();
  return useQuery({
    queryKey: [
      useBatchGetAssessmentRevisionProgressQueryKey,
      req.assessmentName,
      req.studentIds.sort((a, b) => a.localeCompare(b)),
    ],
    queryFn: () => assessmentClient.batchGetAssessmentRevisionProgress(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useListAssessmentGroupsQueryKey = 'useListAssessmentGroups';
export function useListAssessmentGroups<TData = ListAssessmentGroupsResponse>(
  options?: UseQueryOptions<ListAssessmentGroupsResponse, Error, TData>,
) {
  const { assessmentClient } = useAssessmentContext();
  return useQuery({
    queryKey: [useListAssessmentGroupsQueryKey],
    queryFn: () =>
      assessmentClient.listAssessmentGroups({ subjectName: 'subjects/maths' }).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    ...options,
  });
}

const useGetAssessmentGroupQueryKey = 'useGetAssessmentGroup';
export function useGetAssessmentGroup<TData = GetAssessmentGroupResponse>(
  req: GetAssessmentGroupRequest,
  options?: UseQueryOptions<GetAssessmentGroupResponse, Error, TData>,
) {
  const { assessmentClient } = useAssessmentContext();
  return useQuery({
    queryKey: [useGetAssessmentGroupQueryKey, req.name],
    queryFn: () => assessmentClient.getAssessmentGroup(req).response,
    refetchOnWindowFocus: false,
    cacheTime: minutesToMilliseconds(15),
    staleTime: minutesToMilliseconds(15),
    enabled: req.name !== '',
    ...options,
  });
}

import { faEyeSlash, faHourglass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SittingParticipantState,
  StudentActiveState,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Package } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { Tooltip } from 'components/tooltip/Tooltip';
import { differenceInSeconds, max } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

const minMinutesToShow = 2;

export const ProgressTimer = ({
  participantState,
  pkg,
}: {
  participantState: SittingParticipantState | undefined;
  pkg: Package | undefined;
}) => {
  const timeSeconds = useMemo(
    () => getLastProgressedSeconds(participantState, pkg),
    [participantState, pkg],
  );
  const timeMinutes = Math.floor(timeSeconds / 60);
  const active = participantState?.reportedState?.activeState === StudentActiveState.ACTIVE;
  const showTime = timeSeconds > 60 * minMinutesToShow;

  const color = !active && showTime ? 'red' : 'orange';

  return (
    <Stack spacing={2}>
      {(!active || showTime) && (
        <Tooltip
          content={
            active
              ? 'Student has not made progress recently'
              : 'Student is currently not on the assessments window'
          }
        >
          <div style={{ color, fontSize: 'var(--spx-font-size-md)' }}>
            <FontAwesomeIcon icon={!active ? faEyeSlash : faHourglass} fixedWidth={true} />
          </div>
        </Tooltip>
      )}
      {showTime && (
        <div color={color}>
          {timeMinutes} min{timeMinutes > 1 && 's'}
        </div>
      )}
      {active && !showTime && <>Online</>}
    </Stack>
  );
};

export const isStudentActive = (state: SittingParticipantState | undefined) => {
  if (!state || !state.lastSeen) return false;

  const diff = differenceInSeconds(new Date(), Timestamp.toDate(state.lastSeen));
  return diff <= 30;
};

export const useUpdatingStudentActive = (state: SittingParticipantState | undefined) => {
  // Ensure that the component is updating periodically
  const [date, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 5000);
    return () => clearInterval(interval);
  }, []);

  return useMemo(() => date && isStudentActive(state), [state, date]);
};

const getLastProgressedSeconds = (
  participantState: SittingParticipantState | undefined,
  pkg: Package | undefined,
) => {
  const timestamps: Date[] = [];
  if (pkg?.startTimestamp) {
    timestamps.push(Timestamp.toDate(pkg.startTimestamp));
  }
  if (participantState?.lastProgressed) {
    timestamps.push(Timestamp.toDate(participantState.lastProgressed));
  }
  if (timestamps.length === 0) {
    return 0;
  }

  const maxTimestamp = max(timestamps);
  return differenceInSeconds(new Date(), maxTimestamp);
};

import {
  RpcInterceptor,
  RpcOutputStreamController,
  ServerStreamingCall,
  UnaryCall,
} from '@protobuf-ts/runtime-rpc';

import { AccessTokenProvider } from './tokenprovider';

/**
 * Interceptor to add RPC metadata.
 */
export const authInterceptor = (
  accessTokenProvider: AccessTokenProvider,
  opts?: {
    getSessionID?: () => Promise<{ sessionID: string; serverOffset: number }>;
    withServerOffset?: boolean;
  },
): RpcInterceptor => ({
  // Based on approach here: https://github.com/timostamm/protobuf-ts/issues/31#issuecomment-733025632
  interceptUnary(next, method, input, options): UnaryCall {
    const { getSessionID, withServerOffset } = opts || {};
    const getSession = getSessionID || (() => undefined);
    const callPromise = new Promise<UnaryCall>(resolve => {
      Promise.all([getSession(), accessTokenProvider.requestAccessToken()]).then(
        ([sessionData, accessToken]) => {
          if (!options.meta) options.meta = {};
          options.meta['Authorization'] = accessToken;
          if (sessionData) {
            const { sessionID, serverOffset } = sessionData;
            options.meta['X-Session-ID'] = sessionID;
            if (withServerOffset) {
              options.meta['X-Server-Offset'] = `${serverOffset}`;
            }
          }
          resolve(next(method, input, options));
        },
      );
    });

    return new UnaryCall(
      method,
      options.meta ?? {},
      input,
      callPromise.then(c => c.headers),
      callPromise.then(c => c.response),
      callPromise.then(c => c.status),
      callPromise.then(c => c.trailers),
    );
  },
  // Adapted from https://github.com/timostamm/protobuf-ts/issues/31#issuecomment-733118832
  interceptServerStreaming(next, method, input, options): ServerStreamingCall {
    // we have to return an output stream instance before we get one
    // from next(), so we create our own and delegate data below...
    const outputStream = new RpcOutputStreamController();

    // next() creates a call instance, sending data (including headers) to the server.
    // we want to wait until we have our token:
    const callPromise = new Promise<[ServerStreamingCall]>(resolve => {
      accessTokenProvider.requestAccessToken().then(accessToken => {
        if (!options.meta) {
          options.meta = {};
        }
        options.meta['Authorization'] = accessToken;

        // start the call
        const call = next(method, input, options);

        // delegate from the original output stream to the one controlled by us
        call.responses.onNext((message, error, done) => {
          if (message) outputStream.notifyMessage(message);
          if (error) outputStream.notifyError(error);
          if (done) outputStream.notifyComplete();
        });

        // deliberately returning a tuple. the call is awaitable - the promise chain
        // would wait until the call is finished.
        resolve([call]);
      });
    });

    // we have to return a valid call instance immediately.
    // we wrap the promised call.
    return new ServerStreamingCall(
      method,
      options.meta ?? {},
      input,
      callPromise.then(c => c[0].headers),
      outputStream,
      callPromise.then(c => c[0].status),
      callPromise.then(c => c[0].trailers),
    );
  },
});

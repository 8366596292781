import { ListSittingsResponse_SittingData } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useSession } from 'api/auth';
import { useSittings } from 'api/sittings';
import { Button } from 'components/button/Button';
import { PageHeader } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { Link } from 'react-router-dom';
import { sessionIsGuestTeacher } from 'utils/sessions';
import enterAssessmentDataIcon from 'views/teacher/assessmentsview/images/icon_assessment_data.png';
import digitalAssessmentIcon from 'views/teacher/assessmentsview/images/icon_digital_assessment.png';
import { Filters } from 'views/teacher/sittingslistview/Filters';
import { SittingItem } from 'views/teacher/sittingslistview/SittingItem';

import styles from './HomeView.module.css';

export const HomeView = () => {
  const { data: session } = useSession({ suspense: true });
  const isGuestTeacher = sessionIsGuestTeacher(session);

  return (
    <PageContainer>
      <PageHeader>Home</PageHeader>

      <div className={styles.Cards}>
        <section className={styles.Card}>
          <div className={styles.CardImage}>
            <img src={digitalAssessmentIcon} alt="" />
          </div>
          <div className={styles.CardInfo}>
            <h3>Set an online assessment</h3>
            <p>
              Select the digital assessment you want your students to take and set up a sitting for
              your classes.
            </p>
            <Button as={Link} to="/teacher/sittings/create">
              Create a new sitting
            </Button>
          </div>
        </section>
        {!isGuestTeacher && (
          <section className={styles.Card}>
            <div className={styles.CardImage}>
              <img src={enterAssessmentDataIcon} alt="" />
            </div>
            <div className={styles.CardInfo}>
              <h3>Enter assessment data</h3>
              <p>Enter data from a paper assessment that your students have completed.</p>
              <Button as={Link} to="/teacher/assessments">
                Enter data
              </Button>
            </div>
          </section>
        )}
      </div>
      <ActiveSittingList />
    </PageContainer>
  );
};

const ActiveSittingList = () => {
  const { data: sittings } = useSittings({ suspense: true });
  return <FilteredSittingsList sittings={sittings?.sittings || []} />;
};

const FilteredSittingsList = ({ sittings }: { sittings: ListSittingsResponse_SittingData[] }) => (
  <Filters sittings={sittings} clearBeforeFilters={true}>
    {({ filters, filteredSittings }) => (
      <>
        <div className={styles.SittingsHeader}>
          <h2>Active sittings</h2>
          <div className={styles.Filters}>{filters}</div>
        </div>
        <Stack spacing={2} direction="column">
          {filteredSittings.map(sitting => (
            <SittingItem
              key={sitting.sitting?.sittingName}
              sitting={sitting.sitting!}
              participantCount={sitting.participantCount}
            />
          ))}
          {filteredSittings.length === 0 && (
            <div className={styles.NoSittingsFound}>No sittings found</div>
          )}
        </Stack>
      </>
    )}
  </Filters>
);

import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Tabs } from '@sparx/sparx-design/components/tabs/Tabs';
import { LargeLoading } from '@sparx/sparx-design/icons';
import { useQuery } from '@tanstack/react-query';
import { getSchoolID } from 'api/auth';
import { sittingsClient } from 'api/clients';
import { useAssessmentSittings } from 'api/sittings';
import { useStudents } from 'api/students';
import { ExportedResultsTable } from 'components/matchtable/ExportedResultsTable';
import { ExportResultsTable } from 'components/matchtable/ExportResultsTable';
import { MatchGuestTable } from 'components/matchtable/MatchGuestTable';
import styles from 'components/matchtable/MatchTable.module.css';
import {
  GuestParticipantMatch,
  ResolveParticipantMatch,
  SittingLookup,
  useParticipantMatch,
} from 'components/matchtable/utils';
import { Suspense, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const MatchTable = ({ assessment }: { assessment: Assessment }) => {
  const [search, setSearch] = useSearchParams();
  const tab = search.get('tab') || 'match';
  const setTab = (tab: string) =>
    setSearch(p => {
      p.set('tab', tab);
      return p;
    });

  const {
    data,
    isLoading: isLoadingParticipants,
    refetch,
  } = useQuery({
    queryKey: ['assessment', assessment.name, 'participants'],
    suspense: true,
    queryFn: async () =>
      sittingsClient.listAssessmentSittingParticipants({
        schoolName: `schools/${await getSchoolID()}`,
        assessmentName: assessment.name,
      }).response,
  });

  const { data: students = [], isLoading: isLoadingStudents } = useStudents({
    suspense: true,
    select: data =>
      data.students.sort(
        (a, b) =>
          a.givenName.localeCompare(b.givenName) || a.familyName.localeCompare(b.familyName),
      ),
  });

  const { data: sittings, isLoading: isLoadingSittings } = useAssessmentSittings(assessment.name, {
    suspense: true,
    select: data => {
      const lookup: SittingLookup = {};
      for (const sitting of data.sittings) {
        lookup[sitting.sitting?.sittingName || ''] = sitting;
      }
      return lookup;
    },
  });

  const studentLookup = useMemo(() => {
    const lookup: Record<string, Student> = {};
    for (const student of students) {
      lookup[student.studentId] = student;
    }
    return lookup;
  }, [students]);

  const participants = useParticipantMatch(data, studentLookup);
  const guestParticipants = useMemo(
    () => participants.filter(p => p.enteredDetails) as GuestParticipantMatch[],
    [participants],
  );

  const studentsToMatch = guestParticipants.filter(g => !g.matchedDetails).length;
  const toExport = participants.filter(
    p => p.matchedDetails && p.participant.exportedStudentId !== p.resolvedStudentId,
  ) as ResolveParticipantMatch[];
  const exported = participants.filter(
    p => p.matchedDetails && p.participant.exportedStudentId === p.resolvedStudentId,
  ) as ResolveParticipantMatch[];

  // While these two queries are suspense - we don't want to load the MatchingTable
  // component until they're ready as the initial link state will be set to the suggested links
  if (isLoadingParticipants || isLoadingStudents || isLoadingSittings) {
    return null;
  }

  return (
    <Tabs value={tab} onValueChange={setTab}>
      <div className={styles.Tabs}>
        <Tabs.List>
          <Tabs.Trigger value="match">Match guest students ({studentsToMatch})</Tabs.Trigger>
          <Tabs.Trigger value="resolve">
            Resolve &amp; export results ({toExport.length})
          </Tabs.Trigger>
          <Tabs.Trigger value="exported">Exported results ({exported.length})</Tabs.Trigger>
        </Tabs.List>
      </div>
      <Tabs.Content value="match">
        <MatchGuestTable
          sittings={sittings}
          assessment={assessment}
          participants={guestParticipants}
          students={students}
          studentLookup={studentLookup}
          onSave={() => refetch()}
        />
      </Tabs.Content>
      <Tabs.Content value="resolve">
        <Suspense fallback={<LargeLoading />}>
          <ExportResultsTable
            sittings={sittings}
            assessment={assessment}
            participants={toExport}
            exportedParticipants={exported}
            onSave={() => refetch()}
          />
        </Suspense>
      </Tabs.Content>
      <Tabs.Content value="exported">
        <ExportedResultsTable sittings={sittings} participants={exported} />
      </Tabs.Content>
    </Tabs>
  );
};

import { UserInteractionEventFields } from '@sparx/analytics';

import { SortDirection } from '../../../../utils/sorting';

const category = 'Resources & Assessments';

export const tableSortedEvent = (name: string, newDirection: SortDirection) => ({
  action: 'Clicked report column header to sort',
  category,
  labels: {
    order: newDirection === SortDirection.Ascending ? 'ascending' : 'descending',
    sortedBy: name,
  },
});

export const clickedDownloadMarksButton = (
  assessmentID: string,
  studentGroupID: string,
  selectedStudentID?: string,
): UserInteractionEventFields => ({
  action: 'Clicked on Download Marks button',
  category,
  labels: {
    assessmentID,
    studentGroupID,
    selectedStudentID: selectedStudentID || 'none',
  },
});

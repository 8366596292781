import { Timestamp } from '@sparx/api/google/protobuf/timestamp';

// Record the time when Javascript loads:
const dateAtLoad = new Date();
const performanceNowAtLoad = performance.now();

/**
 * Get the current time. This uses performance.now() to ensure that the time is
 * not affected by the computer's clock changing during a session. When this
 * Javascript file loads, we record the current time, and the time elapsed with
 * performance.now(). We can then get the current time using performance.now()
 * rather than new Date(). It is important that the clock is fixed when a
 * session is established as this is when the server calculates the offset
 * between client time and server time
 * @returns {Date}
 */
export const getNow = () => {
  const millisSinceLoad = performance.now() - performanceNowAtLoad;
  return new Date(dateAtLoad.getTime() + millisSinceLoad);
};

/**
 * Convenience function which returns the result of `getNow` as a Timestamp.
 * @returns {Timestamp}
 */
export const getNowTimestamp = () => Timestamp.fromDate(getNow());

/**
 * Get information about when the app first loaded, for debugging time-related issues
 */
export const getTimeAtLoad = () => ({
  dateAtLoad,
  performanceNowAtLoad,
});

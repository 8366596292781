import classNames from 'classnames';
import { ReactElement } from 'react';

import { TriangleExclamationIcon } from '../../icons';
import styles from './WarningMessage.module.css';

/**
 * Small component that displays a warning message in an amber coloured box with a triangle icon
 * @constructor
 */
export const WarningMessage = ({
  message,
  className,
  iconPosition = 'center',
}: {
  message: string | ReactElement;
  className?: string;
  iconPosition?: 'top' | 'center';
}) => (
  <div
    className={classNames(styles.Root, className, {
      [styles.IconTop]: iconPosition === 'top',
      [styles.IconCenter]: iconPosition === 'center',
    })}
  >
    <TriangleExclamationIcon className={styles.Icon} />
    <span className={styles.Message}>{message}</span>
  </div>
);

import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { useAssessment } from '@sparx/assessments/src/api/hooks';
import { MatchTable } from 'components/matchtable/MatchTable';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer, PageContainerScroll } from 'components/pages/PageContainer';
import { useLocation, useParams } from 'react-router-dom';

export const AssessmentMatchView = () => {
  const { assessmentId } = useParams();

  const { data: assessment } = useAssessment(
    assessmentId || '',
    {
      includeAssessmentGroupAssessments: false,
      subjectName: 'subjects/-',
    },
    {
      suspense: true,
    },
  );

  if (!assessment) {
    throw new Error('Assessment not found');
  }

  return <MatchView assessment={assessment} />;
};

const MatchView = ({ assessment }: { assessment: Assessment }) => {
  const { state } = useLocation();
  const backLink = state?.from || `/teacher/${assessment.name}?tab=sittings`;

  return (
    <PageContainer filled={true}>
      <PageContainerScroll>
        <PageHeader title={`Match students - ${assessment.displayName}`} back={backLink}>
          {assessment.displayName}
          <PageHeaderSubpage>Match students</PageHeaderSubpage>
        </PageHeader>
        <MatchTable assessment={assessment} />
      </PageContainerScroll>
    </PageContainer>
  );
};

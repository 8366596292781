import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  Group,
  ListGroupsRequest,
  ListGroupsResponse,
  ListYearGroupsRequest,
  ListYearGroupsResponse,
} from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getSchoolID } from 'api/auth';
import { groupsClient } from 'api/clients';

export const useGroups = (opts?: UseQueryOptions<ListGroupsResponse, RpcError, Group[]>) =>
  useQuery({
    queryKey: ['groups'],
    queryFn: async () =>
      groupsClient.listGroups(
        ListGroupsRequest.create({
          parent: `schools/${await getSchoolID()}`,
        }),
      ).response,
    select: data => data.groups,
    ...opts,
  });

export const useYearGroups = <TData = ListYearGroupsResponse>(
  opts?: UseQueryOptions<ListYearGroupsResponse, RpcError, TData>,
) =>
  useQuery({
    queryKey: ['groups', 'yearGroups'],
    queryFn: async () =>
      groupsClient.listYearGroups(
        ListYearGroupsRequest.create({
          schoolName: `schools/${await getSchoolID()}`,
        }),
      ).response,
    ...opts,
  });

export const useYearGroupMap = (
  opts?: UseQueryOptions<ListYearGroupsResponse, RpcError, Map<string, YearGroup>>,
) =>
  useYearGroups({
    select: data => new Map(data.yearGroups.map(yg => [yg.yearGroupID, yg])),
    ...opts,
  });

import { faCircleExclamation, faQuestionCircle, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/button/Button';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import styles from './NotFound.module.css';

export const NotFound = () => (
  <div className={styles.Container}>
    <FontAwesomeIcon icon={faQuestionCircle} />
    <h2>Not found</h2>
    <p>Sorry, we couldn&apos;t find that page.</p>
    <div className={styles.Buttons}>
      <Button as={Link} to="/" variant="outline">
        Go home
      </Button>
    </div>
  </div>
);

interface UnknownErrorProps {
  reset?: () => void;
}

const defaultMessage = 'Sorry, there was an error loading this page. Please try again.';

export const UnknownError = ({
  children = defaultMessage,
  reset,
}: PropsWithChildren<UnknownErrorProps>) => (
  <div className={styles.Container}>
    <FontAwesomeIcon icon={faCircleExclamation} />
    <h2>Unknown error occurred</h2>
    <p>{children}</p>
    <div className={styles.Buttons}>
      <Button as={Link} to="/" variant="outline">
        Go home
      </Button>
      <Button onClick={reset} leftIcon={<FontAwesomeIcon icon={faRedo} />}>
        Reload
      </Button>
    </div>
  </div>
);

/*
 * NOTE WHEN CHANGING THIS FILE:
 *
 * This algorithm is also implemented in go in:
 * reader/server/pkg/handler/assessment/sas_stanine_calculation.go
 */

const mleIntercept = -4.57796354530737;
const mleAgeSlope = 0.314108756166357;
const mleSD = 1.42011965008261;

const mleInterceptForAge = (ageYears: number): number => {
  return mleIntercept + mleAgeSlope * ageYears;
};

const calculateSas = (abilityCoeff: number, ageYears: number): number => {
  const mleInterceptValue = mleInterceptForAge(ageYears);
  return 100 + (15 * (abilityCoeff - mleInterceptValue)) / mleSD;
};

interface StanineBound {
  higher: number;
  stanine: number;
}

const stanineBounds: StanineBound[] = [
  { higher: 73, stanine: 1 },
  { higher: 81, stanine: 2 },
  { higher: 88, stanine: 3 },
  { higher: 96, stanine: 4 },
  { higher: 103, stanine: 5 },
  { higher: 111, stanine: 6 },
  { higher: 118, stanine: 7 },
  { higher: 126, stanine: 8 },
  { higher: Number.MAX_SAFE_INTEGER, stanine: 9 },
];

const stanineFromSas = (sas: number): number => {
  const roundedSas = Math.round(sas);
  for (const bound of stanineBounds) {
    if (roundedSas <= bound.higher) {
      return bound.stanine;
    }
  }
  return 9;
};

interface SasStanineResult {
  sas: number;
  stanine: number;
}

export const calculateSasStanine = (abilityCoeff: number, ageYears: number): SasStanineResult => {
  const sas = calculateSas(abilityCoeff, ageYears);
  return {
    sas: Math.round(sas),
    stanine: stanineFromSas(sas),
  };
};

const maxReadingAge = 17.0;
const minReadingAge = 5.0;

const lowestLinearBioAge = 11.2893093;
const lowestLinearFittedSrtAbility = -1.0324621641576437;
const srtAbilityToLowReadingAgeScale = 16.09553536935182;
const srtAbilityToLowReadingAgeShape = 0.21959803333437028;
const srtAbilityToReadingAgeIntercept = -4.6331349397856565;
const srtAbilityToReadingAgeSlope = 0.31894535522602274;

export const readingAgeToAbility = (readingAgeYears: number): number => {
  readingAgeYears = Math.max(minReadingAge, Math.min(maxReadingAge, readingAgeYears)); // clamp

  if (readingAgeYears < lowestLinearBioAge) {
    const initialSrtAbility =
      -srtAbilityToLowReadingAgeScale * Math.exp(-srtAbilityToLowReadingAgeShape * readingAgeYears);
    const initialHighestSrtAbility =
      -srtAbilityToLowReadingAgeScale *
      Math.exp(-srtAbilityToLowReadingAgeShape * lowestLinearBioAge);
    return initialSrtAbility + lowestLinearFittedSrtAbility - initialHighestSrtAbility;
  } else {
    return srtAbilityToReadingAgeIntercept + srtAbilityToReadingAgeSlope * readingAgeYears;
  }
};

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "genproto/apis/recall/recall.proto" (package "recall", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message recall.HealthCheckRequest
 */
export interface HealthCheckRequest {}
/**
 * @generated from protobuf message recall.HealthCheckResponse
 */
export interface HealthCheckResponse {
  /**
   * @generated from protobuf field: string message = 1;
   */
  message: string;
}
/**
 * @generated from protobuf message recall.ProcessAnswersRequest
 */
export interface ProcessAnswersRequest {
  /**
   * @generated from protobuf field: repeated recall.StudentAnswer answers = 1;
   */
  answers: StudentAnswer[];
}
/**
 * @generated from protobuf message recall.ProcessAnswersResponse
 */
export interface ProcessAnswersResponse {}
/**
 * @generated from protobuf message recall.GetRecentAnswersRequest
 */
export interface GetRecentAnswersRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp since = 2;
   */
  since?: Timestamp;
  /**
   * @generated from protobuf field: int32 maxCount = 3;
   */
  maxCount: number;
}
/**
 * @generated from protobuf message recall.GetRecentAnswersResponse
 */
export interface GetRecentAnswersResponse {
  /**
   * @generated from protobuf field: repeated recall.StudentAnswer answers = 1;
   */
  answers: StudentAnswer[];
}
/**
 * @generated from protobuf message recall.StudentQuestionSet
 */
export interface StudentQuestionSet {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string familyID = 2;
   */
  familyID: string;
}
/**
 * @generated from protobuf message recall.GetFamilyStateRequest
 */
export interface GetFamilyStateRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
}
/**
 * @generated from protobuf message recall.GetFamilyStateResponse
 */
export interface GetFamilyStateResponse {
  /**
   * @generated from protobuf field: recall.FamilyState familyState = 1;
   */
  familyState?: FamilyState;
}
/**
 * @generated from protobuf message recall.SetFamilyTargetSetRequest
 */
export interface SetFamilyTargetSetRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
  /**
   * @generated from protobuf field: recall.TargetSet targetSet = 2;
   */
  targetSet?: TargetSet;
}
/**
 * @generated from protobuf message recall.SetFamilyTargetSetResponse
 */
export interface SetFamilyTargetSetResponse {}
/**
 * @generated from protobuf message recall.GetCurrentFamilyTargetSetRequest
 */
export interface GetCurrentFamilyTargetSetRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
}
/**
 * @generated from protobuf message recall.GetCurrentFamilyTargetSetResponse
 */
export interface GetCurrentFamilyTargetSetResponse {
  /**
   * @generated from protobuf field: recall.TargetSet targetSet = 1;
   */
  targetSet?: TargetSet;
}
/**
 * @generated from protobuf message recall.GetRecentFamilyTargetSetsRequest
 */
export interface GetRecentFamilyTargetSetsRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp since = 2;
   */
  since?: Timestamp;
  /**
   * @generated from protobuf field: int32 maxCount = 3;
   */
  maxCount: number;
}
/**
 * @generated from protobuf message recall.GetRecentFamilyTargetSetsResponse
 */
export interface GetRecentFamilyTargetSetsResponse {
  /**
   * @generated from protobuf field: repeated recall.TargetSet targetSets = 1;
   */
  targetSets: TargetSet[];
}
/**
 * @generated from protobuf message recall.TargetSet
 */
export interface TargetSet {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timeSet = 5;
   */
  timeSet?: Timestamp;
  /**
   * @generated from protobuf field: repeated string factIDs = 1;
   */
  factIDs: string[];
}
/**
 * @generated from protobuf message recall.SetFamilyLevelRequest
 */
export interface SetFamilyLevelRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
  /**
   * @generated from protobuf field: recall.Level level = 2;
   */
  level?: Level;
}
/**
 * @generated from protobuf message recall.SetFamilyLevelResponse
 */
export interface SetFamilyLevelResponse {}
/**
 * @generated from protobuf message recall.GetCurrentFamilyLevelRequest
 */
export interface GetCurrentFamilyLevelRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
}
/**
 * @generated from protobuf message recall.GetCurrentFamilyLevelResponse
 */
export interface GetCurrentFamilyLevelResponse {
  /**
   * @generated from protobuf field: recall.Level level = 1;
   */
  level?: Level;
}
/**
 * @generated from protobuf message recall.SetFamilyDailyTokensRequest
 */
export interface SetFamilyDailyTokensRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
  /**
   * @generated from protobuf field: map<string, int32> dailyTokensPerHomework = 2;
   */
  dailyTokensPerHomework: {
    [key: string]: number;
  };
}
/**
 * @generated from protobuf message recall.SetFamilyDailyTokensResponse
 */
export interface SetFamilyDailyTokensResponse {}
/**
 * @generated from protobuf message recall.GetRecentFamilyLevelsRequest
 */
export interface GetRecentFamilyLevelsRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp since = 2;
   */
  since?: Timestamp;
  /**
   * @generated from protobuf field: int32 maxCount = 3;
   */
  maxCount: number;
}
/**
 * @generated from protobuf message recall.GetRecentFamilyLevelsResponse
 */
export interface GetRecentFamilyLevelsResponse {
  /**
   * @generated from protobuf field: repeated recall.Level Levels = 1 [json_name = "Levels"];
   */
  levels: Level[];
}
/**
 * @generated from protobuf message recall.GetFamilyDailyTokensRequest
 */
export interface GetFamilyDailyTokensRequest {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
}
/**
 * @generated from protobuf message recall.GetFamilyDailyTokensResponse
 */
export interface GetFamilyDailyTokensResponse {
  /**
   * @generated from protobuf field: recall.DailyTokenData dailyTokens = 1;
   */
  dailyTokens?: DailyTokenData;
}
/**
 * @generated from protobuf message recall.DailyTokenData
 */
export interface DailyTokenData {
  /**
   * @generated from protobuf field: map<string, int32> dailyTokensPerHomework = 1;
   */
  dailyTokensPerHomework: {
    [key: string]: number;
  };
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timeSet = 2;
   */
  timeSet?: Timestamp;
}
/**
 * @generated from protobuf message recall.Level
 */
export interface Level {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timeSet = 5;
   */
  timeSet?: Timestamp;
  /**
   * @generated from protobuf field: int32 index = 1;
   */
  index: number;
  /**
   * @generated from protobuf field: recall.LevelModality modality = 2;
   */
  modality: LevelModality;
  /**
   * @generated from protobuf field: recall.LevelStage stage = 3;
   */
  stage: LevelStage;
  /**
   * Proportion of answers in the quiz that were correct
   *
   * @generated from protobuf field: float proportionCorrect = 4;
   */
  proportionCorrect: number;
  /**
   * Number of questions in the quiz
   *
   * @generated from protobuf field: int32 quizLength = 6;
   */
  quizLength: number;
  /**
   * Proportion of answers that timed out
   *
   * @generated from protobuf field: float proportionTooSlow = 7;
   */
  proportionTooSlow: number;
  /**
   * Proportion of answers that user used "I don't know" for
   *
   * @generated from protobuf field: float proportionDidNotKnow = 8;
   */
  proportionDidNotKnow: number;
  /**
   * How many times this user has completed "All Clear" in a row
   * If >0 then 100 Club uses a faster timer, as do a selection of rapid-fire
   * quizzes
   *
   * @generated from protobuf field: uint64 allClearCompletionStreak = 9;
   */
  allClearCompletionStreak: number;
  /**
   * A flag that is set the first time the user beats the final club
   * so that failing a Speed Challenge does not kick you out of
   * Speed Challenge state unless you are demoted
   *
   * @generated from protobuf field: bool hasCompletedAllClubsOnce = 10;
   */
  hasCompletedAllClubsOnce: boolean;
  /**
   * Set this when the student gets promoted in Speed Challenge.
   * The student cannot get promoted again until the next day
   *
   * @generated from protobuf field: google.protobuf.Timestamp speedChallengeLevelSetDate = 11;
   */
  speedChallengeLevelSetDate?: Timestamp;
}
/**
 * @generated from protobuf message recall.StudentFamilyProgress
 */
export interface StudentFamilyProgress {
  /**
   * @generated from protobuf field: recall.StudentQuestionSet studentQuestionSet = 1;
   */
  studentQuestionSet?: StudentQuestionSet;
  /**
   * @generated from protobuf field: recall.Level level = 2;
   */
  level?: Level;
  /**
   * @generated from protobuf field: recall.TargetSet targetSet = 3;
   */
  targetSet?: TargetSet;
  /**
   * A record of how many daily tokens the student has earned in each homework,
   * indexed by homeworkID
   *
   * @generated from protobuf field: map<string, int32> dailyTokensPerHomework = 4;
   */
  dailyTokensPerHomework: {
    [key: string]: number;
  };
}
/**
 * @generated from protobuf message recall.FamilyState
 */
export interface FamilyState {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string familyID = 2;
   */
  familyID: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updateTime = 3;
   */
  updateTime?: Timestamp;
  /**
   * @generated from protobuf field: map<string, recall.FactState> states = 4;
   */
  states: {
    [key: string]: FactState;
  };
}
/**
 * @generated from protobuf message recall.FactState
 */
export interface FactState {
  /**
   * @generated from protobuf field: recall.FactStateID stateID = 1;
   */
  stateID: FactStateID;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastAnsweredTime = 2;
   */
  lastAnsweredTime?: Timestamp;
  /**
   * @generated from protobuf field: uint32 stateIndex = 3;
   */
  stateIndex: number;
  /**
   * @generated from protobuf field: bytes boundedHistory = 4;
   */
  boundedHistory: Uint8Array;
  /**
   * @generated from protobuf field: bytes recentHistory = 5;
   */
  recentHistory: Uint8Array;
}
/**
 * @generated from protobuf message recall.StudentAnswer
 */
export interface StudentAnswer {
  /**
   * @generated from protobuf field: string studentID = 1;
   */
  studentID: string;
  /**
   * @generated from protobuf field: string factID = 2;
   */
  factID: string;
  /**
   * @generated from protobuf field: string familyID = 3;
   */
  familyID: string;
  /**
   * @generated from protobuf field: recall.AnswerStatus status = 4;
   */
  status: AnswerStatus;
  /**
   * @generated from protobuf field: recall.AnswerContext context = 8;
   */
  context: AnswerContext;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timeAsked = 5;
   */
  timeAsked?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timeAnswered = 6;
   */
  timeAnswered?: Timestamp;
  /**
   * @generated from protobuf field: uint32 questionGap = 7;
   */
  questionGap: number;
}
/**
 * @generated from protobuf enum recall.LevelModality
 */
export enum LevelModality {
  /**
   * @generated from protobuf enum value: UNDEFINED_MODALITY = 0;
   */
  UNDEFINED_MODALITY = 0,
  /**
   * @generated from protobuf enum value: PROMOTION = 1;
   */
  PROMOTION = 1,
  /**
   * @generated from protobuf enum value: DEMOTION = 2;
   */
  DEMOTION = 2,
  /**
   * @generated from protobuf enum value: SAME = 3;
   */
  SAME = 3,
  /**
   * This modality is used when the system resets the user's data for some reason, e.g. if a new feature has been
   * enabled which requires the user's level to be changed
   *
   * @generated from protobuf enum value: SYSTEM_CORRECTION = 4;
   */
  SYSTEM_CORRECTION = 4,
  /**
   * Manual correction - used if Sparx have had to change a user's level (e.g. when requested by a teacher)
   *
   * @generated from protobuf enum value: MANUAL_CORRECTION = 5;
   */
  MANUAL_CORRECTION = 5,
}
/**
 * @generated from protobuf enum recall.LevelStage
 */
export enum LevelStage {
  /**
   * @generated from protobuf enum value: UNDEFINED_STAGE = 0;
   */
  UNDEFINED_STAGE = 0,
  /**
   * @generated from protobuf enum value: REGULAR = 1;
   */
  REGULAR = 1,
  /**
   * The 3 stages of first pass assessment
   *
   * @generated from protobuf enum value: INIT1 = 2;
   */
  INIT1 = 2,
  /**
   * @generated from protobuf enum value: INIT2 = 3;
   */
  INIT2 = 3,
  /**
   * @generated from protobuf enum value: INIT3 = 4;
   */
  INIT3 = 4,
  /**
   * If feature flag discovery-ability-validation-enabled is true, ability validation is the step prior to 100-club
   * first pass in which the user must prove they are ready for their first pass assessment by showing they can
   * answer all 10-club and 20-club questions in less than 6 seconds
   *
   * @generated from protobuf enum value: ABILITY_VALIDATION = 5;
   */
  ABILITY_VALIDATION = 5,
}
/**
 * @generated from protobuf enum recall.FactStateID
 */
export enum FactStateID {
  /**
   * @generated from protobuf enum value: NEW = 0;
   */
  NEW = 0,
  /**
   * @generated from protobuf enum value: SHORT_TERM_GAP = 1;
   */
  SHORT_TERM_GAP = 1,
  /**
   * @generated from protobuf enum value: SHORT_TERM_TIME = 2;
   */
  SHORT_TERM_TIME = 2,
  /**
   * @generated from protobuf enum value: LONG_TERM_TIME = 3;
   */
  LONG_TERM_TIME = 3,
  /**
   * @generated from protobuf enum value: TYPO_CHECK = 4;
   */
  TYPO_CHECK = 4,
  /**
   * @generated from protobuf enum value: TYPO_QUARANTINE = 5;
   */
  TYPO_QUARANTINE = 5,
}
/**
 * @generated from protobuf enum recall.AnswerStatus
 */
export enum AnswerStatus {
  /**
   * @generated from protobuf enum value: UNKNOWN_STATUS = 0;
   */
  UNKNOWN_STATUS = 0,
  /**
   * Answered correctly
   *
   * @generated from protobuf enum value: CORRECT = 1;
   */
  CORRECT = 1,
  /**
   * Answered incorrectly
   *
   * @generated from protobuf enum value: WRONG = 2;
   */
  WRONG = 2,
  /**
   * The data is not reliable
   *
   * @generated from protobuf enum value: BAD_DATA = 3;
   */
  BAD_DATA = 3,
  /**
   * The question timed out before the student entered an answer
   *
   * @generated from protobuf enum value: TIMEOUT = 4;
   */
  TIMEOUT = 4,
}
/**
 * @generated from protobuf enum recall.AnswerContext
 */
export enum AnswerContext {
  /**
   * @generated from protobuf enum value: UNKNOWN_CONTEXT = 0;
   */
  UNKNOWN_CONTEXT = 0,
  /**
   * This means we test if the student can recall the fact
   *
   * @generated from protobuf enum value: TEST = 1;
   */
  TEST = 1,
  /**
   * This means the student is helped after a wrong answer
   *
   * @generated from protobuf enum value: CORRECTION = 2;
   */
  CORRECTION = 2,
  /**
   * This means the student was being taught the fact with a special question type
   *
   * @generated from protobuf enum value: TEACHING = 3;
   */
  TEACHING = 3,
  /**
   * The student chose to answer this question as part of a game - e.g. River Crossing
   * so it is not directly-linkable to their ability to recall a times table
   *
   * @generated from protobuf enum value: GAME_INPUT = 4;
   */
  GAME_INPUT = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckRequest$Type extends MessageType<HealthCheckRequest> {
  constructor() {
    super('recall.HealthCheckRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message recall.HealthCheckRequest
 */
export const HealthCheckRequest = new HealthCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckResponse$Type extends MessageType<HealthCheckResponse> {
  constructor() {
    super('recall.HealthCheckResponse', [
      { no: 1, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.HealthCheckResponse
 */
export const HealthCheckResponse = new HealthCheckResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessAnswersRequest$Type extends MessageType<ProcessAnswersRequest> {
  constructor() {
    super('recall.ProcessAnswersRequest', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.ProcessAnswersRequest
 */
export const ProcessAnswersRequest = new ProcessAnswersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessAnswersResponse$Type extends MessageType<ProcessAnswersResponse> {
  constructor() {
    super('recall.ProcessAnswersResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message recall.ProcessAnswersResponse
 */
export const ProcessAnswersResponse = new ProcessAnswersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentAnswersRequest$Type extends MessageType<GetRecentAnswersRequest> {
  constructor() {
    super('recall.GetRecentAnswersRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
      { no: 2, name: 'since', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'maxCount', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetRecentAnswersRequest
 */
export const GetRecentAnswersRequest = new GetRecentAnswersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentAnswersResponse$Type extends MessageType<GetRecentAnswersResponse> {
  constructor() {
    super('recall.GetRecentAnswersResponse', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetRecentAnswersResponse
 */
export const GetRecentAnswersResponse = new GetRecentAnswersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentQuestionSet$Type extends MessageType<StudentQuestionSet> {
  constructor() {
    super('recall.StudentQuestionSet', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'familyID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.StudentQuestionSet
 */
export const StudentQuestionSet = new StudentQuestionSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFamilyStateRequest$Type extends MessageType<GetFamilyStateRequest> {
  constructor() {
    super('recall.GetFamilyStateRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetFamilyStateRequest
 */
export const GetFamilyStateRequest = new GetFamilyStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFamilyStateResponse$Type extends MessageType<GetFamilyStateResponse> {
  constructor() {
    super('recall.GetFamilyStateResponse', [
      { no: 1, name: 'familyState', kind: 'message', T: () => FamilyState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetFamilyStateResponse
 */
export const GetFamilyStateResponse = new GetFamilyStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetFamilyTargetSetRequest$Type extends MessageType<SetFamilyTargetSetRequest> {
  constructor() {
    super('recall.SetFamilyTargetSetRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
      { no: 2, name: 'targetSet', kind: 'message', T: () => TargetSet },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.SetFamilyTargetSetRequest
 */
export const SetFamilyTargetSetRequest = new SetFamilyTargetSetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetFamilyTargetSetResponse$Type extends MessageType<SetFamilyTargetSetResponse> {
  constructor() {
    super('recall.SetFamilyTargetSetResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message recall.SetFamilyTargetSetResponse
 */
export const SetFamilyTargetSetResponse = new SetFamilyTargetSetResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentFamilyTargetSetRequest$Type extends MessageType<GetCurrentFamilyTargetSetRequest> {
  constructor() {
    super('recall.GetCurrentFamilyTargetSetRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetCurrentFamilyTargetSetRequest
 */
export const GetCurrentFamilyTargetSetRequest =
  new GetCurrentFamilyTargetSetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentFamilyTargetSetResponse$Type extends MessageType<GetCurrentFamilyTargetSetResponse> {
  constructor() {
    super('recall.GetCurrentFamilyTargetSetResponse', [
      { no: 1, name: 'targetSet', kind: 'message', T: () => TargetSet },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetCurrentFamilyTargetSetResponse
 */
export const GetCurrentFamilyTargetSetResponse =
  new GetCurrentFamilyTargetSetResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentFamilyTargetSetsRequest$Type extends MessageType<GetRecentFamilyTargetSetsRequest> {
  constructor() {
    super('recall.GetRecentFamilyTargetSetsRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
      { no: 2, name: 'since', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'maxCount', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetRecentFamilyTargetSetsRequest
 */
export const GetRecentFamilyTargetSetsRequest =
  new GetRecentFamilyTargetSetsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentFamilyTargetSetsResponse$Type extends MessageType<GetRecentFamilyTargetSetsResponse> {
  constructor() {
    super('recall.GetRecentFamilyTargetSetsResponse', [
      {
        no: 1,
        name: 'targetSets',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TargetSet,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetRecentFamilyTargetSetsResponse
 */
export const GetRecentFamilyTargetSetsResponse =
  new GetRecentFamilyTargetSetsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TargetSet$Type extends MessageType<TargetSet> {
  constructor() {
    super('recall.TargetSet', [
      { no: 5, name: 'timeSet', kind: 'message', T: () => Timestamp },
      {
        no: 1,
        name: 'factIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.TargetSet
 */
export const TargetSet = new TargetSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetFamilyLevelRequest$Type extends MessageType<SetFamilyLevelRequest> {
  constructor() {
    super('recall.SetFamilyLevelRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
      { no: 2, name: 'level', kind: 'message', T: () => Level },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.SetFamilyLevelRequest
 */
export const SetFamilyLevelRequest = new SetFamilyLevelRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetFamilyLevelResponse$Type extends MessageType<SetFamilyLevelResponse> {
  constructor() {
    super('recall.SetFamilyLevelResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message recall.SetFamilyLevelResponse
 */
export const SetFamilyLevelResponse = new SetFamilyLevelResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentFamilyLevelRequest$Type extends MessageType<GetCurrentFamilyLevelRequest> {
  constructor() {
    super('recall.GetCurrentFamilyLevelRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetCurrentFamilyLevelRequest
 */
export const GetCurrentFamilyLevelRequest =
  new GetCurrentFamilyLevelRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentFamilyLevelResponse$Type extends MessageType<GetCurrentFamilyLevelResponse> {
  constructor() {
    super('recall.GetCurrentFamilyLevelResponse', [
      { no: 1, name: 'level', kind: 'message', T: () => Level },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetCurrentFamilyLevelResponse
 */
export const GetCurrentFamilyLevelResponse =
  new GetCurrentFamilyLevelResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetFamilyDailyTokensRequest$Type extends MessageType<SetFamilyDailyTokensRequest> {
  constructor() {
    super('recall.SetFamilyDailyTokensRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
      {
        no: 2,
        name: 'dailyTokensPerHomework',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.SetFamilyDailyTokensRequest
 */
export const SetFamilyDailyTokensRequest =
  new SetFamilyDailyTokensRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetFamilyDailyTokensResponse$Type extends MessageType<SetFamilyDailyTokensResponse> {
  constructor() {
    super('recall.SetFamilyDailyTokensResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message recall.SetFamilyDailyTokensResponse
 */
export const SetFamilyDailyTokensResponse =
  new SetFamilyDailyTokensResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentFamilyLevelsRequest$Type extends MessageType<GetRecentFamilyLevelsRequest> {
  constructor() {
    super('recall.GetRecentFamilyLevelsRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
      { no: 2, name: 'since', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'maxCount', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetRecentFamilyLevelsRequest
 */
export const GetRecentFamilyLevelsRequest =
  new GetRecentFamilyLevelsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentFamilyLevelsResponse$Type extends MessageType<GetRecentFamilyLevelsResponse> {
  constructor() {
    super('recall.GetRecentFamilyLevelsResponse', [
      {
        no: 1,
        name: 'Levels',
        kind: 'message',
        jsonName: 'Levels',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Level,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetRecentFamilyLevelsResponse
 */
export const GetRecentFamilyLevelsResponse =
  new GetRecentFamilyLevelsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFamilyDailyTokensRequest$Type extends MessageType<GetFamilyDailyTokensRequest> {
  constructor() {
    super('recall.GetFamilyDailyTokensRequest', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetFamilyDailyTokensRequest
 */
export const GetFamilyDailyTokensRequest =
  new GetFamilyDailyTokensRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFamilyDailyTokensResponse$Type extends MessageType<GetFamilyDailyTokensResponse> {
  constructor() {
    super('recall.GetFamilyDailyTokensResponse', [
      { no: 1, name: 'dailyTokens', kind: 'message', T: () => DailyTokenData },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.GetFamilyDailyTokensResponse
 */
export const GetFamilyDailyTokensResponse =
  new GetFamilyDailyTokensResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DailyTokenData$Type extends MessageType<DailyTokenData> {
  constructor() {
    super('recall.DailyTokenData', [
      {
        no: 1,
        name: 'dailyTokensPerHomework',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
      { no: 2, name: 'timeSet', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.DailyTokenData
 */
export const DailyTokenData = new DailyTokenData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Level$Type extends MessageType<Level> {
  constructor() {
    super('recall.Level', [
      { no: 5, name: 'timeSet', kind: 'message', T: () => Timestamp },
      { no: 1, name: 'index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'modality',
        kind: 'enum',
        T: () => ['recall.LevelModality', LevelModality],
      },
      {
        no: 3,
        name: 'stage',
        kind: 'enum',
        T: () => ['recall.LevelStage', LevelStage],
      },
      {
        no: 4,
        name: 'proportionCorrect',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 6, name: 'quizLength', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 7,
        name: 'proportionTooSlow',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 8,
        name: 'proportionDidNotKnow',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 9,
        name: 'allClearCompletionStreak',
        kind: 'scalar',
        T: 4 /*ScalarType.UINT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 10,
        name: 'hasCompletedAllClubsOnce',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 11,
        name: 'speedChallengeLevelSetDate',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.Level
 */
export const Level = new Level$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentFamilyProgress$Type extends MessageType<StudentFamilyProgress> {
  constructor() {
    super('recall.StudentFamilyProgress', [
      {
        no: 1,
        name: 'studentQuestionSet',
        kind: 'message',
        T: () => StudentQuestionSet,
      },
      { no: 2, name: 'level', kind: 'message', T: () => Level },
      { no: 3, name: 'targetSet', kind: 'message', T: () => TargetSet },
      {
        no: 4,
        name: 'dailyTokensPerHomework',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.StudentFamilyProgress
 */
export const StudentFamilyProgress = new StudentFamilyProgress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FamilyState$Type extends MessageType<FamilyState> {
  constructor() {
    super('recall.FamilyState', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'familyID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'updateTime', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'states',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => FactState },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.FamilyState
 */
export const FamilyState = new FamilyState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FactState$Type extends MessageType<FactState> {
  constructor() {
    super('recall.FactState', [
      {
        no: 1,
        name: 'stateID',
        kind: 'enum',
        T: () => ['recall.FactStateID', FactStateID],
      },
      { no: 2, name: 'lastAnsweredTime', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'stateIndex',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 4,
        name: 'boundedHistory',
        kind: 'scalar',
        T: 12 /*ScalarType.BYTES*/,
      },
      {
        no: 5,
        name: 'recentHistory',
        kind: 'scalar',
        T: 12 /*ScalarType.BYTES*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.FactState
 */
export const FactState = new FactState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentAnswer$Type extends MessageType<StudentAnswer> {
  constructor() {
    super('recall.StudentAnswer', [
      { no: 1, name: 'studentID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'factID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'familyID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'status',
        kind: 'enum',
        T: () => ['recall.AnswerStatus', AnswerStatus],
      },
      {
        no: 8,
        name: 'context',
        kind: 'enum',
        T: () => ['recall.AnswerContext', AnswerContext],
      },
      { no: 5, name: 'timeAsked', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'timeAnswered', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'questionGap',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message recall.StudentAnswer
 */
export const StudentAnswer = new StudentAnswer$Type();
/**
 * @generated ServiceType for protobuf service recall.RecallService
 */
export const RecallService = new ServiceType('recall.RecallService', [
  {
    name: 'LiveCheck',
    options: { 'google.api.http': { get: '/healthz/live' } },
    I: HealthCheckRequest,
    O: HealthCheckResponse,
  },
  {
    name: 'ReadyCheck',
    options: { 'google.api.http': { get: '/healthz/ready' } },
    I: HealthCheckRequest,
    O: HealthCheckResponse,
  },
  {
    name: 'ProcessAnswers',
    options: {
      'google.api.http': { post: '/v0/answers', body: '*' },
      'sparx.api.auth': {
        action: 'create',
        resource: 'recall:answer',
        domain: 'recall',
      },
    },
    I: ProcessAnswersRequest,
    O: ProcessAnswersResponse,
  },
  {
    name: 'GetRecentAnswers',
    options: {
      'google.api.http': {
        get: '/v0/student/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/answers',
      },
      'sparx.api.auth': {
        action: 'read',
        resource: 'recall:answer',
        domain: 'recall',
      },
    },
    I: GetRecentAnswersRequest,
    O: GetRecentAnswersResponse,
  },
  {
    name: 'GetFamilyState',
    options: {
      'google.api.http': {
        get: '/v0/student/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/state',
      },
      'sparx.api.auth': {
        action: 'read',
        resource: 'recall:familystate',
        domain: 'recall',
      },
    },
    I: GetFamilyStateRequest,
    O: GetFamilyStateResponse,
  },
  {
    name: 'SetFamilyTargetSet',
    options: {
      'google.api.http': {
        post: '/v0/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/targets',
        body: 'targetSet',
      },
      'sparx.api.auth': {
        action: 'create',
        resource: 'recall:familystate',
        domain: 'recall',
      },
    },
    I: SetFamilyTargetSetRequest,
    O: SetFamilyTargetSetResponse,
  },
  {
    name: 'GetCurrentFamilyTargetSet',
    options: {
      'google.api.http': {
        get: '/v0/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/targets',
      },
      'sparx.api.auth': {
        action: 'read',
        resource: 'recall:targets',
        domain: 'recall',
      },
    },
    I: GetCurrentFamilyTargetSetRequest,
    O: GetCurrentFamilyTargetSetResponse,
  },
  {
    name: 'GetRecentFamilyTargetSets',
    options: {
      'google.api.http': {
        get: '/v0/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/targets/history',
      },
      'sparx.api.auth': {
        action: 'read',
        resource: 'recall:targets',
        domain: 'recall',
      },
    },
    I: GetRecentFamilyTargetSetsRequest,
    O: GetRecentFamilyTargetSetsResponse,
  },
  {
    name: 'SetFamilyLevel',
    options: {
      'google.api.http': {
        post: '/v0/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/level',
        body: 'level',
      },
      'sparx.api.auth': {
        action: 'create',
        resource: 'recall:level',
        domain: 'recall',
      },
    },
    I: SetFamilyLevelRequest,
    O: SetFamilyLevelResponse,
  },
  {
    name: 'SetFamilyDailyTokens',
    options: {
      'google.api.http': {
        post: '/v0/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/dailyTokensPerHomework',
        body: 'dailyTokensPerHomework',
      },
      'sparx.api.auth': {
        action: 'create',
        resource: 'recall:dailytokens',
        domain: 'recall',
      },
    },
    I: SetFamilyDailyTokensRequest,
    O: SetFamilyDailyTokensResponse,
  },
  {
    name: 'GetCurrentFamilyLevel',
    options: {
      'google.api.http': {
        get: '/v0/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/level',
      },
      'sparx.api.auth': {
        action: 'read',
        resource: 'recall:level',
        domain: 'recall',
      },
    },
    I: GetCurrentFamilyLevelRequest,
    O: GetCurrentFamilyLevelResponse,
  },
  {
    name: 'GetRecentFamilyLevels',
    options: {
      'google.api.http': {
        get: '/v0/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/level/history',
      },
      'sparx.api.auth': {
        action: 'read',
        resource: 'recall:level',
        domain: 'recall',
      },
    },
    I: GetRecentFamilyLevelsRequest,
    O: GetRecentFamilyLevelsResponse,
  },
  {
    name: 'GetFamilyDailyTokens',
    options: {
      'google.api.http': {
        get: '/v0/{studentQuestionSet.studentID}/family/{studentQuestionSet.familyID}/dailyTokensPerHomework',
      },
      'sparx.api.auth': {
        action: 'read',
        resource: 'recall:dailytokens',
        domain: 'recall',
      },
    },
    I: GetFamilyDailyTokensRequest,
    O: GetFamilyDailyTokensResponse,
  },
]);

import classNames from 'classnames';
import { forwardRef, MouseEventHandler, ReactNode, useMemo } from 'react';

import { ComponentWithAs } from '../../ComponentWithAs';
import { LoadingSpinner } from '../../icons/LoadingSpinner';
import accessibilityStyles from '../../shared-styles/Accessibility.module.css';
import { Breakpoint, HiddenAt } from '../hidden-at/HiddenAt';
import styles from './Button.module.css';

export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonColour = 'blue' | 'darkblue' | 'custom';
export type ButtonVariant = 'contained' | 'outlined' | 'plain' | 'text';

export interface ButtonProps {
  onClick?: MouseEventHandler<HTMLElement>;
  onDisabledClick?: MouseEventHandler<HTMLElement>;

  size?: ButtonSize;
  colour?: ButtonColour;
  variant?: ButtonVariant;

  leftIcon?: ReactNode;
  hideLeftIconAt?: Breakpoint;
  rightIcon?: ReactNode;
  hideRightIconAt?: Breakpoint;
  isLoading?: boolean;
  isDisabled?: boolean;

  // TODO
  // color / colorScheme?
  // make it possible to be a link?
}

export const Button: ComponentWithAs<'button', ButtonProps> = forwardRef(
  (
    {
      as: Component = 'button',
      className,
      onClick,
      onDisabledClick,
      children,
      size = 'md',
      colour = 'blue',
      variant = 'outlined',
      leftIcon,
      hideLeftIconAt,
      rightIcon,
      hideRightIconAt,
      isLoading,
      isDisabled,
      tabIndex = 0,
      ...props
    },
    forwardedRef,
  ) => {
    const classes = useMemo(
      () =>
        classNames(
          styles.ButtonBase,
          accessibilityStyles.FocusTarget,
          buttonSizeStyle[size],
          buttonColorStyle[colour],
          buttonVariantStyle[variant],
          isLoading && styles.Loading,
          isDisabled && styles.Disabled,
          className,
        ),
      [size, colour, variant, isLoading, isDisabled, className],
    );

    const renderIcon = (icon: ReactNode, className: string, hideAt?: Breakpoint) =>
      hideAt ? (
        <HiddenAt breakpoint={hideAt} className={className}>
          {icon}
        </HiddenAt>
      ) : (
        <div className={className}>{icon}</div>
      );

    return (
      <Component
        ref={forwardedRef}
        aria-disabled={isDisabled || isLoading}
        className={classes}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (!isDisabled && !isLoading && onClick) {
            onClick(e);
          } else if (isDisabled && onDisabledClick) {
            onDisabledClick(e);
          }
        }}
        tabIndex={!isDisabled && !isLoading ? tabIndex : undefined}
        {...props}
      >
        {leftIcon && renderIcon(leftIcon, styles.LeftIcon, hideLeftIconAt)}
        <div className={styles.Content}>{children}</div>
        {rightIcon && renderIcon(rightIcon, styles.RightIcon, hideRightIconAt)}
        {isLoading && (
          <div className={styles.LoadingOverlay}>
            <LoadingSpinner />
          </div>
        )}
      </Component>
    );
  },
);

// add displayName for better debugging (/to appease linter)
Button.displayName = 'Button';

const buttonSizeStyle: Record<ButtonSize, string> = {
  sm: styles.ButtonSm,
  md: styles.ButtonMd,
  lg: styles.ButtonLg,
};

const buttonColorStyle: Record<ButtonColour, string> = {
  blue: styles.ButtonBlue,
  darkblue: styles.ButtonDarkBlue,
  custom: '', // This is a placeholer until it is safe to remove the button colour prop
};

const buttonVariantStyle: Record<ButtonVariant, string> = {
  contained: styles.ButtonContained,
  outlined: styles.ButtonOutlined,
  plain: styles.ButtonPlain,
  text: styles.ButtonText,
};

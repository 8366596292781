import { UploadedAssessmentResult } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { useAssessment } from '@sparx/assessments/src/api/hooks';
import { useSittingUploadedResults } from 'api/sittings';
import { useClassSelectionStudents } from 'components/header/BackLink';
import { useParams, useSearchParams } from 'react-router-dom';
import { getStudentGroupTypeForAssessment } from 'utils/assessments';

export const useQueryParamStudentId = (): [string | undefined, (val: string) => void] => {
  const [params, setParams] = useSearchParams();
  const selectedStudentId = params.get('s') || undefined;
  const setSelectedStudentId = (studentId: string) =>
    setParams(p => {
      p.set('s', studentId);
      return p;
    });

  return [selectedStudentId, setSelectedStudentId];
};

export const useDataEntryData = () => {
  const { assessmentId } = useParams();

  const { data: assessment } = useAssessment(
    assessmentId || '',
    {
      subjectName: 'subjects/-', // all subjects
      includeAssessmentGroupAssessments: true,
    },
    {
      suspense: true,
    },
  );
  if (!assessment) {
    throw new Error('Assessment not found');
  }

  const groupType = getStudentGroupTypeForAssessment(assessment);
  const { students, groups, selectedGroup } = useClassSelectionStudents(groupType);

  const { data: uploadedResults } = useSittingUploadedResults(
    assessment.name,
    students.map(s => `students/${s.studentId}`),
    {
      enabled: assessment && students.length > 0,
      suspense: true,
      select: data =>
        data.uploadedResults.reduce((acc, r) => {
          acc.set(r.studentName.split('/')[1], r);
          return acc;
        }, new Map<string, UploadedAssessmentResult>()),
    },
  );

  const [selectedStudentId, setSelectedStudentId] = useQueryParamStudentId();

  return {
    students,
    groups,
    uploadedResults,
    selectedGroup,
    assessment,
    selectedStudentId,
    setSelectedStudentId,
  };
};

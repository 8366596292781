import {
  Activity as paActivity,
  Evaluation as paEvaluation,
  SkillActivity_State,
} from '@sparx/api/apis/sparx/packageactivity/v1/activity';
import { TaskItem_Status } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import {
  Activity as scActivity,
  Evaluation as scEvaluation,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { IInput, ISteps, newInput, rehydrateStepAnswer } from '@sparx/question';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { isSecondChance } from './annotations';
import { canSubmit, findChangedGapEvalRef } from './input';

// At the moment this supports both lib/packageactivity and Science packages.
// When we move science to the new packageactivity we can remove the science imports.
type Activity = paActivity | scActivity;
type Evaluation = paEvaluation | scEvaluation;

export const useSteps = (activity: Activity) => {
  const skillActivity = activity.state?.skillActivity;
  const activeStep = skillActivity?.question?.steps?.currentStep || 0;
  const visibleStep = skillActivity?.question?.steps?.visibleStep || 0;
  const previousActivity = activity.state?.previousActivity;

  const _isSecondChance = isSecondChance(activity.annotations);

  const [inputChangedRefs, setInputChangedRefs] = useState<string[]>([]);

  const {
    stepsWithEvaluations: steps,
    initialStepInputs,
    initialStepEvals,
  } = useMemo(() => {
    const questionJSON = skillActivity?.question?.questionJson;
    const steps = JSON.parse(questionJSON || '') as ISteps;

    const stepsWithEvaluations = steps.map((step, i) => {
      const ev = skillActivity?.stepEvaluations[i]?.evaluations[0] || skillActivity?.evaluation;
      const shouldHydrate =
        i !== activeStep || skillActivity?.state !== SkillActivity_State.QUESTION_ANSWER;
      if (!step.input) step.input = newInput();
      if (ev?.submittedAnswer && shouldHydrate) {
        // Rehydrate the steps with the submitted answer if we have one
        rehydrateStepAnswer(step.input, ev.submittedAnswer);
      } else if (skillActivity && 'storedAnswer' in skillActivity && skillActivity?.storedAnswer) {
        // Rehydrate any stored answer if we have one
        rehydrateStepAnswer(step.input, skillActivity.storedAnswer?.components);
      } else if (_isSecondChance && previousActivity?.state?.skillActivity) {
        // If this is a second chance then rehydrate the question with the answers and feedback from the last attempt
        const pev =
          previousActivity.state.skillActivity.stepEvaluations[i]?.evaluations[0] ||
          previousActivity.state.skillActivity.evaluation;
        if (pev) {
          rehydrateStepAnswer(step.input, pev.submittedAnswer);
          // create a new incomplete evaluation but with the gapEvaluations.
          const e: Evaluation = {
            completed: false,
            gapEvaluations: pev.gapEvaluations,
            status: TaskItem_Status.TASK_ITEM_STATUS_UNSPECIFIED,
            submittedAnswer: {},
            marks: 0,
          };

          return { ...step, evaluation: e };
        }
      }

      return { ...step, evaluation: shouldHydrate ? ev : undefined };
    });

    return {
      stepsWithEvaluations,
      initialStepInputs: stepsWithEvaluations.map(s => s.input),
      initialStepEvals: stepsWithEvaluations.map(s => s.evaluation),
    };
  }, [skillActivity, activeStep, _isSecondChance, previousActivity]);

  // Store the values in the useState as the initial values so that there
  // isn't an update where they're undefined.
  // The useEffect will then update the values whenever they change.
  const [stepInput, setStepInput] = useState<(IInput | undefined)[]>(initialStepInputs);

  // Return the input for the current step or the initial input if it doesn't exist or an empty input otherwise.
  const getStepInput = useCallback(
    (i: number) => stepInput[i] || initialStepInputs[i] || {},
    [initialStepInputs, stepInput],
  );

  const [stepEvals, setStepEvals] = useState<(Evaluation | undefined)[]>(initialStepEvals);
  useEffect(() => {
    setStepInput(initialStepInputs);
    setStepEvals(initialStepEvals);
  }, [setStepEvals, setStepInput, initialStepInputs, initialStepEvals]);

  const input = getStepInput(activeStep);
  const setInput = useCallback(
    (input: IInput, stepIndex = activeStep) => {
      if (stepIndex === activeStep) {
        const refs = findChangedGapEvalRef(input, getStepInput(activeStep));
        setInputChangedRefs(c => [...new Set([...c, ...refs])]);

        // Clear any evaluation for current step if the input changes.
        // Unless this is a second chance in which case we will try to just clear the relevant gap evaluation
        if (_isSecondChance) {
          setStepEvals(evals =>
            evals.map((e, i) => {
              if (i !== activeStep) {
                return e;
              }
              if (refs.length > 0 && e) {
                const gapEvals = { ...e.gapEvaluations };
                for (const ref of refs) {
                  delete gapEvals[ref];
                }
                return {
                  ...e,
                  gapEvaluations: gapEvals,
                };
              }
              return undefined;
            }),
          );
        } else {
          setStepEvals(evals => evals.map((e, i) => (i === activeStep ? undefined : e)));
        }
      }
      // Set the step input for the current step
      setStepInput(stepInputs => stepInputs.map((s, i) => (i === stepIndex ? input : s)));
    },
    [_isSecondChance, activeStep, getStepInput],
  );

  const submitDisabled = useMemo(
    () => !canSubmit(input, inputChangedRefs, _isSecondChance),
    [input, inputChangedRefs, _isSecondChance],
  );

  const getStepProps = useCallback(
    (i: number) => {
      const step = steps[i];
      return {
        layout: step?.layout,
        input: getStepInput(i),
        setInput: (input: IInput) => setInput(input, i),
        gapEvaluations: stepEvals[i]?.gapEvaluations,
        shuffleSeed: _isSecondChance
          ? activity.state?.previousActivity?.name || activity.name
          : activity.name,
        annotations: activity.annotations,
      };
    },
    [
      _isSecondChance,
      activity.annotations,
      activity.name,
      activity.state?.previousActivity?.name,
      getStepInput,
      setInput,
      stepEvals,
      steps,
    ],
  );

  return {
    skillActivity,
    steps,
    getStepInput,
    input,
    setInput,
    stepEvals,
    submitDisabled,
    isSecondChance: _isSecondChance,
    activeStep,
    visibleStep,
    getStepProps,
    inputChangedRefs,
  };
};

import {
  faCheck,
  faFlagCheckered,
  faPencil,
  faRotateRight,
  faSpinner,
  faTimes,
  faUser,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SittingDeliveryState,
  SittingParticipant,
  SittingParticipantReportedState,
  SittingParticipantState,
  Student,
  WatchSittingResponse,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import {
  Package,
  StoredAnswerState,
  StudentPackage,
  TaskItem,
  TaskItem_Status,
} from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { Date as pbDate } from '@sparx/api/google/type/date';
import { getCompletionSummary } from '@sparx/packageactivity';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { invalidateSittingParticipants, useUpdateSittingParticipant } from 'api/sittings';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { IconButton } from 'components/button/IconButton';
import { EditStudentForm, GuestStudentFields } from 'components/editstudentform/EditStudentForm';
import { Tooltip } from 'components/tooltip/Tooltip';
import { differenceInMinutes, formatDistance } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { JoinRequests } from 'views/teacher/sittingview/JoinRequests';

import { ProgressTimer, useUpdatingStudentActive } from './ProgressTimer';
import styles from './StudentTable.module.css';

interface StudentTableProps {
  sitting: WatchSittingResponse;
  participants?: Map<string, { participant: SittingParticipant; student: Student | undefined }>;
  packages: StudentPackage[];
}

interface ParticipantPromptData {
  subject: string;
  givenName?: string;
  familyName?: string;
  dateOfBirth?: pbDate;
}

export const StudentTable = ({ sitting, participants, packages }: StudentTableProps) => {
  const getStudentPackages = (studentId: string) =>
    (packages
      ?.filter(p => p.studentId === studentId)
      .map(p => p.package)
      .filter(Boolean) as Package[]) || [];

  const { mutateAsync, isLoading } = useUpdateSittingParticipant(
    sitting.sitting?.sittingName || '',
  );

  const [promptOpen, setPromptOpen] = useState<'void' | 'remove' | 'edit' | undefined>();
  const [promptParticipant, setPromptParticipant] = useState<ParticipantPromptData | undefined>();
  const openPrompt = (type: 'void' | 'remove' | 'edit', participant: ParticipantPromptData) => {
    setPromptOpen(type);
    setPromptParticipant(participant);
  };

  const ended = Boolean(sitting.sitting?.state?.endTimestamp);

  return (
    <>
      <VoidPackageModal
        isOpen={promptOpen === 'void'}
        participant={promptParticipant}
        onClose={() => setPromptOpen(undefined)}
        onConfirm={() => {
          promptParticipant &&
            mutateAsync({
              participantSubject: promptParticipant.subject,
              action: {
                oneofKind: 'voidPackages',
                voidPackages: {},
              },
            }).then(() => setPromptOpen(undefined));
        }}
        isLoading={isLoading}
      />
      <RemoveStudentModal
        isOpen={promptOpen === 'remove'}
        participant={promptParticipant}
        onClose={() => setPromptOpen(undefined)}
        onConfirm={() => {
          promptParticipant &&
            mutateAsync({
              participantSubject: promptParticipant.subject,
              action: {
                oneofKind: 'remove',
                remove: {},
              },
            }).then(() => setPromptOpen(undefined));
        }}
        isLoading={isLoading}
      />
      <EditStudentModal
        isOpen={promptOpen === 'edit'}
        participant={promptParticipant}
        onClose={() => setPromptOpen(undefined)}
        onConfirm={details => {
          promptParticipant &&
            mutateAsync({
              participantSubject: promptParticipant.subject,
              action: {
                oneofKind: 'editDetails',
                editDetails: {
                  student: {
                    linkedStudentName: '', // unused
                    subject: '', // unused
                    ...details,
                  },
                },
              },
            })
              .then(() => invalidateSittingParticipants(sitting.sitting?.sittingName || ''))
              .then(() => setPromptOpen(undefined));
        }}
        isLoading={isLoading}
      />

      <table className={styles.Table}>
        <thead>
          <tr>
            <th>Student</th>
            <th />
            {!ended && <th style={{ width: 150 }}>Status</th>}
            <th style={{ width: 500 }}>Progress</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!ended && (
            <JoinRequests
              joinRequests={sitting.joinRequests}
              sittingName={sitting.sitting?.sittingName || ''}
            />
          )}
          {sitting?.participantStates.map(p => {
            const user = participants?.get(p.participantSubject);
            const studentId = p.participantSubject.split('/')[1];
            const packages = getStudentPackages(studentId);
            const promptData = {
              subject: p.participantSubject,
              familyName: user?.student?.familyName,
              givenName: user?.student?.givenName,
              dateOfBirth: user?.student?.dateOfBirth,
            };

            return (
              <StudentRow
                key={p.participantSubject}
                student={user?.student}
                participant={user?.participant}
                packages={packages}
                state={p}
                inProgress={sitting.sitting?.state?.state === SittingDeliveryState.IN_PROGRESS}
                onVoidPackage={() => openPrompt('void', promptData)}
                onRemoveParticipant={() => openPrompt('remove', promptData)}
                onEditParticipant={() => openPrompt('edit', promptData)}
                sittingName={sitting.sitting?.sittingName || ''}
                sittingStartTimestamp={sitting.sitting?.state?.startTimestamp}
                ended={ended}
                processing={ended && !sitting.sitting?.state?.processedTimestamp}
              />
            );
          })}
          {sitting.participantStates.length === 0 && <NoUsersRow />}
        </tbody>
      </table>
    </>
  );
};

const VoidPackageModal = ({
  isOpen,
  participant,
  onClose,
  onConfirm,
  isLoading,
}: {
  isOpen: boolean;
  participant?: ParticipantPromptData;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Modal.Content width="md">
      <Modal.CloseButton />
      <Modal.Title>Restart {participant?.givenName || 'Student'}</Modal.Title>
      <Modal.Body className={styles.ModalBody}>
        <p>
          Are you sure you want to reset {participant?.givenName} {participant?.familyName}'s
          progress on the assessment?
        </p>
        <p>
          <strong>The student will need to restart the assessment from the beginning.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button colour="grey" onClick={onClose} isDisabled={isLoading}>
          Cancel
        </Button>
        <Button colour="red" onClick={onConfirm} isLoading={isLoading}>
          Restart assessment
        </Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

const RemoveStudentModal = ({
  isOpen,
  participant,
  onClose,
  onConfirm,
  isLoading,
}: {
  isOpen: boolean;
  participant?: ParticipantPromptData;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Modal.Content width="md">
      <Modal.CloseButton />
      <Modal.Title>Remove {participant?.givenName || 'Student'}</Modal.Title>
      <Modal.Body className={styles.ModalBody}>
        <p>
          Are you sure you want to remove {participant?.givenName} {participant?.familyName} from
          the sitting? They will be logged out immediately.
        </p>
        <p>
          <strong>All progress on their assessment will be removed.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button colour="grey" onClick={onClose} isDisabled={isLoading}>
          Cancel
        </Button>
        <Button colour="red" onClick={onConfirm} isLoading={isLoading}>
          Remove student
        </Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

const EditStudentModal = ({
  isOpen,
  participant,
  onClose,
  onConfirm,
  isLoading,
}: {
  isOpen: boolean;
  participant?: ParticipantPromptData;
  onClose: () => void;
  onConfirm: (guestStudentFields: GuestStudentFields) => void;
  isLoading: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<GuestStudentFields>({
    disabled: isLoading,
    defaultValues: participant,
  });

  // Reset with the participant details
  useEffect(() => {
    reset(participant);
  }, [reset, participant]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content width="md">
        <form
          onSubmit={handleSubmit(guestStudent => !isLoading && isValid && onConfirm(guestStudent))}
        >
          <Modal.CloseButton />
          <Modal.Title>
            Edit {participant?.givenName} {participant?.familyName}
          </Modal.Title>
          <Modal.Body className={styles.ModalBody}>
            <p>Edit the details of this student:</p>
            <EditStudentForm errors={errors} register={register} />
          </Modal.Body>
          <Modal.Footer>
            <Button colour="grey" onClick={onClose} isDisabled={isLoading}>
              Cancel
            </Button>
            <Button colour="blue" type="submit" isDisabled={!isValid} isLoading={isLoading}>
              Save changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal>
  );
};

interface PackageStatusProps {
  pkg: Package;
  ended?: boolean;
  processing?: boolean;
  state?: SittingParticipantReportedState;
}

export const PackageStatus = ({ pkg, ...rest }: PackageStatusProps) => {
  switch (pkg.annotations['assessment/delivery']) {
    case 'adaptive':
      return <AdaptiveAssessmentStatus pkg={pkg} {...rest} />;
    case 'quiz':
      return <QuizAssessmentStatus pkg={pkg} {...rest} />;
    default:
      return <>Unknown assessment type</>;
  }
};

const showTooFastWarningActivities = 5;

const AdaptiveAssessmentStatus = ({ pkg, state }: PackageStatusProps) => {
  const currentTaskItem = state?.currentTaskItemName;
  const packageSummary = useMemo(() => getCompletionSummary(pkg.state?.completion), [pkg]);

  return (
    <>
      <div className={styles.PackageProgress}>
        {packageSummary.percentages.C.roundedPercentage}%
      </div>
      <div className={styles.AdaptiveTaskStatus}>
        {pkg.contents?.tasks.map(task => {
          const summary = getCompletionSummary(task?.state?.completion);
          const toofast =
            (task?.state?.completion?.progress['tf'] || 0) >= showTooFastWarningActivities;

          const chip = (
            <div
              key={task.name}
              className={classNames(styles.AdaptiveTaskChip, {
                [styles.AdaptiveTaskChipComplete]: summary.percentages.C.roundedPercentage >= 100,
                [styles.AdaptiveTaskChipCurrent]:
                  currentTaskItem && currentTaskItem.includes(task.name),
                [styles.AdaptiveTaskChipTooFast]: toofast,
              })}
            >
              <div className={styles.AdaptiveTaskChipTick}>
                <FontAwesomeIcon icon={toofast ? faWarning : faCheck} />
              </div>
              <div
                className={styles.AdaptiveTaskChipProgress}
                style={{ width: `${summary.percentages.C.roundedPercentage}%` }}
              />
            </div>
          );

          if (toofast) {
            return (
              <Tooltip
                key={task.name}
                content="This student has answered a few questions too quickly"
              >
                {chip}
              </Tooltip>
            );
          }
          return chip;
        })}
      </div>
    </>
  );
};

const countTaskItemProgress = (taskItems: TaskItem[] | undefined, ended?: boolean) =>
  taskItems?.reduce((taskSum, item) => {
    const add = ended
      ? item.state?.status === TaskItem_Status.CORRECT
      : item.state?.storedAnswerState === StoredAnswerState.STORED_COMPLETE;
    return taskSum + (add ? 1 : 0);
  }, 0) || 0;

const QuizAssessmentStatus = ({ pkg, state, ended, processing }: PackageStatusProps) => {
  const completedCount = useMemo(
    () =>
      pkg.contents?.tasks.reduce(
        (sum, task) => sum + countTaskItemProgress(task.contents?.taskItems, ended),
        0,
      ),
    [pkg.contents, ended],
  );

  const totalCount = useMemo(
    () =>
      pkg.contents?.tasks.reduce((sum, task) => sum + (task.contents?.taskItems.length || 0), 0),
    [pkg.contents],
  );

  return (
    <>
      <div
        className={classNames(styles.PackageProgress, {
          [styles.PackageProgressLoading]: processing,
        })}
      >
        {completedCount} / {totalCount}
      </div>
      <div className={styles.QuizTaskStatus}>
        {pkg.contents?.tasks.map(task => (
          <div key={task.name} className={styles.QuizTask}>
            {task?.contents?.taskItems.map(item => (
              <div
                key={item.name}
                className={classNames(styles.QuizTaskItem, {
                  [styles.QuizTaskItemActive]: state?.currentTaskItemName === item.name,
                  [styles.QuizTaskItemStored]:
                    item?.state?.storedAnswerState === StoredAnswerState.STORED,
                  [styles.QuizTaskItemComplete]:
                    item?.state?.storedAnswerState === StoredAnswerState.STORED_COMPLETE,
                  [styles.QuizTaskItemCorrect]: item?.state?.status === TaskItem_Status.CORRECT,
                  [styles.QuizTaskItemWrong]: item?.state?.status === TaskItem_Status.INCORRECT,
                })}
              />
            ))}
          </div>
        ))}
      </div>
      <div
        className={classNames(styles.QuizTaskStatusFinished, {
          [styles.QuizTaskStatusFinishedActive]: state?.currentActivityName.startsWith('finish'),
          [styles.QuizTaskStatusFinishedTicked]: state?.currentActivityName === 'finish/finished',
        })}
      >
        <FontAwesomeIcon icon={faFlagCheckered} />
      </div>
    </>
  );
};

interface StudentRowProps {
  student?: Student;
  participant?: SittingParticipant;
  packages: Package[];
  state: SittingParticipantState;
  inProgress?: boolean;
  onVoidPackage: () => void;
  onRemoveParticipant: () => void;
  onEditParticipant: () => void;
  sittingName: string;
  sittingStartTimestamp?: Timestamp;
  ended?: boolean;
  processing?: boolean;
}

export const StudentRow = ({
  student,
  participant,
  packages,
  state,
  inProgress,
  onVoidPackage,
  onRemoveParticipant,
  onEditParticipant,
  // sittingName,
  sittingStartTimestamp,
  ended,
  processing,
}: StudentRowProps) => {
  let activePackage = packages.find(pkg => !pkg.expiryTimestamp);
  if (ended && packages && packages.length > 0) {
    // Find the last started package instead
    activePackage = packages.sort(
      (a, b) => (a.startTimestamp?.seconds || 0) - (b.startTimestamp?.seconds || 0),
    )[packages.length - 1];
  }

  const activePackageVoided = state.voidedPackageNames.includes(activePackage?.name || '');
  const active = useUpdatingStudentActive(state);
  const isGuest = student?.subject.startsWith('assessmentsguest/');
  // const sittingId = sittingName.split('/')[3];

  const minutesFromStart = useMemo(() => {
    if (participant?.createdTimestamp && sittingStartTimestamp) {
      const joinedTime = Timestamp.toDate(participant.createdTimestamp);
      const startTime = Timestamp.toDate(sittingStartTimestamp);
      const mins = differenceInMinutes(joinedTime, startTime);
      if (mins > 0) {
        return formatDistance(joinedTime, startTime, { includeSeconds: false });
      }
    }
    return '';
  }, [participant?.createdTimestamp, sittingStartTimestamp]);

  return (
    <tr className={classNames(styles.Row, ended || active ? styles.RowActive : styles.RowInactive)}>
      <td>
        <div
          className={classNames(styles.StudentCellContent, {
            [styles.StudentCellContentClickable]: isGuest,
          })}
          onClick={isGuest ? onEditParticipant : undefined}
        >
          <span className={styles.Icon}>
            <FontAwesomeIcon
              icon={isGuest ? (student?.linkedStudentName ? faCheck : faPencil) : faUser}
              fixedWidth={true}
            />
          </span>
          {student ? (
            <>
              {student.givenName} {student.familyName}
            </>
          ) : (
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          )}
        </div>
      </td>
      <td className={styles.LateJoin}>{minutesFromStart && `Joined ${minutesFromStart} late`}</td>
      {!ended && (
        <td>
          {active ? (
            <ProgressTimer participantState={state} pkg={activePackage} />
          ) : (
            <span className={styles.Offline}>Offline</span>
          )}
        </td>
      )}
      <td>
        <div className={styles.StatusCellContent}>
          {!ended && !inProgress && active ? (
            <i className={styles.WaitingMessage}>Waiting for the assessment to start...</i>
          ) : !ended && (!activePackage || activePackageVoided) && active ? (
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          ) : activePackage ? (
            <PackageStatus
              pkg={activePackage}
              state={state?.reportedState}
              ended={ended}
              processing={processing}
            />
          ) : null}
          {packages.length > 1 && (
            <span className={styles.RestartedChip}>
              <FontAwesomeIcon icon={faRotateRight} />x{packages.length - 1}
            </span>
          )}
        </div>
      </td>
      <td className={styles.Buttons}>
        {/*<Tooltip content="View answers">*/}
        {/*  <IconButton*/}
        {/*    as={Link}*/}
        {/*    aria-label="View answers"*/}
        {/*    variant="ghost"*/}
        {/*    icon={<FontAwesomeIcon icon={faList} />}*/}
        {/*    size="sm"*/}
        {/*    to={`/teacher/sittings/${sittingId}/answers/${student?.subject.replace('/', '%2F')}`}*/}
        {/*  />*/}
        {/*</Tooltip>*/}
        {!ended && (
          <>
            <Tooltip content="Restart assessment">
              <IconButton
                aria-label="Restart"
                variant="ghost"
                icon={<FontAwesomeIcon icon={faRotateRight} />}
                size="sm"
                onClick={onVoidPackage}
              />
            </Tooltip>
            <Tooltip content="Remove from assessment">
              <IconButton
                aria-label="Remove"
                variant="ghost"
                icon={<FontAwesomeIcon icon={faTimes} />}
                size="sm"
                onClick={onRemoveParticipant}
              />
            </Tooltip>
          </>
        )}
      </td>
    </tr>
  );
};

const NoUsersRow = () => (
  <tr className={styles.NoUsers}>
    <td colSpan={5}>
      <p>No students have joined this assessment yet</p>
    </td>
  </tr>
);

import { ComponentWithAs } from '@sparx/sparx-design/ComponentWithAs';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import classNames from 'classnames';
import { forwardRef, MouseEvent, ReactNode } from 'react';

import styles from './Button.module.css';

export interface ButtonProps {
  size?: 'xs' | 'sm' | 'md';
  variant?: 'solid' | 'outline' | 'ghost' | 'link';
  colour?: 'blue' | 'red' | 'green' | 'grey' | 'white' | 'whiteAlpha' | 'orange';

  leftIcon?: ReactNode;
  rightIcon?: ReactNode;

  isLoading?: boolean;
  isDisabled?: boolean;
}

export const Button: ComponentWithAs<'button', ButtonProps> = forwardRef(
  (
    {
      as: Component = 'button',
      size = 'md',
      variant = 'solid',
      colour = 'blue',
      leftIcon,
      rightIcon,
      isLoading,
      isDisabled,
      // Component props
      className,
      children,
      tabIndex = 0,
      onClick,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <Component
        ref={forwardedRef}
        aria-disabled={isDisabled || isLoading}
        onClick={(e: MouseEvent<HTMLElement>) => {
          if (!isDisabled && !isLoading && onClick) {
            onClick(e);
          }
        }}
        className={classNames(
          styles.Button,
          styles['Color-' + colour],
          {
            // Size
            [styles.SizeSm]: size === 'sm',

            // Variants
            [styles.Solid]: variant === 'solid',
            [styles.Outline]: variant === 'outline',
            [styles.Ghost]: variant === 'ghost',
            [styles.Link]: variant === 'link',

            // Controls
            [styles.Disabled]: isDisabled,
            [styles.Loading]: isLoading,
          },
          className,
        )}
        tabIndex={!isDisabled && !isLoading ? tabIndex : undefined}
        {...props}
      >
        {isLoading && (
          <div className={styles.LoadingSpinner}>
            <LoadingSpinner />
          </div>
        )}
        {leftIcon && <span className={styles.LeftIcon}>{leftIcon}</span>}
        <span className={styles.Content}>{children}</span>
        {rightIcon && <span className={styles.RightIcon}>{rightIcon}</span>}
      </Component>
    );
  },
);

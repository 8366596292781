import {
  Assessment,
  AssessmentQuestion,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { TopicSummary as ITopicSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';

import { AssessmentAggregatedScores, AssessmentTopicsDetails } from '../types/assessments';

export const getAssessmentIDFromName = (name: string) => {
  const assessmentID = name.split('/');
  return assessmentID.length === 2 ? assessmentID[1] : 'malformed';
};

/**
 * computeAggregatedScores takes the assessment and student results and computes various aggregate scores.
 * @param assessment
 * @param studentAssessments
 */
export const computeAggregatedScores = (
  assessment: Assessment,
  studentAssessments: StudentAssessment[],
): AssessmentAggregatedScores => {
  const allScoresByQuestion = new Map<string, number[]>();
  const averageScoresByQuestion = new Map<string, number>();
  const availableScoresByQuestion = new Map<string, number>();
  let totalAvailableScore = 0;
  const studentTotals = new Map<string, number>();

  // create maps of scores by question and calculate the total available score
  for (const question of assessment.questions) {
    allScoresByQuestion.set(question.name, []);
    availableScoresByQuestion.set(question.name, question.availableMarks);
    totalAvailableScore += question.availableMarks;
  }

  // for each student add their scores to the score by question map and calculate their total score.
  for (const studentAssessment of studentAssessments) {
    let totalScore = 0;
    let hasMissingMark = false;
    for (const mark of studentAssessment.marks) {
      if (
        mark.score !== undefined &&
        mark.score <= (availableScoresByQuestion.get(mark.assessmentQuestionName) || 0)
      ) {
        allScoresByQuestion.get(mark.assessmentQuestionName)?.push(mark.score);
        totalScore += mark.score;
      } else {
        hasMissingMark = true;
      }
    }
    if (!hasMissingMark) {
      // Only store their total score if they have had marks entered for every question
      studentTotals.set(studentAssessment.studentId, totalScore);
    }
  }

  // Calculate average per question
  for (const [question, scores] of allScoresByQuestion.entries()) {
    const totalScore = scores.reduce((temp: number, score: number) => temp + score, 0);
    const numScores = scores.length;
    if (numScores > 0) {
      averageScoresByQuestion.set(question, totalScore / numScores);
    } else {
      averageScoresByQuestion.set(question, 0);
    }
  }

  return {
    averageScoresByQuestion,
    totalAvailableScore,
    studentTotals,
    availableScoresByQuestion,
  };
};

/**
 * computeStudentPercentileRanks takes a list of student assessments for an assessment and computes
 * their ranks amongst the other student assessments provided. We only consider student assessments
 * that are not marked as absent and have all questions fully marked correctly (no missing or
 * invalid marks).
 * The map returned is a map of studentID to percentile rank string, as a rounded ordinal (1st,
 * 12th, 23rd, etc.) with an "=" prefix if at least one other student has the same rank,
 * e.g. "=50th".
 */
export const computeStudentPercentileRanks = (
  assessment: Assessment,
  studentAssessments: StudentAssessment[],
): Map<string, string> => {
  const availableScoresByQuestion = new Map<string, number>();
  const studentTotals = new Map<string, number>();

  // Create maps of scores by question
  for (const question of assessment.questions) {
    availableScoresByQuestion.set(question.name, question.availableMarks);
  }

  // Cycle through student assessments to get their eligibility and total score
  for (const studentAssessment of studentAssessments) {
    // If a student was absent exclude them from percentile calculations
    if (studentAssessment.absent) {
      continue;
    }

    let totalScore = 0;
    let hasMissingMark = false;
    for (const mark of studentAssessment.marks) {
      // Check that each question is marked with a valid value. If it doesn't then they have at
      // least one missing mark and will be excluded from percentile calculations, so exit early
      if (
        mark.score !== undefined &&
        mark.score <= (availableScoresByQuestion.get(mark.assessmentQuestionName) || 0)
      ) {
        totalScore += mark.score;
      } else {
        hasMissingMark = true;
        break;
      }
    }

    // If the student is fully marked then set their total score.
    if (!hasMissingMark) {
      studentTotals.set(studentAssessment.studentId, totalScore);
    }
  }

  const markFrequency: Record<number, number> = {};
  const markTotals: number[] = [];

  // Compile a map of mark frequencies, that is how many times a student got that total mark. This
  // is used to determine whether to show an "=" prefix in their percentile.
  // We also keep a list of each total so we can calculate each student's percentile.
  for (const mark of studentTotals.values()) {
    markFrequency[mark] = markFrequency[mark] ? markFrequency[mark] + 1 : 1;
    markTotals.push(mark);
  }

  // Sort the total marks in ascending order
  markTotals.sort((a, b) => a - b);

  // Cycle through the student IDs we want to calculate percentiles for and set them
  const studentPercentileRanks = new Map<string, string>();
  for (const studentID of studentTotals.keys()) {
    const studentScore = studentTotals.get(studentID);
    const percentileString = calculateStudentPercentileString(
      markFrequency,
      markTotals,
      studentScore,
    );

    // If something's gone wrong then
    if (percentileString) {
      studentPercentileRanks.set(studentID, percentileString);
    }
  }

  return studentPercentileRanks;
};

export const calculateStudentPercentileString = (
  markFrequency: Record<number, number>,
  markTotals: number[],
  studentTotal?: number,
) => {
  // Each student total provided should be defined and found in markFrequency and markTotals. If not
  // then something's gone wrong and return undefined, in which case they won't be shown a
  // percentile rank.
  if (studentTotal === undefined || markFrequency[studentTotal] === undefined) {
    return undefined;
  }
  const markIndex = markTotals.indexOf(studentTotal);
  if (markIndex === -1) {
    return undefined;
  }

  // A student's percentile is the percentage of students they scored better than
  const percentile = Math.round((markIndex * 100) / markTotals.length);
  // Add ordinal string
  let percentileString = `${percentile}${getOrdinal(percentile)}`;
  // If more than one student scored that total then include an "=" prefix
  if (markFrequency[studentTotal] > 1) {
    percentileString = '=' + percentileString;
  }
  return percentileString;
};

/**
 * getTopicDisplayDetails takes questions from the assessment and a topic summaries map and provides
 * a list of topic details containing comma separated strings of topic names and topic codes for
 * each question. Each topic details is for each question provided so the question index can be
 * used to get its topic details from the returned list.
 * @param questions
 * @param topicSummariesMap
 */
export const getTopicDisplayDetails = (
  questions: AssessmentQuestion[],
  topicSummariesMap: Map<string, ITopicSummary>,
): AssessmentTopicsDetails =>
  questions.map(question => {
    const details = question.curriculumTopicNames.reduce<{
      displayNames: string[];
      topicCodes: string[];
    }>(
      (temp, topicName) => {
        const summary = topicSummariesMap.get(topicName);
        if (summary && summary.topic) {
          temp.displayNames.push(summary.topic.displayName);
          temp.topicCodes.push(summary.topic.code);
        } else {
          temp.displayNames.push('Unknown Topic');
        }
        return temp;
      },
      {
        displayNames: [],
        topicCodes: [],
      },
    );
    return {
      questionName: question.name,
      displayName: details.displayNames.join(', '),
      topicCodes: details.topicCodes.join(', '),
    };
  });

/**
 * roundTo1DP returns the given number rounded to one decimal place.
 * @param x
 */
export const roundTo1DP = (x: number) => Math.round(x * 10) / 10;

/**
 * Get ordinal takes a number and returns a string which can be used to form its ordinal
 * @param  {number} number
 * @returns string
 */
export const getOrdinal = (number: number): string =>
  // number % 100 gives the last 2 digits
  // if the last two digits are in the teens, return 'th'
  // if not, map the last digit to 'st','nd' or 'rd', else return 'th'
  ((number % 100 < 10 || number % 100 > 20) && ['st', 'nd', 'rd'][(number % 10) - 1]) || 'th';

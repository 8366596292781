import { createContext, createRef, RefObject, useContext } from 'react';

interface Context {
  intercomEnabled: boolean;
  isOpen: boolean;
  isOpenRef: RefObject<boolean>;

  boot: (bootProps?: object) => void;
  update: () => void;
  show: () => void;
  hide: () => void;
  showArticle: (id: number) => void;
  showSpace: (space: string) => void;
  showNewMessage: () => void;
}

const noProvider = (funcName: string) => () =>
  console.error(`Intercom: '${funcName}' called when 'IntercomProvider' is not setup.`);

const initial: Context = {
  intercomEnabled: false,
  isOpen: false,
  isOpenRef: createRef<boolean>(),
  // Update is the only method which might get called by a student with the
  // provider not initialised, so make it a no-op
  update: () => undefined,
  // Have the other functions log an error
  boot: noProvider('boot'),
  show: noProvider('show'),
  hide: noProvider('hide'),
  showArticle: noProvider('showArticle'),
  showSpace: noProvider('showSpace'),
  showNewMessage: noProvider('showNewMessage'),
};

export const context = createContext<Context>(initial);

export const useIntercom = () => {
  const ctx = useContext(context);

  if (ctx === undefined) {
    throw new Error('"useIntercom" must be used within `IntercomProvider`.');
  }

  return ctx;
};

import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Activity,
  ActivityActionResponse,
  SkillActivity_State,
} from '@sparx/api/apis/sparx/packageactivity/v1/activity';
import {
  Package,
  StoredAnswerState,
  TaskItem,
} from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { answerComponentsSame, useActivityViewNotify, useSteps } from '@sparx/packageactivity';
import { answersToAnswerComponents, buildStepAnswer, IInput, SparxQuestion } from '@sparx/question';
import { useDebounce, useSessionStorage } from '@sparx/react-utils';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { useMutation } from '@tanstack/react-query';
import { useActivity, useActivityNotify } from 'api/activities';
import { useSession } from 'api/auth';
import { activitiesClient } from 'api/clients';
import { updatePackage } from 'api/packages';
import { setCurrentActivity } from 'api/statepusher';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { LargeLoading } from 'components/largeloading/LargeLoading';
import { QuestionNavigator } from 'components/questionnavigator/QuestionNavigator';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { SPARX_QUESTION_COLOURS } from 'utils/activities';
import { getAssetUrl } from 'utils/assets';
import { useGetTaskItems } from 'utils/packages';
import { ScrollingWrapper } from 'views/student/studentsittingview/ScrollingWrapper';

import styles from './QuizPackage.module.css';
import { SittingPage } from './StudentSittingView';

export const QuizPackage = ({ pkg }: { pkg: Package }) => {
  const [reverse, setReverse] = useState(false);
  const [currentTaskItem, _setCurrentTaskItem] = useSessionStorage(`item/${pkg.name}`, '');

  const setCurrentTaskItem = (item: string) => {
    const newIndex = item === 'finish' ? 100000 : parseInt(item.split('/')[5] || '0');
    const currentIndex =
      currentTaskItem === 'finish' ? 100000 : parseInt(currentTaskItem?.split('/')[5] || '0');
    setReverse(newIndex < currentIndex);
    _setCurrentTaskItem(item);
  };

  const { answerSubmitted, total } = useAnsweredCounts(pkg);
  const header = (
    <strong className={styles.HeaderAnswerCount} onClick={() => setCurrentTaskItem('finish')}>
      {answerSubmitted}/{total} answered
    </strong>
  );

  // Ensure that the selected task item is visible
  useEffect(() => {
    const element = document.querySelector(`[data-taskitem-name="${currentTaskItem}"]`);
    element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [currentTaskItem]);

  const { selectedTaskItem, nextTaskItem, prevTaskItem } = useGetTaskItems(pkg, currentTaskItem);

  const [finished, setFinished] = useState(false);
  useEffect(() => {
    // Ensure that finished is never set if we have a task item
    if (finished && selectedTaskItem?.name) {
      setFinished(false);
    }
  }, [finished, setFinished, selectedTaskItem?.name]);

  const { data: activity } = useActivity(selectedTaskItem?.name);
  useEffect(() => {
    if (finished) {
      setCurrentActivity({
        activityName: finished ? 'finish/finished' : 'finish',
        taskItemName: '',
      });
    } else if (selectedTaskItem?.name) {
      setCurrentActivity({
        activityName: activity?.name || '',
        taskItemName: selectedTaskItem.name,
      });
    }
  }, [finished, activity?.name, selectedTaskItem?.name]);

  return (
    <SittingPage className={styles.Container} header={header}>
      {!finished && (
        <nav className={styles.QuestionList}>
          <QuestionNavigator
            pkg={pkg}
            currentTaskItem={currentTaskItem}
            setCurrentTaskItem={setCurrentTaskItem}
          />
        </nav>
      )}
      <main className={styles.QuestionContainer}>
        <ContentAnimationWrapper reverse={reverse}>
          {currentTaskItem === 'finish' ? (
            <AnimatedPage key="finish" reverse={reverse}>
              <FinishPage
                pkg={pkg}
                setTaskItem={setCurrentTaskItem}
                finished={finished}
                setFinished={setFinished}
              />
            </AnimatedPage>
          ) : selectedTaskItem ? (
            activity ? (
              <AnimatedPage key={activity?.name} reverse={reverse}>
                <ActivityDelivery
                  activity={activity}
                  onNext={() => setCurrentTaskItem(nextTaskItem?.name || 'finish')}
                  onPrevious={
                    prevTaskItem ? () => setCurrentTaskItem(prevTaskItem.name) : undefined
                  }
                />
              </AnimatedPage>
            ) : (
              <></>
            )
          ) : (
            <AnimatedPage key="intro" reverse={reverse}>
              <IntroPage onNext={() => setCurrentTaskItem(nextTaskItem?.name || 'finish')} />
            </AnimatedPage>
          )}
        </ContentAnimationWrapper>
        <DelayedLoadingSpinner active={selectedTaskItem && !activity} />
      </main>
    </SittingPage>
  );
};

const DelayedLoadingSpinner = ({ active }: { active?: boolean }) => (
  <div
    className={classNames(styles.LoadingContainer, {
      [styles.LoadingContainerActive]: active,
    })}
  >
    <LargeLoading />
  </div>
);

const IntroPage = ({ onNext }: { onNext: () => void }) => {
  const { data: session } = useSession();
  return (
    <div className={styles.IntroContainer}>
      <FontAwesomeIcon icon={faFile} className={styles.IntroContainerIcon} />
      <h2>Welcome{session ? `, ${session?.givenName}` : ''}</h2>
      <p>
        Use the navigation on the left to select a question. Your answers will be saved
        automatically.
      </p>
      <Button onClick={onNext}>Start</Button>
    </div>
  );
};

const useAnsweredCounts = (pkg: Package) =>
  useMemo(() => {
    let firstItem = '';
    let firstUnfinished = '';
    let answerSubmitted = 0;
    let total = 0;

    for (const task of pkg.contents?.tasks || []) {
      for (const taskItem of task.contents?.taskItems || []) {
        firstItem = firstItem || taskItem.name;
        total++;
        if (taskItem.state?.storedAnswerState === StoredAnswerState.STORED_COMPLETE) {
          answerSubmitted++;
        } else if (!firstUnfinished) {
          firstUnfinished = taskItem.name;
        }
      }
    }
    return { firstUnfinished, firstItem, answerSubmitted, total };
  }, [pkg]);

const FinishPage = ({
  pkg,
  setTaskItem,
  finished,
  setFinished,
}: {
  pkg: Package;
  setTaskItem: (itemName: string) => void;
  finished: boolean;
  setFinished: (finished: boolean) => void;
}) => {
  useEffect(() => {
    setCurrentActivity({
      activityName: finished ? 'finish/finished' : 'finish',
      taskItemName: '',
    });
  }, [finished]);

  const { firstUnfinished, firstItem, answerSubmitted, total } = useAnsweredCounts(pkg);

  if (finished) {
    return (
      <div className={styles.IntroContainer}>
        <h2>You have marked your assessment as finished</h2>
        <h2
          style={{
            fontWeight: 'normal',
            marginBottom: 'var(--spx-unit-6)',
            color: firstUnfinished ? 'var(--ruby-red-80)' : undefined,
          }}
        >
          You have submitted {answerSubmitted} / {total} answers
        </h2>

        <Button
          onClick={() => {
            setFinished(false);
            setTaskItem(firstUnfinished || firstItem);
          }}
          leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        >
          Back to assessment
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.IntroContainer}>
      <h2>
        You have submitted {answerSubmitted} / {total} answers
      </h2>
      {firstUnfinished && (
        <p>
          <strong>You still have unanswered questions</strong>
        </p>
      )}
      <Stack spacing={5}>
        {firstUnfinished ? (
          <Button onClick={() => setTaskItem(firstUnfinished)}>Continue</Button>
        ) : (
          <Button variant="outline" onClick={() => setTaskItem(firstItem)}>
            Review answers
          </Button>
        )}
        <span>or</span>
        <Button
          variant={firstUnfinished ? 'outline' : 'solid'}
          onClick={() => setFinished(!finished)}
        >
          Finish assessment
        </Button>
      </Stack>
    </div>
  );
};

interface ActivityDeliveryProps {
  activity: Activity;
  taskItem?: TaskItem;
  onNext?: () => void;
  onPrevious?: () => void;
}

const QUESTION_RENDERER_FONT_SIZE = 18;

const useStoreAnswerNotify = (activity: Activity, input: IInput, isComplete?: boolean) => {
  const components = useMemo(() => {
    const answer = buildStepAnswer(input || {});
    return answersToAnswerComponents(answer);
  }, [input]);

  const savedRef = useRef(components);
  const componentsDebounced = useDebounce(components, 1000);

  const lastMutation = useRef<Promise<ActivityActionResponse> | undefined>(undefined);
  const { mutate: saveAnswer, isLoading } = useMutation({
    mutationFn: async (vals: {
      activityName: string;
      newComponents?: Record<string, string>;
      unmount?: boolean;
    }) => {
      if (vals.unmount) {
        console.info(`[act/${vals.activityName.slice(-5)}] Unmounted activity`);
      }

      // Only send if we have changed the input
      const componentsToSave = vals.newComponents || components;
      const same = answerComponentsSame(savedRef.current, componentsToSave);
      if (same) return false;

      // If unmounting we should wait for the last mutation to finish
      if (vals.unmount && lastMutation.current) {
        console.info(`[act/${vals.activityName.slice(-5)}] Awaiting last mutation...`);
        await lastMutation.current;
      }

      console.info(`[act/${vals.activityName.slice(-5)}] Storing answer`);
      savedRef.current = componentsToSave;
      lastMutation.current = activitiesClient.activityAction({
        name: vals.activityName,
        action: {
          oneofKind: 'storeAnswer',
          storeAnswer: {
            components: componentsToSave,
            state: isComplete ? StoredAnswerState.STORED_COMPLETE : StoredAnswerState.STORED,
          },
        },
        token: '',
      }).response;

      return lastMutation.current;
    },
    onSuccess: data => {
      if (data) {
        data.packageUpdate && updatePackage(data.packageUpdate);
      }
    },
  });

  // Effect to update the server when the debounced components change (and it's not loading)
  useEffect(() => {
    if (!isLoading) {
      saveAnswer({ activityName: activity.name, newComponents: componentsDebounced });
    }
  }, [activity.name, saveAnswer, componentsDebounced, isLoading]);

  // Effect to update the server when the activity is unmounted.
  useEffect(
    () => () => saveAnswer({ activityName: activity.name, unmount: true }),
    [activity.name, saveAnswer],
  );

  const same = answerComponentsSame(savedRef.current, components);
  return isLoading || !same;
};

const ActivityDelivery = ({ activity, onNext, onPrevious }: ActivityDeliveryProps) => {
  const {
    skillActivity,
    input,
    submitDisabled,
    activeStep,
    steps,
    getStepProps,
    inputChangedRefs,
  } = useSteps(activity);
  const isOnAnswer = skillActivity?.state === SkillActivity_State.QUESTION_ANSWER;

  const getStep = (i: number) => (
    <SparxQuestion
      {...getStepProps(i)}
      readOnly={i !== activeStep || !isOnAnswer}
      colours={SPARX_QUESTION_COLOURS}
      font={{ fontSize: QUESTION_RENDERER_FONT_SIZE }}
      sendAnalyticEvent={
        (action, labels) => console.log(action, labels)
        // sendEvent({ category: 'question', action }, { activityName: activity.name, ...labels })
      }
      gapEvaluations={undefined} // ensure gap evaluations are hidden
      imageContainerClassName={styles.ActivityImageContainer}
      getAssetUrl={getAssetUrl}
    />
  );

  const hasAnyAnswer =
    inputChangedRefs.length > 0 ||
    Object.keys(
      (skillActivity && 'storedAnswer' in skillActivity
        ? skillActivity?.storedAnswer
        : undefined) || {},
    ).length > 0;

  const savingAnswer = useStoreAnswerNotify(activity, input, !submitDisabled);

  // Ensures we send view/unload events when the activity is mounted/unmounted
  const { mutate: viewActionMutate } = useActivityNotify();
  useActivityViewNotify(viewActionMutate, activity);

  const marks = steps[activeStep]?.marks || 1;

  return (
    <>
      <ScrollingWrapper>{getStep(activeStep)}</ScrollingWrapper>
      <div className={styles.ActivityControls}>
        <div className={styles.ActivityControlsStatus}>
          <strong className={styles.ActivityMarks}>
            [{marks} mark{marks === 1 ? '' : 's'}]
          </strong>
          <AnimatePresence mode="popLayout">
            {savingAnswer ? (
              <motion.div
                key="saving"
                className={styles.Submitting}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <LoadingSpinner />
                Saving answer...
              </motion.div>
            ) : (
              <motion.div
                key="submitting"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {!hasAnyAnswer ? (
                  <div className={styles.ActivityFlagNoAnswer}>No answer submitted</div>
                ) : submitDisabled ? (
                  <div className={styles.ActivityFlagIncomplete}>Incomplete answer</div>
                ) : (
                  <div className={styles.ActivityFlagReady}>Answer saved</div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <Button
          onClick={onPrevious}
          isDisabled={!onPrevious}
          leftIcon={<FontAwesomeIcon icon={faChevronUp} />}
        >
          Previous
        </Button>
        <Button
          onClick={onNext}
          isDisabled={!onNext}
          leftIcon={<FontAwesomeIcon icon={faChevronDown} />}
        >
          Next
        </Button>
      </div>
    </>
  );
};

const ContentAnimationWrapper = ({
  children,
  reverse,
}: PropsWithChildren<{ reverse: boolean }>) => {
  const container = useRef<HTMLDivElement | null>(null);
  const hasChildren = Boolean(children);

  return (
    <div
      className={styles.AnimationContainer}
      onScroll={() => container.current && (container.current.scrollLeft = 0)}
      ref={container}
    >
      {!hasChildren && <LargeLoading />}
      <AnimatePresence mode="wait" custom={reverse}>
        {children}
      </AnimatePresence>
    </div>
  );
};

interface AnimatedPageProps extends PropsWithChildren {
  reverse?: boolean;
  className?: string;
}

export const AnimatedPage = ({ children, reverse, className }: AnimatedPageProps) => (
  <motion.div
    className={classNames(styles.AnimatedPage, className)}
    custom={reverse}
    variants={{
      enter: (reverse: boolean) => ({
        opacity: 0,
        y: reverse ? '-200px' : '200px',
      }),
      exit: (reverse: boolean) => ({
        opacity: 0,
        y: reverse ? '200px' : '-200px',
      }),
    }}
    initial="enter"
    exit="exit"
    animate={{ opacity: 1, y: 0 }}
    transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
  >
    {children}
  </motion.div>
);

import { Assessment } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';

export const isOnlineAssessment = (assessment: Assessment) => {
  return assessment.subjectKey !== 'maths';
};

export const isPaperAssessment = (assessment: Assessment) => {
  return assessment.subjectKey === 'maths' || assessment.subjectKey === 'science';
};

export const assessmentHasRevision = (assessment: Assessment) => {
  // TODO: why are we just doing this on Baseline?
  return assessment.subjectKey === 'maths' && !assessment.displayName.includes('Baseline');
};

export const assessmentHasFollowUp = (assessment: Assessment) => {
  return assessment.subjectKey === 'maths' || assessment.subjectKey === 'science';
};

export const assessmentHasReport = (_: Assessment) => {
  return true;
};

export const assessmentHasStudentSubmissions = (assessment: Assessment) =>
  isOnlineAssessment(assessment) && assessment.subjectKey !== 'english';

export const getStudentGroupTypeForAssessment = (assessment: Assessment) =>
  ({
    maths: StudentGroupType.CLASS,
    science: StudentGroupType.CLASS_SCIENCE,
    english: StudentGroupType.CLASS_ENGLISH,
  })[assessment.subjectKey];

export const followUpName = (assessment: Assessment) =>
  assessment.subjectKey === 'science' ? 'Fix Up' : 'Follow Up';

const envURLs: Record<string, string> = {
  live: 'https://sparxmathscurriculum.com/',
  test1: 'https://test.sparxmathscurriculum.com/',
  test2: 'https://test2.sparxmathscurriculum.com/',
  dev: 'https://test2.sparxmathscurriculum.com/',
};

export const getCurriculumPortalURL = (): string =>
  envURLs[window.settings?.envName || ''] || 'https://sparxmathscurriculum.com/';

export const getAssessmentResourceURL = (assessment: Assessment) => {
  // Make the curriculum portal link to get resources
  const curriculumPortalPath = assessment.links.find(
    l => l.displayName === 'SPX:CurriculumPortalPath',
  )?.url;
  return !!curriculumPortalPath && new URL(curriculumPortalPath, getCurriculumPortalURL()).href;
};

// Import PointerEvents polyfill for safari
import 'pepjs';
import './index.css';
import '@sparx/react-utils/nodeRemoveFix';
import 'api/sentry';
import 'api/firebase';

import { App } from 'app/App';
import React from 'react';
import ReactDOM from 'react-dom/client';
// ResizeObserver (for old Safari)
import ResizeObserverPolyfill from 'resize-observer-polyfill';
window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

// A temporary change to get assessments client to build for testing...
declare global {
  interface Window {
    settings?: {
      envName?: 'dev' | 'test2' | 'test1' | 'live';
      authUrl?: string;
      assessmentsApiUrl?: string;
      teacherApiUrl?: string;
      contentSummariesApiUrl?: string;
      schoolStoreApi?: string;
      schoolStatusApi?: string;
      selectSchoolUrl?: string;
      readerApi?: string;
      slDataUrl?: string;
      interactionGatewayUrl?: string;
      sparxMathsUrl?: string;
      assetsUrl?: string;
    };
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.Fragment>
    <App />
  </React.Fragment>,
);

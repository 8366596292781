// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/staff/schoolstaff/v2/schoolstaff.proto" (package "sparx.school.staff.schoolstaff.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../../google/protobuf/field_mask';
import { Product } from '../../../../types/product';
import { TrainingProgress } from '../../../../training/progress/v1/trainingprogress';
import { StaffRoleAssignment } from '../../v2/staff';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * The SchoolStaffMember message provides a view of a
 * `sparx.school.staff.v2.StaffMember` in a specific school.
 *
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.SchoolStaffMember
 */
export interface SchoolStaffMember {
  /**
   * The staff member's resource name.
   * `staff/{staff_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Read only. The school the related fields are in context of. Must be set on
   * all requests.
   *
   * @generated from protobuf field: string school = 2;
   */
  school: string;
  /**
   * Optional. The staff member's title (e.g. Mx, Mrs, Dr)
   *
   * @generated from protobuf field: string title = 3;
   */
  title: string;
  /**
   * The staff member's given name.
   *
   * @generated from protobuf field: string given_name = 4;
   */
  givenName: string;
  /**
   * The staff member's family name.
   *
   * @generated from protobuf field: string family_name = 5;
   */
  familyName: string;
  /**
   * The staff member's display name, if set should be shown instead of
   * given/family name.
   *
   * @generated from protobuf field: string display_name = 10;
   */
  displayName: string;
  /**
   * The staff member's email address.
   *
   * @generated from protobuf field: string email_address = 6;
   */
  emailAddress: string;
  /**
   * The time the staff member record was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 7;
   */
  updateTime?: Timestamp;
  /**
   * Roles this member of staff has in this school.
   *
   * @generated from protobuf field: repeated sparx.school.staff.v2.StaffRoleAssignment roles = 8;
   */
  roles: StaffRoleAssignment[];
  /**
   * This member of staff has access to Sparx systems.
   *
   * @generated from protobuf field: bool product_access = 11;
   */
  productAccess: boolean;
  /**
   * This staff member exists in multiple schools. Read only.
   *
   * @generated from protobuf field: bool multiple_schools = 12;
   */
  multipleSchools: boolean;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.GetSchoolStaffMemberRequest
 */
export interface GetSchoolStaffMemberRequest {
  /**
   * The name of the staff member to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The name of the school to retrieve the staff member in. If the staff member
   * is not a member of this school, NOT_FOUND is returned.
   *
   * @generated from protobuf field: string school = 2;
   */
  school: string;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersRequest
 */
export interface ListSchoolStaffMembersRequest {
  /**
   * The name of the school to retrieve the members of staff for.
   *
   * @generated from protobuf field: string school = 1;
   */
  school: string;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersResponse
 */
export interface ListSchoolStaffMembersResponse {
  /**
   * The staff members
   *
   * @generated from protobuf field: repeated sparx.school.staff.schoolstaff.v2.SchoolStaffMember staff_members = 1;
   */
  staffMembers: SchoolStaffMember[];
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressRequest
 */
export interface ListSchoolStaffTrainingProgressRequest {
  /**
   * @generated from protobuf field: string school = 1;
   */
  school: string;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressResponse
 */
export interface ListSchoolStaffTrainingProgressResponse {
  /**
   * @generated from protobuf field: repeated sparx.training.progress.v1.TrainingProgress training_progresses = 1;
   */
  trainingProgresses: TrainingProgress[];
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.CreateSchoolStaffMemberRequest
 */
export interface CreateSchoolStaffMemberRequest {
  /**
   * The staff member to create. If the email address matches an existing staff
   * member that is not currently in this school, then the name field should be
   * set to add that staff member to this school.
   *
   * @generated from protobuf field: sparx.school.staff.schoolstaff.v2.SchoolStaffMember staff_member = 1;
   */
  staffMember?: SchoolStaffMember;
  /**
   * The product from which staff member creation has been requested.
   * This will determine details of the welcome email to send, for instance.
   *
   * @generated from protobuf field: sparx.types.Product product = 2;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.UpdateSchoolStaffMemberRequest
 */
export interface UpdateSchoolStaffMemberRequest {
  /**
   * The staff member to update
   *
   * @generated from protobuf field: sparx.school.staff.schoolstaff.v2.SchoolStaffMember staff_member = 1;
   */
  staffMember?: SchoolStaffMember;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
  /**
   * The product from which staff member creation has been requested.
   * This will determine details of the welcome email to send, for instance.
   *
   * @generated from protobuf field: sparx.types.Product product = 3;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.DeleteSchoolStaffMemberRequest
 */
export interface DeleteSchoolStaffMemberRequest {
  /**
   * The staff member to delete
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The school to remove the staff member from.
   *
   * @generated from protobuf field: string school = 2;
   */
  school: string;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailRequest
 */
export interface UnsuppressStaffMemberEmailRequest {
  /**
   * The staff member to unsuppress email address for
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The school the staff member resides in
   *
   * @generated from protobuf field: string school = 2;
   */
  school: string;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailResponse
 */
export interface UnsuppressStaffMemberEmailResponse {}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.SendWelcomeEmailRequest
 */
export interface SendWelcomeEmailRequest {
  /**
   * The staff member to send a welcome email for.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The school to send the welcome email for.
   *
   * @generated from protobuf field: string school = 2;
   */
  school: string;
  /**
   * The product which this welcome email has been requested for.
   *
   * @generated from protobuf field: sparx.types.Product product = 3;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.school.staff.schoolstaff.v2.SendWelcomeEmailResponse
 */
export interface SendWelcomeEmailResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class SchoolStaffMember$Type extends MessageType<SchoolStaffMember> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.SchoolStaffMember', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'update_time', kind: 'message', T: () => Timestamp },
      {
        no: 8,
        name: 'roles',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaffRoleAssignment,
      },
      {
        no: 11,
        name: 'product_access',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 12,
        name: 'multiple_schools',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.SchoolStaffMember
 */
export const SchoolStaffMember = new SchoolStaffMember$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolStaffMemberRequest$Type extends MessageType<GetSchoolStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.GetSchoolStaffMemberRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.GetSchoolStaffMemberRequest
 */
export const GetSchoolStaffMemberRequest =
  new GetSchoolStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolStaffMembersRequest$Type extends MessageType<ListSchoolStaffMembersRequest> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersRequest', [
      { no: 1, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersRequest
 */
export const ListSchoolStaffMembersRequest =
  new ListSchoolStaffMembersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolStaffMembersResponse$Type extends MessageType<ListSchoolStaffMembersResponse> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersResponse', [
      {
        no: 1,
        name: 'staff_members',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolStaffMember,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.ListSchoolStaffMembersResponse
 */
export const ListSchoolStaffMembersResponse =
  new ListSchoolStaffMembersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolStaffTrainingProgressRequest$Type extends MessageType<ListSchoolStaffTrainingProgressRequest> {
  constructor() {
    super(
      'sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressRequest',
      [{ no: 1, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressRequest
 */
export const ListSchoolStaffTrainingProgressRequest =
  new ListSchoolStaffTrainingProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolStaffTrainingProgressResponse$Type extends MessageType<ListSchoolStaffTrainingProgressResponse> {
  constructor() {
    super(
      'sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressResponse',
      [
        {
          no: 1,
          name: 'training_progresses',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => TrainingProgress,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.ListSchoolStaffTrainingProgressResponse
 */
export const ListSchoolStaffTrainingProgressResponse =
  new ListSchoolStaffTrainingProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSchoolStaffMemberRequest$Type extends MessageType<CreateSchoolStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.CreateSchoolStaffMemberRequest', [
      {
        no: 1,
        name: 'staff_member',
        kind: 'message',
        T: () => SchoolStaffMember,
      },
      {
        no: 2,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.CreateSchoolStaffMemberRequest
 */
export const CreateSchoolStaffMemberRequest =
  new CreateSchoolStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolStaffMemberRequest$Type extends MessageType<UpdateSchoolStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.UpdateSchoolStaffMemberRequest', [
      {
        no: 1,
        name: 'staff_member',
        kind: 'message',
        T: () => SchoolStaffMember,
      },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      {
        no: 3,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.UpdateSchoolStaffMemberRequest
 */
export const UpdateSchoolStaffMemberRequest =
  new UpdateSchoolStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSchoolStaffMemberRequest$Type extends MessageType<DeleteSchoolStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.DeleteSchoolStaffMemberRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.DeleteSchoolStaffMemberRequest
 */
export const DeleteSchoolStaffMemberRequest =
  new DeleteSchoolStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnsuppressStaffMemberEmailRequest$Type extends MessageType<UnsuppressStaffMemberEmailRequest> {
  constructor() {
    super(
      'sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailRequest',
      [
        { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailRequest
 */
export const UnsuppressStaffMemberEmailRequest =
  new UnsuppressStaffMemberEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnsuppressStaffMemberEmailResponse$Type extends MessageType<UnsuppressStaffMemberEmailResponse> {
  constructor() {
    super(
      'sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailResponse',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.UnsuppressStaffMemberEmailResponse
 */
export const UnsuppressStaffMemberEmailResponse =
  new UnsuppressStaffMemberEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendWelcomeEmailRequest$Type extends MessageType<SendWelcomeEmailRequest> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.SendWelcomeEmailRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.SendWelcomeEmailRequest
 */
export const SendWelcomeEmailRequest = new SendWelcomeEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendWelcomeEmailResponse$Type extends MessageType<SendWelcomeEmailResponse> {
  constructor() {
    super('sparx.school.staff.schoolstaff.v2.SendWelcomeEmailResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.schoolstaff.v2.SendWelcomeEmailResponse
 */
export const SendWelcomeEmailResponse = new SendWelcomeEmailResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.staff.schoolstaff.v2.SchoolStaffService
 */
export const SchoolStaffService = new ServiceType(
  'sparx.school.staff.schoolstaff.v2.SchoolStaffService',
  [
    {
      name: 'GetSchoolStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:staff',
          domain: '{school}',
        },
      },
      I: GetSchoolStaffMemberRequest,
      O: SchoolStaffMember,
    },
    {
      name: 'ListSchoolStaffMembers',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:staff',
          domain: '{school}',
        },
      },
      I: ListSchoolStaffMembersRequest,
      O: ListSchoolStaffMembersResponse,
    },
    {
      name: 'ListSchoolStaffTrainingProgress',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:staff',
          domain: '{school}',
        },
      },
      I: ListSchoolStaffTrainingProgressRequest,
      O: ListSchoolStaffTrainingProgressResponse,
    },
    {
      name: 'CreateSchoolStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: '{staff_member.school}',
        },
      },
      I: CreateSchoolStaffMemberRequest,
      O: SchoolStaffMember,
    },
    {
      name: 'UpdateSchoolStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: '{staff_member.school}',
        },
      },
      I: UpdateSchoolStaffMemberRequest,
      O: SchoolStaffMember,
    },
    {
      name: 'DeleteSchoolStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: '{school}',
        },
      },
      I: DeleteSchoolStaffMemberRequest,
      O: SchoolStaffMember,
    },
    {
      name: 'UnsuppressStaffMemberEmail',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: '{school}',
        },
      },
      I: UnsuppressStaffMemberEmailRequest,
      O: UnsuppressStaffMemberEmailResponse,
    },
    {
      name: 'SendWelcomeEmail',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:staff',
          domain: '{school}',
        },
      },
      I: SendWelcomeEmailRequest,
      O: SendWelcomeEmailResponse,
    },
  ],
);

import {
  SittingParticipantReportedState,
  StudentActiveState,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { useDebounce } from '@sparx/react-utils/hooks/use-debounce';
import { useQuery } from '@tanstack/react-query';
import { sittingsClient } from 'api/clients';
import { queryClient } from 'app/queryClient';
import lifecycle, { StateChangeEvent } from 'page-lifecycle';
import { useEffect, useState } from 'react';

interface CurrentActivity {
  activityName: string;
  taskItemName: string;
}

const useCurrentActivity = () =>
  useQuery<CurrentActivity>({
    queryKey: ['currentActivity'],
    queryFn: async () => ({
      activityName: '',
      taskItemName: '',
    }),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

export const setCurrentActivity = (currentActivity: CurrentActivity) =>
  queryClient.setQueryData(['currentActivity'], currentActivity);

export const useSittingParticipantStatePusher = (sittingName: string) => {
  const { data: currentActivity } = useCurrentActivity();
  const [activeState, setActiveState] = useState<StudentActiveState>(StudentActiveState.ACTIVE);

  const state: SittingParticipantReportedState = useDebounce(
    {
      currentActivityName: currentActivity?.activityName || '',
      currentTaskItemName: currentActivity?.taskItemName || '',
      activeState,
    },
    250,
  );

  useQuery({
    queryKey: ['sittings', sittingName, 'state', state],
    queryFn: async () =>
      sittingsClient.updateSittingParticipantState({
        sittingName,
        state,
      }),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    enabled: Boolean(sittingName),
    refetchInterval: 15000,
    cacheTime: 0,
  });

  useEffect(() => {
    const handler = (e: StateChangeEvent) => {
      if (e.newState !== e.oldState) {
        setActiveState(
          {
            active: StudentActiveState.ACTIVE,
            passive: StudentActiveState.PASSIVE,
            hidden: StudentActiveState.HIDDEN,
          }[e.newState],
        );
      }
    };
    lifecycle.addEventListener('statechange', handler);
    return () => lifecycle.removeEventListener('statechange', handler);
  }, [setActiveState]);
};

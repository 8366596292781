import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  Assessment,
  ListAssessmentsRequest,
  ListAssessmentsResponse,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { UseQueryOptions } from '@tanstack/react-query';

import { useAssessmentContext } from '../context';
import { useListAssessments, useListStudentAssessments } from './assessment';

export const useAssessments = <TData = ListAssessmentsResponse>(
  req: Partial<ListAssessmentsRequest>,
  opts?: UseQueryOptions<ListAssessmentsResponse, RpcError, TData>,
) =>
  useListAssessments(
    {
      includeAssessmentGroupAssessments: false,
      subjectName: 'subjects/maths',
      ...req,
    },
    opts,
  );

export const useAssessmentsMap = (req: Partial<ListAssessmentsRequest>) =>
  useAssessments(req, {
    select: data =>
      data.assessments.reduce((obj, a) => {
        obj.set(a.name.split('/')[1], a);
        return obj;
      }, new Map<string, Assessment>()),
  });

export const useAssessment = (
  assessmentIdOrName: string,
  req?: Partial<ListAssessmentsRequest>,
  opts?: UseQueryOptions<ListAssessmentsResponse, RpcError, Assessment | undefined>,
) =>
  useAssessments(req || {}, {
    select: data =>
      data.assessments.find(
        a => a.name === assessmentIdOrName || a.name.split('/')[1] === assessmentIdOrName,
      ),
    ...opts,
  });

export const useStudentAssessments = (assessmentName: string, studentIds: string[] | undefined) => {
  const { schoolId } = useAssessmentContext();
  return useListStudentAssessments(
    {
      schoolName: `schools/${schoolId}`,
      studentIds: studentIds || [],
      assessmentName,
    },
    {
      enabled: !!assessmentName && !!studentIds?.length,
      select: data =>
        data.studentAssessments.reduce((obj, s) => {
          obj.set(s.studentId, s);
          return obj;
        }, new Map<string, StudentAssessment>()),
    },
  );
};

import {
  Assessment,
  AssessmentQuestion,
  QuestionType,
  StudentAssessment,
} from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { TopicSummary as ITopicSummary } from '@sparx/api/apis/sparx/content/summaries/v1/curriculum';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Tooltip } from '@sparx/sparx-design/components';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { TriangleExclamationIcon } from '@sparx/sparx-design/icons';
import { TextWithMaths } from '@sparx/text-with-maths';
import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { useAssessmentContext } from '../../../../context';
import { roundTo1DP } from '../../../../utils/assessments';
import { SortDirection } from '../../../../utils/sorting';
import { getSortingLabel } from '../../../Common/SortingLabel';
import { getColourStyle } from '../../styleUtils';
import { tableSortedEvent } from './analytics';
import styles from './Reports.module.css';
import { SortableColumnName, sortFunctions, TableSorting } from './tableSortingUtils';
import {
  getScoresForReport,
  getTopicDetailsForQuestions,
  groupUnitsWithQuestions,
  parseQuestionType,
} from './utils';

/**
 * Reports shows a report for the assessment including aggregated scores for each unit
 * and individual question.
 */

interface IReportsProps {
  // The assessment to show data for
  assessment: Assessment;
  // List of studentAssessments related to the assessment and studentGroup
  studentAssessments: StudentAssessment[];
  // Record of topicSummaryNames to TopicSummaries
  topicSummariesMap: Map<string, ITopicSummary>;
  // Map of studentIDs to their percentile rankings amongst their yearGroup for the assessment
  studentPercentileRanks: Map<string, string>;
  // The selected student to show report data for. If undefined then the user is viewing data for
  // the whole group.
  selectedStudent?: Student;
}

export const Reports = ({
  assessment,
  studentAssessments,
  topicSummariesMap,
  studentPercentileRanks,
  selectedStudent,
}: IReportsProps) => {
  const { sendEvent: analytics } = useAssessmentContext();
  const [sortedBy, updateSortedBy] = useState<TableSorting>({
    dir: SortDirection.Ascending,
    sortBy: SortableColumnName.QuestionNumber,
    sortFn: sortFunctions[SortableColumnName.QuestionNumber],
  });
  const selectedStudentAssessments = selectedStudent
    ? studentAssessments.filter(s => s.studentId === selectedStudent.studentId)
    : studentAssessments;
  const { averageScoresByQuestion, totalAvailableScore } = getScoresForReport(
    assessment,
    selectedStudentAssessments,
    selectedStudent,
  );
  let totalScore = 0;
  averageScoresByQuestion.forEach(a => (totalScore += a));
  const sortedAssessmentQuestions = useMemo(
    () => assessment.questions.sort(sortedBy.sortFn(averageScoresByQuestion)(sortedBy.dir)),
    [assessment.questions, averageScoresByQuestion, sortedBy],
  );

  // Memoize these values as they will not change
  const units = useMemo(
    () => groupUnitsWithQuestions(assessment.questions),
    [assessment.questions],
  );

  const topicDisplayNames = useMemo(
    () => getTopicDetailsForQuestions(assessment.questions, topicSummariesMap),
    [assessment.questions, topicSummariesMap],
  );

  // Get the total score & available marks for a given question type
  const getQuestionTypeTotals = (type: QuestionType): { score: number; total: number } => {
    const questionTypeTotals = { score: 0, total: 0 };
    units.forEach(ta => {
      const questions = ta.get(type);
      if (questions) {
        questions.forEach(q => {
          const scores = averageScoresByQuestion.get(q.name) || 0;
          questionTypeTotals.total += q.availableMarks;
          questionTypeTotals.score += scores;
        });
      }
    });
    return questionTypeTotals;
  };

  // Get the total score and available marks for group of questions
  const getTotalScoresForQuestions = (questions?: AssessmentQuestion[]) =>
    questions?.reduce(
      (obj, q) => {
        if (obj.total === undefined) {
          obj.total = 0;
          obj.score = 0;
        }
        const score = averageScoresByQuestion.get(q.name) || 0;
        obj.total += q.availableMarks;
        obj.score += score;
        return obj;
      },
      {} as { score: number; total: number },
    );

  const fluencyTotals = getQuestionTypeTotals(QuestionType.FLUENCY);
  const problemSolvingTotals = getQuestionTypeTotals(QuestionType.PROBLEM_SOLVING);

  const showAlternativeScore = (
    question: AssessmentQuestion,
  ): { showError?: boolean; showUnattempted?: boolean } => {
    if (!selectedStudent || !selectedStudentAssessments.length) {
      return {};
    }
    const foundQuestion = selectedStudentAssessments[0].marks.find(
      m => m.assessmentQuestionName === question.name,
    );
    if (!foundQuestion) {
      return {};
    }
    const score = foundQuestion.score || 0;
    return {
      showError: score > question.availableMarks,
      showUnattempted: !foundQuestion.attempted,
    };
  };

  const sortClickHandler = (name: SortableColumnName) => {
    const newDirection = sortedBy.sortBy === name ? -sortedBy.dir : SortDirection.Ascending;
    analytics(tableSortedEvent(name, newDirection));
    updateSortedBy({
      dir: newDirection,
      sortBy: name,
      sortFn: sortFunctions[name],
    });
  };

  const studentPercentileRank = selectedStudent
    ? studentPercentileRanks.get(selectedStudent.studentId)
    : undefined;

  const showTopics = assessment.subjectKey !== 'science';

  return (
    <div>
      <Alert className={styles.ContextMessage}>
        <Alert.Icon />
        <Alert.Description>
          This data will include students marked as absent or with partial data completion.
        </Alert.Description>
      </Alert>

      <div className={styles.TableContainer}>
        <table className={styles.Table}>
          <thead>
            <tr className={styles.TableHeadRow}>
              <th>Unit</th>
              <th className={styles.FluencyHeader}>
                {parseQuestionType(QuestionType.FLUENCY, assessment.subjectKey)} mark
              </th>
              <th className={styles.ProblemSolvingHeader}>
                {parseQuestionType(QuestionType.PROBLEM_SOLVING, assessment.subjectKey)} mark
              </th>
            </tr>
          </thead>
          <tbody>
            {[...units.keys()]
              .sort((a, b) => a.localeCompare(b))
              .map(key => {
                const questions = units.get(key);
                if (!questions) {
                  return null;
                }

                const fluency = getTotalScoresForQuestions(questions.get(QuestionType.FLUENCY));
                const problemSolving = getTotalScoresForQuestions(
                  questions.get(QuestionType.PROBLEM_SOLVING),
                );

                return (
                  <tr className={styles.ThinTableRow} key={key}>
                    <td>{key}</td>
                    <ScoreCell total={fluency?.total} score={fluency?.score} />
                    <ScoreCell total={problemSolving?.total} score={problemSolving?.score} />
                  </tr>
                );
              })}
            <tr className={styles.ThinTableRow} style={{ backgroundColor: 'transparent' }}>
              <td className={styles.TotalsPseudoCell} align="right">
                <div className={styles.TotalsText}>
                  <p>Totals</p>
                </div>
              </td>
              <ScoreCell score={fluencyTotals.score} total={fluencyTotals.total} bold />
              <ScoreCell
                score={problemSolvingTotals.score}
                total={problemSolvingTotals.total}
                bold
              />
            </tr>
          </tbody>
        </table>
      </div>

      <div className={styles.QuestionTable}>
        <table className={styles.Table}>
          <thead>
            <tr className={styles.TableHeadRow}>
              <th>
                {getSortingLabel(
                  sortedBy,
                  sortClickHandler,
                  SortableColumnName.QuestionNumber,
                  'Qn',
                )}
              </th>
              {showTopics && <th>Topic</th>}
              <th>
                {getSortingLabel(sortedBy, sortClickHandler, SortableColumnName.Marks, 'Mark')}
              </th>
              {showTopics && <th>Sparx topic code</th>}
              <th>
                {getSortingLabel(sortedBy, sortClickHandler, SortableColumnName.Unit, 'Unit')}
              </th>
              <th>
                {getSortingLabel(
                  sortedBy,
                  sortClickHandler,
                  SortableColumnName.QuestionType,
                  'Question type',
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedAssessmentQuestions.map(q => {
              const score = averageScoresByQuestion.get(q.name);
              const { showError, showUnattempted } = showAlternativeScore(q);
              const nameAndCode = topicDisplayNames[q.name];
              if (!nameAndCode) {
                return null;
              }
              return (
                <tr className={styles.ThinTableRow} key={q.name}>
                  <td>{q.displayName}</td>
                  {showTopics && (
                    <td>
                      <TextWithMaths text={nameAndCode.displayName} />
                    </td>
                  )}
                  <ScoreCell
                    total={q.availableMarks}
                    score={showError ? undefined : score}
                    markError={showError}
                    unattempted={showUnattempted}
                  />
                  {showTopics && <td>{nameAndCode.topicCode}</td>}
                  <td>{q.unit}</td>
                  <td>{parseQuestionType(q.type, assessment.subjectKey)}</td>
                </tr>
              );
            })}
            <tr className={styles.ThinTableRow} style={{ backgroundColor: 'transparent' }}>
              <td className={styles.TotalsPseudoCell} colSpan={2} align="right">
                <div className={styles.TotalsText}>
                  <p>Totals</p>
                </div>
              </td>
              <ScoreCell total={totalAvailableScore} score={totalScore} bold />
              {selectedStudent ? (
                studentPercentileRank ? (
                  <td className={styles.PercentileRankCell}>
                    Percentile Rank: {studentPercentileRank}
                  </td>
                ) : (
                  <Tooltip
                    content="No Percentile Rank due to absence or incomplete data entry"
                    position="top"
                  >
                    <td className={styles.PercentileRankCell}>Percentile Rank: None</td>
                  </Tooltip>
                )
              ) : (
                <td className={styles.FadedCell} />
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

interface IScoreCellProps {
  score?: number;
  total?: number;
  markError?: boolean;
  bold?: boolean;
  unattempted?: boolean;
}

const ScoreCell = ({ score, total, markError, bold, unattempted }: IScoreCellProps) => {
  const getScore = () => {
    if (markError) {
      return <TriangleExclamationIcon className={styles.MarkErrorIcon} />;
    }
    if (unattempted && score === 0) {
      return 'U';
    }
    return score !== undefined ? roundTo1DP(score) : '';
  };

  const colourStyles =
    score !== undefined ? getColourStyle(roundTo1DP(score), total, { width: '40px' }) : {};

  return (
    <td
      className={classNames(styles.ScoreCell, !total && styles.NoMarksAvailable)}
      style={{ ...colourStyles }}
    >
      <Tooltip content={markError ? 'Invalid mark' : ''} position="top" disabled={!markError}>
        <div className={styles.ScoreContainer}>
          {total !== undefined && (
            <>
              <div className={styles.Score} style={{ paddingRight: markError ? 0 : '6px' }}>
                {getScore()}
              </div>
              <div className={styles.Total}>
                <span>/</span> <span style={{ fontWeight: bold ? 700 : 400 }}>{total}</span>
              </div>
            </>
          )}
        </div>
      </Tooltip>
    </td>
  );
};

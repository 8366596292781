import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ComponentProps, createContext, useContext } from 'react';

import styles from './Alert.module.css';

type AlertStatus = 'success' | 'info' | 'warning' | 'error';

const icons: Record<AlertStatus, IconDefinition> = {
  success: faCheckCircle,
  info: faInfoCircle,
  warning: faExclamationTriangle,
  error: faExclamationCircle,
};

const containerClass: Record<AlertStatus, string> = {
  success: styles.Success,
  info: styles.Info,
  warning: styles.Warning,
  error: styles.Error,
};

const AlertContext = createContext<AlertProps>({});

interface AlertProps extends ComponentProps<'div'> {
  status?: AlertStatus;
}

/**
 * Alert is a component that is used to communicate a state that affects a system,
 * feature or page.
 *
 * The Icon will be displayed based on the status prop. The default status is info.
 *
 * ### CSS Overrides
 *
 * The colours of the alerts can be overridden for each status:
 *
 * - `--colours-alert-${status}-background`: Background colour for alerts
 * - `--colours-alert-${status}-icon`: Colour for alert icon
 *
 * These values must be set else the component will be unstyled.
 *
 * @example
 * <Alert status="success">
 *   <Alert.Icon />
 *   <Alert.Description>
 *     Your changes have been saved.
 *   </Alert.Description>
 * </Alert>
 */
const Alert = ({ children, className, status = 'info', ...rest }: AlertProps) => (
  <div className={classNames(styles.Alert, containerClass[status], className)} {...rest}>
    <AlertContext.Provider value={{ status }}>{children}</AlertContext.Provider>
  </div>
);

/**
 * Icon displays the icon for the parent alert based on the alerts status.
 *
 * See the Alert component for more information and usage.
 */
const Icon = ({ customIcon }: { customIcon?: IconDefinition }) => {
  const context = useContext(AlertContext);
  return (
    <FontAwesomeIcon icon={customIcon || icons[context.status || 'info']} className={styles.Icon} />
  );
};
Alert.Icon = Icon;

/**
 * Description contains the description of the Alert.
 *
 * See the Alert component for more information and usage.
 */
const Description = ({ className, children, ...rest }: ComponentProps<'div'>) => (
  <div className={classNames(styles.Description, className)} {...rest}>
    {children}
  </div>
);
Alert.Description = Description;

export { Alert };

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/gardengame/v1/gardengame.proto" (package "sparx.games.state.gardengame.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { UInt32Value } from '../../../../../../google/protobuf/wrappers';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
//
// Picture Collector does not do any processing on the cloud, so the messages
// below are only here because they are expected by game.go

/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: repeated sparx.games.state.gardengame.v1.GridTile gridLayout = 2;
   */
  gridLayout: GridTile[];
  /**
   * @generated from protobuf field: repeated string bestiaryTiles = 3;
   */
  bestiaryTiles: string[];
  /**
   * @generated from protobuf field: uint32 quizPhasesCompleted = 4;
   */
  quizPhasesCompleted: number;
  /**
   * @generated from protobuf field: sparx.games.state.gardengame.v1.CurrencyState currency = 5;
   */
  currency?: CurrencyState;
}
/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.GridTile
 */
export interface GridTile {
  /**
   * @generated from protobuf field: sparx.games.state.gardengame.v1.GridPosition gridPos = 1;
   */
  gridPos?: GridPosition;
  /**
   * @generated from protobuf field: string type = 2;
   */
  type: string;
  /**
   * @generated from protobuf field: string modifier = 3;
   */
  modifier: string;
  /**
   * @generated from protobuf field: uint32 evolutionTurns = 4;
   */
  evolutionTurns: number;
  /**
   * @generated from protobuf field: map<string, uint32> animalQuantities = 5;
   */
  animalQuantities: {
    [key: string]: number;
  };
}
/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.GridPosition
 */
export interface GridPosition {
  /**
   * @generated from protobuf field: int32 x = 1;
   */
  x: number;
  /**
   * @generated from protobuf field: int32 y = 2;
   */
  y: number;
}
/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.CurrencyState
 */
export interface CurrencyState {
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value gold = 1;
   */
  gold?: UInt32Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value wood = 2;
   */
  wood?: UInt32Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value rock = 3;
   */
  rock?: UInt32Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value mushrooms = 4;
   */
  mushrooms?: UInt32Value;
}
/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.State
 */
export interface State {}
/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.Init
 */
export interface Init {}
//
// The only action fired by Gardens is a "cancel" action, called when
// the user presses an in-game "quit" button

/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.Action
 */
export interface Action {
  /**
   * the values that can be sent with an action
   *
   * @generated from protobuf field: sparx.games.state.gardengame.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * the names of actions that can be sent
 *
 * @generated from protobuf enum sparx.games.state.gardengame.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * @generated from protobuf enum value: quitGame = 0;
   */
  quitGame = 0,
}
/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.gardengame.v1.Params
 */
export interface Params {}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.gardengame.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'gridLayout',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GridTile,
      },
      {
        no: 3,
        name: 'bestiaryTiles',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'quizPhasesCompleted',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 5, name: 'currency', kind: 'message', T: () => CurrencyState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GridTile$Type extends MessageType<GridTile> {
  constructor() {
    super('sparx.games.state.gardengame.v1.GridTile', [
      { no: 1, name: 'gridPos', kind: 'message', T: () => GridPosition },
      { no: 2, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'modifier', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'evolutionTurns',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 5,
        name: 'animalQuantities',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.GridTile
 */
export const GridTile = new GridTile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GridPosition$Type extends MessageType<GridPosition> {
  constructor() {
    super('sparx.games.state.gardengame.v1.GridPosition', [
      { no: 1, name: 'x', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'y', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.GridPosition
 */
export const GridPosition = new GridPosition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurrencyState$Type extends MessageType<CurrencyState> {
  constructor() {
    super('sparx.games.state.gardengame.v1.CurrencyState', [
      { no: 1, name: 'gold', kind: 'message', T: () => UInt32Value },
      { no: 2, name: 'wood', kind: 'message', T: () => UInt32Value },
      { no: 3, name: 'rock', kind: 'message', T: () => UInt32Value },
      { no: 4, name: 'mushrooms', kind: 'message', T: () => UInt32Value },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.CurrencyState
 */
export const CurrencyState = new CurrencyState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.gardengame.v1.State', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.gardengame.v1.Init', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.gardengame.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.gardengame.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.gardengame.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.gardengame.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.gardengame.v1.Params
 */
export const Params = new Params$Type();

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/groupsapi/v1/groupsapi.proto" (package "sparx.teacherportal.groupsapi.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { GroupsAPI } from './groupsapi';
import type { ListYearGroupsResponse } from './groupsapi';
import type { ListYearGroupsRequest } from './groupsapi';
import type { Empty } from '../../../../../google/protobuf/empty';
import type { DeleteGroupRequest } from './groupsapi';
import type { BatchUpdateGroupsResponse } from './groupsapi';
import type { BatchUpdateGroupsRequest } from './groupsapi';
import type { ListGroupsResponse } from './groupsapi';
import type { ListGroupsRequest } from './groupsapi';
import type { UpdateGroupRequest } from './groupsapi';
import type { GetGroupRequest } from './groupsapi';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { Group } from './groupsapi';
import type { CreateGroupRequest } from './groupsapi';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.teacherportal.groupsapi.v1.GroupsAPI
 */
export interface IGroupsAPIClient {
  /**
   * @generated from protobuf rpc: CreateGroup(sparx.teacherportal.groupsapi.v1.CreateGroupRequest) returns (sparx.teacherportal.groupsapi.v1.Group);
   */
  createGroup(
    input: CreateGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateGroupRequest, Group>;
  /**
   * @generated from protobuf rpc: GetGroup(sparx.teacherportal.groupsapi.v1.GetGroupRequest) returns (sparx.teacherportal.groupsapi.v1.Group);
   */
  getGroup(
    input: GetGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<GetGroupRequest, Group>;
  /**
   * @generated from protobuf rpc: UpdateGroup(sparx.teacherportal.groupsapi.v1.UpdateGroupRequest) returns (sparx.teacherportal.groupsapi.v1.Group);
   */
  updateGroup(
    input: UpdateGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateGroupRequest, Group>;
  /**
   * @generated from protobuf rpc: ListGroups(sparx.teacherportal.groupsapi.v1.ListGroupsRequest) returns (sparx.teacherportal.groupsapi.v1.ListGroupsResponse);
   */
  listGroups(
    input: ListGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListGroupsRequest, ListGroupsResponse>;
  /**
   * @generated from protobuf rpc: BatchUpdateGroups(sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsRequest) returns (sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsResponse);
   */
  batchUpdateGroups(
    input: BatchUpdateGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<BatchUpdateGroupsRequest, BatchUpdateGroupsResponse>;
  /**
   * DeleteGroup effectively deletes a group by giving it an end date one day in
   * the past. Returns an empty response if the group is successfully deleted
   *
   * @generated from protobuf rpc: DeleteGroup(sparx.teacherportal.groupsapi.v1.DeleteGroupRequest) returns (google.protobuf.Empty);
   */
  deleteGroup(
    input: DeleteGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteGroupRequest, Empty>;
  /**
   * @generated from protobuf rpc: ListYearGroups(sparx.teacherportal.groupsapi.v1.ListYearGroupsRequest) returns (sparx.teacherportal.groupsapi.v1.ListYearGroupsResponse);
   */
  listYearGroups(
    input: ListYearGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListYearGroupsRequest, ListYearGroupsResponse>;
}
/**
 * @generated from protobuf service sparx.teacherportal.groupsapi.v1.GroupsAPI
 */
export class GroupsAPIClient implements IGroupsAPIClient, ServiceInfo {
  typeName = GroupsAPI.typeName;
  methods = GroupsAPI.methods;
  options = GroupsAPI.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: CreateGroup(sparx.teacherportal.groupsapi.v1.CreateGroupRequest) returns (sparx.teacherportal.groupsapi.v1.Group);
   */
  createGroup(
    input: CreateGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateGroupRequest, Group> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateGroupRequest, Group>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetGroup(sparx.teacherportal.groupsapi.v1.GetGroupRequest) returns (sparx.teacherportal.groupsapi.v1.Group);
   */
  getGroup(
    input: GetGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<GetGroupRequest, Group> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetGroupRequest, Group>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateGroup(sparx.teacherportal.groupsapi.v1.UpdateGroupRequest) returns (sparx.teacherportal.groupsapi.v1.Group);
   */
  updateGroup(
    input: UpdateGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateGroupRequest, Group> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateGroupRequest, Group>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListGroups(sparx.teacherportal.groupsapi.v1.ListGroupsRequest) returns (sparx.teacherportal.groupsapi.v1.ListGroupsResponse);
   */
  listGroups(
    input: ListGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListGroupsRequest, ListGroupsResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListGroupsRequest, ListGroupsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: BatchUpdateGroups(sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsRequest) returns (sparx.teacherportal.groupsapi.v1.BatchUpdateGroupsResponse);
   */
  batchUpdateGroups(
    input: BatchUpdateGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<BatchUpdateGroupsRequest, BatchUpdateGroupsResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<BatchUpdateGroupsRequest, BatchUpdateGroupsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * DeleteGroup effectively deletes a group by giving it an end date one day in
   * the past. Returns an empty response if the group is successfully deleted
   *
   * @generated from protobuf rpc: DeleteGroup(sparx.teacherportal.groupsapi.v1.DeleteGroupRequest) returns (google.protobuf.Empty);
   */
  deleteGroup(
    input: DeleteGroupRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteGroupRequest, Empty> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteGroupRequest, Empty>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListYearGroups(sparx.teacherportal.groupsapi.v1.ListYearGroupsRequest) returns (sparx.teacherportal.groupsapi.v1.ListYearGroupsResponse);
   */
  listYearGroups(
    input: ListYearGroupsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListYearGroupsRequest, ListYearGroupsResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListYearGroupsRequest, ListYearGroupsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { StudentGroupType, YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useLocalStorage, useMeasuredSize } from '@sparx/react-utils';
import { useGroups, useYearGroups } from 'api/groups';
import { useStudents } from 'api/students';
import { Button } from 'components/button/Button';
import { useSchool } from 'components/ensuresession/EnsureSession';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import styles from './Header.module.css';

type BackLink = string | { onClick: () => void };

interface NavigationControlContext {
  backLink?: BackLink;
  setBackLink: (link: BackLink | undefined) => void;
  classSelectEnabled?: boolean;
  studentGroupFilter?: StudentGroupType;
  setClassSelectEnabled: (groupType: true | StudentGroupType | undefined) => void;
  selectedGroupName?: string;
  setSelectedGroupName: (name: string) => void;
}

const NavigationControlContext = React.createContext<NavigationControlContext>({
  setBackLink: () => undefined,
  setClassSelectEnabled: () => undefined,
  setSelectedGroupName: () => undefined,
});

export const NavigationControlProvider = ({ children }: PropsWithChildren) => {
  const { schoolId } = useSchool();
  const [backLink, setBackLink] = useState<BackLink | undefined>();
  const [classSelect, setClassSelect] = useState<StudentGroupType | true | undefined>();
  const [selectedGroupName, setSelectedGroupName] = useLocalStorage(
    `assessments/group/${schoolId}`,
  );

  const [urlGroupName, setUrlGroupName] = useSetGroupInUrl();
  const setGroup = (groupName: string) => {
    setSelectedGroupName(() => groupName);
    if (urlGroupName !== groupName) {
      setUrlGroupName(groupName);
    }
  };

  return (
    <NavigationControlContext.Provider
      value={{
        backLink,
        setBackLink: val => setBackLink(() => val),
        classSelectEnabled: Boolean(classSelect),
        studentGroupFilter: classSelect === true ? undefined : classSelect,
        setClassSelectEnabled: val => setClassSelect(() => val),
        selectedGroupName: selectedGroupName || undefined,
        setSelectedGroupName: setGroup,
      }}
    >
      {children}
    </NavigationControlContext.Provider>
  );
};

export const useBackLink = (link: BackLink | undefined) => {
  const { setBackLink } = React.useContext(NavigationControlContext);
  return useEffect(() => {
    if (link) {
      setBackLink(link);
      return () => setBackLink(undefined);
    }
  }, [setBackLink, link]);
};

const useSetGroupInUrl = (): [string | undefined, (value: string) => void] => {
  const [params, setParams] = useSearchParams();
  const urlGroupName = params.get('group') || undefined;
  const set = (groupName: string) =>
    setParams(p => {
      p.set('group', groupName);
      return p;
    });
  return [urlGroupName, set];
};

export const useClassSelection = (groupType?: StudentGroupType) => {
  const { setClassSelectEnabled, selectedGroupName, setSelectedGroupName } =
    React.useContext(NavigationControlContext);

  // Set the group in the URL when the selected group changes
  const [urlGroupName, setUrlGroupName] = useSetGroupInUrl();
  useEffect(() => {
    if (urlGroupName !== selectedGroupName && selectedGroupName) {
      setUrlGroupName(selectedGroupName);
    }
  }, [urlGroupName, selectedGroupName, setSelectedGroupName, setUrlGroupName]);

  useEffect(() => {
    setClassSelectEnabled(groupType || true);
    return () => setClassSelectEnabled(undefined);
  }, [groupType, setClassSelectEnabled]);

  const { data: groups } = useGroups({ suspense: false });
  const { data: yearGroups } = useYearGroups({ suspense: false, select: data => data.yearGroups });
  const selectedGroup = useSelectedGroup(selectedGroupName, groups, yearGroups);

  return { selectedGroupName, setSelectedGroupName, selectedGroup };
};

type SelectedGroup =
  | {
      type: 'group';
      group: Group;
    }
  | {
      type: 'yeargroup';
      yearGroup: YearGroup;
    }
  | undefined;

export const selectedGroupValue = (g: SelectedGroup) =>
  !g ? undefined : g.type === 'group' ? g.group : g.yearGroup;

export const useSelectedGroup = (
  selectedGroupName: string | undefined,
  groups: Group[] | undefined,
  yearGroups: YearGroup[] | undefined,
): SelectedGroup | undefined =>
  useMemo(() => {
    const isYearGroup = selectedGroupName?.startsWith('yeargroup/');
    if (isYearGroup) {
      const yg = yearGroups?.find(g => `yeargroup/${g.yearGroupID}` === selectedGroupName);
      if (yg) {
        return { type: 'yeargroup', yearGroup: yg };
      }
    } else {
      const group = groups?.find(g => g.name === selectedGroupName);
      if (group) {
        return { type: 'group', group };
      }
    }
    return undefined;
  }, [groups, yearGroups, selectedGroupName]);

export const useClassSelectionStudents = (groupType?: StudentGroupType) => {
  const selection = useClassSelection(groupType);
  const { data: groups = [] } = useGroups({ suspense: true });
  const { data: students = [] } = useStudents({ suspense: true });

  const filteredStudents = useMemo(() => {
    switch (selection.selectedGroup?.type) {
      case 'yeargroup': {
        const yearGroupId = selection.selectedGroup.yearGroup.yearGroupID;
        const ygGroups = groups
          .filter(g => g.yearGroupId === yearGroupId)
          .map(g => g.name.split('/')[3]);
        return students.filter(s => ygGroups.some(g => s.studentGroupIds.includes(g)));
      }
      case 'group': {
        const groupId = selection.selectedGroup?.group.name.split('/')[3];
        return students.filter(s => s.studentGroupIds.includes(groupId || ''));
      }
      default:
        return [];
    }
  }, [students, groups, selection.selectedGroup]);

  return { students: filteredStudents, groups, ...selection };
};

export const useNavigationControlContext = () => React.useContext(NavigationControlContext);

export const BackLink = () => {
  const { backLink } = React.useContext(NavigationControlContext);

  const [container, size] = useMeasuredSize();
  const width = size?.width ? size.width : 50;

  return (
    <AnimatePresence mode="wait" initial={false}>
      {backLink && (
        <motion.div
          initial={{ opacity: 0, marginLeft: -width }}
          animate={{ opacity: 1, marginLeft: 0 }}
          exit={{ opacity: 0, marginLeft: -width }}
          transition={{ type: 'spring', bounce: 0 }}
          ref={container}
        >
          <Button
            className={styles.BackLink}
            aria-label="Back"
            {...(typeof backLink === 'object'
              ? backLink
              : {
                  as: Link,
                  to: backLink,
                })}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="xl" />
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

import { ComponentProps, forwardRef, useMemo } from 'react';

import { FormControlContext } from './FormControlContext';

interface FormControlProps extends ComponentProps<'div'> {
  fieldId?: string;
  isInvalid?: boolean;
}

/**
 * FormControl is a wrapper component that proves context to form elements.
 *
 * @example
 * <FormControl isInvalid={!!errors.emailAddress}>
 *   <FormLabel>Email address:</FormLabel>
 *   <Input type="email" />
 *   <FormErrorMessage>
 *     {errors.emailAddress?.message}
 *   </FormErrorMessage>
 * </FormControl>
 */
export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  function FormControl(props, ref) {
    const { isInvalid, fieldId: _fieldId, ...rest } = props;
    const fieldId = useMemo(() => _fieldId || randomId(), [_fieldId]);

    return (
      <FormControlContext.Provider value={{ isInvalid, fieldId }}>
        <div ref={ref} {...rest} />
      </FormControlContext.Provider>
    );
  },
);

/**
 * Randomly generates an ID to be used as the field id.
 */
const randomId = () =>
  'field-' +
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .slice(2, 10);

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/progress/talkandlearncontrol/v1/talkandlearncontrol.proto" (package "sparx.games.progress.talkandlearncontrol.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { StringValue } from '../../../../../../google/protobuf/wrappers';
import { BoolValue } from '../../../../../../google/protobuf/wrappers';
import { UInt32Value } from '../../../../../../google/protobuf/wrappers';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
//
// Stored data to keep track of what questions the player needs to do
// Talk and Learn on before they can do 100 Club

/**
 * @generated from protobuf message sparx.games.progress.talkandlearncontrol.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp lastUpdatedTimestamp = 1;
   */
  lastUpdatedTimestamp?: Timestamp;
  /**
   * For each question, how many times does the player still need to do
   * TaL on it?
   *
   * @generated from protobuf field: map<string, uint32> talRemainingPerQuestion = 2;
   */
  talRemainingPerQuestion: {
    [key: string]: number;
  };
  /**
   * When did the player last empty their list? They should be able to play
   * games for the rest of the day having done this, but be blocked from
   * games on the next day.
   *
   * @generated from protobuf field: google.protobuf.Timestamp pendingListClearedTimestamp = 3;
   */
  pendingListClearedTimestamp?: Timestamp;
  /**
   * Counts the number of times you have needed help with a TaL, to decide
   * if you need a faster timer
   *
   * @generated from protobuf field: google.protobuf.UInt32Value failedAttemptCounter = 4;
   */
  failedAttemptCounter?: UInt32Value;
  /**
   * Counts the number of times each alert has come up
   * after being shown them enough times the TaL process will forego the alerts
   *
   * @generated from protobuf field: google.protobuf.UInt32Value reminderAlertCount = 5;
   */
  reminderAlertCount?: UInt32Value;
  /**
   * @generated from protobuf field: google.protobuf.UInt32Value letsLearnAlertCount = 6;
   */
  letsLearnAlertCount?: UInt32Value;
  /**
   * If you back out during a TaL, you will be prompted to answer that same TaL
   * next time you enter a game, unless that was over 1 hour ago
   *
   * @generated from protobuf field: google.protobuf.BoolValue hasInProgressQuestion = 7;
   */
  hasInProgressQuestion?: BoolValue;
  /**
   * @generated from protobuf field: google.protobuf.StringValue inProgressQuestionId = 8;
   */
  inProgressQuestionId?: StringValue;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp inProgressQuestionTimestamp = 9;
   */
  inProgressQuestionTimestamp?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.progress.talkandlearncontrol.v1.Store', [
      {
        no: 1,
        name: 'lastUpdatedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'talRemainingPerQuestion',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      },
      {
        no: 3,
        name: 'pendingListClearedTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 4,
        name: 'failedAttemptCounter',
        kind: 'message',
        T: () => UInt32Value,
      },
      {
        no: 5,
        name: 'reminderAlertCount',
        kind: 'message',
        T: () => UInt32Value,
      },
      {
        no: 6,
        name: 'letsLearnAlertCount',
        kind: 'message',
        T: () => UInt32Value,
      },
      {
        no: 7,
        name: 'hasInProgressQuestion',
        kind: 'message',
        T: () => BoolValue,
      },
      {
        no: 8,
        name: 'inProgressQuestionId',
        kind: 'message',
        T: () => StringValue,
      },
      {
        no: 9,
        name: 'inProgressQuestionTimestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.progress.talkandlearncontrol.v1.Store
 */
export const Store = new Store$Type();

import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdaptiveAssessmentContents } from '@sparx/api/apis/sparx/assessment/v1/adaptive';
import { Package, Task, TaskItem } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { isComplete } from '@sparx/packageactivity';
import { MarkdownNode } from '@sparx/question';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useActivity } from 'api/activities';
import { setCurrentActivity } from 'api/statepusher';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ActivityDelivery } from 'views/student/studentsittingview/adaptive/ActivityDelivery';
import { BucketedAssessmentTaskItem } from 'views/student/studentsittingview/adaptive/BucketedAssessmentTaskItem';
import { scrollContainerTop } from 'views/student/studentsittingview/adaptive/utils';
import { SittingPage } from 'views/student/studentsittingview/StudentSittingView';

import styles from './AdaptiveAssessment.module.css';

export const AdaptiveAssessmentPackage = ({ pkg }: { pkg: Package }) => {
  // Select the first incomplete task
  const task = pkg.contents?.tasks?.find(task => !isComplete(task.state?.completion));
  if (!task) {
    return 'No incomplete task?';
  }

  const header = <AdaptiveAssessmentProgress pkg={pkg} currentTaskName={task?.name} />;

  return (
    <SittingPage header={header}>
      <AnimatePresence mode="wait" onExitComplete={scrollContainerTop()}>
        <AnimatedContainer key={task.name}>
          <AdaptiveAssessmentTask task={task} />
        </AnimatedContainer>
      </AnimatePresence>
    </SittingPage>
  );
};

interface AdaptiveAssessmentProgressProps {
  pkg: Package;
  currentTaskName?: string;
}

export const AdaptiveAssessmentProgress = ({
  pkg,
  currentTaskName,
}: AdaptiveAssessmentProgressProps) => (
  <Stack spacing={1}>
    {pkg?.contents?.tasks?.map(task => (
      <div
        key={task.name}
        className={classNames(styles.Task, {
          [styles.TaskFinished]: isComplete(task.state?.completion),
          [styles.TaskCurrent]: task.name === currentTaskName,
        })}
      >
        <FontAwesomeIcon icon={faCheck} />
      </div>
    ))}
  </Stack>
);

export const AdaptiveAssessmentTask = ({ task }: { task: Task }) => {
  const [started, setStarted] = useState((task.state?.completion?.progress?.C || 0) > 0);

  // Automatically transition to the first incomplete task item
  const taskItem = task.contents?.taskItems?.find(item => !item.state?.completed);
  if (!taskItem?.contents) {
    return '??';
  }

  const kind = taskItem.contents.contents.oneofKind;

  return (
    <AnimatePresence mode="wait" onExitComplete={scrollContainerTop()}>
      {kind === 'adaptiveAssessment' ? (
        !started ? (
          <AdaptiveAssessmentIntroduction
            key="intro"
            adaptiveAssessment={taskItem.contents.contents.adaptiveAssessment}
            onStart={() => setStarted(true)}
            taskItem={taskItem}
          />
        ) : (
          <AdaptiveAssessmentTaskItem key={taskItem.name} taskItem={taskItem} />
        )
      ) : kind === 'bucketedAssessment' ? (
        <BucketedAssessmentTaskItem key={taskItem.name} taskItem={taskItem} />
      ) : (
        'Unknown kind of task item'
      )}
    </AnimatePresence>
  );
};

const AUTO_PROGRESS_SECONDS = 15;
const WARNING_SECONDS = 5;

const AdaptiveAssessmentIntroduction = ({
  adaptiveAssessment,
  onStart,
  taskItem,
}: {
  adaptiveAssessment: AdaptiveAssessmentContents;
  onStart: () => void;
  taskItem: TaskItem;
}) => {
  // Ticker which counts up in seconds
  const [elapsed, setElapsed] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => setElapsed(t => t + 1), 1000);
    return () => clearInterval(interval);
  }, [setElapsed]);

  // Auto progress
  useEffect(() => {
    if (elapsed >= AUTO_PROGRESS_SECONDS) {
      onStart();
    }
  }, [elapsed, onStart]);

  // Change button text to warning if we're within limit
  const showWarning = elapsed >= AUTO_PROGRESS_SECONDS - WARNING_SECONDS;
  const warningSeconds = Math.max(1, AUTO_PROGRESS_SECONDS - elapsed);

  return (
    <AnimatedContainer>
      <div className={styles.IntroContainer}>
        <h2>{taskItem.title}</h2>
        <MarkdownNode className={styles.Description}>
          {adaptiveAssessment?.description}
        </MarkdownNode>
        <Button
          onClick={onStart}
          className={styles.StartIcon}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          {showWarning ? `Starting in ${warningSeconds}...` : 'Start'}
        </Button>
      </div>
    </AnimatedContainer>
  );
};

const AnimatedContainer = ({ children }: PropsWithChildren) => (
  <motion.div
    className={styles.Page}
    transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
    initial={{ opacity: 0, x: '200px' }}
    exit={{ opacity: 0, x: '-200px' }}
    animate={{ opacity: 1, x: 0 }}
  >
    {children}
  </motion.div>
);

interface AdaptiveAssessmentTaskItemProps {
  taskItem: TaskItem;
}

const AdaptiveAssessmentTaskItem = ({ taskItem }: AdaptiveAssessmentTaskItemProps) => {
  const { data: activity, refetch } = useActivity(taskItem.name);

  useEffect(() => {
    setCurrentActivity({
      activityName: activity?.name || '',
      taskItemName: taskItem.name,
    });
  }, [activity?.name, taskItem.name]);

  if (!activity) {
    return <></>;
  }
  return (
    <AnimatePresence mode="wait" onExitComplete={scrollContainerTop()}>
      <AnimatedContainer key={activity.name}>
        <ActivityDelivery activity={activity} onComplete={refetch} />
      </AnimatedContainer>
    </AnimatePresence>
  );
};

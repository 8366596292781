// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/planning/v1/plan.proto" (package "sparx.planning.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { CreatePackageRequest } from '../../homeworkman/v1/homeworkman';
import { Package } from '../../homeworkman/v1/homeworkman';
import { FieldMask } from '../../../../google/protobuf/field_mask';
import { Timestamp } from '../../../../google/protobuf/timestamp';
import { Int32Value } from '../../../../google/protobuf/wrappers';
import { TimeOfDay } from '../../../../google/type/timeofday';
import { DayOfWeek } from '../../../../google/type/dayofweek';
/**
 * A single unit of work that can go in a homework
 *
 * @generated from protobuf message sparx.planning.v1.HomeworkUnit
 */
export interface HomeworkUnit {
  /**
   * ID that should be unique to the unit within the homework.
   *
   * @generated from protobuf field: string work_unit_id = 1;
   */
  workUnitId: string;
  /**
   * @generated from protobuf oneof: payload
   */
  payload:
    | {
        oneofKind: 'topic';
        /**
         * Topic name in the form "curriculums/{curriculum_id}/topics/{topic_id}"
         *
         * @generated from protobuf field: string topic = 2;
         */
        topic: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.planning.v1.HomeworkPlan
 */
export interface HomeworkPlan {
  /**
   * Name of the homework plan, in the form
   * `schools/{school_id}/studentGroups/{student_group_id}/homeworkPlan`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The version of the homework plan. This is updated on any change to
   * prevent conflicting changes.
   *
   * @generated from protobuf field: int32 version = 2;
   */
  version: number;
  /**
   * The type of the homework plan.
   *
   * @generated from protobuf field: sparx.planning.v1.HomeworkPlanType plan_type = 3;
   */
  planType: HomeworkPlanType;
  /**
   * Name of the SOL this plan was made from, in the form
   * `schools/{school_id}/sols/{sol_id}`.  This could be unset if the plan was
   * not made from a SOL.
   *
   * @generated from protobuf field: string scheme_of_learning = 4;
   */
  schemeOfLearning: string;
  /**
   * The version of the SOL this plan was made from. If this doesn't match
   * the stored version, the new version of the SOL will be fetched and any
   * ungenerated homeworks in the plan will be deleted and recreated from the
   * new SOL. If the new version does not exist, an error will be returned.
   *
   * @generated from protobuf field: int32 scheme_of_learning_version = 5;
   */
  schemeOfLearningVersion: number;
  /**
   * Name of the curriculum that forms the base for this SOL, in the form
   * "curriculums/{curriculum_id}".
   *
   * @generated from protobuf field: string base_curriculum = 6;
   */
  baseCurriculum: string;
  /**
   * Whether homework should be generated once we go past their set_time. If
   * this is false, no homework will be generated for the class.
   *
   * @generated from protobuf field: bool is_active = 7;
   */
  isActive: boolean;
  /**
   * The default day to set homework. This is only used when creating
   * homeworks to find an appropriate set_time.
   *
   * @generated from protobuf field: google.type.DayOfWeek default_set_day = 8;
   */
  defaultSetDay: DayOfWeek;
  /**
   * The default time to hand out homework. This is only used when creating
   * homeworks to find an appropriate set_time.
   *
   * @generated from protobuf field: google.type.TimeOfDay default_set_time = 9;
   */
  defaultSetTime?: TimeOfDay;
  /**
   * The default time homework is due in. This is only used when creating
   * homeworks to find an appropriate due_time.
   *
   * @generated from protobuf field: google.type.TimeOfDay default_due_time = 10;
   */
  defaultDueTime?: TimeOfDay;
  /**
   * The default number of days between the set_time and due_time of a
   * homework. A value of 1 means that homework would be scheduled to be due
   * in one day after the default_set_day. This is only used when creating
   * homeworks to find an appropriate due_time.
   *
   * @generated from protobuf field: int32 default_duration_days = 11;
   */
  defaultDurationDays: number;
  /**
   * The length in minutes of the compulsory packages for homeworks on this
   * plan. A value of 0 means no compulsory packages will be generated. In
   * the case of a new homework plan, if no value is provided, a default of
   * 60 minutes will be used. In the case of updating an existing homework
   * plan, if no value is provided, no change will be made.
   *
   * @generated from protobuf field: google.protobuf.Int32Value compulsory_minutes = 12;
   */
  compulsoryMinutes?: Int32Value;
  /**
   * Has times tables been enabled for this homework plan.
   *
   * @generated from protobuf field: bool has_tables_task = 13;
   */
  hasTablesTask: boolean;
  /**
   * The last time this plan was made active, indicated by the is_active
   * field. If is_active is false and this time is set it indicates that the
   * plan was made active previously and subsequently made inactive.
   * Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp made_active_time = 14;
   */
  madeActiveTime?: Timestamp;
  /**
   * The time when the homework plan ended - this is when the group associated
   * with the plan was marked as "ended", i.e. deleted
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_time = 15;
   */
  endTime?: Timestamp;
  /**
   * Sparx user id of the last user to update the plan
   *
   * @generated from protobuf field: string updated_by = 16;
   */
  updatedBy: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.Homework
 */
export interface Homework {
  /**
   * Name of the homework, in the form
   * `schools/{school_id}/studentGroups/{student_group_id}/homeworkPlan/homeworks/{homework_id}`
   * (Homework is a sub-resource of HomeworkPlan)
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Week this homework belongs to. Note that there could be more than one
   * homework per week - when it is the case, homeworks can be ordered by e.g.
   * set_time.
   *
   * @generated from protobuf field: int32 week_index = 2;
   */
  weekIndex: number;
  /**
   * Type of homework
   *
   * @generated from protobuf field: sparx.planning.v1.HomeworkType homework_type = 3;
   */
  homeworkType: HomeworkType;
  /**
   * What goes into the homework, ordered by which topics should be first in
   * the homework.
   *
   * @generated from protobuf field: sparx.planning.v1.HomeworkContents contents = 4;
   */
  contents?: HomeworkContents;
  /**
   * When the homework should be handed out to students.
   *
   * @generated from protobuf field: google.protobuf.Timestamp set_time = 5;
   */
  setTime?: Timestamp;
  /**
   * When the homework should be handed in by students, after which it would
   * be considered late.
   *
   * @generated from protobuf field: google.protobuf.Timestamp due_time = 6;
   */
  dueTime?: Timestamp;
  /**
   * When this homework started being generated. If this is set but
   * generation_time is not this homework is still being processed for
   * generation.
   *
   * @generated from protobuf field: google.protobuf.Timestamp generation_start_time = 7;
   */
  generationStartTime?: Timestamp;
  /**
   * When the homework packages were generated.  It is not set for homeworks
   * that haven't been handed out yet.
   *
   * @generated from protobuf field: google.protobuf.Timestamp generation_time = 8;
   */
  generationTime?: Timestamp;
  /**
   * Has times tables been enabled for this homework.
   *
   * @generated from protobuf field: bool has_tables_task = 9;
   */
  hasTablesTask: boolean;
  /**
   * When the homework was cancelled (turned optional only)
   *
   * @generated from protobuf field: google.protobuf.Timestamp cancelled_time = 10;
   */
  cancelledTime?: Timestamp;
  /**
   * When the homework should no longer be available to students
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry_time = 11;
   */
  expiryTime?: Timestamp;
  /**
   * The length in minutes of the compulsory packages for this homework. Acts
   * as an override for the plan level compulsory_minutes. A value of 0 means
   * we'll just use the default set on the plan.
   *
   * Note: Students set to 'HalfLength' homework will still receive 30 minutes of
   * homework no matter what the compulsory_minutes is set to.
   *
   * @generated from protobuf field: google.protobuf.Int32Value compulsory_minutes = 12;
   */
  compulsoryMinutes?: Int32Value;
}
/**
 * @generated from protobuf message sparx.planning.v1.HomeworkContents
 */
export interface HomeworkContents {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.HomeworkUnit homework_units = 1;
   */
  homeworkUnits: HomeworkUnit[];
}
/**
 * @generated from protobuf message sparx.planning.v1.GetHomeworkPlanRequest
 */
export interface GetHomeworkPlanRequest {
  /**
   * Name of the homework plan to fetch, in the form
   * `schools/{school_id}/studentGroups/{student_group_id}/homeworkPlan`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.BatchGetHomeworkPlansRequest
 */
export interface BatchGetHomeworkPlansRequest {
  /**
   * Name of the school
   * `schools/{school_id}`
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * List of student group resource names to get
   * schools/{school_id}/studentGroups/{student_group_id}
   *
   * @generated from protobuf field: repeated string student_group_names = 2;
   */
  studentGroupNames: string[];
}
/**
 * @generated from protobuf message sparx.planning.v1.BatchGetHomeworkPlansResponse
 */
export interface BatchGetHomeworkPlansResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.HomeworkPlan homework_plans = 1;
   */
  homeworkPlans: HomeworkPlan[];
}
/**
 * @generated from protobuf message sparx.planning.v1.UpdateHomeworkPlanRequest
 */
export interface UpdateHomeworkPlanRequest {
  /**
   * The plan to update
   *
   * @generated from protobuf field: sparx.planning.v1.HomeworkPlan plan = 1;
   */
  plan?: HomeworkPlan;
  /**
   * List of fields to update
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.planning.v1.GetHomeworkRequest
 */
export interface GetHomeworkRequest {
  /**
   * The name of the homework to retrieve
   * `schools/{school_id}/studentGroups/{studentGroupID}/homeworkPlan/homeworks/{homework_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListHomeworksRequest
 */
export interface ListHomeworksRequest {
  /**
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * The maximum number of homeworks to return. The service may return fewer
   * than this value.
   * If unspecified, at most 100 homeworks will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * A page token, received from a previous `ListHomeworks` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListHomeworks` must
   * match the call that provided the page token.
   *
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
  /**
   * A set of optional filters on the set and due times
   *
   * @generated from protobuf field: google.protobuf.Timestamp set_after = 4;
   */
  setAfter?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp set_before = 5;
   */
  setBefore?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp due_after = 6;
   */
  dueAfter?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp due_before = 7;
   */
  dueBefore?: Timestamp;
  /**
   * If set to true, then this will return homeworks with negative week indices
   * - i.e. in a previous academic year By default, negative week indices are
   * filtered, this is because there are some homework plans in the database
   * that unfortunately cover multiple years due to IM-69 where the same student
   * group was accidentally reused across years due to a MIS syncing issue
   *
   * @generated from protobuf field: bool include_previous_year_homeworks = 8;
   */
  includePreviousYearHomeworks: boolean;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListHomeworksResponse
 */
export interface ListHomeworksResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.Homework homeworks = 1;
   */
  homeworks: Homework[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page. If
   * this field is omitted, there are no subsequent pages.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
  /**
   * The current version of the homework plan the homeworks belong to
   *
   * @generated from protobuf field: int32 plan_version = 3;
   */
  planVersion: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.DeleteHomeworkRequest
 */
export interface DeleteHomeworkRequest {
  /**
   * The name of the homework to delete.
   * `schools/{school_id}/studentGroups/{studentGroupID}/homeworkPlan/homeworks/{homework_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.HomeworkPlanContents
 */
export interface HomeworkPlanContents {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.Homework homeworks = 1;
   */
  homeworks: Homework[];
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.SkippedTopic skipped_topics = 2;
   */
  skippedTopics: SkippedTopic[];
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.RemovedFromConsolidationTopic removed_from_consolidation_topics = 3;
   */
  removedFromConsolidationTopics: RemovedFromConsolidationTopic[];
  /**
   * @generated from protobuf field: int32 plan_version = 4;
   */
  planVersion: number;
}
/**
 * A topic that has been skipped from a homework
 *
 * @generated from protobuf message sparx.planning.v1.SkippedTopic
 */
export interface SkippedTopic {
  /**
   * Resource name of the topic
   *
   * @generated from protobuf field: string topic_name = 1;
   */
  topicName: string;
  /**
   * Week index that the topic was originally skipped from
   *
   * @generated from protobuf field: int32 week_index = 2;
   */
  weekIndex: number;
}
/**
 * A topic that has been removed from consolidation
 *
 * @generated from protobuf message sparx.planning.v1.RemovedFromConsolidationTopic
 */
export interface RemovedFromConsolidationTopic {
  /**
   * Resource name of the topic
   *
   * @generated from protobuf field: string topic_name = 1;
   */
  topicName: string;
  /**
   * Time when the topic was removed from consolidation
   *
   * @generated from protobuf field: google.protobuf.Timestamp removed_time = 2;
   */
  removedTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.planning.v1.GetHomeworkPlanContentsRequest
 */
export interface GetHomeworkPlanContentsRequest {
  /**
   * Name of the homework plan whose contents we wish to fetch, in the form
   * `schools/{school_id}/studentGroups/{student_group_id}/homeworkPlan`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * If set to true, then this will return homeworks with negative week indices
   * - i.e. in a previous academic year By default, negative week indices are
   * filtered, this is because there are some homework plans in the database
   * that unfortunately cover multiple years due to IM-69 where the same student
   * group was accidentally reused across years due to a MIS syncing issue
   *
   * @generated from protobuf field: bool include_previous_year_homeworks = 2;
   */
  includePreviousYearHomeworks: boolean;
}
/**
 * @generated from protobuf message sparx.planning.v1.UpdateHomeworkPlanContentsRequest
 */
export interface UpdateHomeworkPlanContentsRequest {
  /**
   * The resource name of the homework plan whose contents we wish to update, in
   * the form
   * `schools/{school_id}/studentGroups/{student_group_id}/homeworkPlan`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * All the contents of a homework plan to update. All homeworks that have not
   * been generated yet will be replaced with those provided, as will the list
   * of skipped topics and topics removed from consolidation
   *
   * @generated from protobuf field: sparx.planning.v1.HomeworkPlanContents contents = 2;
   */
  contents?: HomeworkPlanContents;
  /**
   * The version of the homework plan being updated. There will be an invalid
   * argument if this is not the latest version of the plan
   *
   * @generated from protobuf field: int32 plan_version = 3;
   */
  planVersion: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListSkippedTopicsRequest
 */
export interface ListSkippedTopicsRequest {
  /**
   * Homework plan name -
   * schools/{school_id}/studentGroups/{student_group_id}/homeworkPlan
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListSkippedTopicsResponse
 */
export interface ListSkippedTopicsResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.SkippedTopic skipped_topics = 1;
   */
  skippedTopics: SkippedTopic[];
}
/**
 * @generated from protobuf message sparx.planning.v1.CancelGeneratedHomeworkRequest
 */
export interface CancelGeneratedHomeworkRequest {
  /**
   * The name of the homework to cancel.
   * `schools/{school_id}/studentGroups/{studentGroupID}/homeworkPlan/homeworks/{homework_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * When cancelling the homework, also remove any topics with resource names
   * in this list from appearing in consolidation. To leave all topics in
   * consolidation, leave the list empty.
   *
   * @generated from protobuf field: repeated string remove_from_consolidation_topics = 2;
   */
  removeFromConsolidationTopics: string[];
  /**
   * User IDs to limit to, if empty the homework will be marked as deleted for
   * all students. If including the list of user_ids the homework has been
   * cancelled for every student, then the overall homework is marked as
   * cancelled
   *
   * @generated from protobuf field: repeated string user_ids = 3;
   */
  userIds: string[];
}
/**
 * @generated from protobuf message sparx.planning.v1.ListHomeworksByStartDateRequest
 */
export interface ListHomeworksByStartDateRequest {
  /**
   * Required. Only return homeworks starting after this time.
   *
   * @generated from protobuf field: google.protobuf.Timestamp starts_after = 1;
   */
  startsAfter?: Timestamp;
  /**
   * Required. Only return homeworks starting before this time.
   *
   * @generated from protobuf field: google.protobuf.Timestamp starts_before = 2;
   */
  startsBefore?: Timestamp;
  /**
   * The maximum number of homeworks to return. The service may return fewer
   * than this value.
   * If unspecified, at most 1000 homeworks will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   *
   * @generated from protobuf field: int32 page_size = 3;
   */
  pageSize: number;
  /**
   * A page token, received from a previous `ListHomeworksByStartDate` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to
   * `ListHomeworksByStartDate` must match the call that provided the page
   * token.
   *
   * @generated from protobuf field: string page_token = 4;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListGeneratedHomeworkForStudentsRequest
 */
export interface ListGeneratedHomeworkForStudentsRequest {
  /**
   * The school name
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Repeated list of student IDs to return. At least 1 must be provided
   *
   * @generated from protobuf field: repeated string student_ids = 2;
   */
  studentIds: string[];
  /**
   * Get homeworks which end after the provided date. This will be truncated
   * to the start of the day by the database query
   *
   * @generated from protobuf field: google.protobuf.Timestamp ends_after = 4;
   */
  endsAfter?: Timestamp;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListHomeworkPlansUsingSchemeRequest
 */
export interface ListHomeworkPlansUsingSchemeRequest {
  /**
   * The parent name for the homework plan
   * `schools/{schoolID}`
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Scheme to be searched for
   *
   * @generated from protobuf field: string sol_id = 2;
   */
  solId: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.ListHomeworkPlansUsingSchemeResponse
 */
export interface ListHomeworkPlansUsingSchemeResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.HomeworkPlan homework_plans = 1;
   */
  homeworkPlans: HomeworkPlan[];
}
/**
 * Request to acquire a launch lock on a plan. This marks the plan as being
 * launched, and other attempts to launch it within the following 2 minutes will
 * not be able to acquire the lock
 *
 * @generated from protobuf message sparx.planning.v1.GetHomeworkPlanLaunchLockRequest
 */
export interface GetHomeworkPlanLaunchLockRequest {
  /**
   * Resource name of the plan to lock
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * Response to a request to acquire a launch lock on a plan
 *
 * @generated from protobuf message sparx.planning.v1.GetHomeworkPlanLaunchLockResponse
 */
export interface GetHomeworkPlanLaunchLockResponse {
  /**
   * Whether the lock was acquired
   *
   * @generated from protobuf field: bool lock_acquired = 1;
   */
  lockAcquired: boolean;
}
/**
 * @generated from protobuf message sparx.planning.v1.BatchUpdateHomeworkPlansRequest
 */
export interface BatchUpdateHomeworkPlansRequest {
  /**
   * schools/{school_id}
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * List of requests to send to homeworkman
   *
   * @generated from protobuf field: repeated sparx.planning.v1.UpdateHomeworkPlanRequest requests = 2;
   */
  requests: UpdateHomeworkPlanRequest[];
}
/**
 * @generated from protobuf message sparx.planning.v1.ListHomeworkPlansResponse
 */
export interface ListHomeworkPlansResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.HomeworkPlan homework_plans = 1;
   */
  homeworkPlans: HomeworkPlan[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.planning.v1.MigrateSchoolHomeworkPlansRequest
 */
export interface MigrateSchoolHomeworkPlansRequest {
  /**
   * List of school IDs to migrate
   *
   * @generated from protobuf field: repeated string school_ids = 1;
   */
  schoolIds: string[];
  /**
   * Dry run boolean, defaults to false for safety while testing
   *
   * @generated from protobuf field: bool commit_changes = 2;
   */
  commitChanges: boolean;
  /**
   * Whether to omit the full plan in the response (to speed up the migration)
   *
   * @generated from protobuf field: bool omit_response = 3;
   */
  omitResponse: boolean;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.planning.v1.MigratedPlan
 */
export interface MigratedPlan {
  /**
   * The migrated homework plan
   *
   * @generated from protobuf field: sparx.planning.v1.HomeworkPlan homework_plan = 1;
   */
  homeworkPlan?: HomeworkPlan;
  /**
   * The migrated homework plan contents
   *
   * @generated from protobuf field: sparx.planning.v1.HomeworkPlanContents homework_plan_contents = 2;
   */
  homeworkPlanContents?: HomeworkPlanContents;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.planning.v1.MigratedSchoolHomeworkPlans
 */
export interface MigratedSchoolHomeworkPlans {
  /**
   * The migrated plans for the school
   *
   * @generated from protobuf field: repeated sparx.planning.v1.MigratedPlan migrated_plans = 1;
   */
  migratedPlans: MigratedPlan[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.planning.v1.MigrateSchoolHomeworkPlansResponse
 */
export interface MigrateSchoolHomeworkPlansResponse {
  /**
   * Migrated homework plans for each school. Will not be returned if
   * omit_response = true in the request
   *
   * @generated from protobuf field: repeated sparx.planning.v1.MigratedSchoolHomeworkPlans migrated_school_homework_plans = 1;
   */
  migratedSchoolHomeworkPlans: MigratedSchoolHomeworkPlans[];
  /**
   * Count of the plans migrated
   *
   * @generated from protobuf field: int32 plans_migrated = 2;
   */
  plansMigrated: number;
}
/**
 * The request to generate a homework for a group of students in a student
 * group. The homework plan homework and packages must already exist in the db.
 *
 * @generated from protobuf message sparx.planning.v1.GenerateHomeworkRequest
 */
export interface GenerateHomeworkRequest {
  /**
   * Homework resource name
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Packages for the homework
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.Package packages = 2;
   */
  packages: Package[];
  /**
   * The start date of the homework in the school's TZ (at 00:00:00)
   *
   * @generated from protobuf field: google.protobuf.Timestamp local_start_date = 3;
   */
  localStartDate?: Timestamp;
  /**
   * The end date of the homework in the school's TZ (at 00:00:00)
   *
   * @generated from protobuf field: google.protobuf.Timestamp local_end_date = 4;
   */
  localEndDate?: Timestamp;
  /**
   * The expiry date of the homework in the school's TZ (at 00:00:00)
   *
   * @generated from protobuf field: google.protobuf.Timestamp local_expiry_date = 5;
   */
  localExpiryDate?: Timestamp;
  /**
   * The expiry time of the homework
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry_time = 6;
   */
  expiryTime?: Timestamp;
  /**
   * The length of the homework in minutes
   *
   * @generated from protobuf field: int64 compulsory_minutes = 7;
   */
  compulsoryMinutes: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.BatchCreatePackagesRequest
 */
export interface BatchCreatePackagesRequest {
  /**
   * Homework plan homework name
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The package requests to create.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.CreatePackageRequest requests = 2;
   */
  requests: CreatePackageRequest[];
  /**
   * Optional: the homework the packages are being requested for. This is used
   * for Primary Times Tables plans where the homework does not exist in the
   * database yet and needs to be created at the point packages are made for it
   *
   * @generated from protobuf field: sparx.planning.v1.Homework homework = 3;
   */
  homework?: Homework;
}
/**
 * @generated from protobuf message sparx.planning.v1.BatchCreatePackagesResponse
 */
export interface BatchCreatePackagesResponse {
  /**
   * The created packages.
   *
   * @generated from protobuf field: repeated sparx.homeworkman.v1.Package packages = 1;
   */
  packages: Package[];
}
/**
 * Message sent over pubsub for Big Query analytics whenever a homework plan or
 * its contents are updated
 *
 * @generated from protobuf message sparx.planning.v1.HomeworkPlanUpdateMessage
 */
export interface HomeworkPlanUpdateMessage {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: sparx.planning.v1.HomeworkPlan homework_plan = 2;
   */
  homeworkPlan?: HomeworkPlan;
  /**
   * @generated from protobuf field: sparx.planning.v1.HomeworkPlanContents contents = 3;
   */
  contents?: HomeworkPlanContents;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.planning.v1.SendAnalyticsRequest
 */
export interface SendAnalyticsRequest {
  /**
   * @generated from protobuf field: string schoolName = 1;
   */
  schoolName: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.planning.v1.SendAnalyticsResponse
 */
export interface SendAnalyticsResponse {
  /**
   * @generated from protobuf field: int32 plansDone = 1;
   */
  plansDone: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.GetPlansToGenerateRequest
 */
export interface GetPlansToGenerateRequest {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.planning.v1.SchoolPlansToGenerate
 */
export interface SchoolPlansToGenerate {
  /**
   * @generated from protobuf field: string schoolID = 1;
   */
  schoolID: string;
  /**
   * @generated from protobuf field: repeated string groupIDs = 2;
   */
  groupIDs: string[];
}
/**
 * @generated from protobuf message sparx.planning.v1.GetPlansToGenerateResponse
 */
export interface GetPlansToGenerateResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.SchoolPlansToGenerate plans_to_generate = 1;
   */
  plansToGenerate: SchoolPlansToGenerate[];
}
/**
 * @generated from protobuf message sparx.planning.v1.AddTopicsToNextHomeworkRequest
 */
export interface AddTopicsToNextHomeworkRequest {
  /**
   * Resource name of the homework plan.
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Request ID is used to create reproducible work unit IDs.
   *
   * @generated from protobuf field: string request_id = 2;
   */
  requestId: string;
  /**
   * Resource names of the topics to add to the next homework.
   *
   * @generated from protobuf field: repeated string topic_names = 3;
   */
  topicNames: string[];
}
/**
 * @generated from protobuf message sparx.planning.v1.AddTopicsToNextHomeworkResponse
 */
export interface AddTopicsToNextHomeworkResponse {
  /**
   * Week index of the homework the topics were added to.
   *
   * @generated from protobuf field: int32 week_index = 1;
   */
  weekIndex: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.UpdatePlansForRolloverDateChangeRequest
 */
export interface UpdatePlansForRolloverDateChangeRequest {
  /**
   * school name - schools/{school_id}
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * New academic year roll-over time
   *
   * @generated from protobuf field: google.protobuf.Timestamp new_academic_year_end_time = 2;
   */
  newAcademicYearEndTime?: Timestamp;
  /**
   * List of student group names of the plans to update
   *
   * @generated from protobuf field: repeated string student_group_names = 3;
   */
  studentGroupNames: string[];
}
/**
 * @generated from protobuf message sparx.planning.v1.UpdatePlansForRolloverDateChangeResponse
 */
export interface UpdatePlansForRolloverDateChangeResponse {
  /**
   * Number of plans updated
   *
   * @generated from protobuf field: int32 plans_updated = 1;
   */
  plansUpdated: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.GetPreviousAcademicYearConsolidationTopicsRequest
 */
export interface GetPreviousAcademicYearConsolidationTopicsRequest {
  /**
   * The name of the group to get the previous consolidation topics for.
   * `schools/{school_id}/studentGroups/{studentGroupID}
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.GetPreviousAcademicYearConsolidationTopicsResponse
 */
export interface GetPreviousAcademicYearConsolidationTopicsResponse {
  /**
   * @generated from protobuf field: repeated sparx.planning.v1.PreviousConsolidationTopicInfo previous_topics = 1;
   */
  previousTopics: PreviousConsolidationTopicInfo[];
}
/**
 * @generated from protobuf message sparx.planning.v1.PreviousConsolidationTopicInfo
 */
export interface PreviousConsolidationTopicInfo {
  /**
   * Resource name of the topic
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The number of students that can have the topic as consolidation
   *
   * @generated from protobuf field: int32 students_count = 2;
   */
  studentsCount: number;
}
/**
 * @generated from protobuf message sparx.planning.v1.CancelUngeneratedHomeworksRequest
 */
export interface CancelUngeneratedHomeworksRequest {
  /**
   * The name of the homework plan to skip ungenerated homeworks for
   * `schools/{school_id}/studentGroups/{student_group_id}/homeworkPlan`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.planning.v1.CancelUngeneratedHomeworksResponse
 */
export interface CancelUngeneratedHomeworksResponse {}
/**
 * @generated from protobuf enum sparx.planning.v1.HomeworkPlanType
 */
export enum HomeworkPlanType {
  /**
   * Not set to a valid value
   *
   * @generated from protobuf enum value: HPT_UNDEFINED = 0;
   */
  HPT_UNDEFINED = 0,
  /**
   * Type of homework plan based on a scheme of learning
   *
   * @generated from protobuf enum value: HPT_SOL = 1;
   */
  HPT_SOL = 1,
  /**
   * Type of homework plan for primary times tables
   *
   * @generated from protobuf enum value: HPT_TIMES_TABLES = 2;
   */
  HPT_TIMES_TABLES = 2,
  /**
   * Type of homework plan for lesson driven homework
   *
   * @generated from protobuf enum value: HPT_LESSON_DRIVEN = 3;
   */
  HPT_LESSON_DRIVEN = 3,
}
/**
 * @generated from protobuf enum sparx.planning.v1.HomeworkType
 */
export enum HomeworkType {
  /**
   * @generated from protobuf enum value: HOMEWORK_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: HOMEWORK_TYPE_FULL = 1;
   */
  FULL = 1,
  /**
   * @generated from protobuf enum value: HOMEWORK_TYPE_HALF = 2;
   */
  HALF = 2,
  /**
   * @generated from protobuf enum value: HOMEWORK_TYPE_OPTIONAL_ONLY = 3;
   */
  OPTIONAL_ONLY = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkUnit$Type extends MessageType<HomeworkUnit> {
  constructor() {
    super('sparx.planning.v1.HomeworkUnit', [
      {
        no: 1,
        name: 'work_unit_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'topic',
        kind: 'scalar',
        oneof: 'payload',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.HomeworkUnit
 */
export const HomeworkUnit = new HomeworkUnit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkPlan$Type extends MessageType<HomeworkPlan> {
  constructor() {
    super('sparx.planning.v1.HomeworkPlan', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'plan_type',
        kind: 'enum',
        T: () => ['sparx.planning.v1.HomeworkPlanType', HomeworkPlanType],
      },
      {
        no: 4,
        name: 'scheme_of_learning',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'scheme_of_learning_version',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'base_curriculum',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'is_active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 8,
        name: 'default_set_day',
        kind: 'enum',
        T: () => ['google.type.DayOfWeek', DayOfWeek],
      },
      { no: 9, name: 'default_set_time', kind: 'message', T: () => TimeOfDay },
      { no: 10, name: 'default_due_time', kind: 'message', T: () => TimeOfDay },
      {
        no: 11,
        name: 'default_duration_days',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 12,
        name: 'compulsory_minutes',
        kind: 'message',
        T: () => Int32Value,
      },
      {
        no: 13,
        name: 'has_tables_task',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 14, name: 'made_active_time', kind: 'message', T: () => Timestamp },
      { no: 15, name: 'end_time', kind: 'message', T: () => Timestamp },
      {
        no: 16,
        name: 'updated_by',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.HomeworkPlan
 */
export const HomeworkPlan = new HomeworkPlan$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Homework$Type extends MessageType<Homework> {
  constructor() {
    super('sparx.planning.v1.Homework', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'week_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'homework_type',
        kind: 'enum',
        T: () => [
          'sparx.planning.v1.HomeworkType',
          HomeworkType,
          'HOMEWORK_TYPE_',
        ],
      },
      { no: 4, name: 'contents', kind: 'message', T: () => HomeworkContents },
      { no: 5, name: 'set_time', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'due_time', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'generation_start_time',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 8, name: 'generation_time', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'has_tables_task',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 10, name: 'cancelled_time', kind: 'message', T: () => Timestamp },
      { no: 11, name: 'expiry_time', kind: 'message', T: () => Timestamp },
      {
        no: 12,
        name: 'compulsory_minutes',
        kind: 'message',
        T: () => Int32Value,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.Homework
 */
export const Homework = new Homework$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkContents$Type extends MessageType<HomeworkContents> {
  constructor() {
    super('sparx.planning.v1.HomeworkContents', [
      {
        no: 1,
        name: 'homework_units',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkUnit,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.HomeworkContents
 */
export const HomeworkContents = new HomeworkContents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlanRequest$Type extends MessageType<GetHomeworkPlanRequest> {
  constructor() {
    super('sparx.planning.v1.GetHomeworkPlanRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetHomeworkPlanRequest
 */
export const GetHomeworkPlanRequest = new GetHomeworkPlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetHomeworkPlansRequest$Type extends MessageType<BatchGetHomeworkPlansRequest> {
  constructor() {
    super('sparx.planning.v1.BatchGetHomeworkPlansRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_group_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.BatchGetHomeworkPlansRequest
 */
export const BatchGetHomeworkPlansRequest =
  new BatchGetHomeworkPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetHomeworkPlansResponse$Type extends MessageType<BatchGetHomeworkPlansResponse> {
  constructor() {
    super('sparx.planning.v1.BatchGetHomeworkPlansResponse', [
      {
        no: 1,
        name: 'homework_plans',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkPlan,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.BatchGetHomeworkPlansResponse
 */
export const BatchGetHomeworkPlansResponse =
  new BatchGetHomeworkPlansResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateHomeworkPlanRequest$Type extends MessageType<UpdateHomeworkPlanRequest> {
  constructor() {
    super('sparx.planning.v1.UpdateHomeworkPlanRequest', [
      { no: 1, name: 'plan', kind: 'message', T: () => HomeworkPlan },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.UpdateHomeworkPlanRequest
 */
export const UpdateHomeworkPlanRequest = new UpdateHomeworkPlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkRequest$Type extends MessageType<GetHomeworkRequest> {
  constructor() {
    super('sparx.planning.v1.GetHomeworkRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetHomeworkRequest
 */
export const GetHomeworkRequest = new GetHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworksRequest$Type extends MessageType<ListHomeworksRequest> {
  constructor() {
    super('sparx.planning.v1.ListHomeworksRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'set_after', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'set_before', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'due_after', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'due_before', kind: 'message', T: () => Timestamp },
      {
        no: 8,
        name: 'include_previous_year_homeworks',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListHomeworksRequest
 */
export const ListHomeworksRequest = new ListHomeworksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworksResponse$Type extends MessageType<ListHomeworksResponse> {
  constructor() {
    super('sparx.planning.v1.ListHomeworksResponse', [
      {
        no: 1,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Homework,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'plan_version',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListHomeworksResponse
 */
export const ListHomeworksResponse = new ListHomeworksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteHomeworkRequest$Type extends MessageType<DeleteHomeworkRequest> {
  constructor() {
    super('sparx.planning.v1.DeleteHomeworkRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.DeleteHomeworkRequest
 */
export const DeleteHomeworkRequest = new DeleteHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkPlanContents$Type extends MessageType<HomeworkPlanContents> {
  constructor() {
    super('sparx.planning.v1.HomeworkPlanContents', [
      {
        no: 1,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Homework,
      },
      {
        no: 2,
        name: 'skipped_topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SkippedTopic,
      },
      {
        no: 3,
        name: 'removed_from_consolidation_topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => RemovedFromConsolidationTopic,
      },
      {
        no: 4,
        name: 'plan_version',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.HomeworkPlanContents
 */
export const HomeworkPlanContents = new HomeworkPlanContents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SkippedTopic$Type extends MessageType<SkippedTopic> {
  constructor() {
    super('sparx.planning.v1.SkippedTopic', [
      { no: 1, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'week_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.SkippedTopic
 */
export const SkippedTopic = new SkippedTopic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemovedFromConsolidationTopic$Type extends MessageType<RemovedFromConsolidationTopic> {
  constructor() {
    super('sparx.planning.v1.RemovedFromConsolidationTopic', [
      { no: 1, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'removed_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.RemovedFromConsolidationTopic
 */
export const RemovedFromConsolidationTopic =
  new RemovedFromConsolidationTopic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlanContentsRequest$Type extends MessageType<GetHomeworkPlanContentsRequest> {
  constructor() {
    super('sparx.planning.v1.GetHomeworkPlanContentsRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'include_previous_year_homeworks',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetHomeworkPlanContentsRequest
 */
export const GetHomeworkPlanContentsRequest =
  new GetHomeworkPlanContentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateHomeworkPlanContentsRequest$Type extends MessageType<UpdateHomeworkPlanContentsRequest> {
  constructor() {
    super('sparx.planning.v1.UpdateHomeworkPlanContentsRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'contents',
        kind: 'message',
        T: () => HomeworkPlanContents,
      },
      {
        no: 3,
        name: 'plan_version',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.UpdateHomeworkPlanContentsRequest
 */
export const UpdateHomeworkPlanContentsRequest =
  new UpdateHomeworkPlanContentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSkippedTopicsRequest$Type extends MessageType<ListSkippedTopicsRequest> {
  constructor() {
    super('sparx.planning.v1.ListSkippedTopicsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListSkippedTopicsRequest
 */
export const ListSkippedTopicsRequest = new ListSkippedTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSkippedTopicsResponse$Type extends MessageType<ListSkippedTopicsResponse> {
  constructor() {
    super('sparx.planning.v1.ListSkippedTopicsResponse', [
      {
        no: 1,
        name: 'skipped_topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SkippedTopic,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListSkippedTopicsResponse
 */
export const ListSkippedTopicsResponse = new ListSkippedTopicsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelGeneratedHomeworkRequest$Type extends MessageType<CancelGeneratedHomeworkRequest> {
  constructor() {
    super('sparx.planning.v1.CancelGeneratedHomeworkRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'remove_from_consolidation_topics',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'user_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.CancelGeneratedHomeworkRequest
 */
export const CancelGeneratedHomeworkRequest =
  new CancelGeneratedHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworksByStartDateRequest$Type extends MessageType<ListHomeworksByStartDateRequest> {
  constructor() {
    super('sparx.planning.v1.ListHomeworksByStartDateRequest', [
      { no: 1, name: 'starts_after', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'starts_before', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListHomeworksByStartDateRequest
 */
export const ListHomeworksByStartDateRequest =
  new ListHomeworksByStartDateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGeneratedHomeworkForStudentsRequest$Type extends MessageType<ListGeneratedHomeworkForStudentsRequest> {
  constructor() {
    super('sparx.planning.v1.ListGeneratedHomeworkForStudentsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'ends_after', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListGeneratedHomeworkForStudentsRequest
 */
export const ListGeneratedHomeworkForStudentsRequest =
  new ListGeneratedHomeworkForStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkPlansUsingSchemeRequest$Type extends MessageType<ListHomeworkPlansUsingSchemeRequest> {
  constructor() {
    super('sparx.planning.v1.ListHomeworkPlansUsingSchemeRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'sol_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListHomeworkPlansUsingSchemeRequest
 */
export const ListHomeworkPlansUsingSchemeRequest =
  new ListHomeworkPlansUsingSchemeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkPlansUsingSchemeResponse$Type extends MessageType<ListHomeworkPlansUsingSchemeResponse> {
  constructor() {
    super('sparx.planning.v1.ListHomeworkPlansUsingSchemeResponse', [
      {
        no: 1,
        name: 'homework_plans',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkPlan,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListHomeworkPlansUsingSchemeResponse
 */
export const ListHomeworkPlansUsingSchemeResponse =
  new ListHomeworkPlansUsingSchemeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlanLaunchLockRequest$Type extends MessageType<GetHomeworkPlanLaunchLockRequest> {
  constructor() {
    super('sparx.planning.v1.GetHomeworkPlanLaunchLockRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetHomeworkPlanLaunchLockRequest
 */
export const GetHomeworkPlanLaunchLockRequest =
  new GetHomeworkPlanLaunchLockRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworkPlanLaunchLockResponse$Type extends MessageType<GetHomeworkPlanLaunchLockResponse> {
  constructor() {
    super('sparx.planning.v1.GetHomeworkPlanLaunchLockResponse', [
      {
        no: 1,
        name: 'lock_acquired',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetHomeworkPlanLaunchLockResponse
 */
export const GetHomeworkPlanLaunchLockResponse =
  new GetHomeworkPlanLaunchLockResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchUpdateHomeworkPlansRequest$Type extends MessageType<BatchUpdateHomeworkPlansRequest> {
  constructor() {
    super('sparx.planning.v1.BatchUpdateHomeworkPlansRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'requests',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UpdateHomeworkPlanRequest,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.BatchUpdateHomeworkPlansRequest
 */
export const BatchUpdateHomeworkPlansRequest =
  new BatchUpdateHomeworkPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHomeworkPlansResponse$Type extends MessageType<ListHomeworkPlansResponse> {
  constructor() {
    super('sparx.planning.v1.ListHomeworkPlansResponse', [
      {
        no: 1,
        name: 'homework_plans',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkPlan,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.ListHomeworkPlansResponse
 */
export const ListHomeworkPlansResponse = new ListHomeworkPlansResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MigrateSchoolHomeworkPlansRequest$Type extends MessageType<MigrateSchoolHomeworkPlansRequest> {
  constructor() {
    super('sparx.planning.v1.MigrateSchoolHomeworkPlansRequest', [
      {
        no: 1,
        name: 'school_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'commit_changes',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'omit_response',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.planning.v1.MigrateSchoolHomeworkPlansRequest
 */
export const MigrateSchoolHomeworkPlansRequest =
  new MigrateSchoolHomeworkPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MigratedPlan$Type extends MessageType<MigratedPlan> {
  constructor() {
    super('sparx.planning.v1.MigratedPlan', [
      { no: 1, name: 'homework_plan', kind: 'message', T: () => HomeworkPlan },
      {
        no: 2,
        name: 'homework_plan_contents',
        kind: 'message',
        T: () => HomeworkPlanContents,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.planning.v1.MigratedPlan
 */
export const MigratedPlan = new MigratedPlan$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MigratedSchoolHomeworkPlans$Type extends MessageType<MigratedSchoolHomeworkPlans> {
  constructor() {
    super('sparx.planning.v1.MigratedSchoolHomeworkPlans', [
      {
        no: 1,
        name: 'migrated_plans',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => MigratedPlan,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.planning.v1.MigratedSchoolHomeworkPlans
 */
export const MigratedSchoolHomeworkPlans =
  new MigratedSchoolHomeworkPlans$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MigrateSchoolHomeworkPlansResponse$Type extends MessageType<MigrateSchoolHomeworkPlansResponse> {
  constructor() {
    super('sparx.planning.v1.MigrateSchoolHomeworkPlansResponse', [
      {
        no: 1,
        name: 'migrated_school_homework_plans',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => MigratedSchoolHomeworkPlans,
      },
      {
        no: 2,
        name: 'plans_migrated',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.planning.v1.MigrateSchoolHomeworkPlansResponse
 */
export const MigrateSchoolHomeworkPlansResponse =
  new MigrateSchoolHomeworkPlansResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateHomeworkRequest$Type extends MessageType<GenerateHomeworkRequest> {
  constructor() {
    super('sparx.planning.v1.GenerateHomeworkRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
      { no: 3, name: 'local_start_date', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'local_end_date', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'local_expiry_date', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'expiry_time', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'compulsory_minutes',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GenerateHomeworkRequest
 */
export const GenerateHomeworkRequest = new GenerateHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCreatePackagesRequest$Type extends MessageType<BatchCreatePackagesRequest> {
  constructor() {
    super('sparx.planning.v1.BatchCreatePackagesRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'requests',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CreatePackageRequest,
      },
      { no: 3, name: 'homework', kind: 'message', T: () => Homework },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.BatchCreatePackagesRequest
 */
export const BatchCreatePackagesRequest = new BatchCreatePackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCreatePackagesResponse$Type extends MessageType<BatchCreatePackagesResponse> {
  constructor() {
    super('sparx.planning.v1.BatchCreatePackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.BatchCreatePackagesResponse
 */
export const BatchCreatePackagesResponse =
  new BatchCreatePackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkPlanUpdateMessage$Type extends MessageType<HomeworkPlanUpdateMessage> {
  constructor() {
    super('sparx.planning.v1.HomeworkPlanUpdateMessage', [
      { no: 1, name: 'timestamp', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'homework_plan', kind: 'message', T: () => HomeworkPlan },
      {
        no: 3,
        name: 'contents',
        kind: 'message',
        T: () => HomeworkPlanContents,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.HomeworkPlanUpdateMessage
 */
export const HomeworkPlanUpdateMessage = new HomeworkPlanUpdateMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendAnalyticsRequest$Type extends MessageType<SendAnalyticsRequest> {
  constructor() {
    super('sparx.planning.v1.SendAnalyticsRequest', [
      { no: 1, name: 'schoolName', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.planning.v1.SendAnalyticsRequest
 */
export const SendAnalyticsRequest = new SendAnalyticsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendAnalyticsResponse$Type extends MessageType<SendAnalyticsResponse> {
  constructor() {
    super('sparx.planning.v1.SendAnalyticsResponse', [
      { no: 1, name: 'plansDone', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.planning.v1.SendAnalyticsResponse
 */
export const SendAnalyticsResponse = new SendAnalyticsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlansToGenerateRequest$Type extends MessageType<GetPlansToGenerateRequest> {
  constructor() {
    super('sparx.planning.v1.GetPlansToGenerateRequest', [
      { no: 1, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetPlansToGenerateRequest
 */
export const GetPlansToGenerateRequest = new GetPlansToGenerateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolPlansToGenerate$Type extends MessageType<SchoolPlansToGenerate> {
  constructor() {
    super('sparx.planning.v1.SchoolPlansToGenerate', [
      { no: 1, name: 'schoolID', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'groupIDs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.SchoolPlansToGenerate
 */
export const SchoolPlansToGenerate = new SchoolPlansToGenerate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlansToGenerateResponse$Type extends MessageType<GetPlansToGenerateResponse> {
  constructor() {
    super('sparx.planning.v1.GetPlansToGenerateResponse', [
      {
        no: 1,
        name: 'plans_to_generate',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolPlansToGenerate,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetPlansToGenerateResponse
 */
export const GetPlansToGenerateResponse = new GetPlansToGenerateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddTopicsToNextHomeworkRequest$Type extends MessageType<AddTopicsToNextHomeworkRequest> {
  constructor() {
    super('sparx.planning.v1.AddTopicsToNextHomeworkRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'request_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'topic_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.AddTopicsToNextHomeworkRequest
 */
export const AddTopicsToNextHomeworkRequest =
  new AddTopicsToNextHomeworkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddTopicsToNextHomeworkResponse$Type extends MessageType<AddTopicsToNextHomeworkResponse> {
  constructor() {
    super('sparx.planning.v1.AddTopicsToNextHomeworkResponse', [
      { no: 1, name: 'week_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.AddTopicsToNextHomeworkResponse
 */
export const AddTopicsToNextHomeworkResponse =
  new AddTopicsToNextHomeworkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePlansForRolloverDateChangeRequest$Type extends MessageType<UpdatePlansForRolloverDateChangeRequest> {
  constructor() {
    super('sparx.planning.v1.UpdatePlansForRolloverDateChangeRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'new_academic_year_end_time',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 3,
        name: 'student_group_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.UpdatePlansForRolloverDateChangeRequest
 */
export const UpdatePlansForRolloverDateChangeRequest =
  new UpdatePlansForRolloverDateChangeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePlansForRolloverDateChangeResponse$Type extends MessageType<UpdatePlansForRolloverDateChangeResponse> {
  constructor() {
    super('sparx.planning.v1.UpdatePlansForRolloverDateChangeResponse', [
      {
        no: 1,
        name: 'plans_updated',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.UpdatePlansForRolloverDateChangeResponse
 */
export const UpdatePlansForRolloverDateChangeResponse =
  new UpdatePlansForRolloverDateChangeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPreviousAcademicYearConsolidationTopicsRequest$Type extends MessageType<GetPreviousAcademicYearConsolidationTopicsRequest> {
  constructor() {
    super(
      'sparx.planning.v1.GetPreviousAcademicYearConsolidationTopicsRequest',
      [{ no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetPreviousAcademicYearConsolidationTopicsRequest
 */
export const GetPreviousAcademicYearConsolidationTopicsRequest =
  new GetPreviousAcademicYearConsolidationTopicsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPreviousAcademicYearConsolidationTopicsResponse$Type extends MessageType<GetPreviousAcademicYearConsolidationTopicsResponse> {
  constructor() {
    super(
      'sparx.planning.v1.GetPreviousAcademicYearConsolidationTopicsResponse',
      [
        {
          no: 1,
          name: 'previous_topics',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => PreviousConsolidationTopicInfo,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.GetPreviousAcademicYearConsolidationTopicsResponse
 */
export const GetPreviousAcademicYearConsolidationTopicsResponse =
  new GetPreviousAcademicYearConsolidationTopicsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviousConsolidationTopicInfo$Type extends MessageType<PreviousConsolidationTopicInfo> {
  constructor() {
    super('sparx.planning.v1.PreviousConsolidationTopicInfo', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'students_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.PreviousConsolidationTopicInfo
 */
export const PreviousConsolidationTopicInfo =
  new PreviousConsolidationTopicInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelUngeneratedHomeworksRequest$Type extends MessageType<CancelUngeneratedHomeworksRequest> {
  constructor() {
    super('sparx.planning.v1.CancelUngeneratedHomeworksRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.CancelUngeneratedHomeworksRequest
 */
export const CancelUngeneratedHomeworksRequest =
  new CancelUngeneratedHomeworksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelUngeneratedHomeworksResponse$Type extends MessageType<CancelUngeneratedHomeworksResponse> {
  constructor() {
    super('sparx.planning.v1.CancelUngeneratedHomeworksResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.planning.v1.CancelUngeneratedHomeworksResponse
 */
export const CancelUngeneratedHomeworksResponse =
  new CancelUngeneratedHomeworksResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.planning.v1.HomeworkPlans
 */
export const HomeworkPlans = new ServiceType(
  'sparx.planning.v1.HomeworkPlans',
  [
    {
      name: 'GetHomeworkPlan',
      options: {
        'google.api.http': {
          get: '/v1/{name=schools/*/studentGroups/*/homeworkPlan}',
        },
        'sparx.api.auth': {
          action: 'read',
          resource: 'plan',
          domain: '{name|parent}',
        },
      },
      I: GetHomeworkPlanRequest,
      O: HomeworkPlan,
    },
    {
      name: 'BatchGetHomeworkPlans',
      options: {
        'google.api.http': { get: '/v1/{parent=schools/*}' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'plan',
          domain: '{parent}',
        },
      },
      I: BatchGetHomeworkPlansRequest,
      O: BatchGetHomeworkPlansResponse,
    },
    {
      name: 'UpdateHomeworkPlan',
      options: {
        'google.api.http': {
          put: '/v1/{plan.name=schools/*/studentGroups/*/homeworkPlan}',
          body: 'plan',
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'plan',
          domain: '{plan.name|parent}',
        },
      },
      I: UpdateHomeworkPlanRequest,
      O: HomeworkPlan,
    },
    {
      name: 'BatchUpdateHomeworkPlans',
      options: {
        'google.api.http': { get: '/v1/{parent=schools/*}:batch' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'plan',
          domain: '{parent}',
        },
      },
      I: BatchUpdateHomeworkPlansRequest,
      O: ListHomeworkPlansResponse,
    },
    {
      name: 'GetHomeworkPlanContents',
      options: {
        'google.api.http': {
          get: '/v1/{name=schools/*/studentGroups/*/homeworkPlan/contents}',
        },
        'sparx.api.auth': {
          action: 'read',
          resource: 'plan',
          domain: '{name|parent}',
        },
      },
      I: GetHomeworkPlanContentsRequest,
      O: HomeworkPlanContents,
    },
    {
      name: 'UpdateHomeworkPlanContents',
      options: {
        'google.api.http': {
          put: '/v1/{name=schools/*/studentGroups/*/homeworkPlan/contents}',
          body: '*',
        },
        'sparx.api.auth': {
          action: 'update',
          resource: 'plan',
          domain: '{name|parent}',
        },
      },
      I: UpdateHomeworkPlanContentsRequest,
      O: HomeworkPlanContents,
    },
    {
      name: 'GetPreviousAcademicYearConsolidationTopics',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'plan',
          domain: '{name|parent}',
        },
      },
      I: GetPreviousAcademicYearConsolidationTopicsRequest,
      O: GetPreviousAcademicYearConsolidationTopicsResponse,
    },
    {
      name: 'ListHomeworkPlansUsingScheme',
      options: {
        'google.api.http': { put: '/v1/{parent=schools/*}', body: '*' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'plan',
          domain: '{parent}',
        },
      },
      I: ListHomeworkPlansUsingSchemeRequest,
      O: ListHomeworkPlansUsingSchemeResponse,
    },
    {
      name: 'AddTopicsToNextHomework',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'plan',
          domain: '{parent}',
        },
      },
      I: AddTopicsToNextHomeworkRequest,
      O: AddTopicsToNextHomeworkResponse,
    },
    {
      name: 'UpdatePlansForRolloverDateChange',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'plan',
          domain: '{parent}',
        },
      },
      I: UpdatePlansForRolloverDateChangeRequest,
      O: UpdatePlansForRolloverDateChangeResponse,
    },
    {
      name: 'CancelUngeneratedHomeworks',
      options: {
        'sparx.api.auth': {
          action: 'update',
          resource: 'plan',
          domain: '{name|parent}',
        },
      },
      I: CancelUngeneratedHomeworksRequest,
      O: CancelUngeneratedHomeworksResponse,
    },
  ],
);
/**
 * @generated ServiceType for protobuf service sparx.planning.v1.Homeworks
 */
export const Homeworks = new ServiceType('sparx.planning.v1.Homeworks', [
  { name: 'GetHomework', options: {}, I: GetHomeworkRequest, O: Homework },
  {
    name: 'ListHomeworks',
    options: {},
    I: ListHomeworksRequest,
    O: ListHomeworksResponse,
  },
  { name: 'DeleteHomework', options: {}, I: DeleteHomeworkRequest, O: Empty },
  {
    name: 'CancelGeneratedHomework',
    options: {},
    I: CancelGeneratedHomeworkRequest,
    O: Homework,
  },
  {
    name: 'ListHomeworksByStartDate',
    options: {},
    I: ListHomeworksByStartDateRequest,
    O: ListHomeworksResponse,
  },
  {
    name: 'ListGeneratedHomeworkForStudents',
    options: {},
    I: ListGeneratedHomeworkForStudentsRequest,
    O: ListHomeworksResponse,
  },
]);
/**
 * @generated ServiceType for protobuf service sparx.planning.v1.HomeworkLaunching
 */
export const HomeworkLaunching = new ServiceType(
  'sparx.planning.v1.HomeworkLaunching',
  [
    {
      name: 'GetHomeworkPlanLaunchLock',
      options: {
        'sparx.api.auth': {
          action: 'update',
          resource: 'plan',
          domain: '{name|parent}',
        },
      },
      I: GetHomeworkPlanLaunchLockRequest,
      O: GetHomeworkPlanLaunchLockResponse,
    },
    {
      name: 'BatchCreatePackages',
      options: {
        'sparx.api.auth': {
          action: 'update',
          resource: 'plan',
          domain: '{name|parent}',
        },
      },
      I: BatchCreatePackagesRequest,
      O: BatchCreatePackagesResponse,
    },
    {
      name: 'GenerateHomework',
      options: {
        'sparx.api.auth': {
          action: 'update',
          resource: 'plan',
          domain: '{name|parent}',
        },
      },
      I: GenerateHomeworkRequest,
      O: Empty,
    },
    {
      name: 'GetPlansToGenerate',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:homeworkplan',
          domain: 'homeworkplan',
        },
      },
      I: GetPlansToGenerateRequest,
      O: GetPlansToGenerateResponse,
    },
  ],
);
/**
 * @generated ServiceType for protobuf service sparx.planning.v1.SkippedTopics
 */
export const SkippedTopics = new ServiceType(
  'sparx.planning.v1.SkippedTopics',
  [
    {
      name: 'ListSkippedTopics',
      options: {},
      I: ListSkippedTopicsRequest,
      O: ListSkippedTopicsResponse,
    },
  ],
);
/**
 * @generated ServiceType for protobuf service sparx.planning.v1.HomeworkPlanMigration
 */
export const HomeworkPlanMigration = new ServiceType(
  'sparx.planning.v1.HomeworkPlanMigration',
  [
    {
      name: 'MigrateSchoolHomeworkPlans',
      options: {},
      I: MigrateSchoolHomeworkPlansRequest,
      O: MigrateSchoolHomeworkPlansResponse,
    },
    {
      name: 'SendAnalytics',
      options: {},
      I: SendAnalyticsRequest,
      O: SendAnalyticsResponse,
    },
  ],
);

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/games/state/findit/v1/findit.proto" (package "sparx.games.state.findit.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.games.state.findit.v1.Store
 */
export interface Store {
  /**
   * @generated from protobuf field: map<string, int32> groupWinCounts = 1;
   */
  groupWinCounts: {
    [key: string]: number;
  };
  /**
   * @generated from protobuf field: map<string, int32> challengeWinCounts = 2;
   */
  challengeWinCounts: {
    [key: string]: number;
  };
}
/**
 * @generated from protobuf message sparx.games.state.findit.v1.State
 */
export interface State {
  /**
   * @generated from protobuf field: string challengeID = 1;
   */
  challengeID: string;
  /**
   * @generated from protobuf field: repeated string groups = 2;
   */
  groups: string[];
  /**
   * @generated from protobuf field: sparx.games.state.findit.v1.State.WinState winState = 3;
   */
  winState: State_WinState;
}
/**
 * @generated from protobuf enum sparx.games.state.findit.v1.State.WinState
 */
export enum State_WinState {
  /**
   * @generated from protobuf enum value: none = 0;
   */
  none = 0,
  /**
   * @generated from protobuf enum value: won = 1;
   */
  won = 1,
  /**
   * @generated from protobuf enum value: lost = 2;
   */
  lost = 2,
}
/**
 * @generated from protobuf message sparx.games.state.findit.v1.Init
 */
export interface Init {
  /**
   * @generated from protobuf field: string instructions = 1;
   */
  instructions: string;
  /**
   * @generated from protobuf field: repeated string targets = 2;
   */
  targets: string[];
  /**
   * @generated from protobuf field: repeated string decoys = 3;
   */
  decoys: string[];
  /**
   * @generated from protobuf field: int32 targetCount = 4;
   */
  targetCount: number;
  /**
   * @generated from protobuf field: int32 shapeCount = 5;
   */
  shapeCount: number;
}
/**
 * @generated from protobuf message sparx.games.state.findit.v1.Action
 */
export interface Action {
  /**
   * @generated from protobuf field: sparx.games.state.findit.v1.Action.ActionType actionType = 1;
   */
  actionType: Action_ActionType;
}
/**
 * @generated from protobuf enum sparx.games.state.findit.v1.Action.ActionType
 */
export enum Action_ActionType {
  /**
   * deprecated - supports client 2.59.8 and earlier
   *
   * @generated from protobuf enum value: win = 0;
   */
  win = 0,
  /**
   * deprecated - supports client 2.59.8 and earlier
   *
   * @generated from protobuf enum value: lose = 1;
   */
  lose = 1,
  /**
   * @generated from protobuf enum value: quitGame = 2;
   */
  quitGame = 2,
  /**
   * @generated from protobuf enum value: flagWinState = 3;
   */
  flagWinState = 3,
  /**
   * @generated from protobuf enum value: flagLoseState = 4;
   */
  flagLoseState = 4,
}
/**
 * @generated from protobuf message sparx.games.state.findit.v1.Update
 */
export interface Update {}
/**
 * @generated from protobuf message sparx.games.state.findit.v1.Params
 */
export interface Params {}
// @generated message type with reflection information, may provide speed optimized methods
class Store$Type extends MessageType<Store> {
  constructor() {
    super('sparx.games.state.findit.v1.Store', [
      {
        no: 1,
        name: 'groupWinCounts',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
      {
        no: 2,
        name: 'challengeWinCounts',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.findit.v1.Store
 */
export const Store = new Store$Type();
// @generated message type with reflection information, may provide speed optimized methods
class State$Type extends MessageType<State> {
  constructor() {
    super('sparx.games.state.findit.v1.State', [
      {
        no: 1,
        name: 'challengeID',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'groups',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'winState',
        kind: 'enum',
        T: () => ['sparx.games.state.findit.v1.State.WinState', State_WinState],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.findit.v1.State
 */
export const State = new State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Init$Type extends MessageType<Init> {
  constructor() {
    super('sparx.games.state.findit.v1.Init', [
      {
        no: 1,
        name: 'instructions',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'targets',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'decoys',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'targetCount', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'shapeCount', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.findit.v1.Init
 */
export const Init = new Init$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Action$Type extends MessageType<Action> {
  constructor() {
    super('sparx.games.state.findit.v1.Action', [
      {
        no: 1,
        name: 'actionType',
        kind: 'enum',
        T: () => [
          'sparx.games.state.findit.v1.Action.ActionType',
          Action_ActionType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.findit.v1.Action
 */
export const Action = new Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Update$Type extends MessageType<Update> {
  constructor() {
    super('sparx.games.state.findit.v1.Update', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.findit.v1.Update
 */
export const Update = new Update$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
  constructor() {
    super('sparx.games.state.findit.v1.Params', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.games.state.findit.v1.Params
 */
export const Params = new Params$Type();

// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/assessment/app/v1/authentication.proto" (package "sparx.assessment.app.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Date } from '../../../../../google/type/date';
/**
 * @generated from protobuf message sparx.assessment.app.v1.GetCurrentSessionRequest
 */
export interface GetCurrentSessionRequest {}
/**
 * @generated from protobuf message sparx.assessment.app.v1.GetCurrentSessionResponse
 */
export interface GetCurrentSessionResponse {
  /**
   * @generated from protobuf field: string subject = 1;
   */
  subject: string;
  /**
   * @generated from protobuf field: string user_id = 2;
   */
  userId: string;
  /**
   * @generated from protobuf field: string school_id = 3;
   */
  schoolId: string;
  /**
   * Names of the sittings the user is currently in.
   *
   * @generated from protobuf field: repeated string current_sitting_names = 4;
   */
  currentSittingNames: string[];
  /**
   * The users given name.
   *
   * @generated from protobuf field: string given_name = 5;
   */
  givenName: string;
  /**
   * The users family name.
   *
   * @generated from protobuf field: string family_name = 6;
   */
  familyName: string;
  /**
   * Name to display instead of given and family name.
   *
   * @generated from protobuf field: string display_name = 8;
   */
  displayName: string;
  /**
   * The user type.
   *
   * @generated from protobuf field: string user_type = 7;
   */
  userType: string;
  /**
   * The current users email address. Only returned for staff members.
   *
   * @generated from protobuf field: string email_address = 9;
   */
  emailAddress: string;
  /**
   * Hash for authenticating with intercom. Only returned for staff members.
   *
   * @generated from protobuf field: string intercom_hash = 10;
   */
  intercomHash: string;
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.GetSittingRequest
 */
export interface GetSittingRequest {
  /**
   * The name of the school the assessment is in.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Join code for the assessment.
   *
   * @generated from protobuf field: string join_code = 2;
   */
  joinCode: string;
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.LoginProvider
 */
export interface LoginProvider {
  /**
   * The authentication method.
   *
   * @generated from protobuf field: sparx.assessment.app.v1.LoginProvider.Method method = 1;
   */
  method: LoginProvider_Method;
  /**
   * Optional authentication domain for SSO logins
   *
   * @generated from protobuf field: string domain = 2;
   */
  domain: string;
}
/**
 * @generated from protobuf enum sparx.assessment.app.v1.LoginProvider.Method
 */
export enum LoginProvider_Method {
  /**
   * @generated from protobuf enum value: METHOD_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: METHOD_GUEST = 1;
   */
  GUEST = 1,
  /**
   * @generated from protobuf enum value: METHOD_SPARX = 2;
   */
  SPARX = 2,
  /**
   * @generated from protobuf enum value: METHOD_GOOGLE_SSO = 3;
   */
  GOOGLE_SSO = 3,
  /**
   * @generated from protobuf enum value: METHOD_AZURE_SSO = 4;
   */
  AZURE_SSO = 4,
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.GetSittingResponse
 */
export interface GetSittingResponse {
  /**
   * Name of the sitting in the form "schools/<uuid>/sittings/<uuid>".
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
  /**
   * Title of the assessment sitting.
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * The join code for the sitting.
   *
   * @generated from protobuf field: string join_code = 3;
   */
  joinCode: string;
  /**
   * Whether the sitting requires approval to join.
   *
   * @deprecated
   * @generated from protobuf field: bool requires_approval = 4 [deprecated = true];
   */
  requiresApproval: boolean;
  /**
   * List of authentication providers for the sitting.
   *
   * @generated from protobuf field: repeated sparx.assessment.app.v1.LoginProvider authentication_providers = 5;
   */
  authenticationProviders: LoginProvider[];
  /**
   * Whether the sitting has started or not.
   *
   * @generated from protobuf field: bool has_started = 6;
   */
  hasStarted: boolean;
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.JoinSittingRequest
 */
export interface JoinSittingRequest {
  /**
   * Name of the assessment sitting to join in the form
   * "schools/<uuid>/sittings/<uuid>".
   *
   * @generated from protobuf field: string sitting_name = 1;
   */
  sittingName: string;
  /**
   * @generated from protobuf oneof: method
   */
  method:
    | {
        oneofKind: 'guestStudent';
        /**
         * The student's details.
         *
         * @generated from protobuf field: sparx.assessment.app.v1.JoinSittingRequest.GuestStudent guest_student = 2;
         */
        guestStudent: JoinSittingRequest_GuestStudent;
      }
    | {
        oneofKind: 'withToken';
        /**
         * Authenticate using the token on the request.
         *
         * @generated from protobuf field: bool with_token = 3;
         */
        withToken: boolean;
      }
    | {
        oneofKind: 'firebaseAuth';
        /**
         * Use Firebase authentication token
         *
         * @generated from protobuf field: sparx.assessment.app.v1.JoinSittingRequest.FirebaseAuthentication firebase_auth = 4;
         */
        firebaseAuth: JoinSittingRequest_FirebaseAuthentication;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.JoinSittingRequest.GuestStudent
 */
export interface JoinSittingRequest_GuestStudent {
  /**
   * The student's given name.
   *
   * @generated from protobuf field: string given_name = 2;
   */
  givenName: string;
  /**
   * Family name of the student.
   *
   * @generated from protobuf field: string family_name = 3;
   */
  familyName: string;
  /**
   * Date of birth (year will be unused)
   *
   * @generated from protobuf field: google.type.Date date_of_birth = 4;
   */
  dateOfBirth?: Date;
  /**
   * If the student is requesting to rejoin as an existing student
   *
   * @generated from protobuf field: bool rejoining = 5;
   */
  rejoining: boolean;
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.JoinSittingRequest.FirebaseAuthentication
 */
export interface JoinSittingRequest_FirebaseAuthentication {
  /**
   * Firebase ID token
   *
   * @generated from protobuf field: string id_token = 1;
   */
  idToken: string;
  /**
   * Required for first time user logs in. Will error if user with token
   * does not exist and this is not provided.
   *
   * @generated from protobuf field: sparx.assessment.app.v1.JoinSittingRequest.GuestStudent guest_student = 2;
   */
  guestStudent?: JoinSittingRequest_GuestStudent;
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.JoinSittingResponse
 */
export interface JoinSittingResponse {
  /**
   * The subject of the assigned user that joined the session.
   *
   * @generated from protobuf field: string subject = 1;
   */
  subject: string;
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.AuthenticateGuestTeacherRequest
 */
export interface AuthenticateGuestTeacherRequest {
  /**
   * The school name.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The join token.
   *
   * @generated from protobuf field: string token = 2;
   */
  token: string;
}
/**
 * @generated from protobuf message sparx.assessment.app.v1.AuthenticateGuestTeacherResponse
 */
export interface AuthenticateGuestTeacherResponse {
  /**
   * The subject of the guest teacher.
   *
   * @generated from protobuf field: string subject = 1;
   */
  subject: string;
  /**
   * Name of the sitting the token was generated for.
   *
   * @generated from protobuf field: string sitting_name = 2;
   */
  sittingName: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentSessionRequest$Type extends MessageType<GetCurrentSessionRequest> {
  constructor() {
    super('sparx.assessment.app.v1.GetCurrentSessionRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.GetCurrentSessionRequest
 */
export const GetCurrentSessionRequest = new GetCurrentSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentSessionResponse$Type extends MessageType<GetCurrentSessionResponse> {
  constructor() {
    super('sparx.assessment.app.v1.GetCurrentSessionResponse', [
      { no: 1, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'current_sitting_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 8,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'user_type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 9,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'intercom_hash',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.GetCurrentSessionResponse
 */
export const GetCurrentSessionResponse = new GetCurrentSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSittingRequest$Type extends MessageType<GetSittingRequest> {
  constructor() {
    super('sparx.assessment.app.v1.GetSittingRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'join_code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.GetSittingRequest
 */
export const GetSittingRequest = new GetSittingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginProvider$Type extends MessageType<LoginProvider> {
  constructor() {
    super('sparx.assessment.app.v1.LoginProvider', [
      {
        no: 1,
        name: 'method',
        kind: 'enum',
        T: () => [
          'sparx.assessment.app.v1.LoginProvider.Method',
          LoginProvider_Method,
          'METHOD_',
        ],
      },
      { no: 2, name: 'domain', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.LoginProvider
 */
export const LoginProvider = new LoginProvider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSittingResponse$Type extends MessageType<GetSittingResponse> {
  constructor() {
    super('sparx.assessment.app.v1.GetSittingResponse', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'join_code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'requires_approval',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 5,
        name: 'authentication_providers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LoginProvider,
      },
      { no: 6, name: 'has_started', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.GetSittingResponse
 */
export const GetSittingResponse = new GetSittingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinSittingRequest$Type extends MessageType<JoinSittingRequest> {
  constructor() {
    super('sparx.assessment.app.v1.JoinSittingRequest', [
      {
        no: 1,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'guest_student',
        kind: 'message',
        oneof: 'method',
        T: () => JoinSittingRequest_GuestStudent,
      },
      {
        no: 3,
        name: 'with_token',
        kind: 'scalar',
        oneof: 'method',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'firebase_auth',
        kind: 'message',
        oneof: 'method',
        T: () => JoinSittingRequest_FirebaseAuthentication,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.JoinSittingRequest
 */
export const JoinSittingRequest = new JoinSittingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinSittingRequest_GuestStudent$Type extends MessageType<JoinSittingRequest_GuestStudent> {
  constructor() {
    super('sparx.assessment.app.v1.JoinSittingRequest.GuestStudent', [
      { no: 2, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'date_of_birth', kind: 'message', T: () => Date },
      { no: 5, name: 'rejoining', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.JoinSittingRequest.GuestStudent
 */
export const JoinSittingRequest_GuestStudent =
  new JoinSittingRequest_GuestStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinSittingRequest_FirebaseAuthentication$Type extends MessageType<JoinSittingRequest_FirebaseAuthentication> {
  constructor() {
    super('sparx.assessment.app.v1.JoinSittingRequest.FirebaseAuthentication', [
      { no: 1, name: 'id_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'guest_student',
        kind: 'message',
        T: () => JoinSittingRequest_GuestStudent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.JoinSittingRequest.FirebaseAuthentication
 */
export const JoinSittingRequest_FirebaseAuthentication =
  new JoinSittingRequest_FirebaseAuthentication$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinSittingResponse$Type extends MessageType<JoinSittingResponse> {
  constructor() {
    super('sparx.assessment.app.v1.JoinSittingResponse', [
      { no: 1, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.JoinSittingResponse
 */
export const JoinSittingResponse = new JoinSittingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateGuestTeacherRequest$Type extends MessageType<AuthenticateGuestTeacherRequest> {
  constructor() {
    super('sparx.assessment.app.v1.AuthenticateGuestTeacherRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.AuthenticateGuestTeacherRequest
 */
export const AuthenticateGuestTeacherRequest =
  new AuthenticateGuestTeacherRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateGuestTeacherResponse$Type extends MessageType<AuthenticateGuestTeacherResponse> {
  constructor() {
    super('sparx.assessment.app.v1.AuthenticateGuestTeacherResponse', [
      { no: 1, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'sitting_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.assessment.app.v1.AuthenticateGuestTeacherResponse
 */
export const AuthenticateGuestTeacherResponse =
  new AuthenticateGuestTeacherResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.assessment.app.v1.Authentication
 */
export const Authentication = new ServiceType(
  'sparx.assessment.app.v1.Authentication',
  [
    {
      name: 'GetCurrentSession',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'assessments:user',
          domain: 'assessments',
        },
      },
      I: GetCurrentSessionRequest,
      O: GetCurrentSessionResponse,
    },
    {
      name: 'GetSitting',
      options: {},
      I: GetSittingRequest,
      O: GetSittingResponse,
    },
    {
      name: 'JoinSitting',
      options: {},
      I: JoinSittingRequest,
      O: JoinSittingResponse,
    },
    {
      name: 'AuthenticateGuestTeacher',
      options: {},
      I: AuthenticateGuestTeacherRequest,
      O: AuthenticateGuestTeacherResponse,
    },
  ],
);

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://9d0e0ddd7414b901d0bba53626181b41@o1086305.ingest.us.sentry.io/4507570982027264',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/.*\.sparxassessments\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  release: import.meta.env.VITE_RELEASE_VERSION,
  environment: window.settings?.envName,
  enabled: Boolean(window.settings?.envName) && window.settings?.envName !== 'dev',

  // Exclude 'ResizeObserver' errors as these are safe to ignore
  // Exclude 'LoadFailed' errors as we think that they're out of our control, e.g. network issues.
  ignoreErrors: ['ResizeObserver', 'Load failed'],
});
